// React Imports
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";

// import {adhocChildrenDeals} from "../../../data/mock-data";
// MUI Imports
import {
  Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Slide,
  Snackbar,
  Table,
  TableFooter,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Snack Settings
import { snackSettings } from "../../home/home-page";

// Component Imports
import TabPanel from "../../../components/tab-panel/tab-panel-component";
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../../components/back-button/back-button-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";
import ChildrenDealsCard from "../../../components/children-deals-card/children-deals-card-component";
import CreateNewDealCard from "../../../components/create-new-deal-card/create-new-deal-card-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Deal Slices
import {
  addDeal,
  getAdHocChildrenDeals,
  getAdHocsChildrenDeals,
  updateAdHocChildrenDeal,
} from "../../../slices/dealCanvas";
import ChannelService from "../../../services/channel.service";
import GeneralService from "../../../services/general.service";

// Data to create new deal with (TO BE REPLACED)
const newDealData = {
  dealDescription:
    "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
  tariffName: "Red Flexi 75",
  tariffTerm: 24,
  paymentType: "Financed",
  dealPrice: 599,
  costToConnect: 2000,
  eff: 57,
};

// Filter by Options
const filterByOptions = [
  {
    heading: "Device Specifications",
    options: [
      {
        value: "32gb",
        label: "32GB",
      },
      {
        value: "64gb",
        label: "64GB",
      },
      {
        value: "128gb",
        label: "128GB",
      },
      {
        value: "256gb",
        label: "256GB",
      },
    ],
  },
  {
    heading: "Tariff",
    options: [
      {
        value: "Red Flexi",
        label: "Red Flexi",
      },
      {
        value: "RED",
        label: "RED",
      },
    ],
  },
];

// Sorting Options
const sortingOptions = [
  {
    heading: "Efficiency",
    options: [
      {
        value: "eff_asc",
        label: "A - Z",
      },
      {
        value: "eff_desc",
        label: "Z - A",
      },
    ],
  },
  {
    heading: "Tariff",
    options: [
      {
        value: "tariff_asc",
        label: "A - Z",
      },
      {
        value: "tariff_desc",
        label: "Z - A",
      },
    ],
  },
];

export default function AdHocDealsChildren() {
  const { dealset } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const deviceID = queryParams.get("id");

  // Redux dispatch hook
  const dispatch = useDispatch();

  const [childrenUpdated, setChildrenUpdated] = useState(0);

  useEffect(() => {
    dispatch(
      getAdHocsChildrenDeals({
        dde_parent_keys: location.state.dde_parent_keys,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, childrenUpdated]);

  // Get adhocChildrenDeals from Redux store
  const adhocChildrenDeals = useSelector(getAdHocChildrenDeals);

  // Set DATA sources
  const data = {
    deals: adhocChildrenDeals,
    device: location.state.device_name,
  };
  const deviceName = data.device;
  const dealType = "adhocChildrenDeals";

  // States
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [filteredData, setFilteredData] = useState(data.deals);

  // Table Properties for 24 Month tariff - Amount of items per page
  const [page24, setPage24] = useState(0);
  const [rowsPerPage24, setRowsPerPage24] = useState(10);

  // Handle change table page for 24 Month tariff
  const handleChangePage24 = (_, newPage) => {
    setPage24(newPage);
  };

  // Handle change table rows per page for 24 Month tariff
  const handleChangeRowsPerPage24 = (event) => {
    setRowsPerPage24(parseInt(event.target.value, 10));
    setPage24(0);
  };

  // Table Properties for 36 Month tariff - Amount of items per page
  const [page36, setPage36] = useState(0);
  const [rowsPerPage36, setRowsPerPage36] = useState(10);

  // Handle change table page for 36 Month tariff
  const handleChangePage36 = (_, newPage) => {
    setPage36(newPage);
  };

  // Handle change table rows per page for 36 Month tariff
  const handleChangeRowsPerPage36 = (event) => {
    setRowsPerPage36(parseInt(event.target.value, 10));
    setPage36(0);
  };

  // Update data as it changes
  useEffect(() => {
    let filteredData = data.deals;

    // Filter deals by search text
    if (searchText) {
      filteredData = data.deals.filter((deal) => {
        // Join all values of the deal object and its specs object into a string
        const values = Object.values(deal)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return values.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      });
    }

    // Filter deals by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((deal) => {
        // Join all values of the deal object and its specs object into a string
        const values = Object.values(deal)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (values.includes(option)) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredData(filteredData);
  }, [searchText, filterBy, data]);

  // Handle search filter via search input field
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // Handle filter by option change
  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  // Handle sorting order change
  const handleSort = (event) => {
    setSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "tariff_asc":
        sortedData = [...filteredData].sort((a, b) =>
          a.tariffName.localeCompare(b.tariffName)
        );
        break;
      case "tariff_desc":
        sortedData = [...filteredData].sort((a, b) =>
          b.tariffName.localeCompare(a.tariffName)
        );
        break;
      case "eff_asc":
        sortedData = [...filteredData].sort((a, b) => a.eff - b.eff);
        break;
      case "eff_desc":
        sortedData = [...filteredData].sort((a, b) => b.eff - a.eff);
        break;
      default:
        sortedData = filteredData;
        break;
    }

    setFilteredData(sortedData);
  };

  // Term selection states and function
  const [term, setTerm] = useState(0);
  const handleTermChange = (_, term) => {
    setTerm(term);
  };

  // Handle deal publish
  const handleSave = () => {
    const updatedChildrenDeals = filteredData
      .filter((obj) => obj.isChanged === true)
      .map((deal) => {
        return {
          dde_parent_key: deal.id,
          deal_price: deal.dealPrice,
        };
      });

    ChannelService.updateChannelLadderDeals(
      GeneralService.getCurrentCycle(),
      updatedChildrenDeals
    ).then(() => {
      setChildrenUpdated(childrenUpdated + 1);
      handleGenericSnackOpen("success", `Changes saved successfully.`);
    });
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleDealPriceChange = (event, deal_id, term) => {
    let value = event.target.value;

    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");

    dispatch(
      updateAdHocChildrenDeal({
        data: {
          value,
          deal_id,
          term,
        },
        object: dealType,
      })
    );
  };

  // Handle add new deal
  // const handleAddDeal = (type, data, deviceID) => {
  //   dispatch(addDeal({ type: type, data: data, deviceID: deviceID }));
  //   handleGenericSnackOpen("success", "Deal successfully created.");
  // };

  const deleteAsync = (dde_parent_key) =>
    new Promise(function (resolve, reject) {
      ChannelService.deleteSingleChannelDeal(dde_parent_key)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleDeleteDeal = async (dde_parent_key) => {
    let deleteError;
    await deleteAsync(dde_parent_key).catch((error) => {
      deleteError = error;
    });
    if (deleteError) {
      //handleServerError(deleteError);
    } else {
      setChildrenUpdated(childrenUpdated + 1);
      handleGenericSnackOpen("success", "Deal deleted successfully.");
    }
  };
  return (
    <>
      <div className="col-start-1 col-end-13 row-start-2 mt-8 mb-4 px-6 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#dealsetTitle"
        className="col-start-1 col-end-13 row-start-3 px-6"
      >
        <TitleBreadcrumb
          title={dealset}
          titleID="dealsetTitle"
          button={
            <Button
              className="mt-6 lg:mt-0"
              variant="contained"
              disableFocusRipple
              onClick={() => handleSave()}
            >
              Save
            </Button>
          }
        />

        <div className="my-8 text-center">
          <ToggleButtonGroup
            value={term}
            exclusive
            onChange={handleTermChange}
            aria-label="Term selection"
          >
            <ToggleButton value={0}>24 Months</ToggleButton>
            <ToggleButton value={1}>36 Months</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: sortOrder || "",
                  onChange: handleSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((group) => [
                  <ListSubheader
                    key={group.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {group.heading}
                  </ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className="px-6"
                    >
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={filterBy}
                onChange={handleFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        {/* 24 Month Tariff */}
        <TabPanel value={term} index={0}>
          <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
            {filteredData
              .filter((deal) => deal.tariffTerm === 24)
              .slice(
                page24 * rowsPerPage24,
                page24 * rowsPerPage24 + rowsPerPage24
              )
              .map((deal) => (
                <div className="col-span-1" key={deal.id}>
                  <ChildrenDealsCard
                    deviceID={deviceID}
                    data={deal}
                    deviceName={deviceName}
                    handleGenericSnackOpen={handleGenericSnackOpen}
                    dealType={dealType}
                    handleDealPriceChange={(e) =>
                      handleDealPriceChange(e, deal.id, 24)
                    }
                    handleDeleteDeal={handleDeleteDeal}
                  />
                </div>
              ))}
            {/* <div className="col-span-1">
              <CreateNewDealCard createDeal={() => handleAddDeal(dealType, newDealData, deviceID)} messageFromActions={handleGenericSnackOpen} />
            </div> */}
          </div>

          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    20,
                    30,
                    40,
                    50,
                    { label: "All", value: -1 },
                  ]}
                  count={
                    filteredData.filter((deal) => deal.tariffTerm === 24).length
                  }
                  rowsPerPage={rowsPerPage24}
                  page={page24}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage24}
                  onRowsPerPageChange={handleChangeRowsPerPage24}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TabPanel>

        {/* 36 Month Tariff */}
        <TabPanel value={term} index={1}>
          <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
            {filteredData
              .filter((deal) => deal.tariffTerm === 36)
              .slice(
                page36 * rowsPerPage36,
                page36 * rowsPerPage36 + rowsPerPage36
              )
              .map((deal) => (
                <div className="col-span-1" key={deal.id}>
                  <ChildrenDealsCard
                    deviceID={deviceID}
                    data={deal}
                    deviceName={deviceName}
                    handleGenericSnackOpen={handleGenericSnackOpen}
                    dealType={dealType}
                    handleDealPriceChange={(e) =>
                      handleDealPriceChange(e, deal.id, 36)
                    }
                  />
                </div>
              ))}
            {/* <div className="col-span-1">
              <CreateNewDealCard createDeal={() => handleAddDeal(dealType, newDealData, deviceID)} messageFromActions={handleGenericSnackOpen} />
            </div> */}
          </div>

          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    20,
                    30,
                    40,
                    50,
                    { label: "All", value: -1 },
                  ]}
                  count={
                    filteredData.filter((deal) => deal.tariffTerm === 36).length
                  }
                  rowsPerPage={rowsPerPage36}
                  page={page36}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage36}
                  onRowsPerPageChange={handleChangeRowsPerPage36}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TabPanel>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
