// Image Import
import AstronautOnCloud from "../../../assets/illustrations/astronaut-on-cloud.svg";

// Error 404 Page
export default function Error500() {
  return (
    <section
      aria-labelledby="errorPageTitle"
      className="col-start-2 col-end-12 my-6 grid min-h-[calc(100vh-137px)] grid-cols-10 place-items-center gap-4"
    >
      <div className="col-span-10 text-center md:col-span-5">
        <h1 className="mb-4 text-6xl font-bold text-black" id="errorPageTitle">
          500 error
        </h1>
        <p className="text-lg font-medium text-black">
          An internal server error occurred
        </p>
      </div>
      <div className="col-span-10 text-center md:col-span-5">
        <img
          src={AstronautOnCloud}
          width={400}
          height={475}
          alt="Astronaut sitting on a cloud with balloons attached"
        />
      </div>
    </section>
  );
}
