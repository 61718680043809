// React Imports
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  approveDeal,
  entirelyRejectDeal,
  getAdHocDeals,
  getChannelDeals,
  getHeadlineDeals,
  getHeadlineDealsByOEM,
  deleteDeals,
  rejectDeal,
  getFlaggedHeadlineDeals,
  _getFlaggedHeadlineDeals,
  getFlaggedChannelDeals,
  _getFlaggedChannelDeals,
} from "../../slices/dealCanvas";

// MUI Imports
import {
  Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Snackbar,
  Slide,
  Tabs,
  Tab,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Component Imports
import BackButton from "../../components/back-button/back-button-component";
import CustomCheckbox from "../../components/checkbox/checkbox-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import InputField from "../../components/input/input-component";
import TitleBreadcrumb from "../../components/title-breadcrumb/title-breadcrumb-component";
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import DealCycleTotals from "../../components/deal-cycle-totals/deal-cycle-totals-component";
import DealApprovalCard from "../../components/deal-approval-cards/deal-approval-cards-component";
import MultiSelectDropdownWithSearch from "../../components/multiselect-dropdown-with-search/multiselect-dropdown-with-search-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SaveIcon from "../../assets/icons/save-icon.svg";
import BlockIcon from "../../assets/icons/block-icon.svg";
import XCircleIcon from "../../assets/icons/x-circle-icon.svg";
import TickCircleOutlineIon from "../../assets/icons/tick-circle-outline-icon.svg";

// Service Imports
import DealService from "../../services/deal.service";
import AuthService from "../../services/auth.service";

// Snack Settings
import { snackSettings } from "../../pages/home/home-page";
import GeneralService from "../../services/general.service";

// Sorting Options
const sortingOptions = [
  {
    heading: "Efficiency",
    options: [
      {
        value: "eff_asc",
        label: "Low to High",
      },
      {
        value: "eff_desc",
        label: "High to Low",
      },
    ],
  },
  {
    heading: "OEN",
    options: [
      {
        value: "oem_asc",
        label: "A - Z",
      },
      {
        value: "oem_desc",
        label: "Z - A",
      },
    ],
  },
];

const _overviewTotals = {
  totalCostToConnect: "R 0.00",
  totalReducedSubscription: "R 0.00",
  totalFinanced: "R 0.00",
  totalEfficiency: "0.00%",
  totalIntensity: "0.00%",
};

export default function DealApproval() {
  const navigate = useNavigate();
  const { cycle } = useParams();

  const [channelDataLoaded, setChannelDataLoaded] = useState(false);
  const [headlineDataLoaded, setHeadlineDataLoaded] = useState(false);
  const [isEmptyHeadlineData, setIsEmptyHeadlineData] = useState(false);
  const [isHeadlineLoading, setIsHeadlineLoading] = useState(true);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Redux dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    queryHeadlineDeals();
    queryChannelDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch
  ]);

  const queryHeadlineDeals = () => {
    // Initialize arrays to store selected items in OEM List and ROM List
    const oemList = [];
    const romList = [];
    const typeList = [];

    // Iterate through the lists array
    filterByOptions.forEach((list) => {
      list.options.forEach((option) => {
        if (filterBy.includes(option.value)) {
          // Check if the option's value is in the selectedItems array
          if (list.heading === "OEM") {
            oemList.push(option.value);
          } else if (list.heading === "Device Specifications") {
            romList.push(option.value);
          } else if (list.heading === "Deal Type") {
            typeList.push(option.value);
          }
        }
      });
    });

    const queryOptions = {
      addon: "N",
      oem: oemList.length > 0 ? oemList : "",
      rom: romList.length > 0 ? romList : "",
      deal_desc: searchText,
      order_asc: sortOrder,
      deal_type_id: "",
    };

    dispatch(
      getFlaggedHeadlineDeals({ cycle, queryOptions })
    )
      .unwrap()
      .then((_) => {
        setIsEmptyHeadlineData(_.length === 0);
        setHeadlineDataLoaded(_.length > 0);
      })
      .catch(() => {})
      .finally(() => {
        setIsHeadlineLoading(false);
      });
  };

  const queryChannelDeals = () => {
    // Initialize arrays to store selected items in OEM List and ROM List
    const oemList = [];
    const romList = [];
    const dealType = [];
    const channelList = [];

    // Iterate through the lists array
    filterByOptions.forEach((list) => {
      list.options.forEach((option) => {
        if (filterBy.includes(option.label)) {
          // Check if the option's value is in the selectedItems array
          if (list.heading === "OEM") {
            oemList.push(option.value);
          } else if (list.heading === "Device Specifications") {
            romList.push(option.value);
          } else if (list.heading === "Deal Type") {
            dealType.push(option.value);
          } else if (list.heading === "Channels") {
            channelList.push(option.value);
          }
        }
      });
    });

    const queryOptions = {
      addon: dealType.length > 0 ? dealType : "N",
      oem: oemList.length > 0 ? oemList : "",
      rom: romList.length > 0 ? romList : "",
      deal_desc: searchText,
      order_asc: sortOrder,
      channel_id: channelList.length > 0 ? channelList : "",
      deal_type_id: "",
    };

    dispatch(
      getFlaggedChannelDeals({ cycle, queryOptions })
    )
      .unwrap()
      .then((_) => {
        setIsEmptyData(_.length === 0);
        setChannelDataLoaded(_.length > 0);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Deals state from Redux slice
  const flaggedHeadlineDeals = useSelector(_getFlaggedHeadlineDeals);
  const flaggedChannelDeals = useSelector(_getFlaggedChannelDeals);
  const adhocDeals = useSelector(getAdHocDeals);

  // Filters and Sort options
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [filterByOptions, setFilterByOptions] = useState([]);

  // Combine data for filtering
  const allDeals = useMemo(
    () => [
      { group: "headline", deals: flaggedHeadlineDeals },
      { group: "channel", deals: flaggedChannelDeals },
      { group: "adhoc", deals: adhocDeals },
    ],
    [flaggedHeadlineDeals, flaggedChannelDeals, adhocDeals]
  );

  // Flagged Deals Search, Sort and Filter options
  const [filteredDeals, setFilteredDeals] = useState(allDeals);

  // Get Filter options
  useEffect(() => {
    GeneralService.getDealApprovalROMs(cycle)
      .then((response_1) => {
        const { data: ROMs } = response_1;

        GeneralService.getDealApprovalOEMs(cycle)
          .then((response_2) => {
            const { data: OEMs } = response_2;
            
            const _ROMs = ROMs.map((item) => {
              return { value: item.name, label: item.name };
            });

            const _OEMs = OEMs.map((item) => {
              return { value: item.name, label: item.name };
            });

            const newfilterByOptions = [
              {
                heading: "Device Specifications",
                options: [..._ROMs],
              },
              {
                heading: "OEM",
                options: [..._OEMs],
              },
              {
                heading: "Deal Type",
                options: [
                  {
                    value: "N",
                    label: "Standalone Deal",
                  },
                  {
                    value: "Y",
                    label: "Add-On Deal",
                  },
                ],
              },
              {
                heading: "Channels",
                options: [
                  {
                    value: 1,
                    label: "Direct",
                  },
                  {
                    value: 2,
                    label: "Dealer & Franchises",
                  },
                  {
                    value: 3,
                    label: "National Chains",
                  },
                  {
                    value: 4,
                    label: "Online",
                  },
                  {
                    value: 5,
                    label: "Onyx",
                  },
                ],
              }
            ];
            setFilterByOptions(newfilterByOptions);
          })
          .catch((error) => {
            handleServerError(error);
          });
    })
      .catch((error) => {
      handleServerError(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle search event
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // Handle sorting event
  const handleSort = (event) => {
    setSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "oem_asc":
        sortedData = filteredDeals.map((dealGroup) => ({
          group: dealGroup.group,
          deals: [...dealGroup.deals].sort((a, b) =>
            a.oem.localeCompare(b.oem)
          ),
        }));
        break;
      case "oem_desc":
        sortedData = filteredDeals.map((dealGroup) => ({
          group: dealGroup.group,
          deals: [...dealGroup.deals].sort((a, b) =>
            b.oem.localeCompare(a.oem)
          ),
        }));
        break;
      case "eff_asc":
        sortedData = filteredDeals.map((dealGroup) => ({
          group: dealGroup.group,
          deals: [...dealGroup.deals].sort((a, b) =>
            a.eff.localeCompare(b.eff)
          ),
        }));
        break;
      case "eff_desc":
        sortedData = filteredDeals.map((dealGroup) => ({
          group: dealGroup.group,
          deals: [...dealGroup.deals].sort((a, b) =>
            b.eff.localeCompare(a.eff)
          ),
        }));
        break;
      default:
        sortedData = filteredDeals;
        break;
    }
    setFilteredDeals(sortedData);
  };

  // Handle Save
  const handleSave = () => {
    const updatedHeadlineDeals = flaggedHeadlineDeals
      .filter((item) => item.dataHasChanged === true)
      .flatMap((item) => {
        return Array.from(
          item.termSpecific.filter((v) => v.approval_action !== 0 && v.dataHasChanged === true),
          (subItem) => {
            return {
              id: item.id,
              type: "HL",
              cycle_id: item.cycle_id,
              channel_id: item.channel_id,
              device_basket_id: item.devices[0].deviceId,
              tariff_basket_id: subItem.tariff_basket_id,
              term: subItem.term,
              status: subItem.approval_action,
              comment: subItem.approval_reason,
              latest_approved_level: subItem.latest_approved_level,
              approval_level_required: subItem.approval_level_required,
            };
          }
        );
      });

    const updatedChannelDeals = flaggedChannelDeals
    .filter((item) => item.dataHasChanged === true)
    .flatMap((item) => {
      return Array.from(
        item.termSpecific.filter((v) => v.approval_action !== 0 && v.dataHasChanged === true),
        (subItem) => {
          return {
            id: item.id,
            type: "CG",
            cycle_id: item.cycle_id,
            channel_id: item.channel_id,
            device_basket_id: item.devices[0].deviceId,
            tariff_basket_id: subItem.tariff_basket_id,
            term: subItem.term,
            dde_parent_key: subItem.dde_parent_key,
            status: subItem.approval_action,
            comment: subItem.approval_reason,
            latest_approved_level: subItem.latest_approved_level,
            approval_level_required: subItem.approval_level_required,
          };
        }
      );
    });

    const combinedUpdatedDeals = [...updatedHeadlineDeals, ...updatedChannelDeals];

    if (combinedUpdatedDeals.length > 0) {
      DealService.updateFlaggedDeals(combinedUpdatedDeals)
        .then((response) => {
          const { unApprovedDeals, unApprovedDealsCount, message } = response.data;

          if (unApprovedDealsCount > 0) {
            dispatch(
              deleteDeals({
                type: "flaggedHeadlineDeals",
                deals: updatedHeadlineDeals,
                unApprovedDeals: unApprovedDeals
              })
            );

            handleGenericSnackOpen(
              "warning",
              "Same user can’t do multi-level approvals."
            );
          } else {
            dispatch(
              deleteDeals({
                type: "flaggedHeadlineDeals",
                deals: updatedHeadlineDeals,
                unApprovedDeals: unApprovedDeals
              })
            );
            handleGenericSnackOpen(
              "success",
              message
            );
          }
          // dispatch(
          //   deleteDeals({
          //     type: "flaggedChannelDeals",
          //     deals: updatedChannelDeals,
          //   })
          // );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // Tabs
  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const [overviewTotals, setOverviewTotals] = useState(_overviewTotals);

  useEffect(() => {
    DealService.getTotalEnr(cycle)
      .then((response) => {
        setOverviewTotals(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Selected Deals States
  const [headlineDealsSelected, setHeadlineDealsSelected] = useState(
    filteredDeals
      .find((v) => v.group === "headline")
      .deals.map((item) => {
        return { id: item.id, selected: false };
      })
  );
  const [channelDealsSelected, setChannelDealsSelected] = useState(
    flaggedChannelDeals.map(() => false)
  );
  const [adhocDealsSelected, setAdHocDealsSelected] = useState(
    adhocDeals.map(() => false)
  );

  // Handle select headline deals change
  const handleSelectHeadlineDealsChange = (id) => {
    const updatedStates = [...headlineDealsSelected];
    updatedStates.find((v) => v.id === id).selected =
      !headlineDealsSelected.find((v) => v.id === id).selected;
    setHeadlineDealsSelected(updatedStates);
  };

  // Handle select channel deals change
  const handleSelectChannelDealsChange = (id) => {
    const updatedStates = [...channelDealsSelected];
    updatedStates.find((v) => v.id === id).selected =
      !channelDealsSelected.find((v) => v.id === id).selected;
    setChannelDealsSelected(updatedStates);
  };

  // Handle select adhoc deals change
  const handleSelectAdHocDealsChange = (index) => {
    const updatedStates = [...adhocDealsSelected];
    updatedStates[index] = !adhocDealsSelected[index];
    setAdHocDealsSelected(updatedStates);
  };

  // Handle selected all deals
  const handleSelectAllDeals = (e, type) => {
    switch (type) {
      case "headline":
        const selectedHeadlines = e.target.checked ? true : false;
        setHeadlineDealsSelected(
          filteredDeals
            .find((v) => v.group === "headline")
            .deals.map((item) => {
              return { id: item.id, selected: selectedHeadlines };
            })
        );
        break;

      case "channel":
        setChannelDealsSelected(
          flaggedChannelDeals.map(() => (e.target.checked ? true : false))
        );
        break;

      case "adhoc":
        setAdHocDealsSelected(
          adhocDeals.map(() => (e.target.checked ? true : false))
        );
        break;

      default:
        break;
    }
  };

  // Handle approve selected
  const handleApproveSelected = (dealType) => {
    // Trigger "approveDeal" for each deal that has been selected
    headlineDealsSelected.forEach((item) => {
      if (item.selected) {
        const selectedDeal = filteredDeals
          .find((v) => v.group === "headline")
          .deals.find((v) => v.id === item.id); 

        // If approve deal is false, set it to true
        if (selectedDeal.termSpecific[term].approval_action !== 1) {
          dispatch(
            approveDeal({
              id: selectedDeal.id,
              type: dealType,
              termIndex: term,
            })
          );
        }
      }
    });

    channelDealsSelected.forEach((item) => {
      if (item.selected) {
        const selectedDeal = filteredDeals
          .find((v) => v.group === "channel")
          .deals.find((v) => v.id === item.id);

        // If approve deal is false, set it to true
        if (selectedDeal.termSpecific[term].approval_action !== 1) {
          dispatch(
            approveDeal({
              id: selectedDeal.id,
              type: dealType,
              termIndex: term,
            })
          );
        }
      }
    });
  };

  // Handle reject selected
  const handleRejectSelected = (dealType) => {
    // Trigger "rejectDeal" for each deal that has been selected
    headlineDealsSelected.forEach((item) => {
      if (item.selected) {
        const selectedDeal = filteredDeals
          .find((v) => v.group === "headline")
          .deals.find((v) => v.id === item.id);

        // If reject deal is false, set it to true
        if (selectedDeal.termSpecific[term].approval_action !== 2) {
          dispatch(
            rejectDeal({
              id: selectedDeal.id,
              type: dealType,
              termIndex: term,
            })
          );
        }
      }
    });

    channelDealsSelected.forEach((item) => {
      if (item.selected) {
        const selectedDeal = filteredDeals
          .find((v) => v.group === "channel")
          .deals.find((v) => v.id === item.id);

        // If reject deal is false, set it to true
        if (selectedDeal.termSpecific[term].approval_action !== 2) {
          dispatch(
            rejectDeal({
              id: selectedDeal.id,
              type: dealType,
              termIndex: term,
            })
          );
        }
      }
    });
  };

  // Handle entirely reject selected
  // const handleEntirelyRejectSelected = (dealType) => {
  //   // Trigger "entirelyRejectDeal" for each deal that has been selected
  //   headlineDealsSelected.forEach((item) => {
  //     if (item.selected) {
  //       const selectedDeal = filteredDeals
  //         .find((v) => v.group === "headline")
  //         .deals.find((v) => v.id === item.id);

  //       // If reject deal is false, set it to true
  //       if (selectedDeal.termSpecific[term].approval_action !== 3) {
  //         dispatch(
  //           entirelyRejectDeal({
  //             id: selectedDeal.id,
  //             type: dealType,
  //             termIndex: term,
  //           })
  //         );
  //       }
  //     }
  //   });
  // };

  const [term, setTerm] = useState(0);
  const handleTermChange = (_, term) => {
    setTerm(term);
  };

  useEffect(() => {
    if(headlineDataLoaded) {
      setIsHeadlineLoading(true);
      queryHeadlineDeals();

      // const updatedGroupedData = {};
      // allDeals
      //   .find((deal) => deal.group === "headline")
      //   .deals.filter((item) => {
      //     console.log(item);

      //     if (!updatedGroupedData[item.channel_id]) {
      //       updatedGroupedData[item.channel_id] = [];
      //     }
      //     updatedGroupedData[item.channel_id].push(item);
      //   });

      //   console.log(updatedGroupedData);
    }

    if(channelDataLoaded) {
      setIsLoading(true);
      queryChannelDeals();
    }

  }, [
    filterBy, 
    searchText
  ]);

  const headlines24 = allDeals
    .find((deal) => deal.group === "headline")
    .deals.filter((v) => v.termSpecific[0].tariff_basket_id !== 0);
  const headlines30 = allDeals
    .find((deal) => deal.group === "headline")
    .deals.filter((v) => v.termSpecific[1].tariff_basket_id !== 0);
  const headlines36 = allDeals
    .find((deal) => deal.group === "headline")
    .deals.filter((v) => v.termSpecific[2].tariff_basket_id !== 0);

  const channel24 = allDeals
    .find((deal) => deal.group === "channel")
    .deals.filter((v) => v.termSpecific[0].tariff_basket_id !== 0);
  const channel30 = allDeals
    .find((deal) => deal.group === "channel")
    .deals.filter((v) => v.termSpecific[1].tariff_basket_id !== 0);
  const channel36 = allDeals
    .find((deal) => deal.group === "channel")
    .deals.filter((v) => v.termSpecific[2].tariff_basket_id !== 0);

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#dealApprovalTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title="Deal Approval"
          titleID="dealApprovalTitle"
          button={
            <Button
              className="mt-6 lg:mt-0"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={SaveIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              onClick={() => handleSave()}
            >
              Save
            </Button>
          }
        />

        <div className="my-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-6 lg:col-span-3">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: sortOrder || "",
                  onChange: handleSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((group) => [
                  <ListSubheader
                    key={group.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {group.heading}
                  </ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className="px-6"
                    >
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-6 lg:col-span-3">
              <MultiSelectDropdownWithSearch
                label="Filter by"
                options={filterByOptions}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
              />
            </div>
          </div>
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Deal type tabs"
          className="my-8"
        >
          <Tab label="Headline Deals" {...a11yProps(0)} disableRipple />
          <Tab label="Channel Deals" {...a11yProps(1)} disableRipple />
          <Tab label="Ad-Hoc Deals" {...a11yProps(2)} disableRipple />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className="my-8 text-center">
            <ToggleButtonGroup
              value={term}
              exclusive
              onChange={handleTermChange}
              aria-label="Term selection"
            >
              <ToggleButton value={0}>24 Months</ToggleButton>
              <ToggleButton value={1}>30 Months</ToggleButton>
              <ToggleButton value={2}>36 Months</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <TabPanel value={term} index={0}>
            {/* <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {headlines24 ? (
                headlines24.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectHeadlineDealsChange(device.id, device)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedHeadlineDeals"
                      termIndex={0}
                    />
                  </div>
                ))
              ) : (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              )}
            </div> */}
            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {isHeadlineLoading ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  Fetching deals...
                </div>
              ) : (isEmptyHeadlineData || headlines24.length === 0) ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              ) : (headlines24.length > 0 && headlineDataLoaded) ? (
                headlines24.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectHeadlineDealsChange(device.id, device)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedHeadlineDeals"
                      termIndex={0}
                    />
                  </div>
                ))
              ) : 
              (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  An error occured while fetching deals...
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={term} index={1}>
            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {isHeadlineLoading ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  Fetching deals...
                </div>
              ) : (isEmptyHeadlineData || headlines30.length === 0) ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              ) : (headlines30.length > 0 && headlineDataLoaded) ? (
                headlines30.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectHeadlineDealsChange(device.id, device)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedHeadlineDeals"
                      termIndex={1}
                    />
                  </div>
                ))
              ) : 
              (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  An error occured while fetching deals...
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={term} index={2}>
            {/* {headlines36 && (
              <div className="my-8 flex flex-col items-center gap-4 lg:flex-row">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      onChange={(e) => handleSelectAllDeals(e, "headline")}
                    />
                  }
                  label="Select all"
                />

                {headlineDealsSelected.find((v) => v.selected).length > 0 && (
                  <div className="flex flex-col items-center gap-4 lg:ml-auto lg:flex-row">
                    <Button
                      color="warning"
                      startIcon={
                        <InlineSVG
                          src={BlockIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() =>
                        handleEntirelyRejectSelected("flaggedHeadlineDeals")
                      }
                    >
                      Entirely reject selected
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={
                        <InlineSVG
                          src={XCircleIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() =>
                        handleRejectSelected("flaggedHeadlineDeals")
                      }
                    >
                      Reject selected
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      startIcon={
                        <InlineSVG
                          src={TickCircleOutlineIon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() =>
                        handleApproveSelected("flaggedHeadlineDeals")
                      }
                    >
                      Approve selected
                    </Button>
                  </div>
                )}
              </div>
            )} */}

            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {isHeadlineLoading ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  Fetching deals...
                </div>
              ) : (isEmptyHeadlineData || headlines36.length === 0) ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              ) : (headlines36.length > 0 && headlineDataLoaded) ? (
                headlines36.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectHeadlineDealsChange(device.id, device)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedHeadlineDeals"
                      termIndex={2}
                    />
                  </div>
                ))
              ) : 
              (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  An error occured while fetching deals...
                </div>
              )}
            </div>
          </TabPanel>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="my-8 text-center">
            <ToggleButtonGroup
              value={term}
              exclusive
              onChange={handleTermChange}
              aria-label="Term selection"
            >
              <ToggleButton value={0}>24 Months</ToggleButton>
              <ToggleButton value={1}>30 Months</ToggleButton>
              <ToggleButton value={2}>36 Months</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <TabPanel value={term} index={0}>
            {/* {channel24.length >
              0 && (
              <div className="my-8 flex flex-col items-center gap-4 lg:flex-row">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      onChange={(e) => handleSelectAllDeals(e, "channel")}
                    />
                  }
                  label="Select all"
                />

                {channelDealsSelected.includes(true) && (
                  <div className="flex flex-col items-center gap-4 lg:ml-auto lg:flex-row">
                    <Button
                      color="warning"
                      startIcon={
                        <InlineSVG
                          src={BlockIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleEntirelyRejectSelected("channelDeals")}
                    >
                      Entirely reject selected
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={
                        <InlineSVG
                          src={XCircleIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleRejectSelected("channelDeals")}
                    >
                      Reject selected
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      startIcon={
                        <InlineSVG
                          src={TickCircleOutlineIon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleApproveSelected("channelDeals")}
                    >
                      Approve selected
                    </Button>
                  </div>
                )}
              </div>
            )} */}

            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {isLoading ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  Fetching deals...
                </div>
              ) : (isEmptyData || channel24.length === 0) ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              ) : (channel24.length > 0 && channelDataLoaded) ? (
                channel24.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectChannelDealsChange(device.id)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedChannelDeals"
                      termIndex={0}
                    />
                  </div>
                ))
              ) : 
              (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  An error occured while fetching deals...
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={term} index={1}>
            {/* {channel30.length >
              0 && (
              <div className="my-8 flex flex-col items-center gap-4 lg:flex-row">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      onChange={(e) => handleSelectAllDeals(e, "channel")}
                    />
                  }
                  label="Select all"
                />

                {channelDealsSelected.includes(true) && (
                  <div className="flex flex-col items-center gap-4 lg:ml-auto lg:flex-row">
                    <Button
                      color="warning"
                      startIcon={
                        <InlineSVG
                          src={BlockIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleEntirelyRejectSelected("channelDeals")}
                    >
                      Entirely reject selected
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={
                        <InlineSVG
                          src={XCircleIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleRejectSelected("channelDeals")}
                    >
                      Reject selected
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      startIcon={
                        <InlineSVG
                          src={TickCircleOutlineIon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleApproveSelected("channelDeals")}
                    >
                      Approve selected
                    </Button>
                  </div>
                )}
              </div>
            )} */}

            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {isLoading ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  Fetching deals...
                </div>
              ) : (isEmptyData || channel30.length === 0) ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              ) : (channel30.length > 0 && channelDataLoaded) ? (
                channel30.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectChannelDealsChange(device.id)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedChannelDeals"
                      termIndex={1}
                    />
                  </div>
                ))
              ) : 
              (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  An error occured while fetching deals...
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={term} index={2}>
            {/* {channel36.length >
              0 && (
              <div className="my-8 flex flex-col items-center gap-4 lg:flex-row">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      onChange={(e) => handleSelectAllDeals(e, "channel")}
                    />
                  }
                  label="Select all"
                />

                {channelDealsSelected.includes(true) && (
                  <div className="flex flex-col items-center gap-4 lg:ml-auto lg:flex-row">
                    <Button
                      color="warning"
                      startIcon={
                        <InlineSVG
                          src={BlockIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleEntirelyRejectSelected("channelDeals")}
                    >
                      Entirely reject selected
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={
                        <InlineSVG
                          src={XCircleIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleRejectSelected("channelDeals")}
                    >
                      Reject selected
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      startIcon={
                        <InlineSVG
                          src={TickCircleOutlineIon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      }
                      disableFocusRipple
                      onClick={() => handleApproveSelected("channelDeals")}
                    >
                      Approve selected
                    </Button>
                  </div>
                )}
              </div>
            )} */}

            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {isLoading ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  Fetching deals...
                </div>
              ) : (isEmptyData || channel36.length === 0) ? (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              ) : (channel36.length > 0 && channelDataLoaded) ? (
                channel36.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectChannelDealsChange(device.id)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="flaggedChannelDeals"
                      termIndex={2}
                    />
                  </div>
                ))
              ) : 
              (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  An error occured while fetching deals...
                </div>
              )}
            </div>
          </TabPanel>
        </TabPanel>

        <TabPanel value={value} index={2}>
          {/* {filteredDeals.find((deal) => deal.group === "adhoc").deals.length >
            0 && (
            <div className="my-8 flex flex-col items-center gap-4 lg:flex-row">
              <FormControlLabel
                control={
                  <CustomCheckbox
                    onChange={(e) => handleSelectAllDeals(e, "adhoc")}
                  />
                }
                label="Select all"
              />

              {adhocDealsSelected.includes(true) && (
                <div className="flex flex-col items-center gap-4 lg:ml-auto lg:flex-row">
                  <Button
                    color="warning"
                    startIcon={
                      <InlineSVG
                        src={BlockIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    }
                    disableFocusRipple
                    onClick={() => handleEntirelyRejectSelected("adhocDeals")}
                  >
                    Entirely reject selected
                  </Button>

                  <Button
                    variant="outlined"
                    color="error"
                    startIcon={
                      <InlineSVG
                        src={XCircleIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    }
                    disableFocusRipple
                    onClick={() => handleRejectSelected("adhocDeals")}
                  >
                    Reject selected
                  </Button>

                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <InlineSVG
                        src={TickCircleOutlineIon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    }
                    disableFocusRipple
                    onClick={() => handleApproveSelected("adhocDeals")}
                  >
                    Approve selected
                  </Button>
                </div>
              )}
            </div>
          )} */}

          <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
            {filteredDeals.find((deal) => deal.group === "adhoc").deals.length >
            0 ? (
              filteredDeals
                .find((deal) => deal.group === "adhoc")
                .deals.map((device, index) => (
                  <div className="col-span-1" key={device.id}>
                    <DealApprovalCard
                      data={device}
                      dealSelected={
                        headlineDealsSelected.find((v) => v.id === device.id)
                          ?.selected
                      }
                      setDealSelected={() =>
                        handleSelectAdHocDealsChange(index)
                      }
                      messageFromActions={handleGenericSnackOpen}
                      dealType="adhocDeals"
                    />
                  </div>
                ))
            ) : (
              <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                No deals found...
              </div>
            )}
          </div>
        </TabPanel>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}