import axios from "axios";

const API_URL = "/api/cycleConfig/";

// Get Standard VASes
const getDealTypePerChannel = (cycle) => {
  return axios.get(API_URL + cycle + "/dealTypePerChannelData");
};

// Get Standard VASes 24 term
const getStandardVASs24 = (cycle) => {
  return axios.get(API_URL + cycle + "/standardVAS/" + 24);
};

// Get Standard VASes 36 term
const getStandardVASs36 = (cycle) => {
  return axios.get(API_URL + cycle + "/standardVAS/" + 36);
};

// Get VAS list
const getVas = () => {
  return axios.get(API_URL + "vases");
};

// Get Tariffs
const getTariffs = (cycle) => {
  return axios.get(API_URL + cycle + "/tariffs");
};

// Get Device Per Channel Handsets
const getDevicePerChannelHandsets = (cycle) => {
  return axios.get(API_URL + cycle + "/dpch");
};

// Get Device Per Channel Accessories
const getDevicePerChannelAccessories = (cycle) => {
  return axios.get(API_URL + cycle + "/dpca");
};

// Get Device Per Channel OEMS
const getDevicePerChannelFilters = (cycle) => {
  return axios.get(API_URL + cycle + "/filters");
};

// Get Routers
const getRouters = (cycle) => {
  return axios.get(API_URL + cycle + "/routersData");
};

// Get Routers
const getRoutersTariff = (cycle) => {
  return axios.get(API_URL + cycle + "/routersData/tariff-type");
};

// Get Deal Cycle Parameters
const getDealCycleParameters = (cycle) => {
  return axios.get(API_URL + cycle + "/deal-cycle-parameters");
};

// Get Active VASs
const getActiveVASs = (cycle) => {
  return axios.get(API_URL + cycle + "/active-vases");
};

// Get Active VASs Type
const getActiveVASsType = (cycle) => {
  return axios.get(API_URL + cycle + "/active-vases-type");
};

// Get Tariff Ranges
const getTariffRanges = (cycle) => {
  return axios.get(API_URL + cycle + "/tariff-ranges");
};

// Get Deal Cycle Config Reviews
const getCycleReviews = (cycle) => {
  return axios.get(API_URL + cycle + "/cycle-reviews");
};

//Update Routers
const updateRouters = (cycle, routersData) => {
  return axios.put(API_URL + cycle + "/routersData", {routersData});
};

//Update Routers Tariffs 
const updateRoutersTariffs = (cycle, routersTariff) => {
  return axios.put(API_URL + cycle + "/routersData/routersTariff", {routersTariff});
};

// Update Devices Per Channel
const updateDevicePerChannel = (cycle, devicePerChannelData) => {
  return axios.put(API_URL + cycle + "/devicePerChannelData", {devicePerChannelData});
};

// Update Deal Per Channel
const updateDealPerChannel = (cycle, dealPerChannelData) => {
  return axios.put(API_URL + cycle + "/dealPerChannelData", {dealPerChannelData});
};

// Update StandardVAS
const updateStandardVAS = (cycle, standardVASData) => {
  return axios.put(API_URL + cycle + "/standardVASData", {standardVASData});
};

// Add New Tariff
const addTariff = (cycle, newTariff) => {
  return axios.post(API_URL + cycle + "/newTariff", {newTariff});
};

// Update Tariffs
const updateTariffs = (cycle, tariffsData) => {
  return axios.put(API_URL + cycle + "/tariffsData", {tariffsData});
};

// Update Tariffs
const updateTariffRanges = (cycle, rangesData) => {
  return axios.put(API_URL + cycle + "/tariff-ranges", {rangesData});
};

// Delete Tariff
const deleteTariff = (cycle, id) => {
  console.log(id);
  return axios.delete(API_URL + cycle + "/deleteTariff/" + id);
};

// Update Tariffs
const updateDealCycleParameters = (cycle, cycleParameters) => {
  return axios.put(API_URL + cycle + "/deal-cycle-parameters", {cycleParameters});
};

// Update Cycle Reviews
const updateCycleReviews = (cycle, cycleReviewsData) => {
  return axios.put(API_URL + cycle + "/cycle-reviews", {cycleReviewsData});
};

// Send Deal Cycle Configuration Approval Email
const sendEmail = (emailLoad) => {
  return axios.post(API_URL +  "api/test/email-test/" + {emailLoad});
};

const CycleConfigService = {
  getDealTypePerChannel,
  getStandardVASs24,
  getStandardVASs36,
  getVas,
  getTariffs,
  getRouters,
  updateRoutersTariffs,
  getRoutersTariff,
  getDevicePerChannelHandsets,
  getDevicePerChannelAccessories,
  getDevicePerChannelFilters,
  getDealCycleParameters,
  getActiveVASs,
  getActiveVASsType,
  getTariffRanges,
  getCycleReviews,
  updateRouters,
  updateDevicePerChannel,
  updateDealPerChannel,
  updateStandardVAS,
  addTariff,
  updateTariffs,
  updateTariffRanges,
  updateDealCycleParameters,
  deleteTariff,
  updateCycleReviews,
  sendEmail
};

export default CycleConfigService;
