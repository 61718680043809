// React and Mui Imports
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  debounce,
} from "@mui/material";

// Redux Imports
import {
  getHeadlineAddonDeals,
  addDeal,
  getHeadlineDeals,
  getDirectDeals,
  getDirectAddonDeals,
  getDealersAndFranchiseDeals,
  getDealersAndFranchiseAddonDeals,
  getNationalChainsDeals,
  getNationalChainsAddonDeals,
  getOnlineDeals,
  getOnlineAddonDeals,
  getVBOnlineDeals,
  getVBOnlineAddonDeals,
  getVodaworldAddonDeals,
  deleteDeal,
  deleteDealTerm,
  getHeadlineDealsByChannel,
} from "../../../slices/dealCanvas";
import { useDispatch, useSelector } from "react-redux";

// Service Imports
import AuthService from "../../../services/auth.service";
import DealService from "../../../services/deal.service";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import HeadlineDealCard from "../../../components/headline-deal-cards/headline-deal-cards-component";
import BackButton from "../../../components/back-button/back-button-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import TabPanel, {
  a11yProps,
} from "../../../components/tab-panel/tab-panel-component";
import DealCycleTotals from "../../../components/deal-cycle-totals/deal-cycle-totals-component";
import CreateNewDealCard from "../../../components/create-new-deal-card/create-new-deal-card-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SaveIcon from "../../../assets/icons/save-icon.svg";
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../../assets/icons/error-circle-no-fill-icon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Generate unique id for deals
import { v4 as uuidv4 } from "uuid";

// Mock Data Import
import {
  //OEMData,
  //deviceOptions,
  //tariffOptions,
  //addDeviceData,
  sortingOptions,
  filterByOptions,
  //routerOptions,
} from "../../../data/mock-data";
import GeneralService from "../../../services/general.service";
import HeadlineService from "../../../services/headline.service";

const _overviewTotals = {
  totalCostToConnect: "R 0.00",
  totalReducedSubscription: "R 0.00",
  totalFinanced: "R 0.00",
  totalEfficiency: "0.00%",
  totalIntensity: "0.00%",
};

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  warning: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

const buildNewDeal = (data) => {
  const term24 = data.tariffCode24 && {
    tariff_basket_id: 0,
    term: 24,
    tariff_code: data.tariffCode24,
    deal_price: 0,
    ctc: 0,
    discount: 0,
    efficiency: 0,
  };
  const term30 = data.tariffCode30 && {
    tariff_basket_id: 0,
    term: 30,
    tariff_code: data.tariffCode30,
    deal_price: 0,
    ctc: 0,
    discount: 0,
    efficiency: 0,
  };
  const term36 = data.tariffCode36 && {
    tariff_basket_id: 0,
    term: 36,
    tariff_code: data.tariffCode36,
    deal_price: 0,
    ctc: 0,
    discount: 0,
    efficiency: 0,
  };
  const termsList = [];
  if (term24) {
    termsList.push(term24);
  }
  if (term30) {
    termsList.push(term30);
  }
  if (term36) {
    termsList.push(term36);
  }

  return {
    id: uuidv4(),
    type: null,
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: data.device.label,
        specs: {
          size: "",
          ram: "",
          rom: "",
          sim: "",
        },
        previousDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        currentDealCycle: {
          ctd: 0,
          term24: 0,
          term30: 0,
          term36: 0,
        },
        supplierAmbitions: {
          ctd: 0,
          term24: 0,
          term36: 0,
          eff: 0,
          int: 0,
          ctc: 0,
          ctc36: 0,
          eff36: 0,
          tariffDiscount: 0,
        },
      },
    ],
    terminal: {
      network: "",
      plan: 0,
      previousSales: 0,
      currentSales: 0,
      comments: "",
    },
    vodacomInfo: {
      termDifferential: 0,
      previousDeviceCost: 0,
      previousCtc: 0,
      previousEff: 0,
      previousInt: 0,
      previousNewLine: 0,
      previousRetention: 0,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -0,
        fairValueDiff: 0,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: 0,
        fairValueDiff: 0,
      },
    ],
    moreInfo: {
      deviceFullTitle: "",
      productCode: 0,
      tariff: 0,
      subs: 0,
      previousDeviceCost: 0,
      previousCtd: 0,
      previousRrp: 0,
      currentRrp: 0,
      currentSimoRrp: 0,
      discountFairValue: 0,
      differentials: {
        dp: 0,
        eff: 0,
        cib: 0,
        reducedSubs: "",
      },
    },
    dataHasChanged: false,
    tariff_basket_id: 0,
    cycle_id: 0,
    multiAccessory: [],
    multiDevice: [],
    multiTariff: [],
    multiRouter: data.router ? [{ id: uuidv4(), cid: data.router.value }] : [],
    channel_id: data.channel_id,
    cardGlobalLevel: {
      main_device_basket_id: 0,
      main_cid: data.device.value,
      total_basket_price: 0,
      prev_ctd: 0,
      prev_deal_price_24: 0,
      prev_deal_price_30: 0,
      prev_deal_price_36: 0,
      ctd: 0,
      is_add_on: true,
    },
    termSpecific: [...termsList],
    createDeal: true,
    isNotValid: false,
  };
};

export default function HeadlineDealList() {
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const { business_unit } = user;

  // Check if the user is a "Terminals" user
  const [terminalsUser, setTerminalsUser] = useState(false);
  const handleTerminalsCheck = () => {
    const terminals_role = "terminal"; // !! TODO: REPLACE THIS WITH CORRECT PERMISSION TITLE !!

    if (user.user_roles.includes(terminals_role)) {
      setTerminalsUser(true);
    }
  };
  useEffect(() => {
    handleTerminalsCheck();
  });
  //

  // Display the OEM name according to the selected OEM in the URL
  const { cycle, oem } = useParams();

  // Tabs
  const [value, setValue] = useState(0);
  const [dataLoaded, SetDataLoaded] = useState(false);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  // Redux dispatch
  const dispatch = useDispatch();

  const [headlinesUpdated, setHeadlinesUpdated] = useState(0);
  useEffect(() => {
    dispatch(getHeadlineDealsByChannel({ cycle, oem, value }))
      .unwrap()
      .then((_) => {
        // TODO: Update Loader
        SetDataLoaded(true);
      })
      .catch(() => {
        // TODO: Handle catch
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, headlinesUpdated, value]);

  const [overviewTotals, setOverviewTotals] = useState(_overviewTotals);

  useEffect(() => {
    DealService.getTotalEnr(cycle)
      .then((response) => {
        setOverviewTotals(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // General Data
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [allDevicesOptions, setAllDevicesOptions] = useState([]);
  const [tariffOptions, setTariffOptions] = useState([]);
  const [routerOptions, setRouterOptions] = useState([]);
  const [accessoryOptions, setAccessoryOptions] = useState([]);

  useEffect(() => {
    GeneralService.getDevices(cycle, oem)
      .then((response) => {
        setDeviceOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getAllDevices(cycle)
      .then((response) => {
        setAllDevicesOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getAccessories(cycle, oem)
      .then((response) => {
        setAccessoryOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getTariffs(cycle)
      .then((response) => {
        setTariffOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getRouters(cycle)
      .then((response) => {
        setRouterOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Deals state from Redux slice
  const bulkDeals = useSelector(getHeadlineDeals);
  const directDeals = useSelector(getDirectDeals);
  const directAddonDeals = useSelector(getDirectAddonDeals);
  const dealersAndFranchiseDeals = useSelector(getDealersAndFranchiseDeals);
  const dealersAndFranchiseAddonDeals = useSelector(
    getDealersAndFranchiseAddonDeals
  );
  const nationalChainsDeals = useSelector(getNationalChainsDeals);
  const nationalChainsAddonDeals = useSelector(getNationalChainsAddonDeals);
  const onlineDeals = useSelector(getOnlineDeals);
  const vbOnlineDeals = useSelector(getVBOnlineDeals);

  const genericAddonDeals = useSelector(getHeadlineAddonDeals);
  const onlineAddonDeals = useSelector(getOnlineAddonDeals);
  const vbOnlineAddonDeals = useSelector(getVBOnlineAddonDeals);
  const vodaworldAddonDeals = useSelector(getVodaworldAddonDeals);

  // Combine data for filtering
  const allDeals = useMemo(
    () => [
      { group: "bulk", deals: bulkDeals },
      { group: "direct", deals: directDeals },
      { group: "dealersAndFranchise", deals: dealersAndFranchiseDeals },
      { group: "nationalChains", deals: nationalChainsDeals },
      { group: "online", deals: onlineDeals },
      { group: "vbOnlineDeals", deals: vbOnlineDeals },
      // { group: "vodaworld", deals: vodaworldDeals },
      { group: "headlineAddonDeals", deals: genericAddonDeals },
      { group: "vodaworldAddonDeals", deals: vodaworldAddonDeals },
      { group: "directAddonDeals", deals: directAddonDeals },
      {
        group: "dealersAndFranchiseAddonDeals",
        deals: dealersAndFranchiseAddonDeals,
      },
      { group: "nationalChainsAddonDeals", deals: nationalChainsAddonDeals },
      { group: "onlineAddonDeals", deals: onlineAddonDeals },
      { group: "vbOnlineAddonDeals", deals: vbOnlineAddonDeals },
    ],
    [
      bulkDeals,
      directDeals,
      dealersAndFranchiseDeals,
      nationalChainsDeals,
      onlineDeals,
      vbOnlineDeals,
      // vodaworldDeals,
      genericAddonDeals,
      directAddonDeals,
      dealersAndFranchiseAddonDeals,
      nationalChainsAddonDeals,
      onlineAddonDeals,
      vbOnlineAddonDeals,
      vodaworldAddonDeals,
    ]
  );

  // Deals Table Search, Sort and Filter options
  const [filteredDeals, setFilteredDeals] = useState(allDeals);
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortOrder, setSortOrder] = useState("");

  // Searching and filtering through the Headline (Standalone) data
  useEffect(() => {
    let filteredData = allDeals;

    if (searchText) {
      filteredData = allDeals.map((dealGroup) => ({
        group: dealGroup.group,
        deals: dealGroup.deals.filter((deal) => {
          // Join all the device names into one object and search that object
          const deviceValues = Object.values(deal.devices[0].device)
            .map((value) =>
              typeof value === "object" && value !== null
                ? Object.values(value).join("")
                : value
            )
            .join("");
          // Check if the search text is present in the joined row values
          return (
            deviceValues.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
          );
        }),
      }));
    }

    // Filter data based on filter options selected
    if (filterBy.length > 0) {
      filteredData = filteredData.map((dealGroup) => ({
        group: dealGroup.group,
        deals: dealGroup.deals.filter((deal) => {
          // Look for Device specs
          const devSpec = deal.devices[0].specs.rom;

          // Look for Tariff Types
          const dealType = deal.cardGlobalLevel.tariff_type;

          // Iterate over filterBy options and check if any row values contain the option
          for (let option of filterBy) {
            option =
              option === "Data" ? "D" : option === "Voice" ? "V" : option;
            if (
              devSpec.toLowerCase().includes(option.toLowerCase()) ||
              dealType.includes(option)
            ) {
              return true;
            }
          }
          return false;
        }),
      }));
    }
    setFilteredDeals(filteredData);
  }, [allDeals, filterBy, searchText, dataLoaded]);

  // Handle search event
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // Handle filter event
  const handleFilterByChange = (event) => {
    let { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  // Handle sorting event
  const handleSort = (event) => {
    setSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = filteredDeals.map((dealGroup) => ({
          group: dealGroup.group,
          deals: [...dealGroup.deals].sort(
            (a, b) =>
              a.cardGlobalLevel.total_basket_price -
              b.cardGlobalLevel.total_basket_price
          ),
        }));
        break;
      case "desc":
        sortedData = filteredDeals.map((dealGroup) => ({
          group: dealGroup.group,
          deals: [...dealGroup.deals].sort(
            (a, b) =>
              b.cardGlobalLevel.total_basket_price -
              a.cardGlobalLevel.total_basket_price
          ),
        }));
        break;
      default:
        sortedData = filteredDeals;
        break;
    }
    setFilteredDeals(sortedData);
  };

  // Handle data save
  const handleSave = (confirm_supplier_ambitions = false, deal_id = 0) => {
    const allStandAloneDeals = [
      ...bulkDeals,
      ...directDeals,
      ...dealersAndFranchiseDeals,
      ...nationalChainsDeals,
      ...onlineDeals,
      ...vbOnlineDeals,
    ];
    const allAddOnDeals = [
      ...genericAddonDeals,
      ...directAddonDeals,
      ...dealersAndFranchiseAddonDeals,
      ...nationalChainsAddonDeals,
      ...onlineAddonDeals,
      ...vbOnlineAddonDeals,
    ];
    const updatedStandAloneDeals = allStandAloneDeals
      .filter((item) =>
        confirm_supplier_ambitions
          ? item.id === deal_id
          : item.dataHasChanged === true
      )
      .map((item) => ({
        channel_id: item.channel_id,
        device_basket_id: +item.devices[0].deviceId,
        tariff_basket_id_24: item.termSpecific[0].tariff_basket_id,
        tariff_basket_id_30: item.termSpecific[1].tariff_basket_id,
        tariff_basket_id_36: item.termSpecific[2].tariff_basket_id,
        tariff_code_24: item.termSpecific[0].tariff_code,
        tariff_code_30: item.termSpecific[1].tariff_code,
        tariff_code_36: item.termSpecific[2].tariff_code,
        total_ctd: item.devices[0].currentDealCycle.ctd,
        supplier_ctd: item.cardGlobalLevel.supplier_ctd,
        deal_price_curr_24: item.devices[0].currentDealCycle.term24,
        deal_price_curr_30: item.devices[0].currentDealCycle.term30,
        deal_price_curr_36: item.devices[0].currentDealCycle.term36,
        supplier_deal_price_24: item.termSpecific[0].supplier_deal_price,
        supplier_deal_price_30: item.termSpecific[1].supplier_deal_price,
        supplier_deal_price_36: item.termSpecific[2].supplier_deal_price,
        confirm_supplier_ambitions: confirm_supplier_ambitions,
      }));

    const updatedAddOnDeals = allAddOnDeals
      .filter((item) =>
        confirm_supplier_ambitions
          ? item.id === deal_id
          : item.dataHasChanged === true
      )
      .map((item) => ({
        channel_id: item.channel_id,
        device_basket_id: +item.devices[0].deviceId,
        tariff_basket_id_24: item.termSpecific[0].tariff_basket_id,
        tariff_basket_id_30: item.termSpecific[1].tariff_basket_id,
        tariff_basket_id_36: item.termSpecific[2].tariff_basket_id,
        main_cid: item.cardGlobalLevel.main_cid,
        device_basket_items: [
          ...item.multiDevice,
          ...item.multiAccessory,
          ...item.multiRouter,
        ],
        main_tariff_code_24: item.termSpecific[0].tariff_code,
        main_tariff_code_30: item.termSpecific[1].tariff_code,
        main_tariff_code_36: item.termSpecific[2].tariff_code,
        tariff_basket_items_24: [...item.multiTariff],
        tariff_basket_items_30: [...item.multiTariff],
        tariff_basket_items_36: [...item.multiTariff],
        total_ctd: item.devices[0].currentDealCycle.ctd,
        supplier_ctd: item.cardGlobalLevel.supplier_ctd,
        deal_price_curr_24: item.devices[0].currentDealCycle.term24,
        deal_price_curr_30: item.devices[0].currentDealCycle.term30,
        deal_price_curr_36: item.devices[0].currentDealCycle.term36,
        supplier_deal_price_24: item.termSpecific[0].supplier_deal_price,
        supplier_deal_price_30: item.termSpecific[1].supplier_deal_price,
        supplier_deal_price_36: item.termSpecific[2].supplier_deal_price,
        confirm_supplier_ambitions: confirm_supplier_ambitions,
      }));

    if (updatedStandAloneDeals.length > 0) {
      HeadlineService.updateHeadlineDeals(cycle, updatedStandAloneDeals)
        .then(() => {
          setHeadlinesUpdated(headlinesUpdated + 1);
          handleGenericSnackOpen("success", "Headlines updated successfully.");
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    if (updatedAddOnDeals.length > 0) {
      HeadlineService.updateAddOnHeadlineDeals(cycle, updatedAddOnDeals)
        .then(() => {
          setHeadlinesUpdated(headlinesUpdated + 1);
          handleGenericSnackOpen(
            "success",
            "Headlines Add-Ons updated successfully."
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
  };

  // Handle add new deal
  const handleAddDeal = (type, data, addOn) => {
    if (addOn) {
      function hasValidTariffCode(obj) {
        for (let key in obj) {
          if (
            obj.hasOwnProperty(key) &&
            key.startsWith("tariffCode") &&
            obj[key] !== undefined
          ) {
            return true;
          }
        }
        return false;
      }

      if (!hasValidTariffCode(data)) {
        handleGenericSnackOpen("warning", "Please select a tariff!");
        return;
      }

      dispatch(
        addDeal({ type: type, data: buildNewDeal(data), newDevice: false })
      );
    } else {
      const deals = [
        {
          channel_id: data.channel_id,
          cid: data.device.value,
          router_cid: data.router?.value,
          tariff_code_24: data.tariffCode24,
          tariff_code_30: data.tariffCode30,
          tariff_code_36: data.tariffCode36,
          is_add_on: "N",
        },
      ];
      HeadlineService.createHeadlines(cycle, oem, deals)
        .then((response) => {
          const { data: newHeadline } = response;
          dispatch(
            addDeal({ type: type, data: newHeadline, newDevice: false })
          );
          handleGenericSnackOpen("success", "Deal successfully created.");
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
  };

  const handleDeleteDeal = (dealID, dealType, deal) => {
    HeadlineService.deleteHeadlineDeal(cycle, deal)
      .then(() => {
        dispatch(deleteDeal({ id: dealID, type: dealType }));
        handleGenericSnackOpen("success", "Deal deleted successfully.");
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const handleDeleteDealTerm = (dealID, dealType, deal) => {
    HeadlineService.deleteHeadlineDeal(cycle, deal)
      .then(() => {
        dispatch(
          deleteDealTerm({ id: dealID, type: dealType, term: deal.term })
        );
        handleGenericSnackOpen(
          "success",
          `Deal for term ${deal.term} deleted successfully.`
        );
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const [createButtonDisable, setCreateButtonDisable] = useState(false);
  const handleCreateAddOnDeal = ({ id, dealType }) => {
    console.log("createAddOnDeal", id, dealType);
    setCreateButtonDisable(true);
    const [dealGroup] = allDeals.filter((v) => v.group === dealType);
    const [deal] = dealGroup.deals.filter((v) => v.id === id);

    let main_tariff_code_24;
    try {
      main_tariff_code_24 = deal.termSpecific.filter((v) => v.term === 24)[0]
        .tariff_code;
    } catch (ex) {}
    let main_tariff_code_30;
    try {
      main_tariff_code_30 = deal.termSpecific.filter((v) => v.term === 30)[0]
        .tariff_code;
    } catch (ex) {}
    let main_tariff_code_36;
    try {
      main_tariff_code_36 = deal.termSpecific.filter((v) => v.term === 36)[0]
        .tariff_code;
    } catch (ex) {}
    const newAddOnDeal = {
      channel_id: deal.channel_id,
      main_cid: deal.cardGlobalLevel.main_cid,
      device_basket_items: [
        ...deal.multiDevice,
        ...deal.multiAccessory,
        ...deal.multiRouter,
      ],
      main_tariff_code_24: main_tariff_code_24,
      tariff_basket_items_24: [...deal.multiTariff],
      main_tariff_code_30: main_tariff_code_30,
      tariff_basket_items_30: [...deal.multiTariff],
      main_tariff_code_36: main_tariff_code_36,
      tariff_basket_items_36: [...deal.multiTariff],
    };

    if (
      newAddOnDeal.device_basket_items.length < 1 &&
      deal.multiTariff.length < 1
    ) {
      setCreateButtonDisable(false);
      handleGenericSnackOpen(
        "warning",
        "You need to add-on at least one add-on!"
      );
      return;
    }

    function hasTariffCodeProperty(array) {
      let tariff_count = 0;
      for (let item of array) {
        if (!item.hasOwnProperty("tariff_code")) {
          tariff_count++;
        }
      }
      return {
        allHaveTariffCode: tariff_count === 0,
        countNoTariffCode: tariff_count,
      };
    }

    function hasCidProperty(array) {
      let cid_count = 0;
      for (let item of array) {
        if (!item.hasOwnProperty("cid")) {
          cid_count++;
        }
      }
      return {
        allHaveCid: cid_count === 0,
        countNoCid: cid_count,
      };
    }

    let cid_result = "";
    if (newAddOnDeal.device_basket_items.length >= 1) {
      cid_result = hasCidProperty(newAddOnDeal.device_basket_items);
    }

    let tariff_result = "";
    if (newAddOnDeal.tariff_basket_items_24.length >= 1) {
      tariff_result = hasTariffCodeProperty(
        newAddOnDeal.tariff_basket_items_24
      );
      console.log(tariff_result);
    }

    // Check for empty add-ons
    if (cid_result.allHaveCid === false && cid_result.countNoCid !== 0) {
      setCreateButtonDisable(false);
      handleGenericSnackOpen(
        `warning`,
        `Please choose an add-on from the list after selecting one.`
      );
      return;
    }
    if (
      tariff_result.allHaveTariffCode === false &&
      tariff_result.countNoTariffCode !== 0
    ) {
      setCreateButtonDisable(false);
      handleGenericSnackOpen(
        `warning`,
        `Please choose an add-on from the list after selecting one.`
      );
      return;
    }

    HeadlineService.createHeadlinesAddOn(cycle, oem, [newAddOnDeal])
      .then((response) => {
        const { data: newHeadline } = response;

        // Remove dummy deal
        dispatch(deleteDeal({ type: dealType, id: id }));
        if (newHeadline) {
          // Add real deal
          dispatch(
            addDeal({ type: dealType, data: newHeadline, newDevice: false })
          );
          setCreateButtonDisable(false);
          handleGenericSnackOpen("success", "Deal successfully created.");
        } else {
          setCreateButtonDisable(false);
          handleGenericSnackOpen("warning", "Deal couldn't be created.");
        }
      })
      .catch((error) => {
        setCreateButtonDisable(false);
        handleServerError(error);
      });
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen(
        "warning",
        JSON.parse(errors[0].message).sqlMessage
      );
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // Category selection states and function
  const [category, setCategory] = useState(0);
  const handleCategoryChange = (_, category) => {
    setCategory(category);
  };

  // Save button fixed state
  const [saveButtonFixed, SetButtonFixed] = useState(false);
  const saveButtonRef = useRef();

  // Top Navigation Element (Used to add/remove classes)
  const topNavigation = document.querySelector("header nav");

  // Handle scroll check
  const handleScroll = () => {
    requestAnimationFrame(() => {
      const scrollY = window.scrollY;
      SetButtonFixed(
        scrollY >=
          saveButtonRef.current.parentNode.offsetTop +
            saveButtonRef.current.offsetHeight / 2
      );
    });
  };

  // Debouncing handleScroll to improve performance
  const debouncedScrollHandler = debounce(handleScroll, 100);

  // Check for scroll event
  useEffect(() => {
    window.addEventListener("scroll", debouncedScrollHandler);
    return () => {
      window.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, [debouncedScrollHandler]);

  // Hide top navigation when save button is fixed and show when it's not
  useEffect(() => {
    if (topNavigation) {
      if (saveButtonFixed) {
        topNavigation.classList.add("opacity-0");
        topNavigation.classList.remove("opacity-100");
      } else {
        topNavigation.classList.remove("opacity-0");
        topNavigation.classList.add("opacity-100");
      }
    }
  }, [saveButtonFixed, topNavigation]);

  const cbuTabList = [
    {
      label: "Generics",
      id: 0,
    },
    {
      label: "Direct",
      id: 1,
    },
    {
      label: "Dealers And Franchises",
      id: 2,
    },
    {
      label: "Nationals Chains",
      id: 3,
    },
    {
      label: "Online",
      id: 4,
    },
  ];

  const vbuTabList = [
    {
      label: "Generics",
      id: 0,
    },
    {
      label: "VB Online",
      id: 7,
    },
  ];

  return (
    <>
      <div className="col-start-1 col-end-13 row-start-2 mt-8 mb-4 px-6 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#selectedOEMTitle"
        className="col-start-1 col-end-13 row-start-3 px-6"
      >
        <TitleBreadcrumb
          title={oem}
          titleID="selectedOEMTitle"
          button={
            <Button
              ref={saveButtonRef}
              className={
                saveButtonFixed
                  ? "fixed top-5 right-6 z-40 mt-0"
                  : "mt-6 lg:mt-0"
              }
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={SaveIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              onClick={() => handleSave()}
            >
              Save
            </Button>
          }
        />

        <div className="my-6 text-center">
          <ToggleButtonGroup
            value={category}
            exclusive
            onChange={handleCategoryChange}
            aria-label="Category selection"
          >
            <ToggleButton value={0}>Standalone</ToggleButton>
            <ToggleButton value={1}>Add-On</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="my-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-6 lg:col-span-3">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: sortOrder || "",
                  onChange: handleSort,
                }}
              >
                <MenuItem value="" className="px-6">
                  - Select -
                </MenuItem>
                {sortingOptions.map((sort) => [
                  <ListSubheader
                    key={sort.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {sort.heading}
                  </ListSubheader>,
                  ...sort.options.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      className="px-12"
                    >
                      {item.label}
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-6 lg:col-span-3">
              <InputField
                select
                inputLabel="Filter by"
                value={filterBy}
                onChange={handleFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        {/* Standalone content */}
        <TabPanel value={category} index={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Deal types tabs"
            className="my-8"
          >
            {business_unit === "CBU"
              ? cbuTabList.map((tab) => {
                  return (
                    <Tab
                      label={tab.label}
                      {...a11yProps(tab.id)}
                      disableRipple
                    />
                  );
                })
              : vbuTabList.map((tab) => {
                  return (
                    <Tab
                      label={tab.label}
                      {...a11yProps(tab.id)}
                      disableRipple
                    />
                  );
                })}
            ,
            {/* <Tab label="Generics" {...a11yProps(0)} disableRipple />
            {business_unit === "CBU" ? (
              <>
                <Tab label="Direct" {...a11yProps(1)} disableRipple />
                <Tab
                  label="Dealers And Franchise"
                  {...a11yProps(2)}
                  disableRipple
                />
                <Tab label="National Chains" {...a11yProps(3)} disableRipple />
                <Tab label="Online" {...a11yProps(4)} disableRipple />
            </>
            ) : (<>
              <Tab label="VB Online" {...a11yProps(7)} disableRipple />
            </>)} */}
          </Tabs>

          <TabPanel value={value} index={0}>
            {/* Sticky element with totals (financial data) */}

            {!terminalsUser && (
              <DealCycleTotals
                totalCostToConnect={overviewTotals.totalCostToConnect}
                totalReducedSubscription={
                  overviewTotals.totalReducedSubscription
                }
                totalFinanced={overviewTotals.totalFinanced}
                totalEfficiency={overviewTotals.totalEfficiency}
                totalIntensity={overviewTotals.totalIntensity}
              />
            )}

            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {!terminalsUser && (
                <div className="col-span-1">
                  <CreateNewDealCard
                    oem={oem}
                    createDeal={(data) =>
                      handleAddDeal(
                        "headlineDeals",
                        { ...data, channel_id: 0 },
                        false
                      )
                    }
                    deviceOptions={[
                      ...deviceOptions,
                      ...accessoryOptions,
                      ...routerOptions.filter((v) => v.oem === oem),
                    ]}
                    tariffOptions={tariffOptions}
                    routerOptions={routerOptions}
                    messageFromActions={handleGenericSnackOpen}
                  />
                </div>
              )}
              {filteredDeals.find((deal) => deal.group === "bulk").deals
                .length > 0 ? (
                filteredDeals
                  .find((deal) => deal.group === "bulk")
                  .deals.map((device) => (
                    <div className="col-span-1" key={device.id}>
                      <HeadlineDealCard
                        data={device}
                        messageFromActions={handleGenericSnackOpen}
                        terminalsUser={terminalsUser}
                        dealType="headlineDeals"
                        deviceOptions={deviceOptions}
                        accessoryOptions={accessoryOptions}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        deleteDeal={handleDeleteDeal}
                        deleteDealTerm={handleDeleteDealTerm}
                        confirmSupplierAmbitions={(id) => handleSave(true, id)}
                        user={user}
                      />
                    </div>
                  ))
              ) : (
                <div className="col-span-1 py-4 text-center lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  No deals found...
                </div>
              )}
            </div>
          </TabPanel>
          {business_unit === "CBU" ? (
            <>
              <TabPanel value={value} index={1}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "direct")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="directDeals"
                          deviceOptions={deviceOptions}
                          accessoryOptions={accessoryOptions}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "directDeals",
                            { ...data, channel_id: 1 },
                            false
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "dealersAndFranchise")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="dealersAndFranchiseDeals"
                          deviceOptions={deviceOptions}
                          accessoryOptions={accessoryOptions}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "dealersAndFranchiseDeals",
                            { ...data, channel_id: 2 },
                            false
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "nationalChains")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="nationalChainsDeals"
                          deviceOptions={deviceOptions}
                          accessoryOptions={accessoryOptions}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "nationalChainsDeals",
                            { ...data, channel_id: 3 },
                            false
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={4}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "online")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="onlineDeals"
                          deviceOptions={deviceOptions}
                          accessoryOptions={accessoryOptions}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "onlineDeals",
                            { ...data, channel_id: 4 },
                            false
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
            </>
          ) : (
            <TabPanel value={value} index={1}>
              {/* Sticky element with totals (financial data) */}

              {!terminalsUser && (
                <DealCycleTotals
                  totalCostToConnect={overviewTotals.totalCostToConnect}
                  totalReducedSubscription={
                    overviewTotals.totalReducedSubscription
                  }
                  totalFinanced={overviewTotals.totalFinanced}
                  totalEfficiency={overviewTotals.totalEfficiency}
                  totalIntensity={overviewTotals.totalIntensity}
                />
              )}

              <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                {filteredDeals
                  .find((deal) => deal.group === "vbOnlineDeals")
                  .deals.map((device) => (
                    <div className="col-span-1" key={device.id}>
                      <HeadlineDealCard
                        data={device}
                        messageFromActions={handleGenericSnackOpen}
                        terminalsUser={terminalsUser}
                        dealType="vbOnlineDeals"
                        deviceOptions={deviceOptions}
                        accessoryOptions={accessoryOptions}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        deleteDeal={handleDeleteDeal}
                        deleteDealTerm={handleDeleteDealTerm}
                        confirmSupplierAmbitions={(id) => handleSave(true, id)}
                        user={user}
                      />
                    </div>
                  ))}
                {!terminalsUser && (
                  <div className="col-span-1">
                    <CreateNewDealCard
                      oem={oem}
                      createDeal={(data) =>
                        handleAddDeal(
                          "vbOnlineDeals",
                          { ...data, channel_id: 7 },
                          false
                        )
                      }
                      deviceOptions={[
                        ...deviceOptions,
                        ...accessoryOptions,
                        ...routerOptions.filter((v) => v.oem === oem),
                      ]}
                      tariffOptions={tariffOptions}
                      routerOptions={routerOptions}
                      messageFromActions={handleGenericSnackOpen}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
          )}
        </TabPanel>

        {/* Add-On content */}
        <TabPanel value={category} index={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Deal types tabs"
            className="my-8"
          >
            {business_unit === "CBU"
              ? cbuTabList.map((tab) => {
                  return (
                    <Tab
                      label={tab.label}
                      {...a11yProps(tab.id)}
                      disableRipple
                    />
                  );
                })
              : vbuTabList.map((tab) => {
                  return (
                    <Tab
                      label={tab.label}
                      {...a11yProps(tab.id)}
                      disableRipple
                    />
                  );
                })}
            ,
          </Tabs>

          <TabPanel value={value} index={0}>
            {/* Sticky element with totals (financial data) */}
            {!terminalsUser && (
              <DealCycleTotals
                totalCostToConnect={overviewTotals.totalCostToConnect}
                totalReducedSubscription={
                  overviewTotals.totalReducedSubscription
                }
                totalFinanced={overviewTotals.totalFinanced}
                totalEfficiency={overviewTotals.totalEfficiency}
                totalIntensity={overviewTotals.totalIntensity}
              />
            )}

            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              {filteredDeals
                .find((deal) => deal.group === "headlineAddonDeals")
                .deals.map((device) => (
                  <div className="col-span-1" key={device.id}>
                    <HeadlineDealCard
                      data={device}
                      messageFromActions={handleGenericSnackOpen}
                      terminalsUser={terminalsUser}
                      dealType="headlineAddonDeals"
                      addOns={true}
                      deviceOptions={allDevicesOptions.filter(
                        (v) => v.object_type === "D"
                      )}
                      accessoryOptions={allDevicesOptions.filter(
                        (v) => v.object_type === "A" || v.object_type === "G"
                      )}
                      tariffOptions={tariffOptions}
                      routerOptions={routerOptions}
                      deleteDeal={handleDeleteDeal}
                      deleteDealTerm={handleDeleteDealTerm}
                      createAddOnDeal={handleCreateAddOnDeal}
                      createButtonDisable={createButtonDisable}
                      confirmSupplierAmbitions={(id) => handleSave(true, id)}
                      user={user}
                    />
                  </div>
                ))}
              {!terminalsUser && (
                <div className="col-span-1">
                  <CreateNewDealCard
                    oem={oem}
                    createDeal={(data) =>
                      handleAddDeal(
                        "headlineAddonDeals",
                        { ...data, channel_id: 0 },
                        true
                      )
                    }
                    deviceOptions={[
                      ...deviceOptions,
                      ...accessoryOptions,
                      ...routerOptions.filter((v) => v.oem === oem),
                    ]}
                    tariffOptions={tariffOptions}
                    routerOptions={routerOptions}
                    addOn={true}
                    messageFromActions={handleGenericSnackOpen}
                  />
                </div>
              )}
            </div>
          </TabPanel>

          {business_unit === "CBU" ? (
            <>
              <TabPanel value={value} index={1}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "directAddonDeals")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="directAddonDeals"
                          addOns={true}
                          deviceOptions={allDevicesOptions.filter(
                            (v) => v.object_type === "D"
                          )}
                          accessoryOptions={allDevicesOptions.filter(
                            (v) =>
                              v.object_type === "A" || v.object_type === "G"
                          )}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          createAddOnDeal={handleCreateAddOnDeal}
                          createButtonDisable={createButtonDisable}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "directAddonDeals",
                            { ...data, channel_id: 1 },
                            true
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        addOn={true}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find(
                      (deal) => deal.group === "dealersAndFranchiseAddonDeals"
                    )
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="dealersAndFranchiseAddonDeals"
                          addOns={true}
                          deviceOptions={allDevicesOptions.filter(
                            (v) => v.object_type === "D"
                          )}
                          accessoryOptions={allDevicesOptions.filter(
                            (v) =>
                              v.object_type === "A" || v.object_type === "G"
                          )}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          createAddOnDeal={handleCreateAddOnDeal}
                          createButtonDisable={createButtonDisable}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "dealersAndFranchiseAddonDeals",
                            { ...data, channel_id: 2 },
                            true
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        addOn={true}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "nationalChainsAddonDeals")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="nationalChainsAddonDeals"
                          addOns={true}
                          deviceOptions={allDevicesOptions.filter(
                            (v) => v.object_type === "D"
                          )}
                          accessoryOptions={allDevicesOptions.filter(
                            (v) =>
                              v.object_type === "A" || v.object_type === "G"
                          )}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          createAddOnDeal={handleCreateAddOnDeal}
                          createButtonDisable={createButtonDisable}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "nationalChainsAddonDeals",
                            { ...data, channel_id: 3 },
                            true
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        addOn={true}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={4}>
                {/* Sticky element with totals (financial data) */}

                {!terminalsUser && (
                  <DealCycleTotals
                    totalCostToConnect={overviewTotals.totalCostToConnect}
                    totalReducedSubscription={
                      overviewTotals.totalReducedSubscription
                    }
                    totalFinanced={overviewTotals.totalFinanced}
                    totalEfficiency={overviewTotals.totalEfficiency}
                    totalIntensity={overviewTotals.totalIntensity}
                  />
                )}

                <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                  {filteredDeals
                    .find((deal) => deal.group === "onlineAddonDeals")
                    .deals.map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          terminalsUser={terminalsUser}
                          dealType="onlineAddonDeals"
                          addOns={true}
                          deviceOptions={allDevicesOptions.filter(
                            (v) => v.object_type === "D"
                          )}
                          accessoryOptions={allDevicesOptions.filter(
                            (v) =>
                              v.object_type === "A" || v.object_type === "G"
                          )}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                          createAddOnDeal={handleCreateAddOnDeal}
                          createButtonDisable={createButtonDisable}
                          confirmSupplierAmbitions={(id) =>
                            handleSave(true, id)
                          }
                          user={user}
                        />
                      </div>
                    ))}
                  {!terminalsUser && (
                    <div className="col-span-1">
                      <CreateNewDealCard
                        oem={oem}
                        createDeal={(data) =>
                          handleAddDeal(
                            "onlineAddonDeals",
                            { ...data, channel_id: 4 },
                            true
                          )
                        }
                        deviceOptions={[
                          ...deviceOptions,
                          ...accessoryOptions,
                          ...routerOptions.filter((v) => v.oem === oem),
                        ]}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        addOn={true}
                        messageFromActions={handleGenericSnackOpen}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
            </>
          ) : (
            <TabPanel value={value} index={1}>
              {/* Sticky element with totals (financial data) */}

              {!terminalsUser && (
                <DealCycleTotals
                  totalCostToConnect={overviewTotals.totalCostToConnect}
                  totalReducedSubscription={
                    overviewTotals.totalReducedSubscription
                  }
                  totalFinanced={overviewTotals.totalFinanced}
                  totalEfficiency={overviewTotals.totalEfficiency}
                  totalIntensity={overviewTotals.totalIntensity}
                />
              )}

              <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                {filteredDeals
                  .find((deal) => deal.group === "vbOnlineAddonDeals")
                  .deals.map((device) => (
                    <div className="col-span-1" key={device.id}>
                      <HeadlineDealCard
                        data={device}
                        messageFromActions={handleGenericSnackOpen}
                        terminalsUser={terminalsUser}
                        dealType="vbOnlineAddonDeals"
                        addOns={true}
                        deviceOptions={allDevicesOptions.filter(
                          (v) => v.object_type === "D"
                        )}
                        accessoryOptions={allDevicesOptions.filter(
                          (v) => v.object_type === "A" || v.object_type === "G"
                        )}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        deleteDeal={handleDeleteDeal}
                        deleteDealTerm={handleDeleteDealTerm}
                        createAddOnDeal={handleCreateAddOnDeal}
                        createButtonDisable={createButtonDisable}
                        confirmSupplierAmbitions={(id) => handleSave(true, id)}
                        user={user}
                      />
                    </div>
                  ))}
                {!terminalsUser && (
                  <div className="col-span-1">
                    <CreateNewDealCard
                      oem={oem}
                      createDeal={(data) =>
                        handleAddDeal(
                          "vbOnlineAddonDeals",
                          { ...data, channel_id: 7 },
                          true
                        )
                      }
                      deviceOptions={[
                        ...deviceOptions,
                        ...accessoryOptions,
                        ...routerOptions.filter((v) => v.oem === oem),
                      ]}
                      tariffOptions={tariffOptions}
                      routerOptions={routerOptions}
                      addOn={true}
                      messageFromActions={handleGenericSnackOpen}
                    />
                  </div>
                )}
              </div>
            </TabPanel>
          )}
        </TabPanel>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
