// React and MUI Imports
import { Fragment, useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Switch,
} from "@mui/material";

// Component Imports
import InputField from "../input/input-component";
import InlineSVG from "../inline-svg/inline-svg-component";
import SelectDropdownWithSearch from "../select-dropdown-with-search/select-dropdown-with-search-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";

export default function CreateNewDealCard(props) {
  const { createDeal, oem = "" } = props;

  const {
    deviceOptions,
    tariffOptions,
    routerOptions,
    oemOptions,
    oemDevices,
    addOn,
    isSIMOnly = false,
    messageFromActions,
  } = props;
  const headline = oem !== "";
  const buttonText = addOn ? "Build Base" : "Create New Deal";

  const [newDeal, setNewDeal] = useState(false);
  const [dataDeal, setDataDeal] = useState(false);

  const [selectedOEM, setSelectedOEM] = useState(oem);
  const [selectedRouter, setSelectedRouter] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [selectedTariff24, setSelectedTariff24] = useState("");
  const [selectedTariff30, setSelectedTariff30] = useState("");
  const [selectedTariff36, setSelectedTariff36] = useState("");
  const [showRouterInput, setShowRouterInput] = useState(false);

  const handleCreateNewDeal = () => {
    setNewDeal(true);
  };

  const handleDataDealSwitch = () => {
    setDataDeal(!dataDeal);
    setSelectedRouter("");
    setSelectedDevice("");
    setSelectedTariff24("");
    setSelectedTariff30("");
    setSelectedTariff36("");
    setShowRouterInput(false);
    setTerms([]);
  };

  const handleOnClick = () => {
    let device;

    if (isSIMOnly) {
      setSelectedOEM("SIM-ONLY");
      device = { label: "SIM-ONLY", value: 404 };
    } else {
      if (addOn) {
        if (oemDevices) {
          const _device = oemDevices
            .find((item) => item.oem === selectedOEM)
            .devices.find((v) => v.label === selectedDevice);
          device = _device;
        } else {
          const _device = deviceOptions.find((v) => v.label === selectedDevice);
          device = _device;
        }
      } else {
        const _device = deviceOptions.find((v) => v.label === selectedDevice);
        device = _device;
      }
    }
    const [router] =
      selectedRouter !== ""
        ? routerOptions.filter((v) => v.label === selectedRouter)
        : [];
    let tariffCode24;
    if (selectedTariff24 !== "") {
      tariffCode24 = tariffOptions.filter(
        (v) => v.label === selectedTariff24
      )[0].value;
    }
    let tariffCode30;
    if (selectedTariff30 !== "") {
      tariffCode30 = tariffOptions.filter(
        (v) => v.label === selectedTariff30
      )[0].value;
    }
    let tariffCode36;
    if (selectedTariff36 !== "") {
      tariffCode36 = tariffOptions.filter(
        (v) => v.label === selectedTariff36
      )[0].value;
    }

    let valid = false;
    let message = "";
    if (isSIMOnly) {
      valid = true;
    } else {
      // Do validation
      // 1. When creating a voice deal the following should be true:
      if (!dataDeal) {
        // -(A) Tariffs:
        // --tariff_type should be 'V'
        // -(B) Device:
        // --tariff_type should be 'V' and object_type should be 'D'
        // Combination that works:
        // -A + B
        if (device.tariff_type === "V" && device.object_type === "D") {
          valid = true;
        } else {
          message =
            "Selected Device: tariff_type should be 'V' or object_type should be 'D'";
        }
      }
      // 2. When creating a data deal the following should be true:
      else {
        // -(A) Tariffs:
        // --tariff_type should be 'D'
        // -(B1) Accessory:
        // --tariff_type should be 'DS' and object_type should be 'A'
        // -(B2) Device + Router:
        // --Device:
        // ---tariff_type should be 'DN'
        // --Router:
        // --a router should be selected
        // Combination that works:
        // -A + B1
        // -A + B2
        if (device.tariff_type === "DS" && device.object_type === "A") {
          valid = true;
        } else if (device.tariff_type === "DN" && router !== undefined) {
          valid = true;
        } else {
          message =
            "A router needs to be included if a device with tariff_type 'DN' is selected!";
        }
      }
    }

    if (valid) {
      createDeal({
        oem: selectedOEM,
        device,
        router,
        tariffCode24,
        tariffCode30,
        tariffCode36,
      });
      setNewDeal(false);
      // Clear selections
      setSelectedOEM(oem);
      setSelectedRouter("");
      setSelectedDevice("");
      setSelectedTariff24("");
      setSelectedTariff30("");
      setSelectedTariff36("");
      setTerms([]);
      setShowRouterInput(false);
    } else {
      messageFromActions("warning", message);
    }
  };

  const [showDeviceDropdown, setShowDeviceDropdown] = useState(false);

  useEffect(() => {
    if (selectedOEM !== "") {
      setShowDeviceDropdown(true);
    } else {
      setShowDeviceDropdown(false);
    }
  }, [selectedOEM]);

  const DeviceSelection = () => {
    return (
      <>
        <SelectDropdownWithSearch
          label="Select Device"
          selectedOption={selectedDevice}
          setSelectedOption={setSelectedDevice}
          options={deviceOptions.filter((v) =>
            dataDeal
              ? v.tariff_type === "DN" || v.tariff_type === "DS"
              : v.tariff_type === "V"
          )}
        />
      </>
    );
  };
  const OEMDeviceSelection = () => {
    return (
      <>
        <SelectDropdownWithSearch
          label="Select OEM"
          selectedOption={selectedOEM}
          setSelectedOption={setSelectedOEM}
          options={oemOptions}
        />
        {showDeviceDropdown && (
          <SelectDropdownWithSearch
            label="Select Device"
            selectedOption={selectedDevice}
            setSelectedOption={setSelectedDevice}
            options={
              oemDevices.find((item) => item.oem === selectedOEM).devices
            }
          />
        )}
      </>
    );
  };

  // Empty Term Data
  const [terms, setTerms] = useState([]);

  // Handle term removal on create new deal
  const handleTermDelete = (index) => {
    const termPeriod = terms[index].period;

    if (terms.length > 1) {
      const updatedTerms = terms.filter((_, i) => i !== index);
      setTerms(updatedTerms);

      messageFromActions(
        "success",
        `Term ${termPeriod !== "" ? termPeriod : ""} successfully removed`
      );
    } else {
      messageFromActions("error", "You cannot remove all the terms on a deal");
    }
  };

  // Handle add term on create new deal
  const handleAddTerm = () => {
    const newTerm = {
      period: "",
    };
    setTerms([...terms, newTerm]);
  };

  // Handle select term event and change the period
  const handleSelectTerm = (value, index) => {
    const updatedTerms = [...terms];
    updatedTerms[index].period = value;
    setTerms(updatedTerms);
  };

  useEffect(() => {
    if (selectedDevice) {
      if (isSIMOnly) {
        setShowRouterInput(false);
      } else {
        if (addOn) {
          if (oemDevices) {
            const _device = oemDevices
              .find((item) => item.oem === selectedOEM)
              .devices.find((v) => v.label === selectedDevice);

            if (_device) {
              if (_device.tariff_type === "DN") {
                setShowRouterInput(true);
              }
            } else {
              setShowRouterInput(false);
            }
          } else {
            const _device = deviceOptions.find(
              (v) => v.label === selectedDevice
            );
            if (_device) {
              if (_device.tariff_type === "DN") {
                setShowRouterInput(true);
              }
            } else {
              setShowRouterInput(false);
            }
          }
        } else {
          const _device = deviceOptions.find((v) => v.label === selectedDevice);
          if (_device) {
            if (_device.tariff_type === "DN") {
              setShowRouterInput(true);
            }
          } else {
            setShowRouterInput(false);
          }
        }
      }
    } else {
      setShowRouterInput(false);
    }
  }, [selectedDevice]);

  return newDeal ? (
    <div className="flex flex-col gap-4 rounded-lg bg-white p-12 shadow">
      <FormControlLabel
        className="ml-0 mb-2 self-start"
        control={
          <Switch
            color="primary"
            checked={dataDeal}
            onChange={handleDataDealSwitch}
          />
        }
        label="Data Deal"
        labelPlacement="start"
      />
      {isSIMOnly ? (
        <></>
      ) : (
        <>
          {dataDeal && showRouterInput ? (
            <>
              {headline ? <DeviceSelection /> : <OEMDeviceSelection />}
              <SelectDropdownWithSearch
                label="Select Router"
                selectedOption={selectedRouter}
                setSelectedOption={setSelectedRouter}
                options={routerOptions}
              />
            </>
          ) : headline ? (
            <DeviceSelection />
          ) : (
            <OEMDeviceSelection />
          )}
        </>
      )}

      {terms.map((term, index) => (
        <Fragment key={index}>
          <div className="flex items-center justify-center gap-x-2">
            <hr className="flex-1 border-grey-100" />
            <p className="text-base font-bold">
              {term.period !== "" ? `${term.period} Months` : "Term"}
            </p>
            <hr className="flex-1 border-grey-100" />
            <IconButton
              className={terms.length === 1 ? "fill-grey-300" : "fill-red-100"}
              aria-label={`Delete ${
                term.period !== null && `${term.period} Months`
              } Term`}
              onClick={() => handleTermDelete(index)}
              disabled={terms.length === 1}
            >
              <InlineSVG src={DeleteIcon} width={24} height={24} ariaHidden />
            </IconButton>
          </div>

          <InputField
            select
            inputLabel="Term"
            defaultValue={"placeholder"}
            value={term.period || "placeholder"}
            SelectProps={{
              IconComponent: (props) => <ArrowDropDownRoundedIcon {...props} />,
            }}
            onChange={(e) => handleSelectTerm(e.target.value, index)}
          >
            <MenuItem disabled value="placeholder">
              - Select -
            </MenuItem>
            {/* <MenuItem value="12" disabled={terms.some((term) => term.period === 12)}>12 Months</MenuItem> */}
            <MenuItem
              value="24"
              disabled={terms.some((term) => term.period === "24")}
            >
              24 Months
            </MenuItem>
            <MenuItem
              value="30"
              disabled={terms.some((term) => term.period === "30")}
            >
              30 Months
            </MenuItem>
            <MenuItem
              value="36"
              disabled={terms.some((term) => term.period === "36")}
            >
              36 Months
            </MenuItem>
          </InputField>

          {term.period === "24" ? (
            <SelectDropdownWithSearch
              label="Tariff"
              selectedOption={selectedTariff24}
              setSelectedOption={setSelectedTariff24}
              options={tariffOptions.filter((v) =>
                dataDeal ? v.tariff_type === "D" : v.tariff_type === "V"
              )}
            />
          ) : term.period === "30" ? (
            <SelectDropdownWithSearch
              label="Tariff"
              selectedOption={selectedTariff30}
              setSelectedOption={setSelectedTariff30}
              options={tariffOptions.filter((v) =>
                dataDeal ? v.tariff_type === "D" : v.tariff_type === "V"
              )}
            />
          ) : (
            <SelectDropdownWithSearch
              label="Tariff"
              selectedOption={selectedTariff36}
              setSelectedOption={setSelectedTariff36}
              options={tariffOptions.filter((v) =>
                dataDeal ? v.tariff_type === "D" : v.tariff_type === "V"
              )}
            />
          )}
        </Fragment>
      ))}

      <Button
        variant="text"
        disableFocusRipple
        className="mt-4 gap-1 fill-red-100 text-red-100 hover:text-red-100"
        startIcon={
          <InlineSVG src={PlusIcon} width={24} height={24} ariaHidden />
        }
        onClick={handleAddTerm}
        disabled={terms.length === 3}
      >
        Add Term
      </Button>

      {/* <SelectDropdownWithSearch
        label="36 Month Tariff"
        selectedOption={selectedTariff36}
        setSelectedOption={setSelectedTariff36}
        options={tariffOptions.filter((v) =>
          dataDeal ? v.tariff_type === "D" : v.tariff_type === "V"
        )}
      /> */}

      <Button
        variant="contained"
        disableFocusRipple
        className="gap-2 fill-white"
        startIcon={
          <InlineSVG src={PlusIcon} width={24} height={24} ariaHidden />
        }
        onClick={handleOnClick}
        disabled={terms.length === 0}
      >
        {buttonText}
      </Button>
    </div>
  ) : (
    <div className="flex h-full min-h-[600px] w-full items-center justify-center rounded-lg border-2 border-dashed border-grey-500 bg-grey-50 p-16">
      <Button
        variant="contained"
        disableFocusRipple
        className="mt-4 gap-2"
        startIcon={
          <InlineSVG
            src={PlusIcon}
            width={24}
            height={24}
            ariaHidden
            className="fill-white"
          />
        }
        onClick={handleCreateNewDeal}
      >
        Create New Deal
      </Button>
    </div>
  );
}
