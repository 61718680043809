// React and Mui Imports
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

// Service Imports
import { socket } from "../../App";
import AuthService from "../../services/auth.service";

export default function ConcurrentSessionHandler() {
  const user = AuthService.getCurrentUser();

  if (user === null) {
    window.location.href = "/logout";
  }

  const { user_id } = user;

  useEffect(() => {
    socket.on("force_logout", (data) => {
      // Clear local storage or any client-side session data
      if (user_id === data.user_id) {
        window.location.href = "/concurrent";
      }
    });

    return () => {
      socket.off("force_logout");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to emit a socket event to connect user_id with socket
  useEffect(() => {
    socket.emit("user_connect", user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
