import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearBulkDeals, clearHeadlineDeals } from "../../slices/dealCanvas";

// Component Imports
import { IconButton } from "@mui/material";
import InlineSVG from "../inline-svg/inline-svg-component";

// Asset Imports
import GenericLogo from "../../assets/logos/Generic-Logo.svg";
import OpenLockIcon from "../../assets/icons/open-lock-icon.svg";
import LockIcon from "../../assets/icons/lock-icon.svg";

export default function OEMSelectCard({ logo, alt, oemPath, showLock, lockState, onLockStateChange }) {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(clearHeadlineDeals());
    dispatch(clearBulkDeals());
  }

  const handleLockToggle = () => {
    const newLockState = !lockState;
    onLockStateChange(newLockState);
  }

  return (
    <div className="flex flex-col rounded-2xl bg-white text-center shadow">
      {showLock &&
        <IconButton aria-label={`${lockState ? 'Unlock' : 'Lock'} OEM`} onClick={handleLockToggle} className="self-end mr-3 mt-3">
          <InlineSVG src={lockState ? LockIcon : OpenLockIcon} width={16} height={16} ariaHidden className="fill-grey-400" />
        </IconButton>
      }
      {logo !== null ? (
        <div className={`flex flex-col items-center justify-center h-40 w-full px-6 ${showLock ? '-mt-7' : 'mt-0'}`}>
          <img src={logo} alt={alt} />
        </div>
      ) : (
        <div className={`flex flex-col items-center justify-center h-40 w-full px-6 ${showLock ? '-mt-7' : 'mt-0'}`}>
          <img src={GenericLogo} alt="Generic OEM Logo" />
          <p className="font-bold text-xl mt-2">{oemPath}</p>
        </div>
      )}
      <hr className="mt-auto border-[#D9D9D9]" />
      {showLock ? (
        lockState ? (<span className="py-3 text-base font-bold text-grey-300">Select OEM</span>) : (
          <Link
            to={oemPath}
            className="py-3 text-base font-bold text-black hover:text-grey-700 hover:underline"
            onClick={handleOnClick}        
          >
            Select OEM
          </Link>
        )
      ) : (
        <Link
          to={oemPath}
          className="py-3 text-base font-bold text-black hover:text-grey-700 hover:underline"
          onClick={handleOnClick}        
        >
          Select OEM
        </Link>
      )}
    </div>
  );
}
