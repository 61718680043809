// React and Mui Imports
import { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../../components/back-button/back-button-component";
import OEMSelectCard from "../../../components/oem-select-card/oem-select-card-component";

// Mock Data Import
import { OEMData } from "../../../data/mock-data";
import DealService from "../../../services/deal.service";
import AuthService from "../../../services/auth.service";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useNavigate, useParams } from "react-router-dom";

// OEM Sorting options
const sortingOptions = [
  {
    value: "default",
    label: "Default",
  },
  {
    value: "asc",
    label: "A - Z",
  },
  {
    value: "desc",
    label: "Z - A",
  },
];

const _totalDeals = {
  standalone: 0,
  accessory: 0,
  secondaryDevice: 0,
  flagged: 0,
};

// Section at the top of the content with generated deals data
export function BaseGenericGeneratedDealSummary({
  standalone,
  accessory,
  secondary,
  flagged,
}) {
  return (
    <div className="flex flex-wrap items-center gap-2 rounded-lg bg-white py-[18px] px-6">
      <div className="flex items-center gap-2">
        <span className="font-bold text-red-100">Total Standalone Deals:</span>
        <span>{standalone}</span>
      </div>
      <div className="mx-auto w-[1px] self-stretch bg-grey-50 py-[18px]"></div>
      <div className="flex items-center gap-2">
        <span className="font-bold text-red-100">Total Accessory Deals:</span>
        <span>{accessory}</span>
      </div>
      <div className="mx-auto w-[1px] self-stretch bg-grey-50 py-[18px]"></div>
      <div className="flex items-center gap-2">
        <span className="font-bold text-red-100">
          Total Secondary Device Deals:
        </span>
        <span>{secondary}</span>
      </div>
      <div className="mx-auto w-[1px] self-stretch bg-grey-50 py-[18px]"></div>
      <div className="flex items-center gap-2">
        <span className="font-bold text-red-100">Flagged Deals:</span>
        <span>{flagged}</span>
      </div>
    </div>
  );
}

export default function BaseGenericOEMSelection() {
  const { cycle } = useParams();
  const [sortedOEMData, setSortedOEMData] = useState(OEMData);
  const [sortOrder, setSortOrder] = useState("default");
  const navigate = useNavigate();
  const [totalDeals, setTotalDeals] = useState(_totalDeals);

  const handleSort = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);

    const sortedData = [...OEMData].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (newSortOrder === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    if (newSortOrder === "default") {
      setSortedOEMData(OEMData);
    } else {
      setSortedOEMData(sortedData);
    }
  };

  useEffect(() => {
    DealService.getTotalDealsBaseGenerics(cycle)
      .then((response) => {
        setTotalDeals(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-2 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#bulkOEMSelectionTitle"
        className="col-start-2 col-end-12 row-start-3"
      >
        <TitleBreadcrumb
          title="OEM Selection"
          titleID="bulkOEMSelectionTitle"
        />

        <div className="my-8">
          <BaseGenericGeneratedDealSummary
            standalone={totalDeals.standalone}
            accessory={totalDeals.accessory}
            secondary={totalDeals.secondaryDevice}
            flagged={totalDeals.flagged}
          />
        </div>

        <div className="mt-8 mb-12 grid grid-cols-10 gap-6">
          <div className="col-span-10 row-start-1 md:col-span-5 2xl:col-span-2 2xl:col-start-2">
            <InputField
              select
              inputLabel="Sort by"
              id="sort-by"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
                value: sortOrder,
                onChange: handleSort,
              }}
            >
              {sortingOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
          </div>
          <div className="col-span-10 row-start-2 grid grid-cols-8 gap-6 2xl:col-span-8 2xl:col-start-2">
            {sortedOEMData.map((card) => (
              <div className="col-span-4 md:col-span-2" key={card.id}>
                <OEMSelectCard
                  logo={require(`../../../${card.logo}`)}
                  alt={`${card.name} logo`}
                  oemPath={card.name}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
