import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackbar: {
    open: false,
    type: "",
    message: ""
  }
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      const { type, message } = action.payload;

      state.message = message;
      state.type = type;
      state.open = true;
    },
    closeSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

// Selectors
export const getSnackbarData = createSelector(
  (state) => state.snackbar,
  (snackbar) => snackbar
);

export default snackbarSlice.reducer;
