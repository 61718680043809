// React and MUI Imports
import React, { useEffect, useState } from "react";
import { FormControlLabel, IconButton, MenuItem, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Redux Imports
import { useDispatch } from "react-redux";
import { removeDealComponent, updateAdHocDeal } from "../../slices/dealCanvas";

// Masking Values Imports
import { useIMask } from "react-imask";
import { maskOptions } from "../headline-deal-info/headline-deal-info-component";

// Component Imports
import InputField from "../input/input-component";
import SelectDropdownWithSearch from "../select-dropdown-with-search/select-dropdown-with-search-component";
import CustomDatePicker from "../datepicker/datepicker-component";
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import DeleteIcon from "../../assets/icons/delete-icon.svg";

// Mock Data
// import {
// dealTypes,
// oemDevices,
// oemOptions,
// channels,
//tariffOptions,
// } from "../../data/mock-data";
import { useParams } from "react-router-dom";
import AdHocService from "../../services/adhoc.service";
import AuthService from "../../services/auth.service";
import ChannelService from "../../services/channel.service";
import GeneralService from "../../services/general.service";
import { deviceOptions } from "../../data/mock-data";

// Set DEFAULT terms for "New deal" cards
const termsDefault = [
  // { period: 12 },
  { period: 24 },
  { period: 30 },
  { period: 36 },
];
export default function AdHocDealInfo(props) {
  const { dealset, campaign_id } = useParams();

  const [channels, setChannels] = useState(
    props?.channels ? props.channels : []
  );
  const [oemOptions, setOEMOptions] = useState(
    props?.oemOptions ? props.oemOptions : []
  );
  const [oemDevices, setOEMDevices] = useState(
    props?.oemDevices ? props.oemDevices : []
  );
  const [dealTypes, setDealTypes] = useState(
    props?.dealTypes ? props.dealTypes : []
  );

  const [briefTypeOptions, setBriefTypeOptions] = useState(
    props?.briefTypeOptions ? props.briefTypeOptions : []
  );

  const [dealerQualificationOptions, setDealerQualificationOptions] = useState(
    props?.dealerQualificationOptions ? props.dealerQualificationOptions : []
  );

  // TODO: Fetch from API once available - Hard coded for now
  const [dealSheetTypeOptions, setDealSheetTypeOptions] = useState([
    {
      value: "N",
      label: "New Line",
    },
    {
      value: "U",
      label: "Upgrade",
    },
    // {
    //   value: "P",
    //   label: "Prepaid",
    // },
    {
      value: "A",
      label: "All",
    },
  ]);

  const {
    id,
    //terms = termsDefault,
    dealTypeId,
    dealStartDate = null,
    dealEndDate = null,
    snackbarMessages,
    showSimOption = true,
    dealType,
    dealsetID,
    tariffOptions,
    newDealFuncs,
    iSIMOnly = false,
    deleteDealTerm,
    vasOptions,
    dealer_qualification,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();

  if (user === null) {
    AuthService.logout();
    navigate("/logout");
  }

  const { business_unit } = user;

  const [campaignCycle, setCampaignCycle] = useState("none");
  const [campaignLoaded, setCampaignLoaded] = useState(0);

  useEffect(() => {
    AdHocService.getCycleFromCampaign(campaign_id).then((response) => {
      setCampaignCycle(response.data);
      setCampaignLoaded(campaignLoaded + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [terms, setTerms] = useState(props.terms ? props.terms : termsDefault);

  const { dealPrice: _dealPrice24 } =
    terms?.find((term) => {
      const { period } = term;
      return period === 24;
    }) || {};
  const { dealPrice: _dealPrice30 } =
    terms?.find((term) => {
      const { period } = term;
      return period === 30;
    }) || {};
  const { dealPrice: _dealPrice36 } =
    terms?.find((term) => {
      const { period } = term;
      return period === 36;
    }) || {};

  const [CTD, setCTD] = useState(props.total_ctd);
  const [dealPrice24, setDealPrice24] = useState(_dealPrice24 || 0);
  const [dealPrice30, setDealPrice30] = useState(_dealPrice30 || 0);
  const [dealPrice36, setDealPrice36] = useState(_dealPrice36 || 0);

  const { ref: refCTD } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCTD(value);
    },
  });
  const { ref: refDealPrice24 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setDealPrice24(value);
    },
  });
  const { ref: refDealPrice30 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setDealPrice30(value);
    },
  });
  const { ref: refDealPrice36 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setDealPrice36(value);
    },
  });

  useEffect(() => {
    if (!props.createDealForm) {
      setTerms(props.terms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.terms]);

  // Sim Only States
  const [simOnly, setSimOnly] = useState(iSIMOnly);
  const handleSimOnlyChange = () => {
    setSimOnly(!simOnly);
    newDealFuncs?.handleIsSIMOnlyChange(!simOnly);
  };

  const [selectedChannel, setSelectedChannel] = useState(
    props?.selectedChannel ? props.selectedChannel : ""
  );

  // Selected OEM and Devices from Searchable Dropdown Component
  const [selectedOEM, setSelectedOEM] = useState(
    props?.selectedOEM ? props.selectedOEM : ""
  );

  // dealInfo.device.oem
  const [selectedDevice, setSelectedDevice] = useState(
    props?.selectedDevice ? props.selectedDevice : ""
  );

  const [selectedDealType, setSelectedDealType] = useState(
    props?.selectedDealType ? props.selectedDealType : ""
  );

  const [selectedTariff24, setSelectedTariff24] = useState(
    props?.selectedTariff24 ? props?.selectedTariff24 : ""
  );

  const [selectedTariff36, setSelectedTariff36] = useState(
    props?.selectedTariff36 ? props?.selectedTariff36 : ""
  );

  const [selectedBriefType, setSelectedBriefType] = useState(
    props?.selectedBriefType ? props?.selectedBriefType : ""
  );

  const [selectedDealerQualification, setSelectedDealerQualification] =
    useState(
      props?.selectedDealerQualification
        ? props?.selectedDealerQualification
        : ""
    );

  // const [selectedDealSheetType, setSelectedDealSheetType] = useState(
  //   props?.selectedDealSheetType ? props?.selectedDealSheetType : ""
  // );

  const [selectedDeviceTariffType, setSelectedDeviceTariffType] = useState(
    oemDevices.length
      ? oemDevices
          .find((item) => item.oem === selectedOEM)
          ?.devices.find((device) => device.label === selectedDevice)
          ?.tariff_type || ""
      : ""
  );
  useEffect(() => {
    if (oemDevices.length && selectedDevice) {
      setSelectedDeviceTariffType(
        oemDevices
          .find((item) => item.oem === selectedOEM)
          .devices.find((device) => device.label === selectedDevice)
          ?.tariff_type || ""
      );
    }
  }, [selectedDeviceTariffType, deviceOptions, selectedDevice]);
  // Masking money values in Inputs
  const { ref: refMonthDealPrice } = useIMask(maskOptions);

  useEffect(() => {
    if (newDealFuncs?.handleMainCIDChange) {
      newDealFuncs?.handleMainCIDChange(selectedDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  useEffect(() => {
    if (newDealFuncs?.handleDealerQualificationChange) {
      newDealFuncs?.handleDealerQualificationChange(
        selectedDealerQualification
      );
    }
  }, [selectedDealerQualification]);

  const getChannelOptions = async () => {
    AdHocService.getAdhocDealsChannels()
      .then((response) => {
        props.newDealFuncs?.handleChannelOptionsChange(response.data);
        setChannels(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getBriefTypeOptions = async () => {
    GeneralService.getBriefTypes()
      .then((response) => {
        props.newDealFuncs?.handleBriefTypeOptionsChange(response.data);
        setBriefTypeOptions(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getDealerQualificationOptions = async (briefType) => {
    GeneralService.getDealerQualifications(briefType)
      .then((response) => {
        if (props.createDealForm) {
          props.newDealFuncs?.handleDealerQualificationOptionsChange(
            response.data
          );
        }
        setDealerQualificationOptions(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getDealTypes = async () => {
    GeneralService.getDealTypes()
      .then((response) => {
        props.newDealFuncs?.handleDealTypeOptionsChange(response.data);
        setDealTypes(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getOEMOptions = async (channel_id) => {
    const cycle = campaignCycle;

    if (campaignLoaded !== 0) {
      ChannelService.getChannelOEMDevices(cycle, channel_id)
        .then((response) => {
          const { data } = response;

          const listA = data.reduce((result, item) => {
            const oemIndex = result.findIndex((el) => el.oem === item.oem);

            if (item.tariff_type !== "G") {
              if (oemIndex === -1) {
                // If the oem doesn't exist in listA, create a new oem object.
                result.push({
                  oem: item.oem,
                  devices: [
                    {
                      value: item.cid,
                      label: item.model,
                      tariff_type: item.tariff_type,
                    },
                  ],
                });
              } else {
                // If the oem already exists in listA, push the option to its options array.
                result[oemIndex].devices.push({
                  value: item.cid,
                  label: item.model,
                  tariff_type: item.tariff_type,
                });
              }
            }

            return result;
          }, []);

          props.newDealFuncs?.handleOemDeviceOptionsChange(listA);
          setOEMDevices(listA);

          const listB = [...new Set(data.map((item) => item.oem))].map(
            (oem) => ({
              value: oem,
              label: oem,
            })
          );

          props.newDealFuncs?.handleOemOptionsChange(listB);
          setOEMOptions(listB);
        })
        .catch((error) => {
          // handleServerError(error);
          const status = error.response.status;
          if (status === 401) {
            AuthService.logout();
            navigate("/logout");
          } else if (status === 403) {
            navigate("/forbidden");
          } else {
            // Handle Error
            navigate("/error");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Get channel options
  useEffect(() => {
    if (props.createDealForm) {
      getChannelOptions();
      getDealTypes();
      getBriefTypeOptions();
    }
  }, []);

  useEffect(() => {
    if (channels.length > 0 && selectedChannel !== "" && props.createDealForm) {
      const channel = channels.find((channel) => {
        return channel.label === selectedChannel;
      });
      getOEMOptions(channel.value);
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (briefTypeOptions.length > 0 && selectedBriefType !== "") {
      getDealerQualificationOptions(selectedBriefType);
    }
  }, [selectedBriefType]);

  // Masking money values in Inputs
  //const { ref: refMonthDealPrice } = useIMask(maskOptions);

  // Handle Remove Component
  const handleRemoveComponent = (id, dealType, component, index, subDealID) => {
    dispatch(
      removeDealComponent({
        id: id,
        type: dealType,
        component: component,
        index: index,
        subDealID: subDealID,
      })
    );
  };

  // Handle term removal on new deals
  const handleTermDelete = (index) => {
    const termPeriod = terms[index].period;

    if (terms.length > 1) {
      dealset
        ? handleRemoveComponent(dealsetID, dealType, "terms", index, id)
        : terms.splice(index, 1);
      snackbarMessages("success", `Term ${termPeriod} successfully removed`);
    } else {
      snackbarMessages("error", "You cannot remove all the terms on a deal");
    }
  };

  const handleDeleteDealTerm = (id, term, dde_parent_key) => {
    if (!props.createDealForm) {
      deleteDealTerm(id, dealType, { term, dde_parent_key });
    } else {
      setTerms(terms.filter((v) => v.period !== term));
      props.newDealFuncs?.handleRemoveTerm(term);
    }
  };

  const handleChannelChange = (e) => {
    const { value } = e.target;
    setSelectedChannel(value);
    if (!props.createDealForm) {
      const channel = channels.find((v) => v.label === value);
      dispatch(
        updateAdHocDeal({ id, type: dealType, channel_id: channel.value })
      );
    } else {
      newDealFuncs?.handleChannelIDChange(e);
    }
  };

  const handleDealerQualificationChange = (value) => {
    setSelectedDealerQualification(value);
    if (!props.createDealForm) {
      const dealerQualification = dealerQualificationOptions.find(
        (v) => v.label === value
      );
      dispatch(
        updateAdHocDeal({
          id,
          type: dealType,
          dealer_qualification: dealerQualification.value,
        })
      );
    }
  };

  const handleDealsheetTypeChange = (e) => {
    const { value } = e.target;
    if (!props.createDealForm) {
      dispatch(
        updateAdHocDeal({
          id,
          type: dealType,
          dealsheet_type: value,
        })
      );
    } else {
      newDealFuncs?.handleDealSheetTypeChange(e);
    }
  };

  const handleDealTypeChange = (e) => {
    const { value } = e.target;
    setSelectedDealType(value);
    if (!props.createDealForm) {
      const item = dealTypes.find((v) => v.label === value);
      dispatch(
        updateAdHocDeal({
          id,
          type: dealType,
          deal_type_id: item.value,
        })
      );
    } else {
      newDealFuncs?.handleDealTypeChange(e);
    }
  };

  const handleCTDChange = (e) => {
    let { value } = e.target;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    if (!props.createDealForm) {
      dispatch(
        updateAdHocDeal({
          id,
          type: dealType,
          total_ctd: value,
        })
      );
    }
  };

  const handleTariffChange = (term, e) => {
    const { value } = e.target;
    if (!props.createDealForm) {
      dispatch(
        updateAdHocDeal({
          id,
          type: dealType,
          termUpdate: true,
          tariff: {
            term: term,
            value: value,
          },
        })
      );
    } else {
      newDealFuncs?.handleTariffTermsChange({ term, e });
    }
  };

  const handleDealPriceChange = (term, e) => {
    let { value } = e.target;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    if (!props.createDealForm) {
      dispatch(
        updateAdHocDeal({
          id,
          type: dealType,
          termUpdate: true,
          dealPrice: {
            term: term,
            value: value,
          },
        })
      );
    } else {
      //newDealFuncs?.handleDealPriceChange({ term, e });
      if (term === 24) {
        setDealPrice24(e.target.value);
      } else if (term === 30) {
        setDealPrice30(e.target.value);
      } else if (term === 36) {
        setDealPrice36(e.target.value);
      }
    }
  };

  const handleStartDateChange = (date) => {
    const isValidDate = !isNaN(new Date(date));

    if (isValidDate === false) {
      return;
    } else {
      const currentStartDate = new Date(dealStartDate);
      const newStartDate = new Date(date);

      const currentStartDateString = currentStartDate
        .toISOString()
        .slice(0, 10);
      const newStartDateString = newStartDate.toISOString().slice(0, 10);

      if (
        !props.createDealForm &&
        !(currentStartDateString === newStartDateString)
      ) {
        dispatch(
          updateAdHocDeal({
            id,
            type: dealType,
            dealStartDate: newStartDateString,
          })
        );
      } else {
        newDealFuncs?.handleStartDateChange(date);
      }
    }
  };
  const handleEndDateChange = (date) => {
    const isValidDate = !isNaN(new Date(date));

    if (isValidDate === false) {
      return;
    } else {
      const currentEndDate = new Date(dealEndDate);
      const newEndDate = new Date(date);

      const currentEndDateString = currentEndDate.toISOString().slice(0, 10);
      const newEndDateString = newEndDate.toISOString().slice(0, 10);

      if (
        !props.createDealForm &&
        !(currentEndDateString === newEndDateString)
      ) {
        dispatch(
          updateAdHocDeal({
            id,
            type: dealType,
            dealEndDate: newEndDateString,
          })
        );
      } else {
        newDealFuncs?.handleEndDateChange(date);
      }
    }
  };

  const handleVASBasketItemsChange = (term, vas) => {
    const { vas_basket_items } = terms.find((v) => v.period === term);
    const updatedVASBasketItems = vas_basket_items.map((item) => {
      if (item.id === vas.id) {
        return { ...item, ...vas };
      }
      return item;
    });
    dispatch(
      updateAdHocDeal({
        id,
        type: dealType,
        termUpdate: true,
        vas_basket_items: {
          term: term,
          value: updatedVASBasketItems,
        },
      })
    );
  };

  const handleTermChange = (_id, e) => {
    const { value } = e.target;
    dispatch(
      updateAdHocDeal({
        id,
        type: dealType,
        termUpdate: true,
        period: {
          id: _id,
          term: +value,
          value: +value,
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {showSimOption && (
        <FormControlLabel
          className="ml-0 mb-2 self-start"
          control={
            <Switch
              color="primary"
              checked={simOnly}
              onChange={() => {
                handleSimOnlyChange();
              }}
            />
          }
          label="SIM only"
          labelPlacement="start"
        />
      )}

      <InputField
        select
        inputLabel="Channel"
        defaultValue={selectedChannel || "placeholder"}
        SelectProps={{
          IconComponent: (props) => <ArrowDropDownRoundedIcon {...props} />,
        }}
        onChange={handleChannelChange}
        inputProps={{
          readOnly: !props.createDealForm,
        }}
      >
        <MenuItem disabled value="placeholder">
          - Select -
        </MenuItem>
        {channels.map((option) => (
          <MenuItem key={option.value} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </InputField>

      {selectedChannel !== "All" && (
        <>
          <InputField
            select
            inputLabel="Campaign Dealset Type"
            value={selectedBriefType || "placeholder"}
            SelectProps={{
              IconComponent: (props) => <ArrowDropDownRoundedIcon {...props} />,
            }}
            onChange={(e) => {
              setSelectedBriefType(e.target.value);
              // newDealFuncs?.handleBriefChange(e);
            }}
          >
            <MenuItem disabled value="placeholder">
              - Select -
            </MenuItem>
            {briefTypeOptions.map((briefType) => (
              <MenuItem key={briefType} value={briefType}>
                {briefType}
              </MenuItem>
            ))}
          </InputField>

          {selectedBriefType === "" ? (
            <>
              <InputField
                inputLabel="Dealer Qualification"
                value={dealer_qualification}
                inputProps={{
                  readOnly: true,
                }}
              />
            </>
          ) : (
            <>
              <SelectDropdownWithSearch
                label="Dealer Qualification"
                selectedOption={selectedDealerQualification}
                setSelectedOption={handleDealerQualificationChange}
                options={
                  selectedBriefType !== "" ? dealerQualificationOptions : [""]
                }
              />
            </>
          )}
        </>
      )}

      <InputField
        select
        inputLabel="Deal Sheet Type"
        defaultValue={props.selectedDealSheetType || "placeholder"}
        SelectProps={{
          IconComponent: (props) => <ArrowDropDownRoundedIcon {...props} />,
        }}
        onChange={handleDealsheetTypeChange}
        inputProps={{
          readOnly: !props.createDealForm,
        }}
      >
        <MenuItem disabled value="placeholder">
          - Select -
        </MenuItem>
        {dealSheetTypeOptions.map((option) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </InputField>
      {business_unit === "CBU" && (
        <>
          {!props.createDealForm ? (
            <>
              <InputField
                inputLabel="Deal Type"
                value={selectedDealType}
                inputProps={{
                  readOnly: true,
                }}
              />
            </>
          ) : (
            <>
              <InputField
                select
                inputLabel="Deal Type"
                defaultValue={selectedDealType || "placeholder"}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                }}
                onChange={handleDealTypeChange}
              >
                <MenuItem disabled value="placeholder">
                  - Select -
                </MenuItem>
                {dealTypes.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </>
          )}
        </>
      )}

      {/* {!props.createDealForm && (
        <div className="mt-4 flex gap-6">
          <InputField
            inputLabel="CTD"
            // inputRef={refSupplierCtd}
            placeholder="R 0,00"
            //value={supplierAmbitions.ctd || ""}
            // onBlur={(event) => handleSupplierCtdChange(event, id, dealType)}
          />
          <InputField
            inputLabel="Channel Support Chargable"
            // inputRef={refSupplierCtd}
            placeholder="R 0,00"
            //value={supplierAmbitions.ctd || ""}
            // onBlur={(event) => handleSupplierCtdChange(event, id, dealType)}
          />
        </div>
      )} */}

      {!simOnly && props.createDealForm && (
        <>
          <div className="flex items-center justify-center gap-x-2">
            <hr className="flex-1 border-grey-100" />
            <p className="text-base font-bold">Device Details</p>
            <hr className="flex-1 border-grey-100" />
          </div>

          <SelectDropdownWithSearch
            label="OEM"
            selectedOption={selectedOEM}
            setSelectedOption={setSelectedOEM}
            options={oemOptions}
            disabled={selectedChannel === ""}
          />

          <SelectDropdownWithSearch
            label="Device"
            selectedOption={selectedDevice}
            setSelectedOption={setSelectedDevice}
            disabled={selectedOEM === ""}
            options={
              selectedOEM !== ""
                ? oemDevices.find((item) => item.oem === selectedOEM).devices
                : [""]
            }
          />
        </>
      )}

      {!props.createDealForm && (
        <>
          <InputField
            inputLabel="CTD"
            inputRef={refCTD}
            placeholder="R 0,00"
            value={CTD}
            onBlur={handleCTDChange}
          />
        </>
      )}

      {terms?.map((term, index) => {
        const {
          id: termID,
          dealsheet_no,
          deal_description,
          vas_basket_items,
          linked_deal_price,
          linked_dealsheet_no,
        } = term;

        return (
          <React.Fragment key={index}>
            {term.period && !termID ? (
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold">{term.period} Months</p>
                <hr className="flex-1 border-grey-100" />
                {terms.length > 1 && (
                  <IconButton
                    className="fill-red-100"
                    aria-label="Delete 12 Month Term"
                    onClick={() =>
                      handleDeleteDealTerm(id, term.period, term.dde_parent_key)
                    }
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                )}
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center gap-x-2">
                  <hr className="flex-1 border-grey-100" />
                  <p className="text-base font-bold">Term</p>
                  <hr className="flex-1 border-grey-100" />
                  {terms.length > 1 && (
                    <IconButton
                      className="fill-red-100"
                      aria-label="Delete Term Period"
                      onClick={() => handleTermDelete(index)}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                  )}
                </div>

                <InputField
                  select
                  inputLabel="Term"
                  defaultValue="placeholder"
                  SelectProps={{
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                  }}
                  onChange={(e) => {
                    const { id } = term;
                    handleTermChange(id, e);
                  }}
                >
                  <MenuItem disabled value="placeholder">
                    - Select -
                  </MenuItem>
                  {/* <MenuItem value="12" disabled={terms.some((term) => term.period === 12)}>12 Months</MenuItem> */}
                  <MenuItem
                    value="24"
                    disabled={terms.some((term) => term.period === 24)}
                  >
                    24 Months
                  </MenuItem>
                  <MenuItem
                    value="30"
                    disabled={terms.some((term) => term.period === 30)}
                  >
                    30 Months
                  </MenuItem>
                  <MenuItem
                    value="36"
                    disabled={terms.some((term) => term.period === 36)}
                  >
                    36 Months
                  </MenuItem>
                </InputField>
              </>
            )}

            {!props.createDealForm ? (
              <>
                {dealsheet_no && (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">Dealsheet No</span>
                    <span>{dealsheet_no}</span>
                  </div>
                )}
                {dealTypeId === 2 && linked_dealsheet_no && (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">Linked Dealsheet No</span>
                    <span>{linked_dealsheet_no}</span>
                  </div>
                )}
                {dealTypeId === 2 && linked_deal_price && (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">Linked Deal Price</span>
                    <span>
                      {linked_deal_price.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </span>
                  </div>
                )}
                {deal_description && (
                  <div className="flex justify-between gap-4">
                    <span>{deal_description}</span>
                  </div>
                )}
                {vas_basket_items && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-base font-bold text-red-100">VASes</p>
                      <hr className="flex-1 border-grey-100" />
                    </div>
                    {vas_basket_items?.map((vas, index) => (
                      <div key={index} className="flex items-center gap-1">
                        <div className="flex-1">
                          <InputField
                            select
                            id={index}
                            value={vas.vas_code || "placeholder"}
                            SelectProps={{
                              IconComponent: (props) => (
                                <ArrowDropDownRoundedIcon {...props} />
                              ),
                            }}
                            onChange={(e) => {
                              const { value: vas_code } = e.target;
                              const data = { ...vas, vas_code };
                              handleVASBasketItemsChange(term.period, data);
                            }}
                          >
                            <MenuItem disabled value="placeholder">
                              - Select -
                            </MenuItem>
                            {vasOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </InputField>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                <InputField
                  select
                  inputLabel="Tariff"
                  value={term.tariff || "placeholder"}
                  SelectProps={{
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                  }}
                  onChange={(e) => {
                    handleTariffChange(term.period, e);
                  }}
                >
                  <MenuItem disabled value="placeholder">
                    - Select -
                  </MenuItem>
                  {tariffOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </>
            ) : (
              <>
                <InputField
                  select
                  inputLabel="Tariff"
                  defaultValue={
                    term.period === 24 && !props.createDealForm
                      ? selectedTariff24
                      : term.period === 36 && !props.createDealForm
                      ? selectedTariff36
                      : "placeholder"
                  }
                  SelectProps={{
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                  }}
                  onChange={(e) => {
                    newDealFuncs?.handleTariffTermsChange({ term, e });
                  }}
                >
                  <MenuItem disabled value="placeholder">
                    - Select -
                  </MenuItem>
                  {tariffOptions
                    // .filter((option) => {
                    //   if (
                    //     selectedDeviceTariffType === "DS" ||
                    //     selectedDeviceTariffType === "DN"
                    //   ) {
                    //     return option.tariff_type === "D";
                    //   } else {
                    //     return option.tariff_type === selectedDeviceTariffType;
                    //   }
                    // })
                    .map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                </InputField>
              </>
            )}

            {!props.createDealForm && !term.id && (
              <>
                <InputField
                  inputLabel="Deal price"
                  inputRef={
                    term.period === 24
                      ? refDealPrice24
                      : term.period === 30
                      ? refDealPrice30
                      : refDealPrice36
                  }
                  placeholder="R 0,00"
                  value={
                    term.period === 24
                      ? dealPrice24
                      : term.period === 30
                      ? dealPrice30
                      : dealPrice36
                  }
                  onBlur={(e) => {
                    handleDealPriceChange(term.period, e);
                  }}
                />

                <div className="flex justify-between gap-4">
                  {/* <div>
                  <p className="font-bold">Discount</p>
                  <p className="text-grey-500">{term.discount || 0}%</p>
                </div> */}
                  <div>
                    <p className="font-bold">CTC</p>
                    <p className="text-grey-500">
                      {(term.ctc || 0).toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div>
                    <p className="font-bold">EFF</p>
                    <p className="text-grey-500">{term.eff || 0}%</p>
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        );
      })}

      <div className="mt-4 flex gap-4">
        <CustomDatePicker
          label="From"
          value={dealStartDate}
          handleEventChange={handleStartDateChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
          // onDateChange={(e) => {newDealFuncs?.handleStartDateChange(e)}}
        />
        <CustomDatePicker
          label="To"
          value={dealEndDate}
          handleEventChange={handleEndDateChange}
          minDate={props.minDate}
          maxDate={props.maxDate}
          // onDateChange={(e)=> {newDealFuncs?.handleStartDateChange(e)}}
        />
      </div>
    </div>
  );
}
