// React Imports
import React, { useEffect, useId, useRef, useState } from "react";

// Mui Imports
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";

// Redux Store Imports
import { useDispatch } from "react-redux";
import {
  approveDeal,
  entirelyRejectDeal,
  rejectDeal,
  setRejectReason,
} from "../../slices/dealCanvas";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";
import InputField from "../input/input-component";
import TabPanel, { a11yProps } from "../tab-panel/tab-panel-component";
import Radialbar from "../radialbar/radialbar-component";
import CustomCheckbox from "../checkbox/checkbox-component";

// Icon Imports
import CloseIcon from "../../assets/icons/close-icon.svg";
import InfoIcon from "../../assets/icons/info-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import BlockIcon from "../../assets/icons/block-icon.svg";
import XCircleIcon from "../../assets/icons/x-circle-icon.svg";
import TickCircleOutlineIon from "../../assets/icons/tick-circle-outline-icon.svg";

export default function DealApprovalCard(props) {
  const {
    id,
    devices,
    vodacomInfo,
    competitorInfo,
    moreInfo,
    multiAccessory,
    multiDevice,
    multiTariff,
    multiRouter,
    multiVAS,
    cardGlobalLevel,
    termSpecific,
  } = props.data;

  const { dealType, dealSelected = false, setDealSelected, termIndex } = props;

  const device = devices[0].device;
  const specs = {
    size: devices[0].specs.size || null,
    ram: devices[0].specs.ram || null,
    rom: devices[0].specs.rom || null,
    sim: devices[0].specs.sim || null,
  };
  const currentDealCycle = devices[0].currentDealCycle;
  const previousDealCycle = devices[0].previousDealCycle;
  const supplierAmbitions = devices[0].supplierAmbitions;

  // Redux dispatch
  const dispatch = useDispatch();

  // Unique ID with custom text for styling in some components
  const uid = useId();

  // Parent card reference
  const mainRef = useRef(null);

  // Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  // Dialogs
  const [informationModal, setInformationModal] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setInformationModal(false);
  };

  // const accessoriesAvailable = accessoryOptions.length > 0;

  const [_supplierAmbitions, setSupplierAmbitions] =
    useState(supplierAmbitions);
  const [_ctd] = useState(0);
  const [_term24] = useState(0);
  const [_term36] = useState(0);
  const [reasonField, setReasonField] = useState(false);

  // const { main_device_basket_id: device_basket_id } = cardGlobalLevel;
  const { total_basket_price: totalBasketPrice } = cardGlobalLevel;
  const [
    {
      ctc: term24CTC,
      efficiency: term24Efficiency,
      tariff_code: tariffCode24,
      tariff_basket_desc: tariffDesc24,
      approval_action: approvalAction24,
      approval_reason: approvalReason24,
      "latest_approved_level": latest_approved_level24
    },
  ] = termSpecific.filter((v) => v.term === 24);
  const [
    {
      ctc: term30CTC,
      efficiency: term30Efficiency,
      tariff_code: tariffCode30,
      tariff_basket_desc: tariffDesc30,
      approval_action: approvalAction30,
      approval_reason: approvalReason30,
      "latest_approved_level": latest_approved_level30
    },
  ] = termSpecific.filter((v) => v.term === 30);
  const [
    {
      ctc: term36CTC,
      efficiency: term36Efficiency,
      tariff_code: tariffCode36,
      tariff_basket_desc: tariffDesc36,
      approval_action: approvalAction36,
      approval_reason: approvalReason36,
      "latest_approved_level": latest_approved_level36
    },
  ] = termSpecific.filter((v) => v.term === 36);

  const currentCtd = currentDealCycle.ctd;
  const current24 = currentDealCycle.term24;
  const current36 = currentDealCycle.term36;
  //const [tariffCode24, setTariffCode24] = useState(currentDealCycle.term24);
  //const [tariffCode36, setTariffCode36] = useState(currentDealCycle.term36);

  useEffect(() => {
    calculateSupplierAmbitions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ctd, _term24, _term36]);

  const calculateSupplierAmbitions = () => {
    const ctd = _ctd ?? 0;

    const { tariffSub, deviceCost, tariffDiscount } = _supplierAmbitions;

    const cashPayIn = 0;
    const channelSupport = 0;
    const vat = 1.15;
    const markup = 1.2;

    const calculateCTC = (term) => {
      let ctc = 0,
        dealPrice = 0;
      if (term === 24) {
        dealPrice = _term24;
      } else {
        dealPrice = _term36;
      }

      if (
        dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount)) >
        0
      ) {
        ctc =
          parseFloat(deviceCost) -
          cashPayIn -
          ctd -
          channelSupport -
          ((dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount))) *
            term) /
            vat /
            markup;
      } else {
        ctc = parseFloat(deviceCost) - ctd;
      }
      return ctc;
    };

    const calculateEFF = (ctc, term) => {
      return (
        (ctc /
          (((parseFloat(tariffSub) + parseFloat(tariffDiscount)) * term) /
            vat)) *
        100
      ).toFixed(2);
    };

    // Calculate CTC
    const ctc = calculateCTC(24);
    const ctc36 = calculateCTC(36);

    // Calculate Efficiency
    const eff = calculateEFF(ctc, 24);
    const eff36 = calculateEFF(ctc36, 36);

    // Calculate Intensity
    // TODO: ..
    const int = 0;
    const int36 = 0;

    setSupplierAmbitions({
      ..._supplierAmbitions,
      ctc,
      eff,
      int,
      ctc36,
      eff36,
      int36,
    });
  };

  // Handle deal approval request
  let approvedDeal, rejectedDeal, entirelyRejectedDeal;

  if (termIndex === 0) {
    approvedDeal = approvalAction24 === 1;
    rejectedDeal = approvalAction24 === 2;
    entirelyRejectedDeal = approvalAction24 === 3;
  }
  if (termIndex === 1) {
    approvedDeal = approvalAction30 === 1;
    rejectedDeal = approvalAction30 === 2;
    entirelyRejectedDeal = approvalAction30 === 3;
  }
  if (termIndex === 2) {
    approvedDeal = approvalAction36 === 1;
    rejectedDeal = approvalAction36 === 2;
    entirelyRejectedDeal = approvalAction36 === 3;
  }

  const handleDealApproval = () => {
    dispatch(approveDeal({ id: id, type: dealType, termIndex }));
    setReasonField(false);
  };

  // Handle deal reject request
  const handleDealReject = () => {
    dispatch(rejectDeal({ id: id, type: dealType, termIndex }));
    setReasonField(true);
  };

  const handleSetRejectReason = (e) => {
    const { value } = e.target;
    dispatch(
      setRejectReason({ id: id, type: dealType, termIndex, reason: value })
    );
  };

  // Handle deal reject entirely request
  const handleDealEntirelyReject = () => {
    dispatch(entirelyRejectDeal({ id: id, type: dealType, termIndex }));
  };

  return (
    <>
      <div className="relative">
        {tariffCode24 !== null && termIndex === 0 && (
          <p className="absolute top-0 right-0 p-6 font-bold text-black">
            Latest Approved Level: {latest_approved_level24}
          </p>
        )}
        {tariffCode36 !== null && termIndex === 2 && (
          <p className="absolute top-0 right-0 p-6 font-bold text-black">
            Latest Approved Level: {latest_approved_level36}
          </p>
        )}
        <div
          ref={mainRef}
          className={`
            flex flex-col gap-4 rounded-lg border-2 border-solid bg-white p-8 
            ${
              rejectedDeal
                ? "border-red-100 shadow"
                : approvedDeal
                ? "border-green-200 shadow"
                : entirelyRejectedDeal
                ? "border-transparent fill-grey-300 text-grey-300 hover:fill-grey-300 hover:text-grey-300"
                : "border-transparent shadow"
            }
          `}
          aria-labelledby={`#${id}`}
        >
          <div className="flex items-center">
            {/* <IconButton
              aria-label="More information"
              className="fill-black hover:fill-red-100"
              onClick={() => setInformationModal(true)}
            >
              <InlineSVG src={InfoIcon} width={24} height={24} ariaHidden />
            </IconButton> */}
            {/* 
            <CustomCheckbox
              className="ml-auto"
              checked={dealSelected}
              onChange={setDealSelected}
              inputProps={{ "aria-label": "Select this deal" }}
            /> */}
          </div>

          <div className="flex w-full flex-col gap-6">
            <div className="flex items-center justify-center gap-2">
              <div className="flex flex-col items-center gap-1 text-center">
                <h2
                  id={id}
                  className={`text-xl font-bold ${
                    entirelyRejectedDeal ? "text-grey-300" : "text-red-100"
                  }`}
                >
                  {device}
                </h2>
                <div className="mt-1 flex items-center gap-2 text-sm font-normal">
                  {specs.size && (
                    <span className="divider-dot">{specs.size}</span>
                  )}
                  {specs.ram && <span className="divider-dot">{specs.ram}</span>}
                  {specs.rom && <span className="divider-dot">{specs.rom}</span>}
                  {specs.sim && <span className="divider-dot">{specs.sim}</span>}
                </div>
              </div>
            </div>

            {/* VAS */}
            {multiVAS &&
              multiVAS.map((item, index) => (
                <div
                  className="flex items-center justify-center gap-4"
                  key={index}
                >
                  <InlineSVG
                    src={PlusIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className={
                      entirelyRejectedDeal ? "fill-grey-300" : "fill-red-100"
                    }
                  />
                  <p className="text-base font-bold">{item.vasName}</p>
                </div>
              ))}

            {/* Accessories */}
            {multiAccessory &&
              multiAccessory.map((item, index) => (
                <div
                  className="flex items-center justify-center gap-4"
                  key={index}
                >
                  <InlineSVG
                    src={PlusIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className={
                      entirelyRejectedDeal ? "fill-grey-300" : "fill-red-100"
                    }
                  />
                  <p className="text-base font-bold">{item.accessoryName}</p>
                </div>
              ))}

            {/* Devices */}
            {multiDevice &&
              multiDevice.map((item, index) => (
                <div
                  className="flex items-center justify-center gap-4"
                  key={index}
                >
                  <InlineSVG
                    src={PlusIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className={
                      entirelyRejectedDeal ? "fill-grey-300" : "fill-red-100"
                    }
                  />
                  <p className="text-base font-bold">{item.deviceName}</p>
                </div>
              ))}

            {/* Tariffs */}
            {multiTariff &&
              multiTariff.map((item, index) => (
                <div
                  className="flex items-center justify-center gap-4"
                  key={index}
                >
                  <InlineSVG
                    src={PlusIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className={
                      entirelyRejectedDeal ? "fill-grey-300" : "fill-red-100"
                    }
                  />
                  <p className="text-base font-bold">{item.tariffName}</p>
                </div>
              ))}

            {/* Routers */}
            {multiRouter &&
              multiRouter.map((item, index) => (
                <div
                  className="flex items-center justify-center gap-4"
                  key={index}
                >
                  <InlineSVG
                    src={PlusIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className={
                      entirelyRejectedDeal ? "fill-grey-300" : "fill-red-100"
                    }
                  />
                  <p className="text-base font-bold">{item.routerName}</p>
                </div>
              ))}

            <div className="flex justify-between gap-4">
              <span className="font-bold">Total Basket Price</span>
              <span>
                {parseFloat(totalBasketPrice).toLocaleString("en-ZA", {
                  style: "currency",
                  currency: "ZAR",
                })}
              </span>
            </div>

            <div className="flex justify-between gap-4">
              <span className="font-bold">CTD</span>
              <span>
                {currentCtd.toLocaleString("en-ZA", {
                  style: "currency",
                  currency: "ZAR",
                })}
              </span>
            </div>

            {tariffCode24 !== null && termIndex === 0 && (
              <>
                <div className="flex items-center justify-center gap-x-2">
                  <hr className="flex-1 border-grey-100" />
                  <p className="text-center text-base font-bold">24 month</p>
                  <hr className="flex-1 border-grey-100" />
                </div>

                <div className="flex justify-between gap-4">
                  <span className="font-bold">Tariff</span>
                  <span>{tariffDesc24}</span>
                </div>

                <div className="flex justify-between gap-4">
                  <span className="font-bold">Deal price</span>
                  <span>
                    {current24.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </span>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <div className="col-span-1 flex justify-between">
                    <p className="font-bold">CTC</p>
                    <p>
                      {term24CTC.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="col-span-1 flex justify-between">
                    <p className="font-bold">EFF</p>
                    <p>{term24Efficiency}%</p>
                  </div>
                </div>
              </>
            )}

            {tariffCode36 !== null && termIndex === 2 && (
              <>
                <div className="flex items-center justify-center gap-x-2">
                  <hr className="flex-1 border-grey-100" />
                  <p className="text-center text-base font-bold">36 month</p>
                  <hr className="flex-1 border-grey-100" />
                </div>

                <div className="flex justify-between gap-4">
                  <span className="font-bold">Tariff</span>
                  <span>{tariffDesc36}</span>
                </div>

                <div className="flex justify-between gap-4">
                  <span className="font-bold">Deal price</span>
                  <span>
                    {current36.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </span>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <div className="col-span-1 flex justify-between">
                    <p className="font-bold">CTC</p>
                    <p>
                      {term36CTC.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="col-span-1 flex justify-between">
                    <p className="font-bold">EFF</p>
                    <p>{term36Efficiency}%</p>
                  </div>
                </div>
              </>
            )}

            <div className="mt-4 flex flex-col gap-4">
              <Button
                variant="contained"
                color="success"
                startIcon={
                  <InlineSVG
                    src={TickCircleOutlineIon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                disableFocusRipple
                disabled={approvedDeal}
                onClick={handleDealApproval}
              >
                Approve
              </Button>

              <Button
                variant="outlined"
                color="error"
                startIcon={
                  <InlineSVG
                    src={XCircleIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                disableFocusRipple
                disabled={rejectedDeal}
                onClick={handleDealReject}
              >
                Reject
              </Button>

              {reasonField && (
                <InputField
                  inputLabel="Reason"
                  placeholder="Enter your comments here..."
                  multiline
                  minRows={2}
                  onBlur={handleSetRejectReason}
                />
              )}

              {/* <Button
                color="warning"
                startIcon={
                  <InlineSVG src={BlockIcon} width={24} height={24} ariaHidden />
                }
                disableFocusRipple
                disabled={entirelyRejectedDeal}
                onClick={handleDealEntirelyReject}
              >
                Entirely reject
              </Button> */}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth={true}
        open={informationModal}
        onClose={handleDialogClose}
        aria-label={`More information`}
      >
        <DialogTitle className="py-4 px-5 text-right">
          <IconButton
            aria-label="Close more information"
            onClick={handleDialogClose}
          >
            <InlineSVG
              src={CloseIcon}
              ariaHidden
              width={32}
              height={32}
              className="fill-black"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-12 pb-12">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="More information content grouped in tabs"
            className="mb-8"
            centered
          >
            <Tab
              label="Deal Info."
              {...a11yProps(`extra${uid}0`)}
              disableRipple
            />
            <Tab
              label="Competitor Info."
              {...a11yProps(`extra${uid}1`)}
              disableRipple
            />
            <Tab
              label="Additional Info."
              {...a11yProps(`extra${uid}2`)}
              disableRipple
            />
          </Tabs>

          <TabPanel value={tabValue} index={0} id={`extra${uid}0`}>
            <div className="grid grid-cols-2 gap-y-10 gap-x-12 lg:divide-x lg:divide-grey-50">
              <div className="col-span-2 flex flex-col justify-between gap-6 lg:col-span-1 lg:pt-4">
                <div className="flex justify-between">
                  <p className="font-bold">Term Differential:</p>
                  <p>{vodacomInfo.termDifferential}%</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">Device Cost:</p>
                  <p>
                    {parseFloat(vodacomInfo.previousDeviceCost).toLocaleString(
                      "en-ZA",
                      {
                        style: "currency",
                        currency: "ZAR",
                      }
                    )}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">CTC:</p>
                  <p>
                    {vodacomInfo.previousCtc.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </p>
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-center lg:col-span-1 lg:pl-12">
                <div className="grid grid-cols-2 gap-x-24 lg:gap-x-12 xl:gap-x-24">
                  <div className="col-span-1 flex flex-col items-center justify-center gap-y-8">
                    <Radialbar
                      value={+vodacomInfo.previousEff}
                      color="#EB9700"
                      label="EFF"
                      width={82}
                      height={82}
                    />
                    <div className="text-center">
                      <p className="mb-1 text-3xl font-light text-yellow-200">
                        {+vodacomInfo.previousNewLine}
                      </p>
                      <p className="text-base font-bold text-black">New Line</p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col items-center justify-center gap-y-8">
                    <Radialbar
                      value={+vodacomInfo.previousInt}
                      color="#E60000"
                      label="INT"
                      width={82}
                      height={82}
                    />
                    <div className="text-center">
                      <p className="mb-1 text-3xl font-light text-red-100">
                        {+vodacomInfo.previousRetention}
                      </p>
                      <p className="text-base font-bold text-black">
                        Retention
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1} id={`extra${uid}1`}>
            <div className="grid grid-cols-2 gap-y-10">
              {competitorInfo ? (
                competitorInfo.map((item, index) => (
                  <div
                    className="col-span-2 flex flex-col gap-5 odd:border-r odd:border-solid odd:border-grey-50 odd:pr-12 even:pl-12 lg:col-span-1"
                    key={item.competitor}
                  >
                    <p className="text-center text-base font-bold text-black">
                      {item.competitor}
                    </p>
                    <div className="flex items-center justify-between gap-4">
                      <InputLabel
                        className="text-base font-bold text-black"
                        id={uid + "competitor-deal-price-" + index}
                      >
                        Deal Price:
                      </InputLabel>
                      <InputField
                        inputProps={{
                          "aria-labelledby":
                            uid + "competitor-deal-price-" + index,
                        }}
                        placeholder="R 0,00"
                        defaultValue={item.dealPrice || ""}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <InputLabel
                        className="text-base font-bold text-black"
                        id={uid + "competitor-plan-" + index}
                      >
                        Plan:
                      </InputLabel>
                      <InputField
                        inputProps={{
                          "aria-labelledby": uid + "competitor-plan-" + index,
                        }}
                        placeholder="Enter competitor plan..."
                        defaultValue={item.plan || ""}
                      />
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <InputLabel
                        className="text-base font-bold text-black"
                        id={uid + "competitor-value-" + index}
                      >
                        Value:
                      </InputLabel>
                      <InputField
                        inputProps={{
                          "aria-labelledby": uid + "competitor-value-" + index,
                        }}
                        placeholder="Enter competitor value..."
                        defaultValue={item.value || ""}
                      />
                    </div>
                    <div className="my-5 flex justify-between gap-4">
                      <p className="font-bold">Discount to Fair Value:</p>
                      <p>{item.discountFairValue}%</p>
                    </div>
                    <div className="flex justify-between gap-4">
                      <p className="font-bold">Fair Value Differential:</p>
                      <p>{item.fairValueDiff}%</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-2">
                  <p className="text-base text-black">
                    No competitor information available.
                  </p>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2} id={`extra${uid}2`}>
            <p className="text-xl font-bold">{moreInfo.deviceFullTitle}</p>
            <hr className="my-4 border-grey-100 opacity-100" />
            <div className="mt-8 grid grid-cols-8 gap-x-12">
              <div className="col-span-3 flex flex-col gap-6">
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    Product Code:
                  </p>
                  <p className="text-base text-black">{moreInfo.productCode}</p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">Tariff:</p>
                  <p className="text-base text-black">{moreInfo.tariff}</p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">Subs:</p>
                  <p className="text-base text-black">
                    {moreInfo.subs.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    Device Cost (Prev. Month):
                  </p>
                  <p className="text-base text-black">
                    {moreInfo.previousDeviceCost.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    CTD (Prev. Month):
                  </p>
                  <p className="text-base text-black">
                    {moreInfo.previousCtd.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </p>
                </div>
              </div>
              <div className="col-span-3 col-start-5 flex flex-col gap-6">
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    RRP (Prev. Month):
                  </p>
                  <p className="text-base text-black">
                    {moreInfo.previousRrp.toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    RRP (Current Month):
                  </p>
                  <p className="text-base text-black">
                    {parseFloat(moreInfo.currentRrp).toLocaleString("en-ZA", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    SIMO RRP (Current Month):
                  </p>
                  <p className="text-base text-black">
                    {moreInfo.currentSimoRrp}%
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-black">
                    Discount to Fair Value:
                  </p>
                  <p className="text-base text-black">
                    {moreInfo.discountFairValue}%
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-6 mb-8 border-grey-100 opacity-100" />
            <p className="text-lg font-bold text-black">Differentials</p>
            <div className="mt-6 grid grid-cols-8 gap-x-12">
              <div className="col-span-3 flex flex-col gap-6">
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">DP:</p>
                  <p className="text-base text-black">
                    {moreInfo.differentials.dp}%
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">CIB:</p>
                  <p className="text-base text-black">
                    {moreInfo.differentials.cib}%
                  </p>
                </div>
              </div>
              <div className="col-span-3 col-start-5 flex flex-col gap-6">
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">EFF:</p>
                  <p className="text-base text-black">
                    {moreInfo.differentials.eff}%
                  </p>
                </div>
                <div className="flex items-center justify-between gap-4">
                  <p className="text-base font-bold text-black">
                    Reduced Subs:
                  </p>
                  <p className="text-base text-black">
                    {moreInfo.differentials.reducedSubs}
                  </p>
                </div>
              </div>
            </div>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
}