import CycleConfigService from "../services/cycleConfig.service";
import {
  createSelector,
  createSlice,
  createAsyncThunk
} from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { v4 as uuidv4 } from "uuid";

export const getRouters = createAsyncThunk(
  "cycleConfig/getRouters",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getRouters(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDevicePerChannelHandsets = createAsyncThunk(
  "cycleConfig/getDevicePerChannelHandsets",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getDevicePerChannelHandsets(
        _.cycle
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDevicePerChannelAccessories = createAsyncThunk(
  "cycleConfig/getDevicePerChannelAccessories",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getDevicePerChannelAccessories(
        _.cycle
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTariffs = createAsyncThunk(
  "cycleConfig/getTariffs",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getTariffs(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStandardVASs = createAsyncThunk(
  "cycleConfig/getStandardVASs",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getStandardVASs(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStandardVASs24 = createAsyncThunk(
  "cycleConfig/getStandardVASs24",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getStandardVASs24(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getStandardVASs36 = createAsyncThunk(
  "cycleConfig/getStandardVASs36",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getStandardVASs36(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDealTypePerChannel = createAsyncThunk(
  "cycleConfig/getDealTypePerChannel",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getDealTypePerChannel(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getActiveVASs = createAsyncThunk(
  "cycleConfig/getActiveVASs",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getActiveVASs(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getTariffRanges = createAsyncThunk(
  "cycleConfig/getTariffRanges",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getTariffRanges(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDealCycleParameters = createAsyncThunk(
  "cycleConfig/getDealCycleParameters",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getDealCycleParameters(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCycleReviews = createAsyncThunk(
  "cycleConfig/getCycleReviews",
  async (_, thunkAPI) => {
    try {
      const response = await CycleConfigService.getCycleReviews(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  dealCycleParameters: {},
  tariffData: [],
  rangesData: [],
  standardVASsData: [],
  standardVASs24Data: [],
  standardVASs36Data: [],
  activeVASsData: [],
  handsetDevicePerChannelData: [],
  accessoryDevicePerChannelData: [],
  dealTypePerChannelData: [],
  routersData: [],
  cycleReviewsData: [],
};

export const configCycleDataSlice = createSlice({
  name: "cycleConfig",
  initialState,
  reducers: {
    addTariff: (state, action) => {
      const { object, data } = action.payload;

      return {
        ...state,
        [object]: [
          ...state.tariffData,
          {
            id: uuidv4(),
            ...data,
            newItem: true,
          },
        ],
      };
    },
    removeData: (state, action) => {
      const { id, object, key = null } = action.payload;

      if (key !== null) {
        const index = state[object][key].findIndex((item) => item.id === id);
        if (index !== -1) {
          state[object][key].splice(index, 1);
        }
      } else {
        const index = state[object].findIndex((item) => item.id === id);
        if (index !== -1) {
          state[object].splice(index, 1);
        }
      }
    },
    updateStandardVASData: (state, action) => {
      const { object, data } = action.payload;
      const { name, value, tariffId, term, vasExists } = data;

      const updateData = state[object].map((tariff) => {
        if (tariff.id === tariffId && tariff.term === term) {
          if (vasExists(tariff, value) === false) {
            if (tariff[name] !== value) {
              return {
                ...tariff,
                [name]: value,
                isChanged: true,
              };
            }
          }
          return tariff;
        } else {
          return tariff;
        }
      });

      return {
        ...state,
        [object]: updateData,
      };
    },
    updateTariffRanges: (state, action) => {
      const { object, data } = action.payload;
      const { name, value, rangeId, term } = data;

      const updatedTariffRanges = state[object].map((range) => {
        const initTerm = (range.initTerm || []).concat(range.term);

        if (range.id === rangeId && range.term === term) {
          return {
            ...range,
            [name]: value,
            isChanged: true,
            initTerm: initTerm,
          };
        }
        return range;
      });

      return {
        ...state,
        [object]: updatedTariffRanges,
      };
    },
    updateTariffData: (state, action) => {
      const { object, data } = action.payload;
      const { name, value, tariffId } = data;

      const updatedTariffs = state[object].map((tariff) =>
        tariff.id === tariffId
          ? { ...tariff, [name]: value, isChanged: true }
          : tariff
      );

      return {
        ...state,
        [object]: updatedTariffs,
      };
    },
    toggleActive: (state, action) => {
      const { object, key, index, active } = action.payload;
      if (index !== null) {
        state[object][key][index].active = active;
      } else {
        state[object][key].active = active;
      }
    },
    updateReviewState: (state, action) => {
      const { object, data, navigation } = action.payload;
      const { config_status, reviewed, config_set, reason_rejected, cycle } = data;

      const updates = [];
    
      const cycleReviewsData = state[object].map((tab) =>
        tab.config_set === config_set
          ? { ...tab, config_status, reviewed, reason_rejected }
          : tab
      );
    
      const result = cycleReviewsData.map(({ config_status, reviewed, latest_approval_level, approval_level_required, modified_by }) => {
        return [config_status, reviewed, latest_approval_level, approval_level_required];
      });
    
      const allApproved = result.every((item) => item[0] === "A");
      const allReviewed = result.every((item) => item[1] === "Y");
      const latest_approval_level = result[0][2];
      const update_lvl_rq = latest_approval_level + 1;
    
      if (allApproved && allReviewed && (latest_approval_level <= result[0][3]) && (update_lvl_rq <= result[0][3])) {
        const updateCycleReviewsData = state[object].map(tab => ({
          ...tab,
          config_status: "P",
          reviewed: "N",
          reason_rejected: null,
          latest_approval_level: update_lvl_rq,
          users: null
        }));

        updates.push("many", updateCycleReviewsData);

        navigation("/home");

        console.log("Send an email to a User with next Level of Approval");

        const emailLoad = [{
          to: "luxolondima41@gmail.com",
          message: `${result[0][4]} has Approved All Configuration and would now require your Review`
        }];

        CycleConfigService.sendEmail(emailLoad)
          .then((response) => {
            const { message } = response.data;
            console.log(message);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if ((!allApproved && allReviewed) && (latest_approval_level !== 1)) {
          const new1_CycleReviewsData = cycleReviewsData.map(tab => ({ ...tab, latest_approval_level: 1 }));
          
          updates.push("many", new1_CycleReviewsData);

          navigation("/home");

          console.log("Send an email to a User doing Deal Cycle Configurations to makes some Amendments");

          const emailLoad = [{
            to: "luxolondima41@gmail.com",
            message: `${result[0][4]} has rejected ${config_set} reason being: ${reason_rejected}`
          }];

          CycleConfigService.sendEmail(emailLoad)
            .then((response) => {
              const { message } = response.data;
              console.log(message);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          const new2_CycleReviewsData = cycleReviewsData.find((tab) => tab.config_set === config_set);

          updates.push(new2_CycleReviewsData)
        }
      }

      // After all updates are prepared
      if (updates.length > 0) {
        var manyUpdates = [];
        if (updates[0] === "many") {
          manyUpdates = updates[1];
        } else {
          manyUpdates = updates;
        }
        
        // Call updateCycleReviews once with all updates
        CycleConfigService.updateCycleReviews(cycle, manyUpdates)
          .then((response) => {
            const { message } = response.data;
            console.log(message);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    
      return {
        ...state,
        [object]: cycleReviewsData,
      };
    },    
    updateDealPerChannel: (state, action) => {
      const { object, data } = action.payload;
      const { channelId, checked, dealId, dealTerm, dealCat, name } = data;

      const updatedData = state[object].map((deal) => {
        if (
          deal.id === dealId &&
          deal.term === dealTerm &&
          deal.category === dealCat
        ) {
          const updatedChannels = deal.channels.map((channel) => {
            if (channel.id === channelId) {
              return {
                ...channel,
                [name]: checked ? "Y" : "N",
                isChanged: true,
              };
            }
            return channel;
          })

          return {
            ...deal,
            channels: updatedChannels,
            isChanged: true,
          };
        }
        return deal;
      });

      return {
        ...state,
        [object]: updatedData,
      };
    },
    updateDevicePerChannel: (state, action) => {
      const { object, data } = action.payload;
      const { name, checked, deviceId, channelId, } = data;
      
      const updatedDevice = state[object].map((device) => {
        if (device.id === deviceId) {
          const updatedChannels = device.channels.map((channel) => {
            if (channel.id === channelId) {
              return {
                ...channel,
                [name]: checked ? "Y" : "N",
                isChanged: true,
              };
            }
            return channel;
          });

          return {
            ...device,
            channels: updatedChannels,
            isChanged: true,
          };
        }
        return device;
      });

      return {
        ...state,
        [object]: updatedDevice,
      };
    },
    updateDealCycleParameters: (state, action) => {
      const { object, data } = action.payload;
      const { checked, name, catId } = data;

      const updatedData = state[object].map((item) => {
        // Toggle VAT Status
        if (item.vat && item.vat.id === catId) {
          return {
            ...item,
            vat: {
              ...item.vat,
              [name]: checked ? "Y" : "N",
              isChanged: true,
            },
          };
        }
        // Toggle Terms Status
        if (item.terms) {
          const updatedTerms = item.terms.map((term) => {
            if (term.id === catId) {
              return {
                ...term,
                [name]: checked ? "Y" : "N",
                markup: true,
                isChanged: true,
              };
            }
            return term;
          });
          return {
            ...item,
            terms: updatedTerms,
          };
        }
        // Toggle Deal Price Multipliers Status
        if (item.dealPriceMultipliers) {
          const updatedMultipliers = item.dealPriceMultipliers.map(
            (multiplier) => {
              if (multiplier.id === catId) {
                return {
                  ...multiplier,
                  [name]: checked ? "Y" : "N",
                  multiplier: true,
                  isChanged: true,
                };
              }
              return multiplier;
            }
          );
          return {
            ...item,
            dealPriceMultipliers: updatedMultipliers,
          };
        }
        // Toggle Financials Status
        if (item.financials) {
          const updatedFinancials = item.financials.map((financial) => {
            if (financial.id === catId) {
              return {
                ...financial,
                [name]: checked ? "Y" : "N",
                financial: true,
                isChanged: true,
              };
            }
            return financial;
          });
          return {
            ...item,
            financials: updatedFinancials,
          };
        }
        return item;
      });

      return {
        ...state,
        [object]: updatedData,
      };
    },
    updateRouters: (state, action) => {
      const { object, data } = action.payload;
      const { checked, name, id } = data;

      const updatedData = state[object].map((rtrs) => {
        if (rtrs.id === id) {
          return { ...rtrs, [name]: checked ? "Y" : "N", isChanged: true };
        }
        return rtrs;
      });

      return {
        ...state,
        [object]: updatedData,
      };
    },
    selectAllUpdate: (state, action) => {
      const { object, data } = action.payload;
      const { deviceId, toggleAll } = data;

      console.log(toggleAll);

      const toggleAllSelection = state[object].map((device) =>
        device.id === deviceId
          ? {
              ...device,
              channels: device.channels.map((channel) => ({
                ...channel,
                active: !toggleAll === true ? "Y" : "N",
                isChanged: true,
              })),
              isChanged: true,
              toggleAll: !toggleAll,
            }
          : device
      );

      return {
        ...state,
        [object]: toggleAllSelection,
      };
    },
  },
  extraReducers: {
    [getRouters.fulfilled]: (state, action) => {
      state.routersData = action.payload;
    },
    [getDevicePerChannelHandsets.fulfilled]: (state, action) => {
      state.handsetDevicePerChannelData = action.payload;
    },
    [getDevicePerChannelAccessories.fulfilled]: (state, action) => {
      state.accessoryDevicePerChannelData = action.payload;
    },
    [getTariffs.fulfilled]: (state, action) => {
      state.tariffData = action.payload;
    },
    [getStandardVASs.fulfilled]: (state, action) => {
      state.standardVASsData = action.payload;
    },
    [getStandardVASs24.fulfilled]: (state, action) => {
      state.standardVASs24Data = action.payload;
    },
    [getStandardVASs36.fulfilled]: (state, action) => {
      state.standardVASs36Data = action.payload;
    },
    [getDealTypePerChannel.fulfilled]: (state, action) => {
      state.dealTypePerChannelData = action.payload;
    },
    [getDealCycleParameters.fulfilled]: (state, action) => {
      state.dealCycleParameters = action.payload;
    },
    [getActiveVASs.fulfilled]: (state, action) => {
      state.activeVASsData = action.payload;
    },
    [getTariffRanges.fulfilled]: (state, action) => {
      state.rangesData = action.payload;
    },
    [getCycleReviews.fulfilled]: (state, action) => {
      state.cycleReviewsData = action.payload;
    },
  },
});

export const {
  addTariff,
  removeData,
  toggleActive,
  updateRouters,
  selectAllUpdate,
  updateTariffData,
  updateReviewState,
  updateTariffRanges,
  updateDealPerChannel,
  updateDevicePerChannel,
  updateStandardVASData,
  updateDealCycleParameters
} = configCycleDataSlice.actions;

// Selectors
export const getDealCycleParametersData = createSelector(
  (state) => state.cycleConfig.dealCycleParameters,
  (dealCycleParameters) => dealCycleParameters
);

export const getRoutersData = createSelector(
  (state) => state.cycleConfig.routersData,
  (routersData) => routersData
);

export const getTariffData = createSelector(
  (state) => state.cycleConfig.tariffData,
  (tariffData) => tariffData
);

export const getRangesData = createSelector(
  (state) => state.cycleConfig.rangesData,
  (rangesData) => rangesData
);

export const getStandardVASsData = createSelector(
  (state) => state.cycleConfig.standardVASsData,
  (standardVASsData) => standardVASsData
);

export const getStandardVASs24Data = createSelector(
  (state) => state.cycleConfig.standardVASs24Data,
  (standardVASs24Data) => standardVASs24Data
);

export const getStandardVASs36Data = createSelector(
  (state) => state.cycleConfig.standardVASs36Data,
  (standardVASs36Data) => standardVASs36Data
);

export const getActiveVASsData = createSelector(
  (state) => state.cycleConfig.activeVASsData,
  (activeVASsData) => activeVASsData
);

export const getHandsetDevicePerChannelData = createSelector(
  (state) => state.cycleConfig.handsetDevicePerChannelData,
  (handsetDevicePerChannelData) => handsetDevicePerChannelData
);

export const getAccessoryDevicePerChannelData = createSelector(
  (state) => state.cycleConfig.accessoryDevicePerChannelData,
  (accessoryDevicePerChannelData) => accessoryDevicePerChannelData
);

export const getDealTypePerChannelData = createSelector(
  (state) => state.cycleConfig.dealTypePerChannelData,
  (dealTypePerChannelData) => dealTypePerChannelData
);

export const getCycleReviewsData = createSelector(
  (state) => state.cycleConfig.cycleReviewsData,
  (cycleReviewsData) => cycleReviewsData
);

export default configCycleDataSlice.reducer;
