// React Imports
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";
import {
  getDealTypePerChannel,
  updateReviewState,
  updateDealPerChannel,
  getDealTypePerChannelData,
} from "../../../slices/cycleConfigCanvas";

// Service Imports
import AuthService from "../../../services/auth.service";
import CycleConfigService from "../../../services/cycleConfig.service";

// MUI Imports
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import TabPanel from "../../../components/tab-panel/tab-panel-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Icon Imports
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import CloseIcon from "@mui/icons-material/Close";

export default function DealTypePerChannelTabContent({
  handleSnackOpen,
  setCancelWarningDialog,
  inReview,
  reviewFeedback,
}) {
  const navigate = useNavigate();
  const { cycle } = useParams();

  const dispatch = useDispatch();

  // Function to update changes
  const handleToggleChange = (event, dealId, channelId, dealTerm, dealCat) => {
    const { name, checked } = event.target;

    dispatch(
      updateDealPerChannel({
        data: {
          name,
          checked,
          dealId,
          channelId,
          dealTerm,
          dealCat,
        },
        object: "dealTypePerChannelData",
      })
    );
  };

  // On Submit function to trigger tab change and success snackbar popup
  const onSubmit = () => {
    // Edited Deal Data
    const dealPerChannelData = dealTypePerChannelData
      .filter((deal) => deal.isChanged)
      .map((deal) => {
        return {
          ...deal,
          channels: deal.channels.filter((channel) => channel.isChanged),
        };
      });

    if (dealPerChannelData.length > 0) {
      CycleConfigService.updateDealPerChannel(cycle, dealPerChannelData)
        .then((response) => {
          const { message } = response.data;
          handleSnackOpen("success", message);
        })
        .catch((error) => {
          handleServerError(error);
        });
    } else {
      handleSnackOpen("notFound", "No changes made.");
    }
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/");
    } else if (status === 403) {
      // Unauthorized
      handleSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleSnackOpen("error", errors[0].message);
    }
  };

  useEffect(() => {
    dispatch(getDealTypePerChannel({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use Data resources
  const dealTypePerChannelData = useSelector(getDealTypePerChannelData);

  // Term selection states and function
  const [term, setTerm] = useState(0);
  const handleTermChange = (_, term) => {
    setTerm(term);
  };

  // Dialogs
  const [unresolvedWarningDialog, setUnresolvedWarningDialog] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog Open for Unresolved feedback warning
  const handleUnresolvedDialogOpen = () => {
    setUnresolvedWarningDialog(true);
  };

  // Alert state
  const [showAlert, setShowAlert] = useState(inReview === "R" ? true : false);

  // handle resolve feedback
  const handleResolveFeedback = () => {
    dispatch(
      updateReviewState({
        data: {
          config_status: "P",
          reviewed: "N",
          reason_rejected: null,
          config_set: "DEAL_TYPE_PER_CHANNEL",
          cycle: cycle,
        },
        object: "cycleReviewsData",
      })
    );
    setShowAlert(false);
    handleSnackOpen(
      "success",
      "Deal Type per Channel feedback has been resolved."
    );
  };

  // handle form submission with warning feedback if unresolved feedback
  const handleFormSubmission = (e) => {
    e.preventDefault();
    (inReview === "R" ? true : false)
      ? handleUnresolvedDialogOpen()
      : onSubmit();
  };

  // Group the handset data by OEM
  const groupedDataByCategory = {};
  dealTypePerChannelData.forEach((item) => {
    if (!groupedDataByCategory[item.category]) {
      groupedDataByCategory[item.category] = [];
    }
    groupedDataByCategory[item.category].push(item);
  });

  return (
    <>
      <Collapse in={showAlert}>
        <Alert
          className="mb-8 items-center gap-4 rounded-lg p-6"
          severity="warning"
          iconMapping={{
            warning: (
              <InlineSVG src={WarningIcon} ariaHidden width={24} height={24} />
            ),
          }}
          action={
            <IconButton
              aria-label="Close Alert"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <h2 className="mb-1 text-lg font-bold">Rejected</h2>
          <p className="mb-6">{reviewFeedback}</p>
          <Link onClick={handleResolveFeedback} className="underline">
            Resolve
          </Link>
        </Alert>
      </Collapse>

      <div className="mb-8 text-center">
        <ToggleButtonGroup
          value={term}
          exclusive
          onChange={handleTermChange}
          aria-label="Term selection"
        >
          <ToggleButton value={0}>24 Months</ToggleButton>
          <ToggleButton value={1}>36 Months</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TabPanel value={term} index={0}>
        <form>
          <Table>
            {Object.keys(groupedDataByCategory)
              .reverse()
              .map((category, index) => (
                <React.Fragment key={category}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`border-grey-100 pl-0 ${
                          index > 0 ? "pt-12" : "pt-0"
                        }`}
                        colSpan={groupedDataByCategory[category].map((item) => {
                          return item.channels.length + 1;
                        })}
                      >
                        <h2 className="text-3xl font-bold text-red-100">
                          {category === "D"
                            ? "Data"
                            : category === "V"
                            ? "Voice"
                            : ""}
                        </h2>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedDataByCategory[category]
                      .filter((deal) => deal.term === 24)
                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell className="pl-0">
                            <span className="text-base font-bold">
                              {item.options}
                            </span>
                          </TableCell>
                          {item.channels.map((channel) => (
                            <TableCell
                              key={channel.label}
                              className="text-base"
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    defaultChecked={
                                      channel.active === "Y" ? true : false
                                    }
                                    name="active"
                                    onChange={(e) =>
                                      handleToggleChange(
                                        e,
                                        item.id,
                                        channel.id,
                                        item.term,
                                        item.category
                                      )
                                    }
                                  />
                                }
                                label={channel.label}
                                labelPlacement="start"
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </React.Fragment>
              ))}
          </Table>
        </form>
      </TabPanel>

      <TabPanel value={term} index={1}>
        <form>
          <Table>
            {Object.keys(groupedDataByCategory)
              .reverse()
              .map((category, index) => (
                <React.Fragment key={category}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={`border-grey-100 pl-0 ${
                          index > 0 ? "pt-12" : "pt-0"
                        }`}
                        colSpan={groupedDataByCategory[category].map((item) => {
                          return item.channels.length + 1;
                        })}
                      >
                        <h2 className="text-3xl font-bold text-red-100">
                          {category === "D"
                            ? "Data"
                            : category === "V"
                            ? "Voice"
                            : ""}
                        </h2>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedDataByCategory[category]
                      .filter((deal) => deal.term === 36)
                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell className="pl-0">
                            <span className="text-base font-bold">
                              {item.options}
                            </span>
                          </TableCell>
                          {item.channels.map((channel) => (
                            <TableCell
                              key={channel.label}
                              className="text-base"
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    defaultChecked={
                                      channel.active === "Y" ? true : false
                                    }
                                    name="active"
                                    onChange={(e) =>
                                      handleToggleChange(
                                        e,
                                        item.id,
                                        channel.id,
                                        item.term,
                                        item.category
                                      )
                                    }
                                  />
                                }
                                label={channel.label}
                                labelPlacement="start"
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </React.Fragment>
              ))}
          </Table>
        </form>
      </TabPanel>

      <div className="my-12 flex justify-center gap-2 md:justify-end">
        <Button
          variant="text"
          disableFocusRipple
          color="secondary"
          onClick={() => setCancelWarningDialog(true)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableFocusRipple
          onClick={handleFormSubmission}
        >
          Save
        </Button>
      </div>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={unresolvedWarningDialog}
        onClose={() => setUnresolvedWarningDialog(false)}
        aria-labelledby="unresolved-warning-title"
      >
        <DialogTitle id="unresolved-warning-title" className="leading-tight">
          Continue with unresolved feedback?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to save and continue without resolving the review
            feedback. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setUnresolvedWarningDialog(false)}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              onSubmit();
              setUnresolvedWarningDialog(false);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}