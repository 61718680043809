// React Imports
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";

// Component Imports
import { Link } from "react-router-dom";
import BackButton from "../../components/back-button/back-button-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import InputField from "../../components/input/input-component";
import TitleBreadcrumb from "../../components/title-breadcrumb/title-breadcrumb-component";
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";

// Icon Imports
import TickOutlineCircle from "../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../assets/icons/error-circle-no-fill-icon.svg";

// MUI Imports
import {
  Button,
  Tab,
  Tabs,
  Slide,
  Snackbar
} from "@mui/material";

// Service Imports
import SystemConfigService from "../../services/settingsConfig.service";
import AuthService from "../../services/auth.service";

import { getConfigSettings, updateConfigSettings } from "../../slices/system-config-canvas";

export const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  warning: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

export default function SystemConfiguration() { 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const configSettings = useSelector((state) => state?.systemConfig?.configSettings);

  useEffect(() => {
    dispatch(getConfigSettings());
  }, [dispatch]);

  // Form Submission hook from react-hook-form
  const { handleSubmit } = useForm();

  // Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // On Submit function to trigger tab change and success snackbar popup
  const onSubmit = () => {
    const updatedConfigs = configSettings.filter(item => item.isUpdated === true);

    if (updatedConfigs.length > 0) {
      SystemConfigService.updateConfigSettings(updatedConfigs)
        .then((response) => {
          const { message } = response.data;
          handleGenericSnackOpen("success", message);
        })
        .catch((error) => {
          handleServerError(error);
        });
    } else {
      handleGenericSnackOpen("notFound", "No changes made."); // Black
    }
  };

  // Function to update changes
  const handleInputChange = (event, id, label) => {
    const { value } = event.target;

      // Regular expression to allow only numbers
      const isNumeric = /^\d*$/.test(value);

      if (isNumeric && value > 0) {
        // Proceed with updating the state or handling the input
        dispatch(updateConfigSettings({
          data: {
            id,
            value,
            label
          },
          object: "systemConfig"
        }));
      }
  };

  // handle form submission
  const handleFormSubmission = (e) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#systemConfigTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title="System Configuration"
          titleID="systemConfigTitle"
        />

        {/* Configuration Options (Tabs) */}
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Configuration Options"
          className="my-8"
        >
          <Tab
            label="SMTP Server"
            {...a11yProps(`smtp-server`)}
            disableRipple
          />
          <Tab
            label="Idle Timeout"
            {...a11yProps(`idle-timeout`)}
            disableRipple
          />
        </Tabs>

        {/* SMTP Server (Tab Content) */}
        <TabPanel value={tabValue} index={0} id={`smtp-server`}>
          <form className="my-12 grid grid-cols-10 gap-x-6">
            <div className="col-span-10 row-start-1 mb-12 flex flex-col gap-4 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3">
              <InputField
                inputLabel="SMTP Server"
                placeholder="IP Address"
              ></InputField>
              <InputField
                inputLabel="Sender"
                placeholder="dde-system@vodacom.co.za"
              ></InputField>
            </div>
            <div className="col-span-10 row-start-2 mt-4 flex justify-center lg:col-span-3 lg:col-start-5 xl:col-span-2 xl:col-start-5">
                <Button type="submit" variant="contained" disableFocusRipple className="w-full">
                  Save
                </Button>
              </div>
          </form>
        </TabPanel>

        {/* Idle Timeout */}
        {configSettings[0] && (
          <TabPanel value={tabValue} index={1} id={`idle-timeout`}>
            <form onSubmit={handleFormSubmission} className="my-12 grid grid-cols-10 gap-x-6">
              <div className="col-span-10 row-start-1 mb-12 flex flex-col gap-4 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3">
                <InputField
                  inputLabel="Idle Timeout (in minutes)"
                  name={configSettings[0].label}
                  placeholder="0m"
                  value={configSettings[0].value}
                  type="number"
                  min={1}
                  onChange={(e) => handleInputChange(e, configSettings[0].id, configSettings[0].label)}
                />
              </div>
              <div className="col-span-10 row-start-2 mt-4 flex justify-center lg:col-span-3 lg:col-start-5 xl:col-span-2 xl:col-start-5">
                <Button type="submit" variant="contained" disableFocusRipple className="w-full">
                  Save
                </Button>
              </div>
            </form>
          </TabPanel>
        )}

        <Snackbar
          open={genericSnack}
          autoHideDuration={6000}
          onClose={handleGenericSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: snackSettings[snackType].colorClass,
          }}
          message={
            <div className="flex items-center gap-3">
              <InlineSVG
                src={snackSettings[snackType].icon}
                ariaHidden
                width={28}
                height={28}
                className="fill-white"
              />
              <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
            </div>
          }
          action={
            <Button
              variant="text"
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              onClick={handleGenericSnackClose}
              disableFocusRipple
            >
              Dismiss
            </Button>
          }
      />

      </section>
    </>
  );
}
