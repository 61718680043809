import axios from "axios";

const API_URL = "/api/deals/";

const getHeadlineDealsByOEM = (cycle, oem) => {
  return axios.get(API_URL + cycle + "/headlines/" + oem);
};

const getHeadlineSIMODeals = (cycle) => {
  return axios.get(API_URL + cycle + "/sim-only");
};

const getTotalHeadlineDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-headline-deals");
};

const getTotalDraftHeadlineDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-draft-headline-deals");
};

const getTotalUnapprovedHeadlineDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-unapproved-headline-deals");
};

const updateHeadlineDeals = (cycle, deals) => {
  return axios.put(API_URL + cycle + "/headlines", { deals });
};
const updateAddOnHeadlineDeals = (cycle, deals) => {
  return axios.put(API_URL + cycle + "/headlines/add-on", { deals });
};

const updateHeadlineSIMODeals = (headline_deals) => {
  return axios.put(API_URL + "headlines/sim-only", { headline_deals });
};

const createHeadlines = (cycle, oem, deals) => {
  return axios.post(API_URL + cycle + "/headlines/" + oem, { deals });
};

const createHeadlinesAddOn = (cycle, oem, deals) => {
  return axios.post(API_URL + cycle + "/headlines/" + oem + "/add-on", {
    deals,
  });
};

const submitForApproval = (cycle_id) => {
  return axios.post(API_URL + "headlines/submit-for-approval", { cycle_id });
};

const publishHeadlines = (cycle_id) => {
  return axios.post(API_URL + "headlines/publish", { cycle_id });
};

const deleteHeadlineDeal = (cycle, deal) => {
  const {
    channel_id,
    device_basket_id,
    tariff_basket_id_24,
    tariff_basket_id_30,
    tariff_basket_id_36,
  } = deal;
  return axios.delete(
    API_URL +
      cycle +
      "/headlines/" +
      channel_id +
      "/" +
      device_basket_id +
      "/" +
      tariff_basket_id_24 +
      "/" +
      tariff_basket_id_30 +
      "/" +
      tariff_basket_id_36
  );
};

const getPreviousMonth = (cycle) => {
  return axios.get(API_URL + cycle + "/headlines/prev-month");
};

const generateProtoHeadlines = (cycle_id) => {
  return axios
    .post(API_URL + "generate-proto-headlines", { cycle_id })
    .then((response) => {
      return response.data;
    });
};

const generateHeadlines = (cycle_id) => {
  return axios
    .post(API_URL + "generate-headlines", { cycle_id })
    .then((response) => {
      return response.data;
    });
};

const HeadlineService = {
  getHeadlineDealsByOEM,
  getHeadlineSIMODeals,
  getTotalHeadlineDeals,
  getTotalDraftHeadlineDeals,
  getTotalUnapprovedHeadlineDeals,
  updateHeadlineDeals,
  updateAddOnHeadlineDeals,
  updateHeadlineSIMODeals,
  createHeadlines,
  createHeadlinesAddOn,
  getPreviousMonth,
  deleteHeadlineDeal,
  generateProtoHeadlines,
  generateHeadlines,
  submitForApproval,
  publishHeadlines,
};

export default HeadlineService;
