// React Imports
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

// Component Imports
import Footer from "../../components/footer/footer-component";
import InputField from "../../components/input/input-component";

// Asset Imports
import logo from "../../assets/vodafone-logo-icon.svg";

export default function ForgotPassword() {
  return (
    <div className="flex min-h-screen flex-col sm:bg-sign-in sm:bg-cover sm:bg-center">
      <main className="grid grid-cols-6 grid-rows-1 sm:container sm:mx-auto sm:mt-auto sm:py-5 lg:grid-cols-8 xl:grid-cols-10 2xl:grid-cols-12">
        <section
          aria-label="Sign in block"
          className="col-span-6 bg-white py-12 text-center sm:rounded-2xl sm:shadow lg:col-start-2 xl:col-start-3 2xl:col-start-4"
        >
          <img
            src={logo}
            alt="Vodacom Logo"
            width={150}
            height={150}
            className="mx-auto mb-7"
          />
          <h1 className="px-8 text-3xl font-bold leading-tight sm:px-12 sm:text-4xl md:col-start-2 md:text-5xl">
            Can't remember your password?
          </h1>
          <div className="grid grid-cols-4 px-8 sm:px-12 md:grid-cols-6 md:px-0">
            <p className="col-span-4 row-span-1 row-start-2 mt-3 md:col-start-2">
              Enter your email address in the field below and we'll send you a
              password reset email to get you back in.
            </p>
            <form className="col-span-4 row-span-1 row-start-3 mt-8 flex flex-col gap-3 text-start md:col-start-2">
              <InputField
                type="email"
                inputLabel="Email Address"
                placeholder="Email Address"
              ></InputField>
              <Button
                variant="contained"
                className="mt-3"
                type="submit"
                disableFocusRipple
              >
                Send password reset email
              </Button>
              <Button
                variant="text"
                color="secondary"
                LinkComponent={Link}
                to="/"
                disableFocusRipple
              >
                Cancel
              </Button>
            </form>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
