const NODE_ENV = process.env.NODE_ENV || "local";

const ENV = {
  nodeEnv: NODE_ENV,
};

const config = {
  env: ENV,
};

export default config;
