// React Imports
import { useIMask } from "react-imask";
import { useCallback, useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";
import { getDealCycleParameters, getDealCycleParametersData, updateReviewState } from "../../../slices/cycleConfigCanvas";

// MUI Imports
import {
  Button,
  FormControlLabel,
  InputAdornment, 
  Switch,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Icon Imports
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import XCircleIcon from "../../../assets/icons/x-circle-icon.svg";
import { useParams } from "react-router-dom";

export default function DealCycleParametersReview({
  setTabValue,
  handleSnackOpen,
  setReviewState,
  reviewStatus
}) {
  const { cycle } = useParams();
  const navigate = useNavigate();

  // Data Source
  const data = useSelector(getDealCycleParametersData);

  // Redux dispatch
  const dispatch = useDispatch();

  // State
  const [editedData, setEditedData] = useState(data);
  const [message, setMessage] = useState("");

  const fetchData = useCallback(() => {
    dispatch(getDealCycleParameters({ cycle }));
  }, [dispatch, cycle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data.length > 0) {
      setEditedData(data)
    }
  }, [data])

  // On Approval function to trigger tab change and success snackbar popup
  const onApprove = () => {
    dispatch(updateReviewState({ data: {
      config_status: "A",
      reviewed: "Y",
      reason_rejected: null,
      config_set: "DEAL_CYCLE_PARAMETERS",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }))

    handleSnackOpen("success", "Deal Cycle Parameters has been approved.");
    handleClickChangeTab();
  };

  // On Reject save function to trigger tab change and success snackbar popup
  const onRejectSave = () => {
    dispatch(updateReviewState({ data: {
      config_status: "R",
      reviewed: "Y",
      reason_rejected: message,
      config_set: "DEAL_CYCLE_PARAMETERS",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }));

    handleSnackOpen("success", "Deal Cycle Parameters has been rejected.");
    handleClickChangeTab();
  }

  // Change Tab on Parent Component
  const handleClickChangeTab = () => {
    setTabValue(1);
  };

  // Number masking state
  const maskOptions = {
    mask: "R num",
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: ".",
      },
    },
  };
  const { ref: inputRef } = useIMask(maskOptions);
  
  // Rejected State
  const [rejected, setRejected] = useState(false)

  // Handle reject button
  const handleRejectChange = () => {
    setRejected(!rejected);
  }

  if (editedData.length > 0) {
    return (
      <>
        <div className="grid grid-cols-12">
          <div className="col-start-1 lg:col-start-3 col-end-13 lg:col-end-10 shadow bg-white rounded-2xl p-12 mb-8">
            <h3 className="text-lg text-red-100 font-bold">VAT</h3>
            <hr className="border-grey-100 my-4" />
            <div className="flex items-end gap-4">
              <div className="grow">
                <InputField 
                  id={editedData[0].vat.id}
                  inputLabel="Amount" 
                  placeholder="Enter percentage" 
                  value={editedData[0].vat.value}
                  InputProps={{
                    endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                    readOnly: true
                  }}
                  disabled={editedData[0].vat.active === "N" ? true : false}
                />
              </div>
              <div className="shrink-0 mb-1 pb-px">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={editedData[0].vat.active === "Y" ? true : false}
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                />
              </div>
            </div>

            <h3 className="text-lg text-red-100 font-bold mt-8">Terms</h3>
              <hr className="border-grey-100 my-4" />
              {editedData[1].terms.map((row, index) => (
                <div className="flex items-end gap-4 mb-4" key={index}>
                  <div className="grow">
                    <InputField 
                      id={row.term}
                      name="term"
                      inputLabel="Term" 
                      placeholder="Term" 
                      value={row.term}
                      disabled={true}
                    />
                  </div>
                  <div className="grow">
                    <InputField 
                      id={row.term}
                      name="dealMarkup"
                      inputLabel="Deal Markup" 
                      placeholder="Enter Deal Markup" 
                      value={row.dealMarkup}
                      disabled={row.active === "N" ? true : false}
                      inputProps={{
                        readOnly: true
                      }}
                    />
                  </div>
                  <div className="shrink-0 mb-1">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="active"
                          checked={row.active === "Y" ? true : false}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </div>
                </div>
              ))}

              <h3 className="text-lg text-red-100 font-bold mt-8">Deal Price Multipliers</h3>
              <hr className="border-grey-100 my-4" />
              {editedData[2].dealPriceMultipliers.map((row, index) => (
                <div className="flex items-end gap-4 mb-4" key={index}>
                  <div className="grow">
                    <InputField 
                      name="value"
                      inputLabel={row.term + " Month"}
                      placeholder="Enter Multiplier" 
                      value={row.value}
                      disabled={row.active === "N" ? true : false}
                      inputProps={{
                        readOnly: true
                      }}                  
                    />
                  </div>
                  <div className="shrink-0 mb-1">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="active"
                          checked={row.active === "Y" ? true : false}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </div>
                </div>
              ))}

              <h3 className="text-lg text-red-100 font-bold mt-8">Financials</h3>
              <hr className="border-grey-100 my-4" />
              {editedData[3].financials.map((row, index) => (
                <div className="flex items-end gap-4 mb-4" key={index}>
                  <div className="grow">
                    <InputField 
                      name="value"
                      inputLabel={row.type}
                      inputRef={row.valueType === "currency" ? inputRef : null}
                      placeholder="Enter Multiplier" 
                      defaultValue={row.value}
                      disabled={row.active === "N" ? true : false}   
                      inputProps={{
                        readOnly: true
                      }}
                    />
                  </div>
                  <div className="shrink-0 mb-1">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="active"
                          checked={row.active === "Y" ? true : false}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </div>
                </div>
              ))}

              <h3 className="text-lg text-red-100 font-bold mt-8">Approval Efficiency Threshold</h3>
              <hr className="border-grey-100 my-4" />
              {editedData[4].approvals.map((row, index) => (
                <div className="flex items-end gap-4 mb-4" key={index}>
                  <div className="grow">
                    <InputField 
                      name="value"
                      inputLabel={row.label}
                      inputRef={row.valueType === "currency" ? inputRef : null}
                      placeholder="Enter Efficiency Threshold" 
                      defaultValue={row.value}
                      disabled={row.active === "N" ? true : false} 
                      inputProps={{
                        readOnly: true
                      }}
                    />
                  </div>
                  <div className="shrink-0 mb-1">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="active"
                          checked={row.active === "Y" ? true : false}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

      {(!setReviewState && !reviewStatus) && 
        (<div className="grid grid-cols-12 mb-12">
          <div className={`col-start-1 col-end-13 ${rejected && "lg:col-start-3 lg:col-end-10"}`}>
            <div className="flex justify-center gap-2 md:justify-end">
              <Button 
                color="error"
                variant="outlined" 
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={XCircleIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                onClick={handleRejectChange}
                disabled={rejected}
              >
                Reject
              </Button>
              <Button 
                color="success"
                variant="contained" 
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={TickOutlineCircle}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                onClick={onApprove}
              >
                Approve
              </Button>
            </div>

            {rejected &&
              <div className="flex flex-col gap-6">
                <InputField
                  inputLabel="Reason"
                  placeholder="Enter your comments here..."
                  multiline
                  minRows={2}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />

                <Button 
                  variant="text"
                  disableFocusRipple 
                  className="text-red-100 self-end"
                  onClick={onRejectSave}
                >
                    Save
                </Button>
              </div>
            }
          </div>
        </div>)
      }
      </>
    );
  }
}
