export const OEMData = [
  {
    id: 1,
    name: "Samsung",
    logo: "assets/logos/Samsung.svg",
  },
  {
    id: 2,
    name: "Apple",
    logo: "assets/logos/Apple.svg",
  },
  {
    id: 3,
    name: "Nokia",
    logo: "assets/logos/Nokia.svg",
  },
  {
    id: 4,
    name: "Sony",
    logo: "assets/logos/Sony.svg",
  },
  {
    id: 5,
    name: "LG",
    logo: "assets/logos/LG.svg",
  },
  {
    id: 6,
    name: "Xiaomi",
    logo: "assets/logos/Xiaomi.svg",
  },
  {
    id: 7,
    name: "Oppo",
    logo: "assets/logos/Oppo.svg",
  },
  {
    id: 8,
    name: "Vivo",
    logo: "assets/logos/Vivo.svg",
  },
  {
    id: 9,
    name: "Huawei",
    logo: "assets/logos/Huawei.svg",
  },
  {
    id: 10,
    name: "Honor",
    logo: "assets/logos/Honor.svg",
  },
  {
    id: 11,
    name: "Hisense",
    logo: "assets/logos/Hisense.svg",
  },
  {
    id: 12,
    name: "TECNO",
    logo: "assets/logos/Tecno-Mobile.svg",
  },
  {
    id: 13,
    name: "Alcatel",
    logo: "assets/logos/Alcatel.svg",
  },
  {
    id: 14,
    name: "Asus",
    logo: "assets/logos/Asus.svg",
  },
  {
    id: 15,
    name: "CAT",
    logo: "assets/logos/Cat.svg",
  },
  {
    id: 16,
    name: "CellSell",
    logo: null,
  },
  {
    id: 17,
    name: "Cernotech",
    logo: null,
  },
  {
    id: 18,
    name: "Duxbury",
    logo: null,
  },
  {
    id: 19,
    name: "Evercomm",
    logo: null,
  },
  {
    id: 20,
    name: "HP",
    logo: "assets/logos/Hp.svg",
  },
  {
    id: 21,
    name: "Lenovo",
    logo: "assets/logos/Lenovo.svg",
  },
  {
    id: 22,
    name: "Itel",
    logo: "assets/logos/Itel.svg",
  },
  {
    id: 23,
    name: "MSI",
    logo: "assets/logos/MSI.svg",
  },
  {
    id: 24,
    name: "Mecer",
    logo: "assets/logos/Mecer.svg",
  },
  {
    id: 25,
    name: "Microsoft",
    logo: "assets/logos/Microsoft.svg",
  },
  {
    id: 26,
    name: "Instacom",
    logo: null,
  },
  {
    id: 27,
    name: "IT Masters",
    logo: null,
  },
  {
    id: 28,
    name: "Mobicel",
    logo: null,
  },
  {
    id: 29,
    name: "ZTE",
    logo: "assets/logos/ZTE.svg",
  },
  {
    id: 30,
    name: "Nexio",
    logo: null,
  },
  {
    id: 31,
    name: "Nokia-Lucent",
    logo: null,
  },
  {
    id: 32,
    name: "Nology",
    logo: null,
  },
  {
    id: 33,
    name: "Proline",
    logo: null,
  },
  {
    id: 34,
    name: "TCL",
    logo: "assets/logos/TCL.svg",
  },
];

export const DialogText = {
  SUBMIT_HEADLINES: {
    title: "Are you sure you want to submit headlines for approval?",
    message: `This action will submit headlines for approval, which will mark headlines that breach effiency as "Waiting for Approval".
              The Headlines view will then only display deals that have been "Rejected" that require changes.`,
  },
  PUBLISH_HEADLINES: {
    title: "Are you sure you want to publish headlines?",
    message: `This action will publish headlines, it is the final step for headlines 
              and will transition the cycle into it's next phase "Channel Deals".`,
  },
};
