// React Imports
import { useIMask } from "react-imask";
import { useEffect, useId, useState } from "react";

// Mui Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

// Redux Store Imports
import { useDispatch } from "react-redux";
import {
  addDealComponent,
  removeDealComponent,
  updateDealComponent,
  updateHeadlineDeal,
} from "../../slices/dealCanvas";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";
import InputField from "../input/input-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import FavouriteIcon from "../../assets/icons/favourite.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import SaveIcon from "../../assets/icons/save-icon.svg";
import AccessoryIcon from "../../assets/icons/connected-devices-icon.svg";
import DeviceIcon from "../../assets/icons/device-icon.svg";
import SimIcon from "../../assets/icons/sim-icon.svg";
import GiftIcon from "../../assets/icons/gift-icon.svg";
import RouterIcon from "../../assets/icons/router-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import ErrorSolidIcon from "../../assets/icons/error-circle-icon.svg";
import HierarchyIcon from "../../assets/icons/hierarchy-icon.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

// Mock Data Imports
import { Link, useParams } from "react-router-dom";

// Info popover element (used to display EFF, INT, and CTC)
// const InfoPopover = (props) => {
//   const { trigger, title } = props;
//   return (
//     <Tooltip
//       id={uuidv4() + "-info-popover"}
//       disableHoverListener
//       title={title}
//       placement="top"
//     >
//       {trigger}
//     </Tooltip>
//   );
// };

// Number masking state
export const maskOptions = {
  mask: "R num",
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      thousandsSeparator: ",",
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: ".",
    },
  },
};

// Headline deal info (Device, Previous Month, Current Month and Supplier Tariff Ambitions)
export default function HeadlineDealInfo(props) {
  const {
    id,
    addOns,
    multiAccessory,
    multiDevice,
    multiTariff,
    multiRouter,
    //addedVAS,
    device,
    specs = {
      size: null,
      ram: null,
      rom: null,
      sim: null,
    },
    previousDealCycle,
    currentDealCycle,
    supplierAmbitions,
    dealType,
    newDevice,
    //totalBasketPrice = 5999,
    tariff24,
    tariff30,
    tariff36,
    expandContent,
    terminalsUser,
    //dataHasChanged,
    childrenDeals,
    deviceOptions,
    accessoryOptions,
    tariffOptions,
    routerOptions,
    vasOptions,
    cardGlobalLevel,
    termSpecific,
    createDeal,
    createAddOnDeal,
    createButtonDisable = false,
    deleteDealTerm,
    deal,
    view,
    isSIMO = false,
    confirmSupplierAmbitions,
    user = {},
  } = props;

  // Get the Cycle and Channel information from the URL
  const { cycle, channel, channel_id } = useParams();
  const { business_unit } = user;

  // Redux dispatch
  const dispatch = useDispatch();

  const dataHasChanged = false; // Note: need to add variables to check data has changed per input field

  const accessoriesAvailable = accessoryOptions.length > 0;

  const [_supplierAmbitions, setSupplierAmbitions] =
    useState(supplierAmbitions);
    const [_ctd, ] = useState(0);
    const [_term24, ] = useState(0);
    const [_term36, ] = useState(0);

  // Unique IDs
  const uid = useId();

  const { main_device_basket_id: device_basket_id, supplier_ctd: supplierCTD } =
    cardGlobalLevel;
  const { total_basket_price: totalBasketPrice } = cardGlobalLevel;
  const [
    {
      ctc: term24CTC,
      efficiency: term24Efficiency,
      tariff_code: tariffCode24,
      tariff_basket_desc: tariffDesc24,
      tariff_basket_id: tariffbasketId24,
      tariff_sub: tariffSub24,
      tariff_discount: tariffDiscount24,
      device_tariff_differential: tariffDifferential24,
      dde_parent_key: ddeParentKey24,
      supplier_deal_price: supplierDealPrice24,
      is_confirmed: isConfirmed24,
      std_vas_items: standardVAS24,
      add_vas_items: addedVAS24,
      approval_reason: reason24,
    },
  ] =
    termSpecific.filter((v) => v.term === 24).length === 1
      ? termSpecific.filter((v) => v.term === 24)
      : [
          {
            ctc: null,
            efficiency: null,
            tariff_code: null,
            tariff_basket_desc: null,
            tariff_basket_id: null,
            tariff_sub: null,
            tariff_discount: null,
            dde_parent_key: null,
            supplier_deal_price: null,
            is_confirmed: null,
          },
        ];

  const [
    {
      ctc: term30CTC,
      efficiency: term30Efficiency,
      tariff_code: tariffCode30,
      tariff_basket_desc: tariffDesc30,
      tariff_basket_id: tariffbasketId30,
      tariff_sub: tariffSub30,
      tariff_discount: tariffDiscount30,
      device_tariff_differential: tariffDifferential30,
      dde_parent_key: ddeParentKey30,
      supplier_deal_price: supplierDealPrice30,
      is_confirmed: isConfirmed30,
      std_vas_items: standardVAS30,
      add_vas_items: addedVAS30,
      approval_action: dealStatus30,
      approval_reason: reason30,
    },
  ] =
    termSpecific.filter((v) => v.term === 30).length === 1
      ? termSpecific.filter((v) => v.term === 30)
      : [
          {
            ctc: null,
            efficiency: null,
            tariff_code: null,
            tariff_basket_desc: null,
            tariff_basket_id: null,
            tariff_sub: null,
            tariff_discount: null,
            dde_parent_key: null,
            supplier_deal_price: null,
            is_confirmed: null,
          },
        ];
  const [
    {
      ctc: term36CTC,
      efficiency: term36Efficiency,
      tariff_code: tariffCode36,
      tariff_basket_desc: tariffDesc36,
      tariff_basket_id: tariffbasketId36,
      tariff_sub: tariffSub36,
      tariff_discount: tariffDiscount36,
      device_tariff_differential: tariffDifferential36,
      dde_parent_key: ddeParentKey36,
      supplier_deal_price: supplierDealPrice36,
      is_confirmed: isConfirmed36,
      std_vas_items: standardVAS36,
      add_vas_items: addedVAS36,
      approval_action: dealStatus36,
      approval_reason: reason36,
    },
  ] =
    termSpecific.filter((v) => v.term === 36).length === 1
      ? termSpecific.filter((v) => v.term === 36)
      : [
          {
            ctc: null,
            efficiency: null,
            tariff_code: null,
            tariff_basket_desc: null,
            tariff_basket_id: null,
            tariff_sub: null,
            tariff_discount: null,
            dde_parent_key: null,
            supplier_deal_price: null,
            is_confirmed: null,
          },
        ];

  const [currentCtd, setCurrentCtd] = useState(
    terminalsUser
      ? isConfirmed24 || isConfirmed30 || isConfirmed36
        ? parseInt(currentDealCycle.ctd) === 0
          ? ""
          : currentDealCycle.ctd
        : 0
      : parseInt(currentDealCycle.ctd) === 0
      ? ""
      : currentDealCycle.ctd
  );
  const [current24, setCurrent24] = useState(
    terminalsUser
      ? isConfirmed24
        ? currentDealCycle.term24
        : 0
      : currentDealCycle.term24
  );
  const [current30, setCurrent30] = useState(
    terminalsUser
      ? isConfirmed30
        ? currentDealCycle.term30
        : 0
      : currentDealCycle.term30
  );
  const [current36, setCurrent36] = useState(
    terminalsUser
      ? isConfirmed36
        ? currentDealCycle.term36
        : 0
      : currentDealCycle.term36
  );
  const [currentSupplierCtd, setCurrentSupplierCtd] = useState(supplierCTD);
  const [currentSupplier24, setCurrentSupplier24] =
    useState(supplierDealPrice24);
  const [currentSupplier30, setCurrentSupplier30] =
    useState(supplierDealPrice30);
  const [currentSupplier36, setCurrentSupplier36] =
    useState(supplierDealPrice36);
  const [currentCTC24, setCurrentCTC24] = useState(term24CTC);
  const [currentCTC30, setCurrentCTC30] = useState(term30CTC);
  const [currentCTC36, setCurrentCTC36] = useState(term36CTC);
  const [currentEFF24, setCurrentEFF24] = useState(term24Efficiency);
  const [currentEFF30, setCurrentEFF30] = useState(term30Efficiency);
  const [currentEFF36, setCurrentEFF36] = useState(term36Efficiency);
  //const [tariffCode24, setTariffCode24] = useState(currentDealCycle.term24);
  //const [tariffCode36, setTariffCode36] = useState(currentDealCycle.term36);

  const { ref: refCurrentCtd } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrentCtd(value);
    },
  });
  const { ref: refCurrent24 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrent24(value);
    },
  });
  const { ref: refCurrent30 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrent30(value);
    },
  });
  const { ref: refCurrent36 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrent36(value);
    },
  });
  const { ref: refSupplierCtd } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrentSupplierCtd(value);
    },
  });
  const { ref: refSupplier24 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrentSupplier24(value);
    },
  });
  const { ref: refSupplier30 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrentSupplier30(value);
    },
  });
  const { ref: refSupplier36 } = useIMask(maskOptions, {
    onAccept: (value) => {
      setCurrentSupplier36(value);
    },
  });

  // Change the tooltip background depending on the EFF calculation
  // NOTE: This is a placeholder until we have the actual calculation.
  // const effCalc = _supplierAmbitions.eff;
  // const effCalc36 = _supplierAmbitions.eff36;
  // let supplierAmbitionsTooltipBG;
  // let supplierAmbitionsTooltipBG36;

  // if (effCalc >= 0 && effCalc < 60) {
  //   supplierAmbitionsTooltipBG =
  //     "bg-gradient-145 from-green-gradient-start to-green-gradient-end";
  // } else if (effCalc >= 60 && effCalc < 80) {
  //   supplierAmbitionsTooltipBG =
  //     "bg-gradient-145 from-yellow-gradient-start to-yellow-gradient-end";
  // } else if (effCalc >= 80 || effCalc < 0) {
  //   supplierAmbitionsTooltipBG =
  //     "bg-gradient-145 from-red-gradient-start to-red-gradient-end";
  // }

  // if (effCalc36 >= 0 && effCalc36 < 60) {
  //   supplierAmbitionsTooltipBG36 =
  //     "bg-gradient-145 from-green-gradient-start to-green-gradient-end";
  // } else if (effCalc36 >= 60 && effCalc36 < 80) {
  //   supplierAmbitionsTooltipBG36 =
  //     "bg-gradient-145 from-yellow-gradient-start to-yellow-gradient-end";
  // } else if (effCalc36 >= 80 || effCalc36 < 0) {
  //   supplierAmbitionsTooltipBG36 =
  //     "bg-gradient-145 from-red-gradient-start to-red-gradient-end";
  // }

  // Information for Supplier Ambition input fields
  // const supplierAmbitionsTooltip = (
  //   <>
  //     <div
  //       className={`flex items-center gap-2 divide-x divide-white/[.35] rounded-lg px-4 py-3 text-center text-base text-white ${supplierAmbitionsTooltipBG}`}
  //     >
  //       <div>
  //         <span className="font-bold">Term 24:</span>
  //       </div>
  //       <div className="pl-2">
  //         <span className="font-bold">EFF:</span> {_supplierAmbitions.eff}%
  //       </div>
  //       <div className="pl-2">
  //         <span className="font-bold">INT:</span> {_supplierAmbitions.int}%
  //       </div>
  //       <div className="pl-2">
  //         <span className="font-bold">CTC:</span>{" "}
  //         {_supplierAmbitions.ctc.toLocaleString("en-ZA", {
  //           style: "currency",
  //           currency: "ZAR",
  //         })}
  //       </div>
  //     </div>
  //     <br />
  //     <div
  //       className={`flex items-center gap-2 divide-x divide-white/[.35] rounded-lg px-4 py-3 text-center text-base text-white ${supplierAmbitionsTooltipBG36}`}
  //     >
  //       <div>
  //         <span className="font-bold">Term 36:</span>
  //       </div>
  //       <div className="pl-2">
  //         <span className="font-bold">EFF:</span> {_supplierAmbitions.eff36}%
  //       </div>
  //       <div className="pl-2">
  //         <span className="font-bold">INT:</span> {_supplierAmbitions.int36}%
  //       </div>
  //       <div className="pl-2">
  //         <span className="font-bold">CTC:</span>{" "}
  //         {_supplierAmbitions.ctc36.toLocaleString("en-ZA", {
  //           style: "currency",
  //           currency: "ZAR",
  //         })}
  //       </div>
  //     </div>
  //   </>
  // );

  // Dialogs
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setRemoveDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  // Handle input fields
  const handleCurrentCtdChange = (event, dealID, dealType) => {
    let value = event.target.value;
    const { readOnly } = event.target;

    if (readOnly) {
      return;
    }

    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: value,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        deal,
      })
    );
  };
  const handleCurrent24Change = (event, dealID, dealType) => {
    let value = event.target.value;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: value,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        deal,
      })
    );
  };
  const handleCurrent30Change = (event, dealID, dealType) => {
    let value = event.target.value;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: value,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        deal,
      })
    );
  };
  const handleCurrent36Change = (event, dealID, dealType) => {
    let value = event.target.value;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: value,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        deal,
      })
    );
  };
  // Supplier Ambitions

  const handleSupplierCtdChange = (event, dealID, dealType) => {
    let value = event.target.value;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        currentSupplierCtd: value,
        currentSupplier24: supplierDealPrice24,
        currentSupplier30: supplierDealPrice30,
        currentSupplier36: supplierDealPrice36,
        deal
      })
    );
  };

  const handleSupplierTerm24Change = (event, dealID, dealType) => {
    let value = event.target.value;
    // setTerm24String(value);
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        currentSupplierCtd: supplierCTD,
        currentSupplier24: value,
        currentSupplier30: supplierDealPrice30,
        currentSupplier36: supplierDealPrice36,
        deal
      })
    );
  };

  const handleSupplierTerm30Change = (event, dealID, dealType) => {
    let value = event.target.value;
    // setTerm24String(value);
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        currentSupplierCtd: supplierCTD,
        currentSupplier24: supplierDealPrice24,
        currentSupplier30: value,
        currentSupplier36: supplierDealPrice36,
        deal
      })
    );
  };

  const handleSupplierTerm36Change = (event, dealID, dealType) => {
    let value = event.target.value;
    // setTerm36String(value);
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");
    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        currentSupplierCtd: supplierCTD,
        currentSupplier24: supplierDealPrice24,
        currentSupplier30: supplierDealPrice30,
        currentSupplier36: value,
        deal
      })
    );
  };

  const handleTariffCode24Change = (event, dealID, dealType) => {
    const { value } = event.target;
    const tariff = tariffOptions.find((option) => option.value === value);

    let dealPrice = currentDealCycle.term24;
    if (business_unit === "VBU") {
      // DP + (New TDF - Old TDF)
      dealPrice =
        dealPrice +
        (parseFloat(tariff.device_tariff_differential) - tariffDifferential24);
      setCurrent24(dealPrice);
    }

    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: dealPrice,
        current30: currentDealCycle.term30,
        current36: currentDealCycle.term36,
        tariffCode24: tariff,
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        currentSupplierCtd: supplierCTD,
        currentSupplier24: supplierDealPrice24,
        currentSupplier30: supplierDealPrice30,
        currentSupplier36: supplierDealPrice36,
        deal
      })
    );
  };

  const handleTariffCode30Change = (event, dealID, dealType) => {
    const { value } = event.target;
    const tariff = tariffOptions.find((option) => option.value === value);

    let dealPrice = currentDealCycle.term30;
    if (business_unit === "VBU") {
      // DP + (New TDF - Old TDF)
      dealPrice =
        dealPrice +
        (parseFloat(tariff.device_tariff_differential) - tariffDifferential30);
      setCurrent30(dealPrice);
    }

    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: dealPrice,
        current36: currentDealCycle.term36,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: tariff,
        tariffCode36: {
          value: tariffCode36,
          tariff_sub: tariffSub36,
          deal_tariff_discount: tariffDiscount36,
        },
        currentSupplierCtd: supplierCTD,
        currentSupplier24: supplierDealPrice24,
        currentSupplier30: supplierDealPrice30,
        currentSupplier36: supplierDealPrice36,
        deal
      })
    );
  };

  const handleTariffCode36Change = (event, dealID, dealType) => {
    const { value } = event.target;
    const tariff = tariffOptions.find((option) => option.value === value);

    let dealPrice = currentDealCycle.term36;
    if (business_unit === "VBU") {
      // DP + (New TDF - Old TDF)
      dealPrice =
        dealPrice +
        (parseFloat(tariff.device_tariff_differential) - tariffDifferential36);
      setCurrent36(dealPrice);
    }

    dispatch(
      updateHeadlineDeal({
        id: dealID,
        type: dealType,
        currentCtd: currentDealCycle.ctd,
        current24: currentDealCycle.term24,
        current30: currentDealCycle.term30,
        current36: dealPrice,
        tariffCode24: {
          value: tariffCode24,
          tariff_sub: tariffSub24,
          deal_tariff_discount: tariffDiscount24,
        },
        tariffCode30: {
          value: tariffCode30,
          tariff_sub: tariffSub30,
          deal_tariff_discount: tariffDiscount30,
        },
        tariffCode36: tariff,
        currentSupplierCtd: supplierCTD,
        currentSupplier24: supplierDealPrice24,
        currentSupplier30: supplierDealPrice30,
        currentSupplier36: supplierDealPrice36,
        deal
      })
    );
  };
  // const handleSupplierAmbitionsMerge = (dealID, dealType) => {
  //   dispatch(
  //     updateHeadlineDeal({
  //       id: dealID,
  //       type: dealType,
  //       currentCtd: _ctd,
  //       current24: _term24,
  //       current36: _term36,
  //     })
  //   );
  //   setCurrentCtd(_ctdString);
  //   setCurrent24(_term24String);
  //   setCurrent36(_term36String);
  // };

  useEffect(() => {
    calculateSupplierAmbitions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ctd, _term24, _term36]);

  const calculateSupplierAmbitions = () => {
    const ctd = _ctd ?? 0;

    const { tariffSub, deviceCost, tariffDiscount } = _supplierAmbitions;

    const cashPayIn = 0;
    const channelSupport = 0;
    const vat = 1.15;
    const markup = 1.2;

    const calculateCTC = (term) => {
      let ctc = 0,
        dealPrice = 0;
      if (term === 24) {
        dealPrice = _term24;
      } else {
        dealPrice = _term36;
      }

      if (
        dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount)) >
        0
      ) {
        ctc =
          parseFloat(deviceCost) -
          cashPayIn -
          ctd -
          channelSupport -
          ((dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount))) *
            term) /
            vat /
            markup;
      } else {
        //ctc = parseFloat(deviceCost) - ctd;
        ctc =
          parseFloat(deviceCost) -
          cashPayIn -
          ctd -
          channelSupport -
          ((dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount))) *
            term) /
            vat;
      }
      return ctc;
    };

    const calculateEFF = (ctc, term) => {
      return (
        (ctc /
          (((parseFloat(tariffSub) + parseFloat(tariffDiscount)) * term) /
            vat)) *
        100
      ).toFixed(2);
    };

    // Calculate CTC
    const ctc = calculateCTC(24);
    const ctc36 = calculateCTC(36);

    // Calculate Efficiency
    const eff = calculateEFF(ctc, 24);
    const eff36 = calculateEFF(ctc36, 36);

    // Calculate Intensity
    // TODO: ..
    const int = 0;
    const int36 = 0;

    setSupplierAmbitions({
      ..._supplierAmbitions,
      ctc,
      eff,
      int,
      ctc36,
      eff36,
      int36,
    });
  };

  useEffect(() => {
    calculateCurrentDeal();
    
    // eslint-disable-next-line
  }, [
    currentDealCycle.ctd,
    currentDealCycle.term24,
    currentDealCycle.term30,
    currentDealCycle.term36,
    tariffSub24,
    tariffSub30,
    tariffSub36,
  ]);

  const calculateCurrentDeal = () => {
    const ctd = currentDealCycle.ctd ?? 0;

    const { deviceCost } = _supplierAmbitions;

    const cashPayIn = 0;
    const channelSupport = 0;
    const vat = 1.15;
    const markup = 1.2;

    const calculateCTC = (term) => {
      let ctc = 0,
        dealPrice = 0,
        tariffSub = 0,
        tariffDiscount = 0;
      if (term === 24) {
        dealPrice = currentDealCycle.term24;
        tariffSub = tariffSub24;
        tariffDiscount = tariffDiscount24;
      } else if (term === 30) {
        dealPrice = currentDealCycle.term30;
        tariffSub = tariffSub30;
        tariffDiscount = tariffDiscount30;
      } else if (term === 36) {
        dealPrice = currentDealCycle.term36;
        tariffSub = tariffSub36;
        tariffDiscount = tariffDiscount36;
      }

      if (
        dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount)) >
        0
      ) {
        ctc =
          parseFloat(deviceCost) -
          cashPayIn -
          ctd -
          channelSupport -
          ((dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount))) *
            term) /
            vat /
            markup;
        // console.log(
        //   "Device Cost: ",
        //   deviceCost,
        //   "CashPayIn: ",
        //   cashPayIn,
        //   "CTD: ",
        //   ctd,
        //   "Channel Support: ",
        //   channelSupport,
        //   "Deal Price: ",
        //   dealPrice,
        //   "Tariff Sub: ",
        //   tariffSub,
        //   "Tariff Discount: ",
        //   tariffDiscount,
        //   "Term: ",
        //   term,
        //   "VAT: ",
        //   vat,
        //   "Markup: ",
        //   markup
        // );
      } else {
        //ctc = parseFloat(deviceCost) - ctd;
        ctc =
          parseFloat(deviceCost) -
          cashPayIn -
          ctd -
          channelSupport -
          ((dealPrice - (parseFloat(tariffSub) + parseFloat(tariffDiscount))) *
            term) /
            vat;

        // console.log(
        //   "Device Cost: ",
        //   deviceCost,
        //   "CashPayIn: ",
        //   cashPayIn,
        //   "CTD: ",
        //   ctd,
        //   "Channel Support: ",
        //   channelSupport,
        //   "Deal Price: ",
        //   dealPrice,
        //   "Tariff Sub: ",
        //   tariffSub,
        //   "Tariff Discount: ",
        //   tariffDiscount,
        //   "Term: ",
        //   term,
        //   "VAT: ",
        //   vat,
        //   "Markup: ",
        //   null
        // );
      }
      return ctc;
    };

    const calculateEFF = (ctc, term) => {
      let tariffSub = 0,
        tariffDiscount = 0;
      if (term === 24) {
        tariffSub = tariffSub24;
        tariffDiscount = tariffDiscount24;
      } else if (term === 30) {
        tariffSub = tariffSub30;
        tariffDiscount = tariffDiscount30;
      } else if (term === 36) {
        tariffSub = tariffSub36;
        tariffDiscount = tariffDiscount36;
      }
      return (
        (ctc /
          (((parseFloat(tariffSub) + parseFloat(tariffDiscount)) * term) /
            vat)) *
        100
      ).toFixed(2);
    };

    // Calculate CTC
    const ctc = calculateCTC(24);
    const ctc30 = calculateCTC(30);
    const ctc36 = calculateCTC(36);

    // Calculate Efficiency
    const eff = calculateEFF(ctc, 24);
    const eff30 = calculateEFF(ctc30, 30);
    const eff36 = calculateEFF(ctc36, 36);

    setCurrentCTC24(ctc);
    setCurrentCTC30(ctc30);
    setCurrentCTC36(ctc36);
    setCurrentEFF24(eff);
    setCurrentEFF30(eff30);
    setCurrentEFF36(eff36);
  };

  // More options for each deal card
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);
  const moreOptionsOpen1 = Boolean(anchorEl1);
  const moreOptionsOpen2 = Boolean(anchorEl2);
  const moreOptionsOpen3 = Boolean(anchorEl3);

  // Handle options open event
  const handleOptionsOpen = (event, control = 0) => {
    switch (control) {
      case 1:
        setAnchorEl1(event.currentTarget);
        break;
      case 2:
        setAnchorEl2(event.currentTarget);
        break;
      case 3:
        setAnchorEl3(event.currentTarget);
        break;
      default:
        setAnchorEl(event.currentTarget);
    }
  };
  // Handle options close event
  const handleOptionsClose = (control = 0) => {
    switch (control) {
      case 1:
        setAnchorEl1(null);
        break;
      case 2:
        setAnchorEl2(null);
        break;
      case 3:
        setAnchorEl3(null);
        break;
      default:
        setAnchorEl(null);
    }
  };

  // Handle Add Component
  const handleAddComponent = (id, component, dealType, termIndex = -1) => {
    dispatch(
      addDealComponent({
        id: id,
        type: dealType,
        component: component,
        deal,
        termIndex,
      })
    );
    handleOptionsClose();
  };
  const handleUpdateComponent = (
    id,
    component,
    dealType,
    data,
    termIndex = -1
  ) => {
    dispatch(
      updateDealComponent({
        id: id,
        type: dealType,
        component: component,
        data: data,
        deal,
        termIndex,
      })
    );
  };

  // Set Component
  const [componentOption, setComponentOption] = useState("");
  const [componentIndex, setComponentIndex] = useState(null);

  // Handle Remove Component
  const handleRemoveComponent = (id, dealType, component, index) => {
    dispatch(
      removeDealComponent({
        id: id,
        type: dealType,
        component: component,
        index: index,
        deal,
      })
    );
    handleOptionsClose();
    props.messageFromActions("success", "Add-on deleted successfully.");
  };

  // Set which tariff to remove
  const [tariffToRemove, setTariffToRemove] = useState(null);
  const [termToRemove, setTermToRemove] = useState(null);
  const [ddeParentKeyToRemove, setDDEParentKeyToRemove] = useState(null);

  // Handle warning dialog for removal of tariffs
  const handleRemoveTariffDialog = (tariff, term, ddeParentKey) => {
    setDeleteDialogOpen(true);
    setTariffToRemove(tariff);
    setTermToRemove(term);
    setDDEParentKeyToRemove(ddeParentKey);
  };

  // Handle tariff removal
  const handleTariffRemove = (
    dealID,
    dealType,
    term,
    tariffBasketId,
    ddeParentKey
  ) => {
    deleteDealTerm(dealID, dealType, term, tariffBasketId, ddeParentKey);
  };

  // Covert text to Sentence Case hanset
  // const convertToSentenceCase = (inputText) => {
  //   if (!inputText) return '';
  //   // Split the text into sentences by using periods as the delimiter.
  //   const sentences = inputText.split('. ');

  //   // Capitalize the first letter of each sentence and join them back with a period and space.
  //   const sentenceCaseText = sentences
  //     .map((sentence) => {
  //       if (sentence.length > 0) {
  //         return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  //       }
  //       return sentence;
  //     })
  //     .join('. ');

  //   return sentenceCaseText;
  // };

  // Expand/Collapse Supplier Ambitions Content
  const [supplierAmbitionsExpand, setSupplierAmbitionsExpand] = useState(false);
  const handleSupplierExpandContent = () => {
    setSupplierAmbitionsExpand(!supplierAmbitionsExpand);
  };

  return (
    <>
      <div
        className={`flex w-full flex-col ${expandContent ? "gap-6" : "gap-4"}`}
      >
        <div className="flex items-center justify-center gap-2">
          {newDevice && (
            <Tooltip title="New Device">
              <img
                src={FavouriteIcon}
                width={24}
                height={24}
                alt="New Device"
              />
            </Tooltip>
          )}
          <div className="flex flex-col items-center gap-1 text-center">
            <h2 id={id} className="text-xl font-bold text-red-100">
              {device}
            </h2>
            <div className="mt-1 flex items-center gap-2 text-sm font-normal text-black">
              {specs.size && <span className="divider-dot">{specs.size}</span>}
              {specs.ram && <span className="divider-dot">{specs.ram}</span>}
              {specs.rom && <span className="divider-dot">{specs.rom}</span>}
              {specs.sim && <span className="divider-dot">{specs.sim}</span>}
            </div>
          </div>
        </div>

        {addOns && (
          <>
            {/* Accessories */}
            {multiAccessory &&
              multiAccessory.length > 0 &&
              (expandContent && !terminalsUser ? (
                <>
                  <div className="flex items-center justify-center gap-x-2">
                    <hr className="flex-1 border-grey-100" />
                    <p className="text-base font-bold text-red-100">
                      + Accessory
                    </p>
                    <hr className="flex-1 border-grey-100" />
                  </div>
                  {multiAccessory.map((accessory, index) => (
                    <div key={index} className="flex items-center gap-1">
                      <div className="flex-1">
                        <InputField
                          select
                          id={accessory.id}
                          value={accessory.cid || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) => {
                            const { value: cid } = e.target;
                            const data = { ...accessory, cid };
                            handleUpdateComponent(
                              id,
                              "multiAccessory",
                              dealType,
                              data
                            );
                          }}
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {accessoryOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>
                      <IconButton
                        aria-label="Delete accessory"
                        className="fill-black hover:fill-red-100"
                        onClick={() => {
                          setRemoveDialogOpen(true);
                          setComponentOption("multiAccessory");
                          setComponentIndex(index);
                        }}
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      </IconButton>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {multiAccessory.map((accessory) => (
                    <div
                      key={accessory.id}
                      className={`flex items-center gap-4 ${
                        terminalsUser ? "justify-center" : "justify-start"
                      }`}
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      <p className="text-base font-bold text-black">
                        {accessory.description}
                      </p>
                    </div>
                  ))}
                </>
              ))}

            {/* Devices */}
            {multiDevice &&
              multiDevice.length > 0 &&
              (expandContent && !terminalsUser ? (
                <>
                  <div className="flex items-center justify-center gap-x-2">
                    <hr className="flex-1 border-grey-100" />
                    <p className="text-base font-bold text-red-100">+ Device</p>
                    <hr className="flex-1 border-grey-100" />
                  </div>
                  {multiDevice.map((device, index) => (
                    <div key={device.id} className="flex items-center gap-1">
                      <div className="flex-1">
                        <InputField
                          select
                          id={device.id}
                          value={device.cid || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) => {
                            const { value: cid } = e.target;
                            const data = { ...device, cid };
                            handleUpdateComponent(
                              id,
                              "multiDevice",
                              dealType,
                              data
                            );
                          }}
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {deviceOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>
                      <IconButton
                        aria-label="Delete device"
                        className="fill-black hover:fill-red-100"
                        onClick={() => {
                          setRemoveDialogOpen(true);
                          setComponentOption("multiDevice");
                          setComponentIndex(index);
                        }}
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      </IconButton>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {multiDevice.map((device) => (
                    <div
                      key={device.id}
                      className={`flex items-center gap-4 ${
                        terminalsUser ? "justify-center" : "justify-start"
                      }`}
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      <p className="text-base font-bold text-black">
                        {device.description}
                      </p>
                    </div>
                  ))}
                </>
              ))}

            {/* Tariffs */}
            {multiTariff &&
              multiTariff.length > 0 &&
              (expandContent && !terminalsUser ? (
                <>
                  <div className="flex items-center justify-center gap-x-2">
                    <hr className="flex-1 border-grey-100" />
                    <p className="text-base font-bold text-red-100">+ Tariff</p>
                    <hr className="flex-1 border-grey-100" />
                  </div>
                  {multiTariff.map((tariff, index) => (
                    <div key={tariff.id} className="flex items-center gap-1">
                      <div className="flex-1">
                        <InputField
                          select
                          id={tariff.id}
                          value={tariff.tariff_code || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) => {
                            const { value: tariff_code } = e.target;
                            const data = { ...tariff, tariff_code };
                            handleUpdateComponent(
                              id,
                              "multiTariff",
                              dealType,
                              data
                            );
                          }}
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {tariffOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>
                      <IconButton
                        aria-label="Delete tariff"
                        className="fill-black hover:fill-red-100"
                        onClick={() => {
                          setRemoveDialogOpen(true);
                          setComponentOption("multiTariff");
                          setComponentIndex(index);
                        }}
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      </IconButton>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {multiTariff.map((tariff) => (
                    <div
                      key={tariff.id}
                      className={`flex items-center gap-4 ${
                        terminalsUser ? "justify-center" : "justify-start"
                      }`}
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      <p className="text-base font-bold text-black">
                        {tariff.description}
                      </p>
                    </div>
                  ))}
                </>
              ))}

            {/* Routers */}
            {multiRouter &&
              multiRouter.length > 0 &&
              (expandContent && !terminalsUser ? (
                <>
                  <div className="flex items-center justify-center gap-x-2">
                    <hr className="flex-1 border-grey-100" />
                    <p className="text-base font-bold text-red-100">+ Router</p>
                    <hr className="flex-1 border-grey-100" />
                  </div>
                  {multiRouter.map((router, index) => (
                    <div key={router.id} className="flex items-center gap-1">
                      <div className="flex-1">
                        <InputField
                          select
                          id={router.id}
                          value={router.cid || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) => {
                            const { value: cid } = e.target;
                            const data = { ...router, cid };
                            handleUpdateComponent(
                              id,
                              "multiRouter",
                              dealType,
                              data
                            );
                          }}
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {routerOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>
                      <IconButton
                        aria-label="Delete router"
                        className="fill-black hover:fill-red-100"
                        onClick={() => {
                          setRemoveDialogOpen(true);
                          setComponentOption("multiRouter");
                          setComponentIndex(index);
                        }}
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                      </IconButton>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {multiRouter.map((router) => (
                    <div
                      key={router.id}
                      className={`flex items-center gap-4 ${
                        terminalsUser ? "justify-center" : "justify-start"
                      }`}
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      <p className="text-base font-bold text-black">
                        {router.description}
                      </p>
                    </div>
                  ))}
                </>
              ))}

            {/* Add add-ons button */}
            {!terminalsUser && expandContent && (
              <>
                <IconButton
                  id={`${uid}-more-menu-trigger`}
                  aria-label="More deal options"
                  aria-controls={
                    moreOptionsOpen ? `${uid}-more-menu` : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={moreOptionsOpen ? "true" : undefined}
                  onClick={handleOptionsOpen}
                  className="self-center bg-red-100 fill-white hover:bg-red-200 hover:fill-white"
                >
                  <InlineSVG src={PlusIcon} width={24} height={24} ariaHidden />
                </IconButton>
                <Menu
                  id={`${uid}-more-menu`}
                  anchorEl={anchorEl}
                  open={moreOptionsOpen}
                  keepMounted={true}
                  disablePortal={true}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={handleOptionsClose}
                  MenuListProps={{
                    "aria-labelledby": `${uid}-more-menu-trigger`,
                  }}
                >
                  {/* {(
                    <MenuItem
                      className="flex items-center gap-x-2"
                      disableRipple
                      onClick={() =>
                        handleAddComponent(id, "addedVAS", dealType)
                      }
                    >
                      <InlineSVG
                        src={GiftIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      Add VAS
                    </MenuItem>
                  )} */}
                  {accessoriesAvailable ? (
                    <MenuItem
                      className="flex items-center gap-x-2"
                      disableRipple
                      onClick={() =>
                        handleAddComponent(id, "multiAccessory", dealType)
                      }
                    >
                      <InlineSVG
                        src={AccessoryIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      Add accessory
                    </MenuItem>
                  ) : (
                    <></>
                  )}

                  {!isSIMO && (
                    <MenuItem
                      className="flex items-center gap-x-2"
                      disableRipple
                      onClick={() =>
                        handleAddComponent(id, "multiDevice", dealType)
                      }
                    >
                      <InlineSVG
                        src={DeviceIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-red-100"
                      />
                      Add device
                    </MenuItem>
                  )}
                  <MenuItem
                    className="flex items-center gap-x-2"
                    disableRipple
                    onClick={() =>
                      handleAddComponent(id, "multiTariff", dealType)
                    }
                  >
                    <InlineSVG
                      src={SimIcon}
                      ariaHidden
                      width={24}
                      height={24}
                      className="fill-red-100"
                    />
                    Add tariff
                  </MenuItem>
                  <MenuItem
                    className="flex items-center gap-x-2"
                    disableRipple
                    onClick={() =>
                      handleAddComponent(id, "multiRouter", dealType)
                    }
                  >
                    <InlineSVG
                      src={RouterIcon}
                      width={24}
                      height={24}
                      ariaHidden
                      className="fill-red-100"
                    />
                    Add router
                  </MenuItem>
                </Menu>
              </>
            )}
          </>
        )}

        {(deal.type || deal.deal_type || deal.data_tariff_type || deal.cardGlobalLevel.tariff_type) && (
          <div className="flex flex-wrap items-center justify-center gap-4">
            {deal.type && (
              <div className="rounded-full border border-solid border-green-200 bg-green-200/10 px-4 py-2 text-sm font-medium text-green-200">
                {deal.type}
              </div>
            )}  
            {deal.cardGlobalLevel.tariff_type && (
              <div className="rounded-full border border-solid border-blue-200 bg-blue-200/10 px-4 py-2 text-sm font-medium text-blue-200">
                {deal.cardGlobalLevel.tariff_type === "D" ? "Data" : "Voice"}
              </div>
            )}
            {deal.deal_type && (
              <div className="rounded-full border border-solid border-red-100 bg-red-60/10 px-4 py-2 text-sm font-medium text-red-100">
                {deal.deal_type}
              </div>
            )}
            {deal.data_tariff_type && (
              <div className="rounded-full border border-solid border-yellow-200 bg-yellow-100/10 px-4 py-2 text-sm font-medium text-yellow-200">
                {deal.data_tariff_type}
              </div>
            )}
          </div>
        )}

        {!createDeal && !isSIMO && (
          <>
            {!terminalsUser && (
              <div
                className={`flex justify-between gap-4 ${
                  expandContent ? "my-4" : "my-0"
                }`}
              >
                <span className="font-bold">Total Basket Price</span>
                <span>
                  {parseFloat(totalBasketPrice).toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </span>
              </div>
            )}
          </>
        )}

        {!createDeal && !isSIMO && (
          <>
            <div className="flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-center text-base font-bold text-red-100">
                Previous Month Deals
              </p>
              <hr className="flex-1 border-grey-100" />
            </div>

            <div
              className={`grid grid-cols-3 gap-4 ${
                expandContent ? "my-2" : "my-0"
              }`}
            >
              <div className="col-span-1">
                <p className="font-bold">CTD</p>
                <p className="text-grey-500">
                  {previousDealCycle.ctd.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </p>
              </div>
              <div className="col-span-1">
                <p className="font-bold">24 months</p>
                <p className="text-grey-500">
                  {previousDealCycle.term24.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </p>
              </div>
              <div className="col-span-1">
                <p className="font-bold">36 months</p>
                <p className="text-grey-500">
                  {previousDealCycle.term36.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </p>
              </div>
            </div>
          </>
        )}

        {terminalsUser && (
          <>
            <div className="flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-center text-base font-bold text-red-100">
                Supplier Tariff Ambitions
              </p>
              <hr className="flex-1 border-grey-100" />

              <IconButton
                aria-label="Expand/Collapse deal information"
                className={`ml-auto text-[24px] text-grey-300 transition-transform duration-150 ease-linear ${
                  supplierAmbitionsExpand ? "rotate-180" : "rotate-0"
                }`}
                onClick={handleSupplierExpandContent}
              >
                <ExpandMoreRoundedIcon />
              </IconButton>
            </div>

            {supplierAmbitionsExpand && (
              <>
                {expandContent ? (
                  <InputField
                    inputLabel="CTD"
                    inputRef={refSupplierCtd}
                    placeholder="R 0,00"
                    value={currentSupplierCtd || ""}
                    onBlur={(event) =>
                      handleSupplierCtdChange(event, id, dealType)
                    }
                  />
                ) : (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">CTD</span>
                    <span>
                      {currentSupplierCtd.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </span>
                  </div>
                )}

                {tariffCode24 && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-center text-base font-bold text-black">
                        24 month
                      </p>
                      <hr className="flex-1 border-grey-100" />
                      {/* {terminalsUser && (
                    <IconButton
                      aria-label="Delete 24 Month Term"
                      className="fill-red-100 hover:fill-red-200"
                      onClick={() => setDeleteDialogOpen(true)}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                  )} */}
                    </div>

                    <div className="flex justify-between gap-4">
                      <span className="font-bold">Tariff</span>
                      <span>{tariffDesc24}</span>
                    </div>

                    {expandContent ? (
                      <InputField
                        inputLabel="Deal price"
                        inputRef={refSupplier24}
                        placeholder="R 0,00"
                        value={currentSupplier24 || ""}
                        onBlur={(event) =>
                          handleSupplierTerm24Change(event, id, dealType)
                        }
                      />
                    ) : (
                      <div className="flex justify-between gap-4">
                        <span className="font-bold">Deal price</span>
                        <span>
                          {currentSupplier24.toLocaleString("en-ZA", {
                            style: "currency",
                            currency: "ZAR",
                          })}
                        </span>
                      </div>
                    )}
                  </>
                )}

                {tariffCode30 && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-center text-base font-bold text-black">
                        30 month
                      </p>
                      <hr className="flex-1 border-grey-100" />
                      {/* {terminalsUser && (
                    <IconButton
                      aria-label="Delete 30 Month Term"
                      className="fill-red-100 hover:fill-red-200"
                      onClick={() => setDeleteDialogOpen(true)}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                  )} */}
                    </div>

                    <div className="flex justify-between gap-4">
                      <span className="font-bold">Tariff</span>
                      <span>{tariffDesc30}</span>
                    </div>

                    {expandContent ? (
                      <InputField
                        inputLabel="Deal price"
                        inputRef={refSupplier30}
                        placeholder="R 0,00"
                        value={currentSupplier30 || ""}
                        onBlur={(event) =>
                          handleSupplierTerm30Change(event, id, dealType)
                        }
                      />
                    ) : (
                      <div className="flex justify-between gap-4">
                        <span className="font-bold">Deal price</span>
                        <span>
                          {currentSupplier30.toLocaleString("en-ZA", {
                            style: "currency",
                            currency: "ZAR",
                          })}
                        </span>
                      </div>
                    )}
                  </>
                )}

                {tariffCode36 && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-center text-base font-bold text-black">
                        36 month
                      </p>
                      <hr className="flex-1 border-grey-100" />
                      {/* {terminalsUser && (
                    <IconButton
                      aria-label="Delete 36 Month Term"
                      className="fill-red-100 hover:fill-red-200"
                      onClick={() => setDeleteDialogOpen(true)}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                  )} */}
                    </div>

                    <div className="flex justify-between gap-4">
                      <span className="font-bold">Tariff</span>
                      <span>{tariffDesc36}</span>
                    </div>

                    {expandContent ? (
                      <InputField
                        inputLabel="Deal price"
                        inputRef={refSupplier36}
                        placeholder="R 0,00"
                        value={currentSupplier36 || ""}
                        onBlur={(event) =>
                          handleSupplierTerm36Change(event, id, dealType)
                        }
                      />
                    ) : (
                      <div className="flex justify-between gap-4">
                        <span className="font-bold">Deal price</span>
                        <span>
                          {currentSupplier36.toLocaleString("en-ZA", {
                            style: "currency",
                            currency: "ZAR",
                          })}
                        </span>
                      </div>
                    )}
                  </>
                )}

                <Button
                  variant="text"
                  disableFocusRipple
                  className="mt-4 gap-1 fill-red-100 text-red-100 hover:text-red-100"
                  startIcon={
                    <InlineSVG
                      src={SaveIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  }
                  onClick={() => confirmSupplierAmbitions(id)}
                >
                  Confirm Supplier Ambitions
                </Button>
              </>
            )}
          </>
        )}

        {!createDeal && !isSIMO && (
          <>
            <div className="flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-center text-base font-bold text-red-100">
                Current Month Deals
              </p>
              <hr className="flex-1 border-grey-100" />
            </div>
          </>
        )}
        {!createDeal && !isSIMO && (
          <>
            {expandContent ? (
              <InputField
                inputLabel="CTD"
                inputRef={refCurrentCtd}
                placeholder="R 0,00"
                value={currentCtd || ""}
                onBlur={(event) => handleCurrentCtdChange(event, id, dealType)}
                inputProps={{
                  readOnly: view === "channels" || terminalsUser,
                }}
              />
            ) : (
              <div className="flex justify-between gap-4">
                <span className="font-bold">CTD</span>
                <span>
                  {currentCtd.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </span>
              </div>
            )}
          </>
        )}

        {tariffCode24 && (
          <>
            <div className="flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-center text-base font-bold text-black">
                24 month
              </p>
              <hr className="flex-1 border-grey-100" />
              {(tariffCode30 || tariffCode36) && !terminalsUser && (
                <IconButton
                  aria-label="Delete 24 Month Term"
                  className="fill-red-100 disabled:fill-grey-300 hover:fill-red-200 disabled:hover:fill-grey-300"
                  onClick={() => {
                    handleRemoveTariffDialog(
                      tariffbasketId24,
                      24,
                      ddeParentKey24
                    );
                  }}
                >
                  <InlineSVG
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                </IconButton>
              )}
            </div>
            {/* VAS */}
            {standardVAS24 && standardVAS24?.length > 0 && (
              <>
                <div className="flex items-center justify-center gap-x-2">
                  <hr className="flex-1 border-grey-100" />
                  <p className="text-base font-bold text-red-100">
                    Standard VASes
                  </p>
                  <hr className="flex-1 border-grey-100" />
                </div>
                {standardVAS24?.map((vas, index) => (
                  <div key={index} className="flex items-center gap-1">
                    <div className="flex-1">
                      <InputField
                        value={vas.vas_desc}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
            {addOns && (
              <>
                {addedVAS24 && addedVAS24?.length > 0 && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-base font-bold text-red-100">
                        Added VASes
                      </p>
                      <hr className="flex-1 border-grey-100" />
                    </div>
                    {addedVAS24?.map((vas, index) => (
                      <div key={index} className="flex items-center gap-1">
                        <div className="flex-1">
                          <InputField
                            select
                            id={index}
                            value={vas.vas_code || "placeholder"}
                            SelectProps={{
                              IconComponent: (props) => (
                                <ArrowDropDownRoundedIcon {...props} />
                              ),
                            }}
                            onChange={(e) => {
                              const { value: vas_code } = e.target;
                              const data = { ...vas, vas_code };
                              handleUpdateComponent(
                                id,
                                "addedVAS24",
                                dealType,
                                data,
                                0
                              );
                            }}
                          >
                            <MenuItem disabled value="placeholder">
                              - Select -
                            </MenuItem>
                            {vasOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </InputField>
                        </div>
                        {/* <IconButton
                      aria-label="Delete vas"
                      className="fill-black hover:fill-red-100"
                      onClick={() => {
                        setRemoveDialogOpen(true);
                        setComponentOption("addedVAS");
                        setComponentIndex(index);
                      }}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton> */}
                      </div>
                    ))}
                  </>
                )}
                {standardVAS24?.length + addedVAS24?.length < 5 && (
                  <>
                    <IconButton
                      id={`${uid}-more-menu-trigger-1`}
                      aria-label="More deal options"
                      aria-controls={
                        moreOptionsOpen1 ? `${uid}-more-menu-1` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={moreOptionsOpen1 ? "true" : undefined}
                      onClick={(e) => handleOptionsOpen(e, 1)}
                      className="self-center bg-red-100 fill-white hover:bg-red-200 hover:fill-white"
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                    <Menu
                      id={`${uid}-more-menu-1`}
                      anchorEl={anchorEl1}
                      open={moreOptionsOpen1}
                      keepMounted={true}
                      disablePortal={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={() => handleOptionsClose(1)}
                      MenuListProps={{
                        "aria-labelledby": `${uid}-more-menu-trigger-1`,
                      }}
                    >
                      {
                        <MenuItem
                          className="flex items-center gap-x-2"
                          disableRipple
                          onClick={() =>
                            handleAddComponent(id, "addedVAS", dealType, 0)
                          }
                        >
                          <InlineSVG
                            src={GiftIcon}
                            width={24}
                            height={24}
                            ariaHidden
                            className="fill-red-100"
                          />
                          Add VAS
                        </MenuItem>
                      }
                    </Menu>
                  </>
                )}
              </>
            )}

            {expandContent && !terminalsUser ? (
              <InputField
                select
                inputLabel="Tariff"
                id={uid + "tariff_option"}
                value={tariff24 || "placeholder"}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                }}
                onChange={(event) =>
                  handleTariffCode24Change(event, id, dealType)
                }
                inputProps={{
                  readOnly: view === "channels",
                }}
              >
                <MenuItem disabled value="placeholder">
                  - Select -
                </MenuItem>
                {tariffOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            ) : (
              <div className="flex justify-between gap-4">
                <span className="font-bold">Tariff</span>
                <span>{tariffDesc24}</span>
              </div>
            )}

            {!createDeal && (
              <>
                {expandContent ? (
                  dataHasChanged ? (
                    <div className="flex items-center gap-4">
                      <InlineSVG
                        src={ErrorSolidIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-yellow-200"
                      />
                      <div className="flex-1">
                        <InputField
                          inputLabel="Deal price"
                          inputRef={refCurrent24}
                          placeholder="R 0,00"
                          value={current24 || ""}
                          onBlur={(event) =>
                            handleCurrent24Change(event, id, dealType)
                          }
                          warning={true}
                          inputProps={{
                            readOnly: terminalsUser,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <InputField
                        inputLabel="Deal price"
                        inputRef={refCurrent24}
                        placeholder="R 0,00"
                        value={current24 || ""}
                        onBlur={(event) =>
                          handleCurrent24Change(event, id, dealType)
                        }
                        inputProps={{
                          readOnly: terminalsUser,
                        }}
                      />
                      {reason24 && (
                        <InputField
                          inputLabel="Reason"
                          multiline
                          minRows={2}
                          value={reason24}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    </>
                  )
                ) : (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">Deal price</span>
                    <span>
                      {current24.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </span>
                  </div>
                )}
              </>
            )}

            {!createDeal && (
              <>
                {!terminalsUser && (
                  <div
                    className={`grid gap-4 ${
                      expandContent ? "grid-cols-2" : "grid-cols-1"
                    }`}
                  >
                    <div
                      className={`col-span-1 ${
                        expandContent ? "" : "flex justify-between"
                      }`}
                    >
                      <p className="font-bold">CTC</p>
                      <p className="text-grey-500">
                        {currentCTC24.toLocaleString("en-ZA", {
                          style: "currency",
                          currency: "ZAR",
                        })}
                      </p>
                    </div>
                    <div
                      className={`col-span-1 ${
                        expandContent ? "text-right" : "flex justify-between"
                      }`}
                    >
                      <p className="font-bold">EFF</p>
                      <p className="text-grey-500">{currentEFF24}%</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {tariffCode30 && (
          <>
            <div className="flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-center text-base font-bold text-black">
                30 month
              </p>
              <hr className="flex-1 border-grey-100" />
              {(tariffCode24 || tariffCode36) && !terminalsUser && (
                <IconButton
                  aria-label="Delete 30 Month Term"
                  className="fill-red-100 disabled:fill-grey-300 hover:fill-red-200 disabled:hover:fill-grey-300"
                  onClick={() => {
                    handleRemoveTariffDialog(
                      tariffbasketId30,
                      30,
                      ddeParentKey30
                    );
                  }}
                >
                  <InlineSVG
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                </IconButton>
              )}
            </div>
            {/* VAS */}
            {standardVAS30 && standardVAS30?.length > 0 && (
              <>
                <div className="flex items-center justify-center gap-x-2">
                  <hr className="flex-1 border-grey-100" />
                  <p className="text-base font-bold text-red-100">
                    Standard VASes
                  </p>
                  <hr className="flex-1 border-grey-100" />
                </div>
                {standardVAS30?.map((vas, index) => (
                  <div key={index} className="flex items-center gap-1">
                    <div className="flex-1">
                      <InputField
                        value={vas.vas_desc}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}

            {addOns && (
              <>
                {addedVAS30 && addedVAS30?.length > 0 && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-base font-bold text-red-100">
                        Added VASes
                      </p>
                      <hr className="flex-1 border-grey-100" />
                    </div>
                    {addedVAS30.map((vas, index) => (
                      <div key={index} className="flex items-center gap-1">
                        <div className="flex-1">
                          <InputField
                            select
                            id={index}
                            value={vas.vas_code || "placeholder"}
                            SelectProps={{
                              IconComponent: (props) => (
                                <ArrowDropDownRoundedIcon {...props} />
                              ),
                            }}
                            onChange={(e) => {
                              const { value: vas_code } = e.target;
                              const data = { ...vas, vas_code };
                              handleUpdateComponent(
                                id,
                                "addedVAS30",
                                dealType,
                                data,
                                1
                              );
                            }}
                          >
                            <MenuItem disabled value="placeholder">
                              - Select -
                            </MenuItem>
                            {vasOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </InputField>
                        </div>
                        {/* <IconButton
                      aria-label="Delete vas"
                      className="fill-black hover:fill-red-100"
                      onClick={() => {
                        setRemoveDialogOpen(true);
                        setComponentOption("addedVAS");
                        setComponentIndex(index);
                      }}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton> */}
                      </div>
                    ))}
                  </>
                )}
                {standardVAS30?.length + addedVAS30?.length < 5 && (
                  <>
                    <IconButton
                      id={`${uid}-more-menu-trigger-2`}
                      aria-label="More deal options"
                      aria-controls={
                        moreOptionsOpen2 ? `${uid}-more-menu-2` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={moreOptionsOpen2 ? "true" : undefined}
                      onClick={(e) => handleOptionsOpen(e, 2)}
                      className="self-center bg-red-100 fill-white hover:bg-red-200 hover:fill-white"
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                    <Menu
                      id={`${uid}-more-menu-2`}
                      anchorEl={anchorEl2}
                      open={moreOptionsOpen2}
                      keepMounted={true}
                      disablePortal={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={() => handleOptionsClose(2)}
                      MenuListProps={{
                        "aria-labelledby": `${uid}-more-menu-trigger-2`,
                      }}
                    >
                      {
                        <MenuItem
                          className="flex items-center gap-x-2"
                          disableRipple
                          onClick={() =>
                            handleAddComponent(id, "addedVAS", dealType, 1)
                          }
                        >
                          <InlineSVG
                            src={GiftIcon}
                            width={24}
                            height={24}
                            ariaHidden
                            className="fill-red-100"
                          />
                          Add VAS
                        </MenuItem>
                      }
                    </Menu>
                  </>
                )}
              </>
            )}

            {expandContent && !terminalsUser ? (
              <InputField
                select
                inputLabel="Tariff"
                id={uid + "tariff_option"}
                value={tariff30 || "placeholder"}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                }}
                onChange={(event) =>
                  handleTariffCode30Change(event, id, dealType)
                }
                inputProps={{
                  readOnly: view === "channels",
                }}
              >
                <MenuItem disabled value="placeholder">
                  - Select -
                </MenuItem>
                {tariffOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            ) : (
              <div className="flex justify-between gap-4">
                <span className="font-bold">Tariff</span>
                <span>{tariffDesc30}</span>
              </div>
            )}

            {!createDeal && (
              <>
                {expandContent ? (
                  dataHasChanged ? (
                    <div className="flex items-center gap-4">
                      <InlineSVG
                        src={ErrorSolidIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-yellow-200"
                      />
                      <div className="flex-1">
                        <InputField
                          inputLabel="Deal price"
                          inputRef={refCurrent30}
                          placeholder="R 0,00"
                          value={current30 || ""}
                          onBlur={(event) =>
                            handleCurrent30Change(event, id, dealType)
                          }
                          warning={true}
                          inputProps={{
                            readOnly: terminalsUser,
                          }}
                        />
                      </div>
                    </div>
                    ) : (
                    <>
                      <InputField
                        inputLabel="Deal price"
                        inputRef={refCurrent30}
                        placeholder="R 0,00"
                        value={current30 || ""}
                        onBlur={(event) =>
                          handleCurrent30Change(event, id, dealType)
                        }
                        inputProps={{
                          readOnly: terminalsUser,
                        }}
                      />
                      {reason30 && (
                        <InputField
                          inputLabel="Reason"
                          multiline
                          minRows={2}
                          value={reason30}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    </>
                  )
                ) : (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">Deal price</span>
                    <span>
                      {current30.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </span>
                  </div>
                )}
              </>
            )}

            {!createDeal && (
              <>
                {!terminalsUser && (
                  <div
                    className={`grid gap-4 ${
                      expandContent ? "grid-cols-2" : "grid-cols-1"
                    }`}
                  >
                    <div
                      className={`col-span-1 ${
                        expandContent ? "" : "flex justify-between"
                      }`}
                    >
                      <p className="font-bold">CTC</p>
                      <p className="text-grey-500">
                        {currentCTC30.toLocaleString("en-ZA", {
                          style: "currency",
                          currency: "ZAR",
                        })}
                      </p>
                    </div>
                    <div
                      className={`col-span-1 ${
                        expandContent ? "text-right" : "flex justify-between"
                      }`}
                    >
                      <p className="font-bold">EFF</p>
                      <p className="text-grey-500">{currentEFF30}%</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {tariffCode36 && (
          <>
            <div className="flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-center text-base font-bold text-black">
                36 month
              </p>
              <hr className="flex-1 border-grey-100" />
              {tariffCode24 && !terminalsUser && (
                <IconButton
                  aria-label="Delete 36 Month Term"
                  className="fill-red-100 disabled:fill-grey-300 hover:fill-red-200 disabled:hover:fill-grey-300"
                  onClick={() => {
                    handleRemoveTariffDialog(
                      tariffbasketId36,
                      36,
                      ddeParentKey36
                    );
                  }}
                >
                  <InlineSVG
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                </IconButton>
              )}
            </div>
            {/* VAS */}
            {standardVAS36 && standardVAS36?.length > 0 && (
              <>
                <div className="flex items-center justify-center gap-x-2">
                  <hr className="flex-1 border-grey-100" />
                  <p className="text-base font-bold text-red-100">
                    Standard VASes
                  </p>
                  <hr className="flex-1 border-grey-100" />
                </div>
                {standardVAS36?.map((vas, index) => (
                  <div key={index} className="flex items-center gap-1">
                    <div className="flex-1">
                      <InputField
                        value={vas.vas_desc}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}

            {addOns && (
              <>
                {addedVAS36 && addedVAS36?.length > 0 && (
                  <>
                    <div className="flex items-center justify-center gap-x-2">
                      <hr className="flex-1 border-grey-100" />
                      <p className="text-base font-bold text-red-100">
                        Added VASes
                      </p>
                      <hr className="flex-1 border-grey-100" />
                    </div>
                    {addedVAS36?.map((vas, index) => (
                      <div key={index} className="flex items-center gap-1">
                        <div className="flex-1">
                          <InputField
                            select
                            id={index}
                            value={vas.vas_code || "placeholder"}
                            SelectProps={{
                              IconComponent: (props) => (
                                <ArrowDropDownRoundedIcon {...props} />
                              ),
                            }}
                            onChange={(e) => {
                              const { value: vas_code } = e.target;
                              const data = { ...vas, vas_code };
                              handleUpdateComponent(
                                id,
                                "addedVAS36?",
                                dealType,
                                data,
                                2
                              );
                            }}
                          >
                            <MenuItem disabled value="placeholder">
                              - Select -
                            </MenuItem>
                            {vasOptions.map((option, index) => (
                              <MenuItem key={index} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </InputField>
                        </div>
                        {/* <IconButton
                      aria-label="Delete vas"
                      className="fill-black hover:fill-red-100"
                      onClick={() => {
                        setRemoveDialogOpen(true);
                        setComponentOption("addedVAS");
                        setComponentIndex(index);
                      }}
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton> */}
                      </div>
                    ))}
                  </>
                )}
                {standardVAS36?.length + addedVAS36?.length < 5 && (
                  <>
                    <IconButton
                      id={`${uid}-more-menu-trigger-3`}
                      aria-label="More deal options"
                      aria-controls={
                        moreOptionsOpen3 ? `${uid}-more-menu-3` : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={moreOptionsOpen3 ? "true" : undefined}
                      onClick={(e) => handleOptionsOpen(e, 3)}
                      className="self-center bg-red-100 fill-white hover:bg-red-200 hover:fill-white"
                    >
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                    </IconButton>
                    <Menu
                      id={`${uid}-more-menu-3`}
                      anchorEl={anchorEl3}
                      open={moreOptionsOpen3}
                      keepMounted={true}
                      disablePortal={true}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      onClose={() => handleOptionsClose(3)}
                      MenuListProps={{
                        "aria-labelledby": `${uid}-more-menu-trigger-3`,
                      }}
                    >
                      {
                        <MenuItem
                          className="flex items-center gap-x-2"
                          disableRipple
                          onClick={() =>
                            handleAddComponent(id, "addedVAS", dealType, 2)
                          }
                        >
                          <InlineSVG
                            src={GiftIcon}
                            width={24}
                            height={24}
                            ariaHidden
                            className="fill-red-100"
                          />
                          Add VAS
                        </MenuItem>
                      }
                    </Menu>
                  </>
                )}
              </>
            )}

            {expandContent && !terminalsUser ? (
              <InputField
                select
                inputLabel="Tariff"
                id={uid + "tariff_option"}
                value={tariff36 || "placeholder"}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                }}
                onChange={(event) =>
                  handleTariffCode36Change(event, id, dealType)
                }
                inputProps={{
                  readOnly: view === "channels",
                }}
              >
                <MenuItem disabled value="placeholder">
                  - Select -
                </MenuItem>
                {tariffOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            ) : (
              <div className="flex justify-between gap-4">
                <span className="font-bold">Tariff</span>
                <span>{tariffDesc36}</span>
              </div>
            )}

            {!createDeal && (
              <>
                {expandContent ? (
                  dataHasChanged ? (
                    <div className="flex items-center gap-4">
                      <InlineSVG
                        src={ErrorSolidIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-yellow-200"
                      />
                      <div className="flex-1">
                        <InputField
                          inputLabel="Deal price"
                          inputRef={refCurrent36}
                          placeholder="R 0,00"
                          value={current36 || ""}
                          onBlur={(event) =>
                            handleCurrent36Change(event, id, dealType)
                          }
                          warning={true}
                          inputProps={{
                            readOnly: terminalsUser,
                          }}
                        />
                      </div>
                    </div>
                    ) : (
                    <>
                      <InputField
                        inputLabel="Deal price"
                        inputRef={refCurrent36}
                        placeholder="R 0,00"
                        value={current36 || ""}
                        onBlur={(event) =>
                          handleCurrent36Change(event, id, dealType)
                        }
                        inputProps={{
                          readOnly: terminalsUser,
                        }}
                      />
                      {reason36 && (
                        <InputField
                          inputLabel="Reason"
                          multiline
                          minRows={2}
                          value={reason36}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    </>
                  )
                ) : (
                  <div className="flex justify-between gap-4">
                    <span className="font-bold">Deal price</span>
                    <span>
                      {current36.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </span>
                  </div>
                )}
              </>
            )}

            {!createDeal && (
              <>
                {!terminalsUser && (
                  <div
                    className={`grid gap-4 ${
                      expandContent ? "grid-cols-2" : "grid-cols-1"
                    }`}
                  >
                    <div
                      className={`col-span-1 ${
                        expandContent ? "" : "flex justify-between"
                      }`}
                    >
                      <p className="font-bold">CTC</p>
                      <p className="text-grey-500">
                        {currentCTC36.toLocaleString("en-ZA", {
                          style: "currency",
                          currency: "ZAR",
                        })}
                      </p>
                    </div>
                    <div
                      className={`col-span-1 ${
                        expandContent ? "text-right" : "flex justify-between"
                      }`}
                    >
                      <p className="font-bold">EFF</p>
                      <p className="text-grey-500">{currentEFF36}%</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {createDeal && (
          <>
            <Button
              variant="contained"
              disableFocusRipple
              className="mt-4 gap-2"
              disabled={createButtonDisable}
              startIcon={
                <InlineSVG
                  src={PlusIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              onClick={() => {
                createAddOnDeal({ id, dealType });
              }}
            >
              Create New Deal
            </Button>
          </>
        )}

        {childrenDeals && !createDeal && (
          <Button
            disableFocusRipple
            className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
            LinkComponent={Link}
            to={`/deal-cycle/${cycle}/channel-deals/${channel}/${channel_id}/${device}/${device_basket_id}`}
          >
            <InlineSVG src={HierarchyIcon} width={24} height={24} ariaHidden />
            <span className="text-base font-bold">View Children Deals</span>
          </Button>
        )}
      </div>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={removeDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "remove-dialog-title"}
      >
        <DialogTitle id={uid + "remove-dialog-title"}>
          Remove this add-on?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with permanently removing this
            add-on?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleRemoveComponent(
                id,
                dealType,
                componentOption,
                componentIndex
              );
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "delete-dialog-title"}
      >
        <DialogTitle id={uid + "delete-dialog-title"}>
          Delete this term?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to permanently delete this term from the deal. Are you
            sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleTariffRemove(
                id,
                dealType,
                termToRemove,
                tariffToRemove,
                ddeParentKeyToRemove
              );
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
