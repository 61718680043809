// React Imports
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useIMask } from "react-imask";
import { useState, useEffect, useCallback } from "react";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";
import {
  getDealCycleParameters,
  getDealCycleParametersData,
  removeData,
  updateReviewState,
  updateDealCycleParameters,
} from "../../../slices/cycleConfigCanvas";

// MUI Imports
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Icon Imports
// import PlusIcon from "../../../assets/icons/plus-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import CloseIcon from "@mui/icons-material/Close";

// Service Imports
import CycleConfigService from "../../../services/cycleConfig.service";
import AuthService from "../../../services/auth.service";

export default function DealCycleParameters({
  handleSnackOpen,
  setCancelWarningDialog,
  inReview,
  reviewFeedback,
}) {
  // Initialize navigation hook
  const navigate = useNavigate();
  const { cycle } = useParams();

  // Data Source
  const data = useSelector(getDealCycleParametersData);

  // Redux dispatch
  const dispatch = useDispatch();

  // State
  const [editedData, setEditedData] = useState(data);

  const fetchData = useCallback(() => {
    dispatch(getDealCycleParameters({ cycle }));
  }, [dispatch, cycle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data.length > 0) {
      setEditedData(data);
    }
  }, [data]);

  // Form Submission hook from react-hook-form
  const { handleSubmit } = useForm();

  // Function to update changes
  const handleInputChange = (event, catId) => {
    const { name, value } = event.target;

    const updatedData = editedData.map((item) => {
      // Update VAT
      if (item.vat && item.vat.id === catId) {
        return {
          ...item,
          vat: {
            ...item.vat,
            [name]: value,
            isChanged: true,
          },
        };
      }
      // Update Terms and Markups
      if (item.terms) {
        const updatedTerms = item.terms.map((term) => {
          if (term.id === catId) {
            return {
              ...term,
              value: value,
              markup: true,
              isChanged: true,
            };
          }
          return term;
        });
        return {
          ...item,
          terms: updatedTerms,
        };
      }
      // Update Deal Price Multipliers
      if (item.dealPriceMultipliers) {
        const updatedMultipliers = item.dealPriceMultipliers.map(
          (multiplier) => {
            if (multiplier.id === catId) {
              return {
                ...multiplier,
                [name]: value,
                multiplier: true,
                isChanged: true,
              };
            }
            return multiplier;
          }
        );
        return {
          ...item,
          dealPriceMultipliers: updatedMultipliers,
        };
      }
      // Update Financials
      if (item.financials) {
        const updatedFinancials = item.financials.map((financial) => {
          if (financial.id === catId) {
            return {
              ...financial,
              [name]:
                financial.valueType === "currency"
                  ? parseFloat(value.replace(/[^0-9.]/g, ""))
                  : value,
              financial: true,
              isChanged: true,
            };
          }
          return financial;
        });
        return {
          ...item,
          financials: updatedFinancials,
        };
      }
      // Update Approvals
      if (item.approvals) {
        const updatedApprovals = item.approvals.map((approval) => {
          if (approval.id === catId) {
            return {
              ...approval,
              [name]: value,
              approval: true,
              isChanged: true,
            };
          }
          return approval;
        });
        return {
          ...item,
          financials: updatedApprovals,
        };
      }
      return item;
    });

    setEditedData(updatedData);
  };

  const handleToggleChange = (event, catId) => {
    const { name, checked } = event.target;

    dispatch(
      updateDealCycleParameters({
        data: {
          name,
          checked,
          catId,
        },
        object: "dealCycleParameters",
      })
    );
  };

  // On Submit function to trigger tab change and success snackbar popup
  const onSubmit = () => {
    const cycleParameters = [];

    // Use the filter method to find objects with "isChanged" set to true
    editedData.map((item) => {
      if (item.vat && item.vat.isChanged) {
        cycleParameters.push(item.vat);
        return item.vat;
      }
      if (item.terms) {
        item.terms.filter((term) => {
          if (term.isChanged) {
            cycleParameters.push(term);
          }
        });
        return item.terms;
      }
      if (item.dealPriceMultipliers) {
        item.dealPriceMultipliers.filter((multiplier) => {
          if (multiplier.isChanged) {
            cycleParameters.push(multiplier);
          }
        });
        return item.dealPriceMultipliers;
      }
      if (item.financials) {
        item.financials.filter((financial) => {
          if (financial.isChanged) {
            cycleParameters.push(financial);
          }
        });
        return item.financials;
      }
      if (item.approvals) {
        item.approvals.filter((approval) => {
          if (approval.isChanged) {
            cycleParameters.push(approval);
          }
        });
        return item.approvals;
      }
      return item;
    });

    if (cycleParameters.length > 0) {
      CycleConfigService.updateDealCycleParameters(cycle, cycleParameters)
        .then((response) => {
          const { message } = response.data;
          // handleClickChangeTab();
          handleSnackOpen("success", message);
        })
        .catch((error) => {
          handleServerError(error);
        });
    } else {
      // handleSnackOpen("warning", "No changes made.");  // Orange
      handleSnackOpen("notFound", "No changes made."); // Black
    }

    handleDialogClose();
  };

  // Server Error handling
  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleSnackOpen("error", errors[0].message);
    }
  };

  // Handle add new term
  // const handleAddNewTerm = () => {
  //   const newTerm = {
  //     term: "",
  //     dealMarkup: 1,
  //     active: true
  //   }
  //   // dispatch(pushData({object: "dealCycleParameters", data: newTerm, key: "terms"}));

  //   console.log(editedData);
  // }

  // Handle remove new term
  const handleRemoveNewTerm = (termId) => {
    dispatch(
      removeData({ object: "dealCycleParameters", id: termId, key: "terms" })
    );
    handleSnackOpen("success", "Term removed successfully");
  };

  // Number masking state
  const maskOptions = {
    mask: "R num",
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: ".",
      },
    },
  };
  const { ref: inputRef } = useIMask(maskOptions);

  // Dialogs
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [unresolvedWarningDialog, setUnresolvedWarningDialog] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Handle dialog Open for Unresolved feedback warning
  const handleUnresolvedDialogOpen = () => {
    setUnresolvedWarningDialog(true);
  };

  // Store which term to remove
  const [termToRemove, setTermToRemove] = useState();

  // Handle opening remove warning
  const handleShowRemoveWarning = (id) => {
    setDeleteDialogOpen(true);
    setTermToRemove(id);
  };

  // Alert state
  const [showAlert, setShowAlert] = useState(inReview === "R" ? true : false);

  // handle resolve feedback
  const handleResolveFeedback = () => {
    dispatch(
      updateReviewState({
        data: {
          config_status: "P",
          reviewed: "N",
          reason_rejected: null,
          config_set: "DEAL_CYCLE_PARAMETERS",
          cycle: cycle,
        },
        object: "cycleReviewsData",
      })
    );
    setShowAlert(false);
    handleSnackOpen(
      "success",
      "Deal cycle parameters feedback has been resolved."
    );
  };

  // handle form submission with warning feedback if unresolved feedback
  const handleFormSubmission = (e) => {
    e.preventDefault();
    (inReview === "R" ? true : false)
      ? handleUnresolvedDialogOpen()
      : handleSubmit(onSubmit)();
  };

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-end-13 lg:col-start-3 lg:col-end-10">
          <Collapse in={showAlert}>
            <Alert
              className="mb-8 items-center gap-4 rounded-lg p-6"
              severity="warning"
              iconMapping={{
                warning: (
                  <InlineSVG
                    src={WarningIcon}
                    ariaHidden
                    width={24}
                    height={24}
                  />
                ),
              }}
              action={
                <IconButton
                  aria-label="Close Alert"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <h2 className="mb-1 text-lg font-bold">Rejected</h2>
              <p className="mb-6">{reviewFeedback}</p>
              <Link onClick={handleResolveFeedback} className="underline">
                Resolve
              </Link>
            </Alert>
          </Collapse>
        </div>
      </div>

      <form onSubmit={handleFormSubmission}>
        <div className="grid grid-cols-12">
          <div className="col-start-1 col-end-13 mb-8 rounded-2xl bg-white p-12 shadow lg:col-start-3 lg:col-end-10">
            <h3 className="text-lg font-bold text-red-100">VAT</h3>
            <hr className="my-4 border-grey-100" />
            <div className="flex items-end gap-4">
              <div className="grow">
                <InputField
                  inputLabel="Amount"
                  id={editedData[0]?.vat.id}
                  name="value"
                  placeholder="Enter percentage"
                  value={editedData[0]?.vat.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  disabled={editedData[0]?.vat.active === "N" ? true : false}
                  onChange={(e) => handleInputChange(e, editedData[0]?.vat.id)}
                />
              </div>
              <div className="mb-1 shrink-0 pb-px">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="active"
                      checked={editedData[0]?.vat.active === "Y" ? true : false}
                      onChange={(e) =>
                        handleToggleChange(e, editedData[0]?.vat.id)
                      }
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                />
              </div>
            </div>

              <h3 className="mt-8 text-lg font-bold text-red-100">Terms</h3>
              <hr className="my-4 border-grey-100" />
              {editedData[1]?.terms.map((row, index) => (
                <div className="mb-4 flex items-end gap-4" key={index}>
                  {row.newItem && (
                    <div className="mb-1 shrink-0">
                      <IconButton
                        aria-label="Remove added term"
                        onClick={() => handleShowRemoveWarning(row.id)}
                        className="fill-red-200 hover:fill-red-300"
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          ariaHidden
                          width={24}
                          height={24}
                        />
                      </IconButton>
                    </div>
                  )}
                  <div className="grow">
                    <InputField
                      id={row.term}
                      name="term"
                      inputLabel="Term"
                      placeholder="Term"
                      value={row.term}
                      disabled={true}
                      onChange={(e) => handleInputChange(e, row.id)}
                    />
                  </div>
                  <div className="grow">
                    <InputField
                      id={row.term}
                      name="dealMarkup"
                      inputLabel="Deal Markup"
                      placeholder="Enter Deal Markup"
                      value={row.value}
                      disabled={row.active === "N" ? true : false}
                      onChange={(e) => handleInputChange(e, row.id)}
                    />
                  </div>
                  <div className="mb-1 shrink-0">
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="active"
                          checked={row.active === "Y" ? true : false}
                          onChange={(e) => handleToggleChange(e, row.id)}
                        />
                      }
                      label="Active"
                      labelPlacement="start"
                    />
                  </div>
                </div>
              ))}
              {/* <div className="text-center">
                <Button
                  variant="text"
                  disableFocusRipple
                  className="gap-2 fill-red-100 text-red-100 hover:fill-red-200 hover:text-red-200"
                  // onClick={handleAddNewTerm}
                >
                  <InlineSVG src={PlusIcon} width={24} height={24} ariaHidden />
                  Add new term
                </Button>
              </div> */}

            <h3 className="mt-8 text-lg font-bold text-red-100">
              Deal Price Multipliers
            </h3>
            <hr className="my-4 border-grey-100" />
            {editedData[2]?.dealPriceMultipliers.map((row, index) => (
              <div className="mb-4 flex items-end gap-4" key={index}>
                <div className="grow">
                  <InputField
                    name="value"
                    inputLabel={row.term + " Month"}
                    placeholder="Enter Multiplier"
                    value={row.value}
                    disabled={row.active === "N" ? true : false}
                    onChange={(e) => handleInputChange(e, row.id)}
                  />
                </div>
                <div className="mb-1 shrink-0">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="active"
                        checked={row.active === "Y" ? true : false}
                        onChange={(e) => handleToggleChange(e, row.id)}
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  />
                </div>
              </div>
            ))}

            <h3 className="mt-8 text-lg font-bold text-red-100">Financials</h3>
            <hr className="my-4 border-grey-100" />
            {editedData[3]?.financials.map((row, index) => (
              <div className="mb-4 flex items-end gap-4" key={index}>
                <div className="grow">
                  <InputField
                    name="value"
                    inputLabel={row.type}
                    inputRef={row.valueType === "currency" ? inputRef : null}
                    placeholder="Enter Multiplier"
                    defaultValue={row.value}
                    disabled={row.active === "N" ? true : false}
                    onBlur={(e) => handleInputChange(e, row.id)}
                  />
                </div>
                <div className="mb-1 shrink-0">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="active"
                        checked={row.active === "Y" ? true : false}
                        onChange={(e) => handleToggleChange(e, row.id)}
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  />
                </div>
              </div>
            ))}

            <h3 className="mt-8 text-lg font-bold text-red-100">
              Approval Efficiency Threshold
            </h3>
            <hr className="my-4 border-grey-100" />
            {editedData[4]?.approvals.map((row, index) => (
              <div className="mb-4 flex items-end gap-4" key={index}>
                <div className="grow">
                  <InputField
                    name="value"
                    inputLabel={row.label}
                    inputRef={row.valueType === "currency" ? inputRef : null}
                    placeholder="Enter Efficiency Threshold"
                    defaultValue={row.value}
                    disabled={row.active === "N" ? true : false}
                    onChange={(e) => handleInputChange(e, row.id)}
                  />
                </div>
                <div className="mb-1 shrink-0">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="active"
                        checked={row.active === "Y" ? true : false}
                        onChange={(e) => handleToggleChange(e, row.id)}
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-12 flex justify-center gap-2 md:justify-end">
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setCancelWarningDialog(true)}
          >
            Cancel
          </Button>
          <Button variant="contained" disableFocusRipple type="submit">
            Save
          </Button>
        </div>
      </form>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Remove this term?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with permanently removing this
            term?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleRemoveNewTerm(termToRemove);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={unresolvedWarningDialog}
        onClose={() => setUnresolvedWarningDialog(false)}
        aria-labelledby="unresolved-warning-title"
      >
        <DialogTitle id="unresolved-warning-title" className="leading-tight">
          Continue with unresolved feedback?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to save and continue without resolving the review
            feedback. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setUnresolvedWarningDialog(false)}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleSubmit(onSubmit)();
              setUnresolvedWarningDialog(false);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
