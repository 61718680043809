// React, Hook Forms and Mui Imports
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Slide,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  addDealset,
  //getAdHocDeals,
  getCycleTab,
  setCycleId,
  setCycleTab,
  submitDealForApproval,
} from "../../../slices/dealCanvas";

// Service Imports
import DealService from "../../../services/deal.service";
import AuthService from "../../../services/auth.service";

// Component Imports
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import InputField from "../../../components/input/input-component";
import CustomDatePicker from "../../../components/datepicker/datepicker-component";
import SelectDropdownWithSearch from "../../../components/select-dropdown-with-search/select-dropdown-with-search-component";
import AdHocDealInfo from "../../../components/ad-hoc-deal-info/ad-hoc-deal-info-component";
import TabPanel, {
  a11yProps,
} from "../../../components/tab-panel/tab-panel-component";

// Animation Imports
import Lottie from "lottie-react";
import AstronautAnimation from "../../../assets/animations/astronaut-animation.json";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import EyeIcon from "../../../assets/icons/eye-icon.svg";
import TickCircleOutlineIcon from "../../../assets/icons/tick-circle-outline-icon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Additional Imports
import { CustomTabScrollButton, snackSettings } from "../../home/home-page";

// Mock Data
import {
  dealOptions,
  oemDevices,
  oemOptions,
  adhocDealset,
} from "../../../data/mock-data";
import { cycleDates } from "../../documents/documents-page";
import AdHocService from "../../../services/adhoc.service";
//import GeneralService from "../../../services/general.service";

export default function AdHocDealsOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignAdded, setCampaignAdded] = useState(0);

  // Sliding tabs
  const cycleTab = useSelector(getCycleTab);
  const [value, setValue] = useState(cycleTab);
  const [cycleUpdated, setCycleUpdated] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    localStorage.setItem("cycleTabValue", newValue);
    localStorage.setItem("cycleId", updatedDealCycle[newValue]?.cycleId);
    dispatch(setCycleTab({ value: newValue }));
    dispatch(setCycleId({ value: updatedDealCycle[newValue]?.cycleId }));
  };

  const [updatedDealCycle, setUpdatedDealCycle] = useState([]);

  useEffect(() => {
    DealService.getDealCycles()
      .then((response) => {
        setUpdatedDealCycle(response.data);
        setCycleUpdated(cycleUpdated + 1);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    AdHocService.getAdHocDealsTotals()
      .then((response) => {
        setData(response.data);
        setAdhocDealsets(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignAdded]);

  // Ad-Hoc deals grouping cards
  const [adhocDealsets, setAdhocDealsets] = useState(data);

  // Filter State
  const [filterBy, setFilterBy] = useState("all");

  // Handle create new deal set
  const handleCreateNewDealset = (data, dealsetType) => {
    dispatch(
      addDealset({ data: data, dealsetType: dealsetType, type: "adhocDeals" })
    );
  };

  // Update DATA and filters as it changes
  useEffect(() => {
    if (filterBy === "all") {
      setAdhocDealsets(data);
    } else {
      // Filter data based on selected option
      const filteredItems = data.filter((item) => {
        // Modify this condition based on your data structure and filtering logic
        return item.dealsetType === filterBy;
      });
      setAdhocDealsets(filteredItems);
    }
  }, [data, filterBy]);

  // Handle filter change
  const handleFilterBy = (event) => {
    const filterOption = event.target.value;
    setFilterBy(filterOption);
  };

  // Dialogs
  const [createDialog, setCreateDialog] = useState(false);
  const [selectDialog, setSelectDialog] = useState(false);
  const [newDealDialog, setNewDealDialog] = useState(false);
  const [importStep1Dialog, setImportStep1Dialog] = useState(false);
  const [importStep2Dialog, setImportStep2Dialog] = useState(false);

  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle Dialogs Close
  const handleCreateDialogClose = () => {
    setCreateDialog(false);
  };
  const handleSelectDialogClose = () => {
    setSelectDialog(false);
  };
  const handleNewDealDialogClose = () => {
    setNewDealDialog(false);
  };
  const handleImportStep1DialogClose = () => {
    setImportStep1Dialog(false);
  };
  const handleImportStep2DialogClose = () => {
    setImportStep2Dialog(false);
  };

  // Setting custom dealset
  const [customDealset, setCustomDealset] = useState(false);

  // Handle start new deal set (open first dialog)
  const handleStartNewDealset = (customDealset) => {
    customDealset === "custom"
      ? setCustomDealset(true)
      : setCustomDealset(false);
    setCreateDialog(true);
  };

  // Sim Only States
  const [simOnly, setSimOnly] = useState(false);
  const handleSimOnlyChange = () => {
    setSimOnly(!simOnly);
  };

  // Selected OEM and Devices from Searchable Dropdown Component
  const [selectedOEM, setSelectedOEM] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  // Handle Server Error
  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // Handle submit for Approval
  const handleSubmitForApproval = (id) => {
    dispatch(submitDealForApproval({ type: "adhocDeals", id: id }));
  };

  // Campaign Creation Variables
  const [campaignName, setCampaignName] = useState("");
  const [campaignStartDate, setCampaignStartDate] = useState(null);
  const [campaignEndDate, setCampaignEndDate] = useState(null);

  const handleCampaignNameChange = (e) => {
    const { value } = e.target;
    setCampaignName(value);
  };
  const handleCampaignStartDate = (date) => {
    setCampaignStartDate(date);
  };
  const handleCampaignEndDate = (date) => {
    setCampaignEndDate(date);
  };

  const handleCampaignCreation = () => {
    // Add 1 day offset to selected dates to mitigate Datetime Picker component bug
    const newStartDate = new Date(campaignStartDate);
    newStartDate.setDate(newStartDate.getDate() + 1);
    const newEndDate = new Date(campaignEndDate);
    newEndDate.setDate(newEndDate.getDate() + 1);

    const data = {
      campaign_name: campaignName,
      campaign_start_date: newStartDate?.toISOString().slice(0, 10),
      campaign_end_date: newEndDate?.toISOString().slice(0, 10),
    };
    AdHocService.createNewCampaign(data)
      .then(() => {
        setCampaignAdded(campaignAdded + 1);
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const handleLoadCampaign = (campaign) => {
    AdHocService.loadCampaign(campaign).then((response) => {
      const { message } = response.data;
      handleGenericSnackOpen("success", message);
    });
  };

  const handleGenerateCampaignODBC = (data) => {
    AdHocService.generateCampaignODBC(data)
      .then((response) => {
        const { message } = response;
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <section
        aria-labelledby="#adhocDealsTitle"
        className="col-start-1 col-end-13 row-start-2 px-6"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          ScrollButtonComponent={CustomTabScrollButton}
          allowScrollButtonsMobile
          aria-label="Deal cycle selection"
          className="deal-cycle-nav my-16"
        >
          {updatedDealCycle.map((cycle, index) => (
            <Tab
              key={index}
              label={cycle.date}
              {...a11yProps(index)}
              disableRipple
              className={`${cycle.active ? "" : "cycle-not-done"}`}
            />
          ))}
        </Tabs>

        {updatedDealCycle.map((cycle, index) => (
          <TabPanel key={index} value={value} index={index}>
            <TitleBreadcrumb
              title={`${cycle.date} Ad-Hoc Deals`}
              titleID="adhocDealsTitle"
              button={
                <>
                  {/* <Button
                    className="mt-6 lg:mt-0 lg:ml-auto"
                    variant="outlined"
                    disableFocusRipple
                    startIcon={
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    }
                    onClick={() => handleStartNewDealset("custom")}
                  >
                    Custom Dealset
                  </Button> */}
                  <Button
                    className="mt-6 lg:mt-0 lg:ml-4"
                    variant="contained"
                    disableFocusRipple
                    startIcon={
                      <InlineSVG
                        src={PlusIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-white"
                      />
                    }
                    onClick={() => handleStartNewDealset("campaign")}
                  >
                    Campaign Dealset
                  </Button>
                </>
              }
            />
            {adhocDealsets.length === 0 ? (
              <div className="flex items-center justify-center">
                <Lottie
                  animationData={AstronautAnimation}
                  aria-hidden="true"
                  role="presentation"
                  className="max-w-2xl"
                />
              </div>
            ) : (
              <>
                <div className="mt-8 mb-4 flex items-center justify-between">
                  <h2 className="text-3xl font-medium text-black">Dealsets</h2>
                  {/* Show the filter by option only when there is both a campaign and custom dealsets available */}
                  {data.some((item) => item.dealsetType === "custom") &&
                    data.some((item) => item.dealsetType === "campaign") && (
                      <InputField
                        inputLabel="Filter by"
                        select
                        value={filterBy}
                        id="selectDealset"
                        defaultValue="all"
                        onChange={handleFilterBy}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                        }}
                        className="w-60"
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="campaign">Campaigns</MenuItem>
                        <MenuItem value="custom">Custom Dealset</MenuItem>
                      </InputField>
                    )}
                </div>
                <div className="mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 3xl:grid-cols-3">
                  {adhocDealsets.map((card, index) => (
                    <article
                      className="col-span-1 rounded-2xl bg-white px-6 pt-8 pb-6 shadow"
                      key={index}
                    >
                      <section className="text-center">
                        <h2 className="text-base font-bold text-black">
                          {card.dealset}
                        </h2>
                        <div className="py-12">
                          <p className="text-6xl font-bold">
                            <span className="bg-gradient-to-r from-red-gradient-start to-red-gradient-end bg-clip-text text-transparent">
                              {card.totalDeals.toLocaleString("en-ZA")}
                            </span>
                          </p>
                          <p className="mt-2 text-2xl leading-normal text-black">
                            Total Deals
                          </p>
                        </div>
                      </section>
                      <footer className="flex flex-col gap-2 sm:flex-row sm:items-center">
                        <Button
                          disableFocusRipple
                          className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700 sm:mr-auto"
                          LinkComponent={Link}
                          // disabled={true}
                          to={`/adhoc-deals/${card.dealset}/${card.campaign_id}`}
                        >
                          <InlineSVG
                            src={EyeIcon}
                            width={24}
                            height={24}
                            ariaHidden
                          />
                          <span className="text-base font-bold">
                            View Deals
                          </span>
                        </Button>

                        {/* <Button
                          variant={
                            card.submitForApproval ? "contained" : "outlined"
                          }
                          disableFocusRipple
                          disabled={card.submitForApproval}
                          onClick={() => handleSubmitForApproval(card.id)}
                        >
                          {card.submitForApproval ? (
                            <div className="flex items-center gap-2">
                              <InlineSVG
                                src={TickCircleOutlineIcon}
                                width={24}
                                height={24}
                                ariaHidden
                                className="fill-grey-300"
                              />
                              <span>Submitted</span>
                            </div>
                          ) : (
                            <span>Submit for Approval</span>
                          )}
                        </Button> */}
                        {card.totalDeals > 1 && (
                          <Button
                            variant="contained"
                            disableFocusRipple
                            onClick={() => {
                              handleGenerateCampaignODBC({
                                campaign_name: card.dealset,
                                campaign_id: card.campaign_id,
                              });
                            }}
                          >
                            Generate ODBC
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          disabled={true}
                          disableFocusRipple
                          onClick={() => {
                            handleLoadCampaign(card.campaign_id);
                          }}
                        >
                          Load
                        </Button>
                      </footer>
                    </article>
                  ))}
                </div>
              </>
            )}
          </TabPanel>
        ))}
      </section>

      {/* Create Campaign / Custom Dealset */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={createDialog}
        onClose={handleCreateDialogClose}
        aria-labelledby="create-dialog-title"
      >
        <DialogTitle id="create-dialog-title">
          <Button
            variant="text"
            onClick={handleCreateDialogClose}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          {customDealset ? "Create a Custom Dealset" : "Create Campaign"}
        </DialogTitle>
        <DialogContent>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="col-span-1 md:col-span-2">
              <InputField
                id="campaignName"
                inputLabel="Campaign Name"
                placeholder="e.g., Easter Weekend Special"
                onChange={handleCampaignNameChange}
              />
            </div>
            <div className="col-span-1">
              <CustomDatePicker
                label="From"
                // onDateChange={handleCampaignStartDate}
                handleEventChange={handleCampaignStartDate}
              />
            </div>
            <div className="col-span-1">
              <CustomDatePicker
                label="To"
                // onDateChange={handleCampaignEndDate}
                handleEventChange={handleCampaignEndDate}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleCreateDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleCampaignCreation();
              handleCreateDialogClose();
            }}
          >
            Save & Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Import or New Deal */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={selectDialog}
        onClose={handleSelectDialogClose}
        aria-labelledby="select-dialog-title"
      >
        <DialogTitle id="select-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleSelectDialogClose();
              setCreateDialog(true);
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Select an Option
        </DialogTitle>
        <DialogActions
          disableSpacing
          className="flex-col items-stretch justify-start gap-4"
        >
          <Button
            variant="outlined"
            disableFocusRipple
            className="gap-2"
            onClick={() => {
              handleSelectDialogClose();
              setImportStep1Dialog(true);
            }}
          >
            <InlineSVG
              src={SearchIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-black"
            />
            Import Existing Deal
          </Button>

          <span className="text-center font-bold">or</span>

          <Button
            variant="contained"
            disableFocusRipple
            className="gap-2"
            onClick={() => {
              handleSelectDialogClose();
              setNewDealDialog(true);
            }}
          >
            <InlineSVG
              src={PlusIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-white"
            />
            Create New Deal
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import deal - Step 1 */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        open={importStep1Dialog}
        onClose={handleImportStep1DialogClose}
        aria-labelledby="import-step1-dialog-title"
      >
        <DialogTitle id="import-step1-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleImportStep1DialogClose();
              setSelectDialog(true);
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Import Deal
        </DialogTitle>
        <DialogContent>
          <form className="flex flex-col gap-4">
            <FormControlLabel
              className="ml-0 mb-2 self-start"
              control={
                <Switch
                  color="primary"
                  checked={simOnly}
                  onChange={handleSimOnlyChange}
                />
              }
              label="SIM only"
              labelPlacement="start"
            />

            <InputField
              select
              inputLabel="Deal Cycle"
              defaultValue="placeholder"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {cycleDates.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>

            {!simOnly && (
              <>
                <SelectDropdownWithSearch
                  label="OEM"
                  selectedOption={selectedOEM}
                  setSelectedOption={setSelectedOEM}
                  options={oemOptions}
                />

                <SelectDropdownWithSearch
                  label="Device"
                  selectedOption={selectedDevice}
                  setSelectedOption={setSelectedDevice}
                  disabled={selectedOEM === ""}
                  options={
                    selectedOEM !== ""
                      ? oemDevices.find((item) => item.oem === selectedOEM)
                          .devices
                      : oemDevices[0].devices
                  }
                />
              </>
            )}

            <InputField
              select
              inputLabel="Deal"
              defaultValue="placeholder"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {dealOptions.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleImportStep1DialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleImportStep1DialogClose();
              setImportStep2Dialog(true);
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import deal - Step 2 */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        open={importStep2Dialog}
        onClose={handleImportStep2DialogClose}
        aria-labelledby="import-step2-dialog-title"
      >
        <DialogTitle id="import-step2-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleImportStep2DialogClose();
              setImportStep1Dialog(true);
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Import Deal
        </DialogTitle>
        <DialogContent>
          <h3 className="mb-3 text-base font-bold text-black">
            Samsung Galaxy A12
          </h3>
          <p className="text-grey-300">
            SAMSUNG Galaxy 12 32GB+20GB X 1(C) +2 Months Free Theft cover+1GB
            VID TKT X 3 @R 699 Reduced Sub PM on Red 10GB 200min - 24 months
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleImportStep2DialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleImportStep2DialogClose();
              handleCreateNewDealset(
                adhocDealset,
                customDealset ? "custom" : "campaign"
              );
            }}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create new deal  */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={newDealDialog}
        onClose={handleNewDealDialogClose}
        aria-labelledby="new-deal-dialog-title"
      >
        <DialogTitle id="new-deal-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleNewDealDialogClose();
              setSelectDialog(true);
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Create New Deal
        </DialogTitle>
        <DialogContent>
          <AdHocDealInfo
            snackbarMessages={handleGenericSnackOpen}
            createDealForm={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleNewDealDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleNewDealDialogClose();
              handleCreateNewDealset(
                adhocDealset,
                customDealset ? "custom" : "campaign"
              );
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
