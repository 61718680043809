// React Imports
import { Route, Routes } from "react-router-dom";
import { useEffect, useCallback } from "react";
import EventBus from "./common/EventBus";

// Redux Imports
import { useSelector, useDispatch } from "react-redux";
import { logout } from "./slices/auth";

// Layout Imports
import TwelveColGrid from "./layouts/12-col-grid";

// Page Imports
import SignIn from "./pages/sign-in/sign-in-page";
import Home from "./pages/home/home-page";
import UserManagementOverview from "./pages/user-management/overview/user-management-overview";
import EditUserProfile from "./pages/user-management/edit-user/user-management-edit-user";
import AddUserProfile from "./pages/user-management/add-user/user-management-add-user";
import Documents from "./pages/documents/documents-page";
import RoleManagementOverview from "./pages/role-management/overview/role-management-overview";
import EditRole from "./pages/role-management/edit-role/role-management-edit-role";
import NewRole from "./pages/role-management/new-role/role-management-new-role";
import SystemConfiguration from "./pages/system-configuration/system-configuration-page";
import Jobs from "./pages/jobs/jobs-page";
import BulkOEMSelection from "./pages/bulk-deals/bulk-oem-selection/bulk-oem-selection-page";
import BulkDeviceList from "./pages/bulk-deals/bulk-device-list/bulk-device-list-page";
import BulkTariffList from "./pages/bulk-deals/bulk-tariff-list/bulk-tariff-list";
import BulkTariffDeals from "./pages/bulk-deals/bulk-tariff-deals/bulk-tariff-deals-page";
import BulkDealsOverview from "./pages/bulk-deals/bulk-deals-overview/bulk-deals-overview-page";
import BaseGenericOEMSelection from "./pages/base-generic-deals/base-generic-oem-selection/base-generic-oem-selection-page";
import BaseGenericDeviceList from "./pages/base-generic-deals/base-generic-device-list/base-generic-device-list-page";
import BaseGenericTariffList from "./pages/base-generic-deals/base-generic-tariff-list/base-generic-tariff-list";
import BaseGenericTariffDeals from "./pages/base-generic-deals/base-generic-tariff-deals/base-generic-tariff-deals-page";
import BaseGenericDealsOverview from "./pages/base-generic-deals/base-generic-deals-overview/base-generic-deals-overview-page";
import HeadlineOEMSelection from "./pages/headline-deals/headline-oem-selection/headline-oem-selection-page";
import HeadlineDealList from "./pages/headline-deals/headline-deal-list/headline-deal-list-page";
import AdHocDealsOverview from "./pages/ad-hoc-deals/ad-hoc-deals-overview/ad-hoc-deals-overview-page";
import AdhocNewDealset from "./pages/ad-hoc-deals/ad-hoc-deals-dealset/ad-hoc-deals-dealset-page";
import DealConfiguration from "./pages/deal-configuration/deal-configuration-page";
import DealApproval from "./pages/deal-approval/deal-approval-page";
import HeadlineDealsOverview from "./pages/headline-deals/headline-deals-overview/headline-deals-overview-page";
import HeadlineSimOnlyDeals from "./pages/headline-deals/headline-sim-only-deals/headline-sim-only-deals-page";
import Error404 from "./pages/errors/404/404-page";
import Error401 from "./pages/errors/401/401-page";
import Error500 from "./pages/errors/500/500-page";
import DealCycleConfiguration from "./pages/deal-cycle-configuration/deal-cycle-configuration-page";
import ChannelDealsOverview from "./pages/channel-deals/channel-deals-overview/channel-deals-overview-page";
import ChannelDealsDeviceList from "./pages/channel-deals/channel-deals-device-list/channel-deals-device-list-page";
import ChannelDealsSelectedDevice from "./pages/channel-deals/channel-deals-selected-device/channel-deals-selected-device-page";
import AdHocDealsChildren from "./pages/ad-hoc-deals/ad-hoc-deals-children/ad-hoc-deals-children-page";
import DealCycleConfigurationReview from "./pages/deal-cycle-configuration-review/deal-cycle-configuration-review-page";
import socketIOClient from "socket.io-client";
import Error403 from "./pages/errors/403/403-page";
import Landing from "./pages/landing/landing-page";
import Logout from "./pages/logout/logout";
import Concurrent from "./pages/errors/concurrent/concurrent-page";
import Inactive from "./pages/errors/inactive/inactive-page";
import AutoLogout from "./components/inactive-auto-logout/AutoLogout";

export const socket = socketIOClient(window.location.origin, {
  path: "/api/socket.io",
});

export default function App() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const env = process.env.REACT_APP_NODE_ENV || "local";

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      // console.log(currentUser.roles);
    }

    EventBus.on("logout", () => {
      logOut();
    });
    return () => {  
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <>
      <AutoLogout />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/signin"
          element={env === "qa" || "local" ? <SignIn /> : <></>}
        />
        <Route path="/logout" element={<Logout />} />
        {/* Session pages */}
        <Route path="/inactive" element={<Inactive />} />
        <Route path="/concurrent" element={<Concurrent />} />
        {/* Error pages */}
        <Route path="/error" element={<Error500 />} />
        <Route path="/unauthorized" element={<Error401 />} />
        <Route path="/forbidden" element={<Error403 />} />
        <Route path="/not-found" element={<Error404 />} />
        <Route element={<TwelveColGrid />}>
          <Route path="/home" element={<Home />} />
          <Route
            path="/deal-cycle/:cycle/headline-deals"
            element={<HeadlineDealsOverview />}
          />
          <Route
            path="/deal-cycle/:cycle/headline-deals/device-deals"
            element={<HeadlineOEMSelection />}
          />
          <Route
            path="/deal-cycle/:cycle/headline-deals/device-deals/:oem"
            element={<HeadlineDealList />}
          />
          <Route
            path="/deal-cycle/:cycle/headline-deals/sim-only"
            element={<HeadlineSimOnlyDeals />}
          />
          <Route
            path="/deal-cycle/:cycle/base-generic-deals"
            element={<BaseGenericDealsOverview />}
          />
          <Route
            path="/deal-cycle/:cycle/base-generic-deals/oem-selection"
            element={<BaseGenericOEMSelection />}
          />
          <Route
            path="/deal-cycle/:cycle/base-generic-deals/oem-selection/:oem"
            element={<BaseGenericDeviceList />}
          />
          <Route
            path="/deal-cycle/:cycle/base-generic-deals/oem-selection/:oem/:device"
            element={<BaseGenericTariffList />}
          />
          <Route
            path="/deal-cycle/:cycle/base-generic-deals/oem-selection/:oem/:device/:tariff"
            element={<BaseGenericTariffDeals />}
          />
          <Route
            path="/deal-cycle/:cycle/bulk-deals"
            element={<BulkDealsOverview />}
          />
          <Route
            path="/deal-cycle/:cycle/bulk-deals/:channel/oem-selection"
            element={<BulkOEMSelection />}
          />
          <Route
            path="/deal-cycle/:cycle/bulk-deals/:channel/oem-selection/:oem"
            element={<BulkDeviceList />}
          />
          <Route
            path="/deal-cycle/:cycle/bulk-deals/:channel/oem-selection/:oem/:device"
            element={<BulkTariffList />}
          />
          <Route
            path="/deal-cycle/:cycle/bulk-deals/:channel/oem-selection/:oem/:device/:tariff"
            element={<BulkTariffDeals />}
          />
          <Route
            path="/deal-cycle/:cycle/deal-approval"
            element={<DealApproval />}
          />
          <Route
            path="/deal-cycle/:cycle/configuration"
            element={<DealCycleConfiguration />}
          />
          <Route
            path="/deal-cycle/:cycle/configuration-review"
            element={<DealCycleConfigurationReview />}
          />

          <Route path="/adhoc-deals" element={<AdHocDealsOverview />} />
          <Route
            path="/adhoc-deals/:dealset/:campaign_id"
            element={<AdhocNewDealset />}
          />
          <Route
            path="/adhoc-deals/:dealset/:campaign_id/children"
            element={<AdHocDealsChildren />}
          />
          <Route
            path="/adhoc-deals/:dealset/:campaign_id/children/:dde_parent_key"
            element={<AdHocDealsChildren />}
          />

          <Route
            path="/deal-cycle/:cycle/channel-deals"
            element={<ChannelDealsOverview />}
          />
          <Route
            path="/deal-cycle/:cycle/channel-deals/:channel"
            element={<ChannelDealsDeviceList />}
          />
          <Route
            path="/deal-cycle/:cycle/channel-deals/:channel/:channel_id"
            element={<ChannelDealsDeviceList />}
          />
          <Route
            path="/deal-cycle/:cycle/channel-deals/:channel/:device"
            element={<ChannelDealsSelectedDevice />}
          />
          <Route
            path="/deal-cycle/:cycle/channel-deals/:channel/:channel_id/:device/:device_basket_id"
            element={<ChannelDealsSelectedDevice />}
          />

          <Route
            path="/settings/user-management"
            element={<UserManagementOverview />}
          />
          <Route
            path="/settings/user-management/new-user"
            element={<AddUserProfile />}
          />
          <Route
            path="/settings/user-management/user/:id"
            element={<EditUserProfile />}
          />

          <Route
            path="/settings/role-management"
            element={<RoleManagementOverview />}
          />
          <Route
            path="/settings/role-management/new-role"
            element={<NewRole />}
          />
          <Route
            path="/settings/role-management/role/:id"
            element={<EditRole />}
          />

          <Route
            path="/settings/system-configuration"
            element={<SystemConfiguration />}
          />
          <Route
            path="/settings/deal-configuration"
            element={<DealConfiguration />}
          />
          <Route path="/settings/jobs" element={<Jobs />} />

          <Route path="documents" element={<Documents />} />
        </Route>
      </Routes>
    </>
  );
}
