// React Imports
import { useState, useEffect } from "react";

// Victory Charts Imports
import { VictoryPie, VictoryAnimation, VictoryLabel } from "victory";

export default function Radialbar({
  label = null,
  color,
  value = 0,
  width = "100%",
  height = "100%",
}) {
  const getData = (percent) => {
    return [
      { x: 1, y: percent },
      { x: 2, y: 100 - percent },
    ];
  };

  const [percent, setPercent] = useState(value);
  const [data, setData] = useState(getData(0));

  useEffect(() => {
    setPercent(value);
    setData(getData(value));
  }, [value]);

  return (
    <div className="flex flex-col items-center justify-center gap-y-4 text-center">
      <svg viewBox="0 0 300 300" width={width} height={height}>
        <circle
          cx="150"
          cy="150"
          r="135"
          stroke="#D8DBE0"
          strokeWidth="30"
          fill="transparent"
        ></circle>
        <VictoryPie
          padding={0}
          standalone={false}
          animate={{ duration: 500 }}
          width={300}
          height={300}
          data={data}
          innerRadius={120}
          cornerRadius={25}
          startAngle={-180}
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => {
                return datum.x === 1 ? color : "transparent";
              },
            },
          }}
        />
        <VictoryAnimation duration={500} data={{ percent }}>
          {(props) => {
            return (
              <VictoryLabel
                textAnchor="middle"
                verticalAnchor="middle"
                x={150}
                y={150}
                text={`${Math.round(props.percent)}%`}
                style={{
                  fontSize: (300 / width) * 16,
                  fontFamily: "Roboto, sans-serif",
                  fill: color,
                }}
              />
            );
          }}
        </VictoryAnimation>
      </svg>
      {label !== null && (
        <p className="text-base font-bold text-black">{label}</p>
      )}
    </div>
  );
}
