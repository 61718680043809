// React, Mui and Hook Form Imports
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  FormGroup,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// Import Services
import AuthService from "../../../services/auth.service";
import RoleService from "../../../services/role.service";
import PermissionService from "../../../services/permission.service";

const roleStructure = {
  name: "",
  description: "",
  escalatedRole: "",
  escalatedHours: "",
  permissions: [],
};

export default function EditRole() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedRole, setSelectedRole] = useState(roleStructure);
  const [successSnack, setSuccessSnack] = useState(false);
  const [successSnackMessage, setSuccessSnackMessage] = useState("");
  const [expanded, setExpanded] = useState("feature0");
  const [permissions, setPermissions] = useState([]);
  const [permGroups, setPermGroups] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [hoursState, setHoursState] = useState(true);

  const handleSubmit = () => {
    RoleService.updateRole(id, selectedRole)
      .then(() => {
        setSuccessSnack(true);
        setSuccessSnackMessage("Role successfully updated.");
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else if (status === 405) {
          console.log(`${error}`);
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  useEffect(() => {
    RoleService.getAllRoles()
      .then((response) => {
        setRoleData(response.data);
      })
      .catch((error) => {
        console.log(`${error}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successSnack) {
      navigate("/settings/role-management", {
        state: { successSnack, successSnackMessage },
      });
    }
  }, [successSnack, successSnackMessage, navigate]);

  useEffect(() => {
    RoleService.getRoleById(id)
      .then((response) => {
        const { data } = response;
        setSelectedRole(data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    PermissionService.getAllPermissionGroups().then((response) => {
      setPermGroups(response.data);
    });
  }, []);

  useEffect(() => {
    PermissionService.getAllPermissions().then((response) => {
      setPermissions(response.data);
    });
  }, [permGroups]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNameChange = (event) => {
    const { value: name } = event.target;
    setSelectedRole({ ...selectedRole, name });
  };
  const handleDescriptionChange = (event) => {
    const { value: description } = event.target;
    setSelectedRole({ ...selectedRole, description });
  };
  const handleRoleEscalationChange = (event) => {
    const { value: escalatedRole } = event.target;
    setSelectedRole({ ...selectedRole, escalatedRole });

    setHoursState(false);
  };
  const handleHourChange = (event) => {
    const { value: hours } = event.target;
    setSelectedRole({ ...selectedRole, escalatedHours: hours });
  };
  const handlePermissionChange = (event) => {
    const { value, checked } = event.target;
    // Get current permissions
    const selectedPermissions = selectedRole.permissions;
    // Add or Remove based on Checked
    if (checked) {
      selectedPermissions.push(+value);
    } else {
      const index = selectedPermissions.indexOf(+value);
      selectedPermissions.splice(index, 1);
    }
    // Updated new role permissions
    setSelectedRole({ ...selectedRole, permissions: selectedPermissions });
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/settings/role-management"></BackButton>
      </div>
      <section
        aria-labelledby="#editNewRoleTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb title="Edit role" titleID="editNewRoleTitle" />

        <div className="my-12 grid grid-cols-10 gap-x-6">
          <div className="col-span-10 row-start-1 lg:col-span-8 lg:col-start-2">
            <InputField
              inputLabel="Role Name"
              placeholder="Role Name"
              value={selectedRole?.name}
              onChange={handleNameChange}
            ></InputField>
            <br />
            <InputField
              inputLabel="Role Description"
              placeholder="Role Description"
              value={selectedRole?.description}
              onChange={handleDescriptionChange}
            ></InputField>
            <br />
            <div className="flex gap-x-6">
              <div className="w-3/4">
                <InputField
                  select
                  inputLabel="Role Escalation"
                  defaultValue="placeholder"
                  onChange={handleRoleEscalationChange}
                  SelectProps={{
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                  }}
                >
                  <MenuItem value="placeholder">- Select -</MenuItem>
                  {roleData.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.role}
                    </MenuItem>
                  ))}
                </InputField>
              </div>
              <div className="w-1/4">
                <InputField
                  inputLabel="Hours Until Escalation"
                  placeholder="Hours"
                  value={selectedRole?.escalatedHours}
                  onChange={handleHourChange}
                  min={0}
                  disabled={hoursState}
                  type="number"
                ></InputField>
              </div>
            </div>
          </div>

          <div className="col-span-10 row-start-2 mt-8 mb-12 lg:col-span-8 lg:col-start-2">
            {permGroups.map((item, index) => (
              <Accordion
                key={`Accordion${index}`}
                expanded={expanded === `feature${index}`}
                onChange={handleAccordionChange(`feature${index}`)}
                className="bg-transparent shadow-none"
              >
                <AccordionSummary
                  key={`AccordionSummary${index}`}
                  expandIcon={<ArrowDropDownRoundedIcon />}
                  aria-controls={`feature${index}-content`}
                  id={`feature${index}-header`}
                  className="border-b border-solid border-grey-100 px-0"
                >
                  <h2 className="text-base font-bold">{item.perm_group}</h2>
                </AccordionSummary>
                <AccordionDetails
                  key={`AccordionDetails${index}`}
                  className="px-1"
                >
                  <FormGroup
                    key={`FormGroup${index}`}
                    className="grid-rows-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
                  >
                    {permissions
                      .filter((_) => _.perm_group === item.perm_group)
                      .map((perm, i) => (
                        <FormControlLabel
                          key={`FormControlLabel${i}`}
                          control={<CustomCheckbox />}
                          label={`${perm.perm_name}`}
                          value={perm.perm_id}
                          checked={selectedRole.permissions.includes(
                            perm.perm_id
                          )}
                          onChange={handlePermissionChange}
                        />
                      ))}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

          <div className="col-span-10 row-start-3 sm:col-span-5 sm:col-start-1 lg:col-span-3 lg:col-start-3 xl:col-span-2 xl:col-start-4">
            <Button
              variant="outlined"
              color="secondary"
              className="w-full"
              LinkComponent={Link}
              to={-1}
              disableFocusRipple
            >
              Cancel
            </Button>
          </div>
          <div className="col-span-10 row-start-4 mt-4 sm:col-span-5 sm:col-start-6 sm:row-start-3 sm:mt-0 lg:col-span-3 lg:col-start-6 xl:col-span-2 xl:col-start-6">
            <Button
              type="submit"
              variant="contained"
              className="w-full"
              disableFocusRipple
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
