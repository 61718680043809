// React, Mui and Hook Form Imports
import { useEffect, useState } from "react";
import { Button, ListItemText, ListSubheader, MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../../components/back-button/back-button-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// Import Services
import AuthService from "../../../services/auth.service";
import UserService from "../../../services/user.service";
import RoleService from "../../../services/role.service";

const businessUnits = [
  {
    value: "CBU",
    label: "CBU",
  },
  {
    value: "VBU",
    label: "VBU",
  },
];

const userStructure = {
  id: 0,
  firstName: "",
  surname: "",
  email: "",
  businessUnit: "",
  userRoles: [],
};

export default function AddUserProfile() {
  const navigate = useNavigate();

  const [selectedUser, SetSelectedUser] = useState(userStructure);
  const [mainRoles, setMainRoles] = useState([]);
  const [approvalRoles, setApprovalRoles] = useState([]);
  const [successSnack, setSuccessSnack] = useState(false);
  const [successSnackMessage, setSuccessSnackMessage] = useState("");

  const handleSubmit = () => {
    const user = {
      first_name: selectedUser.firstName,
      last_name: selectedUser.surname,
      email: selectedUser.email,
      business_unit: selectedUser.businessUnit,
      user_roles: selectedUser.userRoles,
    };
    UserService.createUser(user)
      .then(() => {
        setSuccessSnack(true);
        setSuccessSnackMessage("User profile successfully created.");
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  useEffect(() => {
    RoleService.getAllRolesByCategory()
      .then((response) => {
        const { data } = response;
        setMainRoles([
          ...data
            .filter((v) => v.category === "main")
            .map((m) => ({ value: m.name, label: m.description })),
        ]);
        setApprovalRoles([
          ...data
            .filter((v) => v.category === "approval")
            .map((m) => ({ value: m.name, label: m.description })),
        ]);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successSnack) {
      navigate("/settings/user-management", {
        state: { successSnack, successSnackMessage },
      });
    }
  }, [successSnack, successSnackMessage, navigate]);

  const handleFirstNameChange = (event) => {
    const { value: firstName } = event.target;
    SetSelectedUser({ ...selectedUser, firstName });
  };
  const handleSurnameChange = (event) => {
    const { value: surname } = event.target;
    SetSelectedUser({ ...selectedUser, surname });
  };
  const handleEmailChange = (event) => {
    const { value: email } = event.target;
    SetSelectedUser({ ...selectedUser, email });
  };
  const handleBusinessUnitChange = (event) => {
    const { value: businessUnit } = event.target;
    SetSelectedUser({ ...selectedUser, businessUnit });
  };
  const handleRolesChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      SetSelectedUser({ ...selectedUser, userRoles: [] });
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    SetSelectedUser({ ...selectedUser, userRoles: filteredOptions });
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/settings/user-management"></BackButton>
      </div>
      <section
        aria-labelledby="#createNewUserTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb title="Create new user" titleID="createNewUserTitle" />

        <div className="my-12 grid grid-cols-10 gap-x-6">
          <div className="col-span-10 row-start-1 flex flex-col gap-4 lg:col-span-8 lg:col-start-2 xl:col-span-6 xl:col-start-3">
            <InputField
              inputLabel="First Name"
              placeholder="First Name"
              value={selectedUser?.firstName}
              onChange={handleFirstNameChange}
            ></InputField>

            <InputField
              inputLabel="Surname"
              placeholder="Surname"
              value={selectedUser?.surname}
              onChange={handleSurnameChange}
            ></InputField>

            <InputField
              inputLabel="Email Address"
              placeholder="Email Address"
              type="email"
              value={selectedUser?.email}
              onChange={handleEmailChange}
            ></InputField>

            <InputField
              select
              inputLabel="Business Unit"
              value={selectedUser?.businessUnit}
              onChange={handleBusinessUnitChange}
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {businessUnits.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>

            <InputField
              select
              inputLabel="Roles"
              value={selectedUser?.userRoles}
              onChange={handleRolesChange}
              SelectProps={{
                multiple: true,
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
                renderValue: (selected) => {
                  let allRoles = [];
                  allRoles = [
                    ...allRoles,
                    ...mainRoles
                      .map((role) => {
                        if (selected.includes(role.value)) {
                          return role.label;
                        }
                        return null;
                      })
                      .filter((item) => item !== null),
                  ];
                  allRoles = [
                    ...allRoles,
                    ...approvalRoles
                      .map((role) => {
                        if (selected.includes(role.value)) {
                          return role.label;
                        }
                        return null;
                      })
                      .filter((item) => item !== null),
                  ];
                  return allRoles.length > 0 ? allRoles.join(", ") : "";
                },
                displayEmpty: true,
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              <ListSubheader className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black">
                Main
              </ListSubheader>
              {mainRoles.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <CustomCheckbox
                    checked={selectedUser?.userRoles.indexOf(option.value) > -1}
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
              <ListSubheader className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black">
                Approval
              </ListSubheader>
              {approvalRoles.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <CustomCheckbox
                    checked={selectedUser?.userRoles.indexOf(option.value) > -1}
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </InputField>
          </div>
          <div className="col-span-10 row-start-2 mt-4 mb-8 text-center">
            {/* <Link
              to="/settings/role-management/new-role"
              className="text-base text-blue-200 underline hover:text-blue-300"
            >
              Create new role
            </Link> */}
          </div>
          <div className="col-span-10 row-start-3 sm:col-span-5 sm:col-start-1 lg:col-span-3 lg:col-start-3 xl:col-span-2 xl:col-start-4">
            <Button
              variant="outlined"
              color="secondary"
              className="w-full"
              LinkComponent={Link}
              disableFocusRipple
              to={-1}
            >
              Cancel
            </Button>
          </div>
          <div className="col-span-10 row-start-4 mt-4 sm:col-span-5 sm:col-start-6 sm:row-start-3 sm:mt-0 lg:col-span-3 lg:col-start-6 xl:col-span-2 xl:col-start-6">
            <Button
              type="submit"
              variant="contained"
              className="w-full"
              disableFocusRipple
              onClick={handleSubmit}
            >
              Create user
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
