import axios from "axios";

const API_URL = "/api/permissions/";

const getAllPermissions = () => {
  return axios.get(API_URL);
};

const getAllPermissionGroups = () => {
  return axios.get(API_URL + "groups");
};

const PermissionService = {
  getAllPermissions,
  getAllPermissionGroups
};

export default PermissionService;
