// React and Mui Imports
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component Imports
import { Button, Slide, Snackbar } from "@mui/material";
import InlineSVG from "../inline-svg/inline-svg-component";
import TickOutlineCircle from "../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../assets/icons/error-circle-no-fill-icon.svg";

// Service Imports
import { socket } from "../../App";
import AuthService from "../../services/auth.service";

// Import Slices
import {
  clearSystemNotification,
  setSystemNotification,
} from "../../slices/message";

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  warning: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

export default function SystemNotification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = AuthService.getCurrentUser();

  if (user === null) {
    AuthService.logout();
    navigate("/logout");
  }

  const { email, business_unit } = user | {};

  useEffect(() => {
    socket.on("system-notification", getSystemNotification);
    return () => {
      socket.off("system-notification");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSystemNotification = (notification) => {
    const { user, business_unit: bu, type, message } = notification;
    if (!user || user === email) {
      if (!bu || bu === business_unit) {
        dispatch(setSystemNotification({ type, message }));
      }
    }
  };

  const { systemNotification } = useSelector((state) => state.message);

  useEffect(() => {
    if (systemNotification) {
      const { type, message } = systemNotification;
      handleGenericSnackOpen(type, message);
      dispatch(clearSystemNotification());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemNotification]);

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  return (
    <Snackbar
      open={genericSnack}
      autoHideDuration={6000}
      onClose={handleGenericSnackClose}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      ContentProps={{
        className: snackSettings[snackType].colorClass,
      }}
      message={
        <div className="flex items-center gap-3">
          <InlineSVG
            src={snackSettings[snackType].icon}
            ariaHidden
            width={28}
            height={28}
            className="fill-white"
          />
          <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
        </div>
      }
      action={
        <Button
          variant="text"
          className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
          onClick={handleGenericSnackClose}
          disableFocusRipple
        >
          Dismiss
        </Button>
      }
    />
  );
}
