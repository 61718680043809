// React and Mui Imports
import { useEffect, useState } from "react";
import { Button, InputAdornment, Slide, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../../components/back-button/back-button-component";
import OEMSelectCard from "../../../components/oem-select-card/oem-select-card-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../../assets/icons/error-circle-no-fill-icon.svg";

// Icon Imports
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Data Import
import { OEMData } from "../../../data";

// Import Services
import AuthService from "../../../services/auth.service";
import GeneralService from "../../../services/general.service";
import DealService from "../../../services/deal.service";

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

export default function HeadlineOEMSelection() {
  const navigate = useNavigate();
  const { cycle } = useParams();

  const [sortedData, setSortedData] = useState([]);
  const [filteredOEMData, setFilteredOEMData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [titleHeader, setTitleHeader] = useState("");

  useEffect(() => {
    DealService.getTotalDeviceOnlyDeals(cycle)
      .then((response) => {
        const { headlines } = response.data;
        setTitleHeader(headlines ? "Headline Deals" : "OEM Negotiations");
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get OEM
  useEffect(() => {
    GeneralService.getOEMs(cycle, "headlines")
      .then((response) => {
        const { data: OEMs } = response;

        const _OEMs = OEMs.map((item, index) => {
          const oem = OEMData.filter((v) => v.name === item.name);
          const newItem =
            oem.length > 0
              ? { id: index, name: item.name, logo: oem[0].logo }
              : { id: index, name: item.name, logo: null };
          try {
            if (newItem.logo) {
              require(`../../../${newItem.logo}`);
            }
          } catch (error) {
            newItem.logo = null;
          }
          return newItem;
        });
        setSortedData(_OEMs);
        setFilteredOEMData(_OEMs);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update data as the search text changes
  useEffect(() => {
    let filteredData = sortedData;

    // Filter OEMs by search text
    if (searchText) {
      filteredData = sortedData.filter((oem) => {
        // Join all values of the tariff object and its specs object into a string
        const tariffValues = Object.values(oem.name)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          tariffValues.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    setFilteredOEMData(filteredData);
  }, [searchText, sortedData]);

  // Handle search filter via search input field
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const [oemLockStates, setOEMLockStates] = useState({}); // Keep track of all the lock states of the OEM cards

  // Handle the lock state change
  const handleOEMLockStateChange = (oemName, newLockState) => {
    setOEMLockStates((prevLockStates) => ({
      ...prevLockStates,
      [oemName]: newLockState,
    }));
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-2 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#headlineOEMSelectionTitle"
        className="col-start-2 col-end-12 row-start-3"
      >
        <TitleBreadcrumb
          title={titleHeader}
          titleID="headlineOEMSelectionTitle"
        />

        <div className="my-12 grid grid-cols-10 gap-6">
          <div className="col-span-10 row-start-1">
            <InputField
              inputLabel="Search"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search for OEM..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineSVG
                      src={SearchIcon}
                      width={24}
                      height={24}
                      ariaHidden
                      className="fill-black"
                    />
                  </InputAdornment>
                ),
              }}
            ></InputField>
          </div>
          {filteredOEMData.length === 0 ? (
            <div className="col-span-10 row-start-2 text-center">
              <p>No OEMs found...</p>
            </div>
          ) : (
            <div className="col-span-10 row-start-2 grid grid-cols-12 gap-6 xl:grid-cols-10">
              {filteredOEMData.map((oem) => (
                <div
                  className="col-span-6 md:col-span-4 xl:col-span-2"
                  key={oem.id}
                >
                  <OEMSelectCard
                    logo={oem.logo ? require(`../../../${oem.logo}`) : null}
                    alt={`${oem.name} logo`}
                    oemPath={oem.name}
                    showLock={true}
                    lockState={oemLockStates[oem.name] || false}
                    onLockStateChange={(newLockState) =>
                      handleOEMLockStateChange(oem.name, newLockState)
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
