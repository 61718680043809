// React, Hook Forms and Mui Imports
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  useMediaQuery,
  Slide,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AuthService from "../../../services/auth.service";
import DealService from "../../../services/deal.service";

// Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import InputField from "../../../components/input/input-component";

// Animation Imports
import Lottie from "lottie-react";
import AstronautAnimation from "../../../assets/animations/astronaut-animation.json";
import LoadingAnimation from "../../../assets/animations/loader.json";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import BoxIcon from "../../../assets/icons/box-icon.svg";
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../../assets/icons/error-circle-no-fill-icon.svg";

const channelOptions = [
  {
    value: "Branded",
    label: "Branded",
  },
  {
    value: "Unbranded",
    label: "Unbranded",
  },
  {
    value: "Onyx",
    label: "Onyx",
  },
];

const viewData = {
  CBU: {
    title: "Generic Channel Deals",
    buttonText: "Generate new generic channel deals",
    cardSubHeader: "Total Channel Deals",
    cardLinkText: "View Channel Deals",
  },
  VBU: {
    title: "Trade Deals",
    buttonText: "Generate new deals",
    cardSubHeader: "Total Deals",
    cardLinkText: "View Deals",
  },
};

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

export default function BulkDealsOverview() {
  const navigate = useNavigate();
  const { cycle } = useParams();
  const user = AuthService.getCurrentUser();

  if (user === null) {
    AuthService.logout();
    navigate("/logout");
  }

  const { business_unit } = user;

  // Dialogs
  const [dialogOpen, setDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Loading state
  const [loading, setLoading] = useState(false);

  // Bulk deals grouping cards
  const [bulkCards, setBulkCards] = useState([]);

  // Generate deal dialog form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const { channel } = data;
    setLoading(true);
    DealService.generateGenericChannelDeals(cycle, channel)
      .then((response) => {
        const { message } = response;
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        handleServerError(error);
      });
    handleDialogClose();
  };

  useEffect(() => {
    DealService.getTotalBulkDealsPerChannel(cycle)
      .then((response) => {
        setBulkCards(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBulkCards]);

  useEffect(() => {
    if (dialogOpen) {
      reset();
    }
  }, [dialogOpen, reset]);

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#genericChannelDealsTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title={viewData[business_unit].title}
          titleID="bulkDealsTitle"
          button={
            <Button
              className="mt-6 lg:mt-0"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={PlusIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              onClick={() => {
                if (business_unit === "CBU") {
                  setDialogOpen(true);
                } else {
                  onSubmit({ channel: null });
                }
              }}
            >
              {viewData[business_unit].buttonText}
            </Button>
          }
        />
        {bulkCards.length === 0 && !loading ? (
          <div className="flex items-center justify-center">
            <Lottie
              animationData={AstronautAnimation}
              aria-hidden="true"
              role="presentation"
              className="max-w-2xl"
            />
          </div>
        ) : (
          <div className="col-span-10 col-start-2 row-start-3 mt-8 mb-12 grid gap-8 md:grid-cols-10 xl:grid-cols-9 2xl:grid-cols-12">
            {bulkCards.map((card, index) => (
              <article
                className="rounded-2xl bg-white shadow md:col-span-5 xl:col-span-3"
                key={index}
              >
                <>
                  <header className="flex flex-col items-center justify-center pt-11">
                    <p className="text-6xl font-bold">
                      <span className="bg-gradient-to-r from-red-gradient-start to-red-gradient-end bg-clip-text text-transparent">
                        {card.total.toLocaleString("en-ZA")}
                      </span>
                    </p>
                    <p className="mt-4 text-2xl leading-normal">
                      {viewData[business_unit].cardSubHeader}
                    </p>
                  </header>
                  <main className="px-12 py-8">
                    <div className="flex items-center justify-between gap-4">
                      <p className="text-base font-bold text-black">Channel:</p>
                      <p className="text-base text-black">{card.channel}</p>
                    </div>
                  </main>
                </>

                <footer className="border-t border-solid border-[#D9D9D9]">
                  <Button
                    disableFocusRipple
                    className="flex items-center justify-center gap-2 rounded-t-none rounded-b-2xl py-4 text-black hover:text-grey-700"
                    LinkComponent={Link}
                    to={`${card.channel}/oem-selection/`}
                  >
                    <InlineSVG
                      src={BoxIcon}
                      width={24}
                      height={24}
                      ariaHidden
                      className="fill-red-100"
                    />
                    <span className="text-base font-bold">
                      {viewData[business_unit].cardLinkText}
                    </span>
                  </Button>
                </footer>
              </article>
            ))}
            {loading ? (
              <article
                className="rounded-2xl bg-white shadow md:col-span-5 xl:col-span-3"
                key={"loading"}
              >
                <main className="flex items-center justify-center pt-6">
                  <Lottie
                    animationData={LoadingAnimation}
                    aria-hidden="true"
                    role="presentation"
                    className="my-8"
                  />
                </main>
                <footer className="border-t border-solid border-[#D9D9D9]">
                  <p className="py-4 text-center">
                    <span className="text-base font-bold">In progress...</span>
                  </p>
                </footer>
              </article>
            ) : (
              <></>
            )}
          </div>
        )}
      </section>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
      >
        <DialogTitle className="md:w-96" id="dialog-title">
          Create new generic channel deals
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="mb-4">
            Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit.
          </DialogContentText>
          <InputField
            select
            inputLabel="Channel"
            defaultValue="placeholder"
            SelectProps={{
              IconComponent: (props) => <ArrowDropDownRoundedIcon {...props} />,
            }}
            InputProps={{
              ...register("channel", {
                required: "Please select a channel",
                validate: (value) =>
                  value !== "placeholder" || "Please select a channel",
              }),
            }}
            error={Boolean(errors.channel)}
            helperText={errors.channel?.message}
          >
            <MenuItem disabled value="placeholder">
              - Select -
            </MenuItem>
            {channelOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </InputField>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            autoFocus
            disableFocusRipple
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
