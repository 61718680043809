// React Imports
import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Redux Store Imports
import { useSelector, useDispatch } from "react-redux";
import {
  getRoutersData,
  getRouters,
  updateReviewState,
} from "../../../slices/cycleConfigCanvas";

// MUI Imports
import {
  Button,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  ListSubheader,
  IconButton,
  MenuItem,
  Switch,
  Table,
  TableFooter,
  Tab,
  Tabs,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";
import MultiSelectDropdownWithSearch from "../../../components/multiselect-dropdown-with-search/multiselect-dropdown-with-search-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TabPanel, {
  a11yProps,
} from "../../../components/tab-panel/tab-panel-component";

// Icon Imports
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import XCircleIcon from "../../../assets/icons/x-circle-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SettingsOutlineIcon from "../../../assets/icons/settings-outlined-icon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import CloseIcon from "@mui/icons-material/Close";

// Sorting Options
const sortingOptions = [
  {
    heading: "OEM",
    options: [
      {
        value: "oem_asc",
        label: "A - Z",
      },
      {
        value: "oem_desc",
        label: "Z - A",
      },
    ],
  },
];

export default function RoutersReviewTabContent({
  handleSnackOpen,
}) {
  const { cycle } = useParams();
  const navigate = useNavigate();

  // Set DATA sources
  const data = useSelector(getRoutersData);
  const dispatch = useDispatch();

  // States
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortOrder, setSortOrder] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  // Dialogs
  const [routerDataTariffs, setRouterDataTariffs] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Rejection reason state
  const [message, setMessage] = useState("");

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);

  // Handle change table page
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Handle change table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(getRouters({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle dialog Open for Data Tariffs
  const handleOpenDataTariffs = (routerID) => {
    setRouterDataTariffs(true);
    setSelectedRouter(routerID);
  };

  // Update data as it changes
  useEffect(() => {
    let filteredData = data;

    // Filter data by search text
    if (searchText) {
      filteredData = data.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const values = Object.values(item)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return values.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      });
    }

    // Filter data by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const values = Object.values(item)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (values.toLowerCase().includes(option.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredData(filteredData);

    // Set rows per page
    const rowsLen = filteredData.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptions([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPage(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPage(rows);
    }
  }, [searchText, filterBy, data]);

  // Handle search filter via search input field
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // Handle sorting order change
  const handleSort = (event) => {
    setSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = filteredData.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        break;
      case "desc":
        sortedData = filteredData.sort((a, b) =>
          b.label.localeCompare(a.label)
        );
        break;
      default:
        sortedData = filteredData;
        break;
    }

    setFilteredData(sortedData);
  };

  // On Approval function to trigger tab change and success snackbar popup
  const onApprove = () => {
    dispatch(updateReviewState({ data: {
      config_status: "A",
      reviewed: "Y",
      reason_rejected: null,
      config_set: "ROUTERS",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }))

    handleSnackOpen("success", "Deal Cycle Parameters has been approved.");
  };

  // On Reject save function to trigger tab change and success snackbar popup
  const onRejectSave = () => {
    dispatch(updateReviewState({ data: {
      config_status: "R",
      reviewed: "Y",
      reason_rejected: message,
      config_set: "ROUTERS",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }));

    handleSnackOpen("success", "Deal Cycle Parameters has been rejected.");
  };

  // Rejected State
  const [rejected, setRejected] = useState(false);

  // Handle reject button
  const handleRejectChange = () => {
    setRejected(!rejected);
  };

  // Unique Lists from Data (used for filter data)
  const uniqueOEMs = new Set();
  const uniqueTech = new Set();

  const oemList = filteredData
    .filter((item) => {
      // To show all OEMs regardless of the search conditions, change `filteredData` to `data` instead
      if (!uniqueOEMs.has(item.oem)) {
        uniqueOEMs.add(item.oem);
        return true;
      }
      return false;
    })
    .map((item) => ({
      value: item.oem,
      label: item.oem,
    }));

  const techList = filteredData
    .filter((item) => {
      // To show all Network Technologies regardless of the search conditions, change `filteredData` to `data` instead
      if (!uniqueTech.has(item.network_tech)) {
        uniqueTech.add(item.network_tech);
        return true;
      }
      return false;
    })
    .map((item) => ({
      value: item.network_tech,
      label: item.network_tech,
    }));

  // Filter Options with Unique OEM list
  const filterByOptions = [
    {
      heading: "OEM",
      options: oemList,
    },
    {
      heading: "Network Tech",
      options: techList,
    },
  ];

  // Initialize an empty object for groupedDataByOEM
  const groupedDataByOEM = {};

  // First add data to groupedDataByOEM, grouping by OEM first
  filteredData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach((item) => {
      if (!groupedDataByOEM[item.oem]) {
        groupedDataByOEM[item.oem] = {};
      }

      if (!groupedDataByOEM[item.oem][item.network_tech]) {
        groupedDataByOEM[item.oem][item.network_tech] = [];
      }

      groupedDataByOEM[item.oem][item.network_tech].push(item);
    });

  // Data Tariffs
  // Set selected router
  const [selectedRouter, setSelectedRouter] = useState();

  // Data Tariffs State
  const [dataTariffState, setDataTariffState] = useState(null);

  // Tariffs Search State
  const [tariffSearchText, setTariffSearchText] = useState("");

  useEffect(() => {
    let tariffsData = selectedRouter
      ? filteredData.find((router) => router.id === selectedRouter).dataTariffs
      : null;

    if (tariffSearchText) {
      // Filter options based on the label property
      tariffsData = tariffsData.map((item) => ({
        ...item,
        options: item.options.filter((option) =>
          option.label.toLowerCase().includes(tariffSearchText.toLowerCase())
        ),
      }));
    }

    setDataTariffState(tariffsData);
  }, [selectedRouter, filteredData, tariffSearchText]);

  // Handle Tariff Options Search
  const handleTariffSearchChange = (event) => {
    const { value } = event.target;
    setTariffSearchText(value);
  };

  // Data Tariff Tabs
  const [dataTariffsTab, setDataTariffsTab] = useState(0);
  const handleDataTariffTabChange = (_, newValue) => {
    setDataTariffsTab(newValue);
  };

  return (
    <>
      <div className="mt-8 mb-12">
        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-10 lg:col-span-6">
            <InputField
              inputLabel="Search"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search by router name..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineSVG
                      src={SearchIcon}
                      width={24}
                      height={24}
                      ariaHidden
                      className="fill-black"
                    />
                  </InputAdornment>
                ),
              }}
            ></InputField>
          </div>
          <div className="col-span-5 lg:col-span-2">
            <InputField
              select
              inputLabel="Sort by"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
                displayEmpty: true,
                value: sortOrder || "",
                onChange: handleSort,
              }}
            >
              <MenuItem value="" disabled>
                - Select -
              </MenuItem>
              {sortingOptions.map((group) => [
                <ListSubheader
                  key={group.value}
                  className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                >
                  {group.heading}
                </ListSubheader>,
                ...group.options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    className="px-6"
                  >
                    <ListItemText primary={option.label} />
                  </MenuItem>
                )),
              ])}
            </InputField>
          </div>
          <div className="col-span-5 lg:col-span-2">
            <MultiSelectDropdownWithSearch
              label="Filter by"
              options={filterByOptions}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
            />
          </div>
        </div>
      </div>

      <form>
        {filteredData.length === 0 ? (
          <div className="py-4 text-center text-base text-black">
            No routers found...
          </div>
        ) : (
          Object.keys(groupedDataByOEM).map((oem, index) => (
            <Fragment key={index}>
              <h2 className="mb-4 border-b border-solid border-b-grey-100 pb-4 text-3xl font-bold">
                {oem}
              </h2>
              {Object.keys(groupedDataByOEM[oem]).map((tech, techIndex) => (
                <Fragment key={techIndex}>
                  <h3 className="mb-4 text-xl font-bold">{tech}</h3>
                  <div className="mb-12 grid grid-cols-5 gap-x-16 gap-y-6">
                    {groupedDataByOEM[oem][tech].map(
                      ({ id, label, active }) => (
                        <div className="flex items-center gap-3" key={id}>
                          <IconButton
                            className="fill-red-100 disabled:fill-grey-300"
                            disabled={active === "N"}
                            aria-label="Open Data Tariffs"
                            onClick={() => handleOpenDataTariffs(id)}
                          >
                            <InlineSVG
                              src={SettingsOutlineIcon}
                              width={24}
                              height={24}
                              ariaHidden
                            />
                          </IconButton>
                          <FormControlLabel
                            className="ml-0"
                            control={
                              <Switch
                                color="primary"
                                name="active"
                                value={active}
                                checked={active === "Y" ? true : false}
                              />
                            }
                            label={label}
                            labelPlacement="end"
                          />
                        </div>
                      )
                    )}
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ))
        )}

        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Items per page"
                SelectProps={{
                  inputProps: {
                    "aria-label": "Items per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <div className="mb-12">
          <div className="flex justify-center gap-2 md:justify-end">
            <Button
              color="error"
              variant="outlined"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={XCircleIcon}
                  width={24}
                  height={24}
                  ariaHidden
                />
              }
              onClick={handleRejectChange}
              disabled={rejected}
            >
              Reject
            </Button>
            <Button
              color="success"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={TickOutlineCircle}
                  width={24}
                  height={24}
                  ariaHidden
                />
              }
              onClick={onApprove}
            >
              Approve
            </Button>
          </div>

          {rejected && (
            <div className="flex flex-col gap-6">
              <InputField
                inputLabel="Reason"
                placeholder="Enter your comments here..."
                multiline
                minRows={2}
                value={message}
                autoFocus
                onChange={(e) => setMessage(e.target.value)}
              />

              <Button
                variant="text"
                disableFocusRipple
                className="self-end text-red-100"
                onClick={onRejectSave}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </form>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={routerDataTariffs}
        onClose={() => setRouterDataTariffs(false)}
        aria-labelledby="data-tariffs-title"
      >
        <IconButton
          aria-label="Close dialog window"
          className="mt-2 mr-2 self-end text-grey-300 hover:text-red-100"
          onClick={() => setRouterDataTariffs(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogTitle id="data-tariffs-title" className="pt-0">
          Data Tariffs
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={dataTariffsTab}
            onChange={handleDataTariffTabChange}
            aria-label="Data Tariff Options"
          >
            {dataTariffState &&
              dataTariffState.map(({ category }, index) => (
                <Tab
                  key={index}
                  label={category}
                  {...a11yProps(category)}
                  disableRipple
                />
              ))}
          </Tabs>

          <TextField
            placeholder="Search by tariff name..."
            size="small"
            className="my-6"
            fullWidth
            value={tariffSearchText}
            onChange={handleTariffSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineSVG
                    src={SearchIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-black"
                  />
                </InputAdornment>
              ),
            }}
          />

          {dataTariffState &&
            dataTariffState.map(({ options, toggleAll }, index) => (
              <TabPanel
                key={index}
                value={dataTariffsTab}
                index={index}
                id={`data-tariff-tab-${index}`}
              >
                <FormControlLabel
                  control={<CustomCheckbox checked={toggleAll} />}
                  label={toggleAll === false ? "Select All" : "Deselect All"}
                />
                <hr className="mt-2 mb-4" />
                <div className="flex flex-col gap-3">
                  {options.map((tariff) => (
                    <FormControlLabel
                      key={tariff.tariff_id}
                      control={
                        <CustomCheckbox
                          checked={tariff.active === "Y" ? true : false}
                        />
                      }
                      label={tariff.label}
                    />
                  ))}
                </div>
              </TabPanel>
            ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setRouterDataTariffs(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" autoFocus disableFocusRipple>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
