import axios from "axios";

const API_URL = "/api/roles/";

const createRole = (role) => {
  return axios.post(API_URL, role);
};

const updateRole = (id, role) => {
  return axios.put(API_URL + id, role);
};

const getAllRoles = () => {
  return axios.get(API_URL);
};

const getAllRolesByCategory = () => {
  return axios.get(API_URL + "by-category");
};


const getRoleById = (id) => {
  return axios.get(API_URL + id);
};

const deleteRole = (id) => {
  return axios.delete(API_URL + id);
};

const RoleService = {
  createRole,
  getAllRoles,
  getAllRolesByCategory,
  getRoleById,
  updateRole,
  deleteRole
};

export default RoleService;
