// Generate a more complex unique id for adding components
import { v4 as uuidv4 } from "uuid";

export const OEMData = [
  {
    id: 1,
    name: "Samsung",
    logo: "assets/logos/Samsung.svg",
  },
  {
    id: 2,
    name: "Apple",
    logo: "assets/logos/Apple.svg",
  },
  {
    id: 3,
    name: "Nokia",
    logo: "assets/logos/Nokia.svg",
  },
  {
    id: 4,
    name: "Sony",
    logo: "assets/logos/Sony.svg",
  },
  {
    id: 5,
    name: "LG",
    logo: "assets/logos/LG.svg",
  },
  {
    id: 6,
    name: "Xiaomi",
    logo: "assets/logos/Xiaomi.svg",
  },
  {
    id: 7,
    name: "Oppo",
    logo: "assets/logos/Oppo.svg",
  },
  {
    id: 8,
    name: "Vivo",
    logo: "assets/logos/Vivo.svg",
  },
  {
    id: 9,
    name: "Huawei",
    logo: "assets/logos/Huawei.svg",
  },
  {
    id: 10,
    name: "Honor",
    logo: "assets/logos/Honor.svg",
  },
  {
    id: 11,
    name: "Hisense",
    logo: "assets/logos/Hisense.svg",
  },
  {
    id: 12,
    name: "TECNO",
    logo: "assets/logos/Tecno-Mobile.svg",
  },
  {
    id: 13,
    name: "Alcatel",
    logo: "assets/logos/Alcatel.svg",
  },
  {
    id: 14,
    name: "Asus",
    logo: "assets/logos/Asus.svg",
  },
  {
    id: 15,
    name: "CAT",
    logo: "assets/logos/Cat.svg",
  },
  {
    id: 16,
    name: "CellSell",
    logo: null,
  },
  {
    id: 17,
    name: "Cernotech",
    logo: null,
  },
  {
    id: 18,
    name: "Duxbury",
    logo: null,
  },
  {
    id: 19,
    name: "Evercomm",
    logo: null,
  },
  {
    id: 20,
    name: "HP",
    logo: "assets/logos/Hp.svg",
  },
  {
    id: 21,
    name: "Lenovo",
    logo: "assets/logos/Lenovo.svg",
  },
  {
    id: 22,
    name: "Itel",
    logo: "assets/logos/Itel.svg",
  },
  {
    id: 23,
    name: "MSI",
    logo: "assets/logos/MSI.svg",
  },
  {
    id: 24,
    name: "Mecer",
    logo: "assets/logos/Mecer.svg",
  },
  {
    id: 25,
    name: "Microsoft",
    logo: "assets/logos/Microsoft.svg",
  },
  {
    id: 26,
    name: "Instacom",
    logo: null,
  },
  {
    id: 27,
    name: "IT Masters",
    logo: null,
  },
  {
    id: 28,
    name: "Mobicel",
    logo: null,
  },
  {
    id: 29,
    name: "ZTE",
    logo: "assets/logos/ZTE.svg",
  },
  {
    id: 30,
    name: "Nexio",
    logo: null,
  },
  {
    id: 31,
    name: "Nokia-Lucent",
    logo: null,
  },
  {
    id: 32,
    name: "Nology",
    logo: null,
  },
  {
    id: 33,
    name: "Proline",
    logo: null,
  },
  {
    id: 34,
    name: "TCL",
    logo: "assets/logos/TCL.svg",
  },
];

export const oemOptions = [
  {
    value: "Samsung",
    label: "Samsung",
  },
  {
    value: "Apple",
    label: "Apple",
  },
  {
    value: "Nokia",
    label: "Nokia",
  },
  {
    value: "Sony",
    label: "Sony",
  },
  {
    value: "LG",
    label: "LG",
  },
  {
    value: "Xiaomi",
    label: "Xiaomi",
  },
  {
    value: "Oppo",
    label: "Oppo",
  },
  {
    value: "Vivo",
    label: "Vivo",
  },
  {
    value: "Huawei",
    label: "Huawei",
  },
  {
    value: "Honor",
    label: "Honor",
  },
  {
    value: "Hisense",
    label: "Hisense",
  },
  {
    value: "TECNO",
    label: "TECNO",
  },
  {
    value: "Alcatel",
    label: "Alcatel",
  },
  {
    value: "Asus",
    label: "Asus",
  },
  {
    value: "CAT",
    label: "CAT",
  },
  {
    value: "CellSell",
    label: "CellSell",
  },
  {
    value: "Cernotech",
    label: "Cernotech",
  },
  {
    value: "Duxbury",
    label: "Duxbury",
  },
  {
    value: "Evercomm",
    label: "Evercomm",
  },
  {
    value: "HP",
    label: "HP",
  },
  {
    value: "Lenovo",
    label: "Lenovo",
  },
  {
    value: "Itel",
    label: "Itel",
  },
  {
    value: "MSI",
    label: "MSI",
  },
  {
    value: "Mecer",
    label: "Mecer",
  },
  {
    value: "Microsoft",
    label: "Microsoft",
  },
  {
    value: "Instacom",
    label: "Instacom",
  },
  {
    value: "IT Masters",
    label: "IT Masters",
  },
  {
    value: "Mobicel",
    label: "Mobicel",
  },
  {
    value: "ZTE",
    label: "ZTE",
  },
  {
    value: "Nexio",
    label: "Nexio",
  },
  {
    value: "Nokia-Lucent",
    label: "Nokia-Lucent",
  },
  {
    value: "Nology",
    label: "Nology",
  },
  {
    value: "Proline",
    label: "Proline",
  },
  {
    value: "TCL",
    label: "TCL",
  },
];

export const GenericDeviceData = [
  {
    id: 1,
    device: "OEM Device 1",
    specs: {
      size: '4.7"',
      ram: "2 GB",
      rom: "64 GB",
      sim: "SIM",
    },
    standaloneDeals: 200,
    accessoryDeals: 100,
    secondaryDeviceDeals: 100,
    flaggedDeals: 10,
  },
  {
    id: 2,
    device: "OEM Device 2",
    specs: {
      size: '6.5"',
      ram: "4 GB",
      rom: "128 GB",
      sim: "SIM",
    },
    standaloneDeals: 100,
    accessoryDeals: 50,
    secondaryDeviceDeals: 50,
    flaggedDeals: 5,
  },
  {
    id: 3,
    device: "OEM Device 3",
    specs: {
      size: '6.8"',
      ram: "6 GB",
      rom: "128 GB",
      sim: "SIM",
    },
    standaloneDeals: 100,
    accessoryDeals: 50,
    secondaryDeviceDeals: 50,
    flaggedDeals: 5,
  },
  {
    id: 4,
    device: "OEM Device 4",
    specs: {
      size: '5.4"',
      ram: "4 GB",
      rom: "256 GB",
      sim: "SIM",
    },
    standaloneDeals: 200,
    accessoryDeals: 100,
    secondaryDeviceDeals: 100,
    flaggedDeals: 10,
  },
];

export const GenericDeviceTariffData = [
  {
    id: 1,
    tariff: "Red Flexi 70",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 2,
    tariff: "Red Flexi 130",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 3,
    tariff: "Red Flexi 185",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 4,
    tariff: "Red Flexi 245",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 5,
    tariff: "Red Flexi 410",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 6,
    tariff: "Red Flexi 570",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 7,
    tariff: "Red Flexi 840",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 8,
    tariff: "Red 500MB 50min",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 9,
    tariff: "Red 1GB 100min",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
  {
    id: 10,
    tariff: "Red 1GB 200min",
    standaloneDeals: 40,
    accessoryDeals: 40,
    secondaryDeviceDeals: 40,
    flaggedDeals: 2,
  },
];

export const userData = [
  {
    id: 1234567890,
    firstName: "John",
    surname: "Doe",
    businessUnit: "Commercial Business",
    email: "doej@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567891,
    firstName: "Zandile",
    surname: "Hlophe",
    businessUnit: "Commercial Business",
    email: "hlophez@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567892,
    firstName: "Lebohang",
    surname: "Kunene",
    businessUnit: "Commercial Business",
    email: "kunenel@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567893,
    firstName: "Denise",
    surname: "Msibi",
    businessUnit: "Commercial Business",
    email: "msibid@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567894,
    firstName: "Barbara",
    surname: "Miller",
    businessUnit: "Commercial Business",
    email: "millerb@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567895,
    firstName: "Bongiwe",
    surname: "Mtshali",
    businessUnit: "Commercial Business",
    email: "mtshalib@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567896,
    firstName: "Julia",
    surname: "van der Westhuizen",
    businessUnit: "Commercial Business",
    email: "vanderwesthuizenj@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567897,
    firstName: "Thembi",
    surname: "Shongwe",
    businessUnit: "Commercial Business",
    email: "shongwet@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567898,
    firstName: "Lynn",
    surname: "Van Jaarsveld",
    businessUnit: "Commercial Business",
    email: "vanjaarsveldl@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567899,
    firstName: "Anthony",
    surname: "Cooper",
    businessUnit: "Commercial Business",
    email: "coopera@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567900,
    firstName: "Gugulethu",
    surname: "Kubheka",
    businessUnit: "Commercial Business",
    email: "kubhekag@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567901,
    firstName: "Thulani",
    surname: "Nkabinde",
    businessUnit: "Commercial Business",
    email: "nkabindet@vodacom.co.za",
    role: "Role Type",
  },
  {
    id: 1234567902,
    firstName: "Peter",
    surname: "Mthombeni",
    businessUnit: "Commercial Business",
    email: "mthombenip@vodacom.co.za",
    role: "Role Type",
  },
];

export const businessUnits = [
  {
    value: "Commercial Business",
    label: "Commercial Business",
  },
  {
    value: "business_unit2",
    label: "Business Unit 2",
  },
  {
    value: "business_unit3",
    label: "Business Unit 3",
  },
  {
    value: "business_unit4",
    label: "Business Unit 4",
  },
  {
    value: "business_unit5",
    label: "Business Unit 5",
  },
];

export const roles = [
  {
    value: "Role Type",
    label: "Role Type",
  },
  {
    value: "role2",
    label: "Role 2",
  },
  {
    value: "role3",
    label: "Role 3",
  },
  {
    value: "role4",
    label: "Role 4",
  },
  {
    value: "role5",
    label: "Role 5",
  },
];

export const documentData = [
  {
    id: 1,
    format: "xls",
    name: "March 2023 Trade List",
    type: "Trade List",
    date: "01/03/2023",
  },
  {
    id: 2,
    format: "xls",
    name: "March 2023 Tariff List",
    type: "Tariff List",
    date: "01/03/2023",
  },
  {
    id: 3,
    format: "xls",
    name: "February 2023 Trade List",
    type: "Trade List",
    date: "01/02/2023",
  },
  {
    id: 4,
    format: "xls",
    name: "February 2023 Tariff List",
    type: "Tariff List",
    date: "01/02/2023",
  },
  {
    id: 5,
    format: "xls",
    name: "January 2023 Trade List",
    type: "Trade List",
    date: "01/01/2023",
  },
  {
    id: 6,
    format: "xls",
    name: "January 2023 Tariff List",
    type: "Tariff List",
    date: "01/01/2023",
  },
  {
    id: 7,
    format: "xls",
    name: "December 2022 Trade List",
    type: "Trade List",
    date: "01/12/2022",
  },
  {
    id: 8,
    format: "xls",
    name: "December 2022 Tariff List",
    type: "Tariff List",
    date: "01/12/2022",
  },
  {
    id: 9,
    format: "xls",
    name: "November 2022 Trade List",
    type: "Trade List",
    date: "01/11/2022",
  },
  {
    id: 10,
    format: "xls",
    name: "November 2022 Tariff List",
    type: "Tariff List",
    date: "01/11/2022",
  },
  {
    id: 11,
    format: "xls",
    name: "October 2022 Trade List",
    type: "Trade List",
    date: "01/10/2022",
  },
  {
    id: 12,
    format: "xls",
    name: "October 2022 Tariff List",
    type: "Tariff List",
    date: "01/10/2022",
  },
  {
    id: 13,
    format: "xls",
    name: "September 2022 Trade List",
    type: "Trade List",
    date: "01/09/2022",
  },
  {
    id: 14,
    format: "xls",
    name: "September 2022 Tariff List",
    type: "Tariff List",
    date: "01/09/2022",
  },
];

export const documentFormats = [
  {
    value: "xls",
    label: "XLS",
  },
  {
    value: "xlsx",
    label: "XLSX",
  },
];

export const documentTypes = [
  {
    value: "TL",
    label: "Trade List",
  },
  {
    value: "TARIFF",
    label: "Tariff List",
  },
  {
    value: "TARIFF_SIMO",
    label: "Tariff SIMO list",
  },
  {
    value: "COMPARE",
    label: "Deal Comparison",
  },
  {
    value: "DSHCFG",
    label: "Dealsheet Config",
  },
  {
    value: "COMPETITOR",
    label: "Competitor Information",
  },
  {
    value: "FVPLANS",
    label: "Fair Value Plans",
  },
  {
    value: "CYCLEINFO",
    label: "Deal Cycle Information",
  },
  {
    value: "CSS_INVENTORY",
    label: "Siebel Inventory (PD)",
  },
  {
    value: "CSS_PROMO_TARIFF",
    label: "Siebel Promo Tariff (pt)",
  },
  {
    value: "CSS_PROMO_REFDAT",
    label: "Siebel Ref Dealsheets (PROMO)",
  },
  {
    value: "CSS_VASQ_PPVAS",
    label: "Siebel VAS (ppvas)",
  },
  {
    value: "CSS_VASQ_UVAS",
    label: "Siebel VAS (uvas)",
  },
  {
    value: "PROMO_MULTILINE",
    label: "Promo and Multiline references",
  },
  {
    value: "VAS_STANDARD",
    label: "Standard VASES",
  },
  {
    value: "VAS_ACTIVE",
    label: "Active VASES",
  },
  {
    value: "DEALER_QUALIFICATION",
    label: "Dealer Qualification",
  },
  {
    value: "INPUT_ODBC",
    label: "ODBC file input",
  }, 
  {
    value: "DQ_RULES",
    label: "Dealer Qualification Rules",
  },
  {
    value: "DEAL_MATCH",
    label: "Competitor Deal Matching",
  }, 
];

export const roleData = [
  {
    id: 1,
    role: "Role Name",
    featureList: [],
    users: true,
  },
  {
    id: 2,
    role: "Role Name 2",
    featureList: [],
    users: true,
  },
  {
    id: 3,
    role: "Role Name 3",
    featureList: [],
    users: false,
  },
  {
    id: 4,
    role: "Role Name 4",
    featureList: [],
    users: false,
  },
  {
    id: 5,
    role: "Role Name 5",
    featureList: [],
    users: false,
  },
];

export const jobsData = [
  {
    id: 1234567890,
    name: "Job name",
    type: "Job type",
    status: "Queued",
    queued: "01/01/2023 12:00:00 AM",
    started: "",
    ended: "",
    changedBy: "username",
    canCancel: true,
  },
  {
    id: 1234567891,
    name: "Job name 2",
    type: "Job type",
    status: "In Progress",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "",
    changedBy: "username",
    canCancel: true,
  },
  {
    id: 1234567892,
    name: "Job name 3",
    type: "Job type",
    status: "Completed",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567893,
    name: "Job name 4",
    type: "Job type",
    status: "Completed",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567894,
    name: "Job name 5",
    type: "Job type",
    status: "Cancelled",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567895,
    name: "Job name 6",
    type: "Job type",
    status: "Failed",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567896,
    name: "Job name 7",
    type: "Job type",
    status: "Failed",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567897,
    name: "Job name 8",
    type: "Job type",
    status: "Cancelled",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567898,
    name: "Job name 9",
    type: "Job type",
    status: "Completed",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
  {
    id: 1234567899,
    name: "Job name 10",
    type: "Job type",
    status: "Completed",
    queued: "01/01/2023 12:00:00 AM",
    started: "01/01/2023 12:00:00 AM",
    ended: "01/01/2023 12:00:00 AM",
    changedBy: "username",
    canCancel: false,
  },
];

export const jobTypes = [
  {
    value: "jobType1",
    label: "Job type",
  },
];

export const statusTypes = [
  {
    value: "queued",
    label: "Queued",
  },
  {
    value: "inProgress",
    label: "In Progress",
  },
  {
    value: "completed",
    label: "Completed",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "failed",
    label: "Failed",
  },
];

export const channelOptions = [
  {
    value: "Branded",
    label: "Branded",
  },
  {
    value: "Unbranded",
    label: "Unbranded",
  },
];

export const termOptions = [
  {
    value: 24,
    label: "24 months",
  },
  {
    value: 36,
    label: "36 months",
  },
];

export const vasOptions = [
  {
    value: "option1",
    label: "20GB x 1 (H)",
  },
  {
    value: "option2",
    label: "20GB x 1 (C)",
  },
  {
    value: "option3",
    label: "Try&Buy 3GB - 3 mnths",
  },
  {
    value: "option4",
    label: "1GB VID TKT x 3",
  },
  {
    value: "option5",
    label: "2 Months Free Theft cover",
  },
];

export const secondaryDeviceOptions = [
  {
    value: "option1",
    label: "Option 1",
  },
  {
    value: "option2",
    label: "Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
  },
  {
    value: "option4",
    label: "Option 4",
  },
  {
    value: "option5",
    label: "Option 5",
  },
];

export const secondaryTariffOptions = [
  {
    value: "option1",
    label: "Option 1",
  },
  {
    value: "option2",
    label: "Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
  },
  {
    value: "option4",
    label: "Option 4",
  },
  {
    value: "option5",
    label: "Option 5",
  },
];

export const accessoryOptions = [
  {
    value: "option1",
    label: "Option 1",
  },
  {
    value: "option2",
    label: "Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
  },
  {
    value: "option4",
    label: "Option 4",
  },
  {
    value: "option5",
    label: "Option 5",
  },
];

export const deals = [
  {
    id: 1,
    type: "standalone",
    flagged: false,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: [
      {
        id: uuidv4(),
        vasName: "option1",
      },
    ],
    multiDevice: [
      {
        id: uuidv4(),
        deviceName: "option1",
      },
    ],
    multiTariff: [
      {
        id: uuidv4(),
        tariffName: "option1",
      },
    ],
    accessory: [
      {
        id: uuidv4(),
        accessoryName: "option1",
      },
    ],
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 2,
    type: "accessory",
    flagged: false,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 3,
    type: "multi_device",
    flagged: false,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 4,
    type: "accessory",
    flagged: true,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 5,
    type: "standalone",
    flagged: false,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 6,
    type: "accessory",
    flagged: false,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 7,
    type: "multi_device",
    flagged: false,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
  {
    id: 8,
    type: "accessory",
    flagged: true,
    deviceName: "Device Name",
    tariffName: "Tariff Name",
    tariffTerm: 24,
    primaryVas: "option1",
    addedVas: null,
    multiDevice: null,
    multiTariff: null,
    accessory: null,
    dealPrice: 499,
    ctc: 2000,
    eff: 57,
    expiryDate: new Date("2023-03-07").toISOString(),
  },
];

export const dealCycles = [
  {
    date: "May 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "June 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "July 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "August 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "September 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "October 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "November 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "December 2022",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "January 2023",
    active: true,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "February 2023",
    active: true,
    totalDealsOEM: [
      {
        oem: "Apple",
        total: 150,
      },
      {
        oem: "Honor",
        total: 200,
      },
      {
        oem: "Huawei",
        total: 105,
      },
      {
        oem: "Samsung",
        total: 380,
      },
      {
        oem: "Nokia",
        total: 84,
      },
      {
        oem: "Xiaomi",
        total: 50,
      },
    ],
    totalBulkDeals: 1200,
    headlineDeals: 200,
    flaggedDeals: 22,
    adhocDeals: 25,
    documents: [
      {
        docName: "February 2023 Trade List",
        fileLink: "#",
      },
      {
        docName: "Tariff List",
        fileLink: "#",
      },
      {
        docName: "Standard VAS List",
        fileLink: "#",
      },
    ],
  },
  {
    date: "March 2023",
    active: false,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "April 2023",
    active: false,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
  {
    date: "May 2023",
    active: false,
    totalDealsOEM: [],
    totalBulkDeals: 0,
    headlineDeals: 0,
    flaggedDeals: 0,
    adhocDeals: 0,
    documents: [],
  },
];

export const tradeList = [
  {
    value: "February 2023",
    label: "February 2023 Trade List",
  },
  {
    value: "March 2023",
    label: "March 2023 Trade List",
  },
  {
    value: "April 2023",
    label: "April 2023 Trade List",
  },
  {
    value: "May 2023",
    label: "May 2023 Trade List",
  },
];

export const tariffDifferentials = [
  {
    tariff: "Red Flexi 70",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
  {
    tariff: "Red Flexi 135",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
  {
    tariff: "Red Flexi 185",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
  {
    tariff: "Red Flexi 245",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
  {
    tariff: "Red Flexi 410",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
  {
    tariff: "Red Flexi 570",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
  {
    tariff: "Red Flexi 840",
    tariffSubscription: 70,
    simOnlyDealSubscription: 59,
    simOnlyTariffDifferential: -90,
    deviceDealTariffDifferential: -90,
  },
];

export const standardVASs = [
  {
    tariff: "Red Flexi 70",
    primaryVAS: "20GB x 1 (H)",
    secondaryVAS: "",
    tertiaryVAS: "",
  },
  {
    tariff: "Red Flexi 130",
    primaryVAS: "20GB x 1 (H)",
    secondaryVAS: "",
    tertiaryVAS: "",
  },
  {
    tariff: "Red Flexi 185",
    primaryVAS: "20GB x 1 (H)",
    secondaryVAS: "",
    tertiaryVAS: "",
  },
  {
    tariff: "Red Flexi 245",
    primaryVAS: "20GB x 1 (H)",
    secondaryVAS: "",
    tertiaryVAS: "",
  },
  {
    tariff: "Red Flexi 410",
    primaryVAS: "20GB x 1 (H)",
    secondaryVAS: "",
    tertiaryVAS: "",
  },
  {
    tariff: "Red Flexi 570",
    primaryVAS: "20GB x 1 (H)",
    secondaryVAS: "",
    tertiaryVAS: "",
  },
];

export const bulkHeadlineDeals = [
  {
    id: 1,
    type: "handset",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device 1",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 43,
          int: 55,
          ctc: 599,
        },
      },
    ],
    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Device 1 64GB",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
  {
    id: 2,
    type: "handset",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device 2",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "128 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 58,
          int: 55,
          ctc: 799,
        },
      },
    ],
    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Device 2 128GB",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
  {
    id: 3,
    type: "handset",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device 3",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 75,
          int: 35,
          ctc: 1000,
        },
      },
    ],

    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Device 3 64GB",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
  {
    id: 4,
    type: "handset",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device 4",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 75,
          int: 35,
          ctc: 1000,
        },
      },
    ],

    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Device 4 64GB",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
  {
    id: 5,
    type: "laptop",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Laptop 1",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 75,
          int: 35,
          ctc: 1000,
        },
      },
    ],

    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Laptop 1",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
  {
    id: 6,
    type: "laptop",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Laptop 2",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 75,
          int: 35,
          ctc: 1000,
        },
      },
    ],

    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Laptop 2",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
  {
    id: 7,
    type: "accessory",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Accessory 1",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: null,
          term24: null,
          term36: null,
        },
        supplierAmbitions: {
          ctd: null,
          term24: null,
          term36: null,
          eff: 75,
          int: 35,
          ctc: 1000,
        },
      },
    ],
    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Accessory 1",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
];

export const oemDevices = [
  {
    oem: "Samsung",
    devices: [
      {
        value: 100019914,
        label: "Samsung SAMSUNG GALAXY A13 64GB 5G",
      },
      {
        value: "device2",
        label: "Samsung Device 2",
      },
      {
        value: "device3",
        label: "Samsung Device 3",
      },
      {
        value: "device4",
        label: "Samsung Device 4",
      },
    ],
  },
  {
    oem: "Apple",
    devices: [
      {
        value: "device1",
        label: "Apple Device 1",
      },
      {
        value: "device2",
        label: "Apple Device 2",
      },
      {
        value: "device3",
        label: "Apple Device 3",
      },
      {
        value: "device4",
        label: "Apple Device 4",
      },
    ],
  },
  {
    oem: "Nokia",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Sony",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "LG",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Xiaomi",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Oppo",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Vivo",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Huawei",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Honor",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Hisense",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "TECNO",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Alcatel",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Asus",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "CAT",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "CellSell",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Cernotech",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Duxbury",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Evercomm",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "HP",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Lenovo",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Itel",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "MSI",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Mecer",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Microsoft",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Instacom",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "IT Masters",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Mobicel",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "ZTE",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Nexio",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Nokia-Lucent",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Nology",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "Proline",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
  {
    oem: "TCL",
    devices: [
      {
        value: "device1",
        label: "Device 1",
      },
      {
        value: "device2",
        label: "Device 2",
      },
      {
        value: "device3",
        label: "Device 3",
      },
      {
        value: "device4",
        label: "Device 4",
      },
    ],
  },
];

export const deviceOptions = [
  {
    value: "device1",
    label: "Device 1",
  },
  {
    value: "device2",
    label: "Device 2",
  },
  {
    value: "device3",
    label: "Device 3",
  },
  {
    value: "device4",
    label: "Device 4",
  },
];

export const routerOptions = [
  {
    value: "router1",
    label: "Router 1",
  },
  {
    value: "router2",
    label: "Router 2",
  },
  {
    value: "router3",
    label: "Router 3",
  },
  {
    value: "router4",
    label: "Router 4",
  },
];

export const tariffOptions = [
  {
    value: "tariff1",
    label: "Tariff 1",
  },
  {
    value: "tariff2",
    label: "Tariff 2",
  },
  {
    value: "tariff3",
    label: "Tariff 3",
  },
  {
    value: "tariff4",
    label: "Tariff 4",
  },
  {
    value: "tariff5",
    label: "Tariff 5",
  },
];

export const tariffType = [
  {
    value: "D",
    label: "Data",
  },
  {
    value: "V",
    label: "Voice",
  }
]

export const tariffTerms = [
  {
    value: "6",
    label: "6 Months",
  },
  {
    value: "12",
    label: "12 Months",
  },
  {
    value: "24",
    label: "24 Months",
  },
  {
    value: "36",
    label: "36 Months",
  }
]

export const tariffSubType = [
  {
    value: "Sub Type 1",
    label: "Sub Type 1",
  },
  {
    value: "Sub Type 2",
    label: "Sub Type 2",
  },
  {
    value: "Sub Type 3",
    label: "Sub Type 3",
  },
  {
    value: "Sub Type 4",
    label: "Sub Type 4",
  }
]

export const sortingOptions = [
  {
    heading: "Price",
    options: [
      {
        value: "asc",
        label: "Price Ascending",
      },
      {
        value: "desc",
        label: "Price Descending",
      },
    ]
  }
];

export const filterByOptions = [
  {
    heading: "Type",
    options: [
      {
        value: "V",
        label: "Voice",
      },
      {
        value: "D",
        label: "Data",
      },
    ]
  },
  {
    heading: "Device Specifications",
    options: [
      {
        value: "32gb",
        label: "32GB",
      },
      {
        value: "64gb",
        label: "64GB",
      },
      {
        value: "128gb",
        label: "128GB",
      },
      {
        value: "256gb",
        label: "256GB",
      },
    ],
  },
];

export const addDeviceData = {
  type: "handset",
  approvedDeal: false,
  devices: [
    {
      deviceId: uuidv4(),
      device: "OEM Device 1",
      specs: {
        size: '4.7"',
        ram: "2 GB",
        rom: "64 GB",
        sim: "SIM",
      },
      previousDealCycle: {
        ctd: 1500,
        term24: 299,
        term36: 199,
      },
      currentDealCycle: {
        ctd: null,
        term24: null,
        term36: null,
      },
      supplierAmbitions: {
        ctd: null,
        term24: null,
        term36: null,
        eff: 43,
        int: 55,
        ctc: 599,
        eff36: 0,
        int36: 0,
        ctc36: 0,
      },
    },
  ],
  terminal: {
    network: "3G",
    plan: "Red 500MB 50min",
    previousSales: 1000,
    currentSales: 1000,
  },
  vodacomInfo: {
    termDifferential: 35,
    previousDeviceCost: 5999,
    previousCtc: 2687,
    previousEff: 67,
    previousInt: 75,
    previousNewLine: 1000,
    previousRetention: 566,
  },
  competitorInfo: [
    {
      competitor: "MTN",
      dealPrice: 0,
      plan: "",
      value: 0,
      discountFairValue: -20,
      fairValueDiff: 5,
    },
    {
      competitor: "Telkom",
      dealPrice: 0,
      plan: "",
      value: 0,
      discountFairValue: -20,
      fairValueDiff: 5,
    },
  ],
  moreInfo: {
    deviceFullTitle: "OEM Device 1 64GB",
    productCode: 104055206,
    tariff: "RD5",
    subs: 199,
    previousDeviceCost: 4559,
    previousCtd: 655,
    previousRrp: 5999,
    currentRrp: 6299,
    currentSimoRrp: -129,
    discountFairValue: -30,
    differentials: {
      dp: -11.2,
      eff: -2,
      cib: -3.1,
      reducedSubs: "N",
    },
  },
};

export const deviceToBeAdded = {
  device: "New Device",
  previousDealCycle: {
    ctd: 1500,
    term24: 299,
    term36: 199,
  },
  currentDealCycle: {
    ctd: null,
    term24: null,
    term36: null,
  },
  supplierAmbitions: {
    ctd: null,
    term24: null,
    term36: null,
    eff: 43,
    int: 55,
    ctc: 599,
  },
};

export const allDevices = [
  {
    oem: "Apple",
    devices: [
      {
        value: "iphone8",
        label: "Apple iPhone 8",
      },
      {
        value: "iphone8plus",
        label: "Apple iPhone 8 Plus",
      },
      {
        value: "iphoneX",
        label: "Apple iPhone X",
      },
      {
        value: "iphoneXs",
        label: "Apple iPhone Xs",
      },
      {
        value: "iphoneXsMax",
        label: "Apple iPhone Xs Max",
      },
      {
        value: "iphoneXr",
        label: "Apple iPhone Xr",
      },
      {
        value: "iphone11",
        label: "Apple iPhone 11",
      },
      {
        value: "iphone11Pro",
        label: "Apple iPhone 11 Pro",
      },
      {
        value: "iphone11ProMax",
        label: "Apple iPhone 11 Pro Max",
      },
      {
        value: "iphone12",
        label: "Apple iPhone 12",
      },
      {
        value: "iphone12Pro",
        label: "Apple iPhone 12 Pro",
      },
      {
        value: "iphone12ProMax",
        label: "Apple iPhone 12 Pro Max",
      },
      {
        value: "iphoneSe2",
        label: "Apple iPhone SE 2",
      },
    ],
  },
  {
    oem: "Samsung",
    devices: [
      {
        value: "galaxyA12",
        label: "Samsung Galaxy A12",
      },
      {
        value: "galaxyA02",
        label: "Samsung Galaxy A02",
      },
      {
        value: "galaxyA02s",
        label: "Samsung Galaxy A02s",
      },
      {
        value: "galaxyA325g",
        label: "Samsung Galaxy A32 5G",
      },
      {
        value: "galaxyA324g",
        label: "Samsung Galaxy A32 4G",
      },
      {
        value: "galaxyA525g",
        label: "Samsung Galaxy A52 5G",
      },
      {
        value: "galaxyA524g",
        label: "Samsung Galaxy A52 4G",
      },
      {
        value: "galaxyA145g",
        label: "Samsung Galaxy A14 5G",
      },
      {
        value: "galaxyA144g",
        label: "Samsung Galaxy A14 4G",
      },
      {
        value: "galaxyS22",
        label: "Samsung Galaxy S22",
      },
      {
        value: "galaxyS22Plus",
        label: "Samsung Galaxy S22 Plus",
      },
      {
        value: "galaxyS22Ultra",
        label: "Samsung Galaxy S22 Ultra",
      },
      {
        value: "galaxyS23",
        label: "Samsung Galaxy S23",
      },
      {
        value: "galaxyS23Plus",
        label: "Samsung Galaxy S23 Plus",
      },
      {
        value: "galaxyS23Ultra",
        label: "Samsung Galaxy S23 Ultra",
      },
    ],
  },
];

export const flaggedDeals = [
  {
    id: uuidv4(),
    status: null,
    device: {
      id: uuidv4(),
      name: "OEM Device 1",
      tariff: {
        plan: "Red Flexi 70",
        term: 24,
      },
    },
    dealInfo: {
      ctc: 2000,
      eff: 48,
      int: 23,
    },
    comment: "",
  },
  {
    id: uuidv4(),
    status: null,
    device: {
      id: uuidv4(),
      name: "OEM Device 2",
      tariff: {
        plan: "Red Flexi 135",
        term: 24,
      },
    },
    dealInfo: {
      ctc: 2000,
      eff: 62,
      int: 34,
    },
    comment: "",
  },
  {
    id: uuidv4(),
    status: null,
    device: {
      id: uuidv4(),
      name: "OEM Device 3",
      tariff: {
        plan: "Red Flexi 250",
        term: 24,
      },
    },
    dealInfo: {
      ctc: 2000,
      eff: 82,
      int: 43,
    },
    comment: "",
  },
  {
    id: uuidv4(),
    status: null,
    device: {
      id: uuidv4(),
      name: "OEM Device 4",
      tariff: {
        plan: "Red Flexi 500",
        term: 24,
      },
    },
    dealInfo: {
      ctc: 2000,
      eff: 52,
      int: 41,
    },
    comment: "",
  },
];

export const bulkHeadlineSIMOnlyDeals = [
  {
    id: uuidv4(),
    approvedDeal: false,
    tariff: {
      plan: "Red Flexi 70",
      previousDealCycle: {
        term24: 299,
        term36: 199,
      },
      currentDealCycle: {
        term24: null,
        term36: null,
      },
    },
    vodacomInfo: {
      termDifferential: 35,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
  },
  {
    id: uuidv4(),
    approvedDeal: false,
    tariff: {
      plan: "Red Flexi 135",
      previousDealCycle: {
        term24: 299,
        term36: 199,
      },
      currentDealCycle: {
        term24: null,
        term36: null,
      },
    },
    vodacomInfo: {
      termDifferential: 35,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
  },
];

export const singleSimOnlyData = {
  approvedDeal: false,
  tariff: {
    plan: "Red Flexi 70",
    previousDealCycle: {
      term24: 299,
      term36: 199,
    },
    currentDealCycle: {
      term24: null,
      term36: null,
    },
  },
  vodacomInfo: {
    termDifferential: 35,
    previousEff: 67,
    previousInt: 75,
    previousNewLine: 1000,
    previousRetention: 566,
  },
  competitorInfo: [
    {
      competitor: "MTN",
      dealPrice: 0,
      plan: "",
      value: 0,
      discountFairValue: -20,
      fairValueDiff: 5,
    },
    {
      competitor: "Telkom",
      dealPrice: 0,
      plan: "",
      value: 0,
      discountFairValue: -20,
      fairValueDiff: 5,
    },
  ],
};

export const cycleConfig_Tariffs = [
  {
    id: 1,
    plan: "Red Flexi 70",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 2,
    plan: "Red Flexi 130",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 3,
    plan: "Red Flexi 185",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 4,
    plan: "Red Flexi 245",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 5,
    plan: "Red Flexi 410",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 6,
    plan: "Red Flexi 570",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 7,
    plan: "Red Flexi 840",
    category: "Red Flexi",
    type: "Data",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 8,
    plan: "Red 500MB 50min",
    category: "RED",
    type: "Voice",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
  {
    id: 9,
    plan: "Red 1GB 100min",
    category: "RED",
    type: "Voice",
    tariffCode: "UCG",
    tariffSub: "70",
    simOnlySub: "59",
    tariffDifferential: "-70",
    rank: "1",
    tariffDiscount: "0",
    tariffCib: "400",
  },
];

export const cycleConfig_StandardVAS = [
  {
    id: 1,
    plan: "Red Flexi 70",
    category: "Red Flexi",
    type: "Data",
    vas1: "DC134",
    vas2: null,
    vas3: null,
  },
  {
    id: 2,
    plan: "Red Flexi 130",
    category: "Red Flexi",
    type: "Data",
    vas1: "TC013",
    vas2: null,
    vas3: null,
  },
  {
    id: 3,
    plan: "Red Flexi 185",
    category: "Red Flexi",
    type: "Data",
    vas1: "DD10",
    vas2: null,
    vas3: null,
  },
  {
    id: 4,
    plan: "Red Flexi 245",
    category: "Red Flexi",
    type: "Data",
    vas1: "BTB08",
    vas2: null,
    vas3: null,
  },
  {
    id: 5,
    plan: "Red Flexi 410",
    category: "Red Flexi",
    type: "Data",
    vas1: "BTB08",
    vas2: null,
    vas3: null,
  },
  {
    id: 6,
    plan: "Red Flexi 570",
    category: "Red Flexi",
    type: "Data",
    vas1: "BTB08",
    vas2: null,
    vas3: null,
  },
  {
    id: 7,
    plan: "Red Flexi 840",
    category: "Red Flexi",
    type: "Data",
    vas1: "BTB08",
    vas2: null,
    vas3: null,
  },
  {
    id: 8,
    plan: "Red 500MB 50min",
    category: "RED",
    type: "Voice",
    vas1: "TC013",
    vas2: null,
    vas3: null,
  },
  {
    id: 9,
    plan: "Red 1GB 100min",
    category: "RED",
    type: "Voice",
    vas1: "BTB08",
    vas2: null,
    vas3: null,
  },
  {
    id: 10,
    plan: "Red 2GB 100min",
    category: "RED",
    type: "Voice",
    vas1: "TC013",
    vas2: null,
    vas3: null,
  },
  {
    id: 11,
    plan: "Red 3GB 100min",
    category: "RED",
    type: "Voice",
    vas1: "BTB08",
    vas2: null,
    vas3: null,
  },
  {
    id: 12,
    plan: "Red 6GB 100min",
    category: "RED",
    type: "Voice",
    vas1: "TC013",
    vas2: null,
    vas3: null,
  },
];

export const cycleConfig_activeVASs = [
  {
    id: 1,
    code: "IMAN",
    description: "Screen Insurance",
    subscription: 160,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 2,
    code: "N005",
    description: "Vodasure Medi-Assist",
    subscription: 0,
    type: "Financial Services",
    recurrence: "Null"
  },
  {
    id: 3,
    code: "BTB08",
    description: "Try&Buy 3GB - 3 Months",
    subscription: 0,
    type: "Data",
    recurrence: "3"
  },
  {
    id: 4,
    code: "TC02",
    description: "Theft Cover",
    subscription: 0,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 5,
    code: "TC013",
    description: "2 Months Free Theft Cover",
    subscription: 0,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 6,
    code: "TC03",
    description: "Theft & Screen Cover",
    subscription: 0,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 7,
    code: "VI003",
    description: "Free 12 Month Extended Warranty",
    subscription: 0,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 8,
    code: "TK001",
    description: "Tech Expert",
    subscription: 233,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 9,
    code: "TK000",
    description: "Tech Expert - Free",
    subscription: 0,
    type: "Financial Services",
    recurrence: "Once Off"
  },
  {
    id: 10,
    code: "VI001",
    description: "R29pm 12 Month  Extended Warranty",
    subscription: 0,
    type: "Financial Services",
    recurrence: "12"
  },
  {
    id: 11,
    code: "CODE",
    description: "DESCRIPTION",
    subscription: 0,
    type: "TYPE",
    recurrence: "Once Off"
  },
  {
    id: 12,
    code: "CODE",
    description: "DESCRIPTION",
    subscription: 0,
    type: "TYPE",
    recurrence: "Once Off"
  },
  {
    id: 13,
    code: "CODE",
    description: "DESCRIPTION",
    subscription: 0,
    type: "TYPE",
    recurrence: "Once Off"
  },
  {
    id: 14,
    code: "CODE",
    description: "DESCRIPTION",
    subscription: 0,
    type: "TYPE",
    recurrence: "Once Off"
  },
  {
    id: 15,
    code: "CODE",
    description: "DESCRIPTION",
    subscription: 0,
    type: "TYPE",
    recurrence: "Once Off"
  }
]

export const cycleConfig_ranges = [
  {
    id: 1,
    tariffCode: "UCG",
    description: "",
    tariffType: "",
    tariffSubType: "",
    term: "",
    rangeLower: 0,
    rangeUpper: 1500
  },
  {
    id: 2,
    tariffCode: "UCG",
    description: "",
    tariffType: "",
    tariffSubType: "",
    term: "",
    rangeLower: 0,
    rangeUpper: 1500
  },
  {
    id: 3,
    tariffCode: "UCG",
    description: "",
    tariffType: "",
    tariffSubType: "",
    term: "",
    rangeLower: 0,
    rangeUpper: 1500
  },
  {
    id: 4,
    tariffCode: "UCG",
    description: "",
    tariffType: "",
    tariffSubType: "",
    term: "",
    rangeLower: 0,
    rangeUpper: 1500
  },
  {
    id: 5,
    tariffCode: "UCG",
    description: "",
    tariffType: "",
    tariffSubType: "",
    term: "",
    rangeLower: 0,
    rangeUpper: 1500
  }
]

export const channels = [
  {
    label: "Direct",
    value: 1,
  },
  {
    label: "Dealers and Franchise",
    value: 2,
  },
  {
    label: "National Chains",
    value: 3,
  },
  {
    label: "Online",
    value: 4,
  },
];

export const cycleConfig_DevicePerChannelHandsets = [
  {
    id: 1,
    oem: "Apple",
    device: "iPhone 8",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 2,
    oem: "Apple",
    device: "iPhone X",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 3,
    oem: "Apple",
    device: "iPhone 14",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 4,
    oem: "Apple",
    device: "iPhone 14 Pro",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 5,
    oem: "Apple",
    device: "iPhone 14 Pro Max",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 6,
    oem: "Samsung",
    device: "S23",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 7,
    oem: "Samsung",
    device: "S23 Plus",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 8,
    oem: "Samsung",
    device: "S23 Ultra",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 9,
    oem: "Samsung",
    device: "A54",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 10,
    oem: "Samsung",
    device: "Galaxy Z Fold",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 11,
    oem: "Samsung",
    device: "A34 5G",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 12,
    oem: "Samsung",
    device: "A21s",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
];

export const cycleConfig_DevicePerCategoryAccessories = [
  {
    id: 1,
    oem: "Samsung",
    device: "Buds Pro 2",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
];

export const cycleConfig_DealTypePerChannel = [
  {
    id: 1,
    term: 24,
    category: "Handsets",
    option: "Financed Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 2,
    term: 24,
    category: "Handsets",
    option: "Cash Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 3,
    term: 24,
    category: "Handsets",
    option: "Gift Card/Voucher",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 4,
    term: 24,
    category: "Handsets",
    option: "Onyx",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: false,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 5,
    term: 24,
    category: "Data",
    option: "Financed Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 6,
    term: 24,
    category: "Data",
    option: "Cash Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 7,
    term: 24,
    category: "Data",
    option: "Gift Card/Voucher",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: false,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 8,
    term: 24,
    category: "Data",
    option: "Onyx",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 9,
    term: 24,
    category: "SIM-Only",
    option: "No Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: false,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 10,
    term: 36,
    category: "Handsets",
    option: "Financed Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 11,
    term: 36,
    category: "Handsets",
    option: "Cash Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 12,
    term: 36,
    category: "Handsets",
    option: "Gift Card/Voucher",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 13,
    term: 36,
    category: "Handsets",
    option: "Onyx",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: false,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 14,
    term: 36,
    category: "Data",
    option: "Financed Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 15,
    term: 36,
    category: "Data",
    option: "Cash Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: false,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: false,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 16,
    term: 36,
    category: "Data",
    option: "Gift Card/Voucher",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: false,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
  {
    id: 17,
    term: 36,
    category: "Data",
    option: "Onyx",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: true,
      },
      {
        label: "National Chains",
        active: false,
      },
      {
        label: "On-Billers",
        active: true,
      },
    ],
  },
  {
    id: 18,
    term: 36,
    category: "SIM-Only",
    option: "No Device",
    channels: [
      {
        label: "Dealers & Franchises",
        active: true,
      },
      {
        label: "Direct",
        active: true,
      },
      {
        label: "Online",
        active: false,
      },
      {
        label: "National Chains",
        active: true,
      },
      {
        label: "On-Billers",
        active: false,
      },
    ],
  },
];

export const cycleConfig_Routers = [
  {
    id: 1, // Cid
    label: "Huawei Router 1", // Model
    active: true, // Is
  },
  {
    id: 2,
    label: "ZTE Router 2",
    active: true,
  },
  {
    id: 3,
    label: "Vodafone Router 3",
    active: false,
  },
  {
    id: 4,
    label: "Huawei Router 4",
    active: true,
  },
  {
    id: 5,
    label: "ZTE Router 5",
    active: true,
  },
  {
    id: 6,
    label: "Vodafone Router 6",
    active: true,
  },
  {
    id: 7,
    label: "Huawei Router 7",
    active: true,
  },
  {
    id: 8,
    label: "Huawei Router 8",
    active: false,
  },
  {
    id: 9,
    label: "ZTE Router 9",
    active: true,
  },
  {
    id: 10,
    label: "ZTE Router 10",
    active: false,
  },
];

export const cycleConfig_DealCycleParameters = {
  vat: {
    value: 15,
    active: true,
  },
  terms: [
    {
      term: 12,
      dealMarkup: 1.2,
      active: false
    },
    {
      term: 24,
      dealMarkup: 1.2,
      active: true
    },
    {
      term: 36,
      dealMarkup: 1.2,
      active: true
    }
  ],
  dealPriceMultipliers: [
    {
      term: 24,
      value: 1,
      active: true
    },
    {
      term: 36,
      value: 0.8,
      active: true
    }
  ],
  financials: [
    {
      type: "SIM Support Value (SSV)",
      value: 83.48,
      active: true
    },
    {
      type: "Connection Support Value (CSV)",
      value: 0,
      active: true
    },
    {
      type: "Device Replacement Value Ratio",
      value: 1.25,
      active: true
    },
    {
      type: "Onyx Device Discount Limit",
      value: 20000,
      valueType: "currency",
      active: true
    }
  ]
}

export const headlineAddonDeals = [
  {
    id: 1,
    type: "handset",
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device 1",
        specs: {
          size: '4.7"',
          ram: "2 GB",
          rom: "64 GB",
          sim: "SIM",
        },
        previousDealCycle: {
          ctd: 1500,
          term24: 299,
          term36: 199,
        },
        currentDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        supplierAmbitions: {
          ctd: 0,
          term24: 0,
          term36: 0,
          eff: 43,
          int: 55,
          ctc: 599,
          ctc36: 599,
          eff36: 58,
          tariffDiscount: -5,
        },
      },
    ],
    multiAccessory: [
      { id: 1, accessoryName: "option1" },
      { id: 2, accessoryName: "option2" },
    ],
    multiDevice: [{ id: 1, deviceName: "device1" }],
    multiTariff: [{ id: 1, tariffName: "tariff1" }],
    multiRouter: [{ id: 1, routerName: "router1" }],
    terminal: {
      network: "3G",
      plan: "Red 500MB 50min",
      previousSales: 1000,
      currentSales: 1000,
    },
    vodacomInfo: {
      termDifferential: 35,
      previousDeviceCost: 5999,
      previousCtc: 2687,
      previousEff: 67,
      previousInt: 75,
      previousNewLine: 1000,
      previousRetention: 566,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -20,
        fairValueDiff: 5,
      },
    ],
    moreInfo: {
      deviceFullTitle: "OEM Device 1 64GB",
      productCode: 104055206,
      tariff: "RD5",
      subs: 199,
      previousDeviceCost: 4559,
      previousCtd: 655,
      previousRrp: 5999,
      currentRrp: 6299,
      currentSimoRrp: -129,
      discountFairValue: -30,
      differentials: {
        dp: -11.2,
        eff: -2,
        cib: -3.1,
        reducedSubs: "N",
      },
    },
  },
];

export const channelOnlineDeals = [
  {
    id: uuidv4(),
    oem: "Samsung",
    type: "handset",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device",
        specs: {
          size: "",
          ram: "",
          rom: "",
          sim: "",
        },
        previousDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        currentDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        supplierAmbitions: {
          ctd: 0,
          term24: 0,
          term36: 0,
          eff: 0,
          int: 0,
          ctc: 0,
          ctc36: 0,
          eff36: 0,
          tariffDiscount: 0,
        },
      },
    ],
    terminal: {
      network: "",
      plan: 0,
      previousSales: 0,
      currentSales: 0,
      comments: "",
    },
    vodacomInfo: {
      termDifferential: 0,
      previousDeviceCost: 0,
      previousCtc: 0,
      previousEff: 0,
      previousInt: 0,
      previousNewLine: 0,
      previousRetention: 0,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -0,
        fairValueDiff: 0,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: 0,
        fairValueDiff: 0,
      },
    ],
    moreInfo: {
      deviceFullTitle: "",
      productCode: 0,
      tariff: 0,
      subs: 0,
      previousDeviceCost: 0,
      previousCtd: 0,
      previousRrp: 0,
      currentRrp: 0,
      currentSimoRrp: 0,
      discountFairValue: 0,
      differentials: {
        dp: 0,
        eff: 0,
        cib: 0,
        reducedSubs: "",
      },
    },
    dataHasChanged: false,
    tariff_basket_id: 0,
    cycle_id: 0,
    multiAccessory: [],
    multiDevice: [],
    multiTariff: [],
    multiRouter: [],
    channel_id: 0,
    cardGlobalLevel: {
      main_device_basket_id: 0,
      main_cid: 0,
      total_basket_price: 0,
      prev_ctd: 0,
      prev_deal_price_24: 0,
      prev_deal_price_36: 0,
      ctd: 0,
      is_add_on: false,
    },
    termSpecific: [
      {
        tariff_basket_id: 0,
        term: 24,
        tariff_code: "",
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
      {
        tariff_basket_id: 0,
        term: 36,
        tariff_code: "",
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
    ],
    createDeal: false,
    isNotValid: false,
  },
  {
    id: uuidv4(),
    oem: "Apple",
    type: "handset",
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: "OEM Device",
        specs: {
          size: "",
          ram: "",
          rom: "",
          sim: "",
        },
        previousDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        currentDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        supplierAmbitions: {
          ctd: 0,
          term24: 0,
          term36: 0,
          eff: 0,
          int: 0,
          ctc: 0,
          ctc36: 0,
          eff36: 0,
          tariffDiscount: 0,
        },
      },
    ],
    terminal: {
      network: "",
      plan: 0,
      previousSales: 0,
      currentSales: 0,
      comments: "",
    },
    vodacomInfo: {
      termDifferential: 0,
      previousDeviceCost: 0,
      previousCtc: 0,
      previousEff: 0,
      previousInt: 0,
      previousNewLine: 0,
      previousRetention: 0,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -0,
        fairValueDiff: 0,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: 0,
        fairValueDiff: 0,
      },
    ],
    moreInfo: {
      deviceFullTitle: "",
      productCode: 0,
      tariff: 0,
      subs: 0,
      previousDeviceCost: 0,
      previousCtd: 0,
      previousRrp: 0,
      currentRrp: 0,
      currentSimoRrp: 0,
      discountFairValue: 0,
      differentials: {
        dp: 0,
        eff: 0,
        cib: 0,
        reducedSubs: "",
      },
    },
    dataHasChanged: false,
    tariff_basket_id: 0,
    cycle_id: 0,
    multiAccessory: [],
    multiDevice: [],
    multiTariff: [],
    multiRouter: [],
    channel_id: 0,
    cardGlobalLevel: {
      main_device_basket_id: 0,
      main_cid: 0,
      total_basket_price: 0,
      prev_ctd: 0,
      prev_deal_price_24: 0,
      prev_deal_price_36: 0,
      ctd: 0,
      is_add_on: false,
    },
    termSpecific: [
      {
        tariff_basket_id: 0,
        term: 24,
        tariff_code: "",
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
      {
        tariff_basket_id: 0,
        term: 36,
        tariff_code: "",
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
    ],
    createDeal: false,
    isNotValid: false,
  },
];

export const channelOnlineDeviceDeals = [
  {
    id: "oem-device-id-1",
    oem: "Apple",
    device: "iPhone 14 5G 256GB",
    deals: [
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 75",
        tariffTerm: 24,
        paymentType: "Financed",
        standardVas: [
          {
            vasName: "VAS 1",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
        ],
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 140",
        tariffTerm: 24,
        paymentType: "Voucher",
        standardVas: [
          {
            vasName: "VAS 1",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
        ],
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 200",
        tariffTerm: 24,
        paymentType: "Onyx",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 260",
        tariffTerm: 24,
        paymentType: "Cash",
        standardVas: [
          {
            vasName: "VAS 1",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
        ],
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 75",
        tariffTerm: 36,
        paymentType: "Financed",
        standardVas: [
          {
            vasName: "VAS 1",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
        ],
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 36 months",
        tariffName: "Red Flexi 140",
        tariffTerm: 36,
        paymentType: "Voucher",
        standardVas: [
          {
            vasName: "VAS 1",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
        ],
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 36 months",
        tariffName: "Red Flexi 200",
        tariffTerm: 36,
        paymentType: "Onyx",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 36 months",
        tariffName: "Red Flexi 260",
        tariffTerm: 36,
        paymentType: "Cash",
        standardVas: [
          {
            vasName: "VAS 1",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
          {
            vasName: "VAS 2",
            vasDetails: "20 GB X 1",
          },
        ],
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
    ],
  },
];

export const dealOptions = [
  {
    value: "deal1",
    label: "Deal 1",
  },
  {
    value: "deal2",
    label: "Deal 2",
  },
  {
    value: "deal3",
    label: "Deal 3",
  },
  {
    value: "deal4",
    label: "Deal 4",
  },
];

export const dealTypes = [
  {
    value: "1",
    label: "Standard",
  },
  {
    value: "2",
    label: "Handset Cash",
  },
  {
    value: "3",
    label: "Giftcard",
  },
  {
    value: "4",
    label: "Onyx Financed",
  },
  {
    value: "5",
    label: "Onyx Cash",
  },
  {
    value: "6",
    label: "Onyx",
  },
];

export const adhocDealset = {
  id: uuidv4(),
  dealset: "Easter Weekend Special",
  totalDeals: 70,
  submitForApproval: false,
  deals: [
    {
      id: "id-device-1",
      device: {
        oem: "Apple",
        deviceName: "iPhone 8",
      },
      dealDescription:
        "Test test test test test test test test test test test test test test test test test",
      paymentType: "Finance",
      terms: [
        {
          period: 12,
          tariff: "",
          dealPrice: 0,
          discount: -5,
          ctc: 2000,
          eff: 38,
        },
        {
          period: 24,
          tariff: "",
          dealPrice: 0,
          discount: -5,
          ctc: 2000,
          eff: 38,
        },
        {
          period: 36,
          tariff: "",
          dealPrice: 0,
          discount: -5,
          ctc: 2000,
          eff: 38,
        },
      ],
      dealStartDate: "",
      dealEndDate: "",
      published: false,
      submitForApproval: false,
    },
  ],
};

export const adhocDeal = {
  device: {
    oem: "Apple",
    deviceName: "iPhone #",
  },
  dealDescription:
    "Test test test test test test test test test test test test test test test test test",
  paymentType: "Finance",
  terms: [{ period: 12 }, { period: 24 }, { period: 36 }],
  dealStartDate: "",
  dealEndDate: "",
  published: false,
};

export const adhocChildrenDeals = [
  {
    id: "id-device-1",
    oem: "Apple",
    device: "iPhone 8",
    deals: [
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 8 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 75",
        tariffTerm: 24,
        paymentType: "Financed",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 140",
        tariffTerm: 24,
        paymentType: "Voucher",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 200",
        tariffTerm: 24,
        paymentType: "Onyx",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 260",
        tariffTerm: 24,
        paymentType: "Cash",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 24 months",
        tariffName: "Red Flexi 75",
        tariffTerm: 36,
        paymentType: "Financed",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 36 months",
        tariffName: "Red Flexi 140",
        tariffTerm: 36,
        paymentType: "Voucher",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 36 months",
        tariffName: "Red Flexi 200",
        tariffTerm: 36,
        paymentType: "Onyx",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
      {
        id: uuidv4(),
        dealDescription:
          "APPLE IPHONE 14 5G 256GB+20GB X 1 (C) +2 Months Free Theft cover+1GB VID TKT X 3 @R599 Reduced Sub PM on Red 600MB 50min - 36 months",
        tariffName: "Red Flexi 260",
        tariffTerm: 36,
        paymentType: "Cash",
        dealPrice: 599,
        costToConnect: 2000,
        eff: 57,
      },
    ],
  },
];

export const dealCycleReviewTimeline = [
  {
    event: "Deal Cycle Configuration Review",
    date: "01/03/2023",
    active: true,
    subEvents: [
      {
        label: "Approver 1",
        active: true
      },
      {
        label: "Approver 2",
        active: true
      },
      {
        label: "Approver 3",
        active: true
      }
    ]
  },
  {
    event: "Deals Approval",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Approver 1",
        active: false
      },
      {
        label: "Approver 2",
        active: false
      },
      {
        label: "Approver 3",
        active: false
      }
    ]
  },
  {
    event: "Deal Summary Review",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Approver 1",
        active: false
      },
      {
        label: "Approver 2",
        active: false
      },
      {
        label: "Approver 3",
        active: false
      }
    ]
  },
  {
    event: "Completed",
    date: null,
    active: false
  }
];

export const dealCycleTimeline = [
  {
    event: "Deal cycle configured",
    date: "01/03/2023",
    active: true,
  },
  {
    event: "Deal cycle started",
    date: "01/03/2023",
    active: true,
    subEvents: [
      {
        label: "Trade List Ingested",
        active: true
      },
    ]
  },
  {
    event: "Deal cycle configuration approval",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Approver 1",
        active: false
      },
      {
        label: "Approver 2",
        active: false
      },
      {
        label: "Approver 3",
        active: false
      },
    ]
  },
  {
    event: "Proto-headline deals generated",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Proto-headline deals updated",
        active: false
      },
    ]
  },
  {
    event: "Headline deals generated",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Headline deals updated",
        active: false
      },
    ]
  },
  {
    event: "Headline deals approval",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Approver 1",
        active: false
      },
      {
        label: "Approver 2",
        active: false
      },
      {
        label: "Approver 3",
        active: false
      },
    ]
  },
  {
    event: "Channel deals generated",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Dealers & Franchises",
        active: false
      },
      {
        label: "Direct",
        active: false
      },
      {
        label: "Online",
        active: false
      },
      {
        label: "National Chains",
        active: false
      },
    ]
  },
  {
    event: "Channel deals approval",
    date: null,
    active: false,
    subEvents: [
      {
        label: "Approver 1",
        active: false
      },
      {
        label: "Approver 2",
        active: false
      },
      {
        label: "Approver 3",
        active: false
      },
      {
        label: "Approver 4",
        active: false
      },
    ]
  },
  {
    event: "Deal cycle approval",
    date: null,
    active: false,
  },
  {
    event: "Deal cycle published",
    date: null,
    active: false,
  }
]