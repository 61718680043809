import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import UploadService from "../services/upload.service";

export const uploadFile = createAsyncThunk(
  "uploads/",
  async (formData, thunkAPI) => {
    try {
      const data = await UploadService.uploadFile(formData);
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  formData: null,
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setUploadFile: (state, action) => {
      state.formData = action.payload;
    },
    clearUploadFile: (state) => {
      state.formData = null;
    },
  },
  extraReducers: {
    [uploadFile.fulfilled]: (state, action) => {},
    [uploadFile.rejected]: (state, action) => {},
  },
});

export const { setUploadFile, clearUploadFile } = uploadSlice.actions;
export default uploadSlice.reducer;
