// React Imports
import { useEffect, useId, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { useIMask } from "react-imask";

// Redux Store Imports
import { useDispatch } from "react-redux";
import {
  duplicateDeal,
  deleteDeal,
  flagDeal,
  addDealComponent,
  removeDealComponent,
  updateDeal,
} from "../../slices/dealCanvas";

// Component Imports
import InputField from "../input/input-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import CustomDatePicker from "../datepicker/datepicker-component";

// Icon Import
import PriceTagIcon from "../../assets/icons/price-tag-icon.svg";
import AccessoryIcon from "../../assets/icons/accessories-icon.svg";
import MultiDeviceIcon from "../../assets/icons/connected-devices-icon.svg";
import RewardIcon from "../../assets/icons/all-rewards-icon.svg";
import FlagOutlinedIcon from "../../assets/icons/flag-outlined-icon.svg";
import FlagIcon from "../../assets/icons/flag-icon.svg";
import DuplicateIcon from "../../assets/icons/duplicate-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import TickOutlineCircle from "../../assets/icons/tick-circle-outline-icon.svg";
import SavingsIcon from "../../assets/icons/savings-icon.svg";
import ControlPanelIcon from "../../assets/icons/control-panel-icon.svg";

// Mock Data Import
import { accessoryOptions } from "../../data/mock-data";
import DealService from "../../services/deal.service";
import { useParams } from "react-router-dom";

export default function DealCard(props) {
  const {
    id,
    type,
    dealType,
    dealDescription,
    flagged,
    deviceName,
    tariffName,
    tariffTerm,
    primaryVas,
    addedVas,
    multiDevice,
    multiTariff,
    accessory,
    dealPrice,
    ctc,
    eff,
    expiryDate,
    isDuplicate = false,
  } = props.dealData;

  const { duplicateCheck } = props;

  // Redux dispatch
  const dispatch = useDispatch();
  const { cycle, channel, oem, device } = useParams();

  const [vasOptions, setVASOptions] = useState([]);
  const [secondaryDeviceOptions, setSecondaryDeviceOptions] = useState([]);
  const [secondaryTariffOptions, setSecondaryTariffOptions] = useState([]);

  useEffect(() => {
    DealService.getStandardVASList().then((response) => {
      setVASOptions(response.data);
    });
  }, []);

  useEffect(() => {
    DealService.getGenericDeviceList(cycle, channel, oem).then((response) => {
      const deviceOptions = response.data.map((item) => {
        return { value: item.cid, label: item.device };
      });
      setSecondaryDeviceOptions(deviceOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DealService.getGenericDeviceTariffList(cycle, channel, oem, device).then(
      (response) => {
        const tariffOptions = response.data.map((item) => {
          return { value: item.tariff_code, label: item.tariff };
        });
        setSecondaryTariffOptions(tariffOptions);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Unique IDs when multiple deal cards are rendered
  const uid = useId();

  // Delete confirmation dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Menu for deal card
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle duplicate deal option
  const handleDuplicateDeal = (dealID) => {
    dispatch(duplicateDeal({ id: dealID, type: "bulkDeals" }));
    handleClose();
    window.scrollTo({ top: 360, left: 0, behavior: "smooth" });
  };

  // Handle delete deal option
  const handleDeleteDeal = (dealID) => {
    dispatch(deleteDeal({ id: dealID, type: "bulkDeals" }));
    handleClose();
    handleSnackOpen("Deal deleted successfully.");
    window.scrollTo({ top: 360, left: 0, behavior: "smooth" });
  };

  // Handle flag deal
  const [flagDialogOpen, setFlagDialogOpen] = useState(false);

  const handleFlagADeal = (dealID) => {
    dispatch(flagDeal({ id: dealID, type: "bulkDeals" }));
  };

  // Deal card types
  const [dealTypeData, setDealTypeData] = useState({
    headerBg: "bg-grey-800",
    headerIcon: PriceTagIcon,
  });

  // Add/Remove components
  const [removalID, setRemovalID] = useState(null);
  const [componentOption, setComponentOption] = useState(null);

  const handleAddVAS = (dealID) => {
    dispatch(
      addDealComponent({ id: dealID, type: "bulkDeals", component: "addedVas" })
    );
    handleClose();
  };

  const handleAddDevice = (dealID) => {
    dispatch(
      addDealComponent({
        id: dealID,
        type: "bulkDeals",
        component: "multiDevice",
      })
    );
    handleClose();
  };

  const handleAddTariff = (dealID) => {
    dispatch(
      addDealComponent({
        id: dealID,
        type: "bulkDeals",
        component: "multiTariff",
      })
    );
    handleClose();
  };

  const handleAddAccessory = (dealID) => {
    dispatch(
      addDealComponent({
        id: dealID,
        type: "bulkDeals",
        component: "accessory",
      })
    );
    handleClose();
  };

  // Handle open remove dialog
  const handleOpenRemoveDialog = (id, component) => {
    setRemoveDialogOpen(true);
    setRemovalID(id);
    setComponentOption(component);
  };

  // Handle remove component
  const handleConfirmRemove = (dealID) => {
    if (removalID != null) {
      if (componentOption === "vas") {
        dispatch(
          removeDealComponent({
            id: dealID,
            type: "bulkDeals",
            component: "addedVas",
          })
        );
      } else if (componentOption === "device") {
        dispatch(
          removeDealComponent({
            id: dealID,
            type: "bulkDeals",
            component: "multiDevice",
          })
        );
      } else if (componentOption === "tariff") {
        dispatch(
          removeDealComponent({
            id: dealID,
            type: "bulkDeals",
            component: "multiTariff",
          })
        );
      } else if (componentOption === "accessory") {
        dispatch(
          removeDealComponent({
            id: dealID,
            type: "bulkDeals",
            component: "accessory",
          })
        );
      }
    }
    handleDialogClose();
  };

  // Handle closing dialogs
  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setFlagDialogOpen(false);
    setRemoveDialogOpen(false);

    setRemovalID(null);
  };

  // Handle input fields
  const handleDealPriceChange = (event) => {
    let value = event.target.value;
    // Remove R and trimm
    value = value.replace("R", "").replace(/\s/g, '').replace(",","");

    dispatch(updateDeal({ id, value }));
  };

  // Snackbar state
  const [successSnack, setSuccessSnack] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackOpen = (message) => {
    setSnackbarMessage(message);
    setSuccessSnack(true);
  };

  const handleSuccessSnackClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnack(false);
  };

  // Number masking state
  const maskOptions = {
    mask: "R num",
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: ".",
      },
    },
  };
  const { ref } = useIMask(maskOptions);

  useEffect(() => {
    // Set deal types for card header styles
    const dealTypeData = {
      standalone: {
        headerBg: isDuplicate ? "bg-purple-100" : "bg-grey-800",
        headerIcon: PriceTagIcon,
      },
      accessory: {
        headerBg: isDuplicate ? "bg-purple-100" : "bg-grey-800",
        headerIcon: AccessoryIcon,
      },
      multi_device: {
        headerBg: isDuplicate ? "bg-purple-100" : "bg-grey-800",
        headerIcon: MultiDeviceIcon,
      },
      adhoc_deal: {
        headerBg:
          "bg-gradient-to-r from-blue-purple-gradient-start to-blue-purple-gradient-end",
        headerIcon: ControlPanelIcon,
      },
    };
    setDealTypeData(dealTypeData[type]);
  }, [type, isDuplicate, duplicateCheck]);

  const getDealTypeString = (dealType) => {
    switch(dealType){
      case "C":
        return "Cash";
      case "F":
        return "Finance";
      case "G":
        return "Gift Card";
      case "N":
        return "New Line";
      case "U":
        return "Upgrade";
      default:
        return "N/A";
    }
  }


  return (
    <section
      className="w-full min-w-[24rem] rounded-2xl bg-white shadow"
      aria-labelledby={uid + "dealID"}
    >
      <header
        className={`flex items-center justify-center gap-x-2 rounded-t-2xl p-6 ${
          flagged ? "bg-yellow-200" : dealTypeData.headerBg
        }`}
        title={dealDescription}
      >
        <InlineSVG
          src={dealTypeData.headerIcon}
          width={24}
          height={24}
          ariaHidden={true}
          className="fill-white"
        />
        <h2 className="text-base font-bold text-white" id={uid + "dealID"}>
          {deviceName}
        </h2>
      </header>

      <main className="px-8">
        {/* Deal Tariff Name and Duration */}
        <div className="my-6 flex items-center justify-center gap-x-4">
          {tariffName && (
            <>
              <p className="text-base font-bold text-black">{tariffName}</p>
              <div className="hidden h-6 w-px bg-grey-50 2xl:block"></div>
            </>
          )}
          <p className="text-base text-black">{tariffTerm} months</p>
        </div>

        {/* Deal Type */}
        <div className="my-6 flex items-center justify-center gap-x-4">
          <>
            <p className="text-base font-bold text-black">Deal Type</p>
            <div className="hidden h-6 w-px bg-grey-50 2xl:block"></div>
          </>
          <p className="text-base text-black">{getDealTypeString(dealType)}</p>
        </div>

        {/* Value added service */}
        <div className="my-6 flex items-center justify-center gap-x-2">
          <hr className="flex-1 border-grey-100" />
          <p className="text-base font-bold text-black">Value Added Services</p>
          <hr className="flex-1 border-grey-100" />
        </div>
        <div className="flex flex-col gap-4">
          <InputField
            select
            id={uid + "primaryVasID"}
            inputLabel="Primary VAS"
            defaultValue={primaryVas || "placeholder"}
            SelectProps={{
              IconComponent: (props) => <ArrowDropDownRoundedIcon {...props} />,
            }}
          >
            <MenuItem disabled value="placeholder">
              - Select -
            </MenuItem>
            {vasOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </InputField>

          {addedVas &&
            addedVas.map((vasItem) => (
              <div key={vasItem.id} className="flex items-end gap-x-2">
                <div className="flex-1">
                  <InputField
                    select
                    id={vasItem.id}
                    inputLabel="Additional VAS"
                    defaultValue={vasItem.vasName || "placeholder"}
                    SelectProps={{
                      IconComponent: (props) => (
                        <ArrowDropDownRoundedIcon {...props} />
                      ),
                    }}
                  >
                    <MenuItem disabled value="placeholder">
                      - Select -
                    </MenuItem>
                    {vasOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InputField>
                </div>
                {isDuplicate && (
                  <IconButton
                    aria-label="Remove Component"
                    onClick={() => handleOpenRemoveDialog(vasItem.id, "vas")}
                    className="mb-1 fill-red-200 hover:fill-red-300"
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </IconButton>
                )}
              </div>
            ))}
        </div>

        {/* Multi-Device */}
        {((multiDevice && multiDevice.length > 0) ||
          (multiTariff && multiTariff.length > 0)) && (
          <>
            <div className="my-6 flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-base font-bold text-black">Multi-Device</p>
              <hr className="flex-1 border-grey-100" />
            </div>
            <div className="flex flex-col gap-4">
              {multiDevice &&
                multiDevice.map((deviceItem) => (
                  <div className="flex items-end gap-x-2" key={deviceItem.id}>
                    <div className="flex-1">
                      <InputField
                        select
                        id={deviceItem.id}
                        inputLabel="Device"
                        defaultValue={deviceItem.deviceName || "placeholder"}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                        }}
                      >
                        <MenuItem disabled value="placeholder">
                          - Select -
                        </MenuItem>
                        {secondaryDeviceOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    {isDuplicate && (
                      <IconButton
                        aria-label="Remove Component"
                        onClick={() =>
                          handleOpenRemoveDialog(deviceItem.id, "device")
                        }
                        className="mb-1 fill-red-200 hover:fill-red-300"
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          ariaHidden
                          width={24}
                          height={24}
                        />
                      </IconButton>
                    )}
                  </div>
                ))}

              {multiTariff &&
                multiTariff.map((tariffItem) => (
                  <div className="flex items-end gap-x-2" key={tariffItem.id}>
                    <div className="flex-1">
                      <InputField
                        select
                        id={tariffItem.id + "-tariff"}
                        inputLabel="Tariff"
                        defaultValue={tariffItem.tariffName || "placeholder"}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                        }}
                      >
                        <MenuItem disabled value="placeholder">
                          - Select -
                        </MenuItem>
                        {secondaryTariffOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    {isDuplicate && (
                      <IconButton
                        aria-label="Remove Component"
                        onClick={() =>
                          handleOpenRemoveDialog(tariffItem.id, "tariff")
                        }
                        className="mb-1 fill-red-200 hover:fill-red-300"
                      >
                        <InlineSVG
                          src={DeleteIcon}
                          ariaHidden
                          width={24}
                          height={24}
                        />
                      </IconButton>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}

        {/* Accessories */}
        {accessory && accessory.length > 0 && (
          <>
            <div className="my-6 flex items-center justify-center gap-x-2">
              <hr className="flex-1 border-grey-100" />
              <p className="text-base font-bold text-black">Accessory</p>
              <hr className="flex-1 border-grey-100" />
            </div>
            <div className="flex flex-col gap-4">
              {accessory.map((accessoryItem) => (
                <div key={accessoryItem.id} className="flex items-end gap-x-2">
                  <div className="flex-1">
                    <InputField
                      select
                      id={accessoryItem.id}
                      inputLabel="Accessory"
                      defaultValue={
                        accessoryItem.accessoryName || "placeholder"
                      }
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {accessoryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  {isDuplicate && (
                    <IconButton
                      aria-label="Remove Component"
                      onClick={() =>
                        handleOpenRemoveDialog(accessoryItem.id, "accessory")
                      }
                      className="mb-1 fill-red-200 hover:fill-red-300"
                    >
                      <InlineSVG
                        src={DeleteIcon}
                        ariaHidden
                        width={24}
                        height={24}
                      />
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          </>
        )}

        {/* Deal Info */}
        <div className="my-6 flex items-center justify-center gap-x-2">
          <hr className="flex-1 border-grey-100" />
          <p className="text-base font-bold text-black">Deal Info.</p>
          <hr className="flex-1 border-grey-100" />
        </div>
        <div className="flex flex-col gap-4">
          <InputField
            id={uid + "dealPriceID"}
            inputLabel="Deal Price"
            placeholder="Enter price"
            inputRef={ref}
            defaultValue={dealPrice}
            onBlur={handleDealPriceChange}
          />

          <div className="mt-2 flex items-center justify-between">
            <p className="text-base font-bold text-black">CTC:</p>
            <p className="text-base text-black">
              {ctc?.toLocaleString("en-ZA", {
                style: "currency",
                currency: "ZAR",
              })}
            </p>
          </div>

          <div className="mb-2 flex items-center justify-between">
            <p className="text-base font-bold text-black">EFF:</p>
            <p className="text-base text-black">{eff}%</p>
          </div>

          <CustomDatePicker label="Expires On" value={expiryDate} />
        </div>
      </main>

      <footer className="flex items-center justify-between p-6">
        <IconButton
          aria-label="Flag this deal"
          onClick={() =>
            flagged ? handleFlagADeal(id) : setFlagDialogOpen(true)
          }
          className={`${flagged ? "fill-yellow-200" : "fill-grey-200"}`}
        >
          {flagged ? (
            <InlineSVG src={FlagIcon} width={24} height={24} ariaHidden />
          ) : (
            <InlineSVG
              src={FlagOutlinedIcon}
              width={24}
              height={24}
              ariaHidden
            />
          )}
        </IconButton>

        <IconButton
          id={`${uid}-more-menu-trigger`}
          className="text-red-100"
          aria-label="More deal options"
          aria-controls={open ? `${uid}-more-menu` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreHorizRoundedIcon />
        </IconButton>
        {!isDuplicate ? (
          <Menu
            id={`${uid}-more-menu`}
            anchorEl={anchorEl}
            open={open}
            keepMounted={true}
            disablePortal={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": `${uid}-more-menu-trigger`,
            }}
          >
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => handleDuplicateDeal(id)}
            >
              <InlineSVG
                src={DuplicateIcon}
                width={24}
                height={24}
                ariaHidden={true}
                className="fill-red-100"
              />
              Duplicate deal
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            id={`${uid}-more-menu`}
            anchorEl={anchorEl}
            open={open}
            keepMounted={true}
            disablePortal={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": `${uid}-more-menu-trigger`,
            }}
          >
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => handleDuplicateDeal(id)}
            >
              <InlineSVG
                src={DuplicateIcon}
                width={24}
                height={24}
                ariaHidden={true}
                className="fill-red-100"
              />
              Duplicate deal
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => handleAddDevice(id)}
            >
              <InlineSVG
                src={MultiDeviceIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add device
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => handleAddAccessory(id)}
            >
              <InlineSVG
                src={AccessoryIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add accessory
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => handleAddVAS(id)}
            >
              <InlineSVG
                src={RewardIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add VAS
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => handleAddTariff(id)}
            >
              <InlineSVG
                src={SavingsIcon}
                ariaHidden
                width={24}
                height={24}
                className="fill-red-100"
              />
              Add tariff
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() => setDeleteDialogOpen(true)}
            >
              <InlineSVG
                src={DeleteIcon}
                width={24}
                height={24}
                ariaHidden={true}
                className="fill-yellow-200"
              />
              Delete deal
            </MenuItem>
          </Menu>
        )}
      </footer>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "delete-dialog-title"}
      >
        <DialogTitle id={uid + "delete-dialog-title"}>
          Delete this deal?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to permanently delete this deal. Are you sure you want
            to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleDeleteDeal(id);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={removeDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "remove-dialog-title"}
      >
        <DialogTitle id={uid + "remove-dialog-title"}>
          Remove this component?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to remove this component from the deal. This action
            cannot be undone. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleConfirmRemove(id);
              handleSnackOpen("The component has been removed successfully.");
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={flagDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "flag-dialog-title"}
      >
        <DialogTitle id={uid + "flag-dialog-title"}>
          Flag this deal?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to flag this deal for review and approval. Are you
            sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleFlagADeal(id);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={successSnack}
        autoHideDuration={6000}
        onClose={handleSuccessSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={TickOutlineCircle}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{snackbarMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleSuccessSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </section>
  );
}
