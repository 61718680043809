// React and MUI Imports
import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

// Redux Imports
import { useDispatch } from "react-redux";
import {
  addDealComponent,
  deleteDeal,
  duplicateDeal,
  publishDeal,
  removeDealComponent,
  submitDealForApproval,
  propagateAdhocDeal,
  getAdHocsChildrenDeals,
  updateDealComponent,
} from "../../slices/dealCanvas";

// Component Imports
import AdHocDealInfo from "../ad-hoc-deal-info/ad-hoc-deal-info-component";
import InlineSVG from "../inline-svg/inline-svg-component";
import InputField from "../input/input-component";
import AdHocService from "../../services/adhoc.service";
import GeneralService from "../../services/general.service";
import AuthService from "../../services/auth.service";
import ChannelService from "../../services/channel.service";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import InfoIcon from "../../assets/icons/info-icon.svg";
import InfoSolidIcon from "../../assets/icons/info-solid-icon.svg";
import AccessoryIcon from "../../assets/icons/connected-devices-icon.svg";
import DeviceIcon from "../../assets/icons/device-icon.svg";
import RouterIcon from "../../assets/icons/router-icon.svg";
import SimIcon from "../../assets/icons/sim-icon.svg";
import CalendarStarIcon from "../../assets/icons/calendar-star-icon.svg";
import GiftIcon from "../../assets/icons/gift-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import DuplicateIcon from "../../assets/icons/duplicate-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import SendIcon from "../../assets/icons/send-icon.svg";
import HierarchyIcon from "../../assets/icons/hierarchy-icon.svg";
import TickIcon from "../../assets/icons/tick.svg";

// Mock Data
// import // accessoryOptions,
// // deviceOptions,
// // tariffOptions,
// //vasOptions,
// "../../data/mock-data";

export default function AdHocDealCards(props) {
  const {
    id,
    device,
    dealDescription,
    paymentType,
    multiVAS,
    multiAccessory,
    multiDevice,
    multiTariff,
    multiRouter,
    total_ctd,
    terms,
    dealStartDate,
    dealEndDate,
    published,
    submitForApproval,
    deal_type_id,
    device_basket_id,
    channel_id,
    dealsheet_type,
    dealer_qualification,
    dataHasChanged = false,
  } = props.data;
  const {
    tariffOptions,
    vasOptions,
    routerOptions,
    campaign,
    deleteDeal,
    deleteDealTerm,
  } = props;

  const { dealsetID, dealType = "adhocDeals", handleGenericSnackOpen } = props;

  const { campaign_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let selectedTariff24 = "";
  let selectedTariff36 = "";

  const [campaignCycle, setCampaignCycle] = useState("none");
  const [campaignLoaded, setCampaignLoaded] = useState(0);

  useEffect(() => {
    AdHocService.getCycleFromCampaign(campaign_id).then((response) => {
      setCampaignCycle(response.data);
      setCampaignLoaded(campaignLoaded + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  terms.forEach((term) => {
    const matchingTariffOption = tariffOptions.find(
      (option) => option.value === term.tariff
    );
    if (matchingTariffOption) {
      if (term.period === 24) selectedTariff24 = matchingTariffOption.label;
      else if (term.period === 36)
        selectedTariff36 = matchingTariffOption.label;
    }
  });

  const getChannelOptions = async () => {
    AdHocService.getAdhocDealsChannels()
      .then((response) => {
        props.newDealFuncs?.handleChannelOptionsChange(response.data);
        setChannels(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getDealTypes = async () => {
    GeneralService.getDealTypes()
      .then((response) => {
        props.newDealFuncs?.handleDealTypeOptionsChange(response.data);
        setDealTypes(response.data);
        setUpdatedDealType(response.data.find((e) => e.value === deal_type_id));
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getOEMOptions = async (channel_id) => {
    const cycle = campaignCycle;

    if (campaignLoaded !== 0) {
      ChannelService.getChannelOEMDevices(cycle, channel_id)
        .then((response) => {
          const { data } = response;

          const listA = data.reduce((result, item) => {
            const oemIndex = result.findIndex((el) => el.oem === item.oem);

            if (item.tariff_type !== "G") {
              if (oemIndex === -1) {
                // If the oem doesn't exist in listA, create a new oem object.
                result.push({
                  oem: item.oem,
                  devices: [
                    {
                      value: item.cid,
                      label: item.model,
                      tariff_type: item.tariff_type,
                    },
                  ],
                });
              } else {
                // If the oem already exists in listA, push the option to its options array.
                result[oemIndex].devices.push({
                  value: item.cid,
                  label: item.model,
                  tariff_type: item.tariff_type,
                });
              }
            }

            return result;
          }, []);

          props.newDealFuncs?.handleOemDeviceOptionsChange(listA);
          setOEMDevices(listA);

          const listB = [...new Set(data.map((item) => item.oem))].map(
            (oem) => ({
              value: oem,
              label: oem,
            })
          );

          props.newDealFuncs?.handleOemOptionsChange(listB);
          setOEMOptions(listB);
        })
        .catch((error) => {
          // handleServerError(error);
          const status = error.response.status;
          if (status === 401) {
            AuthService.logout();
            navigate("/logout");
          } else if (status === 403) {
            navigate("/forbidden");
          } else {
            // Handle Error
            navigate("/error");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const [channels, setChannels] = useState([]);
  const [oemOptions, setOEMOptions] = useState([]);
  const [oemDevices, setOEMDevices] = useState([]);
  const [dealTypes, setDealTypes] = useState([]);
  const [briefTypeOptions, setBriefTypeOptions] = useState([]);
  const [dealerQualificationOptions, setDealerQualificationOptions] = useState(
    []
  );
  const [selectedDealerQualification, setSelectedDealerQualification] =
    useState("");
  const [selectedBriefType, setSelectedBriefType] = useState("");

  // TODO: Fetch from API once available - Hard coded for now, does not exist in DB
  const [dealSheetTypeOptions, setDealSheetTypeOptions] = useState([
    {
      value: "N",
      label: "New Line",
    },
    {
      value: "U",
      label: "Upgrade",
    },
    {
      value: "P",
      label: "Prepaid",
    },
    {
      value: "A",
      label: "All",
    },
  ]);

  const getBriefTypeOptions = async () => {
    GeneralService.getBriefTypes()
      .then((response) => {
        props.newDealFuncs?.handleBriefTypeOptionsChange(response.data);
        setBriefTypeOptions(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const getDealerQualificationOptions = async (briefType) => {
    GeneralService.getDealerQualifications(briefType)
      .then((response) => {
        props.newDealFuncs?.handleDealerQualificationOptionsChange(
          response.data
        );
        setDealerQualificationOptions(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  useEffect(() => {
    getDealerQualificationOptions(props.data.brief_type);
    getChannelOptions();
    getOEMOptions(props.data.channel_id);
    getDealTypes();
    getBriefTypeOptions();
  }, [campaignLoaded]);

  // Show/Hide Deal Description
  const [showDealDescription, setShowDealDescription] = useState(false);
  const handleShowDealDescription = () => {
    setShowDealDescription(!showDealDescription);
  };

  // Unique IDs
  const uid = useId();

  // More options for each deal card
  const [anchorEl, setAnchorEl] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);

  // Handle options open event
  const handleOptionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Handle options close event
  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  // Handle Add Component
  const handleAddComponent = (id, component, dealType, subDealID) => {
    dispatch(
      addDealComponent({
        id: id,
        type: dealType,
        component: component,
        subDealID: subDealID,
      })
    );
    handleOptionsClose();
  };

  // Set Component
  const [componentOption, setComponentOption] = useState("");
  const [componentIndex, setComponentIndex] = useState(null);

  // Handle Remove Component
  const handleRemoveComponent = (id, dealType, component, index, subDealID) => {
    dispatch(
      removeDealComponent({
        id: id,
        type: dealType,
        component: component,
        index: index,
        subDealID: subDealID,
      })
    );
    handleOptionsClose();
    handleGenericSnackOpen("success", "Add-on deleted successfully.");
  };

  // Handle duplicate deal option
  const handleDuplicateDeal = (dealsetID, dealID) => {
    dispatch(
      duplicateDeal({ id: dealsetID, type: "adhocDeals", subDealID: dealID })
    );
    handleGenericSnackOpen("success", "Deal duplicated successfully.");
  };

  // Handle delete deal option
  const handleDeleteDeal = () => {
    deleteDeal(id, dealType, {
      dde_parent_keys: terms.map((item) => item.dde_parent_key),
    });
    // dispatch(
    //   deleteDeal({ id: dealsetID, type: "adhocDeals", subDealID: dealID })
    // );
    // handleGenericSnackOpen("success", "Deal deleted successfully.");
  };

  // Dialogs
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [propagateDialogOpen, setPropagateDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setRemoveDialogOpen(false);
    setDeleteDialogOpen(false);
    setPropagateDialogOpen(false);
  };

  // Button states
  const [propagated, setPropagated] = useState(true);

  // Handle propagated state
  const handlePropagated = () => {
    if (propagated) {
      const dde_parent_keys = [];
      terms.forEach((term) => {
        dde_parent_keys.push(term.dde_parent_key);
      });
      navigate(`children?id=${id}`, {
        state: {
          dde_parent_keys: dde_parent_keys,
          device_name: device.deviceName,
        },
      });
    } else {
      dispatch(propagateAdhocDeal({ type: "adhocDeals", id: id }));
      handleGenericSnackOpen("success", "80 Deals successfully generated");
    }
  };

  const handlePropagate = () => {
    const deals = terms.map((item) => {
      return { dde_parent_key: item.dde_parent_key };
    });
    AdHocService.propagateAdHocHeadlineDeals(deals).then((response) => {
      const { message } = response.data;
      handleGenericSnackOpen("success", message);
    });
  };

  // Handle Publish Deal
  const handlePublishDeal = () => {
    AdHocService.loadAdHocCard(
      campaign_id,
      channel_id,
      device_basket_id,
      deal_type_id
    ).then((response) => {
      const { message } = response.data;
      handleGenericSnackOpen("success", message);
    });
  };

  // Handle submit for Approval
  const handleSubmitForApproval = (id, subDealID) => {
    dispatch(
      submitDealForApproval({
        type: "adhocDeals",
        id: id,
        subDealID: subDealID,
      })
    );
  };

  // Handle Dealset Propagation
  const handleDealsetPropagation = (id) => {
    dispatch(propagateAdhocDeal({ type: "adhocDeals", id: id }));
    handlePropagated();
  };

  const [tariff24, setTariff24] = useState("");
  const [tariff36, setTariff36] = useState("");
  const [dealPrice24, setDealPrice24] = useState(
    terms.find((term) => {
      return term.period === 24;
    })?.dealPrice
  );
  const [dealPrice30, setDealPrice30] = useState(
    terms.find((term) => {
      return term.period === 30;
    })?.dealPrice
  );
  const [dealPrice36, setDealPrice36] = useState(
    terms.find((term) => {
      return term.period === 36;
    })?.dealPrice
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updatedDealType, setUpdatedDealType] = useState("");

  const handleDealTypeChange = (e) => {
    setUpdatedDealType(e.target.value);
    setPropagated(false);
  };

  const handleTariffTermsChange = (_) => {
    const { term, e } = _;
    const { period } = term;
    const { value } = e.target;
    if (period === 24) {
      setTariff24(value);
    } else if (period === 36) {
      setTariff36(value);
    }
    setPropagated(false);
  };

  const handleDealPriceChange = (_) => {
    const { term, e } = _;
    const { period } = term;
    const { value } = e.target;
    if (period === 24) {
      setDealPrice24(value);
    } else if (period === 30) {
      setDealPrice30(value);
    } else if (period === 36) {
      setDealPrice36(value);
    }
    setPropagated(false);
  };

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e));
    // setPropagated(false);
  };
  const handleEndDateChange = (e) => {
    setEndDate(new Date(e));
    // setPropagated(false);
  };

  const newDealFuncs = {
    handleDealTypeChange,
    handleTariffTermsChange,
    handleStartDateChange,
    handleEndDateChange,
    handleDealPriceChange,
  };

  const [deviceOptions, setDeviceOptions] = useState([]);
  const [accessoryOptions, setAccessoryOptions] = useState([]);

  useEffect(() => {
    if (campaignLoaded !== 0) {
      ChannelService.getAllChannelDevices(campaignCycle, channel_id)
        .then((response) => {
          setDeviceOptions(response.data.filter((v) => v.object_type === "D"));
          setAccessoryOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
          //handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignLoaded]);

  const handleUpdateComponent = (
    id,
    component,
    dealType,
    data,
    termIndex = -1
  ) => {
    dispatch(
      updateDealComponent({
        id: id,
        type: dealType,
        component: component,
        data: data,
        termIndex,
      })
    );
  };

  return (
    <>
      <article
        className={`w-full rounded-2xl border-2 border-solid bg-white shadow ${
          dataHasChanged ? "border-yellow-200" : "border-transparent"
        }`}
        aria-label={`${device.deviceName}`}
      >
        <header className="rounded-t-2xl bg-gradient-to-br from-blue-navy-gradient-start to-blue-navy-gradient-end px-8 py-4">
          <div className="flex items-center justify-center gap-1">
            {/* <IconButton
              className="ml-auto fill-white"
              aria-label={`${
                showDealDescription ? "Hide" : "Show"
              } device description`}
              onClick={handleShowDealDescription}
            >
              <InlineSVG
                src={showDealDescription ? InfoSolidIcon : InfoIcon}
                width={24}
                height={24}
                ariaHidden
              />
            </IconButton> */}
            <div className="ml-auto fill-white"></div>
            <p className="text-center text-white">{device.deviceName}</p>

            {/* <IconButton
              className="ml-auto fill-white"
              aria-label="Duplicate deal"
              onClick={() => handleDuplicateDeal(dealsetID, id)}
            >
              <InlineSVG
                src={DuplicateIcon}
                width={24}
                height={24}
                ariaHidden
              />
            </IconButton> */}
            <IconButton
              className="ml-auto fill-white"
              aria-label="Delete deal"
              onClick={() => setDeleteDialogOpen(true)}
            >
              <InlineSVG src={DeleteIcon} width={24} height={24} ariaHidden />
            </IconButton>
          </div>

          <Collapse in={showDealDescription}>
            <hr className="my-4 border-white" />
            <p className="text-white">{dealDescription}</p>
          </Collapse>
        </header>
        <section className="flex flex-col gap-4 px-8">
          <br />
          {/* {paymentType && (
            <div className="mt-6 mb-2 self-center rounded-full border border-solid border-green-200 bg-green-200/10 px-8 py-2 text-center text-sm font-medium text-green-200">
              {paymentType}
            </div>
          )}
          
        {(deal.type || deal.deal_type || deal.data_tariff_type) && (
          <div className="flex flex-wrap items-center justify-center gap-4">
            {deal.type && (
              <div className="rounded-full border border-solid border-green-200 bg-green-200/10 px-4 py-2 text-sm font-medium text-green-200">
                {deal.type}
              </div>
            )}
            {deal.deal_type && (
              <div className="rounded-full border border-solid border-red-100 bg-red-60/10 px-4 py-2 text-sm font-medium text-red-100">
                {deal.deal_type}
              </div>
            )}
            {deal.data_tariff_type && (
              <div className="rounded-full border border-solid border-yellow-200 bg-yellow-100/10 px-4 py-2 text-sm font-medium text-yellow-200">
                {deal.data_tariff_type}
              </div>
            )}
          </div>
        )} */}

          {/* VAS */}
          {/* {multiVAS && multiVAS.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ VAS</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiVAS.map((router, index) => (
                <div key={router.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={router.id}
                      defaultValue={router.routerName || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {vasOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete router"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiVAS");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )} */}

          {/* Accessories */}
          {multiAccessory && multiAccessory.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Accessory</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiAccessory.map((accessory, index) => (
                <div key={accessory.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={accessory.id}
                      value={accessory.cid || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                      onChange={(e) => {
                        const { value: cid } = e.target;
                        const data = { ...accessory, cid };
                        handleUpdateComponent(
                          id,
                          "multiAccessory",
                          dealType,
                          data
                        );
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {accessoryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete accessory"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiAccessory");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Devices */}
          {multiDevice && multiDevice.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Device</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiDevice.map((device, index) => (
                <div key={device.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={device.id}
                      value={device.cid || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                      onChange={(e) => {
                        const { value: cid } = e.target;
                        const data = { ...device, cid };
                        handleUpdateComponent(
                          id,
                          "multiDevice",
                          dealType,
                          data
                        );
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {deviceOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete device"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiDevice");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Tariffs */}
          {multiTariff && multiTariff.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Tariff</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiTariff.map((tariff, index) => (
                <div key={tariff.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={tariff.id}
                      value={tariff.tariff_code || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                      onChange={(e) => {
                        const { value: tariff_code } = e.target;
                        const data = { ...tariff, tariff_code };
                        handleUpdateComponent(
                          id,
                          "multiTariff",
                          dealType,
                          data
                        );
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {tariffOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete tariff"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiTariff");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Routers */}
          {multiRouter && multiRouter.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Router</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiRouter.map((router, index) => (
                <div key={router.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={router.id}
                      value={router.cid || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                      onChange={(e) => {
                        const { value: cid } = e.target;
                        const data = { ...router, cid };
                        handleUpdateComponent(
                          id,
                          "multiRouter",
                          dealType,
                          data
                        );
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {routerOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete router"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiRouter");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          <IconButton
            id={`${uid}-more-menu-trigger`}
            aria-label="More deal options"
            aria-controls={moreOptionsOpen ? `${uid}-more-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={moreOptionsOpen ? "true" : undefined}
            onClick={handleOptionsOpen}
            className="mb-2 self-center bg-red-100 fill-white hover:bg-red-200 hover:fill-white"
          >
            <InlineSVG src={PlusIcon} width={24} height={24} ariaHidden />
          </IconButton>
          <Menu
            id={`${uid}-more-menu`}
            anchorEl={anchorEl}
            open={moreOptionsOpen}
            keepMounted={true}
            disablePortal={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleOptionsClose}
            MenuListProps={{
              "aria-labelledby": `${uid}-more-menu-trigger`,
            }}
          >
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(id, "multiAccessory", dealType, dealsetID)
              }
            >
              <InlineSVG
                src={AccessoryIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add accessory
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(id, "multiDevice", dealType, dealsetID)
              }
            >
              <InlineSVG
                src={DeviceIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add device
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(id, "multiTariff", dealType, dealsetID)
              }
            >
              <InlineSVG
                src={SimIcon}
                ariaHidden
                width={24}
                height={24}
                className="fill-red-100"
              />
              Add tariff
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(id, "multiRouter", dealType, dealsetID)
              }
            >
              <InlineSVG
                src={RouterIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add router
            </MenuItem>
            {terms.length < 3 && (
              <MenuItem
                className="flex items-center gap-x-2"
                disableRipple
                onClick={() =>
                  handleAddComponent(id, "terms", dealType, dealsetID)
                }
              >
                <InlineSVG
                  src={CalendarStarIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-red-100"
                />
                Add Term
              </MenuItem>
            )}
          </Menu>
          {channels.length > 0 &&
            oemOptions.length > 0 &&
            oemDevices.length > 0 &&
            dealTypes.length > 0 && (
              //dealerQualificationOptions.length > 0 &&
              //briefTypeOptions.length > 0 &&
              <AdHocDealInfo
                id={id}
                dealTypeId={deal_type_id}
                terms={terms}
                dealStartDate={dealStartDate}
                dealEndDate={dealEndDate}
                showSimOption={false}
                snackbarMessages={handleGenericSnackOpen}
                dealType={dealType}
                dealsetID={dealsetID}
                createDealForm={false}
                tariffOptions={props.tariffOptions}
                channels={channels}
                oemOptions={oemOptions}
                oemDevices={oemDevices}
                dealTypes={dealTypes}
                briefTypeOptions={briefTypeOptions}
                dealerQualificationOptions={dealerQualificationOptions}
                dealSheetTypeOptions={dealSheetTypeOptions}
                selectedChannel={
                  channels.find(
                    (channel) => channel.value === props.data.channel_id
                  ).label
                }
                // selectedOEM={
                //   oemOptions.find((oem) => oem.value === device.oem).label
                // }
                // selectedDevice={
                //   oemDevices
                //     .find((item) =>
                //       item.devices.some(
                //         (oemDevice) => oemDevice.label === device.deviceName
                //       )
                //     )
                //     .devices.find(
                //       (oemDevice) => oemDevice.label === device.deviceName
                //     ).label
                // }
                selectedDealType={
                  dealTypes.find(
                    (dealType) => dealType.value === props.data.deal_type_id
                  )?.label
                }
                selectedDealSheetType={dealsheet_type}
                selectedDealerQualification={
                  dealerQualificationOptions.find(
                    (e) => e.value === props.data.dealer_qualification
                  )?.label
                }
                selectedBriefType={briefTypeOptions.find(
                  (briefType) => briefType === props.data.brief_type
                )}
                selectedTariff24={selectedTariff24}
                selectedTariff36={selectedTariff36}
                dealInfo={props.data}
                newDealFuncs={newDealFuncs}
                total_ctd={total_ctd}
                dealPrice24={dealPrice24}
                dealPrice30={dealPrice30}
                dealPrice36={dealPrice36}
                minDate={campaign?.campaign_start_date}
                maxDate={campaign?.campaign_end_date}
                deleteDealTerm={deleteDealTerm}
                vasOptions={vasOptions}
                dealer_qualification={props.data.dealer_qualification}
              />
            )}
        </section>
        <footer
          className={`flex flex-col gap-4 px-8 pt-8 ${
            published ? "pb-6" : "pb-2"
          }`}
        >
          <Button
            variant="contained"
            disableFocusRipple
            className="gap-2"
            startIcon={
              <InlineSVG
                src={published ? TickIcon : UploadIcon}
                width={24}
                height={24}
                ariaHidden
                className={published ? "fill-grey-300" : "fill-white"}
              />
            }
            onClick={handlePublishDeal}
            disabled={true}
          >
            {published ? "Loaded" : "Load Deal(s)"}
          </Button>

          {/* {!published && (
            <Button
              variant={submitForApproval ? "contained" : "outlined"}
              disableFocusRipple
              className="gap-2"
              startIcon={
                <InlineSVG
                  src={submitForApproval ? TickIcon : SendIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className={submitForApproval ? "fill-grey-300" : "fill-black"}
                />
              }
              onClick={() => handleSubmitForApproval(dealsetID, id)}
              disabled={true}
            >
              {submitForApproval ? "Submitted" : "Submit for Approval"}
            </Button>
          )} */}

          <Button
            variant="contained"
            disableFocusRipple
            className="gap-2"
            startIcon={
              <InlineSVG
                src={HierarchyIcon}
                width={24}
                height={24}
                ariaHidden
                className={"fill-white"}
              />
            }
            onClick={() => setPropagateDialogOpen(true)}
            disabled={dataHasChanged}
          >
            Propagate
          </Button>

          {!published && (
            <Button
              variant="text"
              disableFocusRipple
              className="gap-2"
              startIcon={
                <InlineSVG
                  src={HierarchyIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-red-100"
                />
              }
              onClick={handlePropagated}
            >
              {propagated ? "View Children Deals" : "Propagate"}
            </Button>
          )}
        </footer>
      </article>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={removeDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "remove-dialog-title"}
      >
        <DialogTitle id={uid + "remove-dialog-title"}>
          Remove this add-on?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with permanently removing this
            add-on?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleRemoveComponent(
                id,
                dealType,
                componentOption,
                componentIndex,
                dealsetID
              );
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "delete-dialog-title"}
      >
        <DialogTitle id={uid + "delete-dialog-title"}>
          Delete this deal?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with permanently deleting this
            deal?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleDeleteDeal();
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={propagateDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "propagate-dialog-title"}
      >
        <DialogTitle id={uid + "propagate-dialog-title"}>
          Propagate this card?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action create children deals for each term of this card.
            Existing children will be overridden. Are sure you want to
            propagate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handlePropagate();
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
