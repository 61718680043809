// React and MUI Imports
import { useEffect, useState } from "react";
import { InputAdornment, ListItemText, ListSubheader, MenuItem, TextField } from "@mui/material";

// Custom Component Imports
import InputField from "../input/input-component";
import CustomCheckbox from "../checkbox/checkbox-component";
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../assets/icons/search-icon.svg";

export default function MultiSelectDropdownWithSearch(props) {
  const { label, options, filterBy, setFilterBy, disabled = false } = props;

  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    let filteredData = options;

    if (searchText) {
      filteredData = options.map(category => ({
        heading: category.heading,
        options: category.options.filter(option =>
          option.label.toLowerCase().includes(searchText.toLowerCase())
        ),
      }));
    }

    setFilteredOptions(filteredData);
  }, [options, searchText]);

  // Handle search for filters via the input field in the menu dropdown
  const handleFilterSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  // Handle filter by option change
  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  return (
    <InputField
      select
      inputLabel={label}
      value={filterBy}
      onChange={handleFilterByChange}
      SelectProps={{
        multiple: true,
        IconComponent: (props) => (
          <ArrowDropDownRoundedIcon {...props} />
        ),
        renderValue: (selected) => selected.length > 0 ? selected.join(", ") : "All",
        displayEmpty: true,
        MenuProps: {
          MenuListProps: {
            autoFocusItem: true
          }
        }
      }}
      disabled={disabled}
    >
      {/* Search input field */}
      <div onKeyDown={(e) => e.stopPropagation()} className="py-4 px-6 sticky top-0 bg-white z-10">
        <TextField
          placeholder="Search"
          size="small"          
          fullWidth
          value={searchText}
          onChange={handleFilterSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InlineSVG
                  src={SearchIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-black"
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      
      {/* Filter options */}
      <MenuItem value="" className="px-6">
        All
      </MenuItem>
      {filteredOptions.map((filter) => [
        filter.options.length > 0 && <ListSubheader key={filter.value} className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black top-[70px]">
          {filter.heading}
        </ListSubheader>,
        ...filter.options.map((item) => (
          <MenuItem value={item.label}>
            <CustomCheckbox
              checked={filterBy.indexOf(item.label) > -1}
            />
            <ListItemText primary={item.label} />
          </MenuItem>
        )),
      ])}
    </InputField>
  );
};