// React and MUI Imports
import { useEffect, useState } from "react";
import { InputAdornment, ListItemText, ListSubheader, MenuItem, TextField } from "@mui/material";

// Custom Component Imports
import InputField from "../input/input-component";
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../assets/icons/search-icon.svg";

// Unique ID
// import { v4 as uuidv4 } from "uuid";

SelectDropdownWithSearch.defaultProps = {
  selectedOption: "",
};

export default function SelectDropdownWithSearch(props) {
  const { label, options, selectedOption, setSelectedOption, disabled = false } = props;

  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    let filteredData = options;

    if (searchText) {
      if(options.options) {
        filteredData = options.map(category => ({
          heading: category.heading,
          options: category.options.filter(option =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
          ),
        }));
      } else {
        filteredData = options.filter(option =>
          option.label.toLowerCase().includes(searchText.toLowerCase())          
        );
      }    
    }

    setFilteredOptions(filteredData);
  }, [options, searchText]);

  // Handle search for filters via the input field in the menu dropdown
  const handleFilterSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleOnChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  return (
    <InputField
      select
      inputLabel={label}
      value={selectedOption}
      onChange={handleOnChange}
      SelectProps={{
        IconComponent: (props) => (
          <ArrowDropDownRoundedIcon {...props} />
        ),
        renderValue: (selected) => selected ? selected : "- Select -",
        displayEmpty: true,
        MenuProps: {
          MenuListProps: {
            autoFocusItem: true
          }
        }
      }}
      disabled={disabled}
    >
      {/* Search input field */}
      <div onKeyDown={(e) => e.stopPropagation()} className="py-4 px-6 sticky top-0 bg-white z-10">
        <TextField
          placeholder="Search"
          size="small"          
          fullWidth
          value={searchText}
          onChange={handleFilterSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InlineSVG
                  src={SearchIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-black"
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      
      {filteredOptions.options ? (
        filteredOptions.map((option) => [
          option.options.length > 0 && 
          <ListSubheader key={option.value} className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black top-[70px]">
            {option.heading}
          </ListSubheader>,
          ...option.options.map((item) => (
            <MenuItem value={item.label}>
              <ListItemText primary={item.label} />
            </MenuItem>
          )),
        ])
      ) : (
        filteredOptions.map((option, index) => (
          <MenuItem value={option.label} key={index}>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))
      )}
      
    </InputField>
  );
};