// React Imports
import { Link, useNavigate, useParams } from "react-router-dom";

// Mui Imports
import { Button, Slide, Snackbar } from "@mui/material";

// Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";

// Icon Imports
import PriceTagIcon from "../../../assets/icons/price-tag-icon.svg";
import SimIcon from "../../../assets/icons/sim-icon.svg";
import DealService from "../../../services/deal.service";
import AuthService from "../../../services/auth.service";
import { useState, useEffect } from "react";

//import SubmitIcon from "../../../assets/icons/submit-icon.svg";
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
//import FAB from "../../../components/floating-action-button/floating-action-button-component";
//import SaveIcon from "../../../assets/icons/save-icon.svg";
//import DownloadIcon from "../../../assets/icons/download-icon-v2.svg";

export default function HeadlineDealsOverview() {
  const { cycle } = useParams();
  const navigate = useNavigate();
  const [totalDeviceDeals, setTotalDeviceDeals] = useState(0);
  const [totalSimOnlyDeals, setTotalSimOnlyDeals] = useState(0);
  const [titleHeader, setTitleHeader] = useState("");

  const user = AuthService.getCurrentUser();
  if (user === null) {
    AuthService.logout();
    navigate("/logout");
  }

  const isTerminalsUser = user.user_roles.includes("terminal");

  useEffect(() => {
    DealService.getTotalDeviceOnlyDeals(cycle)
      .then((response) => {
        const { total, headlines } = response.data;
        setTotalDeviceDeals(total);
        setTitleHeader(headlines ? "Headline Deals" : "OEM Negotiations");
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DealService.getTotalSIMOnlyDeals(cycle)
      .then((response) => {
        const { total } = response.data;
        setTotalSimOnlyDeals(total);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handlePublish = () => {
  //   DealService.publishHeadlines(cycle)
  //   .then((response)=>{
  //     handleSnackOpen("Headline deals published.");
  //   })
  //   .catch((error)=>{

  //   });
  // };
  // Floating action button options (actions)
  // const fabActions = [
  //   {
  //     icon: <InlineSVG src={SubmitIcon} width={32} height={32} />,
  //     name: "Publish",
  //     onClick: handlePublish
  //   },
  //   // {
  //   //   icon: <InlineSVG src={SaveIcon} width={32} height={32} />,
  //   //   name: "Save",
  //   //   onClick: handleSave,
  //   // },
  //   // {
  //   //   icon: <InlineSVG src={DownloadIcon} width={32} height={32} />,
  //   //   name: "Download Report",
  //   // },
  // ];

  // Snackbar state
  const [successSnack, setSuccessSnack] = useState(false);
  const [snackbarMessage] = useState("");

  // const handleSnackOpen = (message) => {
  //   setSuccessSnack(true);
  //   setSnackbarMessage(message);
  // };

  const handleSuccessSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnack(false);
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#headlineDealsTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb title={titleHeader} titleID="headlineDealsTitle" />

        <div className="my-12 flex flex-col justify-center gap-4 md:flex-row">
          <article className="rounded-2xl bg-white shadow md:basis-6/12 xl:basis-3/12">
            <main className="flex flex-col items-center justify-center py-12">
              <p className="text-6xl font-bold">
                <span className="bg-gradient-to-r from-blue-gradient-start to-blue-gradient-end bg-clip-text text-transparent">
                  {totalDeviceDeals.toLocaleString("en-ZA")}
                </span>
              </p>
              <p className="mt-4 text-2xl leading-normal text-grey-700">
                Device Deals
              </p>
            </main>
            <footer className="border-t border-solid border-[#D9D9D9]">
              <Button
                disableFocusRipple
                className="flex items-center justify-center gap-2 rounded-t-none rounded-b-2xl py-4 text-black hover:text-grey-700"
                LinkComponent={Link}
                to="device-deals/"
              >
                <InlineSVG
                  src={PriceTagIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-red-100"
                />
                <span className="text-base font-bold">View Device Deals</span>
              </Button>
            </footer>
          </article>
          {!isTerminalsUser && (
            <article className="rounded-2xl bg-white shadow md:basis-6/12 xl:basis-3/12">
              <main className="flex flex-col items-center justify-center py-12">
                <p className="text-6xl font-bold">
                  <span className="bg-gradient-to-r from-purple-gradient-start to-purple-gradient-end bg-clip-text text-transparent">
                    {totalSimOnlyDeals.toLocaleString("en-ZA")}
                  </span>
                </p>
                <p className="mt-4 text-2xl leading-normal text-grey-700">
                  SIM Only Deals
                </p>
              </main>
              <footer className="border-t border-solid border-[#D9D9D9]">
                <Button
                  disableFocusRipple
                  className="flex items-center justify-center gap-2 rounded-t-none rounded-b-2xl py-4 text-black hover:text-grey-700"
                  LinkComponent={Link}
                  to="sim-only/"
                  // disabled={true}
                >
                  <InlineSVG
                    src={SimIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-red-100"
                  />
                  <span className="text-base font-bold">
                    View SIM Only Deals
                  </span>
                </Button>
              </footer>
            </article>
          )}
        </div>
      </section>
      {/*       
      <FAB actions={fabActions} /> */}

      <Snackbar
        open={successSnack}
        autoHideDuration={6000}
        onClose={handleSuccessSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: `rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end`,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={TickOutlineCircle}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{snackbarMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleSuccessSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
