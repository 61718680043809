// React Imports
import React, { useEffect, useId, useState } from "react";

// Redux Store Imports
import { useDispatch } from "react-redux";
import { useIMask } from "react-imask";

// MUI Imports
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

// Mask Options
import { maskOptions } from "../headline-deal-info/headline-deal-info-component";

// Component Imports
import InputField from "../input/input-component";
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import InfoIcon from "../../assets/icons/info-icon.svg";
import InfoSolidIcon from "../../assets/icons/info-solid-icon.svg";
import PriceTagIcon from "../../assets/icons/price-tag-icon.svg";
import AccessoryIcon from "../../assets/icons/connected-devices-icon.svg";
import DeviceIcon from "../../assets/icons/device-icon.svg";
import SimIcon from "../../assets/icons/sim-icon.svg";
import RouterIcon from "../../assets/icons/router-icon.svg";
import GiftIcon from "../../assets/icons/gift-icon.svg";
//import PlusIcon from "../../assets/icons/plus-icon.svg";
import DeleteIcon from "../../assets/icons/delete-icon.svg";

// Deal Slices
import { addDealComponent, removeDealComponent } from "../../slices/dealCanvas";

// Mock data
import {
  accessoryOptions,
  deviceOptions,
  routerOptions,
  tariffOptions,
  vasOptions,
} from "../../data/mock-data";

export default function ChildrenDealsCard(props) {
  const {
    id,
    dde_parent_key,
    dealDescription,
    tariffName,
    tariffTerm,
    paymentType,
    multiVAS,
    multiAccessory,
    multiDevice,
    multiTariff,
    multiRouter,
    standardVas,
    dealPrice,
    costToConnect,
    eff,
  } = props.data;

  const {
    deviceID,
    deviceName,
    dealType,
    handleGenericSnackOpen,
    handleDealPriceChange,
    handleDeleteDeal,
  } = props;

  const dispatch = useDispatch();

  // Show/Hide Deal Description
  const [showDealDescription, setShowDealDescription] = useState(false);
  const handleShowDealDescription = () => {
    setShowDealDescription(!showDealDescription);
  };

  // Unique IDs
  const uid = useId();

  // More options for each deal card
  const [anchorEl, setAnchorEl] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);

  // Handle options open event
  // const handleOptionsOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // Handle options close event
  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  // Handle Add Component
  const handleAddComponent = (id, component, dealType, subDealID) => {
    dispatch(
      addDealComponent({
        id: id,
        type: dealType,
        component: component,
        subDealID: subDealID,
      })
    );
    handleOptionsClose();
  };

  // Set Component
  const [componentOption, setComponentOption] = useState("");
  const [componentIndex, setComponentIndex] = useState(null);

  // Handle Remove Component
  const handleRemoveComponent = (id, dealType, component, index, subDealID) => {
    dispatch(
      removeDealComponent({
        id: id,
        type: dealType,
        component: component,
        index: index,
        subDealID: subDealID,
      })
    );
    handleOptionsClose();
    handleGenericSnackOpen("success", "Add-on deleted successfully.");
  };

  // Dialogs
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setRemoveDialogOpen(false);
  };

  // Deal Price Masking
  const { ref: refDealPrice } = useIMask(maskOptions);

  const [headerStyles, setHeaderStyles] = useState("");

  useEffect(() => {
    switch (dealType) {
      case "adhocDeals":
        setHeaderStyles(
          "bg-gradient-to-br from-blue-navy-gradient-start to-blue-navy-gradient-end"
        );
        break;
      default:
        setHeaderStyles(
          "bg-gradient-to-b from-grey-gradient-start to-grey-gradient-end"
        );
        break;
    }
  }, [dealType]);

  return (
    <>
      <article
        className="w-full rounded-2xl shadow"
        aria-label={`${deviceName} on the ${tariffName}`}
      >
        <header className={`rounded-t-2xl px-8 py-4 ${headerStyles}`}>
          <div className="flex items-center justify-between">
            <InlineSVG
              src={PriceTagIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-white"
            />
            <p className="grow text-center text-white">{deviceName}</p>
            <IconButton
              className="fill-white"
              aria-label={`${
                showDealDescription ? "Hide" : "Show"
              } device description`}
              onClick={handleShowDealDescription}
            >
              <InlineSVG
                src={showDealDescription ? InfoSolidIcon : InfoIcon}
                width={24}
                height={24}
                ariaHidden
              />
            </IconButton>
            <IconButton
              className="ml-auto fill-white"
              aria-label="Delete deal"
              onClick={() => handleDeleteDeal(id)}
            >
              <InlineSVG src={DeleteIcon} width={24} height={24} ariaHidden />
            </IconButton>
          </div>

          <Collapse in={showDealDescription}>
            <hr className="my-4 border-white" />
            <p className="text-white">{dealDescription}</p>
          </Collapse>
        </header>
        <section className="flex flex-col gap-4 px-8 pb-6">
          <div className="my-6 flex flex-wrap items-center justify-center gap-4">
            {tariffName && (
              <div className="rounded-full border border-solid border-red-100 bg-red-60/10 px-4 py-2 text-sm font-medium text-red-100">
                {tariffName}
              </div>
            )}
            {tariffTerm && (
              <div className="rounded-full border border-solid border-blue-200 bg-blue-100/10 px-4 py-2 text-sm font-medium text-blue-200">
                {tariffTerm} Month
              </div>
            )}
            {paymentType && (
              <div className="rounded-full border border-solid border-green-200 bg-green-200/10 px-4 py-2 text-sm font-medium text-green-200">
                {paymentType}
              </div>
            )}
          </div>

          {/* VAS */}
          {multiVAS && multiVAS.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ VAS</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiVAS.map((router, index) => (
                <div key={router.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={router.id}
                      defaultValue={router.routerName || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {vasOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete router"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiVAS");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Accessories */}
          {multiAccessory && multiAccessory.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Accessory</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiAccessory.map((accessory, index) => (
                <div key={accessory.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={accessory.id}
                      defaultValue={accessory.accessoryName || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {accessoryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete accessory"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiAccessory");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Devices */}
          {multiDevice && multiDevice.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Device</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiDevice.map((device, index) => (
                <div key={device.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={device.id}
                      defaultValue={device.deviceName || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {deviceOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete device"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiDevice");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Tariffs */}
          {multiTariff && multiTariff.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Tariff</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiTariff.map((tariff, index) => (
                <div key={tariff.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={tariff.id}
                      defaultValue={tariff.tariffName || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {tariffOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete tariff"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiTariff");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* Routers */}
          {multiRouter && multiRouter.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-base font-bold text-red-100">+ Router</p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {multiRouter.map((router, index) => (
                <div key={router.id} className="flex items-center gap-1">
                  <div className="flex-1">
                    <InputField
                      select
                      id={router.id}
                      defaultValue={router.routerName || "placeholder"}
                      SelectProps={{
                        IconComponent: (props) => (
                          <ArrowDropDownRoundedIcon {...props} />
                        ),
                      }}
                    >
                      <MenuItem disabled value="placeholder">
                        - Select -
                      </MenuItem>
                      {routerOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>
                  <IconButton
                    aria-label="Delete router"
                    className="fill-black hover:fill-red-100"
                    onClick={() => {
                      setRemoveDialogOpen(true);
                      setComponentOption("multiRouter");
                      setComponentIndex(index);
                    }}
                  >
                    <InlineSVG
                      src={DeleteIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                  </IconButton>
                </div>
              ))}
            </>
          )}

          {/* <IconButton
            id={`${uid}-more-menu-trigger`}
            aria-label="More deal options"
            aria-controls={moreOptionsOpen ? `${uid}-more-menu` : undefined}
            aria-haspopup="true"
            aria-expanded={moreOptionsOpen ? "true" : undefined}
            onClick={handleOptionsOpen}
            className="fill-white bg-red-100 hover:fill-white hover:bg-red-200 self-center mb-2">
            <InlineSVG src={PlusIcon} width={24} height={24} ariaHidden />
          </IconButton> */}
          <Menu
            id={`${uid}-more-menu`}
            anchorEl={anchorEl}
            open={moreOptionsOpen}
            keepMounted={true}
            disablePortal={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleOptionsClose}
            MenuListProps={{
              "aria-labelledby": `${uid}-more-menu-trigger`,
            }}
          >
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(deviceID, "multiVAS", dealType, id)
              }
            >
              <InlineSVG
                src={GiftIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add VAS
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(deviceID, "multiAccessory", dealType, id)
              }
            >
              <InlineSVG
                src={AccessoryIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add accessory
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(deviceID, "multiDevice", dealType, id)
              }
            >
              <InlineSVG
                src={DeviceIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add device
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(deviceID, "multiTariff", dealType, id)
              }
            >
              <InlineSVG
                src={SimIcon}
                ariaHidden
                width={24}
                height={24}
                className="fill-red-100"
              />
              Add tariff
            </MenuItem>
            <MenuItem
              className="flex items-center gap-x-2"
              disableRipple
              onClick={() =>
                handleAddComponent(deviceID, "multiRouter", dealType, id)
              }
            >
              <InlineSVG
                src={RouterIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-red-100"
              />
              Add router
            </MenuItem>
          </Menu>

          {standardVas && standardVas.length > 0 && (
            <>
              <div className="flex items-center justify-center gap-x-2">
                <hr className="flex-1 border-grey-100" />
                <p className="text-center text-base font-bold text-black">
                  Standard VAS
                </p>
                <hr className="flex-1 border-grey-100" />
              </div>
              {standardVas.map((vas, index) => (
                <div className="flex justify-between gap-4" key={index}>
                  <p className="text-base font-bold text-black">
                    {vas.vasName}
                  </p>
                  <p className="text-base text-grey-500">{vas.vasDetails}</p>
                </div>
              ))}
            </>
          )}

          <div className="flex items-center justify-center gap-x-2">
            <hr className="flex-1 border-grey-100" />
            <p className="text-center text-base font-bold text-black">
              Deal Info.
            </p>
            <hr className="flex-1 border-grey-100" />
          </div>

          <InputField
            inputLabel="Deal price"
            inputRef={refDealPrice}
            placeholder="R 0,00"
            defaultValue={dealPrice || ""}
            onBlur={(e) => {
              handleDealPriceChange(e, dde_parent_key);
            }}
          />

          <div className="mt-2 flex justify-between gap-4">
            <div className="text-left">
              <p className="text-sm text-black">Cost to Connect:</p>
              <p className="text-xl font-bold text-red-100">
                {costToConnect.toLocaleString("en-ZA", {
                  style: "currency",
                  currency: "ZAR",
                })}
              </p>
            </div>
            <div className="text-right">
              <p className="text-sm text-black">Efficiency</p>
              <p className="bg-gradient-to-br from-blue-gradient-start to-blue-gradient-end bg-clip-text text-xl font-bold text-transparent">
                {eff}%
              </p>
            </div>
          </div>
        </section>
      </article>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={removeDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "remove-dialog-title"}
      >
        <DialogTitle id={uid + "remove-dialog-title"}>
          Remove this add-on?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to proceed with permanently removing this
            add-on?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleRemoveComponent(
                deviceID,
                dealType,
                componentOption,
                componentIndex,
                id
              );
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
