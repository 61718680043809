// React and Mui Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";

// Component Imports
import InputField from "../../components/input/input-component";
import TitleBreadcrumb from "../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../components/back-button/back-button-component";
import CustomCheckbox from "../../components/checkbox/checkbox-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";

// Mock Data Import
import { statusTypes } from "../../data/mock-data";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ClockIcon from "../../assets/icons/clock-icon.svg";
import CloseCircleIcon from "../../assets/icons/close-circle-icon.svg";
import ErrorCircleIcon from "../../assets/icons/error-circle-icon.svg";
import SettingsIcon from "../../assets/icons/settings-icon.svg";
import TrickCircleIcon from "../../assets/icons/tick-circle-icon.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
import JobService from "../../services/job.service";
import AuthService from "../../services/auth.service";

// Status Icon Map
const statusIcons = {
  Queued: { icon: ClockIcon, color: "fill-grey-200" },
  "In Progress": { icon: SettingsIcon, color: "fill-blue-200" },
  Completed: { icon: TrickCircleIcon, color: "fill-green-100" },
  Cancelled: { icon: CloseCircleIcon, color: "fill-yellow-200" },
  Failed: { icon: ErrorCircleIcon, color: "fill-red-100" },
};

// Jobs Table Columns
const jobsColumns = [
  {
    field: "id",
    headerName: "ID",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
    renderCell: (params) => {
      // If a job can be cancelled, the job can't be viewed (as per requirements)
      if (params.row.canCancel) {
        return params.value;
      } else {
        return (
          <Link
            className="text-blue-200 underline focus-visible:outline-none hover:text-blue-300"
            to={`# `}
          >
            {params.value}
          </Link>
        );
      }
    },
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "text-base font-medium text-black",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "type",
    headerName: "Type",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 150,
    renderCell: (params) => {
      const status = statusIcons[params.row.status];
      return (
        <div className="flex items-center gap-x-2">
          <InlineSVG
            src={status.icon}
            width={22}
            height={22}
            ariaHidden
            className={status.color}
          />
          <Typography noWrap>{params.row.status}</Typography>
        </div>
      );
    },
  },
  {
    field: "queued",
    headerName: "Queued",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 150,
    renderCell: (params) => {
      if (params.row.queued !== "") {
        return (
          <Tooltip title={params.row.queued} placement="top">
            <Typography noWrap>{params.row.queued}</Typography>
          </Tooltip>
        );
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    field: "started",
    headerName: "Started",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 150,
    renderCell: (params) => {
      if (params.row.started !== "") {
        return (
          <Tooltip title={params.row.started} placement="top">
            <Typography noWrap>{params.row.started}</Typography>
          </Tooltip>
        );
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    field: "ended",
    headerName: "Ended",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 150,
    renderCell: (params) => {
      if (params.row.ended !== "") {
        return (
          <Tooltip title={params.row.ended} placement="top">
            <Typography noWrap>{params.row.ended}</Typography>
          </Tooltip>
        );
      } else {
        return <span>-</span>;
      }
    },
  },
  {
    field: "changedBy",
    headerName: "Changed by",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 100,
  },
];

export default function Jobs() {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState(10);

  const [jobsData, setJobsData] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [jobId, setJobId] = useState(0);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  const handleCancelJob = () => {
    JobService.cancelJob(jobId)
      .then((response) => {
        const { data } = response;
        setFilteredRows(
          filteredRows.map((item) => {
            if (item.id === jobId) {
              return data;
            }
            return item;
          })
        );
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    JobService.getAllJobs()
      .then((response) => {
        const { data } = response;
        setJobsData(data);
        setFilteredRows(data);
        setJobTypes([
          ...new Map(
            data.map((item) => [
              item["type"],
              { label: item.type, value: item.type },
            ])
          ).values(),
        ]);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jobsTable = {
    rows: filteredRows,
    columns: [
      ...jobsColumns,
      {
        field: "actions",
        type: "actions",
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            disabled={!params.row.canCancel}
            icon={
              <CancelRoundedIcon
                className={`text-[28px] ${
                  params.row.canCancel ? "text-red-100" : "text-grey-100"
                }`}
              />
            }
            onClick={() => {
              setJobId(params.id);
              setDialogOpen(true);
            }}
            label={params.row.canCancel ? "Cancel job" : "Can't cancel job"}
          />,
        ],
      },
    ],
    pageSize: pageSize,
    rowsPerPageOptions: [10, 20, 30, 40, 50],
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
    disableSelectionOnClick: true,
    autoHeight: true,
    components: {
      NoRowsOverlay: () => {
        return (
          <div className="mt-10 text-center">
            <Typography>No jobs found...</Typography>
          </div>
        );
      },
    },
  };

  // DataGrid Table Search
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);

  useEffect(() => {
    let filteredData = jobsData;

    // Filter rows by search text
    if (searchText) {
      filteredData = jobsData.filter((row) => {
        return (
          Object.values(row.name)
            .join("")
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter rows by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((row) => {
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (
            Object.values(row)
              .join("")
              .toLowerCase()
              .includes(option.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredRows(filteredData);
  }, [searchText, filterBy, jobsData]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#jobsTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb title="Jobs" titleID="jobsTitle" />

        <div className="mt-8 mb-4">
          <div className="grid grid-cols-10 gap-x-4">
            <div className="col-span-10 sm:col-span-5 md:col-span-6 lg:col-span-7 xl:col-span-8">
              <InputField
                inputLabel="Search"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search by job name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-10 col-end-10 mt-6 sm:col-span-5 sm:mt-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={filterBy}
                onChange={handleFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                <ListSubheader className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black">
                  Type
                </ListSubheader>
                {jobTypes.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    <CustomCheckbox
                      checked={filterBy.indexOf(option.label) > -1}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
                <ListSubheader className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black">
                  Status
                </ListSubheader>
                {statusTypes.map((option) => (
                  <MenuItem key={option.value} value={option.label}>
                    <CustomCheckbox
                      checked={filterBy.indexOf(option.label) > -1}
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </InputField>
            </div>
          </div>
        </div>
        <DataGrid
          {...jobsTable}
          className="mb-12 rounded-2xl border-0 bg-white px-4 pt-8 pb-4 text-base text-black shadow sm:px-6 md:px-10"
        />

        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="cancel-job-dialog-title"
        >
          <DialogTitle id="cancel-job-dialog-title">
            Cancel this job?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to cancel this job. This action cannot be undone.
              Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableRipple
              color="secondary"
              onClick={handleDialogClose}
              disableFocusRipple
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              autoFocus
              disableFocusRipple
              onClick={() => {
                handleCancelJob();
                handleDialogClose();
              }}
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
}
