import { TextField, InputLabel, FormGroup, FormControl } from "@mui/material";
import { useId } from "react";

export default function InputField({ inputLabel = null, warning = false, ...props }) {
  const uid = useId();

  return (
    <FormGroup>
      {inputLabel !== null && (
        <InputLabel
          className="mb-2 text-base font-medium text-black"
          htmlFor={uid}
        >
          {inputLabel}
        </InputLabel>
      )}
      <FormControl fullWidth={true}>
        <TextField
          {...props}
          id={uid}
          inputProps={{
            ...props.inputProps,
            className: `
              bg-white placeholder:text-grey-300 placeholder:opacity-100 
              ${props.multiline ? "py-0 px-2" : "py-3 px-4"} 
              ${warning ? "warning" : ""}
            `,
          }}
          FormHelperTextProps={{
            className: "text-xs mx-0",
          }}
        ></TextField>
      </FormControl>
    </FormGroup>
  );
}
