// React Imports
import { useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";
import { useIMask } from "react-imask";
import { Link, useParams, useNavigate } from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";

import {
  getRangesData,
  getTariffData,
  getTariffRanges,
  getTariffs,
  // addTariff,
  removeData,
  updateTariffData,
  updateReviewState,
  // updateTariffRanges,
} from "../../../slices/cycleConfigCanvas";
import CycleConfigService from "../../../services/cycleConfig.service";
import AuthService from "../../../services/auth.service";

// MUI Imports
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";
import TabPanel from "../../../components/tab-panel/tab-panel-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import PencilIcon from "../../../assets/icons/pencil-icon.svg";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
// import PlusIcon from "../../../assets/icons/plus-icon.svg";

// Tariff Sorting Options
const sortingOptions = [
  {
    heading: "Description",
    options: [
      {
        value: "name_asc",
        label: "A - Z",
      },
      {
        value: "name_desc",
        label: "Z - A",
      },
    ],
  },
];

// Tariff Filter Options
const filterByOptions = [
  {
    heading: "Category",
    options: [
      {
        value: "Red Flexi",
        label: "Red Flexi",
      },
      {
        value: "RED",
        label: "RED",
      },
      {
        value: "Home Internet",
        label: "Home Internet",
      },
      {
        value: "Data Price plan",
        label: "Data Price plan",
      },
    ],
  },
  {
    heading: "Type",
    options: [
      {
        value: "V",
        label: "Voice",
      },
      {
        value: "D",
        label: "Data",
      },
    ],
  },
  {
    heading: "Tariff Sub Type",
    options: [
      {
        value: "MBB",
        label: "Mobile Broadband",
      },
      {
        value: "FWA",
        label: "Fixed Wireless",
      },
    ],
  },
];

// Tariff Table Head Cells
const tariffTableHead = [
  {
    id: "tariff_code",
    label: "Tariff Code",
  },
  {
    id: "tariff_sub",
    label: "Tariff Sub.",
  },
  {
    id: "sim_only_sub",
    label: "SIM-Only Sub.",
  },
  {
    id: "tariff_differential",
    label: "Tariff Differential",
  },
  {
    id: "ranking",
    label: "Rank",
  },
  {
    id: "tariff_discount",
    label: "Tariff Discount",
  },
  {
    id: "tariff_cib",
    label: "Tariff CIB",
  },
  {
    id: "tariff_sub_type",
    label: "Tariff Sub Type",
  },
  {
    id: "tariff_type",
    label: "Tariff Type",
  },
];

// Ranges Table Head Cells
const rangesTableHead = [
  {
    id: "tariff_description",
    label: "Tariff Name",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "tariff_type",
    label: "Tariff Type",
  },
  {
    id: "tariff_sub_type",
    label: "Tariff Sub. Type",
  },
  {
    id: "term",
    label: "Term",
  },
  {
    id: "range_lower",
    label: "Range Lower",
  },
  {
    id: "range_upper",
    label: "Range Upper",
  },
];

// Ranges Tariff Types
const tariffType = [
  {
    value: "D",
    label: "Data",
  },
  {
    value: "V",
    label: "Voice",
  },
];

// Ranges Tariff Terms
const tariffTerms = [
  {
    value: "6",
    label: "6 Months",
  },
  {
    value: "12",
    label: "12 Months",
  },
  {
    value: "24",
    label: "24 Months",
  },
  {
    value: "36",
    label: "36 Months",
  },
];

// Ranges Tariff Sub Types
const tariffSubType = [
  {
    value: null,
    label: "None",
  },
  {
    value: "MBB",
    label: "MBB",
  },
  {
    value: "FWA_LTE_UNCAPPED",
    label: "FWA_LTE_UNCAPPED",
  },
  {
    value: "FWA_5G_UNCAPPED",
    label: "FWA_5G_UNCAPPED",
  },
  {
    value: "FWA_LTE_CAPPED",
    label: "FWA_LTE_CAPPED",
  },
  {
    value: "FWA",
    label: "FWA",
  },
];

export default function TariffTabContent({
  handleSnackOpen,
  setCancelWarningDialog,
  inReview,
  reviewFeedback,
}) {
  const navigate = useNavigate();

  // Created separate component to ensure masking for each map item.
  function RangeInputs({ defaultValue, rangeId, term, name }) {
    const uid = useId();

    // Number masking state
    const maskOptions = {
      mask: "R num",
      blocks: {
        num: {
          mask: Number,
          scale: 2,
          thousandsSeparator: ",",
          padFractionalZeros: true,
          normalizeZeros: true,
          radix: ".",
        },
      },
    };
    const { ref: inputRef } = useIMask(maskOptions);

    // const handleRangeChange = (event) => {
    //   const { name, value } = event.target;

    //   const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));

    //   const updatedData = filteredRangeData.map((range) => {
    //     const initTerm = (range.initTerm || []).concat(range.term);

    //     if (range.id === rangeId && range.term === term) {
    //       return {
    //         ...range,
    //         [name]: numericValue,
    //         isChanged: true,
    //         initTerm: initTerm,
    //       };
    //     }
    //     return range;
    //   });

    //   setFilteredRangeData(updatedData);
    // };

    return (
      <InputField
        id={uid + "range-lower"}
        placeholder="R 0.00"
        inputRef={inputRef}
        defaultValue={defaultValue}
        // onBlur={(e) => handleRangeChange(e)}
        name={name}
        inputProps={{
          readOnly: true,
        }}
      />
    );
  }

  //const navigate = useNavigate();
  const { cycle } = useParams();

  // Redux dispatch hook
  const dispatch = useDispatch();

  // Form Submission hook from react-hook-form
  const { handleSubmit } = useForm();

  useEffect(() => {
    dispatch(getTariffs({ cycle }));
    dispatch(getTariffRanges({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set DATA sources
  const tariffData = useSelector(getTariffData);
  const rangesData = useSelector(getRangesData);

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleSnackOpen("error", errors[0].message);
    }
  };

  // Function to update changes
  const handleInputChange = (event, tariffId) => {
    const { name, value } = event.target;

    dispatch(
      updateTariffData({
        data: {
          name,
          value,
          tariffId,
        },
        object: "tariffData",
      })
    );
  };

  // On Submit function to trigger tab change and success snackbar popup
  const onSubmit = () => {
    let newTariff = filteredTariffData.filter(
      (tariff) => tariff.newItem && tariff.isChanged
    );

    const editedTariffsData = filteredTariffData.filter(
      (tariff) =>
        tariff.isChanged && tariff.ranking !== null && tariff.newItem !== true
    );

    // Filter only the edited routers
    let rangesData = filteredRangeData.filter((range) => range.isChanged);

    // Update Tariff Data
    if (editedTariffsData.length > 0) {
      CycleConfigService.updateTariffs(cycle, editedTariffsData)
        .then((response) => {
          const { message } = response.data;
          handleSnackOpen("success", message);
        })
        .catch((error) => {
          console.log(error);
          handleServerError(error);
        });
    }

    // Add New Tariff Data
    if (newTariff.length > 0) {
      CycleConfigService.addTariff(cycle, newTariff)
        .then((response) => {
          const { message } = response.data;
          handleSnackOpen("success", message);
        })
        .catch((error) => {
          console.log(error);
          handleServerError(error);
        });
    }

    // Update Tariff Ranges Data
    if (rangesData.length > 0) {
      CycleConfigService.updateTariffRanges(cycle, rangesData)
        .then((response) => {
          const { message } = response.data;
          handleSnackOpen("success", message);
        })
        .catch((error) => {
          console.log(error);
          handleServerError(error);
        });
    }

    // Shows pop-up message if no changes made
    if (
      editedTariffsData.length === 0 &&
      newTariff.length === 0 &&
      rangesData.length === 0
    ) {
      handleSnackOpen("notFound", "No changes made");
    }
  };

  // // Handle Changes on the Dropdowns
  // const handleChange = (event, rangeId, term) => {
  //   const { name, value } = event.target;

  // dispatch(
  //   updateTariffRanges({
  //     data: {
  //       name,
  //       value,
  //       rangeId,
  //       term,
  //     },
  //     object: "rangesData",
  //   })
  // );

  // Handle edit tariff name
  const enableTariffNameEdit = (index) => {
    const updatedEditStates = [...editTariffName];
    updatedEditStates[index] = true;
    setEditTariffName(updatedEditStates);
  };

  // // Handle add new tariff
  // const handleAddNewTariff = () => {
  //   const rowsLen = filteredTariffData.length;

  //   if (rowsLen > 0) {
  //     // Set rows per page
  //     const rows = Math.ceil(rowsLen / 10) * 10;
  //     const pg = [];
  //     for (let i = 0; i <= rowsLen; i++) {
  //       if (i > 0 && i <= 5) {
  //         pg.push(i * 10);
  //       }
  //     }

  //     setRowsPerPageOptionsT([...pg, { label: "All", value: rows }]);

  //     if (rowsLen > 0 && rows <= 50) {
  //       setRowsPerPageT(rows);
  //     } else if (rowsLen > 0 && rows > 50) {
  //       setRowsPerPageT(rows);
  //     }

  //     setPage(Math.ceil(filteredTariffData.length / rowsPerPageT) - 1);
  //   }
  //   const newData = {
  //     plan: "",
  //     ranking: "",
  //     type: "- Select -",
  //     simOnlySub: "",
  //     tariffCib: "",
  //     tariffCode: "",
  //     tariffDifferential: "",
  //     tariffDiscount: "",
  //     tariffSub: "",
  //     tariffSubType: "",
  //   };

  //   dispatch(addTariff({ data: newData, object: "tariffData" }));
  // };

  // State of id of item being removed.
  const [removalID, setRemovalID] = useState(null);

  // Handle open remove warning dialog
  const handleOpenRemoveWarningDialog = (id) => {
    setRemoveWarningDialog(true);
    setRemovalID(id);
  };

  // Handle remove existing tariff
  const handleRemoveTariff = (id) => {
    CycleConfigService.deleteTariff(cycle, id)
      .then((response) => {
        const { message } = response.data;

        dispatch(removeData({ id: id, object: "tariffData" }));

        handleSnackOpen("success", message);
      })
      .catch((error) => {
        console.log(error);
        handleServerError(error);
      });
  };

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPageT, setRowsPerPageT] = useState(-1);
  const [rowsPerPageR, setRowsPerPageR] = useState(-1);
  const [rowsPerPageOptionsT, setRowsPerPageOptionsT] = useState([]);
  const [rowsPerPageOptionsR, setRowsPerPageOptionsR] = useState([]);

  // Handle change table page
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Handle change table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageT(parseInt(event.target.value, 10));
    setRowsPerPageR(parseInt(event.target.value, 10));
    setPage(0);
  };

  // States
  const [tariffSearchText, setTariffSearchText] = useState("");
  const [tariffFilterBy, setTariffFilterBy] = useState([]);
  const [tariffSortOrder, setTariffSortOrder] = useState("");
  const [filteredTariffData, setFilteredTariffData] = useState(tariffData);
  const [editTariffName, setEditTariffName] = useState(
    tariffData.map(() => false)
  ); // State for editing tariff name

  // States
  const [rangeSearchText, setRangeSearchText] = useState("");
  const [rangeFilterBy, setRangeFilterBy] = useState([]);
  const [rangeSortOrder, setRangeSortOrder] = useState("");
  const [filteredRangeData, setFilteredRangeData] = useState(rangesData);

  // Update Tariff data as it changes
  useEffect(() => {
    // Tariff Search and Filtering
    let filteredTariffData = tariffData;

    // Filter tariffs by search text
    if (tariffSearchText) {
      filteredTariffData = tariffData.filter((tariff) => {
        // Join all values of the tariff object and its specs object into a string
        const tariffValues = Object.values(tariff.plan)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          tariffValues.toLowerCase().indexOf(tariffSearchText.toLowerCase()) !==
            -1 || tariff.plan === ""
        );
      });
    }

    // Filter tariffs by selected filters
    if (tariffFilterBy.length > 0) {
      filteredTariffData = filteredTariffData.filter((tariff) => {
        // Extract plan and type properties
        const { plan, type, tariffSubType } = tariff;

        // Combine plan and type properties into an array
        const tariffValues = [plan, type, tariffSubType];

        // Iterate over tariffFilterBy options and check if any row values contain the option
        for (let option of tariffFilterBy) {
          if (
            tariffValues[0].includes(option) ||
            (tariffValues[1] === "D" ? "Data" : "Voice") === option ||
            (tariffValues[2] === "MBB"
              ? "Mobile Broadband"
              : "Fixed Wireless") === option
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredTariffData(filteredTariffData);

    // Set rows per page
    const rowsLen = filteredTariffData.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptionsT([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPageT(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPageT(rows);
    }
  }, [tariffSearchText, tariffFilterBy, tariffData]);

  // Update data as it changes
  useEffect(() => {
    // Ranges Search and Filtering
    let filteredRangeData = rangesData;

    // Filter tariffs by search text
    if (rangeSearchText) {
      filteredRangeData = rangesData.filter((range) => {
        // Join all values of the range object and its specs object into a string
        const rangeValues = Object.values(range)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          rangeValues.toLowerCase().indexOf(rangeSearchText.toLowerCase()) !==
            -1 || range.plan === ""
        );
      });
    }

    // Filter tariffs by selected filters
    if (rangeFilterBy.length > 0) {
      filteredRangeData = filteredRangeData.filter((range) => {
        // Extract plan and type properties
        const { tariffName, tariffType, tariffSubType } = range;

        // Combine plan and type properties into an array
        const rangeValues = [tariffName, tariffType, tariffSubType];

        // Iterate over rangeFilterBy options and check if any row values contain the option
        for (let option of rangeFilterBy) {
          if (
            rangeValues[0].includes(option) ||
            (rangeValues[1] === "D" ? "Data" : "Voice") === option ||
            (rangeValues[2] === "MBB"
              ? "Mobile Broadband"
              : "Fixed Wireless") === option
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredRangeData(filteredRangeData);

    // Set rows per page
    const rowsLen = filteredRangeData.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptionsR([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPageR(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPageR(rows);
    }
  }, [rangeSearchText, rangeFilterBy, rangesData]);

  // Handle search filter via search input field
  const handleTariffSearch = (event) => {
    const { value } = event.target;
    setTariffSearchText(value);
  };
  const handleRangeSearch = (event) => {
    const { value } = event.target;
    setRangeSearchText(value);
  };

  // Handle filter by option change
  const handleTariffFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setTariffFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];

    setTariffFilterBy(filteredOptions);
  };
  const handleRangeFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setRangeFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setRangeFilterBy(filteredOptions);
  };

  // Handle sorting order change
  const handleTariffSort = (event) => {
    setTariffSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "name_asc":
        sortedData = [...filteredTariffData].sort((a, b) =>
          a.plan.localeCompare(b.plan)
        );
        break;
      case "name_desc":
        sortedData = [...filteredTariffData].sort((a, b) =>
          b.plan.localeCompare(a.plan)
        );
        break;
      default:
        sortedData = filteredTariffData;
        break;
    }

    setFilteredTariffData(sortedData);
  };
  const handleRangeSort = (event) => {
    setRangeSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = [...filteredRangeData].sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        break;
      case "desc":
        sortedData = [...filteredRangeData].sort((a, b) =>
          b.description.localeCompare(a.description)
        );
        break;
      default:
        sortedData = filteredRangeData;
        break;
    }

    setFilteredRangeData(sortedData);
  };

  // Dialogs (Modals)
  const [removeWarningDialog, setRemoveWarningDialog] = useState(false);
  const [unresolvedWarningDialog, setUnresolvedWarningDialog] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Category selection states and function
  const [category, setCategory] = useState(0);
  const handleCategoryChange = (_, category) => {
    setCategory(category);
  };

  // Handle dialog Open for Unresolved feedback warning
  const handleUnresolvedDialogOpen = () => {
    setUnresolvedWarningDialog(true);
  };

  // Alert state
  const [showAlert, setShowAlert] = useState(inReview === "R" ? true : false);

  // handle resolve feedback
  const handleResolveFeedback = () => {
    dispatch(
      updateReviewState({
        data: {
          config_status: "P",
          reviewed: "N",
          reason_rejected: null,
          config_set: "TARIFFS",
          cycle: cycle,
        },
        object: "cycleReviewsData",
      })
    );
    setShowAlert(false);
    handleSnackOpen("success", "Tariffs feedback has been resolved.");
  };

  // handle form submission with warning feedback if unresolved feedback
  const handleFormSubmission = (e) => {
    e.preventDefault();
    (inReview === "R" ? true : false)
      ? handleUnresolvedDialogOpen()
      : handleSubmit(onSubmit)();
  };

  return (
    <>
      <Collapse in={showAlert}>
        <Alert
          className="mb-8 items-center gap-4 rounded-lg p-6"
          severity="warning"
          iconMapping={{
            warning: (
              <InlineSVG src={WarningIcon} ariaHidden width={24} height={24} />
            ),
          }}
          action={
            <IconButton
              aria-label="Close Alert"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <h2 className="mb-1 text-lg font-bold">Rejected</h2>
          <p className="mb-6">{reviewFeedback}</p>
          <Link onClick={handleResolveFeedback} className="underline">
            Resolve
          </Link>
        </Alert>
      </Collapse>

      <div className="text-center">
        <ToggleButtonGroup
          value={category}
          exclusive
          onChange={handleCategoryChange}
          aria-label="Category selection"
        >
          <ToggleButton value={0}>Tariffs</ToggleButton>
          <ToggleButton value={1}>Ranges</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TabPanel value={category} index={0}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={tariffSearchText}
                onChange={handleTariffSearch}
                placeholder="Search by tariff name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: tariffSortOrder || "",
                  onChange: handleTariffSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((group) => [
                  <ListSubheader
                    key={group.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {group.heading}
                  </ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className="px-6"
                    >
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={tariffFilterBy}
                onChange={handleTariffFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={tariffFilterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        <form onSubmit={handleFormSubmission}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="border-0 py-0 px-2"
                  width={200}
                ></TableCell>
                {tariffTableHead.map((headCell) => (
                  <TableCell
                    className="border-0 py-0 px-2 text-base"
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
                <TableCell
                  className="border-0 py-0 px-2"
                  width={40}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTariffData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={tariffTableHead.length + 2}
                    className="border-0 py-8 text-center text-base text-black"
                  >
                    No tariffs found...
                  </TableCell>
                </TableRow>
              ) : (
                filteredTariffData
                  .slice(
                    page * rowsPerPageT,
                    page * rowsPerPageT + rowsPerPageT
                  )
                  .map((tariff, index) => (
                    <TableRow key={tariff.id + index}>
                      <TableCell className="border-0 p-2 pl-0 text-base">
                        {editTariffName[index] || tariff.newItem ? (
                          <InputField
                            defaultValue={tariff.plan}
                            placeholder="Tariff Name"
                            name="plan"
                            onChange={(e) => handleInputChange(e, tariff.id)}
                          ></InputField>
                        ) : (
                          <div className="flex items-center gap-1">
                            <IconButton
                              aria-label="Delete tariff"
                              onClick={() => enableTariffNameEdit(index)}
                              name="plan"
                            >
                              <InlineSVG
                                src={PencilIcon}
                                width={24}
                                height={24}
                                ariaHidden
                                className="fill-red-100"
                              />
                            </IconButton>
                            <p className="font-bold">{tariff.plan}</p>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.tariffCode}
                          placeholder="Tariff Code"
                          name="tariffCode"
                          inputProps={{
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                          }}
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.tariffSub}
                          inputProps={{
                            inputMode: "numeric",
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                          }}
                          name="tariffSub"
                          placeholder="Tariff Sub."
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.simOnlySub}
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          name="simOnlySub"
                          placeholder="SIM-Only Sub."
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.tariffDifferential}
                          inputProps={{
                            inputMode: "numeric",
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                          }}
                          name="tariffDifferential"
                          placeholder="Tariff Differential"
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.ranking}
                          placeholder="Rank"
                          inputProps={{
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                            inputMode: "numeric",
                          }}
                          name="ranking"
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.tariffDiscount}
                          placeholder="Tariff Discount"
                          inputProps={{
                            inputMode: "numeric",
                          }}
                          name="tariffDiscount"
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          defaultValue={tariff.tariffCib}
                          placeholder="Tariff CIB"
                          inputProps={{
                            inputMode: "numeric",
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                          }}
                          name="tariffCib"
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        ></InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          id={tariff.id}
                          select={
                            tariff.newItem && tariff.newItem === true
                              ? true
                              : false
                          }
                          name="tariffSubType"
                          value={tariff.tariffSubType || "- Select -"}
                          inputProps={{
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                          }}
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        >
                          <MenuItem disabled value="- Select -">
                            - Select -
                          </MenuItem>
                          {tariff.newItem && tariff.newItem === true
                            ? tariffSubType.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            : null}
                        </InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <InputField
                          id={tariff.id}
                          select={
                            tariff.newItem && tariff.newItem === true
                              ? true
                              : false
                          }
                          name="type"
                          value={
                            tariff.newItem && tariff.newItem === true
                              ? tariff.type
                              : (tariff.type === "V" ? "Voice" : "Data") ||
                                "- Select -"
                          }
                          inputProps={{
                            readOnly:
                              tariff.newItem && tariff.newItem === true
                                ? false
                                : true,
                          }}
                          onChange={(e) => handleInputChange(e, tariff.id)}
                        >
                          <MenuItem disabled value="- Select -">
                            - Select -
                          </MenuItem>
                          {tariff.newItem && tariff.newItem === true
                            ? tariffType.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))
                            : null}
                        </InputField>
                      </TableCell>
                      <TableCell className="border-0 p-2">
                        <IconButton
                          aria-label="Delete tariff"
                          onClick={() =>
                            handleOpenRemoveWarningDialog(tariff.id)
                          }
                        >
                          <InlineSVG
                            src={DeleteIcon}
                            width={24}
                            height={24}
                            ariaHidden
                            className="fill-yellow-200"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                {/* <TableCell
                  colSpan={tariffTableHead.length + 2}
                  className="border-0 pb-0 text-center"
                >
                  <Button
                    variant="text"
                    disableFocusRipple
                    className="gap-2 fill-red-100 text-red-100 hover:fill-red-200 hover:text-red-200"
                    onClick={() => handleAddNewTariff()}
                  >
                    <InlineSVG
                      src={PlusIcon}
                      width={24}
                      height={24}
                      ariaHidden
                    />
                    Add new tariff
                  </Button>
                </TableCell> */}
              </TableRow>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptionsT}
                  count={filteredTariffData.length}
                  rowsPerPage={rowsPerPageT}
                  page={page}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <div className="mb-12 flex justify-center gap-2 md:justify-end">
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={() => setCancelWarningDialog(true)}
            >
              Cancel
            </Button>
            <Button type="submit" disableFocusRipple variant="contained">
              Save
            </Button>
          </div>
        </form>
      </TabPanel>

      <TabPanel value={category} index={1}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={rangeSearchText}
                onChange={handleRangeSearch}
                placeholder="Search by tariff name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: rangeSortOrder || "",
                  onChange: handleRangeSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={rangeFilterBy}
                onChange={handleRangeFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={rangeFilterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        <form onSubmit={handleFormSubmission}>
          <Table>
            <TableHead>
              <TableRow>
                {rangesTableHead.map((headCell) => (
                  <TableCell
                    className="border-0 py-0 px-2 text-base first:pl-0"
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRangeData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={rangesTableHead.length}
                    className="border-0 py-8 text-center text-base text-black"
                  >
                    No tariffs found...
                  </TableCell>
                </TableRow>
              ) : (
                filteredRangeData
                  .slice(
                    page * rowsPerPageR,
                    page * rowsPerPageR + rowsPerPageR
                  )
                  .map((range, index) => (
                    <TableRow key={range.id + index}>
                      <TableCell className="w-[10%] border-0 p-2 pl-0 text-base">
                        {range.tariffName}
                      </TableCell>
                      <TableCell className="w-[15%] border-0 p-2">
                        <InputField
                          defaultValue={range.description}
                          placeholder="Description"
                          name="description"
                          inputProps={{
                            readOnly: true,
                          }}
                        ></InputField>
                      </TableCell>
                      <TableCell className="w-[15%] border-0 p-2">
                        <InputField
                          // select
                          id={range.id + "tariff-type"}
                          name="tariffType"
                          value={
                            range.tariffType === "V"
                              ? "Voice"
                              : "Data" || "placeholder"
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                          // onChange={(e) =>
                          //   handleChange(e, range.id, range.term)
                          // }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {tariffType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[15%] border-0 p-2">
                        <InputField
                          // select
                          id={range.id + "tariff-sub-type"}
                          name="tariffSubType"
                          value={range.tariffSubType || "- Select -"}
                          inputProps={{
                            readOnly: true,
                          }}
                          // onChange={(e) =>
                          //   handleChange(e, range.id, range.term)
                          // }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {tariffSubType.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[15%] border-0 p-2">
                        <InputField
                          // select
                          id={range.id + "term"}
                          name="term"
                          value={`${range.term} Months` || "placeholder"}
                          inputProps={{
                            readOnly: true,
                          }}
                          // onChange={(e) =>
                          //   handleChange(e, range.id, range.term)
                          // }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {tariffTerms.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[15%] border-0 p-2">
                        <RangeInputs
                          defaultValue={range.rangeLower}
                          rangeId={range.id}
                          term={range.term}
                          name="rangeLower"
                        />
                      </TableCell>
                      <TableCell className="w-[15%] border-0 p-2">
                        <RangeInputs
                          defaultValue={range.rangeUpper}
                          rangeId={range.id}
                          term={range.term}
                          name="rangeUpper"
                        />
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptionsR}
                  count={filteredRangeData.length}
                  rowsPerPage={rowsPerPageR}
                  page={page}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <div className="mb-12 flex justify-center gap-2 md:justify-end">
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              disabled
              onClick={() => setCancelWarningDialog(true)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disableFocusRipple
              variant="contained"
              disabled
            >
              Save & Continue
            </Button>
          </div>
        </form>
      </TabPanel>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={removeWarningDialog}
        onClose={() => setRemoveWarningDialog(false)}
        aria-labelledby="remove-tariff-warning-title"
      >
        <DialogTitle id="remove-tariff-warning-title">
          Remove tariff?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to remove this tariff, this change cannot be undone.
            Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setRemoveWarningDialog(false)}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              setRemoveWarningDialog(false);
              handleRemoveTariff(removalID);
              handleSnackOpen(
                "success",
                "Tariff has been successfully removed."
              );
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={unresolvedWarningDialog}
        onClose={() => setUnresolvedWarningDialog(false)}
        aria-labelledby="unresolved-warning-title"
      >
        <DialogTitle id="unresolved-warning-title" className="leading-tight">
          Continue with unresolved feedback?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to save and continue without resolving the review
            feedback. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setUnresolvedWarningDialog(false)}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleSubmit(onSubmit)();
              setUnresolvedWarningDialog(false);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
