// React Imports
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

DealCycleTotals.propTypes = {
  totalCostToConnect: PropTypes.string.isRequired,
  totalReducedSubscription: PropTypes.string.isRequired,
  totalFinanced: PropTypes.string.isRequired,
  totalEfficiency: PropTypes.string.isRequired,
  totalIntensity: PropTypes.string.isRequired
};

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default function DealCycleTotals(props) {
  const {
    totalCostToConnect,
    totalReducedSubscription,
    totalFinanced,
    totalEfficiency,
    totalIntensity
  } = props;

  // Element that becomes sticky on scroll
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef(null);

  // Handle scroll event for detecting stickiness
  const handleScroll = () => {
    if (stickyRef.current) {
      setIsSticky(stickyRef.current.getBoundingClientRect().top <= 96);
    }
  };

  // Listen for window scrolling to trigger scroll event for detecting stickiness
  useEffect(() => {
    window.addEventListener("scroll", debounce(handleScroll, 10));
    return () => {
      window.removeEventListener("scroll", () => debounce(handleScroll, 10));
    };
  }, []);

  return (
    <div
      className={`top-24 z-10 rounded transition-[background-color] duration-300 ease-in-out lg:sticky ${
        isSticky && "bg-white/90 shadow backdrop-blur overflow-anchor-none"
      }`}
      ref={stickyRef}
    >
      <div
        className={`grid grid-cols-10 transition-all duration-300 ease-in-out ${
          isSticky ? "gap-0" : "mb-6 gap-4"
        }`}
      >
        <div
          className={`col-span-5 px-4 transition-[padding] duration-300 ease-in-out lg:col-span-2 ${
            isSticky ? "py-6" : "rounded-2xl bg-white py-12 shadow"
          }`}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <span
              className={`bg-gradient-to-r from-purple-100 to-red-100 bg-clip-text text-transparent transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-lg" : "text-xl font-bold"
              }`}
            >
              {totalCostToConnect}
            </span>
            <p
              className={`text-black transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-xs font-bold" : "text-base"
              }`}
            >
              Total Cost to Connect
            </p>
          </div>
        </div>

        <div
          className={`col-span-5 px-4 transition-[padding] duration-300 ease-in-out lg:col-span-2 ${
            isSticky ? "py-6" : "rounded-2xl bg-white py-12 shadow"
          }`}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <span
              className={`bg-gradient-to-r from-red-gradient-start to-red-gradient-end bg-clip-text text-transparent transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-lg" : "text-xl font-bold"
              }`}
            >
              {totalReducedSubscription}
            </span>
            <p
              className={`text-black transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-xs font-bold" : "text-base"
              }`}
            >
              Total Reduced Subscription
            </p>
          </div>
        </div>

        <div
          className={`col-span-5 px-4 transition-[padding] duration-300 ease-in-out lg:col-span-2 ${
            isSticky ? "py-6" : "rounded-2xl bg-white py-12 shadow"
          }`}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <span
              className={`bg-gradient-to-br from-blue-purple-gradient-start to-blue-purple-gradient-end bg-clip-text text-transparent transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-lg" : "text-xl font-bold"
              }`}
            >
              {totalFinanced}
            </span>
            <p
              className={`text-black transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-xs font-bold" : "text-base"
              }`}
            >
              Total Financed
            </p>
          </div>
        </div>

        <div
          className={`col-span-5 px-4 transition-[padding] duration-300 ease-in-out lg:col-span-2 ${
            isSticky ? "py-6" : "rounded-2xl bg-white py-12 shadow"
          }`}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <span
              className={`bg-gradient-to-b from-blue-gradient-start to-blue-gradient-end bg-clip-text text-transparent transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-lg" : "text-xl font-bold"
              }`}
            >
              {totalEfficiency}
            </span>
            <p
              className={`text-black transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-xs font-bold" : "text-base"
              }`}
            >
              Total Efficiency
            </p>
          </div>
        </div>

        <div
          className={`col-span-5 px-4 transition-[padding] duration-300 ease-in-out lg:col-span-2 ${
            isSticky ? "py-6" : "rounded-2xl bg-white py-12 shadow"
          }`}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <span
              className={`bg-gradient-to-b from-purple-gradient-start to-purple-gradient-end bg-clip-text text-transparent transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-lg" : "text-xl font-bold"
              }`}
            >
              {totalIntensity}
            </span>
            <p
              className={`text-black transition-[font-size] duration-300 ease-in-out ${
                isSticky ? "text-xs font-bold" : "text-base"
              }`}
            >
              Total Intensity
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
