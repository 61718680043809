// React and Mui Imports
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";

// Asset Imports (Icons and Logo)
import logo from "../../assets/vodafone-logo-icon.svg";
import HomeIcon from "../../assets/icons/home-outlined-icon.svg";
import BriefcaseIcon from "../../assets/icons/briefcase-outlined-icon.svg";
import SettingsIcon from "../../assets/icons/settings-outlined-icon.svg";
//import ProfileIcon from "../../assets/icons/admin-outlined-icon.svg";
import ControlPanelIcon from "../../assets/icons/control-panel-icon.svg";
import ArrowRightFromBracketIcon from "../../assets/icons/arrow-right-from-bracket-icon.svg";

// Service Imports
import AuthService from "../../services/auth.service";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

export default function Navbar() {
  // Menu dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const user = AuthService.getCurrentUser();

  if (user === null) {
    AuthService.logout();
    navigate("/logout");
  }

  const isSystemAdmin = user?.user_roles?.includes("system");
  const isTerminalsUser = user?.user_roles?.includes("terminal");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Dialogs
  const [logoutWarningDialogOpen, setLogoutWarningDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setLogoutWarningDialogOpen(false);
  };

  // Handle dialog open event
  const handleLogoutWarningOpen = (event) => {
    event.preventDefault();
    setLogoutWarningDialogOpen(true);
  };

  // Handle logout trigger
  const handleLogout = () => {
    AuthService.logout().then(() => {
      navigate("/logout");
    });
  };

  return (
    <>
      <header className="sticky top-0 z-20 flex items-center bg-white px-6 py-4 shadow">
        <Link to="/home">
          <img src={logo} alt="Vodafone Logo" width={56} height={56} />
        </Link>

        <nav className="ml-auto flex items-center gap-x-6 transition-opacity">
          <NavLink
            to="/home"
            end
            className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
          >
            <InlineSVG src={HomeIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Home</span>
          </NavLink>
          {!isTerminalsUser && (
            <NavLink
              to="/adhoc-deals"
              end
              className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
            >
              <InlineSVG
                src={ControlPanelIcon}
                width={28}
                height={28}
                ariaHidden
              />
              <span className="text-lg">Ad-Hoc Deals</span>
            </NavLink>
          )}
          <NavLink
            to="/documents"
            className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
          >
            <InlineSVG src={BriefcaseIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Documents</span>
          </NavLink>
          {isSystemAdmin ? (
            <>
              <NavLink
                to="/settings"
                id="settings-nav-item"
                className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
                aria-controls={open ? "nav-settings-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <InlineSVG
                  src={SettingsIcon}
                  width={28}
                  height={28}
                  ariaHidden
                />
                <span className="text-lg">Settings</span>
              </NavLink>
              {/* <NavLink
              to="/profile"
              className="flex items-center gap-x-3 fill-black text-black hover:fill-red-200 hover:text-red-200"
            >
              <InlineSVG src={ProfileIcon} width={28} height={28} ariaHidden />
              <span className="text-lg">Profile</span>
            </NavLink> */}
            </>
          ) : (
            <></>
          )}
          <NavLink
            to="/logout"
            className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
            onClick={handleLogoutWarningOpen}
          >
            <InlineSVG
              src={ArrowRightFromBracketIcon}
              width={28}
              height={28}
              ariaHidden
            />
            <span className="text-lg">Logout</span>
          </NavLink>
        </nav>

        <Menu
          id="nav-settings-menu" // Start the ID with "nav-" this is important for styling of the dropdown
          anchorEl={anchorEl}
          open={open}
          keepMounted={true}
          disablePortal={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "nav-settings-menu-item",
          }}
        >
          <MenuItem
            component={Link}
            to="/settings/user-management"
            disableRipple
          >
            User Management
          </MenuItem>
          <hr className="mx-8 border-grey-50" />
          <MenuItem
            component={Link}
            to="/settings/role-management"
            disableRipple
          >
            Role Management
          </MenuItem>
          <hr className="mx-8 border-grey-50" />
          {/* <MenuItem
          component={Link}
          to="/settings/system-configuration"
          disableRipple
        >
          System Configuration
        </MenuItem>
        <hr className="mx-8 border-grey-50" />
        <MenuItem
          component={Link}
          to="/settings/deal-configuration"
          disableRipple
        >
          Deal Cycle Configuration
        </MenuItem>
        <hr className="mx-8 border-grey-50" /> */}
          <MenuItem component={Link} to="/settings/jobs" disableRipple>
            Jobs
          </MenuItem>
          <hr className="mx-8 border-grey-50" />
          <MenuItem component={Link} to="/settings/system-configuration" disableRipple>
            System Configuration
          </MenuItem>
        </Menu>
      </header>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={logoutWarningDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Sign out?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any unsaved data will be lost. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={handleLogout}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
