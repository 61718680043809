import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';

// Custom hook to mimic useEffect behavior that runs only once
const useEffectOnce = (effect) => {
  const destroyFunc = useRef();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [, setVal] = useState(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    setVal((val) => val + 1);

    return () => {
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

function NonConcurrent() {
  const navigate = useNavigate();
  const sessionKey = 'dde-app-session-key';

  const isSessionValidated = useRef(false);
  const broadcastChannel = useRef(null);
  const isMounted = useRef(true);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Function to validate the session
  const validateSession = async (key) => {
    if (isSessionValidated.current) return;

    try {
      const response = await AuthService.validateSession({ sessionKey: key });
      const { isValid, activeSession } = response.data;

      if (response && isValid) {
        localStorage.setItem(sessionKey, activeSession);
        sessionStorage.setItem(sessionKey, activeSession);
        broadcastChannel.current.postMessage({ type: 'SESSION_UPDATE', key: activeSession });

        isSessionValidated.current = true;
        return;
      } else {
        triggerRedirect();
      }
    } catch (error) {
      console.error('Error validating session:', error);
      triggerRedirect(); // Ensure redirect on error
    }
  };

  // Function to restore session or validate a new one
  const restoreSession = async () => {
    const existingSessionKey = localStorage.getItem(sessionKey);
    if (existingSessionKey) {
      await validateSession(existingSessionKey);
    } else {
      const newSessionKey = Date.now().toString();
      await validateSession(newSessionKey);
    }
  };

  // Function to handle redirection to '/concurrent'
  const triggerRedirect = () => {
    if (!isRedirecting && isMounted.current) {
      setIsRedirecting(true);
      window.location.href = '/concurrent'; // Redirect to a different page
    }
  };

  // Function to terminate session from both storages
  const terminateSession = async () => {
    const currentKey = sessionStorage.getItem(sessionKey);
    if (currentKey) {
      try {
        await AuthService.terminateSession({ sessionKey: currentKey });
        console.log("Session terminated successfully for key:", currentKey);
      } catch (error) {
        console.error("Error terminating session:", error);
      }
    }
    localStorage.removeItem(sessionKey);
    sessionStorage.removeItem(sessionKey);
  };

  // Function to reset the session using AuthService.resetSession
  const resetActiveSession = async () => {
    const currentKey = sessionStorage.getItem(sessionKey);
    if (currentKey) {
      try {
        await AuthService.resetSession({ sessionKey: currentKey });
        localStorage.removeItem(sessionKey);
        sessionStorage.removeItem(sessionKey);
        console.log("Session reset successfully for key:", currentKey);
      } catch (error) {
        console.error("Error resetting session:", error);
      }
    }
  };

  useEffectOnce(() => {
    // Initialize BroadcastChannel for session updates
    broadcastChannel.current = new BroadcastChannel('session-channel');

    broadcastChannel.current.onmessage = (event) => {
      if (event.data.type === 'SESSION_UPDATE') {
        const updatedKey = event.data.key;
        // Only redirect if the session key in storage doesn't match the updated key
        if (updatedKey && updatedKey !== sessionStorage.getItem(sessionKey)) {
          triggerRedirect();
        }
      }
    };

    restoreSession(); // Attempt to restore session on mount

    // Handle storage changes across tabs
    const handleStorageChange = (e) => {
      if (e.key === sessionKey) {
        const updatedKey = e.newValue;
        if (updatedKey && updatedKey !== sessionStorage.getItem(sessionKey)) {
          triggerRedirect(); // Redirect if session key changes
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Prevent navigating back
    const preventBack = () => {
      window.history.pushState(null, null, window.location.href);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', preventBack);

    // Handle session termination and session reset on tab close
    const handleTabClose = async () => {
      await resetActiveSession(); // Reset the active session before terminating
      await terminateSession(); // Terminate session on tab close
      triggerRedirect(); // Redirect on tab close
    };

    window.addEventListener('beforeunload', handleTabClose);
    window.addEventListener('unload', handleTabClose);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('popstate', preventBack);
      window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener('unload', handleTabClose);

      if (broadcastChannel.current) {
        broadcastChannel.current.close(); // Close broadcast channel on component unmount
      }

      if (isMounted.current) {
        terminateSession(); // Ensure session termination on component unmount
      }
    };
  });

  return null;
}

export default NonConcurrent;
