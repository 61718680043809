import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// Service Imports
import AuthService from "../../services/auth.service";
import { socket } from "../../App";

export default function Landing() {
  const navigate = useNavigate();

  useEffect(() => {
    AuthService.getCurrentUserAPI()
      .then((response) => {
        console.log("AuthService.getCurrentUserAPI(): ", response);
        const { currentUser } = response;
        if (currentUser !== null) {
          console.log("Step 1");
          const { user_id } = currentUser;
          socket.emit("user_connect", user_id);
          return navigate("/home", { replace: true });
        }

        window.location.href = "/api/oauth/authorize";
        return;
      })
      .catch((error) => {
        handleServerError(error);
      });
  });

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      navigate("/unauthorized");
    } else {
      // Unexpected server error
      navigate("/error");
    }
  };

  return <></>;
}
