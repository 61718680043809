// React and Mui Imports
import { Link as LinkRouter, useLocation, useParams } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";

// Icon Imports
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";

export default function TitleBreadcrumb(props) {
  const { title, titleID, button = null } = props;

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const {
    cycle,
    id,
    channel,
    device,
    dealset,
    channel_id,
    device_basket_id,
    campaign_id,
    dde_parent_key,
  } = useParams();

  // User friendly names for breadcrumb paths
  const breadcrumbNameMap = {
    [`/deal-cycle/${encodeURIComponent(cycle)}/bulk-deals`]:
      "Generic Channel Deals",
    [`/deal-cycle/${encodeURIComponent(cycle)}/bulk-deals/${encodeURIComponent(
      channel
    )}/oem-selection`]: "OEM Selection",
    [`/deal-cycle/${encodeURIComponent(cycle)}/headline-deals`]:
      "Headline Deals",
    [`/deal-cycle/${encodeURIComponent(cycle)}/headline-deals/device-deals`]:
      "OEM Selection",
    [`/deal-cycle/${encodeURIComponent(cycle)}/headline-deals/sim-only`]:
      "SIM Only Deals",
    [`/deal-cycle/${encodeURIComponent(cycle)}/channel-deals`]: "Channel Deals",
    [`/adhoc-deals`]: "Ad-Hoc Deals",
    [`/adhoc-deals/${encodeURIComponent(dealset)}/${campaign_id}/children`]: "Children Deals",
    [`/deal-cycle/${encodeURIComponent(cycle)}/deal-approval`]: "Deal Approval",
    "/settings": "Settings",
    "/settings/user-management": "User Management",
    "/settings/user-management/new-user": "Create new user",
    [`/settings/user-management/user/${encodeURIComponent(id)}`]:
      "Edit user profile",
    "/settings/role-management": "Role Management",
    [`/settings/role-management/role/${encodeURIComponent(id)}`]: "Edit role",
    "/settings/role-management/new-role": "Create new role",
    "/settings/system-configuration": "System Configuration",
    "/settings/deal-configuration": "Deal Cycle Configuration",
    "/settings/jobs": "Jobs",
    "/documents": "Documents",
    [`/deal-cycle/${encodeURIComponent(cycle)}/base-generic-deals`]:
      "Base Generic Deals",
    [`/deal-cycle/${encodeURIComponent(
      cycle
    )}/base-generic-deals/oem-selection`]: "OEM Selection",
  };

  return (
    <>
      <div className="flex flex-col sm:items-start lg:flex-row lg:items-center lg:justify-between">
        <h1
          id={titleID}
          className="mb-0 text-4xl font-bold text-black sm:text-5xl md:text-6xl"
        >
          {title}
        </h1>

        {button && button}
      </div>

      <Breadcrumbs
        className="mt-6"
        aria-label="breadcrumb"
        separator={
          <PlayArrowRoundedIcon fontSize="small" className="text-blue-200" />
        }
      >
        <LinkRouter
          key={"Home"}
          className="text-base font-medium text-blue-200 hover:underline"
          to="/home"
        >
          Home
        </LinkRouter>
        {pathnames
          .filter(
            (value) =>
              value !== channel_id &&
              value !== device_basket_id &&
              value !== campaign_id &&
              value !== dde_parent_key
          )
          .map((value, index) => {
            let last =
              index ===
              pathnames.length - (1 + (channel ? 1 : 0) + (device ? 1 : 0));

            let to = `/${pathnames
              .slice(0, index + (1 + (device ? 1 : 0)))
              .join("/")}`;

            // Campaign Level
            if(to === `/${pathnames[0]}/${encodeURIComponent(dealset)}` && pathnames.slice(-1)[0] !== "children") {
              to = `${to}/${campaign_id}`;
              last = true;
            }
            // Children Level and back 
            else if(to === `/${pathnames[0]}/${encodeURIComponent(dealset)}` && index === 1) {
              to = `${to}/${campaign_id}`;
              // last = true;
            }
            // Children Level 
            else if(to === `/${pathnames[0]}/${encodeURIComponent(dealset)}/${campaign_id}` && index === 2) {
              to = `${to}/children`;
              last = true;
            }
                    
            // Don't display breadcrumbs for the following items
            if (
              value === "user" ||
              value === "role" ||
              value === "deal-cycle"
            ) {
              return false;
            }

            // Display the Deal Cycle with the actual date ie. 'April 2023 Deal Cycle'
            if (decodeURIComponent(value) === cycle) {
              return (
                <LinkRouter
                  key={"DealCycle"}
                  className="text-base font-medium text-blue-200 hover:underline"
                  to="/home"
                >
                  {decodeURIComponent(value) + " Deal Cycle"}
                </LinkRouter>
              );
            }

            // Display 'Settings' as a text item and not a link
            if (value === "settings") {
              return (
                <Typography
                  key={to}
                  className="text-base font-medium text-blue-200"
                >
                  {breadcrumbNameMap[to] || decodeURIComponent(value)}
                </Typography>
              );
            }

            // Display names from the breadcrumbNameMap and if the item doesn't exist use the value from the URL.
            // Displaying it as either a link, or a text item if it's the last item in the list.
            return last ? (
              <Typography
                key={to}
                className="text-base font-bold text-grey-300"
              >
                {breadcrumbNameMap[to] || decodeURIComponent(value)}
              </Typography>
            ) : (
              <LinkRouter
                className="text-base font-medium text-blue-200 hover:underline"
                key={to}
                to={to}
              >
                {breadcrumbNameMap[to] || decodeURIComponent(value)}
              </LinkRouter>
            );
          })}
      </Breadcrumbs>
    </>
  );
}
