// React, MUI and Hook Form Imports
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../slices/auth";
import { useForm } from "react-hook-form";

// Service Imports
import AuthService from "../../services/auth.service";

// Component Imports
import Footer from "../../components/footer/footer-component";
import InputField from "../../components/input/input-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";

// Asset Imports
import logo from "../../assets/vodafone-logo-icon.svg";

// Icon Imports
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ErrorOutlineIcon from "../../assets/icons/error-circle-outline-icon.svg";

export default function SignIn() {
  const navigate = useNavigate();
  const { hostname } = window.location;
  const welcomeText =
    hostname === "condorwebtests.link"
      ? "Welcome"
      : "Welcome to the Vodacom Deals Data Engine";

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // Alert states
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  // handle show alert
  const handleShowAlert = (severity, message) => {
    setShowAlert(true);
    setAlertSeverity(severity);
    setAlertMessage(message);
    console.log(showAlert, severity, message);
  };

  // Redux dispatch
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm();

  //console.log("localStorage.getItem('user'): ", localStorage.getItem("user"));
  // if (localStorage.getItem("user") != null) {
  //   navigate("/home", { replace: true });
  // }

  //   if (localStorage.getItem("user") != null) {
  //     navigate("/home");
  //   }

  const onSubmit = (data) => {
    const { email_address: email, password } = data;

    if (!loading) {
      setLoading(true);
    }

    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        navigate("/home");
        setLoading(false);
        //window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        handleShowAlert(
          "error",
          "Oops! Your login details are incorrect. Please double-check your username and password and try again."
        );
      });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="flex min-h-screen flex-col sm:bg-sign-in sm:bg-cover sm:bg-center">
      <main className="grid grid-cols-6 grid-rows-1 sm:container sm:mx-auto sm:mt-auto sm:py-5 lg:grid-cols-8 xl:grid-cols-10 2xl:grid-cols-12">
        <section
          aria-label="Sign in block"
          className="col-span-6 bg-white py-12 text-center sm:rounded-2xl sm:shadow lg:col-start-2 xl:col-start-3 2xl:col-start-4"
        >
          {hostname !== "condorwebtests.link" && (
            <>
              <img
                src={logo}
                alt="Vodacom Logo"
                width={150}
                height={150}
                className="mx-auto mb-7"
              />
            </>
          )}
          <h1 className="px-8 text-3xl font-bold leading-tight sm:px-12 sm:text-4xl md:col-start-2 md:text-5xl">
            {welcomeText}
          </h1>
          <div className="grid grid-cols-4 px-8 sm:px-12 md:grid-cols-6 md:px-0">
            <p className="col-span-4 row-start-2 mt-3 md:col-start-2">
              Start your journey by signing in using your account credentials
              below.
            </p>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="col-span-4 row-start-3 mt-8 flex flex-col gap-3 text-start md:col-start-2"
            >
              <InputField
                type="email"
                inputLabel="Email Address"
                placeholder="Email Address"
                InputProps={{
                  ...register("email_address", { required: true }),
                }}
              ></InputField>

              <InputField
                type={showPassword ? "text" : "password"}
                inputLabel="Password"
                placeholder="Password"
                InputProps={{
                  ...register("password", { required: true }),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="mr-[-6px] text-black"
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffOutlinedIcon fontSize="small" />
                        ) : (
                          <VisibilityOutlinedIcon fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></InputField>

              {showAlert && !loading && (
                <Alert
                  severity={alertSeverity}
                  iconMapping={{
                    error: (
                      <InlineSVG
                        src={ErrorOutlineIcon}
                        ariaHidden
                        width={24}
                        height={24}
                      />
                    ),
                  }}
                  className="col-span-4 mt-3 items-center justify-center rounded-lg md:col-start-2"
                >
                  {alertMessage}
                </Alert>
              )}

              <div className="relative mt-3">
                <Button
                  type="submit"
                  variant="contained"
                  className="w-full"
                  disableFocusRipple
                  disabled={!isDirty || !isValid || loading}
                >
                  Sign in
                </Button>
                {loading && (
                  <div className="absolute top-1/2 left-1/2 mt-[3px] -translate-x-1/2 -translate-y-1/2">
                    <CircularProgress
                      size={30}
                      thickness={4}
                      className="text-grey-700"
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="mt-8">
            <Link
              to={"/forgot-password"}
              className="text-base text-blue-200 underline focus-visible:outline-offset-4 focus-visible:outline-blue-200 hover:text-blue-300"
            >
              Forgot your password?
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
