// React Imports
import { Fragment } from "react";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import TickIcon from "../../assets/icons/tick.svg";

export default function DealCycleTimeline(props) {
  const { data } = props;

  return (
    <div className="flex flex-col justify-start py-1.5">
      {data.map((status, index) => (
        <Fragment key={index}>
          <div className="-my-1.5 flex items-center gap-3">
            <div
              className={`rounded-full p-1 ${
                status.active ? "bg-red-100" : "bg-gray-300"
              }`}
            >
              <InlineSVG
                src={TickIcon}
                width={15}
                height={15}
                ariaHidden
                className="fill-white"
              />
            </div>
            <div
              className={`${status.active ? "text-black" : "text-gray-300"}`}
            >
              <p className="font-bold leading-tight">{status.event}</p>
              {status.date && <p className="text-xs">{status.date}</p>}
            </div>
          </div>

          {status.subEvents && (
            <>
              <div className="flex h-12 w-[22px] justify-center">
                <div
                  className={`h-full w-px border-l border-dashed ${
                    status.active ? "border-red-100" : "border-gray-300"
                  }`}
                ></div>
              </div>

              {status.subEvents.map((event, subIndex) => (
                <Fragment key={subIndex}>
                  <div className="-my-0.5 flex items-center gap-3">
                    <div className="flex w-[22px] justify-center">
                      <div
                        className={`rounded-full p-1 ${
                          event.active ? "bg-red-100" : "bg-gray-300"
                        }`}
                      ></div>
                    </div>
                    <p
                      className={`text-xs ${
                        event.active ? "text-black" : "text-gray-300"
                      }`}
                    >
                      {event.label}
                    </p>
                  </div>

                  {subIndex < status.subEvents.length - 1 && (
                    <div className="flex h-12 w-[22px] justify-center">
                      <div
                        className={`h-full w-px border-l border-dashed ${
                          event.active ? "border-red-100" : "border-gray-300"
                        }`}
                      ></div>
                    </div>
                  )}
                </Fragment>
              ))}
            </>
          )}

          {index < data.length - 1 && (
            <div className="flex h-12 w-[22px] justify-center">
              <div
                className={`h-full w-px border-l border-dashed ${
                  status.active ? "border-red-100" : "border-gray-300"
                }`}
              ></div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}
