// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import ArrowRight from "../../assets/icons/arrow-right-icon.svg";

export default function BulkDealCardGeneric({
  id,
  title,
  subtitle = false,
  standalone = 0,
  accessory = 0,
  secondary = 0,
  flagged = 0,
}) {
  return (
    <div
      className="flex w-full flex-wrap items-center gap-6 rounded-lg bg-white px-8 py-5 text-start text-base shadow"
      aria-labelledby={`#${id}`}
    >
      <div className="flex basis-full flex-col 2xl:basis-auto">
        <h2 id={id} className="text-base font-bold text-red-100">
          {title}
        </h2>
        {subtitle && (
          <span className="mt-1 text-sm font-normal text-grey-500">
            {subtitle}
          </span>
        )}
      </div>
      <div className="hidden h-6 w-px bg-grey-50 2xl:block"></div>
      {/* <div className="flex items-center gap-2">
        <span className="font-bold">Standalone Deals:</span>
        <span className="font-normal">{standalone}</span>
      </div>
      <div className="h-6 w-px bg-grey-50"></div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Accessory Deals:</span>
        <span className="font-normal">{accessory}</span>
      </div>
      <div className="h-6 w-px bg-grey-50"></div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Secondary Device Deals:</span>
        <span className="font-normal">{secondary}</span>
      </div>
      <div className="h-6 w-px bg-grey-50"></div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Flagged Deals:</span>
        <span className="font-normal">{flagged}</span>
      </div> */}

      <div className="ml-auto">
        <InlineSVG
          src={ArrowRight}
          className="fill-red-100"
          ariaHidden
          width={34}
          height={34}
        />
      </div>
    </div>
  );
}
