// React and Mui Imports
import {
  Button,
  Tab,
  Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  DialogTitle,
  DialogContentText,
  Slide,
  Snackbar,
  Alert,
  Collapse,
  IconButton,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

// Unique ID Import
import { v4 as uuidv4 } from "uuid";

// Component Imports
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import InputField from "../../components/input/input-component";
import CustomDatePicker from "../../components/datepicker/datepicker-component";
import DealCycleTimeline from "../../components/deal-cycle-timeline/deal-cycle-timeline-component";

// Icon Imports
import SettingsIcon from "../../assets/icons/settings-outlined-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";
import TrashIcon from "../../assets/icons/trash-icon.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right-icon.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import TickOutlineCircle from "../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../assets/icons/error-circle-no-fill-icon.svg";
import EyeIcon from "../../assets/icons/eye-icon.svg";
import WarningIcon from "../../assets/icons/warning-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import DealCycleReviewIcon from "../../assets/icons/deal-cycle-review-icon.svg";
import DealApprovalIcon from "../../assets/icons/deal-approval-icon.svg";

// Services Imports
import AuthService from "../../services/auth.service";
import DealService from "../../services/deal.service";
import UploadService from "../../services/upload.service";

// Animation Imports
import Lottie from "lottie-react";
import AstronautAnimation from "../../assets/animations/astronaut-animation.json";
import DownloadService from "../../services/download.service";
import CycleConfigService from "../../services/cycleConfig.service";

// Slice Imports
import { getCycleTab, setCycleId, setCycleTab } from "../../slices/dealCanvas";
import { closeSnackbar, getSnackbarData } from "../../slices/snackbar";
import HeadlineService from "../../services/headline.service";
import {
  dealCycleReviewTimeline,
  dealCycleTimeline,
} from "../../data/mock-data";
import { DialogText } from "../../data";
import { getConfigSettings } from "../../slices/system-config-canvas";

export const CustomTabScrollButton = forwardRef((props, ref) => {
  return (
    <Button
      {...props}
      ref={ref}
      aria-label={
        props.direction === "left" ? "Scroll tabs left" : "Scroll tabs right"
      }
      className={`shadow
        ${
          props.direction === "left"
            ? "rounded-r-none rounded-l-lg"
            : "rounded-r-lg rounded-l-none"
        }
      `}
      disableFocusRipple
    >
      {props.direction === "left" ? (
        <InlineSVG src={ArrowLeftIcon} ariaHidden width={32} height={32} />
      ) : (
        <InlineSVG src={ArrowRightIcon} ariaHidden width={32} height={32} />
      )}
    </Button>
  );
});

// const viewData = {
//   CBU: {
//     channelCardSubHeader: "Total Channel Deals",
//     channelCardLinkText: "View Channel Deals",
//     genericCardSubHeader: "Total Base Deals",
//     genericCardLinkText: "View Base Deals",
//   },
//   VBU: {
//     channelCardSubHeader: "Total Trade Deals",
//     channelCardLinkText: "View Trade Deals",
//     genericCardSubHeader: "Total Base Deals",
//     genericCardLinkText: "View Base Deals",
//   },
// };

const _overviewTotals = {
  totalCostToConnect: "R 0.00",
  totalReducedSubscription: "R 0.00",
  totalFinanced: "R 0.00",
  totalEfficiency: "0.00%",
  totalIntensity: "0.00%",
};

export const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  warning: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

export default function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = AuthService.getCurrentUser();

  if (user === null) {
    AuthService.logout();
    window.location.href = "/logout";
  }

  const { business_unit } = user;
  const isTerminalsUser = user.user_roles.includes("terminal");

  // Sliding tabs
  const cycleTab = useSelector(getCycleTab);
  const [value, setValue] = useState(cycleTab);
  const [cycleUpdated, setCycleUpdated] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    localStorage.setItem("cycleTabValue", newValue);
    localStorage.setItem("cycleId", updatedDealCycle[newValue]?.cycleId);
    dispatch(setCycleTab({ value: newValue }));
    dispatch(setCycleId({ value: updatedDealCycle[newValue]?.cycleId }));
  };

  // Deal cycle create dialog
  const uid = uuidv4();
  const [dialogNewCycleOpen, setDialogNewCycleOpen] = useState(false);
  const [dialogCancelCycleOpen, setDialogCancelCycleOpen] = useState(false);
  const [updatedDealCycle, setUpdatedDealCycle] = useState([]);

  const [tradeList, setTradeList] = useState([]);

  const [generateProtoDisable, setGenerateProtoDisable] = useState(false);
  const [generateHeadlineDisable, setGenerateHeadlineDisable] = useState(false);

  // User Permission Variables used to show only the relevant content on the dashboard
  const [latestApprovalLevel, setLatestApprovalLevel] = useState("");
  const dealConfig_approval_level =
    user.user_roles.includes(latestApprovalLevel);
  const [cycleReviewApprovalRequired, setCycleReviewApprovalRequired] =
    useState(true);
  const [viewConfigRev, setViewConfigRev] = useState(false);

  useEffect(() => {
    DealService.getDealCycles()
      .then((response) => {
        setUpdatedDealCycle(response.data);
        setCycleUpdated(cycleUpdated + 1);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getTotalDealsPerOEM(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.totalDealsOEM = response.data;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getDealCycleDocuments(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.documents = response.data;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    const date = updatedDealCycle[value]?.date;
    if (cycle_id !== undefined) {
      DealService.getTotalBulkDeals(date)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.totalBulkDeals = response.data.total;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    const date = updatedDealCycle[value]?.date;
    if (cycle_id !== undefined) {
      DealService.getTotalGenericDeals(date)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.totalGenericDeals = response.data.total;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getTotalProtoHeadlineDeals(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.protoHeadlineDeals = response.data.total;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getTotalHeadlineDeals(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.headlineDeals = response.data.total;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getTotalFlaggedDeals(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.flaggedDeals = response.data.total;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getTotalAdHocDeals(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.adhocDeals = response.data.total;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getDealCycleTimeline(cycle_id)
        .then((response) => {
          setUpdatedDealCycle(
            updatedDealCycle.filter((item) => {
              if (item.cycleId === cycle_id) {
                item.dealCycleTimeLine = response.data;
              }
              return item;
            })
          );
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      UploadService.getTradeList(cycle_id).then((response) => {
        let tradeListData = [];
        for (let i = 0; i < response.data.length; i++) {
          const data = {
            value: response.data[i].date,
            label: response.data[i].name,
          };
          tradeListData[i] = data;
        }
        setTradeList(tradeListData);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  useEffect(() => {
    const cycle_date = updatedDealCycle[value]?.date;

    if (cycle_date !== undefined) {
      CycleConfigService.getCycleReviews(cycle_date)
        .then((response) => {
          const { data } = response;
          if (data.length > 0) {
            const result = data.map(
              ({ config_status, reviewed, latest_approval_level }) => {
                return [config_status, reviewed, latest_approval_level];
              }
            );

            // Get the Approval Level of the user
            const userApvLvl = user.user_roles
              .filter((word) => word.includes("approval_lvl"))[0]
              .slice(-1);

            const doneReview =
              parseInt(userApvLvl) < result[0][2] ? true : false;
            setViewConfigRev(doneReview);

            const allPending = result.some((item) => item[0] === "P");
            const allReviewed = result.some((item) => item[1] === "N");

            setCycleReviewApprovalRequired(
              allPending && allReviewed && parseInt(userApvLvl) === result[0][2]
            );

            setLatestApprovalLevel(`approval_lvl${result[0][2]}`);
          } else {
            //setCycleReviewApprovalRequired(false);
            setLatestApprovalLevel("");
          }
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value, user.user_roles]);

  const handleCycleCreate = (cycle) => {
    DealService.createDealCycle(cycle)
      .then(() => {
        DealService.getDealCycle(cycle).then((response) => {
          const updatedCycle = updatedDealCycle.map((item) => {
            if (item.cycleId === cycle) {
              return { ...response.data, active: true };
            } else {
              return item;
            }
          });
          setUpdatedDealCycle(updatedCycle);
          setCycleUpdated(cycleUpdated + 1);
        });
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const handleClearDealCycle = (cycle) => {
    handleDialogClose();
    DealService.clearDealCycle(cycle)
      .then((response) => {
        const { message } = response;
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  // Cycle Parameters dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogGenericAction, setDialogGenericAction] = useState("");
  const [dialogGenericOpen, setDialogGenericOpen] = useState(false);
  const [dialogGenericMessageText, setDialogGenericMessageText] = useState("");
  const [dialogGenericConfirmationTitle, setDialogGenericConfirmationTitle] =
    useState("");
  const fullScreen = useMediaQuery("(max-width: 640px)");
  //const [cycleTabValue, setCycleTabValue] = useState(0);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogNewCycleOpen(false);
    setDialogCancelCycleOpen(false);
  };

  const handleDialogGenericOpen = (action) => {
    setDialogGenericAction(action);
    setDialogGenericConfirmationTitle(DialogText[action].title);
    setDialogGenericMessageText(DialogText[action].message);
    setDialogGenericOpen(true);
  };

  const handleDialogGenericClose = () => {
    setDialogGenericAction("");
    setDialogGenericConfirmationTitle("");
    setDialogGenericMessageText("");
    setDialogGenericOpen(false);
  };

  const handleDialogGenericAction = (cycle_id) => {
    handleDialogGenericClose();
    switch (dialogGenericAction) {
      case "SUBMIT_HEADLINES":
        handleSubmitDealsForApproval(cycle_id);
        break;
      case "PUBLISH_HEADLINES":
        handlePublishHeadlineDeals(cycle_id);
        break;
    }
  };

  // const handleDialogTabChange = (_, newValue) => {
  //   setCycleTabValue(newValue);
  // };

  const isItemInOptions = (item, options) =>
    options.some((option) => option.value === item);
  const selectedOption = (item, options) =>
    isItemInOptions(item, options) ? item : "placeholder";

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // Values for totals at the top of the page
  const [overviewTotals, setOverviewTotals] = useState(_overviewTotals);

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      DealService.getOverviewTotals(cycle_id)
        .then((response) => {
          setOverviewTotals(response.data);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);
  //

  // Handle document download
  const handleDownload = (docName, s3FileName) => {
    DownloadService.downloadFile(s3FileName)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileExtension = s3FileName.split(".")[1];
        const file = `${docName}.${fileExtension}`;

        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
  };

  // Alert states
  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity] = useState("success");
  const [alertTitle] = useState("");
  const [alertMessage] = useState("");
  const [alertLinkText] = useState("");
  const [alertLinkURL] = useState("");

  // handle show alert
  // const handleShowAlert = (severity, title, message, linkText, linkURL) => {
  //   setShowAlert(true);
  //   setAlertSeverity(severity);
  //   setAlertTitle(title);
  //   setAlertMessage(message);
  //   setAlertLinkText(linkText);
  //   setAlertLinkURL(linkURL);

  //   // Usage example:
  //   //
  //   // handleShowAlert("warning", "No trade list available.", "We were unable to find a trade list for this deal cycle. Upload a March 2023 trade list in order to get started.", "Upload trade list", "/upload-trade-list")
  //   //
  // };

  const remoteSnackbar = useSelector(getSnackbarData);

  const configSettings = useSelector((state) => state?.systemConfig?.configSettings);

  useEffect(() => {
    dispatch(getConfigSettings());
  }, [dispatch]);

  useEffect(() => {
    if (remoteSnackbar.open) {
      handleGenericSnackOpen(remoteSnackbar.type, remoteSnackbar.message);
      dispatch(closeSnackbar());
    }
  }, [
    remoteSnackbar.open,
    remoteSnackbar.type,
    remoteSnackbar.message,
    dispatch,
  ]);

  const handleGenerateProtoHeadlines = (cycle) => {
    setGenerateProtoDisable(true);
    HeadlineService.generateProtoHeadlines(cycle)
      .then(() => {
        DealService.getDealCycle(cycle).then((response) => {
          const updatedCycle = updatedDealCycle.map((item) => {
            if (item.cycleId === cycle) {
              return { ...response.data, active: true };
            } else {
              return item;
            }
          });
          setUpdatedDealCycle(updatedCycle);
          setCycleUpdated(cycleUpdated + 1);
        });
      })
      .catch((error) => {
        setGenerateProtoDisable(false);
        handleServerError(error);
      });
  };

  const handleGenerateHeadlines = (cycle) => {
    setGenerateHeadlineDisable(true);
    HeadlineService.generateHeadlines(cycle)
      .then(() => {
        DealService.getDealCycle(cycle).then((response) => {
          const updatedCycle = updatedDealCycle.map((item) => {
            if (item.cycleId === cycle) {
              return { ...response.data, active: true };
            } else {
              return item;
            }
          });
          setUpdatedDealCycle(updatedCycle);
          setCycleUpdated(cycleUpdated + 1);
        });
      })
      .catch((error) => {
        setGenerateHeadlineDisable(false);
        handleServerError(error);
      });
  };

  const handleSubmitDealsForApproval = (cycle_id) => {
    HeadlineService.submitForApproval(cycle_id)
      .then(() => {
        DealService.getDealCycle(cycle_id).then((response) => {
          const updatedCycle = updatedDealCycle.map((item) => {
            if (item.cycleId === cycle_id) {
              return { ...response.data, active: true };
            } else {
              return item;
            }
          });
          setUpdatedDealCycle(updatedCycle);
          setCycleUpdated(cycleUpdated + 1);
        });
        handleGenericSnackOpen(
          "success",
          "Headline deals have been submitted for approval!"
        );
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const handlePublishHeadlineDeals = (cycle_id) => {
    HeadlineService.publishHeadlines(cycle_id)
      .then(() => {
        DealService.getDealCycle(cycle_id).then((response) => {
          const updatedCycle = updatedDealCycle.map((item) => {
            if (item.cycleId === cycle_id) {
              return { ...response.data, active: true };
            } else {
              return item;
            }
          });
          setUpdatedDealCycle(updatedCycle);
          setCycleUpdated(cycleUpdated + 1);
        });
        handleGenericSnackOpen(
          "success",
          "Headline deals have been published!"
        );
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  // User Permission Variables used to show only the relevant content on the dashboard
  const approval_lvl1 = user.user_roles.includes("approval_lvl1");
  const admin = true; //user.user_roles.includes("admin");

  // Set approval state for cycles (THIS NEEDS TO BE REPLACED WITH READ CYCLE DATA)
  // const [dealApprovalRequired] = useState(true);
  // const [dealSummaryApprovalRequired] = useState(true);

  const [disableSubmitForApproval, setDisableSubmitForApproval] =
    useState(true);
  const [disablePublishHeadlines, setDisablePublishHeadlines] = useState(true);
  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      HeadlineService.getTotalDraftHeadlineDeals(cycle_id)
        .then((response) => {
          const { total } = response.data;
          setDisableSubmitForApproval(total === 0);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);
  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      HeadlineService.getTotalUnapprovedHeadlineDeals(cycle_id)
        .then((response) => {
          const { total } = response.data;
          setDisablePublishHeadlines(total > 0);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleUpdated, value]);

  return (
    <section
      aria-labelledby="#homePageTitle"
      className="col-start-2 col-end-12 row-start-2"
    >
      <Collapse in={showAlert}>
        <Alert
          className="mt-16 items-center gap-4 rounded-lg p-6"
          severity={alertSeverity}
          iconMapping={{
            warning: (
              <InlineSVG src={WarningIcon} ariaHidden width={24} height={24} />
            ),
            success: (
              <InlineSVG
                src={TickOutlineCircle}
                ariaHidden
                width={24}
                height={24}
              />
            ),
          }}
          action={
            <IconButton
              aria-label="Close Alert"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <h2 className="mb-1 text-lg font-bold">{alertTitle}</h2>
          <p className="mb-6">{alertMessage}</p>
          <Link to={alertLinkURL} className="underline">
            {alertLinkText}
          </Link>
        </Alert>
      </Collapse>

      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        ScrollButtonComponent={CustomTabScrollButton}
        allowScrollButtonsMobile
        aria-label="Deal cycle selection"
        className={`deal-cycle-nav ${showAlert ? "mt-6 mb-16" : "my-16"}`}
      >
        {updatedDealCycle.map((cycle, index) => (
          <Tab
            key={index}
            label={cycle.date}
            {...a11yProps(index)}
            disableRipple
            className={`${cycle.active ? "" : "cycle-not-done"}`}
          />
        ))}
      </Tabs>

      {updatedDealCycle.map((cycle, index) => {
        const headlinesPublished = cycle.dealCycleTimeLine
          .find((v) => v.event === "Headlines")
          .subEvents.find((v) => v.label === "Published").active;

        return (
          <TabPanel key={index} value={value} index={index}>
            <div className="flex flex-col items-center gap-x-6 text-center lg:flex-row lg:text-left">
              <h1
                id="homePageTitle"
                className="text-4xl font-bold text-black sm:text-5xl md:text-6xl lg:mr-auto"
              >
                {cycle.date} Deal Cycle
              </h1>

              {cycle.active ? (
                // Deal Cycle configuration button
                <>
                  {!isTerminalsUser && (
                    <>
                      <Button
                        className="mt-6 flex-shrink-0 lg:mt-0"
                        variant="outlined"
                        disableFocusRipple
                        href={`deal-cycle/${cycle.date}/configuration`}
                        startIcon={
                          <InlineSVG
                            src={SettingsIcon}
                            ariaHidden
                            width={24}
                            height={24}
                            className="fill-black"
                          />
                        }
                      >
                        Deal Cycle Configuration
                      </Button>
                      {user.user_roles.includes("system") && (
                        <Button
                          variant="text"
                          className="mt-6 flex flex-shrink-0 items-center gap-x-2 text-black hover:text-grey-700 hover:underline lg:mt-0"
                          disableRipple
                          onClick={() => setDialogOpen(true)}
                        >
                          <InlineSVG
                            src={TrashIcon}
                            ariaHidden
                            width={24}
                            height={24}
                            className="fill-red-100"
                          />
                          <span>Clear Deal Cycle</span>
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {!isTerminalsUser && (
                    <>
                      {/* Deal Cycle configuration button */}
                      {/* <Button
                      className="mt-6 flex-shrink-0 lg:mt-0"
                      variant="outlined"
                      disableFocusRipple
                      href={`deal-cycle/${cycle.date}/configuration`}
                      startIcon={
                        <InlineSVG
                          src={SettingsIcon}
                          ariaHidden
                          width={24}
                          height={24}
                          className="fill-black"
                        />
                      }
                    >
                      Deal Cycle Configuration
                    </Button> */}
                      <Button
                        className="mt-6 flex-shrink-0 lg:mt-0"
                        variant="contained"
                        disableFocusRipple
                        onClick={() => handleCycleCreate(cycle.cycleId)}
                        startIcon={
                          <InlineSVG
                            src={PlusIcon}
                            ariaHidden
                            width={24}
                            height={24}
                            className="fill-white"
                          />
                        }
                      >
                        Start Deal Cycle
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>

            {cycle.active ? (
              // cycleConfigReviewer
              <div
                className={`my-12 grid gap-4 ${
                  admin ? "grid-cols-11" : "grid-cols-10"
                }`}
              >
                {/* Financial detail cards (Cost to Connect, Total Reduced Sub, Total Financed, Efficiency, Intensity) */}
                {admin && (
                  <div className="col-span-full grid grid-cols-10 gap-4">
                    {/* Total Cost to Connect */}
                    <div className="col-span-10 rounded-2xl bg-white px-4 py-12 shadow sm:col-span-5 lg:col-span-3 xl:col-span-2">
                      <div className="flex flex-col items-center justify-center text-center">
                        <span className="break-all bg-gradient-to-r from-purple-100 to-red-100 bg-clip-text text-xl font-bold text-transparent">
                          {overviewTotals.totalCostToConnect}
                        </span>
                        <p className="text-base text-black">
                          Total Cost to Connect
                        </p>
                      </div>
                    </div>

                    {/* Total Reduced Subscription */}
                    <div className="col-span-10 rounded-2xl bg-white px-4 py-12 shadow sm:col-span-5 lg:col-span-3 xl:col-span-2">
                      <div className="flex flex-col items-center justify-center text-center">
                        <span className="break-all bg-gradient-to-r from-red-gradient-start to-red-gradient-end bg-clip-text text-xl font-bold text-transparent">
                          {overviewTotals.totalReducedSubscription}
                        </span>
                        <p className="text-base text-black">
                          Total Reduced Subscription
                        </p>
                      </div>
                    </div>

                    {/* Total Financed */}
                    <div className="col-span-10 rounded-2xl bg-white px-4 py-12 shadow sm:col-span-5 lg:col-span-3 xl:col-span-2">
                      <div className="flex flex-col items-center justify-center text-center">
                        <span className="break-all bg-gradient-to-br from-blue-purple-gradient-start to-blue-purple-gradient-end bg-clip-text text-xl font-bold text-transparent">
                          {overviewTotals.totalFinanced}
                        </span>
                        <p className="text-base text-black">Total Financed</p>
                      </div>
                    </div>

                    {/* Total Efficiency */}
                    <div className="col-span-10 rounded-2xl bg-white px-4 py-12 shadow sm:col-span-5 lg:col-span-3 xl:col-span-2">
                      <div className="flex flex-col items-center justify-center text-center">
                        <span className="break-all bg-gradient-to-b from-blue-gradient-start to-blue-gradient-end bg-clip-text text-xl font-bold text-transparent">
                          {overviewTotals.totalEfficiency}
                        </span>
                        <p className="text-base text-black">Total Efficiency</p>
                      </div>
                    </div>

                    {/* Total Intensity */}
                    <div className="col-span-10 rounded-2xl bg-white px-4 py-12 shadow sm:col-span-5 lg:col-span-3 xl:col-span-2">
                      <div className="flex flex-col items-center justify-center text-center">
                        <span className="break-all bg-gradient-to-b from-purple-gradient-start to-purple-gradient-end bg-clip-text text-xl font-bold text-transparent">
                          {overviewTotals.totalIntensity}
                        </span>
                        <p className="text-base text-black">Total Intensity</p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-3 row-span-2 pt-2">
                  <DealCycleTimeline data={cycle.dealCycleTimeLine} />
                </div>

                <div
                  className={`flex flex-col gap-4 ${
                    admin ? "col-span-8 xl:col-span-4" : "col-span-7"
                  }`}
                >
                  {/* Deal Cycle Configuration Review  */}
                  {(dealConfig_approval_level || viewConfigRev) && (
                    <div className="rounded-2xl bg-white shadow">
                      <div className="flex flex-col items-center justify-center px-6 pt-4 pb-12 text-center">
                        {cycleReviewApprovalRequired && (
                          <p className="mb-12 self-start justify-self-start text-lg text-grey-300">
                            Waiting for approval
                          </p>
                        )}

                        <InlineSVG
                          src={DealCycleReviewIcon}
                          width={40}
                          height={40}
                          ariaHidden
                        />

                        <h3 className="mt-6 mb-10 text-2xl font-normal text-grey-700">
                          Deal Cycle Configuration Review
                        </h3>

                        <Button
                          disableFocusRipple
                          className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                          LinkComponent={Link}
                          to={`/deal-cycle/${cycle.date}/configuration-review`}
                        >
                          <InlineSVG
                            src={EyeIcon}
                            width={24}
                            height={24}
                            ariaHidden
                          />
                          <span className="text-base font-bold">
                            View Deal Cycle Configuration Review
                          </span>
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* Deal Approvals */}
                  {/* {approval_lvl1 && (
                  <div className="rounded-2xl bg-white shadow">
                    <div className="flex flex-col items-center justify-center px-6 pt-4 pb-12 text-center">
                      {dealApprovalRequired && (
                        <p className="text-lg text-grey-300 self-start justify-self-start mb-12">Waiting for approval</p>
                      )}

                      <InlineSVG
                        src={DealApprovalIcon}
                        width={40}
                        height={40}
                        ariaHidden
                      />

                      <h3 className="mt-6 mb-10 text-2xl font-normal text-grey-700">
                        Deal Approvals
                      </h3>

                      <Button
                        disableFocusRipple
                        className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                        LinkComponent={Link}
                        to={`/deal-cycle/${cycle.date}/deal-approval`}
                      >
                        <InlineSVG
                          src={EyeIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                        <span className="text-base font-bold">
                          View Deal Approvals
                        </span>
                      </Button>
                    </div>
                  </div>
                )} */}

                  {/* Deal Summary Reviews */}
                  {/* {approval_lvl1 && (
                  <div className="rounded-2xl bg-white shadow">
                    <div className="flex flex-col items-center justify-center px-6 pt-4 pb-12 text-center">
                      {dealSummaryApprovalRequired && (
                        <p className="text-lg text-grey-300 self-start justify-self-start mb-12">Waiting for approval</p>
                      )}

                      <p className="text-6xl font-bold">
                        <span className="bg-gradient-to-tr from-purple-gradient-start to-purple-gradient-end bg-clip-text text-transparent">
                          {(700).toLocaleString("en-ZA")}
                        </span>
                      </p>

                      <h3 className="mt-6 mb-10 text-2xl font-normal text-grey-700">
                        Deal Summary Reviews
                      </h3>

                      <Button
                        disableFocusRipple
                        className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                        LinkComponent={Link}
                      >
                        <InlineSVG
                          src={EyeIcon}
                          width={24}
                          height={24}
                          ariaHidden
                        />
                        <span className="text-base font-bold">
                          View Deal Summary Reviews
                        </span>
                      </Button>
                    </div>
                  </div>
                )} */}

                  {admin &&
                    (cycle.headlineDeals > 0 ? (
                      <>
                        {/* Headline Deals */}
                        <div
                          className={`rounded-2xl bg-white ${
                            cycle.headlineDeals > 0 ? "shadow" : ""
                          }`}
                        >
                          <div className="flex flex-col items-center justify-center px-8 py-12 text-center">
                            <p className="text-6xl font-bold">
                              <span
                                className={`${
                                  cycle.headlineDeals > 0
                                    ? "bg-gradient-to-tr from-red-gradient-start to-red-gradient-end bg-clip-text text-transparent"
                                    : "text-grey-300"
                                }`}
                              >
                                {cycle.headlineDeals.toLocaleString("en-ZA")}
                              </span>
                            </p>
                            <p
                              className={`mt-4 text-2xl leading-normal ${
                                cycle.headlineDeals > 0
                                  ? "text-black"
                                  : "text-grey-300"
                              }`}
                            >
                              Headline Deals
                            </p>
                          </div>
                          <div className="flex items-center justify-between gap-4 px-6 pb-6">
                            {!isTerminalsUser && (
                              <>
                                <Button
                                  disableFocusRipple
                                  className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                                  LinkComponent={Link}
                                  to={`/deal-cycle/${cycle.date}/headline-deals/`}
                                  disabled={cycle.headlineDeals < 1}
                                >
                                  <InlineSVG
                                    src={EyeIcon}
                                    width={24}
                                    height={24}
                                    ariaHidden
                                  />
                                  <span className="text-base font-bold">
                                    View Headline Deals
                                  </span>
                                </Button>
                                {!headlinesPublished && (
                                  <>
                                    <Button
                                      variant="contained"
                                      disableFocusRipple
                                      disabled={disableSubmitForApproval}
                                      onClick={() =>
                                        handleDialogGenericOpen(
                                          "SUBMIT_HEADLINES"
                                        )
                                      }
                                    >
                                      Submit for Approval
                                    </Button>

                                    <Button
                                      variant="contained"
                                      disableFocusRipple
                                      disabled={disablePublishHeadlines}
                                      onClick={() =>
                                        handleDialogGenericOpen(
                                          "PUBLISH_HEADLINES"
                                        )
                                      }
                                    >
                                      Publish
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Proto Headline Deals */}
                        <div
                          className={`rounded-2xl bg-white ${
                            cycle.protoHeadlineDeals > 0 ? "shadow" : ""
                          }`}
                        >
                          <div className="flex flex-col items-center justify-center px-8 py-12 text-center">
                            <p className="text-6xl font-bold">
                              <span
                                className={`${
                                  cycle.protoHeadlineDeals > 0
                                    ? "bg-gradient-to-tr from-red-gradient-start to-red-gradient-end bg-clip-text text-transparent"
                                    : "text-grey-300"
                                }`}
                              >
                                {cycle.protoHeadlineDeals.toLocaleString(
                                  "en-ZA"
                                )}
                              </span>
                            </p>
                            <p
                              className={`mt-4 text-2xl leading-normal ${
                                cycle.protoHeadlineDeals > 0
                                  ? "text-black"
                                  : "text-grey-300"
                              }`}
                            >
                              OEM Negotiations
                            </p>
                          </div>
                          <div className="flex items-center justify-between gap-4 px-6 pb-6">
                            <Button
                              disableFocusRipple
                              className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                              LinkComponent={Link}
                              to={`/deal-cycle/${cycle.date}/headline-deals/`}
                              disabled={cycle.protoHeadlineDeals < 1}
                            >
                              <InlineSVG
                                src={EyeIcon}
                                width={24}
                                height={24}
                                ariaHidden
                              />
                              <span className="text-base font-bold">
                                View Proto Headline Deals
                              </span>
                            </Button>

                            {cycle.protoHeadlineDeals < 1 &&
                            business_unit === "CBU" &&
                            !isTerminalsUser ? (
                              <>
                                <Button
                                  variant="contained"
                                  disableFocusRipple
                                  onClick={() =>
                                    handleGenerateProtoHeadlines(cycle.cycleId)
                                  }
                                  disabled={generateProtoDisable}
                                >
                                  Generate Proto Headlines
                                </Button>
                              </>
                            ) : (cycle.protoHeadlineDeals > 0 ||
                                business_unit === "VBU") &&
                              cycle.headlineDeals < 1 &&
                              !isTerminalsUser ? (
                              <>
                                <Button
                                  variant="contained"
                                  disableFocusRipple
                                  onClick={() =>
                                    handleGenerateHeadlines(cycle.cycleId)
                                  }
                                  disabled={generateHeadlineDisable}
                                >
                                  Generate Headlines
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ))}

                  {/* Flagged for Approval */}
                  {admin && (
                    <div
                      className={`rounded-2xl bg-white ${
                        cycle.flaggedDeals > 0 ? "shadow" : ""
                      }`}
                    >
                      <div className="flex flex-col items-center justify-center px-8 py-12 text-center">
                        <p className="text-6xl font-bold">
                          <span
                            className={`${
                              cycle.flaggedDeals > 0
                                ? "bg-gradient-to-br from-yellow-gradient-start to-yellow-gradient-end bg-clip-text text-transparent"
                                : "text-grey-300"
                            }`}
                          >
                            {cycle.flaggedDeals.toLocaleString("en-ZA")}
                          </span>
                        </p>
                        <p
                          className={`mt-4 text-2xl leading-normal ${
                            cycle.flaggedDeals > 0
                              ? "text-black"
                              : "text-grey-300"
                          }`}
                        >
                          Flagged For Approval
                        </p>
                      </div>
                      <div className="flex items-center justify-between gap-4 px-6 pb-6">
                        <Button
                          disableFocusRipple
                          className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                          LinkComponent={Link}
                          to={`/deal-cycle/${cycle.date}/deal-approval/`}
                          disabled={cycle.flaggedDeals < 1}
                        >
                          <InlineSVG
                            src={EyeIcon}
                            width={24}
                            height={24}
                            ariaHidden
                          />
                          <span className="text-base font-bold">
                            View Deal Approval
                          </span>
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* Total Channel Deals */}
                  {admin && (
                    <div
                      className={`rounded-2xl bg-white ${
                        cycle.headlineDeals > 0 > 0 ? "shadow" : ""
                      }`}
                    >
                      <div className="flex flex-col items-center justify-center px-8 py-12 text-center">
                        <p className="text-6xl font-bold">
                          <span
                            className={`${
                              cycle.headlineDeals > 0
                                ? "bg-gradient-to-r from-purple-gradient-start to-purple-gradient-end bg-clip-text text-transparent"
                                : "text-grey-300"
                            }`}
                          >
                            {cycle.totalBulkDeals.toLocaleString("en-ZA")}
                          </span>
                        </p>
                        <p
                          className={`mt-4 text-2xl leading-normal ${
                            cycle.headlineDeals > 0
                              ? "text-black"
                              : "text-grey-300"
                          }`}
                        >
                          Channel Deals
                        </p>
                      </div>
                      <div className="flex items-center justify-between gap-4 px-6 pb-6">
                        {!isTerminalsUser && (
                          <>
                            <Button
                              disableFocusRipple
                              className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                              LinkComponent={Link}
                              to={`/deal-cycle/${cycle.date}/channel-deals/`}
                              disabled={!headlinesPublished}
                            >
                              <InlineSVG
                                src={EyeIcon}
                                width={24}
                                height={24}
                                ariaHidden
                              />
                              <span className="text-base font-bold">
                                View Channel Deals
                              </span>
                            </Button>
                            {/* <Button
                            variant="contained"
                            disableFocusRipple
                            //disabled={cycle.totalBulkDeals < 1}
                            disabled={true}
                          >
                            Publish
                          </Button> */}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Ad-Hoc Deals */}
                  {admin && (
                    <div className={`rounded-2xl bg-white shadow`}>
                      <div className="flex flex-col items-center justify-center px-8 py-12 text-center">
                        <p className="text-6xl font-bold">
                          <span
                            className={`bg-gradient-to-br from-blue-gradient-start to-blue-gradient-end bg-clip-text text-transparent`}
                          >
                            {cycle.adhocDeals.toLocaleString("en-ZA")}
                          </span>
                        </p>
                        <p
                          className={`mt-4 text-2xl leading-normal text-black`}
                        >
                          Ad-Hoc Deals
                        </p>
                      </div>
                      <div className="flex items-center justify-between gap-4 px-6 pb-6">
                        {!isTerminalsUser && (
                          <Button
                            disableFocusRipple
                            className="flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                            LinkComponent={Link}
                            to={`/adhoc-deals`}
                          >
                            <InlineSVG
                              src={EyeIcon}
                              width={24}
                              height={24}
                              ariaHidden
                            />
                            <span className="text-base font-bold">
                              View Ad-Hoc Deals
                            </span>
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`flex flex-col gap-4 ${
                    admin
                      ? "col-span-8 col-start-4 xl:col-span-4 xl:col-start-auto"
                      : "hidden"
                  }`}
                >
                  {/* Total Deals per OEM */}
                  {admin && (
                    <div
                      className={`flex flex-col rounded-2xl bg-white py-8 ${
                        cycle.totalDealsOEM.length > 0
                          ? "shadow"
                          : "items-center justify-center py-32"
                      }`}
                    >
                      <h2
                        className={`bg-white px-8 pb-1 text-xl font-bold ${
                          cycle.totalDealsOEM.length > 0
                            ? "text-black"
                            : "text-grey-300"
                        }`}
                      >
                        Total Deals Per OEM
                      </h2>
                      <div className="scrollbar-rounded-lg max-h-[25rem] flex-1 overflow-y-auto px-8 scrollbar-thin scrollbar-track-grey-50 scrollbar-thumb-grey-100">
                        {cycle.totalDealsOEM.length > 0 ? (
                          cycle.totalDealsOEM.map((item, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between border-b border-solid border-grey-50 py-4 text-base"
                            >
                              <Link
                                className="text-blue-200 underline hover:text-blue-300"
                                to={`/deal-cycle/${cycle.date}/base-generic-deals/oem-selection/${item.oem}`}
                              >
                                {item.oem}
                              </Link>
                              <span className="text-black">
                                {item.total} Deals
                              </span>
                            </div>
                          ))
                        ) : (
                          <p className="py-4 text-base text-grey-300">
                            No deal information to display yet.
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Deal Cycle Documents */}
                  {admin && (
                    <div
                      className={`flex flex-col rounded-2xl bg-white py-8 ${
                        cycle.documents.length > 0
                          ? "shadow"
                          : "items-center justify-center py-32"
                      }`}
                    >
                      <h2
                        className={`bg-white px-8 pb-1 text-xl font-bold ${
                          cycle.documents.length > 0
                            ? "text-black"
                            : "text-grey-300"
                        }`}
                      >
                        Deal Cycle Documents
                      </h2>
                      <div className="scrollbar-rounded-lg max-h-[19rem] flex-1 overflow-y-auto px-8 scrollbar-thin scrollbar-track-grey-50 scrollbar-thumb-grey-100">
                        {cycle.documents.length > 0 ? (
                          cycle.documents.map((item, index) => (
                            <div
                              key={index}
                              className="border-b border-solid border-grey-50 py-4 text-base"
                            >
                              <Link
                                className="text-blue-200 underline hover:text-blue-300"
                                to={item.fileLink}
                                onClick={() => {
                                  handleDownload(item.docName, item.s3FileName);
                                }}
                              >
                                {item.docName}
                              </Link>
                            </div>
                          ))
                        ) : (
                          <p className="py-4 text-base text-grey-300">
                            No documents available for this deal cycle yet.
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-center">
                  <Lottie
                    animationData={AstronautAnimation}
                    aria-hidden="true"
                    role="presentation"
                    className="max-w-2xl"
                  />
                </div>

                <Dialog
                  fullScreen={fullScreen}
                  maxWidth="xs"
                  open={dialogCancelCycleOpen}
                  onClose={handleDialogClose}
                  aria-labelledby={uid}
                >
                  <DialogTitle id={uid}>
                    Cancel deal cycle creation?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      You are about to cancel the creation and configuration of
                      this deal cycle. Are you sure you want to continue?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="text"
                      disableFocusRipple
                      color="secondary"
                      onClick={() => {
                        handleDialogClose();
                        setDialogNewCycleOpen(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disableFocusRipple
                      autoFocus
                      onClick={handleDialogClose}
                    >
                      Yes, I'm sure
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  fullScreen={fullScreen}
                  maxWidth="xs"
                  open={dialogNewCycleOpen}
                  onClose={handleDialogClose}
                  aria-labelledby={uid}
                >
                  <DialogTitle id={uid}>Create new deal cycle</DialogTitle>
                  <DialogContent>
                    <DialogContentText className="mb-8">
                      To begin the deal cycle, please enter the deal cycle
                      period below, and make sure that the appropriate trade
                      list is selected.
                    </DialogContentText>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="col-span-1">
                        <CustomDatePicker label="From" />
                      </div>
                      <div className="col-span-1">
                        <CustomDatePicker label="To" />
                      </div>
                      <div className="col-span-2">
                        <InputField
                          select
                          inputLabel="Trade List"
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                            value: selectedOption(cycle.date, tradeList),
                          }}
                        >
                          {/* <MenuItem value="placeholder" disabled>
                          - Select -
                        </MenuItem> */}
                          {tradeList.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              disabled
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="text"
                      disableFocusRipple
                      color="secondary"
                      onClick={() => {
                        handleDialogClose();
                        setDialogCancelCycleOpen(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      autoFocus
                      disableFocusRipple
                      onClick={() => {
                        handleDialogClose();
                        handleCycleCreate(cycle.cycleId);
                      }}
                    >
                      Create new deal cycle
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}

            <Dialog
              fullScreen={fullScreen}
              maxWidth="xs"
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="delete-confirmation-dialog-title"
            >
              <DialogTitle id="delete-confirmation-dialog-title">
                Clear this deal cycle?
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  By clearing the deal cycle, you'll clear all deals, cycle
                  configs and trade list data for this cycle. This action cannot
                  be undone. Are you sure you want to continue?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  disableFocusRipple
                  color="secondary"
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  disableFocusRipple
                  onClick={() => {
                    handleClearDealCycle(cycle.cycleId);
                  }}
                >
                  Yes, I'm sure
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullScreen={fullScreen}
              maxWidth="xs"
              open={dialogGenericOpen}
              onClose={handleDialogGenericClose}
              aria-labelledby="generic-confirmation-dialog-title"
            >
              <DialogTitle id="generic-confirmation-dialog-title">
                {dialogGenericConfirmationTitle}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {dialogGenericMessageText}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="text"
                  disableFocusRipple
                  color="secondary"
                  onClick={handleDialogGenericClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  disableFocusRipple
                  onClick={() => {
                    handleDialogGenericAction(cycle.cycleId);
                  }}
                >
                  Yes, I'm sure
                </Button>
              </DialogActions>
            </Dialog>
          </TabPanel>
        );
      })}

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </section>
  );
}
