import axios from "axios";

const API_URL = "/api/jobs/";

const getAllJobs = () => {
  return axios.get(API_URL);
};

const cancelJob = (id) => {
  return axios.put(API_URL + id);
};

const JobService = {
  getAllJobs,
  cancelJob
};

export default JobService;
