// React and Mui Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../../components/back-button/back-button-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Icon Imports
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import TrashWhiteIcon from "../../../assets/icons/trash-white-icon.svg";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../../assets/icons/error-circle-no-fill-icon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import RoleService from "../../../services/role.service";
import AuthService from "../../../services/auth.service";

// Search bar for Role Management Table
const DataGridSearchBar = (props) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <InputField
      inputLabel="Search"
      value={searchText}
      onChange={handleSearch}
      placeholder="Search..."
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <InlineSVG
              src={SearchIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-black"
            />
          </InputAdornment>
        ),
      }}
    ></InputField>
  );
};

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

// Role Management Table Columns
const roleManagementColumns = [
  {
    field: "role",
    headerName: "Name",
    headerClassName: "text-base font-medium text-black",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => (
      <Link
        className="text-blue-200 underline focus-visible:outline-none hover:text-blue-300"
        to={`/settings/role-management/role/${params.row.id}`}
      >
        {params.value}
      </Link>
    ),
  },
];

// Role Management Page
export default function RoleManagementOverview() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(10);
  const [roleData, setRoleData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [deleteSnack, setDeleteSnack] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    RoleService.getAllRoles()
      .then((response) => {
        setRoleData(response.data);
        setFilteredRows(response.data);
      })
      .catch((error) => {
        handleServerError(error);
        alert(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setErrorDialogOpen(false);
  };

  const handleSnackClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteSnack(false);
  };

  const handleSearch = (searchText) => {
    setFilteredRows(
      roleData.filter((row) =>
        Object.values(row)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    );
  };

  const rolesTable = {
    rows: filteredRows,
    columns: [
      ...roleManagementColumns,
      {
        field: "actions",
        type: "actions",
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <InlineSVG src={TrashIcon} ariaHidden width={28} height={28} />
            }
            onClick={() => {
              params.row.users
                ? setErrorDialogOpen(true)
                : setDeleteDialogOpen(true);
            }}
            label="Delete this role"
          />,
        ],
      },
    ],
    pageSize: pageSize,
    rowsPerPageOptions: [10, 20, 30, 40, 50],
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
    disableSelectionOnClick: true,
    autoHeight: true,
    components: {
      NoRowsOverlay: () => {
        return (
          <div className="mt-10 text-center">
            <Typography>No roles found...</Typography>
          </div>
        );
      },
    },
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState(
    location.state && location.state.successSnackMessage
  );
  const [genericSnack, setGenericSnack] = useState(
    location.state && location.state.successSnack
  );
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#roleManagementTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title="Role Management"
          titleID="roleManagementTitle"
          button={
            <Button
              className="mt-6 lg:mt-0"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={PlusIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              LinkComponent={Link}
              to="new-role"
            >
              Add new role
            </Button>
          }
        />

        <div className="mt-8 mb-4">
          <DataGridSearchBar onChange={handleSearch} />
        </div>
        <DataGrid
          {...rolesTable}
          className="mb-12 rounded-2xl border-0 bg-white px-4 pt-8 pb-4 text-base text-black shadow sm:px-6 md:px-10"
        />
        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={deleteDialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="delete-confirmation-dialog-title"
        >
          <DialogTitle id="delete-confirmation-dialog-title">
            Delete this role?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this role cannot be undone. Are you sure you want to
              continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              autoFocus
              disableFocusRipple
              onClick={() => {
                handleDialogClose();
                setDeleteSnack(true);
              }}
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={errorDialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="error-dialog-title"
        >
          <DialogTitle id="error-dialog-title">
            Unable to delete this role
          </DialogTitle>
          <DialogContent className="pb-2">
            <DialogContentText>
              You cannot delete this role as there are currently active users
              associated with it.
            </DialogContentText>
          </DialogContent>
          <DialogActions className="pb-4">
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={deleteSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: "rounded-lg bg-grey-800",
          }}
          message={
            <div className="flex items-center gap-3">
              <img
                src={TrashWhiteIcon}
                alt="Trash icon"
                role="presentation"
                aria-hidden={true}
                width={28}
                height={28}
                className="mb-1"
              />
              <p className="mb-0 text-base text-white">
                The role has been deleted.
              </p>
            </div>
          }
          action={
            <Button
              variant="text"
              disableFocusRipple
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              onClick={handleSnackClose}
            >
              Dismiss
            </Button>
          }
        />

        <Snackbar
          open={genericSnack}
          autoHideDuration={6000}
          onClose={handleGenericSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: snackSettings[snackType].colorClass,
          }}
          message={
            <div className="flex items-center gap-3">
              <InlineSVG
                src={snackSettings[snackType].icon}
                ariaHidden
                width={28}
                height={28}
                className="fill-white"
              />
              <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
            </div>
          }
          action={
            <Button
              variant="text"
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              onClick={handleGenericSnackClose}
              disableFocusRipple
            >
              Dismiss
            </Button>
          }
        />
      </section>
    </>
  );
}
