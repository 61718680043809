import axios from "axios";

const API_URL = "/api/system-config/";

// Get Config Settings
const getConfigSettings = () => {
  return axios.get(API_URL + "config-settings");
};

// Update Config Settings
const updateConfigSettings = (config_settings) => {
  return axios.put(API_URL + "config-settings", {config_settings});
};

// Auto Logout
const autoLogout = () => {
  return axios.post("/api/auth/auto-logout");
};


const SystemConfigService = {
  getConfigSettings,
  updateConfigSettings,
  autoLogout,
};

export default SystemConfigService;
