// React Imports
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";
import { getCycleReviews, getCycleReviewsData } from "../../slices/cycleConfigCanvas";

// Services Imports
import AuthService from "../../services/auth.service";

// MUI Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import BackButton from "../../components/back-button/back-button-component";
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";

// Snack Settings
import { snackSettings } from "../home/home-page";

// Icon Imports
import TickCircle from "../../assets/icons/tick-circle-icon.svg";

// Tab Content Imports
import TariffReviewTabContent from "./tab-content/tariff-review-tab";
import StandardVASsReviewTabContent from "./tab-content/standard-vas-review-tab";
import ActiveVASsReviewTabContent from "./tab-content/active-vas-review-tab";
import DevicePerChannelReviewTabContent from "./tab-content/device-per-channel-review-tab";
import DealTypePerChannelReviewTabContent from "./tab-content/deal-per-channel-review-tab";
import RoutersReviewTabContent from "./tab-content/routers-review-tab";
import DealCycleParametersReview from "./tab-content/deal-cycle-parameters-review";

export default function DealCycleConfigurationReview() {
  const navigate = useNavigate();
  const { cycle } = useParams();
  const user = AuthService.getCurrentUser();

  // Data Source
  const data = useSelector(getCycleReviewsData); 

  // Redux dispatch
  const dispatch = useDispatch();

  // State
  const [editedData, setEditedData] = useState(data);
  const [viewConfigRev, setViewConfigRev] = useState(false);

  const fetchData = useCallback(() => {
    dispatch(getCycleReviews({ cycle }));
  }, [dispatch, cycle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data.length > 0) {
      setEditedData(data);

      const result = data.map(({ latest_approval_level }) => {
        return latest_approval_level;
      });

      const userRoles = user.user_roles;

      // Get the Approval Level of the user
      const userApvLvl = userRoles.filter(word => word.includes("approval_lvl"))[0].slice(-1);

      const doneReview = parseInt(userApvLvl) < result[0] ? true : false;
      setViewConfigRev(doneReview);
    }
  }, [data, editedData, user.user_roles])

  // Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  // Dialogs
  const [cancelWarningDialog, setCancelWarningDialog] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  if (editedData.length > 0) {
    return (
      <>
        {/* Back button */}
        <div className="col-start-1 col-end-13 row-start-1 mt-8 mb-4 px-6 sm:mb-6 sm:mt-12 md:mb-6 md:mt-16 md:px-12">
          <BackButton to="/home"></BackButton>
        </div>
  
        {/* Main Content */}
        <section
          aria-labelledby="#cycleConfigurationTitle"
          className="col-start-1 col-end-13 row-start-2 px-6 md:px-12"
        >
          {/* Page heading */}
          <h1
            id="cycleConfigurationTitle"
            className="mb-5 text-4xl font-bold text-black sm:text-5xl md:text-6xl"
          >
            {cycle} Deal Cycle
          </h1>
  
          <h2 className="text-lg text-black md:text-2xl">
            Deal Cycle Configuration Review
          </h2>
  
          {/* Configuration Review Options (Tabs) */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Configuration Options"
            className="my-8"
          >
            <Tab
              label="Deal Cycle Parameters"
              icon={
                ((editedData[0].reviewed === "Y" ? true : false) || viewConfigRev) ? (
                  <InlineSVG src={TickCircle} width={16} height={16} ariaHidden />
                ) : null
              }
              iconPosition="end"
              {...a11yProps(`deal-cycle-parameters`)}
              disableRipple
            />
            <Tab
              label="Tariffs"
              icon={
                ((editedData[5].reviewed === "Y" ? true : false) || viewConfigRev) ? (
                  <InlineSVG src={TickCircle} width={16} height={16} ariaHidden />
                ) : null
              }
              iconPosition="end"
              {...a11yProps(`tariffs-content`)}
              disableRipple
            />
            <Tab
              label="Standard VAS"
              icon={
                ((editedData[4].reviewed === "Y" ? true : false) || viewConfigRev) ? (
                  <InlineSVG src={TickCircle} width={16} height={16} ariaHidden />
                ) : null
              }
              iconPosition="end"
              {...a11yProps(`standard-vas-content`)}
              disableRipple
            />
            <Tab
              label="Active VASs"
              icon={<InlineSVG src={TickCircle} width={16} height={16} ariaHidden />}
              iconPosition="end"
              {...a11yProps(`active-vas-content`)}
              disableRipple
            />
            <Tab
              label="Device per Channel"
              icon={
                ((editedData[2].reviewed === "Y" ? true : false) || viewConfigRev) ? (
                  <InlineSVG src={TickCircle} width={16} height={16} ariaHidden />
                ) : null
              }
              iconPosition="end"
              {...a11yProps(`device-per-channel-content`)}
              disableRipple
            />
            <Tab
              label="Deal Type per Channel"
              icon={
                ((editedData[1].reviewed === "Y" ? true : false) || viewConfigRev) ? (
                  <InlineSVG src={TickCircle} width={16} height={16} ariaHidden />
                ) : null
              }
              iconPosition="end"
              {...a11yProps(`deal-type-per-channel-content`)}
              disableRipple
            />
            <Tab
              label="Routers"
              icon={
                ((editedData[3].reviewed === "Y" ? true : false) || viewConfigRev) ? (
                  <InlineSVG src={TickCircle} width={16} height={16} ariaHidden />
                ) : null
              }
              iconPosition="end"
              {...a11yProps(`routers-content`)}
              disableRipple
            />
          </Tabs>
  
          {/* Deal Cycle Parameters (Tab Content) */}
          <TabPanel value={tabValue} index={0} id={`deal-cycle-parameters`}>
            <DealCycleParametersReview
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setReviewState={editedData[0].reviewed === "Y" ? true : false}
              reviewStatus={viewConfigRev}
            />
          </TabPanel>
  
          {/* Tariff Options (Tab Content) */}
          <TabPanel value={tabValue} index={1} id={`tariff-content`}>
            <TariffReviewTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setReviewState={editedData[5].reviewed === "Y" ? true : false}
              reviewStatus={viewConfigRev}
            />
          </TabPanel>
  
          {/* Standard VASs Options (Tab Content) */}
          <TabPanel value={tabValue} index={2} id={`standard-vas-content`}>
            <StandardVASsReviewTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setReviewState={editedData[4].reviewed === "Y" ? true : false}
              reviewStatus={viewConfigRev}
            />
          </TabPanel>
  
          {/* Active VASs Options (Tab Content) */}
          <TabPanel value={tabValue} index={3} id={`active-vas-content`}>
            <ActiveVASsReviewTabContent />
          </TabPanel>
  
          {/* Device per Channel Options (Tab Content) */}
          <TabPanel value={tabValue} index={4} id={`device-per-channel-content`}>
            <DevicePerChannelReviewTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setReviewState={editedData[2].reviewed === "Y" ? true : false}
              reviewStatus={viewConfigRev}
            />
          </TabPanel>
  
          {/* Deal Type per Channel Options (Tab Content) */}
          <TabPanel
            value={tabValue}
            index={5}
            id={`deal-type-per-channel-content`}
          >
            <DealTypePerChannelReviewTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setReviewState={editedData[1].reviewed === "Y" ? true : false}
              reviewStatus={viewConfigRev}
            />
          </TabPanel>
  
          {/* Routers Options (Tab Content) */}
          <TabPanel value={tabValue} index={6} id={`routers-content`}>
            <RoutersReviewTabContent
              handleSnackOpen={handleSnackOpen}
              setReviewState={editedData[3].reviewed === "Y" ? true : false}
              reviewStatus={viewConfigRev}
            />
          </TabPanel>
        </section>
  
        <Snackbar
          open={genericSnack}
          autoHideDuration={6000}
          onClose={handleGenericSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: snackSettings[snackType].colorClass,
          }}
          message={
            <div className="flex items-center gap-3">
              <InlineSVG
                src={snackSettings[snackType].icon}
                ariaHidden
                width={28}
                height={28}
                className="fill-white"
              />
              <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
            </div>
          }
          action={
            <Button
              variant="text"
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              onClick={handleGenericSnackClose}
              disableFocusRipple
            >
              Dismiss
            </Button>
          }
        />
  
        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={cancelWarningDialog}
          onClose={() => setCancelWarningDialog(false)}
          aria-labelledby="remove-tariff-warning-title"
        >
          <DialogTitle id="remove-tariff-warning-title">
            Cancel configuration review?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to cancel deal cycle configuration review, all changes
              will be lost. Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={() => setCancelWarningDialog(false)}
            >
              No, take me back
            </Button>
            <Button
              variant="contained"
              autoFocus
              disableFocusRipple
              onClick={() => {
                navigate("/home");
              }}
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
