import axios from "axios";

const API_URL = "/api/auth/";

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.email) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = (redirectPath = "/logout") => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "logout", { redirectPath }).then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserAPI = () => {
  return axios.get(API_URL + "currentuser").then((response) => {
    const { currentUser } = response.data;
    if (currentUser !== null) {
      localStorage.setItem("user", JSON.stringify(currentUser));
    }
    return response.data;
  });
};

const getLogoutRedirectURL = () => {
  return axios.get(API_URL + "logout-redirect-url").then((response) => {
    return response.data;
  });
};

// Validate Session
const validateSession = (sessionKey) => {
  return axios.post("/api/session/validate", { sessionKey });
};

// Terminate Session
const terminateSession = (sessionKey) => {
  return axios.post("/api/session/terminate", { sessionKey });
};

// Restore Session
const restoreSession = (sessionKey) => {
  return axios.post("/api/session/restore", { sessionKey });
};

// Terminate Session
const resetSession = () => {
  return axios.post("/api/session/reset");
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
  getCurrentUserAPI,
  getLogoutRedirectURL,
  validateSession,
  terminateSession,
  restoreSession,
  resetSession,
};

export default AuthService;
