// React and MUI Imports
import { IconButton } from "@mui/material";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

// Icon Imports
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useDispatch } from "react-redux";
import { setUploadFile, clearUploadFile } from "../../slices/upload";

export default function DragAndDropArea() {
  // Drag state
  const [dragActive, setDragActive] = useState(false);
  // Ref
  const inputRef = useRef(null);
  // File state
  const [file, setFile] = useState([]);
  // Show file name state
  const [showName, setShowName] = useState(false);
  // Show file type error state
  const [showTypeError, setShowTypeError] = useState(false);

  const dispatch = useDispatch();

  // Handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // Triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const fileSelected = e.dataTransfer.files[0];
      const extension = fileSelected.name.split(".").pop().toLowerCase();
      if (extension === "xls" || extension === "csv" || extension === "xlsx") {
        setFile(fileSelected);
        setShowName(true);
        setShowTypeError(false);

        // Add additional functionality to store the file somewhere
        // until the "upload" button is triggered

        const formData = new FormData();
        formData.append("file", fileSelected);
        dispatch(setUploadFile(formData));
      } else {
        setShowTypeError(true);
      }
    }
  };

  // Triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const fileSelected = e.target.files[0];
      const extension = fileSelected.name.split(".").pop().toLowerCase();
      if (extension === "xls" || extension === "csv" || extension === "xlsx" || extension === "xlsb") {
        setFile(fileSelected);
        setShowName(true);
        setShowTypeError(false);

        // Add additional functionality to store the file somewhere
        // until the "upload" button is triggered
        const formData = new FormData();
        formData.append("file", fileSelected);
        dispatch(setUploadFile(formData));
      } else {
        setShowTypeError(true);
      }
    }
  };

  // Triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  // Clear the file state
  const clearFileUpload = () => {
    setFile([]);
    dispatch(clearUploadFile());
    setShowName(false);
  };

  return (
    <>
      <form
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
        className="relative"
      >
        <input
          ref={inputRef}
          type="file"
          id="input-file-upload"
          multiple={true}
          onChange={handleChange}
          className="hidden"
          disabled={showName}
        />
        <label htmlFor="input-file-upload">
          <div
            className={`flex w-full justify-center gap-1 rounded-lg border border-dashed border-grey-300 p-5 text-center ${
              dragActive ? "bg-grey-50" : "bg-white"
            } `}
          >
            {!showName && (
              <>
                <p>Drag and drop or</p>
                <Link
                  className="text-blue-200 underline hover:text-blue-300"
                  onClick={onButtonClick}
                >
                  browse
                </Link>
              </>
            )}

            {showName && (
              <div className="-my-3 flex w-full items-center justify-between">
                <p className="text-base">{file.name}</p>
                <IconButton
                  aria-label="Remove selected document"
                  className="text-black hover:bg-white hover:text-red-100"
                  onClick={clearFileUpload}
                >
                  <CloseRoundedIcon fontSize="large" />
                </IconButton>
              </div>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            className="absolute top-0 left-0 h-full w-full"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
      <p
        className={`mt-3 text-center text-sm ${
          showTypeError ? "font-bold text-red-100" : "text-black"
        }`}
      >
        You can only upload files in the formats .xls, .xlsx, .xlsb, and .csv
      </p>
    </>
  );
}
