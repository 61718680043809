// React, Hook Forms and Mui Imports
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Slide, Snackbar } from "@mui/material";
import { useState, useEffect } from "react";

// Service Imports
import { socket } from "../../../App";
import AuthService from "../../../services/auth.service";

// Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Snackbar Settings
import { snackSettings } from "../../home/home-page";

// Icon Imports
import EyeIcon from "../../../assets/icons/eye-icon.svg";
import TickCircleOutlineIcon from "../../../assets/icons/tick-circle-outline-icon.svg";
import TickCircleIcon from "../../../assets/icons/tick-circle-icon.svg";
import ChannelService from "../../../services/channel.service";
import { useDispatch } from "react-redux";
import { clearAllChannelDeals } from "../../../slices/dealCanvas";

export default function ChannelDealsOverview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cycle } = useParams();
  const user = AuthService.getCurrentUser();

  if (user === null) {
    AuthService.logout();
    navigate("/logout");
  }

  const [channels, setChannels] = useState([]);
  
  useEffect(() => {
    ChannelService.getChannelDealsTotals(cycle)
      .then((response) => {
        setChannels(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle set loading state
  const handleLoadingStateChange = (channel_id) => {
    const updatedChannels = channels.map((item) => {
      if (item.channel_id === channel_id) {
        return { ...item, loading: !item.loading };
      }
      return item;
    });
    setChannels(updatedChannels);
  };

  useEffect(() => {
    socket.on("system-action", handleSystemAction);
    return () => {
      socket.off("system-action");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSystemAction = ({ action }) => {
    if (action === "refresh-channel") {
      ChannelService.getChannelDealsTotals(cycle)
        .then((response) => {
          setChannels(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleGenerateDeals = (data, channel_id) => {
    handleLoadingStateChange(channel_id);
    ChannelService.generateChannelDeals(data)
      .then((response) => {
        const { message } = response;
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGenerateChannelODBC = (data) => {
    ChannelService.generateChannelODBC(data)
      .then((response) => {
        const { message } = response;
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePublishDeals = (data) => {
    ChannelService.publishChannelDeals({
      cycle_id: data.cycle_id,
      channel_id: data.channel_id,
    })
      .then((response) => {
        const { message } = response.data;
        const updatedChannels = channels.map((item) => {
          if (item.channel_id === data.channel_id) {
            return { ...item, readyToPublish: false };
          }
          return item;
        });
        setChannels(updatedChannels);
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };  

  const handleClearChannel = () => {
    dispatch(clearAllChannelDeals());
  };

  const handleSubmitDealsForApproval = (cycle_id, channel_id, channel_name) => {
    const updatedChannels = channels.map((item) => {
      if (item.channel_id === channel_id) {
        return { ...item, submitForApproval: false };
      }
      return item;
    });
    setChannels(updatedChannels);
    
    ChannelService.submitForApproval(cycle_id, channel_id, channel_name)
      .then(() => {
        handleGenericSnackOpen("success", `${channel_name} deals has been successfully submitted for approval!`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>

      <section
        aria-labelledby="#channelDealsTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb title="Channel Deals" titleID="channelDealsTitle" />

        <div className="col-span-10 col-start-2 row-start-3 mt-8 mb-12 grid grid-cols-1 gap-4 md:grid-cols-2">
          {channels.map((card, index) => (
            <article
              className={`col-span-1 rounded-2xl bg-white p-8 ${
                card.totalDeals > 0 ? "shadow" : ""
              }`}
              key={index}
            >
              <section>
                <p
                  className={`text-center text-base font-bold ${
                    card.totalDeals > 0 || card.loading
                      ? "text-black"
                      : "text-grey-300"
                  }`}
                >
                  {card.channel}
                </p>

                {card.loading ? (
                  <div className="mt-10 mb-9 flex items-center justify-center">
                    {/* Spinning loading indicator */}
                    <div
                      className="relative h-[120px] w-[120px] animate-spin rounded-full duration-700 after:absolute after:left-1/2 after:top-1/2 after:h-[100px] after:w-[100px] after:-translate-y-1/2 after:-translate-x-1/2 after:rounded-full after:bg-white"
                      style={{
                        background:
                          "conic-gradient(from 180deg at 50% 50%, rgba(230, 0, 0, 1) 0deg, rgba(230, 0, 0, 0.00) 360deg)",
                      }}
                    ></div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center py-12">
                    <p className="text-6xl font-bold">
                      <span
                        className={
                          card.totalDeals > 0
                            ? "bg-gradient-to-r from-red-gradient-start to-red-gradient-end bg-clip-text text-transparent"
                            : "text-grey-300"
                        }
                      >
                        {card.totalDeals.toLocaleString("en-ZA")}
                      </span>
                    </p>
                    <h2
                      className={`mt-2 text-2xl leading-normal ${
                        card.totalDeals > 0 ? "text-black" : "text-grey-300"
                      }`}
                    >
                      Total Deals
                    </h2>
                  </div>
                )}
              </section>

              <footer className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-center">
                {card.totalDeals > 0 ? (
                  <>
                    <Button
                      disableFocusRipple
                      className="mr-auto flex items-center justify-center gap-2 fill-red-100 py-4 text-black hover:text-grey-700"
                      LinkComponent={Link}
                      to={`/deal-cycle/${cycle}/channel-deals/${card.channel}/${card.channel_id}`}
                      onClick={handleClearChannel}
                    >
                      <InlineSVG
                        src={EyeIcon}
                        width={24}
                        height={24}
                        ariaHidden
                      />
                      <span className="text-base font-bold">
                        View {card.channel}
                      </span>
                    </Button>

                    {(card.submitForApproval) ? (   
                      <Button
                        variant={
                          card.submitForApproval ? "contained" : "outlined"
                        }
                        disableFocusRipple
                        disabled={!card.submitForApproval}
                        onClick={() =>handleSubmitDealsForApproval(card.cycle_id, card.channel_id, card.channel)}
                      >
                        <span>Submit for Approval</span>
                      </Button>
                    ) : (card.readyToGenerateODBC) ? (
                      <div className="flex items-center gap-2">
                        <InlineSVG
                          src={TickCircleIcon}
                          width={24}
                          height={24}
                          ariaHidden
                          className="fill-green-500"
                        />
                        <span>All Approved</span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <InlineSVG
                          src={TickCircleOutlineIcon}
                          width={24}
                          height={24}
                          ariaHidden
                          className="fill-green-500"
                        />
                        <span>Submitted</span>
                      </div>
                    )}
                    
                    <Button
                      variant="contained"
                      disableFocusRipple
                      onClick={() => handleGenerateChannelODBC(card)}
                      disabled={!card.readyToGenerateODBC}
                    >
                      Generate ODBC
                    </Button>
                    <Button
                      variant="contained"
                      disableFocusRipple
                      disabled={!card.readyToPublish}
                      onClick={() => handlePublishDeals(card)}
                    >
                      Load
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    disableFocusRipple
                    onClick={() => handleGenerateDeals(card, card.channel_id)}
                    disabled={card.loading}
                  >
                    Generate Deals
                  </Button>
                )}
              </footer>
            </article>
          ))}
        </div>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
