// React Imports
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Redux Store Imports
import {
  getStandardVASs24Data,
  getStandardVASs24,
  getStandardVASs36Data,
  getStandardVASs36,
  updateStandardVASData,
  updateReviewState,
} from "../../../slices/cycleConfigCanvas";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";

// MUI Imports
import {
  Alert,
  Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Collapse,
  // useMediaQuery,
  IconButton,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import TabPanel from "../../../components/tab-panel/tab-panel-component";
import CloseIcon from "@mui/icons-material/Close";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Service Imports
// import DealService from "../../../services/deal.service";
import CycleConfigService from "../../../services/cycleConfig.service";
import AuthService from "../../../services/auth.service";

// Sorting Options
const sortingOptions = [
  {
    value: "asc",
    label: "A - Z",
  },
  {
    value: "desc",
    label: "Z - A",
  },
];

// Filter Options
const filterByOptions = [
  {
    heading: "Category",
    options: [
      {
        value: "Red Flexi",
        label: "Red Flexi",
      },
      {
        value: "RED",
        label: "RED",
      },
    ],
  },
  {
    heading: "Type",
    options: [
      {
        value: "V",
        label: "Voice",
      },
      {
        value: "D",
        label: "Data",
      },
    ],
  },
];

// Standard VASs Table Head Cells
const standardVASsTableHead = [
  {
    id: "vas1",
    label: "VAS 1",
  },
  {
    id: "vas2",
    label: "VAS 2",
  },
  {
    id: "vas3",
    label: "VAS 3",
  },
  {
    id: "vas4",
    label: "VAS 4",
  },
  {
    id: "vas5",
    label: "VAS 5",
  },
];

export default function StandardVASsTabContent({
  handleSnackOpen,
  inReview,
  reviewFeedback,
  setCancelWarningDialog,
}) {
  const navigate = useNavigate();
  const { cycle } = useParams();

  // Redux dispatch hook
  const dispatch = useDispatch();

  // On Submit function to trigger tab change and success snackbar popup
  const onSubmit = () => {
    // Filter only the edited routers
    const standardVAS24Data = filteredVAS24Data.filter(
      (tariff) => tariff.isChanged && tariff.term === 24
    );
    const standardVAS36Data = filteredVAS36Data.filter(
      (tariff) => tariff.isChanged && tariff.term === 36
    );

    const allStandardVASs = standardVAS24Data.concat(standardVAS36Data);

    const newStandardVASData = [allStandardVASs, [vasOptions24[0].options, vasOptions36[0].options, vasOptions24[1].options]];

    if (newStandardVASData[0].length > 0) {
      CycleConfigService.updateStandardVAS(cycle, newStandardVASData)
        .then((response) => {
          const { message } = response.data;
          handleSnackOpen("success", message);
        })
        .catch((error) => {
          console.log(error);
          handleServerError(error);
        });
    } else {
      handleSnackOpen("notFound", "No changes made.");
    }
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleSnackOpen("error", errors[0].message);
    }
  };

  // Set DATA sources
  const VAS24Data = useSelector(getStandardVASs24Data);
  const VAS36Data = useSelector(getStandardVASs36Data);

  // VAS 36 States
  const [VAS36_searchText, setVAS36_searchText] = useState("");
  const [VAS36_filterBy, setVAS36_filterBy] = useState([]);
  const [VAS36_sortOrder, setVAS36_sortOrder] = useState("");
  const [filteredVAS36Data, setFilteredVAS36Data] = useState(VAS36Data);

  // VAS 24 States
  const [VAS24_searchText, setVAS24_searchText] = useState("");
  const [VAS24_filterBy, setVAS24_filterBy] = useState([]);
  const [VAS24_sortOrder, setVAS24_sortOrder] = useState("");
  const [filteredVAS24Data, setFilteredVAS24Data] = useState(VAS24Data);

  const [setUnresolvedWarningDialog] = useState(false);
  // const fullScreen = useMediaQuery("(max-width: 640px)");

  const [vasOptions24, setVASOptions24] = useState([]);
  const [vasOptions36, setVASOptions36] = useState([]);
  const [activeVASOptions, setActiveVASOptions] = useState([]);

  // Form Submission hook from react-hook-form
  const { handleSubmit } = useForm();

  // Get VAS list
  useEffect(() => {
    const nullOption = [{ value: null, label: "None" }];

    CycleConfigService.getActiveVASs(cycle).then((response) => {
      const { data } = response;

      const editedActiveOptions = [{ id: null, value: null, description: "None" }].concat(data);

      CycleConfigService.getVas().then((response) => {
        const { data } = response;
  
        const editedOptions24 = nullOption.concat(data[0]);
        const editedOptions36 = nullOption.concat(data[1]);

        const vas24 = [
          {
            heading: "24 Months VAS",
            options: editedOptions24,
          },
          {
            heading: "Active VAS",
            options: editedActiveOptions,
          },
        ];

        const vas36 = [
          {
            heading: "26 Months VAS",
            options: editedOptions36,
          },
          {
            heading: "Active VAS",
            options: editedActiveOptions,
          },
        ];
  
        setVASOptions24(vas24);
        setVASOptions36(vas36);
      });
    });

    dispatch(getStandardVASs24({ cycle }));
    dispatch(getStandardVASs36({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [rowsPerPage36, setRowsPerPage36] = useState(-1);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);
  const [rowsPerPageOptions36, setRowsPerPageOptions36] = useState([]);

  // Function to check if VAS is only selected once
  const vasExists = (obj, searchString) => {
    if (searchString === null) {
      return false;
    }
    for (const key in obj) {
      if (typeof obj[key] === "string" && obj[key].includes(searchString)) {
        handleSnackOpen("warning", `VAS Option has already been selected.`);
        return true;
      }
    }
    return false;
  };

  // Handle Dropdown Selection
  const handleVasChange = (event, tariffId, term) => {
    const { name, value } = event.target;

    // let edit;
    // let setEdit;
    let object;

    if (term === 24) {
      // edit = filteredVAS24Data;
      // setEdit = setFilteredVAS24Data;
      object = "standardVASs24Data";
    } else {
      // edit = filteredVAS36Data;
      // setEdit = setFilteredVAS36Data;
      object = "standardVASs36Data";
    }

    // const updatedStandardVASData = edit.map((tariff) => {
    //   if (tariff.id === tariffId && tariff.term === term) {
    //     if (vasExists(tariff, value) === false) {
    //       if (tariff[name] !== value) {
    //         return {
    //           ...tariff,
    //           [name]: value,
    //           isChanged: true,
    //         };
    //       }
    //     }
    //     return tariff;
    //   } else {
    //     return tariff;
    //   }
    // });

    // setEdit(updatedStandardVASData);

    dispatch(
      updateStandardVASData({
        data: {
          name,
          term,
          value,
          tariffId,
          vasExists,
        },
        object,
      })
    );
  };

  // Handle change table page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setRowsPerPage36(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Update vas 24 data as it changes
  useEffect(() => {
    let updatedVAS24Data = VAS24Data;

    // Filter data by search text
    if (VAS24_searchText) {
      updatedVAS24Data = VAS24Data.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const values = Object.values(item.plan)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          values.toLowerCase().indexOf(VAS24_searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter data by selected filters
    if (VAS24_filterBy.length > 0) {
      updatedVAS24Data = updatedVAS24Data.filter((tariff) => {
        // Extract plan and type properties
        const { plan, type } = tariff;

        // Combine plan and type properties into an array
        const tariffValues = [plan, type];

        // Iterate over filterBy options and check if any row values contain the option
        for (let option of VAS24_filterBy) {
          if (
            tariffValues[0].includes(option) ||
            (tariffValues[1] === "D" ? "Data" : "Voice") === option
          ) {
            return true;
          }
        }
        return false;
      });
    }

    // Set rows per page
    const rowsLen = updatedVAS24Data.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptions([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPage(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPage(rows);
    }

    setFilteredVAS24Data(updatedVAS24Data);
  }, [VAS24_searchText, VAS24_filterBy, VAS24Data]);

  // Update vas 36 data as it changes
  useEffect(() => {
    let updatedVAS36Data = VAS36Data;

    // Filter data by search text
    if (VAS36_searchText) {
      updatedVAS36Data = VAS36Data.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const values = Object.values(item.plan)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          values.toLowerCase().indexOf(VAS36_searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter data by selected filters
    if (VAS36_filterBy.length > 0) {
      updatedVAS36Data = updatedVAS36Data.filter((tariff) => {
        // Extract plan and type properties
        const { plan, type } = tariff;

        // Combine plan and type properties into an array
        const tariffValues = [plan, type];
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of VAS36_filterBy) {
          if (
            tariffValues[0].includes(option) ||
            (tariffValues[1] === "D" ? "Data" : "Voice") === option
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredVAS36Data(updatedVAS36Data);

    // Set rows per page
    const rowsLen = updatedVAS36Data.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptions36([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPage36(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPage36(rows);
    }
  }, [VAS36_searchText, VAS36_filterBy, VAS36Data]);

  // Handle search VAS24 filter via search input field
  const handleVAS24Search = (event) => {
    const { value } = event.target;
    setVAS24_searchText(value);
  };

  // Handle search VAS36 filter via search input field
  const handleVAS36Search = (event) => {
    const { value } = event.target;
    setVAS36_searchText(value);
  };

  // Handle VAS24 filter by option change
  const handleVAS24FilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setVAS24_filterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setVAS24_filterBy(filteredOptions);
  };

  // Handle VAS36 filter by option change
  const handleVAS36FilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setVAS36_filterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setVAS36_filterBy(filteredOptions);
  };

  // Handle VAS24 sorting order change
  const handleVAS24Sort = (event) => {
    setVAS24_sortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = [...filteredVAS24Data].sort((a, b) =>
          a.plan.localeCompare(b.plan)
        );
        break;
      case "desc":
        sortedData = [...filteredVAS24Data].sort((a, b) =>
          b.plan.localeCompare(a.plan)
        );
        break;
      default:
        sortedData = filteredVAS24Data;
        break;
    }

    setFilteredVAS24Data(sortedData);
  };

  // // Handle VAS36 sorting order change
  const handleVAS36Sort = (event) => {
    setVAS36_sortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = [...filteredVAS36Data].sort((a, b) =>
          a.plan.localeCompare(b.plan)
        );
        break;
      case "desc":
        sortedData = [...filteredVAS36Data].sort((a, b) =>
          b.plan.localeCompare(a.plan)
        );
        break;
      default:
        sortedData = filteredVAS36Data;
        break;
    }

    setFilteredVAS36Data(sortedData);
  };

  // Term selection states and function
  const [term, setTerm] = useState(0);
  const handleTermChange = (_, term) => {
    setTerm(term);
  };

  // Handle dialog Open for Unresolved feedback warning
  const handleUnresolvedDialogOpen = () => {
    setUnresolvedWarningDialog(true);
  };


  // Alert state
  const [showAlert, setShowAlert] = useState(inReview === "R" ? true : false);


  // handle resolve feedback
  const handleResolveFeedback = () => {
    
    dispatch(
      updateReviewState({
        data: {
          config_status: "P",
          reviewed: "N",
          reason_rejected: null,
          config_set: "STANDARD_VAS",
          cycle: cycle,
        },
        object: "cycleReviewsData",
      })
    );

    setShowAlert(false);
    handleSnackOpen("success", "Standard VAS feedback has been resolved.");
  };


  // handle form submission with warning feedback if unresolved feedback
  const handleFormSubmission = (e) => {
    e.preventDefault();
    (inReview === "R" ? true : false)
      ? handleUnresolvedDialogOpen()
      : handleSubmit(onSubmit)();
  };

  return (
    <>
      <Collapse in={showAlert}>
        <Alert
          className="mb-8 items-center gap-4 rounded-lg p-6"
          severity="warning"
          iconMapping={{
            warning: (
              <InlineSVG src={WarningIcon} ariaHidden width={24} height={24} />
            ),
          }}
          action={
            <IconButton
              aria-label="Close Alert"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          <h2 className="mb-1 text-lg font-bold">Rejected</h2>
          <p className="mb-6">{reviewFeedback}</p>
          <Link onClick={handleResolveFeedback} className="underline">
            Resolve
          </Link>
        </Alert>
      </Collapse>

      <div className="mb-8 text-center">
        <ToggleButtonGroup
          value={term}
          exclusive
          onChange={handleTermChange}
          aria-label="Term selection"
        >
          <ToggleButton value={0}>24 Months</ToggleButton>
          <ToggleButton value={1}>36 Months</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TabPanel value={term} index={0}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={VAS24_searchText}
                onChange={handleVAS24Search}
                placeholder="Search by tariff name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: VAS24_sortOrder || "",
                  onChange: handleVAS24Sort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={VAS24_filterBy}
                onChange={handleVAS24FilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={VAS24_filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        <form onSubmit={handleFormSubmission}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="w-[10%] border-0 py-0 px-2"></TableCell>
                {standardVASsTableHead.map((headCell) => (
                  <TableCell
                    className="border-0 py-0 px-2 text-base"
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVAS24Data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={standardVASsTableHead.length + 1}
                    className="border-0 py-8 text-center text-base text-black"
                  >
                    No tariffs found...
                  </TableCell>
                </TableRow>
              ) : (
                filteredVAS24Data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="border-0 p-2 pl-0">
                        <p className="text-base font-bold">{item.plan}</p>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas1"}
                          name="vas1"
                          value={item.vas1}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions24.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas2"}
                          name="vas2"
                          value={item.vas2 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions24.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas3"}
                          name="vas3"
                          value={item.vas3 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions24.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas4"}
                          name="vas4"
                          value={item.vas4 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions24.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas5"}
                          name="vas5"
                          value={item.vas5 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions24.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={filteredVAS24Data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </form>
      </TabPanel>

      <TabPanel value={term} index={1}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={VAS36_searchText}
                onChange={handleVAS36Search}
                placeholder="Search by tariff name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: VAS36_sortOrder || "",
                  onChange: handleVAS36Sort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={VAS36_filterBy}
                onChange={handleVAS36FilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={VAS36_filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            {/* <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={VAS36_filterBy}
                onChange={handleVAS36FilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={VAS24_filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div> */}
          </div>
        </div>

        <form onSubmit={handleFormSubmission}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="w-[10%] border-0 py-0 px-2"></TableCell>
                {standardVASsTableHead.map((headCell) => (
                  <TableCell
                    className="border-0 py-0 px-2 text-base"
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVAS36Data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={standardVASsTableHead.length + 1}
                    className="border-0 py-8 text-center text-base text-black"
                  >
                    No tariffs found...
                  </TableCell>
                </TableRow>
              ) : (
                filteredVAS36Data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="border-0 p-2 pl-0">
                        <p className="text-base font-bold">{item.plan}</p>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas1"}
                          name="vas1"
                          value={item.vas1 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions36.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas2"}
                          name="vas2"
                          value={item.vas2 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions36.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas3"}
                          name="vas3"
                          value={item.vas3 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions36.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas4"}
                          name="vas4"
                          value={item.vas4 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions36.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                      <TableCell className="w-[18%] border-0 p-2">
                        <InputField
                          select
                          id={item.id + "vas5"}
                          name="vas5"
                          value={item.vas5 || "placeholder"}
                          SelectProps={{
                            IconComponent: (props) => (
                              <ArrowDropDownRoundedIcon {...props} />
                            ),
                          }}
                          onChange={(e) =>
                            handleVasChange(e, item.id, item.term)
                          }
                        >
                          <MenuItem disabled value="placeholder">
                            - Select -
                          </MenuItem>
                          {vasOptions36.map((vas) => [
                            <ListSubheader
                              key={vas.value}
                              className="mx-0 my-3 border-b border-solid border-grey-50 px-2 p-2 text-base font-bold text-black"
                            >
                              {vas.heading}
                            </ListSubheader>,
                            ...vas.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label || option.description} />
                              </MenuItem>
                            )),
                          ])}
                        </InputField>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions36}
                  count={filteredVAS36Data.length}
                  rowsPerPage={rowsPerPage36}
                  page={page}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </form>
      </TabPanel>

      <div className="mb-12 flex justify-center gap-2 md:justify-end">
        <Button
          variant="text"
          disableFocusRipple
          color="secondary"
          onClick={() => setCancelWarningDialog(true)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableFocusRipple
          // type="submit"
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
}