import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SystemConfigService from "../../services/settingsConfig.service";
import { getConfigSettings } from "../../slices/system-config-canvas";
import AuthService from "../../services/auth.service";
import axios from "axios";

const AutoLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux selector to get config settings
  const configSettings = useSelector(
    (state) => state?.systemConfig?.configSettings
  );
  const userIsLoggedIn = useSelector((state) => state?.auth.isLoggedIn);

  const [timeoutDuration, setTimeoutDuration] = useState(60 * 15 * 1000); // Default: 15 minutes in milliseconds
  const [timeLeft, setTimeLeft] = useState(timeoutDuration);
  const timerRef = useRef(null);

  // Fetch config settings when the component mounts
  useEffect(() => {
    dispatch(getConfigSettings());
  }, [dispatch]);

  // Update timeoutDuration whenever configSettings change
  useEffect(() => {
    let newTimeoutDuration = timeoutDuration; // Default to 15 minutes if config value is 0 or undefined
    if (configSettings && configSettings.length > 0) {
      if (configSettings[0].value > 0) {
        newTimeoutDuration = 60 * configSettings[0].value * 1000;
      }
      setTimeoutDuration(newTimeoutDuration);
      resetTimer(newTimeoutDuration); // Reset timer with new duration
    }
  }, [configSettings]);

  const logout = () => {
    // Perform logout actions
    window.location.href = "/inactive";
  };

  // Function to reset the timer with the given duration
  const resetTimer = (duration) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the existing timer
    }
    setTimeLeft(duration); // Reset time left

    // Start a new timer for auto logout with the given duration
    timerRef.current = setTimeout(() => {
      logout();
    }, duration);
  };

  // Reset the timer on component mount and user activity
  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "scroll"];

    // Reset timer on any user interaction
    const resetTimerOnActivity = () => {
      resetTimer(timeoutDuration); // Reset timer with the current timeoutDuration
    };

    // Add event listeners for detecting user activity
    events.forEach((event) =>
      window.addEventListener(event, resetTimerOnActivity)
    );

    // Start the initial timer
    resetTimer(timeoutDuration); // Start the timer with the initial duration

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimerOnActivity)
      );
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [timeoutDuration]); // Ensure to reset the timer with the current timeoutDuration

  // Countdown logic
  useEffect(() => {
    if (userIsLoggedIn) {
      if (timeLeft > 0) {
        const countdown = setInterval(() => {
          setTimeLeft((prev) => prev - 1000); // Decrement by 1000ms (1 second)
        }, 1000);

        return () => clearInterval(countdown); // Clear interval on unmount or when timeLeft changes
      } else {
        logout(); // Trigger logout when countdown reaches zero
      }
    }
  }, [timeLeft, userIsLoggedIn]);

  useEffect(() => {
    // Function to handle popstate event and redirect
    const handlePopState = () => {
      if (window.location.pathname !== "/inactive") {
        navigate("/inactive", { replace: true });
      }
    };

    // Listen for history changes
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return null;
};

export default AutoLogout;
