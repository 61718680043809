import { SpeedDial, SpeedDialAction } from "@mui/material";
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import PlusIcon from "../../assets/icons/plus-icon.svg";
import { useState } from "react";

export default function FAB({ actions }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <SpeedDial
      ariaLabel="Open more options"
      sx={{ position: "fixed", bottom: 30, right: 40 }}
      icon={
        <InlineSVG
          src={PlusIcon}
          width={32}
          height={32}
          ariaHidden
          className="fill-white"
        />
      }
      direction="left"
      onClick={handleOpen}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          onClick={action?.onClick}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
        />
      ))}
    </SpeedDial>
  );
}
