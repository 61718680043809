// React and Mui Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Service Imports
import { socket } from "../../App";
import AuthService from "../../services/auth.service";
import UploadService from "../../services/upload.service";
import DealService from "../../services/deal.service";
import DownloadService from "../../services/download.service";

// Component Imports
import InputField from "../../components/input/input-component";
import TitleBreadcrumb from "../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../components/back-button/back-button-component";
import DragAndDropArea from "../../components/drag-and-drop/drag-and-drop-component";
import CustomCheckbox from "../../components/checkbox/checkbox-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import { CustomTabScrollButton } from "../home/home-page";

// Mock Data Import
import { documentFormats, documentTypes } from "../../data/mock-data";

// Icon Imports
import TrashIcon from "../../assets/icons/trash-icon.svg";
import TrashWhiteIcon from "../../assets/icons/trash-white-icon.svg";
import TickOutlineCircle from "../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../assets/icons/error-circle-no-fill-icon.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";

// File Format Image Imports
import XLSIcon from "../../assets/file-types/xls-file-format.svg";
import {
  getCycleId,
  getCycleTab,
  setCycleId,
  setCycleTab,
} from "../../slices/dealCanvas";

// Documents Table Columns
const documentsColumns = [
  {
    field: "format",
    headerName: "Format",
    headerClassName: "text-base font-medium text-black",
    flex: 0.25,
    minWidth: 80,
    renderCell: (params) => {
      if (params.row.format === "xls")
        return <img src={XLSIcon} alt="XLS Format" width={26} height={28} />;
    },
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "text-base font-medium text-black",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "type",
    headerName: "Type",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: "date",
    headerName: "Date",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: "uploaded_by",
    headerName: "Uploaded By",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
  },
];

export const cycleDates = (() => {
  const currentDate = new Date();
  const dates = [];
  
  // Generate past 6 months
  for (let i = 6; i > 0; i--) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
    dates.push({
      value: `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}`,
      label: `${new Intl.DateTimeFormat("en-US", { month: "long", year: "numeric" }).format(date)}`,
    });
  }
  
  // Generate 12 future months
  for (let i = 0; i < 13; i++) {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
    dates.push({
      value: `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}`,
      label: `${new Intl.DateTimeFormat("en-US", { month: "long", year: "numeric" }).format(date)}`,
    });
  }

  return dates;
})();

const templateList = [
  {
    value: "xls_ingestion/Templates/TEMPLATE_FOR_TRADELIST.xlsx",
    label: "Trade List Template",
  },
];

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

export default function Documents() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Sliding tabs
  const cycleTab = useSelector(getCycleTab);
  const cycleId = useSelector(getCycleId);
  const [value, setValue] = useState(cycleTab);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    localStorage.setItem("cycleTabValue", newValue);
    localStorage.setItem("cycleId", updatedDealCycle[newValue]?.cycleId);
    dispatch(setCycleTab({ value: newValue }));
    dispatch(setCycleId({ value: updatedDealCycle[newValue]?.cycleId }));
  };

  useEffect(() => {
    socket.on("system-action", handleSystemAction);
    return () => {
      socket.off("system-action");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSystemAction = ({ action }) => {
    if (action === "refresh-documents") {
      UploadService.getDocumentsByCycleId(cycleId)
        .then((response) => {
          setDocumentData(response.data);
          setFilteredRows(response.data);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
  };

  // Deal cycle
  const [updatedDealCycle, setUpdatedDealCycle] = useState([]);
  const [cyclesIsLoaded, setCyclesIsLoaded] = useState(false);

  useEffect(() => {
    DealService.getDealCycles()
      .then((response) => {
        setUpdatedDealCycle(response.data);
        setCyclesIsLoaded(true);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageSize, setPageSize] = useState(10);
  const [documentData, setDocumentData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(0);
  const [dialogUploadOpen, setDialogUploadOpen] = useState(false);
  const [dialogDownloadOpen, setDialogDownloadOpen] = useState(false);

  const fullScreen = useMediaQuery("(max-width: 640px)");

  const [deleteSnack, setDeleteSnack] = useState(false);
  const [uploadSnack, setUploadSnack] = useState(false);

  const [cycleDate, setCycleDate] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [fileType, setFileType] = useState("");

  const { formData } = useSelector((state) => state.upload);
  const [disableUpload, setDisableUpload] = useState(true);

  useEffect(() => {
    // Validate form inputs
    if (
      formData !== null &&
      cycleDate !== "" &&
      documentName !== "" &&
      fileType !== ""
    ) {
      setDisableUpload(false);
    } else {
      setDisableUpload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, cycleDate, documentName, fileType]);

  const handleCycleDateChange = (_event) => {
    setCycleDate(_event.target.value);
  };

  const handleDocumentNameChange = (_event) => {
    setDocumentName(_event.target.value);
  };

  const handleFileTypeChange = (_event) => {
    setFileType(_event.target.value);
  };

  const handleSubmit = (_event) => {
    _event.preventDefault();

    if (formData !== null) {
      formData.append("cycleId", cycleDate);
      formData.append("documentName", documentName);
      formData.append("fileType", fileType);

      UploadService.uploadFile(formData)
        .then((document) => {
          setDocumentData([document, ...documentData]);
          setFilteredRows([document, ...filteredRows]);
          // Switch to uploaded cycle
          const index = updatedDealCycle.findIndex(
            (item) => item.cycleId === +cycleDate
          );
          setValue(index);
          handleGenericSnackOpen("success", "Document successfully uploaded");
        })
        .catch((error) => {
          handleServerError(error);
        });
    } else {
      handleGenericSnackOpen("notFound", "Nothing to upload.");
    }
  };

  const handleDialogClose = () => {
    setDialogDeleteOpen(false);
    setDialogUploadOpen(false);
    setDialogDownloadOpen(false);
  };

  const handleSnackClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteSnack(false);
  };

  const handleUploadSnackClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUploadSnack(false);
  };

  const documentsTable = {
    rows: filteredRows,
    columns: [
      ...documentsColumns,
      {
        field: "actions",
        type: "actions",
        width: 120,
        getActions: (row) => [
          <GridActionsCellItem
            icon={
              <InlineSVG
                src={DownloadIcon}
                ariaHidden
                width={28}
                height={28}
                className="fill-black"
              />
            }
            onClick={() => {
              handleDownload(row.row.name, row.row.s3_file_name);
            }}
            label="Download this document"
          />,

          <GridActionsCellItem
            icon={
              <InlineSVG src={TrashIcon} ariaHidden width={28} height={28} />
            }
            onClick={() => {
              setDialogDeleteOpen(true);
              setDocumentToDelete(row.id);
            }}
            label="Delete this document"
          />,
        ],
      },
    ],
    pageSize: pageSize,
    rowsPerPageOptions: [10, 20, 30, 40, 50],
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
    disableSelectionOnClick: true,
    autoHeight: true,
    components: {
      NoRowsOverlay: () => {
        return (
          <div className="mt-10 text-center">
            <Typography>No documents found...</Typography>
          </div>
        );
      },
    },
  };

  // FOR UPLOADING ILLUSTRATION PURPOSES
  // START
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (uploadSnack) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setUploadSnack(false);
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploadSnack]);
  // END

  useEffect(() => {
    const cycle_id = updatedDealCycle[value]?.cycleId;
    if (cycle_id !== undefined) {
      UploadService.getDocumentsByCycleId(cycle_id)
        .then((response) => {
          setDocumentData(response.data);
          setFilteredRows(response.data);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cyclesIsLoaded, value]);

  // DataGrid Table Search
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);

  useEffect(() => {
    let filteredData = documentData;

    // Filter rows by search text
    if (searchText) {
      filteredData = documentData.filter((row) => {
        return (
          Object.values(row.name)
            .join("")
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter rows by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((row) => {
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (
            Object.values(row)
              .join("")
              .toLowerCase()
              .includes(option.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredRows(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterBy]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  const handleDeleteDocument = () => {
    handleDialogClose();
    UploadService.deleteDocument(documentToDelete)
      .then(() => {
        setFilteredRows(
          filteredRows.filter((row) => row.id !== documentToDelete)
        );
        setDeleteSnack(true);
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  const handleDownload = (docName, s3FileName) => {
    DownloadService.downloadFile(s3FileName)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const fileExtension = s3FileName.split(".")[1];
        const file = `${docName}.${fileExtension}`;
        console.log(file);
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // Template Section
  const [template, setTemplate] = useState("");

  const handleTemplateChange = (e) => {
    const { value } = e.target;
    setTemplate(value);
  };

  const handleDownloadTemplate = () => {
    const [templateItem] = templateList.filter((v) => v.value === template);
    handleDownload(templateItem.label, templateItem.value);
    handleDialogClose();
  };
  ///

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#documentsTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title="Documents"
          titleID="documentsTitle"
          button={
            <div className="mt-6 flex gap-4 lg:mt-0">
              <Button
                variant="outlined"
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={DownloadIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-black"
                  />
                }
                onClick={() => setDialogDownloadOpen(true)}
              >
                Download template
              </Button>
              <Button
                variant="contained"
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={UploadIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-white"
                  />
                }
                onClick={() => {
                  // Clear state
                  setCycleDate("");
                  setDocumentName("");
                  setFileType("");
                  // Popup
                  setDialogUploadOpen(true);
                }}
              >
                Upload document
              </Button>
            </div>
          }
        />

        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          ScrollButtonComponent={CustomTabScrollButton}
          allowScrollButtonsMobile
          aria-label="Deal cycle selection"
          className="deal-cycle-nav my-12"
        >
          {updatedDealCycle.map((cycle, index) => (
            <Tab
              key={index}
              label={cycle.date}
              {...a11yProps(index)}
              disableRipple
              className={`${cycle.active ? "" : "cycle-not-done"}`}
            />
          ))}
        </Tabs>

        {updatedDealCycle.map((cycle, index) => (
          <TabPanel key={index} value={value} index={index}>
            <div className="mt-8 mb-4">
              <div className="grid grid-cols-10 gap-x-4">
                <div className="col-span-10 sm:col-span-5 md:col-span-6 lg:col-span-7 xl:col-span-8">
                  <InputField
                    inputLabel="Search"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search by document name..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InlineSVG
                            src={SearchIcon}
                            width={24}
                            height={24}
                            ariaHidden
                            className="fill-black"
                          />
                        </InputAdornment>
                      ),
                    }}
                  ></InputField>
                </div>
                <div className="col-span-10 mt-6 sm:col-span-5 sm:mt-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
                  <InputField
                    select
                    inputLabel="Filter by"
                    value={filterBy}
                    SelectProps={{
                      multiple: true,
                      IconComponent: (props) => (
                        <ArrowDropDownRoundedIcon {...props} />
                      ),
                      onChange: handleFilterByChange,
                      renderValue: (selected) =>
                        selected.length > 0 ? selected.join(", ") : "All",
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" className="px-6">
                      All
                    </MenuItem>
                    <ListSubheader className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black">
                      File Format
                    </ListSubheader>
                    {documentFormats.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        <CustomCheckbox
                          checked={filterBy.indexOf(option.label) > -1}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                    <ListSubheader className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black">
                      Type
                    </ListSubheader>
                    {documentTypes.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        <CustomCheckbox
                          checked={filterBy.indexOf(option.label) > -1}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </InputField>
                </div>
              </div>
            </div>
            <DataGrid
              {...documentsTable}
              className="mb-12 rounded-2xl border-0 bg-white px-4 pt-8 pb-4 text-base text-black shadow sm:px-6 md:px-10"
            />
          </TabPanel>
        ))}

        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={dialogDeleteOpen}
          onClose={handleDialogClose}
          aria-labelledby="delete-confirmation-dialog-title"
        >
          <DialogTitle id="delete-confirmation-dialog-title">
            Delete this document?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deleting this document cannot be undone. Are you sure you want to
              continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableFocusRipple
              autoFocus
              onClick={() => {
                handleDeleteDocument();
              }}
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={dialogDownloadOpen}
          onClose={handleDialogClose}
          aria-labelledby="download-confirmation-dialog-title"
        >
          <DialogTitle
            className="md:w-96"
            id="download-confirmation-dialog-title"
          >
            Download template
          </DialogTitle>
          <DialogContent>
            <InputField
              select
              inputLabel="Select template"
              defaultValue="placeholder"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
              onChange={handleTemplateChange}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {templateList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableFocusRipple
              autoFocus
              onClick={() => {
                handleDownloadTemplate();
              }}
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          maxWidth="sm"
          open={dialogUploadOpen}
          onClose={handleDialogClose}
          aria-labelledby="upload-dialog-title"
        >
          <DialogTitle id="upload-dialog-title">Upload Document</DialogTitle>
          <DialogContent>
            <div className="mb-8 w-full sm:w-96">
              <DragAndDropArea />
            </div>
            <InputField
              select
              inputLabel="Cycle Date"
              defaultValue="placeholder"
              className="mb-4"
              onChange={handleCycleDateChange}
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {cycleDates.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
            <InputField
              inputLabel="Document Name"
              placeholder="Document Name"
              className="mb-4"
              onChange={handleDocumentNameChange}
              autoComplete="off"
              required
            ></InputField>
            <InputField
              select
              inputLabel="Document Type"
              defaultValue="placeholder"
              onChange={handleFileTypeChange}
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {documentTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              autoFocus
              disableFocusRipple
              disabled={disableUpload}
              onClick={(e) => {
                handleSubmit(e);
                handleDialogClose();
                //setUploadSnack(true);
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={deleteSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: "rounded-lg bg-grey-800",
          }}
          message={
            <div className="flex items-center gap-3">
              <img
                src={TrashWhiteIcon}
                alt="Trash icon"
                role="presentation"
                aria-hidden={true}
                width={28}
                height={28}
                className="mb-1"
              />
              <p className="mb-0 text-base text-white">
                The document was deleted.
              </p>
            </div>
          }
          action={
            <Button
              variant="text"
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              disableFocusRipple
              onClick={handleSnackClose}
            >
              Dismiss
            </Button>
          }
        />

        <Snackbar
          open={uploadSnack}
          onClose={handleUploadSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: "rounded-lg bg-grey-800",
          }}
          message={
            <div className="flex items-center gap-6 px-1">
              <p className="text-base">Uploading...</p>

              <LinearProgress
                variant="determinate"
                value={progress}
                className="h-2 w-48 rounded-xl bg-grey-600 sm:w-96"
              />
            </div>
          }
          action={
            <Button
              variant="text"
              disableFocusRipple
              className="text-white hover:text-white"
              onClick={handleUploadSnackClose}
            >
              Dismiss
            </Button>
          }
        />

        <Snackbar
          open={genericSnack}
          autoHideDuration={6000}
          onClose={handleGenericSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: snackSettings[snackType].colorClass,
          }}
          message={
            <div className="flex items-center gap-3">
              <InlineSVG
                src={snackSettings[snackType].icon}
                ariaHidden
                width={28}
                height={28}
                className="fill-white"
              />
              <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
            </div>
          }
          action={
            <Button
              variant="text"
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              onClick={handleGenericSnackClose}
              disableFocusRipple
            >
              Dismiss
            </Button>
          }
        />
      </section>
    </>
  );
}
