// React and MUI Imports
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Slide,
  Snackbar,
  Switch,
  useMediaQuery,
} from "@mui/material";

// Redux Imports
import { useSelector, useDispatch } from "react-redux";
import {
  getAdHocHeadlineDeals,
  getAdHocDeals,
  deleteDeal,
  deleteDealTerm,
  deleteAdHocDealTerm,
} from "../../../slices/dealCanvas";

// Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import InputField from "../../../components/input/input-component";
import AdHocDealCards from "../../../components/ad-hoc-deal-cards/ad-hoc-deal-cards-component";
import AdHocDealInfo from "../../../components/ad-hoc-deal-info/ad-hoc-deal-info-component";
import SelectDropdownWithSearch from "../../../components/select-dropdown-with-search/select-dropdown-with-search-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
//import SearchIcon from "../../../assets/icons/search-icon.svg";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import SaveIcon from "../../../assets/icons/save-icon.svg";

// Snack Settings
import { snackSettings } from "../../home/home-page";

// Mock Data Imports
// import {
//   dealTypes,
//   channels,
//   dealOptions,
//   oemDevices,
//   oemOptions,
//   adhocDeal,
// } from "../../../data/mock-data";
import { cycleDates } from "../../documents/documents-page";
import AdHocService from "../../../services/adhoc.service";
import GeneralService from "../../../services/general.service";
import { getCycleId } from "../../../slices/dealCanvas";
import { set } from "react-hook-form";
import ChannelService from "../../../services/channel.service";
// import { tariffOptions } from "../../../data/mock-data";

const NewDealCard = (props) => {
  const { dealsetID, handleGenericSnackOpen, campaign } = props;

  const { campaign_id } = useParams();
  const dispatch = useDispatch();

  // Sim Only States
  const [simOnly, setSimOnly] = useState(false);
  const handleSimOnlyChange = () => {
    setSimOnly(!simOnly);
  };

  const [campaignCycle, setCampaignCycle] = useState("none");
  const [campaignLoaded, setCampaignLoaded] = useState(0);

  useEffect(() => {
    AdHocService.getCycleFromCampaign(campaign_id).then((response) => {
      setCampaignCycle(response.data);
      setCampaignLoaded(campaignLoaded + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Selected OEM and Devices from Searchable Dropdown Component
  const [selectedOEM, setSelectedOEM] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");

  // Dialogs
  const [newDealDialog, setNewDealDialog] = useState(false);
  const [importStep1Dialog, setImportStep1Dialog] = useState(false);
  const [importStep2Dialog, setImportStep2Dialog] = useState(false);

  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle Dialogs Close
  const handleNewDealDialogClose = () => {
    setNewDealDialog(false);
  };
  const handleImportStep1DialogClose = () => {
    setImportStep1Dialog(false);
  };
  const handleImportStep2DialogClose = () => {
    setImportStep2Dialog(false);
  };

  // Handle Add Deal
  // const handleAddNewDeal = (type, data, dealsetID) => {
  //   //dispatch(addDeal({ type: type, data: data, deviceID: dealsetID }));
  //   //handleGenericSnackOpen("success", "Deal added successfully");
  // };

  // New Deal Variables
  const [isSIMOnly, setIsSIMOnly] = useState(false);
  const [dealType, setDealType] = useState(0);
  const [channelID, setChannelID] = useState(0);
  const [mainCID, setMainCID] = useState(0);
  const [terms, setTerms] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [oemDevices, setOemDevices] = useState([]);
  const [dealOptions, setDealOptions] = useState([]);
  const [oemOptions, setOemOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [briefType, setBriefType] = useState("");
  const [briefTypeOptions, setBriefTypeOptions] = useState([]);
  const [dealerQualification, setDealerQualification] = useState("");
  const [dealerQualificationOptions, setDealerQualificationOptions] = useState(
    []
  );
  const [dealSheetType, setDealSheetType] = useState("");

  const handleIsSIMOnlyChange = (e) => {
    setIsSIMOnly(e);
  };
  const handleDealTypeChange = (e) => {
    setDealType(e);
  };
  const handleChannelIDChange = (e) => {
    setChannelID(e);
  };
  const handleMainCIDChange = (e) => {
    setMainCID(e);
  };
  const handleTariffTermsChange = (_) => {
    const { term, e } = _;
    const { period } = term;
    const { value } = e.target;
    const item = terms.find((v) => v.term === period);
    if (item) {
      const updatedTerms = terms.map((m) => {
        if (m.term === period) {
          return { term: period, label: value };
        }
        return m;
      });
      setTerms(updatedTerms);
    } else {
      setTerms([...terms, { term: period, label: value }]);
    }
  };
  const handleRemoveTerm = (term) => {
    setTerms(terms.filter((v) => v.term !== term));
  };
  const handleStartDateChange = (e) => {
    setStartDate(new Date(e));
  };
  const handleEndDateChange = (e) => {
    setEndDate(new Date(e));
  };

  const handleDealerQualificationChange = (e) => {
    setDealerQualification(e);
  };

  const handleDealSheetTypeChange = (e) => {
    setDealSheetType(e);
  };

  const handleOemDeviceOptionsChange = (e) => {
    setOemDevices(e);
  };
  const handleDealTypeOptionsChange = (e) => {
    setDealOptions(e);
  };
  const handleOemOptionsChange = (e) => {
    setOemOptions(e);
  };
  const handleChannelOptionsChange = (e) => {
    setChannelOptions(e);
  };
  const handleBriefTypeOptionsChange = (e) => {
    setBriefTypeOptions(e);
  };

  const handleDealerQualificationOptionsChange = (e) => {
    setDealerQualificationOptions(e);
  };

  const newDealFuncs = {
    handleIsSIMOnlyChange,
    handleDealTypeChange,
    handleChannelIDChange,
    handleMainCIDChange,
    handleTariffTermsChange,
    handleRemoveTerm,
    handleStartDateChange,
    handleEndDateChange,
    handleOemDeviceOptionsChange,
    handleDealTypeOptionsChange,
    handleOemOptionsChange,
    handleChannelOptionsChange,
    handleBriefTypeOptionsChange,
    handleDealerQualificationChange,
    handleDealSheetTypeChange,
    handleDealerQualificationOptionsChange,
  };

  const handleCreateDeal = (campaign_id) => {
    const { value: deal_type_label } = dealType?.target | {};
    const { value: channel_label } = channelID.target;
    const { value: dealsheet_type } = dealSheetType.target;

    let main_cid = "";
    oemDevices.forEach((deviceType) => {
      const device = deviceType.devices.find((e) => {
        return e.label === mainCID;
      });
      if (device) {
        main_cid = device.value;
      }
    });
    const { value: dealer_qualification } =
      dealerQualificationOptions.find((v) => {
        return v.label === dealerQualification;
      }) || {};
    const { value: deal_type_id } =
      dealOptions.find((v) => v.label === deal_type_label) | {};
    const { value: channel_id } = channelOptions.filter(
      (v) => v.label === channel_label
    )[0];

    // Add 1 day offset to selected dates to mitigate Datetime Picker component bug
    startDate.setDate(startDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 1);

    const newDeals = terms.map((m) => {
      const { value: tariff_code } =
        tariffOptions.find((v) => v.label === m.label) || {};
      return {
        deal_type_id: deal_type_id,
        main_cid: isSIMOnly ? 404 : main_cid,
        tariff_code: tariff_code,
        start_date: startDate?.toISOString().slice(0, 10),
        end_date: endDate?.toISOString().slice(0, 10),
        channel_id: channel_id,
        campaign_id: campaign_id,
        dealsheet_type: dealsheet_type,
        dealer_qualification: channel_id == 0 ? null : dealer_qualification,
        term: m.term,
      };
    });

    AdHocService.createNewAdhocDeal(newDeals)
      .then(() => {
        dispatch(getAdHocHeadlineDeals({ campaign_id }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [tariffOptions, setTariffOptions] = useState([]);

  useEffect(() => {
    if (campaignLoaded !== 0) {
      GeneralService.getTariffs(campaignCycle)
        .then((response) => {
          setTariffOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignLoaded]);

  return (
    <>
      <div className="flex h-full min-h-[600px] w-full flex-col justify-center gap-6 rounded-lg border-2 border-dashed border-grey-500 bg-grey-50 p-16">
        <Button
          variant="contained"
          disableFocusRipple
          startIcon={
            <InlineSVG
              src={PlusIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-white"
            />
          }
          onClick={() => {
            setSimOnly(false);
            setIsSIMOnly(false);
            setNewDealDialog(true);
          }}
        >
          Create New Deal
        </Button>
        {/* <Button
          variant="outlined"
          disableFocusRipple
          startIcon={
            <InlineSVG
              src={SearchIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-black"
            />
          }
          onClick={() => setImportStep1Dialog(true)}
        >
          Import Existing Deal
        </Button> */}
      </div>

      {/* Import deal - Step 1 */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        open={importStep1Dialog}
        onClose={handleImportStep1DialogClose}
        aria-labelledby="import-step1-dialog-title"
      >
        <DialogTitle id="import-step1-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleImportStep1DialogClose();
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Import Deal
        </DialogTitle>
        <DialogContent>
          <form className="flex flex-col gap-4">
            <FormControlLabel
              className="ml-0 mb-2 self-start"
              control={
                <Switch
                  color="primary"
                  checked={isSIMOnly}
                  onChange={handleIsSIMOnlyChange}
                />
              }
              label="SIM only"
              labelPlacement="start"
            />

            <InputField
              select
              inputLabel="Deal Cycle"
              defaultValue="placeholder"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {cycleDates.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>

            {!simOnly && (
              <>
                <SelectDropdownWithSearch
                  label="OEM"
                  selectedOption={selectedOEM}
                  setSelectedOption={setSelectedOEM}
                  options={oemOptions}
                />

                <SelectDropdownWithSearch
                  label="Device"
                  selectedOption={selectedDevice}
                  setSelectedOption={setSelectedDevice}
                  disabled={selectedOEM === ""}
                  options={
                    selectedOEM !== ""
                      ? oemDevices.find((item) => item.oem === selectedOEM)
                          .devices
                      : ""
                  }
                />
              </>
            )}

            <InputField
              select
              inputLabel="Deal"
              defaultValue="placeholder"
              SelectProps={{
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
              }}
            >
              <MenuItem disabled value="placeholder">
                - Select -
              </MenuItem>
              {dealOptions.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleImportStep1DialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleImportStep1DialogClose();
              setImportStep2Dialog(true);
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Import deal - Step 2 */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        open={importStep2Dialog}
        onClose={handleImportStep2DialogClose}
        aria-labelledby="import-step2-dialog-title"
      >
        <DialogTitle id="import-step2-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleImportStep2DialogClose();
              setImportStep1Dialog(true);
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Import Deal
        </DialogTitle>
        <DialogContent>
          <h3 className="mb-3 text-base font-bold text-black">
            Samsung Galaxy A12
          </h3>
          <p className="text-grey-300">
            SAMSUNG Galaxy 12 32GB+20GB X 1(C) +2 Months Free Theft cover+1GB
            VID TKT X 3 @R 699 Reduced Sub PM on Red 10GB 200min - 24 months
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleImportStep2DialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleImportStep2DialogClose();
              //handleAddNewDeal("adhocDeals", adhocDeal, dealsetID);
              //createAdHocDeal({ adhocDeal, dealsetID });
            }}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create new deal  */}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={newDealDialog}
        onClose={handleNewDealDialogClose}
        aria-labelledby="new-deal-dialog-title"
      >
        <DialogTitle id="new-deal-dialog-title">
          <Button
            variant="text"
            onClick={() => {
              handleNewDealDialogClose();
            }}
            disableRipple
            className="mb-2 flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 14"
              width={16}
              height={14}
            >
              <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
            </svg>
            Back
          </Button>
          Create New Deal
        </DialogTitle>
        <DialogContent>
          <AdHocDealInfo
            snackbarMessages={handleGenericSnackOpen}
            newDealFuncs={newDealFuncs}
            tariffOptions={tariffOptions}
            createDealForm={true}
            minDate={campaign.campaign_start_date}
            maxDate={campaign.campaign_end_date}
            isSIMOnly={isSIMOnly}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleNewDealDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleNewDealDialogClose();
              //handleAddNewDeal("adhocDeals", adhocDeal, dealsetID);
              handleCreateDeal(dealsetID);
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const data = {
  id: "0",
  campaign_id: "",
  dealset: "",
  totalDeals: 0,
  submitForApproval: false,
  deals: [],
};

export default function AdhocNewDealset() {
  const { dealset, campaign_id } = useParams();

  // Deals state
  //const adhocDeals = useSelector(getAdHocDeals);
  const dispatch = useDispatch();

  // Get Ad-Hoc Deals for this Dealset
  //const data = adhocDeals.find((data) => data.dealset === dealset);
  //const [_data, setData] = useState({});
  // const [dealAdded, setDealAdded] = useState(0);
  // useEffect(() => {
  //   AdHocService.getAdHocHeadlineDeals(campaign_id)
  //     .then((response) => {
  //       //setData(response.data);
  //       setDealData(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dealAdded]);

  const [campaignUpdated, setCampaignUpdated] = useState(0);
  useEffect(() => {
    dispatch(getAdHocHeadlineDeals({ campaign_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, campaignUpdated]);

  // const [dealData, setDealData] = useState(data.deals);
  const dealData = useSelector(getAdHocDeals);
  const [tariffOptions, setTariffOptions] = useState([]);
  const [routerOptions, setRouterOptions] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [campaignCycle, setCampaignCycle] = useState("none");
  const [campaignLoaded, setCampaignLoaded] = useState(0);

  useEffect(() => {
    AdHocService.getCycleFromCampaign(campaign_id).then((response) => {
      setCampaignCycle(response.data);
      setCampaignLoaded(campaignLoaded + 1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update data as it changes.
  // useEffect(() => {
  //   setDealData(data.deals);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  useEffect(() => {
    AdHocService.getCampaign(campaign_id)
      .then((response) => {
        setCampaign(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
    if (campaignLoaded !== 0) {
      GeneralService.getTariffs(campaignCycle)
        .then((response) => {
          setTariffOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignLoaded]);

  useEffect(() => {
    if (campaignLoaded !== 0) {
      GeneralService.getRouters(campaignCycle)
        .then((response) => {
          setRouterOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
          //handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignLoaded]);

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const createAdHocDeal = (newDeal) => {
    AdHocService.createNewAdhocDeal(newDeal)
      .then(() => {
        // setDealAdded(dealAdded + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    const updatedAdHocDeals = dealData
      .filter((v) => v.dataHasChanged)
      .flatMap((item) => {
        const {
          id: card_id,
          main_cid,
          device_basket_id,
          channel_id,
          dealer_qualification,
          dealsheet_type,
          deal_type_id,
          dealStartDate,
          dealEndDate,
          total_ctd,
        } = item;

        const start_date = new Date(dealStartDate);
        const end_date = new Date(dealEndDate);

        return Array.from(item.terms, (subItem) => {
          const {
            dde_parent_key,
            period: term,
            dealPrice: deal_price,
            tariff: main_tariff_code,
            vas_basket_items,
          } = subItem;
          return {
            card_id,
            campaign_id,
            dde_parent_key,
            main_cid,
            device_basket_id,
            device_basket_items: [
              ...item.multiDevice,
              ...item.multiAccessory,
              ...item.multiRouter,
            ],
            main_tariff_code,
            tariff_basket_items: [...item.multiTariff],
            vas_basket_items,
            channel_id,
            term,
            dealer_qualification,
            dealsheet_type,
            deal_type_id,
            total_ctd,
            deal_price,
            start_date: start_date.toISOString().slice(0, 10),
            end_date: end_date.toISOString().slice(0, 10),
          };
        });
      });

    if (updatedAdHocDeals.length > 0) {
      AdHocService.updateAdHocHeadlineDeals(updatedAdHocDeals)
        .then(() => {
          setCampaignUpdated(campaignUpdated + 1);
          handleGenericSnackOpen("success", `AdHoc Deals saved successfully.`);
        })
        .catch((error) => {
          //handleServerError(error);
        });
    }
  };

  const deleteAsync = (dde_parent_key) =>
    new Promise(function (resolve, reject) {
      ChannelService.deleteChannelHeadlineDeal(dde_parent_key)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleDeleteDeal = async (dealID, dealType, deal) => {
    let deleteError;
    for (let i = 0; i < deal.dde_parent_keys.length; i++) {
      if (deal.dde_parent_keys[i] !== "") {
        await deleteAsync(deal.dde_parent_keys[i]).catch((error) => {
          deleteError = error;
        });
        if (deleteError) {
          break;
        }
      }
    }
    if (deleteError) {
      //handleServerError(deleteError);
    } else {
      dispatch(deleteDeal({ id: dealID, type: dealType }));
      handleGenericSnackOpen("success", "Deal deleted successfully.");
    }
  };

  const handleDeleteDealTerm = (dealID, dealType, deal) => {
    ChannelService.deleteChannelHeadlineDeal(deal.dde_parent_key)
      .then(() => {
        dispatch(
          deleteAdHocDealTerm({
            id: dealID,
            type: dealType,
            term: deal.term,
          })
        );
        handleGenericSnackOpen(
          "success",
          `Deal for term ${deal.term} deleted successfully.`
        );
      })
      .catch((error) => {
        //handleServerError(error);
      });
  };

  const [vasOptions, setVASOptions] = useState([]);
  useEffect(() => {
    if (campaignLoaded !== 0) {
      GeneralService.getActiveVASes(campaignCycle)
        .then((response) => {
          setVASOptions([{ label: "None", value: null }, ...response.data]);
        })
        .catch((error) => {
          //handleServerError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignLoaded]);

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>

      <section
        aria-labelledby="#adhocNewDealsetTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title={dealset}
          titleID="adhocNewDealsetTitle"
          button={
            <Button
              className="mt-6 lg:mt-0"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={SaveIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              onClick={() => handleSave()}
            >
              Save
            </Button>
          }
        />
      </section>

      <section
        className="col-start-2 col-end-12 row-start-3 mt-8 mb-12 grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6 2xl:grid-cols-3"
        aria-label="Ad-Hoc Deals"
      >
        {campaignLoaded !== 0 &&
          dealData.map((deal, index) => (
            <div className="col-span-1" key={index}>
              <AdHocDealCards
                data={deal}
                //dealsetID={campaign_id}
                handleGenericSnackOpen={handleGenericSnackOpen}
                tariffOptions={tariffOptions}
                routerOptions={routerOptions}
                vasOptions={vasOptions}
                campaign={campaign}
                deleteDeal={handleDeleteDeal}
                deleteDealTerm={handleDeleteDealTerm}
              />
            </div>
          ))}
        {campaignLoaded !== 0 && (
          <div className="col-span-1">
            <NewDealCard
              handleGenericSnackOpen={handleGenericSnackOpen}
              dealsetID={campaign_id}
              createAdHocDeal={createAdHocDeal}
              campaign={campaign}
            />
          </div>
        )}
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
