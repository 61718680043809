// React Imports
import {
  Button,
  MobileStepper,
  Tab,
  Tabs,
  Slide,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Redux slice import
import {
  getBulkDeals,
  getBulkFlaggedDeals,
  getBaseGenericDealsList,
} from "../../../slices/dealCanvas";
import DealService from "../../../services/deal.service";
import AuthService from "../../../services/auth.service";

// Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import DealCard from "../../../components/deal-cards/deal-cards-component";
import TabPanel, {
  a11yProps,
} from "../../../components/tab-panel/tab-panel-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import FAB from "../../../components/floating-action-button/floating-action-button-component";

// Icon Imports
import RightArrowIcon from "../../../assets/icons/arrow-right-icon.svg";
import LeftArrowIcon from "../../../assets/icons/arrow-left-icon.svg";
//import SubmitIcon from "../../../assets/icons/submit-icon.svg";
import SaveIcon from "../../../assets/icons/save-icon.svg";
//import DownloadIcon from "../../../assets/icons/download-icon-v2.svg";
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../../../assets/icons/error-circle-no-fill-icon.svg";

const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};

function BaseGenericTariffDeals() {
  // Display the Device and Tariff name according to the selected device in the URL
  const { cycle, oem, device, tariff } = useParams();
  const navigate = useNavigate();

  // Tabs
  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
    allDealState.setActiveStep(0);
    standaloneDealState.setActiveStep(0);
    accessoryDealState.setActiveStep(0);
    multiDeviceDealState.setActiveStep(0);
    flaggedDealState.setActiveStep(0);

    allDealState.handleResize();
    standaloneDealState.handleResize();
    accessoryDealState.handleResize();
    multiDeviceDealState.handleResize();
    flaggedDealState.handleResize();
  };

  // Deals state
  const deals = useSelector(getBulkDeals);
  const flaggedDeals = useSelector(getBulkFlaggedDeals);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBaseGenericDealsList({ cycle, oem, device, tariff }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle all the deal type checks for scrolling, duplication, etc.
  const useHandleDealTypes = (type, deals) => {
    // Card scrolling feature
    const containerRef = useRef(null);
    const [amountScrolled, setAmountScrolled] = useState(null);
    const [maxSteps, setMaxSteps] = useState(0);

    const filteredDeals =
      type !== null ? deals.filter((deal) => deal.type === type) : deals;
    const [activeStep, setActiveStep] = useState(0);

    const handleResize = () => {
      if (containerRef.current !== null) {
        const childElements =
          containerRef.current.querySelectorAll(".snap-always");
        const columnWidth = window.innerWidth / 12 - 15;

        childElements[0].style.width = columnWidth + "px";
        childElements[childElements.length - 1].style.width =
          columnWidth + "px";

        setMaxSteps(
          Math.ceil(
            filteredDeals.length /
              Math.round((window.innerWidth - columnWidth * 2) / 400)
          )
        );
        setAmountScrolled(window.innerWidth - columnWidth * 2);
      }
    };

    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    const handleScrollLeft = () => {
      containerRef.current.scrollLeft += amountScrolled;
      if (activeStep !== maxSteps - 1) {
        setActiveStep(activeStep + 1);
      }
    };

    const handleScrollRight = () => {
      containerRef.current.scrollLeft -= amountScrolled;
      if (activeStep !== 0) {
        setActiveStep(activeStep - 1);
      }
    };

    return {
      filteredDeals,
      containerRef,
      maxSteps,
      activeStep,
      setActiveStep,
      handleScrollLeft,
      handleScrollRight,
      handleResize,
    };
  };

  const allDealState = useHandleDealTypes(null, deals);
  const standaloneDealState = useHandleDealTypes("standalone", deals);
  const accessoryDealState = useHandleDealTypes("accessory", deals);
  const multiDeviceDealState = useHandleDealTypes("multi_device", deals);
  const flaggedDealState = useHandleDealTypes(null, flaggedDeals);

  const handleSave = () => {
    const updatedBaseGenericDeals = deals
      .filter((item) => item.dataHasChanged === true)
      .map((item) => ({
        device_basket_id: item.deviceBasketId,
        tariff_basket_id: item.tariffBasketId,
        service_codes: item.serviceCodes,
        deal_type: item.dealType,
        term: item.term,
        cycle_id: item.cycleId,
        total_subscription: item.dealPrice,
        flagged: item.flagged === true ? "Y" : "N",
      }));

    DealService.updateBaseGenericDeals(updatedBaseGenericDeals)
      .then((response) => {
        const { message } = response.data;
        handleGenericSnackOpen("success", message);
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  // Floating action buttons
  const fabActions = [
    // {
    //   icon: <InlineSVG src={SubmitIcon} width={32} height={32} />,
    //   name: "Publish",
    // },
    {
      icon: <InlineSVG src={SaveIcon} width={32} height={32} />,
      name: "Save",
      onClick: handleSave,
    },
    // {
    //   icon: <InlineSVG src={DownloadIcon} width={32} height={32} />,
    //   name: "Download Report",
    // },
  ];

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-2 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#selectedDeviceTariffTitle"
        className="col-start-2 col-end-12 row-start-3"
      >
        <TitleBreadcrumb
          title={`${device} - ${tariff}`}
          titleID="selectedDeviceTariffTitle"
        />

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Deal types tabs"
          className="my-12"
        >
          <Tab label="All Deals" {...a11yProps(0)} disableRipple />
          <Tab label="Standalone Deals" {...a11yProps(1)} disableRipple />
          <Tab label="Accessory Deals" {...a11yProps(2)} disableRipple />
          <Tab label="Multi-Device Deals" {...a11yProps(3)} disableRipple />
          <Tab label="Flagged Deals" {...a11yProps(4)} disableRipple />
        </Tabs>
      </section>

      <section
        className="col-span-12 row-start-4 grid grid-cols-12"
        aria-label="Deals"
      >
        <div className="col-span-12">
          <TabPanel value={value} index={0}>
            {allDealState.maxSteps > 1 && (
              <MobileStepper
                variant="dots"
                steps={allDealState.maxSteps}
                position="static"
                activeStep={allDealState.activeStep}
                className="mb-8 items-center justify-center gap-4 bg-transparent p-0"
                nextButton={
                  <Button
                    onClick={allDealState.handleScrollLeft}
                    disabled={
                      allDealState.activeStep === allDealState.maxSteps - 1
                    }
                    aria-label="Next"
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                  >
                    <InlineSVG
                      src={RightArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
                backButton={
                  <Button
                    onClick={allDealState.handleScrollRight}
                    disabled={allDealState.activeStep === 0}
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                    aria-label="Previous"
                  >
                    <InlineSVG
                      src={LeftArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
              />
            )}
            <div
              ref={allDealState.containerRef}
              className="row-auto grid snap-x snap-mandatory auto-cols-max grid-flow-col auto-rows-auto gap-x-4 overflow-x-auto scroll-smooth pb-12 scrollbar-none"
            >
              <div className="snap-center snap-always"></div>
              {allDealState.filteredDeals.map((deal, index) => (
                <div
                  className={`snap-center snap-always transition-all duration-200 ease-in-out ${
                    deal.lastDuplicate ? "last-duplicate" : ""
                  }`}
                  key={index}
                >
                  <DealCard dealData={deal} />
                </div>
              ))}
              <div className="snap-center snap-always"></div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {standaloneDealState.maxSteps > 1 && (
              <MobileStepper
                variant="dots"
                steps={standaloneDealState.maxSteps}
                position="static"
                activeStep={standaloneDealState.activeStep}
                className="mb-8 items-center justify-center gap-4 bg-transparent p-0"
                nextButton={
                  <Button
                    onClick={standaloneDealState.handleScrollLeft}
                    disabled={
                      standaloneDealState.activeStep ===
                      standaloneDealState.maxSteps - 1
                    }
                    aria-label="Next"
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                  >
                    <InlineSVG
                      src={RightArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
                backButton={
                  <Button
                    onClick={standaloneDealState.handleScrollRight}
                    disabled={standaloneDealState.activeStep === 0}
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                    aria-label="Previous"
                  >
                    <InlineSVG
                      src={LeftArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
              />
            )}
            <div
              ref={standaloneDealState.containerRef}
              className="row-auto grid snap-x snap-mandatory auto-cols-max grid-flow-col auto-rows-auto gap-x-4 overflow-x-auto scroll-smooth pb-12 scrollbar-none"
            >
              <div className="snap-center snap-always"></div>
              {standaloneDealState.filteredDeals.map((deal, index) => (
                <div
                  className={`snap-center snap-always transition-all duration-200 ease-in-out ${
                    deal.lastDuplicate ? "last-duplicate" : ""
                  }`}
                  key={index}
                >
                  <DealCard dealData={deal} />
                </div>
              ))}
              <div className="snap-center snap-always"></div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            {accessoryDealState.maxSteps > 1 && (
              <MobileStepper
                variant="dots"
                steps={accessoryDealState.maxSteps}
                position="static"
                activeStep={accessoryDealState.activeStep}
                className="mb-8 items-center justify-center gap-4 bg-transparent p-0"
                nextButton={
                  <Button
                    onClick={accessoryDealState.handleScrollLeft}
                    disabled={
                      accessoryDealState.activeStep ===
                      accessoryDealState.maxSteps - 1
                    }
                    aria-label="Next"
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                  >
                    <InlineSVG
                      src={RightArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
                backButton={
                  <Button
                    onClick={accessoryDealState.handleScrollRight}
                    disabled={accessoryDealState.activeStep === 0}
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                    aria-label="Previous"
                  >
                    <InlineSVG
                      src={LeftArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
              />
            )}
            <div
              ref={accessoryDealState.containerRef}
              className="row-auto grid snap-x snap-mandatory auto-cols-max grid-flow-col auto-rows-auto gap-x-4 overflow-x-auto scroll-smooth pb-12 scrollbar-none"
            >
              <div className="snap-center snap-always"></div>
              {accessoryDealState.filteredDeals.map((deal, index) => (
                <div
                  className={`snap-center snap-always transition-all duration-200 ease-in-out ${
                    deal.lastDuplicate ? "last-duplicate" : ""
                  }`}
                  key={index}
                >
                  <DealCard dealData={deal} />
                </div>
              ))}
              <div className="snap-center snap-always"></div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            {multiDeviceDealState.maxSteps > 1 && (
              <MobileStepper
                variant="dots"
                steps={multiDeviceDealState.maxSteps}
                position="static"
                activeStep={multiDeviceDealState.activeStep}
                className="mb-8 items-center justify-center gap-4 bg-transparent p-0"
                nextButton={
                  <Button
                    onClick={multiDeviceDealState.handleScrollLeft}
                    disabled={
                      multiDeviceDealState.activeStep ===
                      multiDeviceDealState.maxSteps - 1
                    }
                    aria-label="Next"
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                  >
                    <InlineSVG
                      src={RightArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
                backButton={
                  <Button
                    onClick={multiDeviceDealState.handleScrollRight}
                    disabled={multiDeviceDealState.activeStep === 0}
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                    aria-label="Previous"
                  >
                    <InlineSVG
                      src={LeftArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
              />
            )}
            <div
              ref={multiDeviceDealState.containerRef}
              className="row-auto grid snap-x snap-mandatory auto-cols-max grid-flow-col auto-rows-auto gap-x-4 overflow-x-auto scroll-smooth pb-12 scrollbar-none"
            >
              <div className="snap-center snap-always"></div>
              {multiDeviceDealState.filteredDeals.map((deal, index) => (
                <div
                  className={`snap-center snap-always transition-all duration-200 ease-in-out ${
                    deal.lastDuplicate ? "last-duplicate" : ""
                  }`}
                  key={index}
                >
                  <DealCard dealData={deal} />
                </div>
              ))}
              <div className="snap-center snap-always"></div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            {flaggedDealState.maxSteps > 1 && (
              <MobileStepper
                variant="dots"
                steps={flaggedDealState.maxSteps}
                position="static"
                activeStep={flaggedDealState.activeStep}
                className="mb-8 items-center justify-center gap-4 bg-transparent p-0"
                nextButton={
                  <Button
                    onClick={flaggedDealState.handleScrollLeft}
                    disabled={
                      flaggedDealState.activeStep ===
                      flaggedDealState.maxSteps - 1
                    }
                    aria-label="Next"
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                  >
                    <InlineSVG
                      src={RightArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
                backButton={
                  <Button
                    onClick={flaggedDealState.handleScrollRight}
                    disabled={flaggedDealState.activeStep === 0}
                    disableRipple
                    className="min-w-0 fill-red-100 p-1 disabled:fill-grey-100"
                    aria-label="Previous"
                  >
                    <InlineSVG
                      src={LeftArrowIcon}
                      ariaHidden
                      width={24}
                      height={24}
                    />
                  </Button>
                }
              />
            )}
            <div
              ref={flaggedDealState.containerRef}
              className="row-auto grid snap-x snap-mandatory auto-cols-max grid-flow-col auto-rows-auto gap-x-4 overflow-x-auto scroll-smooth pb-12 scrollbar-none"
            >
              <div className="snap-center snap-always"></div>
              {flaggedDealState.filteredDeals.map((deal, index) => (
                <div
                  className={`snap-center snap-always transition-all duration-200 ease-in-out ${
                    deal.lastDuplicate ? "last-duplicate" : ""
                  }`}
                  key={index}
                >
                  <DealCard dealData={deal} />
                </div>
              ))}
              <div className="snap-center snap-always"></div>
            </div>
          </TabPanel>
        </div>
      </section>

      <FAB actions={fabActions} />

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}

export default React.memo(BaseGenericTariffDeals);
