// React Imports
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";
import {
  getCycleReviews,
  getCycleReviewsData,
} from "../../slices/cycleConfigCanvas";

// MUI Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import BackButton from "../../components/back-button/back-button-component";
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";

// Snack Settings
import { snackSettings } from "../home/home-page";

// Tab Content Imports
import TariffTabContent from "./tab-content/tariff-tab";
import StandardVASsTabContent from "./tab-content/standard-vas-tab";
import ActiveVASsTabContent from "./tab-content/active-vas-tab";
import DevicePerChannelTabContent from "./tab-content/device-per-channel-tab";
import DealTypePerChannelTabContent from "./tab-content/deal-per-channel-tab";
import RoutersTabContent from "./tab-content/routers-tab";
import DealCycleParameters from "./tab-content/deal-cycle-parameters";

export default function DealCycleConfiguration() {
  const navigate = useNavigate();
  const { cycle } = useParams();

  // Data Source
  const data = useSelector(getCycleReviewsData);

  // Redux dispatch
  const dispatch = useDispatch();

  // State
  const [editedData, setEditedData] = useState(data);

  const fetchData = useCallback(() => {
    dispatch(getCycleReviews({ cycle }));
  }, [dispatch, cycle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data.length > 0) {
      setEditedData(data);
    }
  }, [data, editedData]);

  // Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  // Dialogs
  const [cancelWarningDialog, setCancelWarningDialog] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  const cycleInReview = editedData
    .map((tab) => tab.reviewed)
    .some((item) => item === "N");

    return (
      <>
        {/* Back button */}
        <div className="col-start-1 col-end-13 row-start-1 mt-8 mb-4 px-6 sm:mb-6 sm:mt-12 md:mb-6 md:mt-16 md:px-12">
          <BackButton to="/home"></BackButton>
        </div>

        {/* Main Content */}
        <section
          aria-labelledby="#cycleConfigurationTitle"
          className="col-start-1 col-end-13 row-start-2 px-6 md:px-12"
        >
          {/* Page heading */}
          <h1
            id="cycleConfigurationTitle"
            className="mb-5 text-4xl font-bold text-black sm:text-5xl md:text-6xl"
          >
            {cycle} Deal Cycle
          </h1>

          <h2 className="text-lg text-black md:text-2xl">
            Deal Cycle Configuration {cycleInReview && "Review"}
          </h2>

          {/* Configuration Options (Tabs) */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Configuration Options"
            className="my-8"
          >
            <Tab
              label="Deal Cycle Parameters"
              {...a11yProps(`deal-cycle-parameters`)}
              disableRipple
            />
            <Tab
              label="Tariffs"
              {...a11yProps(`tariffs-content`)}
              disableRipple
            />
            <Tab
              label="Standard VAS"
              {...a11yProps(`standard-vas-content`)}
              disableRipple
            />
            <Tab
              label="Active VAS"
              {...a11yProps(`active-vas-content`)}
              disableRipple
            />
            <Tab
              label="Device per Channel"
              {...a11yProps(`device-per-channel-content`)}
              disableRipple
            />
            <Tab
              label="Deal Type per Channel"
              {...a11yProps(`deal-type-per-channel-content`)}
              disableRipple
            />
            <Tab
              label="Routers"
              {...a11yProps(`routers-content`)}
              disableRipple
            />
          </Tabs>

          {/* Deal Cycle Parameters (Tab Content) */}
          <TabPanel value={tabValue} index={0} id={`deal-cycle-parameters`}>
            <DealCycleParameters
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
              inReview={editedData[0]?.config_status}
              reviewFeedback={editedData[0]?.reason_rejected}
            />
          </TabPanel>

          {/* Tariff Options (Tab Content) */}
          <TabPanel value={tabValue} index={1} id={`tariff-content`}>
            <TariffTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
              inReview={editedData[5]?.config_status}
              reviewFeedback={editedData[5]?.reason_rejected}
            />
          </TabPanel>

          {/* Standard VASs Options (Tab Content) */}
          <TabPanel value={tabValue} index={2} id={`standard-vas-content`}>
            <StandardVASsTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
              inReview={editedData[4]?.config_status}
              reviewFeedback={editedData[4]?.reason_rejected}
            />
          </TabPanel>

          {/* Active VASs Options (Tab Content) */}
          <TabPanel value={tabValue} index={3} id={`active-vas-content`}>
            <ActiveVASsTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
            />
          </TabPanel>

          {/* Device per Channel Options (Tab Content) */}
          <TabPanel
            value={tabValue}
            index={4}
            id={`device-per-channel-content`}
          >
            <DevicePerChannelTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
              inReview={editedData[2]?.config_status}
              reviewFeedback={editedData[2]?.reason_rejected}
            />
          </TabPanel>

          {/* Deal Type per Channel Options (Tab Content) */}
          <TabPanel
            value={tabValue}
            index={5}
            id={`deal-type-per-channel-content`}
          >
            <DealTypePerChannelTabContent
              setTabValue={setTabValue}
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
              inReview={editedData[1]?.config_status}
              reviewFeedback={editedData[1]?.reason_rejected}
            />
          </TabPanel>

          {/* Routers Options (Tab Content) */}
          <TabPanel value={tabValue} index={6} id={`routers-content`}>
            <RoutersTabContent
              handleSnackOpen={handleSnackOpen}
              setCancelWarningDialog={setCancelWarningDialog}
              inReview={editedData[3]?.config_status}
              reviewFeedback={editedData[3]?.reason_rejected}
            />
          </TabPanel>
        </section>

        <Snackbar
          open={genericSnack}
          autoHideDuration={6000}
          onClose={handleGenericSnackClose}
          TransitionComponent={Slide}
          ContentProps={{
            className: snackSettings[snackType].colorClass,
          }}
          message={
            <div className="flex items-center gap-3">
              <InlineSVG
                src={snackSettings[snackType].icon}
                ariaHidden
                width={28}
                height={28}
                className="fill-white"
              />
              <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
            </div>
          }
          action={
            <Button
              variant="text"
              className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
              onClick={handleGenericSnackClose}
              disableFocusRipple
            >
              Dismiss
            </Button>
          }
        />

        <Dialog
          fullScreen={fullScreen}
          maxWidth="xs"
          open={cancelWarningDialog}
          onClose={() => setCancelWarningDialog(false)}
          aria-labelledby="remove-tariff-warning-title"
        >
          <DialogTitle id="remove-tariff-warning-title">
            Cancel configuration?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to cancel deal cycle configuration, all changes will
              be lost. Are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              disableFocusRipple
              color="secondary"
              onClick={() => setCancelWarningDialog(false)}
            >
              No, take me back
            </Button>
            <Button
              variant="contained"
              autoFocus
              disableFocusRipple
              onClick={() => {
                navigate("/home");
              }}
            >
              Yes, I'm sure
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}
