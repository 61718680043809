import axios from "axios";

const API_URL = "/api/general/";

const getDevices = (cycle, oem) => {
  return axios.get(API_URL + cycle + "/devices/" + oem);
};
const getAllDevices = (cycle) => {
  return axios.get(API_URL + cycle + "/devices");
};
const getAccessories = (cycle, oem) => {
  return axios.get(API_URL + cycle + "/accessories/" + oem);
};
const getTariffs = (cycle, sim_only = "N") => {
  return axios.get(API_URL + cycle + "/tariffs/" + sim_only);
};
const getRouters = (cycle) => {
  return axios.get(API_URL + cycle + "/routers");
};
const getOEMs = (cycle, type, id = 0) => {
  return axios.get(API_URL + cycle + "/oems/" + type + "/" + id);
};
const getDeviceROMs = (cycle, type, id = 0) => {
  return axios.get(API_URL + cycle + "/device-roms/" + type + "/" + id);
};
const getDealTypes = () => {
  return axios.get(API_URL + "deal-types");
};
const getActiveVASes = (cycle) => {
  return axios.get(API_URL + cycle + "/active-vases");
};
const getBriefTypes = () => {
  return axios.get(API_URL + "brief-types");
};
const getDealApprovalROMs = (cycle) => {
  return axios.get(API_URL + cycle + "/deal-approval-filters/roms");
};
const getDealApprovalOEMs = (cycle) => {
  return axios.get(API_URL + cycle + "/deal-approval-filters/oems");
};

const getDealerQualifications = (brief_type) => {
  return axios.get(API_URL + "dealer-qualifications/" + brief_type);
};

const getCurrentCycle = () => {
  const date = new Date();
  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };
  return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

const GeneralService = {
  getDevices,
  getAllDevices,
  getAccessories,
  getTariffs,
  getRouters,
  getOEMs,
  getDeviceROMs,
  getDealApprovalROMs,
  getDealApprovalOEMs,
  getDealTypes,
  getBriefTypes,
  getCurrentCycle,
  getActiveVASes,
  getDealerQualifications,
};

export default GeneralService;
