// React Imports
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

// Redux Store Imports
import AuthService from "../../../services/auth.service";
import {
  getActiveVASs,
  getActiveVASsData,
} from "../../../slices/cycleConfigCanvas";
import CycleConfigService from "../../../services/cycleConfig.service";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// MUI Imports
import {
  // Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Active VASs Table Head Cells
const activeVASsTableHead = [
  {
    id: "code",
    label: "Code",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "recurrence",
    label: "Recurrence",
  },
];

// Table Sorting Helper Functions
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
//

export default function ActiveVASsTabContent({ setTabValue, handleSnackOpen }) {
  const navigate = useNavigate();
  const { cycle } = useParams();

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleSnackOpen("error", errors[0].message);
    }
  };

  // Redux dispatch hook
  const dispatch = useDispatch();

  // Form Submission hook from react-hook-form
  const { handleSubmit } = useForm();

  // On Submit function to trigger tab change and success snackbar popup
  const onSubmit = () => {
    handleSnackOpen(
      "success",
      "Active VASs configuration has been successfully saved."
    );
    handleClickChangeTab();
  };

  // Change Tab on Parent Component
  const handleClickChangeTab = () => {
    setTabValue(4);
  };

  // Set DATA sources
  const data = useSelector(getActiveVASsData);

  // States
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [filteredData, setFilteredData] = useState(data);

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);

  // Handle change table page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filters
  const [filterByOptions, setFilterByOptions] = useState([]);

  // Get Filters
  useEffect(() => {
    CycleConfigService.getActiveVASsType(cycle)
      .then((response) => {
        const { data } = response;

        setFilterByOptions(data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getActiveVASs({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update data as it changes
  useEffect(() => {
    let filteredData = data;

    // Filter data by search text
    if (searchText) {
      filteredData = data.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const values = Object.values(item)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return values.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
      });
    }

    // Filter data by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((vas) => {
        // Extract plan and type properties
        const { recurrence, type } = vas;

        // Combine plan and type properties into an array
        const vasValues = [recurrence, type];

        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (vasValues[0] === option || vasValues[1] === option) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredData(filteredData);

    // Set rows per page
    const rowsLen = filteredData.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptions([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPage(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPage(rows);
    }
  }, [searchText, filterBy, data]);

  // Handle search filter via search input field
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // Handle filter by option change
  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  // Sorting Functions
  //
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  // Showing sorted data and split into pages according to the pagination
  const visibleRows = useMemo(
    () =>
      filteredData
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .sort(getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, filteredData]
  );

  return (
    <>
      <div className="my-8">
        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-6 lg:col-span-8">
            <InputField
              inputLabel="Search"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search by description..."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InlineSVG
                      src={SearchIcon}
                      width={24}
                      height={24}
                      ariaHidden
                      className="fill-black"
                    />
                  </InputAdornment>
                ),
              }}
            ></InputField>
          </div>
          <div className="col-span-4 lg:col-span-2">
            <InputField
              select
              inputLabel="Filter by"
              value={filterBy}
              onChange={handleFilterByChange}
              SelectProps={{
                multiple: true,
                IconComponent: (props) => (
                  <ArrowDropDownRoundedIcon {...props} />
                ),
                renderValue: (selected) =>
                  selected.length > 0 ? selected.join(", ") : "All",
                displayEmpty: true,
              }}
            >
              <MenuItem value="" className="px-6">
                All
              </MenuItem>
              {filterByOptions.map((filter) => [
                <ListSubheader
                  key={filter.value}
                  className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                >
                  {filter.heading}
                </ListSubheader>,
                ...filter.options.map((item) => (
                  <MenuItem value={item.label}>
                    <CustomCheckbox
                      checked={filterBy.indexOf(item.label) > -1}
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                )),
              ])}
            </InputField>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8 rounded-2xl bg-white px-8 pt-6 shadow">
          <Table>
            <TableHead>
              <TableRow>
                {activeVASsTableHead.map((headCell, index) => (
                  <TableCell
                    key={headCell.id + index}
                    sortDirection={orderBy === headCell.id ? order : false}
                    className="text-base text-black"
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "desc"}
                      onClick={createSortHandler(headCell.id)}
                      IconComponent={ArrowDownwardRoundedIcon}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className="sr-only">
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={activeVASsTableHead.length}
                    className="border-grey-50 py-8 text-center text-base text-black"
                  >
                    No VASs found...
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((item, index) => (
                  <TableRow key={item.id + index}>
                    <TableCell className="border-grey-50 text-base text-black">
                      {item.code}
                    </TableCell>
                    <TableCell className="border-grey-50 text-base text-black">
                      {item.description}
                    </TableCell>
                    <TableCell className="border-grey-50 text-base text-black">
                      {(item.subscription || 0).toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </TableCell>
                    <TableCell className="border-grey-50 text-base text-black">
                      {item.type}
                    </TableCell>
                    <TableCell className="border-grey-50 text-base text-black">
                      {item.recurrence}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="Items per page"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "Items per page",
                    },
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>

        {/* <div className="mb-12 flex justify-center gap-2 md:justify-end">
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={() => setCancelWarningDialog(true)}
          >
            Cancel
          </Button>
          <Button variant="contained" disableFocusRipple type="submit">
            Save & Continue
          </Button>
        </div> */}
      </form>
    </>
  );
}
