// React Imports
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Redux Store Imports
import { useSelector, useDispatch } from "react-redux";
import {
  getDealTypePerChannel,
  getDealTypePerChannelData,
  updateReviewState
} from "../../../slices/cycleConfigCanvas";

// MUI Imports
import {
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Component Imports
import TabPanel from "../../../components/tab-panel/tab-panel-component";

// Icon Imports
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import XCircleIcon from "../../../assets/icons/x-circle-icon.svg";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import InputField from "../../../components/input/input-component";

export default function DealTypePerChannelReviewTabContent({
  setTabValue,
  handleSnackOpen,
  setReviewState,
  reviewStatus
}) {
  // Rejection reason state
  const [message, setMessage] = useState("");

  const { cycle } = useParams();
  const navigate = useNavigate();

  // On Approval function to trigger tab change and success snackbar popup
  const onApprove = () => {
    dispatch(updateReviewState({ data: {
      config_status: "A",
      reviewed: "Y",
      reason_rejected: null,
      config_set: "DEAL_TYPE_PER_CHANNEL",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }))

    handleSnackOpen("success", "Deal Cycle Parameters has been approved.");
    handleClickChangeTab();
  };

  // On Reject save function to trigger tab change and success snackbar popup
  const onRejectSave = () => {
    dispatch(updateReviewState({ data: {
      config_status: "R",
      reviewed: "Y",
      reason_rejected: message,
      config_set: "DEAL_TYPE_PER_CHANNEL",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }));

    handleSnackOpen("success", "Deal Cycle Parameters has been rejected.");
    handleClickChangeTab();
  }

  // Change Tab on Parent Component
  const handleClickChangeTab = () => {
    setTabValue(6);
  };

  // Rejected State
  const [rejected, setRejected] = useState(false);

  // Handle reject button
  const handleRejectChange = () => {
    setRejected(!rejected);
  }

  useEffect(() => {
    dispatch(getDealTypePerChannel({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set DATA sources
  let data = useSelector(getDealTypePerChannelData);

  const dispatch = useDispatch();

  // Group the handset data by OEM
  const groupedDataByCategory = {};
  data.forEach((item) => {
    if (!groupedDataByCategory[item.category]) {
      groupedDataByCategory[item.category] = [];
    }
    groupedDataByCategory[item.category].push(item);
  });

  // Term selection states and function
  const [term, setTerm] = useState(0);
  const handleTermChange = (_, term) => {
    setTerm(term);
  };

  return (
    <>
      <div className="mb-8 text-center">
        <ToggleButtonGroup
          value={term}
          exclusive
          onChange={handleTermChange}
          aria-label="Term selection"
        >
          <ToggleButton value={0}>24 Months</ToggleButton>
          <ToggleButton value={1}>36 Months</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TabPanel value={term} index={0}>
        <Table>
          {Object.keys(groupedDataByCategory)
            .reverse()
            .map((category, index) => (
              <React.Fragment key={category}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={`border-grey-100 pl-0 ${
                        index > 0 ? "pt-12" : "pt-0"
                      }`}
                      colSpan={groupedDataByCategory[category].map((item) => {
                        return item.channels.length + 1;
                      })}
                    >
                      <h2 className="text-3xl font-bold text-red-100">
                        {category === "D"
                          ? "Data"
                          : category === "V"
                          ? "Voice"
                          : ""}
                      </h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedDataByCategory[category]
                    .filter((deal) => deal.term === 24)
                    .map((item) => (
                      <TableRow key={item.id}>
                        <TableCell className="pl-0">
                          <span className="text-base font-bold">
                            {item.options}
                          </span>
                        </TableCell>
                        {item.channels.map((channel) => (
                          <TableCell
                            key={channel.label}
                            className="text-base"
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={
                                    channel.active === "Y" ? true : false
                                  }
                                  name="active"
                                />
                              }
                              label={channel.label}
                              labelPlacement="start"
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </React.Fragment>
            ))}
        </Table>
      </TabPanel>

      <TabPanel value={term} index={1}>
        <Table>
          {Object.keys(groupedDataByCategory)
            .reverse()
            .map((category, index) => (
              <React.Fragment key={category}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={`border-grey-100 pl-0 ${
                        index > 0 ? "pt-12" : "pt-0"
                      }`}
                      colSpan={groupedDataByCategory[category].map((item) => {
                        return item.channels.length + 1;
                      })}
                    >
                      <h2 className="text-3xl font-bold text-red-100">
                        {category === "D"
                          ? "Data"
                          : category === "V"
                          ? "Voice"
                          : ""}
                      </h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedDataByCategory[category]
                    .filter((deal) => deal.term === 36)
                    .map((item) => (
                      <TableRow key={item.id}>
                        <TableCell className="pl-0">
                          <span className="text-base font-bold">
                            {item.options}
                          </span>
                        </TableCell>
                        {item.channels.map((channel) => (
                          <TableCell
                            key={channel.label}
                            className="text-base"
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={
                                    channel.active === "Y" ? true : false
                                  }
                                  name="active"
                                />
                              }
                              label={channel.label}
                              labelPlacement="start"
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </React.Fragment>
            ))}
        </Table>
      </TabPanel>

      {(!setReviewState && !reviewStatus) && (
        <div className="mt-8 mb-12">
          <div className="flex justify-center gap-2 md:justify-end">
            <Button 
              color="error"
              variant="outlined" 
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={XCircleIcon}
                  width={24}
                  height={24}
                  ariaHidden
                />
              }
              onClick={handleRejectChange}
              disabled={rejected}
            >
              Reject
            </Button>
            <Button 
              color="success"
              variant="contained" 
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={TickOutlineCircle}
                  width={24}
                  height={24}
                  ariaHidden
                />
              }
              onClick={onApprove}
            >
              Approve
            </Button>
          </div>
  
          {rejected &&
            <div className="flex flex-col gap-6">
              <InputField
                inputLabel="Reason"
                placeholder="Enter your comments here..."
                multiline
                minRows={2}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
  
              <Button 
                variant="text"
                disableFocusRipple 
                className="text-red-100 self-end"
                onClick={onRejectSave}
              >
                  Save
              </Button>
            </div>
          }
        </div>
      )}
    </>
  );
}
