import axios from "axios";

const API_URL = "/api/deals/";

const getAdHocDealsTotals = () => {
  return axios.get(API_URL + "adhoc-deals-totals/");
};

const loadCampaign = (campaign_id) => {
  return axios.post(API_URL + "adhoc-deals/load-campaign-deals", {
    campaign_id,
  });
};

const loadAdHocCard = (
  campaign_id,
  channel_id,
  device_basket_id,
  deal_type_id
) => {
  return axios.post(API_URL + "adhoc-deals/load-adhoc-card-deals", {
    campaign_id,
    channel_id,
    device_basket_id,
    deal_type_id,
  });
};

// const getChildDealsForHeadlinesForChannel = (
//   cycle,
//   channel_id,
//   device_basket_id
// ) => {
//   return axios.get(
//     API_URL + cycle + "/channel-deals/" + channel_id + "/" + device_basket_id
//   );
// };

const getAdHocHeadlineDeals = (campaign_id) => {
  return axios.get(API_URL + "adhoc-deals/" + campaign_id);
};

const getCycleFromCampaign = (campaign_id) => {
  return axios.get(API_URL + "get-cycle-from-campaign/" + campaign_id);
};

const createNewCampaign = (data) => {
  return axios.post(API_URL + "adhoc-deals/campaigns", { ...data });
};

const createNewAdhocDeal = (deals) => {
  return axios.post(API_URL + "adhoc-deals", { deals });
};

const getAdhocDealsChannels = () => {
  return axios.get(API_URL + "adhoc-deals/channels");
};

const getAdHocsChildrenDeals = (dde_parent_keys) => {
  return axios.post(API_URL + "adhoc-deals/children", {
    dde_parent_keys: dde_parent_keys,
  });
};

const getCampaign = (campaign_id) => {
  return axios.get(API_URL + "adhoc-deals/campaign/" + campaign_id);
};

const updateAdHocHeadlineDeals = (deals) => {
  return axios.put(API_URL + "adhoc-deals", { deals });
};

const propagateAdHocHeadlineDeals = (deals) => {
  return axios.post(API_URL + "propagate-adhoc-deals", { deals });
};

const generateCampaignODBC = ({ campaign_id, campaign_name }) => {
  return axios
    .post(API_URL + "generate-campaign-odbc", {
      campaign_id,
      campaign_name,
    })
    .then((response) => {
      return response.data;
    });
};

const AdHocService = {
  getAdHocDealsTotals,
  getAdHocHeadlineDeals,
  getCycleFromCampaign,
  createNewCampaign,
  createNewAdhocDeal,
  getAdhocDealsChannels,
  getAdHocsChildrenDeals,
  getCampaign,
  updateAdHocHeadlineDeals,
  propagateAdHocHeadlineDeals,
  loadCampaign,
  loadAdHocCard,
  generateCampaignODBC,
};

export default AdHocService;
