import axios from "axios";

const API_URL = "/api/deals/";

const getDealCycle = (cycle_Id) => {
  return axios.get(API_URL + "cycles/" + cycle_Id);
};

const getDealCycles = () => {
  return axios.get(API_URL + "cycles");
};

const createDealCycle = (cycle_id) => {
  return axios.post(API_URL + "cycles", { cycle_id }).then((response) => {
    return response.data;
  });
};

const clearDealCycle = (cycle_id) => {
  return axios.delete(API_URL + "cycles/" + cycle_id).then((response) => {
    return response.data;
  });
};

const createDealConfig = (cycle_id) => {
  return axios.post(API_URL + "cycle-config", { cycle_id }).then((response) => {
    return response.data;
  });
};

const generateProtoHeadlines = (cycle_id) => {
  return axios
    .post(API_URL + "generate-proto-headlines", { cycle_id })
    .then((response) => {
      return response.data;
    });
};

const generateGenericChannelDeals = (cycle, channel) => {
  return axios
    .post(API_URL + "generic-channel-deals", { cycle, channel })
    .then((response) => {
      return response.data;
    });
};

const generateGenericDeals = (cycle) => {
  return axios.post(API_URL + "base-generics", { cycle }).then((response) => {
    return response.data;
  });
};

const getHeadlineDealsByOEM = (cycle, oem) => {
  return axios.get(API_URL + cycle + "/headlines/" + oem);
};

const getHeadlineDealsByChannel = (cycle, oem, channel_id) => {
  return axios.get(API_URL + cycle + "/headlines/" + oem + "/" + channel_id);
};

const getHeadlineSIMODeals = (cycle) => {
  return axios.get(API_URL + cycle + "/sim-only");
};

const getFlaggedHeadlineDeals = (
  cycle, 
  { oem }
  ) => {
  return axios.get(
    API_URL +
    cycle + 
    "/deal-approval/headlines/" +
    `?oem=${oem}`);
};

const getFlaggedChannelDeals = (
  cycle,
  { addon, oem, rom, deal_desc, order_asc, channel_id, deal_type_id }
  ) => {
  return axios.get(
    API_URL + 
    cycle + 
    "/deal-approval/channels/" + 
    `?addon=${addon}&oem=${oem}&rom=${rom}&deal_desc=${deal_desc}&order_asc=${order_asc}&channel_id=${channel_id}&deal_type_id=${deal_type_id}`);
};

const getTotalDealsPerOEM = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/peroem");
};

const getDealCycleDocuments = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/documents");
};

const getTotalBulkDeals = (cycle) => {
  return axios.get(API_URL + cycle + "/total-bulk-deals");
};

const getDealCycleTimeline = (cycle) => {
  return axios.get(API_URL + cycle + "/deal-cycle-timeline");
};

const getTotalGenericDeals = (cycle) => {
  return axios.get(API_URL + cycle + "/base-generics/total-deals");
};

const getTotalBulkDealsPerChannel = (cycle) => {
  return axios.get(API_URL + cycle + "/total-bulk-deals-per-channel");
};

const getTotalProtoHeadlineDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-proto-headline-deals");
};

const getTotalHeadlineDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-headline-deals");
};

const getTotalFlaggedDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-flagged-deals");
};

const getTotalAdHocDeals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/total-adhoc-deals");
};

const updateHeadlineDeals = (headline_deals) => {
  return axios.put(API_URL + "headlines/", { headline_deals });
};

const updateHeadlineSIMODeals = (headline_deals) => {
  return axios.put(API_URL + "headlines/sim-only", { headline_deals });
};

const getGenericDeviceList = (cycle, channel, oem) => {
  return axios.get(
    API_URL + cycle + "/bulk/" + channel + "/" + oem + "/device-list"
  );
};

const getBaseGenericDeviceList = (cycle, oem) => {
  return axios.get(API_URL + cycle + "/base-generics/" + oem + "/device-list");
};

const getGenericDeviceTariffList = (cycle, channel, oem, device) => {
  return axios.get(
    API_URL +
      cycle +
      "/bulk/" +
      channel +
      "/" +
      oem +
      "/" +
      device +
      "/device-tariff-list"
  );
};

const getBaseGenericDeviceTariffList = (cycle, oem, device) => {
  return axios.get(
    API_URL +
      cycle +
      "/base-generics/" +
      oem +
      "/" +
      device +
      "/device-tariff-list"
  );
};

const getGenericBulkDealsList = (cycle, channel, oem, device, tariff) => {
  return axios.get(
    API_URL +
      cycle +
      "/bulk/" +
      channel +
      "/" +
      oem +
      "/" +
      device +
      "/" +
      tariff
  );
};

const getBaseGenericDealsList = (cycle, oem, device, tariff) => {
  return axios.get(
    API_URL + cycle + "/base-generics/" + oem + "/" + device + "/" + tariff
  );
};

const getStandardVASList = () => {
  return axios.get(API_URL + "standard-vas-list");
};

const updateGenericChannelDeals = (deals) => {
  return axios.put(API_URL + "generic-channel-deals/", { deals });
};

const updateBaseGenericDeals = (deals) => {
  return axios.put(API_URL + "base-generics/", { deals });
};

const getOverviewTotals = (cycle_id) => {
  return axios.get(API_URL + cycle_id + "/overview-totals");
};

const getTotalEnr = (cycle) => {
  return axios.get(API_URL + cycle + "/total-enr");
};

const getFlaggedDeals = (cycle) => {
  return axios.get(API_URL + cycle + "/deal-approval");
};

const getTotalDeviceOnlyDeals = (cycle) => {
  return axios.get(API_URL + cycle + "/total-device-only-deals");
};

const getTotalSIMOnlyDeals = (cycle) => {
  return axios.get(API_URL + cycle + "/total-sim-only-deals");
};

const updateFlaggedDeals = (deals) => {
  return axios.post(API_URL + "deal-approval/", { deals });
};

const publishHeadlines = (cycle) => {
  return axios.post(API_URL + cycle + "/headlines");
};

const publishBaseGenerics = (cycle) => {
  return axios.post(API_URL + cycle + "/base-generic-deals");
};

const getTotalDealsBaseGenerics = (cycle) => {
  return axios.get(API_URL + cycle + "/base-generic-totals");
};

const getTariffDifferentials = () => {
  return axios.get(API_URL + "tariff-differentials");
};

const getStandardVASParameters = () => {
  return axios.get(API_URL + "standard-vas-parameters");
};

const getTotalDealsChannelGenerics = (cycle, channel) => {
  return axios.get(API_URL + cycle + "/generic-channel-totals/" + channel);
};

const getDealCycleParameters = (cycle) => {
  return axios.get(API_URL + cycle + "/deal-cycle-parameters");
};

const getOEMs = (cycle) => {
  return axios.get(API_URL + cycle + "/oems");
};

const getPreviousMonth = (cycle) => {
  return axios.get(API_URL + cycle + "/headlines/prev-month");
};

const DealService = {
  getDealCycle,
  getDealCycles,
  getDealCycleTimeline,
  createDealCycle,
  clearDealCycle,
  createDealConfig,
  generateProtoHeadlines,
  getHeadlineDealsByOEM,
  getHeadlineDealsByChannel,
  getHeadlineSIMODeals,
  getFlaggedHeadlineDeals,
  getFlaggedChannelDeals,
  getTotalDealsPerOEM,
  getDealCycleDocuments,
  getTotalBulkDeals,
  getTotalGenericDeals,
  getTotalProtoHeadlineDeals,
  getTotalHeadlineDeals,
  getTotalFlaggedDeals,
  getTotalAdHocDeals,
  updateHeadlineDeals,
  updateHeadlineSIMODeals,
  getGenericDeviceList,
  getBaseGenericDeviceList,
  getGenericDeviceTariffList,
  getBaseGenericDeviceTariffList,
  getGenericBulkDealsList,
  getBaseGenericDealsList,
  getTotalBulkDealsPerChannel,
  getStandardVASList,
  updateGenericChannelDeals,
  updateBaseGenericDeals,
  generateGenericChannelDeals,
  generateGenericDeals,
  getOverviewTotals,
  getTotalEnr,
  getFlaggedDeals,
  getTotalDeviceOnlyDeals,
  getTotalSIMOnlyDeals,
  updateFlaggedDeals,
  publishHeadlines,
  publishBaseGenerics,
  getTotalDealsBaseGenerics,
  getTariffDifferentials,
  getStandardVASParameters,
  getTotalDealsChannelGenerics,
  getDealCycleParameters,
  getOEMs,
  getPreviousMonth,
};

export default DealService;
