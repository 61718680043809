import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import uploadReducer from "./slices/upload";
import messageReducer from "./slices/message";
import dealReducer from "./slices/dealCanvas";
import cycleConfigReducer from "./slices/cycleConfigCanvas";
import snackbarReducer from "./slices/snackbar";
import systemConfigReducer from "./slices/system-config-canvas";

const reducer = {
  auth: authReducer,
  user: userReducer,
  upload: uploadReducer,
  message: messageReducer,
  deal: dealReducer,
  cycleConfig: cycleConfigReducer,
  systemConfig: systemConfigReducer,
  snackbar: snackbarReducer,
};

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});
