import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { setMessage } from "./message";
import SystemConfigService from "../services/settingsConfig.service";

export const getConfigSettings = createAsyncThunk(
  "settingsConfig/getConfigSettings",
  async (_, thunkAPI) => {
    try {
      const response = await SystemConfigService.getConfigSettings();
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  configSettings: [
    {
      id: 1,
      label: "Auto logout configuration",
      value: 15
    }
  ],
};

export const ConfigSettingsSlice = createSlice({
  name: "settingsConfig",
  initialState,
  reducers: {
    updateConfigSettings: (state, action) => {
      const { data } = action.payload;
      const { id, label, value } = data;
      const object = "configSettings"

      const updateSetting = state[object].map((setting) => {
        if (setting.id === id && setting.label === label) {
          return {
            ...setting,
            value: value,
            isUpdated: true,
          };
        } else {
          return setting;
        }
      });

      return {
        ...state,
        [object]: updateSetting,
      };
    },
  },
  extraReducers: {
    [getConfigSettings.fulfilled]: (state, action) => {
      state.configSettings = action.payload;
    },
  },
});

export const { updateConfigSettings } = ConfigSettingsSlice.actions;

export const getConfigSettingsData = createSelector(
  (state) => state?.settingsConfig?.configSettings,
  (configSettings) => configSettings
);


export default ConfigSettingsSlice.reducer;
