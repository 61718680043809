// React and MUI Imports
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputAdornment,
  MenuItem,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
} from "@mui/material";

// Service Imports
import DealService from "../../services/deal.service";
import AuthService from "../../services/auth.service";

// Component Imports
import BackButton from "../../components/back-button/back-button-component";
import InputField from "../../components/input/input-component";
import TitleBreadcrumb from "../../components/title-breadcrumb/title-breadcrumb-component";
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import { CustomTabScrollButton } from "../home/home-page";

// Icon Imports
import SearchIcon from "../../assets/icons/search-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { useSelector } from "react-redux";
import { getCycleTab } from "../../slices/dealCanvas";

export const SearchableTable = (props) => {
  const { data, searchProp, placeholder, noResultsMessage, renderRow } = props;
  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = data.filter((row) => {
    return row[searchProp].toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <>
      <InputField
        inputLabel="Search"
        value={searchText}
        onChange={handleSearch}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InlineSVG
                src={SearchIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-black"
              />
            </InputAdornment>
          ),
        }}
      ></InputField>

      <Table className="my-12">
        <TableBody>
          {filteredData.length === 0 ? (
            <TableRow>
              <TableCell className="text-center text-base text-black">
                {noResultsMessage}
              </TableCell>
            </TableRow>
          ) : (
            filteredData.map((row, index) => (
              <TableRow key={index}>{renderRow(row)}</TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default function DealConfiguration() {
  const navigate = useNavigate();

  // Cycle Tabs
  const [updatedDealCycle, setUpdatedDealCycle] = useState([]);

  const cycleTab = useSelector(getCycleTab);
  const [value, setValue] = useState(cycleTab);

  const [standardVASs, setStandardVASs] = useState([]);
  const [tariffDifferentials, setTariffDifferentials] = useState([]);
  const [vasOptions, setVASOptions] = useState([]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    DealService.getDealCycles()
      .then((response) => {
        setUpdatedDealCycle(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DealService.getTariffDifferentials()
      .then((response) => {
        setTariffDifferentials(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DealService.getStandardVASParameters()
      .then((response) => {
        setStandardVASs(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DealService.getStandardVASList().then((response) => {
      setVASOptions(response.data);
    });
  }, []);

  // Configuration Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-1 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton to="/home"></BackButton>
      </div>
      <section
        aria-labelledby="#dealConfigTitle"
        className="col-start-2 col-end-12 row-start-2"
      >
        <TitleBreadcrumb
          title="Deal Cycle Configuration"
          titleID="dealConfigTitle"
        />

        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          ScrollButtonComponent={CustomTabScrollButton}
          allowScrollButtonsMobile
          aria-label="Deal cycle selection"
          className="deal-cycle-nav my-12"
        >
          {updatedDealCycle.map((cycle, index) => (
            <Tab
              key={index}
              label={cycle.date}
              {...a11yProps(index)}
              disableRipple
              className={`${cycle.active ? "" : "cycle-not-done"}`}
            />
          ))}
        </Tabs>

        {updatedDealCycle.map((cycle, index) => (
          <TabPanel key={index} value={value} index={index}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Select a configuration type"
              className="mt-8 mb-6"
            >
              <Tab
                label="Tariff Differentials"
                {...a11yProps(0)}
                disableRipple
              />
              <Tab label="Standard VAS" {...a11yProps(1)} disableRipple />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <SearchableTable
                data={tariffDifferentials}
                searchProp="tariff"
                placeholder="Search by tariff name..."
                noResultsMessage="No tariffs found..."
                renderRow={(row) => (
                  <>
                    <TableCell className="min-w-[11rem] border-0 p-2 pl-0 text-base font-bold text-red-100">
                      {row.tariff}
                    </TableCell>
                    <TableCell className="w-1/4 border-0 p-2">
                      <InputField
                        inputLabel="Tariff Subscription"
                        defaultValue={row.tariffSubscription}
                      ></InputField>
                    </TableCell>
                    <TableCell className="w-1/4 border-0 p-2">
                      <InputField
                        inputLabel="SIM Only - Deal Subscription"
                        defaultValue={row.simOnlyDealSubscription}
                      ></InputField>
                    </TableCell>
                    <TableCell className="w-1/4 border-0 p-2">
                      <InputField
                        inputLabel="SIM Only - Tariff Differential"
                        defaultValue={row.simOnlyTariffDifferential}
                      ></InputField>
                    </TableCell>
                    <TableCell className="w-1/4 border-0 p-2 pr-0">
                      <InputField
                        inputLabel="Device Deal - Tariff Differential"
                        defaultValue={row.deviceDealTariffDifferential}
                      ></InputField>
                    </TableCell>
                  </>
                )}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <SearchableTable
                data={standardVASs}
                searchProp="tariff"
                placeholder="Search by tariff name..."
                noResultsMessage="No tariffs found..."
                renderRow={(row) => (
                  <>
                    <TableCell className="min-w-[11rem] border-0 p-2 pl-0 text-base font-bold text-red-100">
                      {row.tariff}
                    </TableCell>
                    <TableCell className="w-1/3 border-0 p-2">
                      <InputField
                        select
                        inputLabel="VAS 1"
                        defaultValue={row.primaryVAS || "placeholder"}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                        }}
                      >
                        <MenuItem disabled value="placeholder">
                          - Select -
                        </MenuItem>
                        {vasOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </TableCell>
                    <TableCell className="w-1/3 border-0 p-2">
                      <InputField
                        select
                        inputLabel="VAS 2"
                        defaultValue={row.secondaryVAS || "placeholder"}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                        }}
                      >
                        <MenuItem disabled value="placeholder">
                          - Select -
                        </MenuItem>
                        {vasOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </TableCell>
                    <TableCell className="w-1/3 border-0 p-2">
                      <InputField
                        select
                        inputLabel="VAS 3"
                        defaultValue={row.tertiaryVAS || "placeholder"}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                        }}
                      >
                        <MenuItem disabled value="placeholder">
                          - Select -
                        </MenuItem>
                        {vasOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </TableCell>
                  </>
                )}
              />
            </TabPanel>
          </TabPanel>
        ))}
      </section>
    </>
  );
}
