// React Imports
import React, { useId, useRef, useState } from "react";

// Mui Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";

// Redux Store Imports
import { useDispatch } from "react-redux";
import { addTerminalsComments } from "../../slices/dealCanvas";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";
import InputField from "../input/input-component";
import TabPanel, { a11yProps } from "../tab-panel/tab-panel-component";
import CustomDatePicker from "../datepicker/datepicker-component";
import Radialbar from "../radialbar/radialbar-component";
import HeadlineDealInfo from "../headline-deal-info/headline-deal-info-component";

// Icon Imports
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import CloseIcon from "../../assets/icons/close-icon.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import InfoIcon from "../../assets/icons/info-icon.svg";
//import HeadlineService from "../../services/headline.service";

export default function HeadlineDealCard(props) {
  const {
    id,
    devices,
    terminal,
    vodacomInfo,
    competitorInfo,
    newDevice,
    dataHasChanged,
    moreInfo,
    multiAccessory,
    multiDevice,
    multiTariff,
    multiRouter,
    multiVAS,
    cardGlobalLevel,
    termSpecific,
    createDeal,
    channel_id,
    isNotValid,
  } = props.data;

  const {
    dealType,
    addOns,
    terminalsUser,
    childrenDeals,
    deviceOptions,
    accessoryOptions,
    tariffOptions,
    routerOptions,
    vasOptions,
    deleteDeal,
    deleteDealTerm,
    createAddOnDeal,
    //cycle,
    createButtonDisable = false,
    view = "default",
    isSIMO = false,
    confirmSupplierAmbitions,
    user = {},
  } = props;

  // Redux dispatch
  const dispatch = useDispatch();

  // Unique ID with custom text for styling in some components
  const uid = useId();

  // Parent card reference
  const mainRef = useRef(null);
  let tariff24;
  const [tariffTerm24] = termSpecific?.filter((v) => v.term === 24);
  if (tariffTerm24) {
    tariff24 = tariffTerm24.tariff_code;
  }
  let tariff30;
  const [tariffTerm30] = termSpecific?.filter((v) => v.term === 30);
  if (tariffTerm30) {
    tariff30 = tariffTerm30.tariff_code;
  }
  let tariff36;
  const [tariffTerm36] = termSpecific?.filter((v) => v.term === 36);
  if (tariffTerm36) {
    tariff36 = tariffTerm36.tariff_code;
  }

  // Deal Keys
  const { main_device_basket_id: deviceBasketId } = cardGlobalLevel;
  let tariffBasketId24;
  let ddeParentKey24;
  try {
    tariffBasketId24 = termSpecific[0].tariff_basket_id;
    ddeParentKey24 = termSpecific[0].dde_parent_key;
  } catch (ex) {}
  let tariffBasketId30;
  let ddeParentKey30;
  try {
    tariffBasketId30 = termSpecific[1].tariff_basket_id;
    ddeParentKey30 = termSpecific[1].dde_parent_key;
  } catch (ex) {}
  let tariffBasketId36;
  let ddeParentKey36;
  try {
    tariffBasketId36 = termSpecific[2].tariff_basket_id;
    ddeParentKey36 = termSpecific[2].dde_parent_key;
  } catch (ex) {}

  // Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  // Dialogs
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [informationModal, setInformationModal] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
    setInformationModal(false);
  };

  // Handle delete deal option
  const handleDeleteDeal = (dealID, dealType) => {
    const deal = {
      channel_id: channel_id,
      device_basket_id: deviceBasketId,
      tariff_basket_id_24: tariffBasketId24,
      tariff_basket_id_30: tariffBasketId30,
      tariff_basket_id_36: tariffBasketId36,
      dde_parent_keys: [ddeParentKey24, ddeParentKey30, ddeParentKey36],
    };
    deleteDeal(dealID, dealType, deal);
  };
  const handleDeleteDealTerm = (
    dealID,
    dealType,
    term,
    tariffBasketId,
    ddeParentKey
  ) => {
    const deal = {
      channel_id: channel_id,
      device_basket_id: deviceBasketId,
      term: term,
      tariff_basket_id_24: term === 24 ? tariffBasketId : 0,
      tariff_basket_id_30: term === 30 ? tariffBasketId : 0,
      tariff_basket_id_36: term === 36 ? tariffBasketId : 0,
      dde_parent_key: ddeParentKey,
    };
    deleteDealTerm(dealID, dealType, deal);
  };

  // Handle mark deal as approved
  // const handleApproveDeal = (dealID) => {
  //   dispatch(approveDeal({ id: dealID, type: dealType }));
  // };

  // Handle comments
  const [comment, setComment] = useState(terminal.comments);

  const handleCommentChange = (event) => {
    const value = event.target.value;
    setComment(value);
  };

  const handleTerminalsComments = (dealID, dealType, comments) => {
    dispatch(addTerminalsComments({ id: dealID, type: dealType, comments }));
  };

  // Expand/Collapse Deal Card Content
  const [expandContent, setExpandContent] = useState(true);
  const handleExpandContent = () => {
    setExpandContent(!expandContent);
  };

  return (
    <>
      <div
        ref={mainRef}
        className={`
          flex flex-col gap-4 rounded-lg border-2 border-solid bg-white p-8 shadow 
          ${
            isNotValid
              ? "border-red-200"
              : createDeal
              ? "border-green-100"
              : dataHasChanged
              ? "border-yellow-200"
              : "border-transparent"
          }
        `}
        aria-labelledby={`#${id}`}
        id={`p${id}`}
      >
        <div className="flex items-center">
          {isSIMO ? (
            <>
              {!terminalsUser && (
                <IconButton
                  aria-label="Delete deal"
                  className="fill-black hover:fill-red-100"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  <InlineSVG
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                </IconButton>
              )}
            </>
          ) : (
            <>
              <IconButton
                aria-label="More information"
                className="fill-black hover:fill-red-100"
                onClick={() => setInformationModal(true)}
              >
                <InlineSVG src={InfoIcon} width={24} height={24} ariaHidden />
              </IconButton>

              {!terminalsUser && (
                <IconButton
                  aria-label="Delete deal"
                  className="fill-black hover:fill-red-100"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  <InlineSVG
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                </IconButton>
              )}
            </>
          )}

          <IconButton
            aria-label="Expand/Collapse deal information"
            className={`ml-auto text-[24px] text-grey-300 transition-transform duration-150 ease-linear ${
              expandContent ? "rotate-180" : "rotate-0"
            }`}
            onClick={handleExpandContent}
          >
            <ExpandMoreRoundedIcon />
          </IconButton>
        </div>

        {devices.map((item) => (
          <HeadlineDealInfo
            key={id}
            id={id}
            deviceId={item.deviceId}
            device={item.device}
            specs={item.specs}
            previousDealCycle={item.previousDealCycle}
            currentDealCycle={item.currentDealCycle}
            supplierAmbitions={item.supplierAmbitions}
            addOns={addOns}
            multiAccessory={multiAccessory}
            multiDevice={multiDevice}
            multiTariff={multiTariff}
            multiRouter={multiRouter}
            multiVAS={multiVAS}
            dealType={dealType}
            messageFromActions={props.messageFromActions}
            expandContent={expandContent}
            terminalsUser={terminalsUser}
            newDevice={newDevice}
            dataHasChanged={dataHasChanged}
            childrenDeals={childrenDeals}
            deviceOptions={deviceOptions}
            accessoryOptions={accessoryOptions}
            tariffOptions={tariffOptions}
            routerOptions={routerOptions}
            vasOptions={vasOptions}
            tariff24={tariff24}
            tariff30={tariff30}
            tariff36={tariff36}
            cardGlobalLevel={cardGlobalLevel}
            termSpecific={termSpecific}
            createDeal={createDeal}
            createAddOnDeal={createAddOnDeal}
            createButtonDisable={createButtonDisable}
            deleteDealTerm={handleDeleteDealTerm}
            deal={props.data}
            view={view}
            isSIMO={isSIMO}
            confirmSupplierAmbitions={confirmSupplierAmbitions}
            user={user}
          />
        ))}
      </div>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "delete-dialog-title"}
      >
        <DialogTitle id={uid + "delete-dialog-title"}>
          Delete this deal?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to permanently delete this deal. Are you sure you want
            to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleDeleteDeal(id, dealType);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth={true}
        open={informationModal}
        onClose={handleDialogClose}
        aria-label={`More information`}
      >
        <DialogTitle className="py-4 px-5 text-right">
          <IconButton
            aria-label="Close more information"
            onClick={handleDialogClose}
          >
            <InlineSVG
              src={CloseIcon}
              ariaHidden
              width={32}
              height={32}
              className="fill-black"
            />
          </IconButton>
        </DialogTitle>
        {terminalsUser ? (
          <DialogContent className="px-12 pb-12">
            <div className="grid grid-cols-2 gap-y-10 gap-x-12 lg:divide-x lg:divide-grey-50">
              <div className="col-span-2 flex flex-col gap-10 lg:col-span-1 lg:pt-4">
                <div className="flex justify-between">
                  <p className="font-bold">Network:</p>
                  <p>{terminal.network}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">Plan:</p>
                  <p>{terminal.plan}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">Sales (Feb. 2023):</p>
                  <p>{terminal.previousSales}</p>
                </div>
                <div className="flex justify-between">
                  <p className="font-bold">Stock-on-hand (Mar. 2023):</p>
                  <p>{terminal.currentSales}</p>
                </div>
              </div>
              <div className="col-span-2 flex flex-col gap-5 lg:col-span-1 lg:pl-12">
                <div className="flex items-center justify-between">
                  <p className="font-bold">New Device Deal Launch:</p>
                  <CustomDatePicker />
                </div>
                <div className="flex items-center justify-between">
                  <p className="font-bold">End-of-life:</p>
                  <CustomDatePicker />
                </div>
                <div className="mt-2">
                  <InputField
                    inputLabel="Comments"
                    placeholder="Enter your comments here..."
                    multiline
                    minRows={2}
                    value={comment ?? undefined}
                    onChange={handleCommentChange}
                    onBlur={(event) => {
                      const comments = event.target.value;
                      handleTerminalsComments(id, dealType, comments);
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent className="px-12 pb-12">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="More information content grouped in tabs"
              className="mb-8"
              centered
            >
              <Tab
                label="Deal Info."
                {...a11yProps(`extra${uid}0`)}
                disableRipple
              />
              <Tab
                label="Competitor Info."
                {...a11yProps(`extra${uid}1`)}
                disableRipple
              />
              <Tab
                label="Additional Info."
                {...a11yProps(`extra${uid}2`)}
                disableRipple
              />
            </Tabs>

            <TabPanel value={tabValue} index={0} id={`extra${uid}0`}>
              <div className="grid grid-cols-2 gap-y-10 gap-x-12 lg:divide-x lg:divide-grey-50">
                <div className="col-span-2 flex flex-col justify-between gap-6 lg:col-span-1 lg:pt-4">
                  <div className="flex justify-between">
                    <p className="font-bold">Term Differential:</p>
                    <p>{vodacomInfo.termDifferential}%</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-bold">Device Cost:</p>
                    <p>
                      {parseFloat(
                        vodacomInfo.previousDeviceCost
                      ).toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="font-bold">CTC:</p>
                    <p>
                      {vodacomInfo.previousCtc.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                </div>
                <div className="col-span-2 flex items-center justify-center lg:col-span-1 lg:pl-12">
                  <div className="grid grid-cols-2 gap-x-24 lg:gap-x-12 xl:gap-x-24">
                    <div className="col-span-1 flex flex-col items-center justify-center gap-y-8">
                      <Radialbar
                        value={+vodacomInfo.previousEff}
                        color="#EB9700"
                        label="EFF"
                        width={82}
                        height={82}
                      />
                      <div className="text-center">
                        <p className="mb-1 text-3xl font-light text-yellow-200">
                          {+vodacomInfo.previousNewLine}
                        </p>
                        <p className="text-base font-bold text-black">
                          New Line
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center justify-center gap-y-8">
                      <Radialbar
                        value={+vodacomInfo.previousInt}
                        color="#E60000"
                        label="INT"
                        width={82}
                        height={82}
                      />
                      <div className="text-center">
                        <p className="mb-1 text-3xl font-light text-red-100">
                          {+vodacomInfo.previousRetention}
                        </p>
                        <p className="text-base font-bold text-black">
                          Retention
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1} id={`extra${uid}1`}>
              <div className="grid grid-cols-2 gap-y-10">
                {competitorInfo ? (
                  competitorInfo.map((item, index) => (
                    <div
                      className="col-span-2 flex flex-col gap-5 odd:border-r odd:border-solid odd:border-grey-50 odd:pr-12 even:pl-12 lg:col-span-1"
                      key={item.competitor}
                    >
                      <p className="text-center text-base font-bold text-black">
                        {item.competitor}
                      </p>
                      <div className="flex items-center justify-between gap-4">
                        <InputLabel
                          className="text-base font-bold text-black"
                          id={uid + "competitor-deal-price-" + index}
                        >
                          Deal Price:
                        </InputLabel>
                        <InputField
                          inputProps={{
                            "aria-labelledby":
                              uid + "competitor-deal-price-" + index,
                          }}
                          placeholder="R 0,00"
                          defaultValue={item.dealPrice || ""}
                        />
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <InputLabel
                          className="text-base font-bold text-black"
                          id={uid + "competitor-plan-" + index}
                        >
                          Plan:
                        </InputLabel>
                        <InputField
                          inputProps={{
                            "aria-labelledby": uid + "competitor-plan-" + index,
                          }}
                          placeholder="Enter competitor plan..."
                          defaultValue={item.plan || ""}
                        />
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <InputLabel
                          className="text-base font-bold text-black"
                          id={uid + "competitor-value-" + index}
                        >
                          Value:
                        </InputLabel>
                        <InputField
                          inputProps={{
                            "aria-labelledby":
                              uid + "competitor-value-" + index,
                          }}
                          placeholder="Enter competitor value..."
                          defaultValue={item.value || ""}
                        />
                      </div>
                      <div className="my-5 flex justify-between gap-4">
                        <p className="font-bold">Discount to Fair Value:</p>
                        <p>{item.discountFairValue}%</p>
                      </div>
                      <div className="flex justify-between gap-4">
                        <p className="font-bold">Fair Value Differential:</p>
                        <p>{item.fairValueDiff}%</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-2">
                    <p className="text-base text-black">
                      No competitor information available.
                    </p>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={2} id={`extra${uid}2`}>
              <p className="text-xl font-bold">{moreInfo.deviceFullTitle}</p>
              <hr className="my-4 border-grey-100 opacity-100" />
              <div className="mt-8 grid grid-cols-8 gap-x-12">
                <div className="col-span-3 flex flex-col gap-6">
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">Main CID:</p>
                    <p className="text-base text-black">
                      {moreInfo.productCode}
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">Tariff:</p>
                    <p className="text-base text-black">{moreInfo.tariff}</p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">Subs:</p>
                    <p className="text-base text-black">
                      {moreInfo.subs.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">
                      Device Cost (Prev. Month):
                    </p>
                    <p className="text-base text-black">
                      {moreInfo.previousDeviceCost.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">
                      CTD (Prev. Month):
                    </p>
                    <p className="text-base text-black">
                      {moreInfo.previousCtd.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                </div>
                <div className="col-span-3 col-start-5 flex flex-col gap-6">
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">
                      RRP (Prev. Month):
                    </p>
                    <p className="text-base text-black">
                      {moreInfo.previousRrp.toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">
                      RRP (Current Month):
                    </p>
                    <p className="text-base text-black">
                      {parseFloat(moreInfo.currentRrp).toLocaleString("en-ZA", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">
                      SIMO RRP (Current Month):
                    </p>
                    <p className="text-base text-black">
                      {moreInfo.currentSimoRrp}%
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-base font-bold text-black">
                      Discount to Fair Value:
                    </p>
                    <p className="text-base text-black">
                      {moreInfo.discountFairValue}%
                    </p>
                  </div>
                </div>
              </div>
              <hr className="mt-6 mb-8 border-grey-100 opacity-100" />
              <p className="text-lg font-bold text-black">Differentials</p>
              <div className="mt-6 grid grid-cols-8 gap-x-12">
                <div className="col-span-3 flex flex-col gap-6">
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">DP:</p>
                    <p className="text-base text-black">
                      {moreInfo.differentials.dp}%
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">CIB:</p>
                    <p className="text-base text-black">
                      {moreInfo.differentials.cib}%
                    </p>
                  </div>
                </div>
                <div className="col-span-3 col-start-5 flex flex-col gap-6">
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">EFF:</p>
                    <p className="text-base text-black">
                      {moreInfo.differentials.eff}%
                    </p>
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <p className="text-base font-bold text-black">
                      Reduced Subs:
                    </p>
                    <p className="text-base text-black">
                      {moreInfo.differentials.reducedSubs}
                    </p>
                  </div>
                </div>
              </div>
            </TabPanel>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}
