import { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";

// Logout Page
export default function Logout() {
  // 5 second count down
  const [timeLeft, setTimeLeft] = useState(2);

  useEffect(() => {
    if (timeLeft <= 0) {
      AuthService.logout("/logout")
        .then((data) => {
          const { redirectURL } = data;
          window.location.href = redirectURL;
        })
        .catch(() => {
          AuthService.getLogoutRedirectURL().then((data) => {
            const { redirectURL } = data;
            window.location.href = redirectURL;
          });
        });
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);
  return (
    <section
      aria-labelledby="errorPageTitle"
      className="col-start-2 col-end-12 my-6 grid min-h-[calc(100vh-137px)] grid-cols-10 place-items-center gap-4"
    >
      <div className="col-span-10 text-center md:col-span-5">
        <h1 className="mb-4 text-6xl font-bold text-black" id="errorPageTitle">
          Logged out!
        </h1>
        <p className="text-lg font-medium text-black"></p>
      </div>
      <div className="col-span-10 text-center md:col-span-5">
        {/* <img
          src={AstronautOnCloud}
          width={400}
          height={475}
          alt="Astronaut sitting on a cloud with balloons attached"
        /> */}
      </div>
    </section>
  );
}
