// React and Mui Imports
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Slide,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Redux Imports
import {
  addDeal,
  clearChannelDeals,
  deleteDeal,
  deleteDealTerm,
  getChannelDealersAndFranchiseDeals,
  getChannelDeals,
  getChannelDirectDeals,
  getChannelHeadlineDealsForChannel,
  getChannelNationalChainsDeals,
  getChannelOnlineDeals,
  getUpdatedChannelAddOnDeals,
  getUpdatedChannelDeals,
} from "../../../slices/dealCanvas";
import { useDispatch, useSelector } from "react-redux";

// Service Imports
import AuthService from "../../../services/auth.service";
import DealService from "../../../services/deal.service";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import HeadlineDealCard from "../../../components/headline-deal-cards/headline-deal-cards-component";
import BackButton from "../../../components/back-button/back-button-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import DealCycleTotals from "../../../components/deal-cycle-totals/deal-cycle-totals-component";
import MultiSelectDropdownWithSearch from "../../../components/multiselect-dropdown-with-search/multiselect-dropdown-with-search-component";
import TabPanel from "../../../components/tab-panel/tab-panel-component";
import CreateNewDealCard from "../../../components/create-new-deal-card/create-new-deal-card-component";

// Snack Settings
import { snackSettings } from "../../home/home-page";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SaveIcon from "../../../assets/icons/save-icon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Generate unique id for deals
import { v4 as uuidv4 } from "uuid";
import GeneralService from "../../../services/general.service";
import ChannelService from "../../../services/channel.service";

const _overviewTotals = {
  totalCostToConnect: "R 0.00",
  totalReducedSubscription: "R 0.00",
  totalFinanced: "R 0.00",
  totalEfficiency: "0.00%",
  totalIntensity: "0.00%",
};

const buildNewDeal = (data) => {
  return {
    id: uuidv4(),
    type: "handset",
    oem: data.oem,
    approvedDeal: false,
    devices: [
      {
        deviceId: uuidv4(),
        device: data.device.label,
        specs: {
          size: "",
          ram: "",
          rom: "",
          sim: "",
        },
        previousDealCycle: {
          ctd: 0,
          term24: 0,
          term36: 0,
        },
        currentDealCycle: {
          ctd: 0,
          term24: 0,
          term30: 0,
          term36: 0,
        },
        supplierAmbitions: {
          ctd: 0,
          term24: 0,
          term36: 0,
          eff: 0,
          int: 0,
          ctc: 0,
          ctc36: 0,
          eff36: 0,
          tariffDiscount: 0,
        },
      },
    ],
    terminal: {
      network: "",
      plan: 0,
      previousSales: 0,
      currentSales: 0,
      comments: "",
    },
    vodacomInfo: {
      termDifferential: 0,
      previousDeviceCost: 0,
      previousCtc: 0,
      previousEff: 0,
      previousInt: 0,
      previousNewLine: 0,
      previousRetention: 0,
    },
    competitorInfo: [
      {
        competitor: "MTN",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: -0,
        fairValueDiff: 0,
      },
      {
        competitor: "Telkom",
        dealPrice: 0,
        plan: "",
        value: 0,
        discountFairValue: 0,
        fairValueDiff: 0,
      },
    ],
    moreInfo: {
      deviceFullTitle: "",
      productCode: 0,
      tariff: 0,
      subs: 0,
      previousDeviceCost: 0,
      previousCtd: 0,
      previousRrp: 0,
      currentRrp: 0,
      currentSimoRrp: 0,
      discountFairValue: 0,
      differentials: {
        dp: 0,
        eff: 0,
        cib: 0,
        reducedSubs: "",
      },
    },
    dataHasChanged: false,
    tariff_basket_id: 0,
    cycle_id: 0,
    multiAccessory: [],
    multiDevice: [],
    multiTariff: [],
    multiRouter: data.router ? [{ id: uuidv4(), cid: data.router.value }] : [],
    channel_id: data.channel_id,
    cardGlobalLevel: {
      main_device_basket_id: 0,
      main_cid: data.device.value,
      total_basket_price: 0,
      prev_ctd: 0,
      prev_deal_price_24: 0,
      prev_deal_price_30: 0,
      prev_deal_price_36: 0,
      ctd: 0,
      is_add_on: true,
    },
    termSpecific: [
      {
        tariff_basket_id: 0,
        term: 24,
        tariff_code: data.tariffCode24,
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
      {
        tariff_basket_id: 0,
        term: 30,
        tariff_code: data.tariffCode30,
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
      {
        tariff_basket_id: 0,
        term: 36,
        tariff_code: data.tariffCode36,
        deal_price: 0,
        ctc: 0,
        discount: 0,
        efficiency: 0,
      },
    ],
    createDeal: true,
    isNotValid: false,
  };
};

// Sorting Options
const sortingOptions = [
  {
    heading: "OEM",
    options: [
      {
        value: "oem_asc",
        label: "A - Z",
      },
      {
        value: "oem_desc",
        label: "Z - A",
      },
    ],
  },
  {
    heading: "Device",
    options: [
      {
        value: "device_asc",
        label: "A - Z",
      },
      {
        value: "device_desc",
        label: "Z - A",
      },
    ],
  },
];

export default function ChannelDealsDeviceList() {
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Redux dispatch

  // Get the Cycle and Channel information from the URL
  const { cycle, channel, channel_id } = useParams();

  const [overviewTotals, setOverviewTotals] = useState(_overviewTotals);

  // General Data
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [tariffOptions, setTariffOptions] = useState([]);
  const [routerOptions, setRouterOptions] = useState([]);
  const [vasOptions, setVASOptions] = useState([]);
  const [accessoryOptions, setAccessoryOptions] = useState([]);
  const [oemOptions, setOEMOptions] = useState([]);
  const [oemDevices, setOEMDevices] = useState([]);
  const [oemAccessories, setOEMAccessories] = useState([]);
  const [allDevicesOptions, setAllDevicesOptions] = useState([]);
  const [oemDevicesAccessories, setOEMDevicesAccessories] = useState([]);

  // Deals loaded form Redux store
  const channelDeals = useSelector(getChannelDeals);
  const updatedChannelDeals = useSelector(getUpdatedChannelDeals);
  const updatedChannelAddOnDeals = useSelector(getUpdatedChannelAddOnDeals);
  const channelOnlineDeals = useSelector(getChannelOnlineDeals);
  const channelDirectDeals = useSelector(getChannelDirectDeals);
  const channelNationalChainsDeals = useSelector(getChannelNationalChainsDeals);
  const channelDealersAndFranchiseDeals = useSelector(
    getChannelDealersAndFranchiseDeals
  );
  const [newDealCardID, setNewDealCardID] = useState("");

  const [dealType, setDealType] = useState("channelDeals");

  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    const queryOptions = {
      addon: "N",
      oem: "",
      rom: "",
      deal_desc: "",
      order_asc: "Y",
      deal_type_id: "",
    };
    dispatch(
      getChannelHeadlineDealsForChannel({ cycle, channel_id, queryOptions })
    )
      .unwrap()
      .then((_) => {
        // TODO: Update Loader
        setDataLoaded(true);
      })
      .catch(() => {
        // TODO: Handle catch
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Filters
  const [filterByOptions, setFilterByOptions] = useState([]);

  // Get OEM
  useEffect(() => {
    GeneralService.getOEMs(cycle, "channels", channel_id)
      .then((response) => {
        const { data: OEMs } = response;

        const _OEMs = OEMs.map((item) => {
          return { value: item.name, label: item.name };
        });
        GeneralService.getDeviceROMs(cycle, "channels", channel_id)
          .then((response_2) => {
            const { data: ROMs } = response_2;

            const _ROMs = ROMs.map((item) => {
              return { value: item.name, label: item.name };
            });
            const newfilterByOptions = [
              {
                heading: "Device Specifications",
                options: [..._ROMs],
              },
              {
                heading: "OEM",
                options: [..._OEMs],
              },
            ];
            setFilterByOptions(newfilterByOptions);
          })
          .catch((error) => {
            handleServerError(error);
          });
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get Channel OEM Devices
  useEffect(() => {
    ChannelService.getChannelOEMDevices(cycle, channel_id)
      .then((response) => {
        const { data } = response;

        const listA = data
          .filter((v) => v.tariff_type === "V")
          .reduce((result, item) => {
            const oemIndex = result.findIndex((el) => el.oem === item.oem);

            if (oemIndex === -1) {
              // If the oem doesn't exist in listA, create a new oem object.
              result.push({
                oem: item.oem,
                devices: [
                  {
                    value: item.cid,
                    label: item.model,
                    tariff_type: item.tariff_type,
                    object_type: item.object_type,
                  },
                ],
              });
            } else {
              // If the oem already exists in listA, push the option to its options array.
              result[oemIndex].devices.push({
                value: item.cid,
                label: item.model,
                tariff_type: item.tariff_type,
                object_type: item.object_type,
              });
            }

            return result;
          }, []);

        setOEMDevices(listA);

        const listB = [
          ...new Set(
            data.filter((v) => v.tariff_type === "V").map((item) => item.oem)
          ),
        ].map((oem) => ({
          value: oem,
          label: oem,
        }));

        setOEMOptions(listB);

        const listC = data
          .filter((v) => v.object_type === "A")
          .reduce((result, item) => {
            const oemIndex = result.findIndex((el) => el.oem === item.oem);

            if (oemIndex === -1) {
              // If the oem doesn't exist in listA, create a new oem object.
              result.push({
                oem: item.oem,
                devices: [
                  {
                    value: item.cid,
                    label: item.model,
                    tariff_type: item.tariff_type,
                    object_type: item.object_type,
                  },
                ],
              });
            } else {
              // If the oem already exists in listA, push the option to its options array.
              result[oemIndex].devices.push({
                value: item.cid,
                label: item.model,
                tariff_type: item.tariff_type,
                object_type: item.object_type,
              });
            }

            return result;
          }, []);

        setOEMAccessories(listC);

        const listD = data.reduce((result, item) => {
          const oemIndex = result.findIndex((el) => el.oem === item.oem);

          if (oemIndex === -1) {
            // If the oem doesn't exist in listA, create a new oem object.
            result.push({
              oem: item.oem,
              devices: [
                {
                  value: item.cid,
                  label: item.model,
                  tariff_type: item.tariff_type,
                  object_type: item.object_type,
                },
              ],
            });
          } else {
            // If the oem already exists in listA, push the option to its options array.
            result[oemIndex].devices.push({
              value: item.cid,
              label: item.model,
              tariff_type: item.tariff_type,
              object_type: item.object_type,
            });
          }

          return result;
        }, []);

        setOEMDevicesAccessories(listD);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ChannelService.getAllChannelDevices(cycle, channel_id)
      .then((response) => {
        setAllDevicesOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getDevices(cycle, "Samsung")
      .then((response) => {
        setDeviceOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getAccessories(cycle, "Samsung")
      .then((response) => {
        setAccessoryOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getTariffs(cycle)
      .then((response) => {
        setTariffOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getRouters(cycle)
      .then((response) => {
        setRouterOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    DealService.getTotalEnr(cycle)
      .then((response) => {
        setOverviewTotals(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GeneralService.getActiveVASes(cycle)
      .then((response) => {
        setVASOptions(response.data);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryChannelDeals = () => {
    //setDataLoaded(false);
    // Initialize arrays to store selected items in OEM List and ROM List
    const oemList = [];
    const romList = [];

    // Iterate through the lists array
    filterByOptions.forEach((list) => {
      list.options.forEach((option) => {
        if (filterBy.includes(option.value)) {
          // Check if the option's value is in the selectedItems array
          if (list.heading === "OEM") {
            oemList.push(option.value);
          } else if (list.heading === "Device Specifications") {
            romList.push(option.value);
          }
        }
      });
    });

    const queryOptions = {
      addon: category === 0 ? "N" : "Y",
      oem: oemList.length > 0 ? oemList : "",
      rom: romList.length > 0 ? romList : "",
      deal_desc: searchText,
      order_asc: sortOrder,
      deal_type_id: "",
    };

    dispatch(
      getChannelHeadlineDealsForChannel({ cycle, channel_id, queryOptions })
    )
      .unwrap()
      .then((_) => {})
      .catch(() => {});
  };

  useEffect(() => {
    switch (channel) {
      case "Online":
        setDealType("channelOnlineDeals");
        //setChannelDeals(channelOnlineDeals);
        break;
      case "Direct":
        setDealType("channelDirectDeals");
        //setChannelDeals(channelDirectDeals);
        break;
      case "Dealers & Franchise":
        setDealType("channelNationalChainsDeals");
        //setChannelDeals(channelNationalChainsDeals);
        break;
      case "National Chains":
        setDealType("channelDealersAndFranchiseDeals");
        //setChannelDeals(channelDealersAndFranchiseDeals);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channel,
    channelOnlineDeals,
    channelDirectDeals,
    channelNationalChainsDeals,
    channelDealersAndFranchiseDeals,
  ]);

  // Deals Table Search, Sort and Filter options
  const [filteredDeals, setFilteredDeals] = useState(channelDeals);
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortOrder, setSortOrder] = useState("");

  // Handle search event
  const handleSearch = (event) => {
    dispatch(clearChannelDeals());
    const { value } = event.target;
    setSearchText(value);
  };

  // Handle sorting event
  const handleSort = (event) => {
    dispatch(clearChannelDeals());
    setSortOrder(event.target.value);
  };

  const handleFilterBy = (data) => {
    dispatch(clearChannelDeals());
    setFilterBy(data);
  };

  // Handle data save
  const handleSave = () => {
    const updatedChannelHeadlineDeals = updatedChannelDeals
      .filter((item) => item.cardGlobalLevel.is_add_on === false)
      .map((item) => ({
        channel_id: item.channel_id,
        deal_price_curr_24: item.devices[0].currentDealCycle.term24,
        deal_price_curr_30: item.devices[0].currentDealCycle.term30,
        deal_price_curr_36: item.devices[0].currentDealCycle.term36,
        dde_parent_key_24: item.termSpecific[0].dde_parent_key,
        dde_parent_key_30: item.termSpecific[1].dde_parent_key,
        dde_parent_key_36: item.termSpecific[2].dde_parent_key,
      }));

    const updatedChannelAddOnHeadlineDeals = updatedChannelAddOnDeals.map(
      (item) => ({
        main_cid: item.cardGlobalLevel.main_cid,
        device_basket_items: [
          ...item.multiDevice,
          ...item.multiAccessory,
          ...item.multiRouter,
        ],
        main_tariff_code_24: item.termSpecific[0].tariff_code,
        main_tariff_code_30: item.termSpecific[1].tariff_code,
        main_tariff_code_36: item.termSpecific[2].tariff_code,
        tariff_basket_items_24: [...item.multiTariff],
        tariff_basket_items_30: [...item.multiTariff],
        tariff_basket_items_36: [...item.multiTariff],
        deal_price_curr_24: item.devices[0].currentDealCycle.term24,
        deal_price_curr_30: item.devices[0].currentDealCycle.term30,
        deal_price_curr_36: item.devices[0].currentDealCycle.term36,
        dde_parent_key_24: item.termSpecific[0].dde_parent_key,
        dde_parent_key_30: item.termSpecific[1].dde_parent_key,
        dde_parent_key_36: item.termSpecific[2].dde_parent_key,
        add_vas_items_24: item.termSpecific[0].add_vas_items,
        add_vas_items_30: item.termSpecific[1].add_vas_items,
        add_vas_items_36: item.termSpecific[2].add_vas_items,
      })
    );

    if (updatedChannelHeadlineDeals.length > 0) {
      ChannelService.updateChannelHeadlineDeals(
        cycle,
        updatedChannelHeadlineDeals
      )
        .then(() => {
          handleGenericSnackOpen("success", `${channel} saved successfully.`);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
    if (updatedChannelAddOnHeadlineDeals.length > 0) {
      ChannelService.updateChannelAddOnHeadlineDeals(
        cycle,
        updatedChannelAddOnHeadlineDeals
      )
        .then(() => {
          handleGenericSnackOpen("success", `${channel} saved successfully.`);
        })
        .catch((error) => {
          handleServerError(error);
        });
    }
  };

  // Handle add new deal
  const handleAddDeal = (type, data) => {
    const _data = buildNewDeal(data);
    setNewDealCardID(`#p${_data.id}`);
    dispatch(addDeal({ type: type, data: _data, newDevice: false }));
  };

  const [createButtonDisable, setCreateButtonDisable] = useState(false);
  const handleCreateAddOnDeal = ({ id, dealType }) => {
    setCreateButtonDisable(true);
    const deal = channelDeals.find((v) => v.id === id);

    const newAddOnDeal = {
      channel_id: deal.channel_id,
      oem: deal.oem,
      main_cid: deal.cardGlobalLevel.main_cid,
      device_basket_items: [
        ...deal.multiDevice,
        ...deal.multiAccessory,
        ...deal.multiRouter,
      ],
      main_tariff_code_24: deal.termSpecific[0].tariff_code,
      tariff_basket_items_24: [...deal.multiTariff],
      main_tariff_code_30: deal.termSpecific[1].tariff_code,
      tariff_basket_items_30: [...deal.multiTariff],
      main_tariff_code_36: deal.termSpecific[2].tariff_code,
      tariff_basket_items_36: [...deal.multiTariff],
    };

    if (
      newAddOnDeal.device_basket_items.length < 1 &&
      deal.multiTariff.length < 1
    ) {
      setCreateButtonDisable(false);
      handleGenericSnackOpen(
        "warning",
        "You need to add-on at least one add-on!"
      );
      return;
    }

    ChannelService.createChannelAddOnDeal(cycle, newAddOnDeal)
      .then((response) => {
        const { data: newHeadline } = response;
        // Remove dummy deal
        dispatch(deleteDeal({ type: dealType, id: id }));
        if (newHeadline) {
          // Add real deal
          dispatch(
            addDeal({
              type: "channelDeals",
              data: newHeadline,
              newDevice: false,
            })
          );
          setCreateButtonDisable(false);
          handleGenericSnackOpen("success", "Deal successfully created.");
        } else {
          setCreateButtonDisable(false);
          handleGenericSnackOpen("warning", "Deal couldn't be created.");
        }
      })
      .catch((error) => {
        setCreateButtonDisable(false);
        handleServerError(error);
      });
  };

  // Category selection states and function
  const [category, setCategory] = useState(0);
  const handleCategoryChange = async (_, category) => {
    dispatch(clearChannelDeals());
    setCategory(category);
    //setFilteredDeals([]);
  };

  // Searching and filtering through the Headline (Standalone) data
  useEffect(() => {
    if (dataLoaded) {
      queryChannelDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, filterBy, searchText, sortOrder]);

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleGenericSnackOpen("error", errors[0].message);
    }
  };

  // State to store the grouped data
  const [groupedDataByOEM, setGroupedDataByOEM] = useState({});

  // Effect to update the groupedData whenever filteredDeals changes
  useEffect(() => {
    setFilteredDeals(channelDeals);
    if (newDealCardID !== "") {
      setTimeout(() => {
        let destination = window.document.querySelector(newDealCardID);
        // Scroll to the destination using
        // scrollIntoView method
        destination.scrollIntoView({
          behavior: "smooth",
        });
        setNewDealCardID("");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, channelDeals]);

  useEffect(() => {
    const updatedGroupedData = {};
    filteredDeals.forEach((item) => {
      if (!updatedGroupedData[item.oem]) {
        updatedGroupedData[item.oem] = [];
      }
      updatedGroupedData[item.oem].push(item);
    });

    setGroupedDataByOEM(updatedGroupedData);
  }, [filteredDeals]);

  const deleteAsync = (dde_parent_key) =>
    new Promise(function (resolve, reject) {
      ChannelService.deleteChannelHeadlineDeal(dde_parent_key)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

  const handleDeleteDeal = async (dealID, dealType, deal) => {
    let deleteError;
    console.log(dealID, dealType, deal);
    for (let i = 0; i < deal.dde_parent_keys.length; i++) {
      console.log(i);
      if (deal.dde_parent_keys[i] !== "") {
        await deleteAsync(deal.dde_parent_keys[i]).catch((error) => {
          deleteError = error;
        });
        if (deleteError) {
          break;
        }
      }
    }
    if (deleteError) {
      handleServerError(deleteError);
    } else {
      dispatch(deleteDeal({ id: dealID, type: dealType }));
      handleGenericSnackOpen("success", "Deal deleted successfully.");
    }
  };

  const handleDeleteDealTerm = (dealID, dealType, deal) => {
    ChannelService.deleteChannelHeadlineDeal(deal.dde_parent_key)
      .then(() => {
        dispatch(
          deleteDealTerm({ id: dealID, type: dealType, term: deal.term })
        );
        handleGenericSnackOpen(
          "success",
          `Deal for term ${deal.term} deleted successfully.`
        );
      })
      .catch((error) => {
        handleServerError(error);
      });
  };

  return (
    <>
      <div className="col-start-1 col-end-13 row-start-2 mt-8 mb-4 px-6 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#channelTitle"
        className="col-start-1 col-end-13 row-start-3 px-6"
      >
        <TitleBreadcrumb
          title={channel}
          titleID="channelTitle"
          button={
            <Button
              className="mt-6 lg:mt-0"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={SaveIcon}
                  width={24}
                  height={24}
                  ariaHidden
                  className="fill-white"
                />
              }
              onClick={() => handleSave()}
            >
              Save
            </Button>
          }
        />

        <div className="my-6 text-center">
          <ToggleButtonGroup
            value={category}
            exclusive
            onChange={handleCategoryChange}
            aria-label="Category selection"
          >
            <ToggleButton value={0}>Standalone</ToggleButton>
            <ToggleButton value={1}>Add-On</ToggleButton>
          </ToggleButtonGroup>
        </div>

        <div className="my-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 lg:col-span-6">
              <InputField
                inputLabel="Search"
                defaultValue={searchText}
                onBlur={handleSearch}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-6 lg:col-span-3">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  defaultValue: sortOrder || "",
                  onChange: handleSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((group) => [
                  <ListSubheader
                    key={group.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {group.heading}
                  </ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className="px-6"
                    >
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-6 lg:col-span-3">
              <MultiSelectDropdownWithSearch
                label="Filter by"
                options={filterByOptions}
                filterBy={filterBy}
                setFilterBy={handleFilterBy}
              />
            </div>
          </div>
        </div>

        {/* Standalone content */}
        <TabPanel value={category} index={0}>
          {/* Sticky element with totals (financial data) */}
          <DealCycleTotals
            totalCostToConnect={overviewTotals.totalCostToConnect}
            totalReducedSubscription={overviewTotals.totalReducedSubscription}
            totalFinanced={overviewTotals.totalFinanced}
            totalEfficiency={overviewTotals.totalEfficiency}
            totalIntensity={overviewTotals.totalIntensity}
          />

          {filteredDeals.filter((v) => !v.cardGlobalLevel.is_add_on).length >
          0 ? (
            Object.keys(groupedDataByOEM).map((oem) => (
              <div
                className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4"
                key={oem}
              >
                <div className="col-span-1 mb-2 border-b border-solid border-grey-100 pb-3 lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                  <h2 className="text-3xl font-bold text-black">{oem}</h2>
                </div>
                {groupedDataByOEM[oem]
                  .filter((v) => !v.cardGlobalLevel.is_add_on)
                  .map((device) => (
                    <div className="col-span-1" key={device.id}>
                      <HeadlineDealCard
                        data={device}
                        messageFromActions={handleGenericSnackOpen}
                        dealType="updatedChannelDeals"
                        deviceOptions={deviceOptions}
                        accessoryOptions={accessoryOptions}
                        tariffOptions={tariffOptions}
                        routerOptions={routerOptions}
                        vasOptions={vasOptions}
                        childrenDeals={true}
                        view={"channels"}
                        deleteDeal={handleDeleteDeal}
                        deleteDealTerm={handleDeleteDealTerm}
                      />
                    </div>
                  ))}
                {/* <div className="col-span-1">
                  <CreateNewDealCard
                    createDeal={() => handleAddDeal(dealType, newDealData)}
                  />
                </div> */}
              </div>
            ))
          ) : (
            <></>
            // <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
            //   <div className="col-span-1">
            //     <CreateNewDealCard
            //       createDeal={() => handleAddDeal(dealType, newDealData)}
            //     />
            //   </div>
            // </div>
          )}
        </TabPanel>

        {/* Add-On content */}
        <TabPanel value={category} index={1}>
          {/* Sticky element with totals (financial data) */}
          <DealCycleTotals
            totalCostToConnect={overviewTotals.totalCostToConnect}
            totalReducedSubscription={overviewTotals.totalReducedSubscription}
            totalFinanced={overviewTotals.totalFinanced}
            totalEfficiency={overviewTotals.totalEfficiency}
            totalIntensity={overviewTotals.totalIntensity}
          />

          {filteredDeals.filter((v) => v.cardGlobalLevel.is_add_on).length >
          0 ? (
            <>
              <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
                <div className="col-span-1">
                  <CreateNewDealCard
                    createDeal={(data) =>
                      handleAddDeal(
                        "newChannelDeals",
                        { ...data, channel_id: channel_id },
                        true
                      )
                    }
                    deviceOptions={[...deviceOptions, ...accessoryOptions]}
                    tariffOptions={tariffOptions}
                    routerOptions={routerOptions}
                    oemOptions={oemOptions}
                    oemDevices={oemDevices}
                    addOn={true}
                    messageFromActions={handleGenericSnackOpen}
                  />
                </div>
              </div>
              {Object.keys(groupedDataByOEM).map((oem) => (
                <div
                  className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4"
                  key={oem}
                >
                  <div className="col-span-1 mb-2 border-b border-solid border-grey-100 pb-3 lg:col-span-2 xl:col-span-3 3xl:col-span-4">
                    <h2 className="text-3xl font-bold text-black">{oem}</h2>
                  </div>
                  {groupedDataByOEM[oem]
                    .filter((v) => v.cardGlobalLevel.is_add_on)
                    .map((device) => (
                      <div className="col-span-1" key={device.id}>
                        <HeadlineDealCard
                          data={device}
                          messageFromActions={handleGenericSnackOpen}
                          dealType={
                            device.createDeal
                              ? "newChannelDeals"
                              : "updatedChannelAddOnDeals"
                          }
                          deviceOptions={allDevicesOptions.filter(
                            (v) => v.object_type === "D"
                          )}
                          accessoryOptions={allDevicesOptions.filter(
                            (v) =>
                              v.object_type === "A" || v.object_type === "G"
                          )}
                          tariffOptions={tariffOptions}
                          routerOptions={routerOptions}
                          vasOptions={vasOptions}
                          addOns={true}
                          childrenDeals={true}
                          view={"channels"}
                          createAddOnDeal={handleCreateAddOnDeal}
                          createButtonDisable={createButtonDisable}
                          deleteDeal={handleDeleteDeal}
                          deleteDealTerm={handleDeleteDealTerm}
                        />
                      </div>
                    ))}
                  <div className="col-span-1">
                    <CreateNewDealCard
                      createDeal={(data) =>
                        handleAddDeal(
                          "newChannelDeals",
                          { ...data, channel_id: channel_id },
                          true
                        )
                      }
                      oem={oem}
                      deviceOptions={[
                        ...(oemDevices.length > 0
                          ? oemDevices.find((item) => item.oem === oem)?.devices
                            ? oemDevices.find((item) => item.oem === oem)
                                ?.devices
                            : []
                          : []),
                        ...(oemAccessories.length > 0
                          ? oemAccessories.find((item) => item.oem === oem)
                              ?.devices
                            ? oemAccessories.find((item) => item.oem === oem)
                                ?.devices
                            : []
                          : []),
                      ]}
                      tariffOptions={tariffOptions}
                      routerOptions={routerOptions}
                      oemOptions={oemOptions}
                      oemDevices={oemDevices}
                      addOn={true}
                      messageFromActions={handleGenericSnackOpen}
                    />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="mt-8 mb-12 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6 xl:grid-cols-3 3xl:grid-cols-4">
              <div className="col-span-1">
                <CreateNewDealCard
                  createDeal={(data) =>
                    handleAddDeal(
                      "newChannelDeals",
                      { ...data, channel_id: channel_id },
                      true
                    )
                  }
                  deviceOptions={[...deviceOptions, ...accessoryOptions]}
                  tariffOptions={tariffOptions}
                  routerOptions={routerOptions}
                  oemOptions={oemOptions}
                  oemDevices={oemDevices}
                  addOn={true}
                  messageFromActions={handleGenericSnackOpen}
                />
              </div>
            </div>
          )}
        </TabPanel>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
