import axios from "axios";

const API_URL = "/api/download?key=";

const downloadFile = (key) => {
  return axios.get(API_URL + key, { responseType: "blob"});
};

const DownloadService = {
  downloadFile
};

export default DownloadService;
