import axios from "axios";

const API_URL = "/api/deals/";

const getChannelDealsTotals = (cycle) => {
  return axios.get(API_URL + cycle + "/channel-deals-totals/");
};

const getChannelDealsTotal = (cycle, channel_id) => {
  return axios.get(API_URL + cycle + "/channel-deals-totals/" + channel_id);
};

const getChannelOEMDevices = (cycle, channel_id) => {
  return axios.get(
    API_URL + cycle + "/channel-deals/" + channel_id + "/devices"
  );
};

const getAllChannelDevices = (cycle, channel_id) => {
  return axios.get(
    API_URL + cycle + "/channel-deals/" + channel_id + "/all-devices"
  );
};

const generateChannelDeals = ({
  cycle_id,
  channel_id,
  channel: channel_name,
}) => {
  return axios
    .post(API_URL + "generate-channel-deals", {
      cycle_id,
      channel_id,
      channel_name,
    })
    .then((response) => {
      return response.data;
    });
};

const generateChannelODBC = ({
  cycle_id,
  channel_id,
  channel: channel_name,
}) => {
  return axios
    .post(API_URL + "generate-channel-odbc", {
      cycle_id,
      channel_id,
      channel_name,
    })
    .then((response) => {
      return response.data;
    });
};

const getChildDealsForHeadlinesForChannel = (
  cycle,
  channel_id,
  device_basket_id
) => {
  return axios.get(
    API_URL + cycle + "/channel-deals/" + channel_id + "/" + device_basket_id
  );
};

const getChannelHeadlineDealsForChannel = (
  cycle,
  channel_id,
  { addon, oem, rom, deal_desc, order_asc, deal_type_id }
) => {
  return axios.get(
    API_URL +
      cycle +
      "/channel-deals/" +
      channel_id +
      `?addon=${addon}&oem=${oem}&rom=${rom}&deal_desc=${deal_desc}&order_asc=${order_asc}&deal_type_id=${deal_type_id}`
  );
};

const updateChannelHeadlineDeals = (cycle, deals) => {
  return axios.put(API_URL + cycle + "/channel-deals", { deals });
};

const updateChannelAddOnHeadlineDeals = (cycle, deals) => {
  return axios.put(API_URL + cycle + "/channels/add-on", { deals });
};

const updateChannelLadderDeals = (cycle, deals) => {
  return axios.put(API_URL + cycle + "/channel-deals/ladder-deals", { deals });
};

const createChannelAddOnDeal = (cycle, deal) => {
  return axios.post(API_URL + cycle + "/channels/add-on", {
    ...deal,
  });
};

const publishChannelDeals = (channel_data) => {
  return axios.post("/api/deals/channels/load-deals", {
    ...channel_data,
  });
};

const deleteChannelHeadlineDeal = (dde_parent_key) => {
  return axios.delete(API_URL + "channels/" + dde_parent_key);
};

const deleteSingleChannelDeal = (dde_parent_key) => {
  return axios.delete(API_URL + "channels/single/" + dde_parent_key);
};

const submitForApproval = (cycle_id, channel_id, channel_name) => {
  return axios.post(API_URL + "channels/submit-for-approval", { cycle_id, channel_id, channel_name });
};

const ChannelService = {
  getChannelDealsTotals,
  getChannelDealsTotal,
  getChannelOEMDevices,
  getAllChannelDevices,
  generateChannelDeals,
  generateChannelODBC,
  getChildDealsForHeadlinesForChannel,
  getChannelHeadlineDealsForChannel,
  updateChannelHeadlineDeals,
  updateChannelAddOnHeadlineDeals,
  updateChannelLadderDeals,
  createChannelAddOnDeal,
  publishChannelDeals,
  deleteChannelHeadlineDeal,
  deleteSingleChannelDeal,
  submitForApproval
};

export default ChannelService;
