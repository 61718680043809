// React Imports
import { useEffect, useState } from "react";

// Redux Store Imports
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getRangesData,
  getTariffData,
  getTariffRanges,
  getTariffs,
  updateReviewState
} from "../../../slices/cycleConfigCanvas";

// MUI Imports
import {
  Button,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";
import TabPanel from "../../../components/tab-panel/tab-panel-component";

// Icon Imports
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import XCircleIcon from "../../../assets/icons/x-circle-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Tariff Sorting Options
const sortingOptions = [
  {
    value: "asc",
    label: "A - Z",
  },
  {
    value: "desc",
    label: "Z - A",
  },
];

// Tariff Filter Options
const filterByOptions = [
  {
    heading: "Category",
    options: [
      {
        value: "Red Flexi",
        label: "Red Flexi",
      },
      {
        value: "RED",
        label: "RED",
      },
      {
        value: "Home Internet",
        label: "Home Internet",
      },
      {
        value: "Data Price plan",
        label: "Data Price plan",
      },
    ],
  },
  {
    heading: "Type",
    options: [
      {
        value: "V",
        label: "Voice",
      },
      {
        value: "D",
        label: "Data",
      },
    ],
  },
  {
    heading: "Tariff Sub Type",
    options: [
      {
        value: "MBB",
        label: "Mobile Broadband",
      },
      {
        value: "FWA",
        label: "Fixed Wireless",
      },
    ],
  },
];

// Tariff Table Head Cells
const tariffTableHead = [
  {
    id: "tariff_name",
    label: "Tariff Name",
  },
  {
    id: "tariff_code",
    label: "Tariff Code",
  },
  {
    id: "tariff_sub",
    label: "Tariff Sub.",
  },
  {
    id: "sim_only_sub",
    label: "SIM-Only Sub.",
  },
  {
    id: "tariff_differential",
    label: "Tariff Differential",
  },
  {
    id: "ranking",
    label: "Rank",
  },
  {
    id: "tariff_discount",
    label: "Tariff Discount",
  },
  {
    id: "tariff_cib",
    label: "Tariff CIB",
  },
  {
    id: "tariff_sub_type",
    label: "Tariff Sub Type",
  },
  {
    id: "tariff_type",
    label: "Tariff Type",
  },
];

// Ranges Table Head Cells
const rangesTableHead = [
  {
    id: "tariff_description",
    label: "Tariff Name",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "tariff_type",
    label: "Tariff Type",
  },
  {
    id: "tariff_sub_type",
    label: "Tariff Sub. Type",
  },
  {
    id: "term",
    label: "Term",
  },
  {
    id: "range_lower",
    label: "Range Lower",
  },
  {
    id: "range_upper",
    label: "Range Upper",
  },
];

export default function TariffReviewTabContent({
  setTabValue,
  handleSnackOpen,
  setReviewState,
  reviewStatus
}) {

  // Rejected State
  const [rejected, setRejected] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const { cycle } = useParams();

  // Redux dispatch hook
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTariffs({ cycle }));
    dispatch(getTariffRanges({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On Approval function to trigger tab change and success snackbar popup
  const onApprove = () => {
    dispatch(updateReviewState({ data: {
      config_status: "A",
      reviewed: "Y",
      reason_rejected: null,
      config_set: "TARIFFS",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }))

    handleSnackOpen("success", "Tariffs has been approved.");
    handleClickChangeTab();
  };

  // On Reject save function to trigger tab change and success snackbar popup
  const onRejectSave = () => {
    dispatch(updateReviewState({ data: {
      config_status: "R",
      reviewed: "Y",
      reason_rejected: message,
      config_set: "TARIFFS",
      cycle: cycle
    }, 
    object: "cycleReviewsData",
    navigation: navigate }));

    handleSnackOpen("success", "Tariffs has been rejected.");
    handleClickChangeTab();
  }

  // Change Tab on Parent Component
  const handleClickChangeTab = () => {
    setTabValue(2);
  };

  // Handle reject button
  const handleRejectChange = () => {
    setRejected(!rejected);
  }

  // Set DATA sources
  const tariffData = useSelector(getTariffData);
  const rangesData = useSelector(getRangesData);

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPageT, setRowsPerPageT] = useState(-1);
  const [rowsPerPageR, setRowsPerPageR] = useState(-1);
  const [rowsPerPageOptionsT, setRowsPerPageOptionsT] = useState([]);
  const [rowsPerPageOptionsR, setRowsPerPageOptionsR] = useState([]);

  // Handle change table page
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Handle change table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageT(parseInt(event.target.value, 10));
    setPage(0);
  };

  // States
  const [tariffSearchText, setTariffSearchText] = useState("");
  const [tariffFilterBy, setTariffFilterBy] = useState([]);
  const [tariffSortOrder, setTariffSortOrder] = useState("");
  const [filteredTariffData, setFilteredTariffData] = useState(tariffData);

  // States
  const [rangeSearchText, setRangeSearchText] = useState("");
  const [rangeFilterBy, setRangeFilterBy] = useState([]);
  const [rangeSortOrder, setRangeSortOrder] = useState("");
  const [filteredRangeData, setFilteredRangeData] = useState(rangesData);

  // Update data as it changes
  useEffect(() => {
    // Tariff Search and Filtering
    let filteredTariffData = tariffData;

    // Filter tariffs by search text
    if (tariffSearchText) {
      filteredTariffData = tariffData.filter((tariff) => {
        // Join all values of the tariff object and its specs object into a string
        const tariffValues = Object.values(tariff.plan)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          tariffValues.toLowerCase().indexOf(tariffSearchText.toLowerCase()) !==
            -1 || tariff.plan === ""
        );
      });
    }

    // Filter tariffs by selected filters
    if (tariffFilterBy.length > 0) {
      filteredTariffData = filteredTariffData.filter((tariff) => {
        // Extract plan and type properties
        const { plan, type, tariffSubType } = tariff;
        
        // Combine plan and type properties into an array
        const tariffValues = [plan, type, tariffSubType];

        // Iterate over tariffFilterBy options and check if any row values contain the option
        for (let option of tariffFilterBy) {
          if (tariffValues[0].includes(option) || ((tariffValues[1] === "D" ? "Data" : "Voice") === option) || ((tariffValues[2] === "MBB" ? "Mobile Broadband" : "Fixed Wireless") === option)) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredTariffData(filteredTariffData);

    // Set rows per page 
    const rowsLen = filteredTariffData.length;
    const rows = Math.ceil((rowsLen) / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptionsT([
      ...pg,
      { label: "All", value: rows }
    ])

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPageT(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPageT(rows);
    }

    }, [
      tariffSearchText,
      tariffFilterBy,
      tariffData,
  ]);

  // Update data as it changes
  useEffect(() => {
    // Ranges Search and Filtering
    let filteredRangeData = rangesData;

    // Filter tariffs by search text
    if (rangeSearchText) {
      filteredRangeData = rangesData.filter((range) => {
        // Join all values of the range object and its specs object into a string
        const rangeValues = Object.values(range)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");
        // Check if the search text is present in the joined row values
        return (
          rangeValues.toLowerCase().indexOf(rangeSearchText.toLowerCase()) !==
            -1 || range.plan === ""
        );
      });
    }

    // Filter tariffs by selected filters
    if (rangeFilterBy.length > 0) {
      filteredRangeData = filteredRangeData.filter((range) => {
        // Extract plan and type properties
        const { tariffName, tariffType, tariffSubType } = range;

        // Combine plan and type properties into an array
        const rangeValues = [tariffName, tariffType, tariffSubType];

        // Iterate over rangeFilterBy options and check if any row values contain the option
        for (let option of rangeFilterBy) {
          if (rangeValues[0].includes(option) || ((rangeValues[1] === "D" ? "Data" : "Voice") === option) || ((rangeValues[2] === "MBB" ? "Mobile Broadband" : "Fixed Wireless") === option)) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredRangeData(filteredRangeData);

    // Set rows per page 
    const rowsLen = filteredRangeData.length;
    const rows = Math.ceil((rowsLen) / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptionsR([
      ...pg,
      { label: "All", value: rows }
    ])

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPageR(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPageR(rows);
    }
  }, [
    rangeSearchText,
    rangeFilterBy,
    rangesData,
  ]);

  const handleTariffSearch = (event) => {
    const { value } = event.target;
    setTariffSearchText(value);
  };
  const handleRangeSearch = (event) => {
    const { value } = event.target;
    setRangeSearchText(value);
  };

  // Handle filter by option change
  const handleTariffFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setTariffFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setTariffFilterBy(filteredOptions);
  };
  const handleRangeFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setRangeFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setRangeFilterBy(filteredOptions);
  };

  // Handle sorting order change
  const handleTariffSort = (event) => {
    setTariffSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = [...filteredTariffData].sort((a, b) =>
          a.plan.localeCompare(b.plan)
        );
        break;
      case "desc":
        sortedData = [...filteredTariffData].sort((a, b) =>
          b.plan.localeCompare(a.plan)
        );
        break;
      default:
        sortedData = filteredTariffData;
        break;
    }

    setFilteredTariffData(sortedData);
  };
  const handleRangeSort = (event) => {
    setRangeSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = [...filteredRangeData].sort((a, b) =>
          a.description.localeCompare(b.description)
        );
        break;
      case "desc":
        sortedData = [...filteredRangeData].sort((a, b) =>
          b.description.localeCompare(a.description)
        );
        break;
      default:
        sortedData = filteredRangeData;
        break;
    }

    setFilteredRangeData(sortedData);
  };

  // Category selection states and function
  const [category, setCategory] = useState(0);
  const handleCategoryChange = (_, category) => {
    setCategory(category);
  };

  return (
    <>

      <div className="text-center">
        <ToggleButtonGroup
          value={category}
          exclusive
          onChange={handleCategoryChange}
          aria-label="Category selection"
        >
          <ToggleButton value={0}>Tariffs</ToggleButton>
          <ToggleButton value={1}>Ranges</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TabPanel value={category} index={0}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={tariffSearchText}
                onChange={handleTariffSearch}
                placeholder="Search by tariff name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: tariffSortOrder || "",
                  onChange: handleTariffSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={tariffFilterBy}
                onChange={handleTariffFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={tariffFilterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              {tariffTableHead.map((headCell) => (
                <TableCell
                  className="border-grey-100 text-base pt-0 font-bold first:pl-0"
                  key={headCell.id}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTariffData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={tariffTableHead.length}
                  className="border-grey-100 py-8 text-center text-base text-black"
                >
                  No tariffs found...
                </TableCell>
              </TableRow>
            ) : (
              filteredTariffData
                .slice(page * rowsPerPageT, page * rowsPerPageT + rowsPerPageT)
                .map((tariff) => (
                  <TableRow key={tariff.id}>
                    <TableCell className="border-grey-100 pl-0 text-base">
                      <p className="font-bold">{tariff.plan}</p>
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.tariffCode}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.tariffSub}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.simOnlySub}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.tariffDifferential}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.ranking}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.tariffDiscount}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.tariffCib}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.tariffSubType}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {tariff.type === "V" ? "Voice" : "Data"}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptionsT}
                count={filteredTariffData.length}
                rowsPerPage={rowsPerPageT}
                page={page}
                labelRowsPerPage="Items per page"
                SelectProps={{
                  inputProps: {
                    "aria-label": "Items per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>

        {(!setReviewState && !reviewStatus) && (
          <div className="mb-12">
            <div className="flex justify-center gap-2 md:justify-end">
              <Button 
                color="error"
                variant="outlined" 
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={XCircleIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                onClick={handleRejectChange}
                disabled={rejected}
              >
                Reject
              </Button>
              <Button 
                color="success"
                variant="contained" 
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={TickOutlineCircle}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                onClick={onApprove}
              >
                Approve
              </Button>
            </div>
  
            {rejected &&
              <div className="flex flex-col gap-6">
                <InputField
                  inputLabel="Reason"
                  placeholder="Enter your comments here..."
                  multiline
                  minRows={2}
                  onChange={(e) => setMessage(e.target.value)}
                />
  
                <Button 
                  variant="text"
                  disableFocusRipple 
                  className="text-red-100 self-end"
                  onClick={onRejectSave}
                >
                    Save
                </Button>
              </div>
            }
          </div>
        )}
      </TabPanel>
      <TabPanel value={category} index={1}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={rangeSearchText}
                onChange={handleRangeSearch}
                placeholder="Search by tariff name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: rangeSortOrder || "",
                  onChange: handleRangeSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={rangeFilterBy}
                onChange={handleRangeFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={rangeFilterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>
        
        <Table>
          <TableHead>
            <TableRow>                
              {rangesTableHead.map((headCell) => (
                <TableCell
                  className="border-grey-100 pt-0 text-base font-bold first:pl-0"
                  key={headCell.id}
                >
                  {headCell.label}
                </TableCell>
              ))}                
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRangeData.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={rangesTableHead.length}
                  className="border-grey-100 py-8 text-center text-base text-black"
                >
                  No tariffs found...
                </TableCell>
              </TableRow>
            ) : (
              filteredRangeData
                .slice(page * rowsPerPageR, page * rowsPerPageR + rowsPerPageR)
                .map((range) => (
                  <TableRow key={range.id}>
                    <TableCell className="border-grey-100 pl-0 text-base">
                      {range.tariffName}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {range.description}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {range.tariffType === "D" ? "Data" : "Voice"}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {range.tariffSubType}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      {range.term} Months
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      R {range.rangeLower}
                    </TableCell>
                    <TableCell className="border-grey-100 text-base">
                      R {range.rangeUpper}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptionsR}
                count={filteredRangeData.length}
                rowsPerPage={rowsPerPageR}
                page={page}
                labelRowsPerPage="Items per page"
                SelectProps={{
                  inputProps: {
                    "aria-label": "Items per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>

        {!setReviewState && (
          <div className="mb-12">
            <div className="flex justify-center gap-2 md:justify-end">
              <Button 
                color="error"
                variant="outlined" 
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={XCircleIcon}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                onClick={handleRejectChange}
                disabled={rejected}
              >
                Reject
              </Button>
              <Button 
                color="success"
                variant="contained" 
                disableFocusRipple
                startIcon={
                  <InlineSVG
                    src={TickOutlineCircle}
                    width={24}
                    height={24}
                    ariaHidden
                  />
                }
                onClick={onApprove}
              >
                Approve
              </Button>
            </div>
  
            {rejected &&
              <div className="flex flex-col gap-6">
                <InputField
                  inputLabel="Reason"
                  placeholder="Enter your comments here..."
                  multiline
                  minRows={2}
                />
  
                <Button 
                  variant="text"
                  disableFocusRipple 
                  className="text-red-100 self-end"
                  onClick={onRejectSave}
                >
                    Save
                </Button>
              </div>
            }
          </div>
        )}
      </TabPanel>
    </>
  );
}
