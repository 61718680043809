import {
  createSelector,
  createSlice,
  createAsyncThunk,
  current,
} from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { v4 as uuidv4 } from "uuid";

import DealService from "../services/deal.service";
import ChannelService from "../services/channel.service";
import AdHocService from "../services/adhoc.service";

const cycleTabValue = localStorage.getItem("cycleTabValue");
const cycleId = localStorage.getItem("cycleId");
const date = new Date();
// Get Year & Month & combine in string
const year = date.getFullYear().toString().padStart(4, "0");
const month = (date.getMonth() + 1).toString().padStart(2, "0");
const currentCycleId = year + month;

export const getHeadlineDealsByOEM = createAsyncThunk(
  "deal/getHeadlineDealsByOEM",
  async (_, thunkAPI) => {
    try {
      const response = await DealService.getHeadlineDealsByOEM(_.cycle, _.oem);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getHeadlineDealsByChannel = createAsyncThunk(
  "deal/getHeadlineDealsByChannel",
  async (_, thunkAPI) => {
    try {
      const response = await DealService.getHeadlineDealsByChannel(
        _.cycle,
        _.oem,
        _.value
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getChannelHeadlineDealsForChannel = createAsyncThunk(
  "deal/getChannelHeadlineDealsForChannel",
  async (_, thunkAPI) => {
    try {
      const response = await ChannelService.getChannelHeadlineDealsForChannel(
        _.cycle,
        _.channel_id,
        _.queryOptions
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getHeadlineSIMODeals = createAsyncThunk(
  "deal/getHeadlineSIMODeals",
  async (_, thunkAPI) => {
    try {
      const response = await DealService.getHeadlineSIMODeals(_.cycle);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getGenericBulkDealsList = createAsyncThunk(
  "deal/getGenericBulkDealsList",
  async (_, thunkAPI) => {
    try {
      const response = await DealService.getGenericBulkDealsList(
        _.cycle,
        _.channel,
        _.oem,
        _.device,
        _.tariff
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBaseGenericDealsList = createAsyncThunk(
  "deal/getBaseGenericDealsList",
  async (_, thunkAPI) => {
    try {
      const response = await DealService.getBaseGenericDealsList(
        _.cycle,
        _.oem,
        _.device,
        _.tariff
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAdHocHeadlineDeals = createAsyncThunk(
  "deal/getAdHocHeadlineDeals",
  async (_, thunkAPI) => {
    try {
      const response = await AdHocService.getAdHocHeadlineDeals(_.campaign_id);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAdHocsChildrenDeals = createAsyncThunk(
  "deal/getAdHocsChildrenDeals",
  async (_, thunkAPI) => {
    try {
      const response = await AdHocService.getAdHocsChildrenDeals(
        _.dde_parent_keys
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFlaggedHeadlineDeals = createAsyncThunk(
  "deal/getFlaggedHeadlineDeals",
  async (_, thunkAPI) => {
    try {
      const { cycle, queryOptions } = _;
      const response = await DealService.getFlaggedHeadlineDeals(
        cycle,
        queryOptions
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getFlaggedChannelDeals = createAsyncThunk(
  "deal/getFlaggedChannelDeals",
  async (_, thunkAPI) => {
    try {
      const { cycle, queryOptions } = _;
      const response = await DealService.getFlaggedChannelDeals(
        cycle,
        queryOptions
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  bulkDeals: [],
  headlineDeals: [],
  headlineAddonDeals: [],
  flaggedHeadlineDeals: [],
  flaggedChannelDeals: [],
  directDeals: [],
  directAddonDeals: [],
  dealersAndFranchiseDeals: [],
  dealersAndFranchiseAddonDeals: [],
  nationalChainsDeals: [],
  nationalChainsAddonDeals: [],
  onlineDeals: [],
  vbOnlineDeals: [],
  onlineAddonDeals: [],
  vbOnlineAddonDeals: [],
  headlineSIMODeals: [],
  headlineAddonSIMODeals: [],
  directAddonSIMODeals: [],
  dealersAndFranchiseAddonSIMODeals: [],
  nationalChainsAddonSIMODeals: [],
  onlineAddonSIMODeals: [],
  vodaworldDeals: [],
  vodaworldAddonDeals: [],
  vodacomBusinessDeals: [],
  channelOnlineDeals: [],
  channelOnlineDeviceDeals: [],
  channelDirectDeals: [],
  channelDirectDeviceDeals: [],
  channelNationalChainsDeals: [],
  channelNationalChainsDeviceDeals: [],
  channelDealersAndFranchiseDeals: [],
  channelDealersAndFranchiseDeviceDeals: [],
  channelDeals: [],
  channelAddonDeals: [],
  newChannelAddOnDeals: [],
  newChannelDeals: [],
  updatedChannelDeals: [],
  updatedChannelAddOnDeals: [],
  adhocDeals: [],
  adhocChildrenDeals: [],
  multiVAS: [],
  multiAccessory: [],
  multiDevice: [],
  cycleTabValue: cycleTabValue ? parseInt(cycleTabValue) : 5,
  cycleId: cycleId ? parseInt(cycleId) : parseInt(currentCycleId),
};

// Function to check if the duplicated deal is the last one for that specific deal and
// add a property of "lastDuplicate" to the deal (this is for creating the line between
// the last duplicated deal and the initial deals)
const checkForLastDuplicate = (state, newDeal, id) => {
  const originalDealIndex = state.findIndex((deal) => deal.id === id);

  const newDeals = [...state]; // create a copy of the original deals array

  if (newDeal !== null) {
    newDeals.splice(originalDealIndex + 1, 0, newDeal);
  }

  let lastDuplicateIndex = null;

  // Find the last duplicate deal and add a class to it.
  for (let i = 0; i < newDeals.length; i++) {
    const currentDeal = newDeals[i];
    const nextDeal = newDeals[i + 1];
    if (
      currentDeal &&
      nextDeal &&
      currentDeal.isDuplicate &&
      !nextDeal.isDuplicate
    ) {
      currentDeal.lastDuplicate = true;
      lastDuplicateIndex = i;
    } else if (currentDeal) {
      currentDeal.lastDuplicate = false;
    }
  }

  // If there is a last duplicate, set the "lastDuplicate" property to false
  // for all deals after it.
  if (lastDuplicateIndex !== null) {
    for (let i = lastDuplicateIndex + 1; i < newDeals.length; i++) {
      const currentDeal = newDeals[i];
      if (currentDeal) {
        currentDeal.lastDuplicate = false;
      }
    }
  }

  return newDeals;
};

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    addDeal: (state, action) => {
      const { type, data, newDevice } = action.payload;
      const deviceId = data.devices[0].deviceId;

      if (deviceId !== null) {
        let dealExists = false;
        const updatedArray = state[type].map((deal) => {
          if (deal.devices[0].deviceId === deviceId) {
            dealExists = true;
            return { 
              ...deal, 
              ...data,
              id: uuidv4()
            };  // Update the deal with new data
          }
          return deal; // Return the deal as it is if it doesn't match
        });

        if (!dealExists) {
          // Edit the id on the data array before the following line
          data.id = uuidv4();

          const newDeal = {
            id: uuidv4(),
            ...data,
          };
          updatedArray.push(newDeal); // Add new deal if it doesn't exist
        }

        state[type] = updatedArray; // Update the state with the modified array
      } else {
        const newDeal = {
          id: uuidv4(),
          ...data,
          newDevice: newDevice || false,
        };
        state[type].push(newDeal);
      }
    },
    addDealset: (state, action) => {
      const { type, data, dealsetType } = action.payload;

      const newDealset = {
        id: uuidv4(),
        ...data,
        dealsetType: dealsetType,
      };

      state[type].push(newDealset);
    },
    duplicateDeal: (state, action) => {
      const { type, id, subDealID = null } = action.payload;

      // Check whether the component being added is part of the children deals
      if (subDealID !== null) {
        const groupArray = state[type].find((deal) => deal.id === id);
        const dealArray = groupArray.deals;

        // Find the deal to duplicate
        const dealToDuplicate = dealArray.find(
          (subDeal) => subDeal.id === subDealID
        );

        // Create a new deal object to be used as the duplicate
        const newDeal = {
          ...dealToDuplicate,
          id: uuidv4(),
        };

        // Update the Redux state with the new deals array
        const originalDealIndex = dealArray.findIndex(
          (subDeal) => subDeal.id === subDealID
        );
        const newDeals = [...dealArray]; // create a copy of the original deals array

        if (newDeal !== null) {
          newDeals.splice(originalDealIndex + 1, 0, newDeal);
        }

        // Update state with new deals array
        state[type].find((deal) => deal.id === id).deals = newDeals;
      } else {
        // Find the deal to duplicate
        const dealToDuplicate = state[type].find((deal) => deal.id === id);

        // Create a new deal object to be used as the duplicate
        const newDeal = {
          ...dealToDuplicate,
          id: uuidv4(),
          isDuplicate: true,
        };

        // Update the Redux state with the new deals array
        state[type] = checkForLastDuplicate(state[type], newDeal, id);
      }
    },
    deleteDeal: (state, action) => {
      const { type, id, subDealID = null } = action.payload;

      if (
        type === "updatedChannelDeals" ||
        type === "updatedChannelAddOnDeals"
      ) {
        // Get the index of the deal to be deleted
        const dealIndex = state.channelDeals.findIndex(
          (deal) => deal.id === id
        );
        // Remove the deal from the deals array
        state.channelDeals.splice(dealIndex, 1);
        // Check for the last duplicate and update the Redux state with the new deals array
        state.channelDeals = checkForLastDuplicate(
          state.channelDeals,
          null,
          id
        );
      } else {
        // Check whether the component being added is part of the children deals
        if (subDealID !== null) {
          const groupArray = state[type].find((item) => item.id === id);
          const dealArray = groupArray.deals;

          // Get the index of the deal to be deleted
          const dealIndex = dealArray.findIndex(
            (subDeal) => subDeal.id === subDealID
          );
          // Remove the deal from the deal array
          dealArray.splice(dealIndex, 1);
        } else {
          // Get the index of the deal to be deleted
          const dealIndex = state[type].findIndex((deal) => deal.id === id);
          // Remove the deal from the deals array
          state[type].splice(dealIndex, 1);
          // Check for the last duplicate and update the Redux state with the new deals array
          state[type] = checkForLastDuplicate(state[type], null, id);
        }
      }
    },
    deleteDeals: (state, action) => {
      const { type, deals, unApprovedDeals } = action.payload;

      // Remove deals that match data in unApprovedDeals
      const filteredDeals = deals.filter(deal => {
        return !unApprovedDeals.some(unApprovedDeal =>
          deal.device_basket_id === unApprovedDeal.device_basket_id &&
          deal.tariff_basket_id === unApprovedDeal.tariff_basket_id &&
          deal.term === unApprovedDeal.term &&
          deal.cycle_id === unApprovedDeal.cycle_id
        );
      });

      for (let i = 0; i < filteredDeals.length; i++) {
        // Get the index of the deal to be deleted
        const dealIndex = state[type].findIndex((deal) => deal.id === filteredDeals[i].id);
        if (dealIndex !== -1) {
          // Remove the deal from the deals array
          state[type].splice(dealIndex, 1);
          // Check for last duplicate and update the Redux state
          state[type] = checkForLastDuplicate(state[type], null, filteredDeals[i].id);
        }
      }
    },
    deleteDealTerm: (state, action) => {
      const { type, id, term } = action.payload;

      if (type === "updatedChannelDeals") {
        const dealToUpdate = state.channelDeals.find((item) => item.id === id);

        if (term === 24) {
          dealToUpdate.termSpecific[0].tariff_code = null;
        } else if (term === 30) {
          dealToUpdate.termSpecific[1].tariff_code = null;
        } else if (term === 36) {
          dealToUpdate.termSpecific[2].tariff_code = null;
        }
      } else {
        // Find the headline deal to update
        const dealToUpdate = state[type].find((deal) => deal.id === id);
        // Update headline deal
        if (term === 24) {
          dealToUpdate.termSpecific[0].tariff_code = null;
        } else if (term === 30) {
          dealToUpdate.termSpecific[1].tariff_code = null;
        } else if (term === 36) {
          dealToUpdate.termSpecific[2].tariff_code = null;
        }
      }
    },
    deleteAdHocDealTerm: (state, action) => {
      const { type, id, term } = action.payload;

      const dealToDelete = state[type].find((deal) => deal.id === id);

      const termIndex = dealToDelete.terms.findIndex((v) => v.period === term);

      dealToDelete.terms.splice(termIndex, 1);
    },
    flagDeal: (state, action) => {
      const { type, id } = action.payload;

      // Find the deal to flag
      const dealToFlag = state[type].find((deal) => deal.id === id);

      // Toggle the "flagged" boolean true/false
      dealToFlag.flagged = !dealToFlag.flagged;
      dealToFlag.dataHasChanged = true;
    },
    approveDeal: (state, action) => {
      const { type, id, termIndex } = action.payload;

      // Find the deal to mark as approved
      const dealToApprove = state[type].find((deal) => deal.id === id);

      // Toggle the "approvedDeal" boolean true/false
      //dealToApprove.approvedDeal = !dealToApprove.approvedDeal;
      dealToApprove.termSpecific[termIndex].approval_action = 1;
      dealToApprove.termSpecific[termIndex].dataHasChanged = true;

      dealToApprove.dataHasChanged = true;
    },
    rejectDeal: (state, action) => {
      const { type, id, termIndex } = action.payload;

      // Find the deal to mark as rejected
      const dealToReject = state[type].find((deal) => deal.id === id);

      // Toggle the "rejectedDeal" boolean true/false
      //dealToReject.rejectedDeal = !dealToReject.rejectedDeal;
      dealToReject.termSpecific[termIndex].approval_action = 2;
      dealToReject.termSpecific[termIndex].dataHasChanged = true;

      dealToReject.dataHasChanged = true;
    },
    setRejectReason: (state, action) => {
      const { type, id, termIndex, reason } = action.payload;

      // Find the deal to update
      const dealToUpdate = state[type].find((deal) => deal.id === id);

      //dealToUpdate.rejectedReason = reason;
      dealToUpdate.termSpecific[termIndex].approval_reason = reason;
      dealToUpdate.dataHasChanged = true;
    },
    entirelyRejectDeal: (state, action) => {
      const { type, id, termIndex } = action.payload;

      // Find the deal to mark as rejected
      const dealToReject = state[type].find((deal) => deal.id === id);

      // Toggle the "entirelyRejectedDeal" boolean true/false
      //dealToReject.entirelyRejectedDeal = !dealToReject.entirelyRejectedDeal;
      dealToReject.termSpecific[termIndex].approval_action = 3;

      dealToReject.dataHasChanged = true;
    },
    addDevice: (state, action) => {
      const { type, id, device } = action.payload;

      // Find the deal where the devices are being added to
      const dealToAddDevices = state[type].find((deal) => deal.id === id);

      // Push new device added
      dealToAddDevices.devices.push({ ...device, deviceId: uuidv4() });
    },
    duplicateDevice: (state, action) => {
      const { type, id, deviceId } = action.payload;

      // Find the deal where the devices are being added to
      const dealToAddDevices = state[type].find((deal) => deal.id === id);

      // Find the device to duplicate
      const deviceToDuplicate = dealToAddDevices.devices.find(
        (device) => device.deviceId === deviceId
      );

      // Get the index of the device being duplicated
      const deviceIndex = dealToAddDevices.devices.findIndex(
        (device) => device.deviceId === deviceId
      );

      // Insert new device next to the device being duplicated
      dealToAddDevices.devices.splice(deviceIndex + 1, 0, {
        ...deviceToDuplicate,
        deviceId: uuidv4(),
      });
    },
    removeDevice: (state, action) => {
      const { type, id, deviceId } = action.payload;

      // Find the deal where the devices are being removed from
      const dealToAddDevices = state[type].find((deal) => deal.id === id);

      // Get the index of the device to be deleted
      const deviceIndex = dealToAddDevices.devices.findIndex(
        (device) => device.deviceId === deviceId
      );

      // Remove the device from the devices array
      dealToAddDevices.devices.splice(deviceIndex, 1);
    },
    addDealComponent: (state, action) => {
      const {
        type,
        id,
        component,
        subDealID = null,
        deal,
        termIndex,
      } = action.payload;

      if (type === "updatedChannelAddOnDeals") {
        // Store/Update updated
        const updatedDealToUpdate = state[type].find(
          (item) => item.card_id === deal.card_id
        );
        // Update Base
        const dealToUpdate = state.channelDeals.find(
          (item) => item.card_id === deal.card_id
        );
        if (updatedDealToUpdate) {
          let add_vas_items;
          if (termIndex > -1) {
            // Push component part to the already existing sections, or create new ones
            if (!updatedDealToUpdate.termSpecific[termIndex].add_vas_items) {
              add_vas_items = [{ id: uuidv4() }];
              updatedDealToUpdate.termSpecific[termIndex].add_vas_items =
                add_vas_items;
              dealToUpdate.termSpecific[termIndex].add_vas_items =
                add_vas_items;
            } else {
              const addedVas = {
                id: uuidv4(),
              };
              updatedDealToUpdate.termSpecific[termIndex].add_vas_items.push(
                addedVas
              );
              dealToUpdate.termSpecific[termIndex].add_vas_items.push(addedVas);
            }
          } else {
            if (!updatedDealToUpdate[component]) {
              updatedDealToUpdate[component] = [{ id: uuidv4() }];
            } else {
              updatedDealToUpdate[component].push({ id: uuidv4() });
            }
            if (!dealToUpdate[component]) {
              dealToUpdate[component] = [{ id: uuidv4() }];
            } else {
              dealToUpdate[component].push({ id: uuidv4() });
            }
          }
          updatedDealToUpdate.dataHasChanged = true;
          dealToUpdate.dataHasChanged = true;
        } else {
          //const termSpecific = deal.termSpecific;
          // const termSpecific = deal.termSpecific.map((item, index) => {
          //   if (index === termIndex) {
          //     item.add_vas_items = [{ id: uuidv4() }];
          //     return item;
          //   }
          //   return item;
          // });
          //termSpecific[termIndex].add_vas_items.push({ id: uuidv4() });
          if (termIndex > -1) {
            const termSpecific =
              termIndex === 0
                ? [
                    {
                      ...deal.termSpecific[0],
                      add_vas_items: [{ id: uuidv4() }],
                    },
                    { ...deal.termSpecific[1] },
                    { ...deal.termSpecific[2] },
                  ]
                : termIndex === 1
                ? [
                    {
                      ...deal.termSpecific[0],
                    },
                    {
                      ...deal.termSpecific[1],
                      add_vas_items: [{ id: uuidv4() }],
                    },
                    { ...deal.termSpecific[2] },
                  ]
                : [
                    {
                      ...deal.termSpecific[0],
                    },
                    {
                      ...deal.termSpecific[1],
                    },
                    {
                      ...deal.termSpecific[2],
                      add_vas_items: [{ id: uuidv4() }],
                    },
                  ];
            const newDeal = {
              ...deal,
              termSpecific: termSpecific,
              dataHasChanged: true,
            };
            state[type].push(newDeal);

            dealToUpdate.termSpecific = termSpecific;
          } else {
            const newDeal = {
              ...deal,
              dataHasChanged: true,
            };

            if (!dealToUpdate[component]) {
              const comp = [{ id: uuidv4() }];
              dealToUpdate[component] = comp;
              newDeal[component] = comp;
            } else {
              const comp = [{ id: uuidv4() }];
              dealToUpdate[component].push(comp);
              newDeal[component] = comp;
            }

            state[type].push(newDeal);
          }
          dealToUpdate.dataHasChanged = true;
        }
      } else {
        let dealToAddComponent;
        // Check whether the component being added is part of the children deals
        if (subDealID !== null) {
          const dealArray = state[type].find((deal) => deal.id === id);
          dealToAddComponent = dealArray.deals.find(
            (subDeal) => subDeal.id === subDealID
          );
        } else {
          // Find the deal where the component is being added to
          dealToAddComponent = state[type].find((deal) => deal.id === id);
        }

        // Push component part to the already existing sections, or create new ones
        if (!dealToAddComponent[component]) {
          dealToAddComponent[component] = [{ id: uuidv4() }];
        } else {
          dealToAddComponent[component].push({ id: uuidv4() });
        }
        dealToAddComponent.dataHasChanged = true;
      }
    },
    updateDealComponent: (state, action) => {
      const {
        type,
        id,
        component,
        data,
        subDealID = null,
        deal,
        termIndex,
      } = action.payload;

      if (type === "updatedChannelAddOnDeals") {
        // Store/Update updated
        const updatedDealToUpdate = state[type].find(
          (item) => item.card_id === deal.card_id
        );
        // Update Base
        const dealToUpdate = state.channelDeals.find(
          (item) => item.card_id === deal.card_id
        );
        if (termIndex > -1) {
          // Update component item
          const updatedAddedVASItems = dealToUpdate.termSpecific[
            termIndex
          ].add_vas_items.map((item) => {
            return item.id === data.id ? data : item;
          });
          // Set updated components
          dealToUpdate.termSpecific[termIndex].add_vas_items =
            updatedAddedVASItems;
          updatedDealToUpdate.termSpecific[termIndex].add_vas_items =
            updatedAddedVASItems;
        } else {
          // Update component item
          const updatedComponents = dealToUpdate[component].map((item) => {
            return item.id === data.id ? data : item;
          });
          // Set updated components
          dealToUpdate[component] = updatedComponents;
          updatedDealToUpdate[component] = updatedComponents;
        }

        dealToUpdate.dataHasChanged = true;
        updatedDealToUpdate.dataHasChanged = true;
      } else {
        let dealToAddComponent;

        // Check whether the component being added is part of the children deals
        if (subDealID !== null) {
          const dealArray = state[type].find((deal) => deal.id === id);
          dealToAddComponent = dealArray.deals.find(
            (subDeal) => subDeal.id === subDealID
          );
        } else {
          // Find the deal where the component is being added to
          dealToAddComponent = state[type].find((deal) => deal.id === id);
        }
        // Update component item
        const updatedComponents = dealToAddComponent[component].map((item) => {
          return item.id === data.id ? data : item;
        });
        // Set updated components
        dealToAddComponent[component] = updatedComponents;
        dealToAddComponent.dataHasChanged = true;
      }
    },
    removeDealComponent: (state, action) => {
      const {
        type,
        id,
        component,
        index,
        subDealID = null,
        deal,
      } = action.payload;

      let dealToRemoveComponent;

      if (type === "updatedChannelAddOnDeals") {
        // Store/Update updated
        const updatedDealToUpdate = state[type].find(
          (item) => item.card_id === deal.card_id
        );
        // Update Base
        const dealToUpdate = state.channelDeals.find(
          (item) => item.card_id === deal.card_id
        );

        // Remove the component from the deal
        dealToUpdate[component].splice(index, 1);
        dealToUpdate.dataHasChanged = true;

        if (updatedDealToUpdate) {
          updatedDealToUpdate[component].splice(index, 1);
          updatedDealToUpdate.dataHasChanged = true;
        } else {
          state[type].push(dealToUpdate);
        }
      } else {
        // Check whether the component being removed is part of the children deals
        if (subDealID !== null) {
          const dealArray = state[type].find((deal) => deal.id === id);
          dealToRemoveComponent = dealArray.deals.find(
            (subDeal) => subDeal.id === subDealID
          );
        } else {
          // Find the deal where the component is being removed form
          dealToRemoveComponent = state[type].find((deal) => deal.id === id);
        }

        // Remove the component from the deal
        dealToRemoveComponent[component].splice(index, 1);
        dealToRemoveComponent.dataHasChanged = true;
      }
    },
    clearBulkDeals: (state) => {
      state.bulkDeals = [];
    },
    clearHeadlineDeals: (state) => {
      state.headlineDeals = [];
    },
    clearAllChannelDeals: (state) => {
      state.channelDeals = [];
      state.newChannelDeals = [];
      state.updatedChannelDeals = [];
    },
    clearChannelDeals: (state) => {
      state.channelDeals = [];
    },
    clearUpdatedChannelDeals: (state) => {
      state.updatedChannelDeals = [];
    },
    updateHeadlineDeal: (state, action) => {
      const {
        type,
        id,
        currentCtd,
        current24,
        current30,
        current36,
        tariffCode24,
        tariffCode30,
        tariffCode36,
        currentSupplierCtd,
        currentSupplier24,
        currentSupplier30,
        currentSupplier36,
        deal,
      } = action.payload;

      const device_id = deal.devices[0].deviceId;
      const tariff_basket_id = deal.tariff_basket_id;

      if (
        type === "updatedChannelDeals" ||
        type === "updatedChannelAddOnDeals"
      ) {
        // Store/Update updated
        const updatedDealToUpdate = state[type].find(
          (item) => item.card_id === deal.card_id
        );
        if (updatedDealToUpdate) {
          // Update headline deal
          // updatedDealToUpdate.devices[0].currentDealCycle.ctd = currentCtd;
          updatedDealToUpdate.devices[0].currentDealCycle.term24 = current24;
          updatedDealToUpdate.devices[0].currentDealCycle.term30 = current30;
          updatedDealToUpdate.devices[0].currentDealCycle.term36 = current36;
          // updatedDealToUpdate.termSpecific[0].tariff_code = tariffCode24;
          // updatedDealToUpdate.termSpecific[1].tariff_code = tariffCode36;
          updatedDealToUpdate.dataHasChanged = true;
        } else {
          const newDeal = {
            ...deal,
            devices: [
              {
                ...deal.devices[0],
                currentDealCycle: {
                  ...deal.devices[0].currentDealCycle,
                  term24: current24,
                  term30: current30,
                  term36: current36,
                },
              },
            ],
            dataHasChanged: true,
          };
          state[type].push(newDeal);
        }
        // Update Base
        const dealToUpdate = state.channelDeals.find(
          (item) => item.card_id === deal.card_id
        );

        //dealToUpdate.devices[0].currentDealCycle.ctd = currentCtd;
        dealToUpdate.devices[0].currentDealCycle.term24 = current24;
        dealToUpdate.devices[0].currentDealCycle.term30 = current30;
        dealToUpdate.devices[0].currentDealCycle.term36 = current36;
        // dealToUpdate.termSpecific[0].tariff_code = tariffCode24;
        // dealToUpdate.termSpecific[1].tariff_code = tariffCode36;
        dealToUpdate.dataHasChanged = true;
      } else {
        // Find the headline deal to update
        const dealToUpdate = state[type].find((deal) => 
        deal.id === id && 
        deal.tariff_basket_id === tariff_basket_id &&
        deal.devices[0].deviceId === device_id);
        
        // Update headline deal
        if (
          currentCtd &&
          parseFloat(dealToUpdate.devices[0].currentDealCycle.ctd) !==
            parseFloat(currentCtd)
        ) {
          dealToUpdate.devices[0].currentDealCycle.ctd = currentCtd;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          parseFloat(dealToUpdate.devices[0].currentDealCycle.term24) !==
          parseFloat(current24)
        ) {
          dealToUpdate.devices[0].currentDealCycle.term24 = current24;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          parseFloat(dealToUpdate.devices[0].currentDealCycle.term30) !==
          parseFloat(current30)
        ) {
          dealToUpdate.devices[0].currentDealCycle.term30 = current30;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          parseFloat(dealToUpdate.devices[0].currentDealCycle.term36) !==
          parseFloat(current36)
        ) {
          dealToUpdate.devices[0].currentDealCycle.term36 = current36;
          dealToUpdate.dataHasChanged = true;
        }
        if (dealToUpdate.termSpecific[0].tariff_code !== tariffCode24.value) {
          dealToUpdate.termSpecific[0].tariff_code = tariffCode24.value;
          dealToUpdate.termSpecific[0].tariff_sub = tariffCode24.tariff_sub;
          dealToUpdate.termSpecific[0].deal_tariff_discount =
            tariffCode24.deal_tariff_discount;
          dealToUpdate.termSpecific[0].device_tariff_differential =
            tariffCode24.device_tariff_differential;
          dealToUpdate.dataHasChanged = true;
        }
        if (dealToUpdate.termSpecific[1].tariff_code !== tariffCode30.value) {
          dealToUpdate.termSpecific[1].tariff_code = tariffCode30.value;
          dealToUpdate.termSpecific[1].tariff_sub = tariffCode30.tariff_sub;
          dealToUpdate.termSpecific[1].deal_tariff_discount =
            tariffCode30.deal_tariff_discount;
          dealToUpdate.termSpecific[1].device_tariff_differential =
            tariffCode30.device_tariff_differential;
          dealToUpdate.dataHasChanged = true;
        }
        if (dealToUpdate.termSpecific[2].tariff_code !== tariffCode36.value) {
          dealToUpdate.termSpecific[2].tariff_code = tariffCode36.value;
          dealToUpdate.termSpecific[2].tariff_sub = tariffCode36.tariff_sub;
          dealToUpdate.termSpecific[2].deal_tariff_discount =
            tariffCode36.deal_tariff_discount;
          dealToUpdate.termSpecific[2].device_tariff_differential =
            tariffCode36.device_tariff_differential;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          currentSupplierCtd &&
          parseFloat(dealToUpdate.cardGlobalLevel.supplier_ctd) !==
            parseFloat(currentSupplierCtd)
        ) {
          dealToUpdate.cardGlobalLevel.supplier_ctd = currentSupplierCtd;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          parseFloat(dealToUpdate.termSpecific[0].supplier_deal_price) !==
          parseFloat(currentSupplier24)
        ) {
          dealToUpdate.termSpecific[0].supplier_deal_price = currentSupplier24;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          parseFloat(dealToUpdate.termSpecific[1].supplier_deal_price) !==
          parseFloat(currentSupplier30)
        ) {
          dealToUpdate.termSpecific[1].supplier_deal_price = currentSupplier30;
          dealToUpdate.dataHasChanged = true;
        }
        if (
          parseFloat(dealToUpdate.termSpecific[2].supplier_deal_price) !==
          parseFloat(currentSupplier36)
        ) {
          dealToUpdate.termSpecific[2].supplier_deal_price = currentSupplier36;
          dealToUpdate.dataHasChanged = true;
        }
      }
    },
    updateHeadlineSIMODeal: (state, action) => {
      const { type, id, current24, current36 } = action.payload;

      // Find the headline SIMO deal to update
      const dealToUpdate = state[type].find((deal) => deal.id === id);

      // Update headline SIMO deal
      dealToUpdate.tariff.currentDealCycle.term24 = current24;
      dealToUpdate.tariff.currentDealCycle.term36 = current36;
      dealToUpdate.dataHasChanged = true;
    },
    updateWholeDeal: (state, action) => {
      const { id, dealData } = action.payload;

      // Find the generic channel deal to update
      const dealToUpdate = state.bulkDeals.find((deal) => deal.id === id);

      console.log(id, dealToUpdate, dealData);
      // Update generic channel deal
      // dealToUpdate.dealPrice = value;
      // dealToUpdate.dataHasChanged = true;
    },
    updateDeal: (state, action) => {
      const { id, value } = action.payload;

      // Find the generic channel deal to update
      const dealToUpdate = state.bulkDeals.find((deal) => deal.id === id);

      // Update generic channel deal
      dealToUpdate.dealPrice = value;
      dealToUpdate.dataHasChanged = true;
    },
    updateAdHocDeal: (state, action) => {
      const { type, id, termUpdate, ...updates } = action.payload;
      // Find the AdHoc headline deal to update
      const dealToUpdate = state[type].find((deal) => deal.id === id);

      // Update only properties that changed
      for (const key in updates) {
        if (termUpdate) {
          const updatedTerms = dealToUpdate["terms"].map((item) => {
            const { id: _id } = item;
            const { id, term } = updates[key];

            if (item.period === term || (_id !== undefined && _id === id)) {
              return { ...item, [key]: updates[key].value };
            }
            return item;
          });
          dealToUpdate["terms"] = updatedTerms;
        } else if (updates[key] !== undefined) {
          dealToUpdate[key] = updates[key];
        }
      }

      dealToUpdate.dataHasChanged = true;
    },
    updateDataHasChanged: (state, action) => {
      const { id, type, deal } = action.payload;

      if (type === "updatedChannelDeals") {
        const dealToUpdate = state[type].find(
          (item) => item.card_id === deal.card_id
        );
        dealToUpdate.dataHasChanged = true;
      } else {
        const dealToUpdate = state[type].find((deal) => deal.id === id);
        dealToUpdate.dataHasChanged = true;
      }
    },
    addTerminalsComments: (state, action) => {
      const { type, id, comments } = action.payload;

      // Find the headline deal to update
      const dealToUpdate = state[type].find((deal) => deal.id === id);

      // Update headline deal
      dealToUpdate.terminal.comments = comments;
      dealToUpdate.dataHasChanged = true;
    },
    setCycleTab: (state, action) => {
      const { value } = action.payload;
      state.cycleTabValue = value;
    },
    setCycleId: (state, action) => {
      const { value } = action.payload;
      state.cycleId = value;
    },
    publishDeal: (state, action) => {
      const { type, id, subDealID = null } = action.payload;

      let dealToPublish;

      // Check whether the component being added is part of the children deals
      if (subDealID !== null) {
        const groupArray = state[type].find((deal) => deal.id === id);
        const dealArray = groupArray.deals;

        // Find the deal to Publish
        dealToPublish = dealArray.find((subDeal) => subDeal.id === subDealID);
      } else {
        // Find the deal to Publish
        dealToPublish = state[type].find((deal) => deal.id === id);
      }

      dealToPublish.published = true;
    },
    submitDealForApproval: (state, action) => {
      const { type, id, subDealID = null } = action.payload;

      let dealToPublish;

      // Check whether the component being added is part of the children deals
      if (subDealID !== null) {
        const groupArray = state[type].find((deal) => deal.id === id);
        const dealArray = groupArray.deals;

        // Find the deal to Publish
        dealToPublish = dealArray.find((subDeal) => subDeal.id === subDealID);
      } else {
        // Find the deal to Publish
        dealToPublish = state[type].find((deal) => deal.id === id);
      }

      dealToPublish.submitForApproval = true;
    },
    propagateAdhocDeal: (state, action) => {
      const { type, id } = action.payload;

       // eslint-disable-next-line
      let dealToPropagate = current(state)[type].find((deal) => deal.id === id);
    },
    updateAdHocChildrenDeal: (state, action) => {
      const { object, data } = action.payload;
      const { deal_id, value, term } = data;

      const updatedChildrenDeal = state[object].map((deal) => {
        if (deal.id === deal_id && deal.tariffTerm === term) {
          return { ...deal, dealPrice: value, isChanged: true };
        }
        return deal;
      });

      return {
        ...state,
        [object]: updatedChildrenDeal,
      };
    },
  },
  extraReducers: {
    [getHeadlineDealsByOEM.fulfilled]: (state, action) => {
      state.headlineDeals = action.payload.filter(
        (v) => v.channel_id === 0 && !v.cardGlobalLevel.is_add_on
      );
      state.headlineAddonDeals = action.payload.filter(
        (v) => v.channel_id === 0 && v.cardGlobalLevel.is_add_on
      );
      state.directDeals = action.payload.filter(
        (v) => v.channel_id === 1 && !v.cardGlobalLevel.is_add_on
      );
      state.directAddonDeals = action.payload.filter(
        (v) => v.channel_id === 1 && v.cardGlobalLevel.is_add_on
      );
      state.dealersAndFranchiseDeals = action.payload.filter(
        (v) => v.channel_id === 2 && !v.cardGlobalLevel.is_add_on
      );
      state.dealersAndFranchiseAddonDeals = action.payload.filter(
        (v) => v.channel_id === 2 && v.cardGlobalLevel.is_add_on
      );
      state.nationalChainsDeals = action.payload.filter(
        (v) => v.channel_id === 3 && !v.cardGlobalLevel.is_add_on
      );
      state.nationalChainsAddonDeals = action.payload.filter(
        (v) => v.channel_id === 3 && v.cardGlobalLevel.is_add_on
      );
      state.onlineDeals = action.payload.filter(
        (v) => v.channel_id === 4 && !v.cardGlobalLevel.is_add_on
      );
      state.onlineAddonDeals = action.payload.filter(
        (v) => v.channel_id === 4 && v.cardGlobalLevel.is_add_on
      );
      state.vbOnlineDeals = action.payload.filter(
        (v) => v.channel_id === 7 && !v.cardGlobalLevel.is_add_on
      );
      state.vbOnlineAddonDeals = action.payload.filter(
        (v) => v.channel_id === 7 && v.cardGlobalLevel.is_add_on
      );
    },
    [getHeadlineDealsByChannel.fulfilled]: (state, action) => {
      state.headlineDeals = action.payload.filter(
        (v) => v.channel_id === 0 && !v.cardGlobalLevel.is_add_on
      );
      state.headlineAddonDeals = action.payload.filter(
        (v) => v.channel_id === 0 && v.cardGlobalLevel.is_add_on
      );
      state.directDeals = action.payload.filter(
        (v) => v.channel_id === 1 && !v.cardGlobalLevel.is_add_on
      );
      state.directAddonDeals = action.payload.filter(
        (v) => v.channel_id === 1 && v.cardGlobalLevel.is_add_on
      );
      state.dealersAndFranchiseDeals = action.payload.filter(
        (v) => v.channel_id === 2 && !v.cardGlobalLevel.is_add_on
      );
      state.dealersAndFranchiseAddonDeals = action.payload.filter(
        (v) => v.channel_id === 2 && v.cardGlobalLevel.is_add_on
      );
      state.nationalChainsDeals = action.payload.filter(
        (v) => v.channel_id === 3 && !v.cardGlobalLevel.is_add_on
      );
      state.nationalChainsAddonDeals = action.payload.filter(
        (v) => v.channel_id === 3 && v.cardGlobalLevel.is_add_on
      );
      state.onlineDeals = action.payload.filter(
        (v) => v.channel_id === 4 && !v.cardGlobalLevel.is_add_on
      );
      state.onlineAddonDeals = action.payload.filter(
        (v) => v.channel_id === 4 && v.cardGlobalLevel.is_add_on
      );
      state.vbOnlineDeals = action.payload.filter(
        (v) => v.channel_id === 7 && !v.cardGlobalLevel.is_add_on
      );
      state.vbOnlineAddonDeals = action.payload.filter(
        (v) => v.channel_id === 7 && v.cardGlobalLevel.is_add_on
      );
    },
    [getHeadlineSIMODeals.fulfilled]: (state, action) => {
      state.headlineSIMODeals = action.payload.filter(
        (v) => v.channel_id === 0 && !v.cardGlobalLevel.is_add_on
      );
      state.headlineAddonSIMODeals = action.payload.filter(
        (v) => v.channel_id === 0 && v.cardGlobalLevel.is_add_on
      );
      state.directAddonSIMODeals = action.payload.filter(
        (v) => v.channel_id === 1 && v.cardGlobalLevel.is_add_on
      );
      state.dealersAndFranchiseAddonSIMODeals = action.payload.filter(
        (v) => v.channel_id === 2 && v.cardGlobalLevel.is_add_on
      );
      state.nationalChainsAddonSIMODeals = action.payload.filter(
        (v) => v.channel_id === 3 && v.cardGlobalLevel.is_add_on
      );
      state.onlineAddonSIMODeals = action.payload.filter(
        (v) => v.channel_id === 4 && v.cardGlobalLevel.is_add_on
      );
    },
    [getChannelHeadlineDealsForChannel.fulfilled]: (state, action) => {
      state.channelDeals = action.payload;
      // Update channel deals with updated channel deals list

      // -Create a map of updatedChannelDeals items by id for efficient lookup
      const updatedChannelDealsMap = new Map(
        state.updatedChannelDeals.map((item) => [item.card_id, item])
      );

      // -Create a map of updatedChannelDeals items by id for efficient lookup
      const updatedChannelAddOnDealsMap = new Map(
        state.updatedChannelAddOnDeals.map((item) => [item.card_id, item])
      );

      // Update the properties in the payload
      state.channelDeals = action.payload.map((item) => {
        const updatedChannelDeal = updatedChannelDealsMap.get(item.card_id); // Get the corresponding item from listB
        const updatedChannelAddOnDeal = updatedChannelAddOnDealsMap.get(
          item.card_id
        );
        if (updatedChannelDeal) {
          // If there's a matching item in updatedChannelDealsMap, update the properties
          return {
            ...item, // Keep the original properties from the payload
            // Update properties from updatedChannelDeal
            devices: updatedChannelDeal.devices,
            termSpecific: updatedChannelDeal.termSpecific,
            dataHasChanged: true,
          };
        }
        // Addon Deals
        else if (updatedChannelAddOnDeal) {
          // If there's a matching item in updatedChannelAddOnDealsMap, update the properties
          return {
            ...item, // Keep the original properties from the payload
            // Update properties from updatedChannelAddOnDeal
            devices: updatedChannelAddOnDeal.devices,
            multiAccessory: updatedChannelAddOnDeal.multiAccessory,
            multiDevice: updatedChannelAddOnDeal.multiDevice,
            multiTariff: updatedChannelAddOnDeal.multiTariff,
            multiRouter: updatedChannelAddOnDeal.multiRouter,
            termSpecific: updatedChannelAddOnDeal.termSpecific,
            dataHasChanged: true,
          };
        }
        return item; // If no matching item found, keep the original item
      });
    },
    [getGenericBulkDealsList.fulfilled]: (state, action) => {
      state.bulkDeals = action.payload;
    },
    [getBaseGenericDealsList.fulfilled]: (state, action) => {
      state.bulkDeals = action.payload;
    },
    [getAdHocHeadlineDeals.fulfilled]: (state, action) => {
      state.adhocDeals = action.payload;
    },
    [getAdHocsChildrenDeals.fulfilled]: (state, action) => {
      state.adhocChildrenDeals = action.payload;
    },
    [getFlaggedHeadlineDeals.fulfilled]: (state, action) => {
      state.flaggedHeadlineDeals = action.payload;
    },
    [getFlaggedChannelDeals.fulfilled]: (state, action) => {
      state.flaggedChannelDeals = action.payload;
    },
    //TODO
  },
});

export const {
  addDeal,
  addDealset,
  duplicateDeal,
  deleteDeal,
  deleteDeals,
  deleteDealTerm,
  deleteAdHocDealTerm,
  flagDeal,
  approveDeal,
  addDevice,
  duplicateDevice,
  removeDevice,
  addDealComponent,
  updateDealComponent,
  removeDealComponent,
  clearBulkDeals,
  clearAllChannelDeals,
  clearChannelDeals,
  clearUpdatedChannelDeals,
  clearHeadlineDeals,
  updateHeadlineDeal,
  updateHeadlineSIMODeal,
  updateAdHocDeal,
  updateDeal,
  updateWholeDeal,
  addTerminalsComments,
  setCycleTab,
  setCycleId,
  publishDeal,
  submitDealForApproval,
  rejectDeal,
  setRejectReason,
  entirelyRejectDeal,
  updateDataHasChanged,
  propagateAdhocDeal,
  updateAdHocChildrenDeal,
} = dealSlice.actions;

// Selectors
export const getBulkDeals = createSelector(
  (state) => state.deal.bulkDeals,
  (bulkDeals) => bulkDeals
);

export const getHeadlineDeals = createSelector(
  (state) => state.deal.headlineDeals,
  (headlineDeals) => headlineDeals
);

export const _getFlaggedHeadlineDeals = createSelector(
  (state) => state.deal.flaggedHeadlineDeals,
  (flaggedHeadlineDeals) => flaggedHeadlineDeals
);

export const _getFlaggedChannelDeals = createSelector(
  (state) => state.deal.flaggedChannelDeals,
  (flaggedChannelDeals) => flaggedChannelDeals
);

export const getHeadlineAddonDeals = createSelector(
  (state) => state.deal.headlineAddonDeals,
  (headlineAddonDeals) => headlineAddonDeals
);

export const _getHeadlineSIMODeals = createSelector(
  (state) => state.deal.headlineSIMODeals,
  (headlineSIMODeals) => headlineSIMODeals
);

export const getHeadlineAddonSIMODeals = createSelector(
  (state) => state.deal.headlineAddonSIMODeals,
  (headlineAddonSIMODeals) => headlineAddonSIMODeals
);

export const getChannelDeals = createSelector(
  (state) => [...state.deal.channelDeals, ...state.deal.newChannelDeals],
  (channelDeals) => channelDeals
);

export const getDirectDeals = createSelector(
  (state) => state.deal.directDeals,
  (directDeals) => directDeals
);

export const getDirectAddonDeals = createSelector(
  (state) => state.deal.directAddonDeals,
  (directAddonDeals) => directAddonDeals
);

export const getDirectAddonSIMODeals = createSelector(
  (state) => state.deal.directAddonSIMODeals,
  (directAddonSIMODeals) => directAddonSIMODeals
);

export const getDealersAndFranchiseDeals = createSelector(
  (state) => state.deal.dealersAndFranchiseDeals,
  (dealersAndFranchiseDeals) => dealersAndFranchiseDeals
);

export const getDealersAndFranchiseAddonDeals = createSelector(
  (state) => state.deal.dealersAndFranchiseAddonDeals,
  (dealersAndFranchiseAddonDeals) => dealersAndFranchiseAddonDeals
);

export const getDealersAndFranchiseAddonSIMODeals = createSelector(
  (state) => state.deal.dealersAndFranchiseAddonSIMODeals,
  (dealersAndFranchiseAddonSIMODeals) => dealersAndFranchiseAddonSIMODeals
);

export const getNationalChainsDeals = createSelector(
  (state) => state.deal.nationalChainsDeals,
  (nationalChainsDeals) => nationalChainsDeals
);

export const getNationalChainsAddonDeals = createSelector(
  (state) => state.deal.nationalChainsAddonDeals,
  (nationalChainsAddonDeals) => nationalChainsAddonDeals
);

export const getNationalChainsAddonSIMODeals = createSelector(
  (state) => state.deal.nationalChainsAddonSIMODeals,
  (nationalChainsAddonSIMODeals) => nationalChainsAddonSIMODeals
);

export const getOnlineDeals = createSelector(
  (state) => state.deal.onlineDeals,
  (onlineDeals) => onlineDeals
);

export const getOnlineAddonDeals = createSelector(
  (state) => state.deal.onlineAddonDeals,
  (onlineAddonDeals) => onlineAddonDeals
);

export const getVBOnlineDeals = createSelector(
  (state) => state.deal.vbOnlineDeals,
  (vbOnlineDeals) => vbOnlineDeals
);

export const getVBOnlineAddonDeals = createSelector(
  (state) => state.deal.vbOnlineAddonDeals,
  (vbOnlineAddonDeals) => vbOnlineAddonDeals
);

export const getOnlineAddonSIMODeals = createSelector(
  (state) => state.deal.onlineAddonSIMODeals,
  (onlineAddonSIMODeals) => onlineAddonSIMODeals
);

export const getVodaworldDeals = createSelector(
  (state) => state.deal.vodaworldDeals,
  (vodaworldDeals) => vodaworldDeals
);

export const getVodaworldAddonDeals = createSelector(
  (state) => state.deal.vodaworldAddonDeals,
  (vodaworldAddonDeals) => vodaworldAddonDeals
);

export const getVodacomBusinessDeals = createSelector(
  (state) => state.deal.vodacomBusinessDeals,
  (vodacomBusinessDeals) => vodacomBusinessDeals
);

export const getBulkFlaggedDeals = createSelector(
  (state) => state.deal.bulkDeals,
  (bulkDeals) => bulkDeals.filter((deal) => deal.flagged)
);

export const getAdHocDeals = createSelector(
  (state) => state.deal.adhocDeals,
  (adhocDeals) => adhocDeals
);

export const getAdHocChildrenDeals = createSelector(
  (state) => state.deal.adhocChildrenDeals,
  (adhocChildrenDeals) => adhocChildrenDeals
);

export const getCycleTab = createSelector(
  (state) => state.deal.cycleTabValue,
  (cycleTabValue) => cycleTabValue
);

export const getCycleId = createSelector(
  (state) => state.deal.cycleId,
  (cycleId) => cycleId
);

export const getChannelOnlineDeals = createSelector(
  (state) => state.deal.channelOnlineDeals,
  (channelOnlineDeals) => channelOnlineDeals
);

export const getChannelOnlineDeviceDeals = createSelector(
  (state) => state.deal.channelOnlineDeviceDeals,
  (channelOnlineDeviceDeals) => channelOnlineDeviceDeals
);

export const getChannelDirectDeals = createSelector(
  (state) => state.deal.channelDirectDeals,
  (channelDirectDeals) => channelDirectDeals
);

export const getChannelNationalChainsDeals = createSelector(
  (state) => state.deal.channelNationalChainsDeals,
  (channelNationalChainsDeals) => channelNationalChainsDeals
);

export const getChannelDealersAndFranchiseDeals = createSelector(
  (state) => state.deal.channelDealersAndFranchiseDeals,
  (channelDealersAndFranchiseDeals) => channelDealersAndFranchiseDeals
);

export const getNewChannelAddOnDeals = createSelector(
  (state) => state.deal.newChannelAddOnDeals,
  (newChannelAddOnDeals) => newChannelAddOnDeals
);

export const getUpdatedChannelDeals = createSelector(
  (state) => state.deal.updatedChannelDeals,
  (updatedChannelDeals) => updatedChannelDeals
);

export const getUpdatedChannelAddOnDeals = createSelector(
  (state) => state.deal.updatedChannelAddOnDeals,
  (updatedChannelAddOnDeals) => updatedChannelAddOnDeals
);

export default dealSlice.reducer;