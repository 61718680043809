// React Imports
import React, { useId, useRef, useState } from "react";
import { useIMask } from "react-imask";

// Mui Imports
import {
  CardContent,
  Collapse,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
} from "@mui/material";

// Redux Store Imports
import { useDispatch } from "react-redux";
import { approveDeal, updateHeadlineSIMODeal } from "../../slices/dealCanvas";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";
import InputField from "../input/input-component";
import TabPanel, { a11yProps } from "../tab-panel/tab-panel-component";
import Radialbar from "../radialbar/radialbar-component";

// Icon Imports
import TickOutlineCircle from "../../assets/icons/tick-circle-outline-icon.svg";
import TickCircle from "../../assets/icons/tick-circle-icon.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

export default function HeadlineSimOnlyDealCard(props) {
  const { id, tariff, approvedDeal, vodacomInfo, competitorInfo } = props.data;

  const { dealType = "headlineSIMODeals" } = props;

  // Redux dispatch
  const dispatch = useDispatch();

  // Unique ID with custom text for styling in some components
  const uid = useId();

  // Parent card reference
  const mainRef = useRef(null);

  // Collapse state
  const [expanded, setExpanded] = useState(false);

  // Handle expand event
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  // Handle mark deal as approved
  const handleApproveDeal = (dealID) => {
    dispatch(approveDeal({ id: dealID, type: dealType }));
  };

  // Number masking state
  const maskOptions = {
    mask: "R num",
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        thousandsSeparator: ",",
        padFractionalZeros: true,
        normalizeZeros: true,
        radix: ".",
      },
    },
  };

  const { ref: refCurrent24 } = useIMask(maskOptions);
  //const { ref: refCurrent36 } = useIMask(maskOptions);

  // Handle input fields
  const handleCurrent24Change = (event, dealID, dealType) =>{
    let value = event.target.value;
    // Remove R and trimm
    value = value.replace("R","").replace(/\s/g, '').replace(",","");
    dispatch(updateHeadlineSIMODeal({
      id: dealID,
      type: dealType,
      current24: value,
      current36: tariff.currentDealCycle.term36
    }));
  }
  // const handleCurrent36Change = (event, dealID, dealType) =>{
  //   let value = event.target.value;
  //   // Remove R and trimm
  //   value = value.replace("R","").trim();
  //   dispatch(updateHeadlineDeal({
  //     id: dealID,
  //     type: dealType,
  //     current24: tariff.currentDealCycle.term24,
  //     current36: value
  //   }));
  // }

  return (
    <>
      <div
        ref={mainRef}
        className={`w-full rounded-lg border-2 border-solid bg-white px-4 pt-[18px] text-start text-base shadow ${
          approvedDeal ? "border-green-100" : "border-transparent"
        }`}
        aria-labelledby={`#${id}`}
      >
        <div className="flex items-center gap-4 pr-1">
          <IconButton
            aria-label="Approve deal"
            className="p-0 text-green-40"
            onClick={() => handleApproveDeal(id)}
          >
            <InlineSVG
              src={approvedDeal ? TickCircle : TickOutlineCircle}
              width={28}
              height={28}
              ariaHidden
              className={approvedDeal ? "fill-green-100" : "fill-green-40"}
            />
          </IconButton>
          <div className="flex flex-auto items-center gap-6">
            <div className="w-40 shrink-0">
              <h2 className="text-base font-bold text-red-100">
                {tariff.plan}
              </h2>
            </div>

            <div className="h-6 w-px bg-grey-50"></div>

            <div className="flex shrink-0 gap-6">
              <div>
                <p className="font-semi mb-1 text-grey-400">24 months</p>
                <p className="text-grey-400">
                  {tariff.previousDealCycle.term24.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </p>
              </div>
              <div>
                <p className="font-semi mb-1 text-grey-400">36 months</p>
                <p className="text-grey-400">
                  {tariff.previousDealCycle.term36.toLocaleString("en-ZA", {
                    style: "currency",
                    currency: "ZAR",
                  })}
                </p>
              </div>
            </div>

            <div className="h-6 w-px bg-grey-50"></div>

            <div className="flex flex-auto gap-4">
              <div className="w-full">
                <InputField
                  inputLabel="24 months"
                  inputRef={refCurrent24}
                  placeholder="R 0,00"
                  defaultValue={tariff.currentDealCycle.term24 || ""}
                  onChange={(event) => handleCurrent24Change(event, id, dealType)}
                />
              </div>
              {/* <div className="w-full">
                <InputField
                  inputLabel="36 months"
                  inputRef={refCurrent36}
                  placeholder="R 0,00"
                  defaultValue={tariff.currentDealCycle.term36 || ""}
                  inputProps={{ readOnly: true }}
                  // onChange={(event) => handleCurrent36Change(event, id, dealType)}
                />
              </div> */}
            </div>
          </div>
        </div>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className="px-11 pt-8 pb-0">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="More information"
              className="mb-8"
              centered
            >
              <Tab
                label="Vodacom info."
                {...a11yProps(`extra${uid}0`)}
                disableRipple
              />
              <Tab
                label="Competitor info."
                {...a11yProps(`extra${uid}1`)}
                disableRipple
              />
            </Tabs>

            <TabPanel value={tabValue} index={0} id={`extra${uid}0`}>
              <div className="grid grid-cols-10 items-center gap-4">
                <div className="col-span-2 text-center">
                  <p className="text-3xl font-light text-black">
                    {vodacomInfo.termDifferential}%
                  </p>
                  <p className="mt-1 font-bold">Term Differential:</p>
                </div>
                <div className="col-span-2 text-center">
                  <p className="text-3xl font-light text-yellow-200">
                    {vodacomInfo.previousNewLine}
                  </p>
                  <p className="mt-1 font-bold">New Line</p>
                </div>
                <div className="col-span-2 text-center">
                  <p className="text-3xl font-light text-red-100">
                    {vodacomInfo.previousRetention}
                  </p>
                  <p className="mt-1 font-bold">Retention</p>
                </div>
                <div className="col-span-2 text-center">
                  <Radialbar
                    value={vodacomInfo.previousEff}
                    color="#EB9700"
                    label="EFF"
                    width={82}
                    height={82}
                  />
                </div>
                <div className="col-span-2 text-center">
                  <Radialbar
                    value={vodacomInfo.previousInt}
                    color="#E60000"
                    label="INT"
                    width={82}
                    height={82}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1} id={`extra${uid}1`}>
              <div className="grid grid-cols-2 gap-y-10">
                {competitorInfo.map((item, index) => (
                  <div
                    className="col-span-2 flex flex-col gap-5 odd:border-r odd:border-solid odd:border-grey-50 odd:pr-12 even:pl-12 lg:col-span-1"
                    key={item.competitor}
                  >
                    <p className="text-center text-base font-bold text-black">
                      {item.competitor}
                    </p>
                    <div className="flex items-center justify-between">
                      <InputLabel
                        className="text-base font-bold text-black"
                        id={uid + "competitor-deal-price-" + index}
                      >
                        Deal Price:
                      </InputLabel>
                      <InputField
                        inputProps={{
                          "aria-labelledby":
                            uid + "competitor-deal-price-" + index,
                        }}
                        placeholder="R 0,00"
                        defaultValue={item.dealPrice || ""}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <InputLabel
                        className="text-base font-bold text-black"
                        id={uid + "competitor-plan-" + index}
                      >
                        Plan:
                      </InputLabel>
                      <InputField
                        inputProps={{
                          "aria-labelledby": uid + "competitor-plan-" + index,
                        }}
                        placeholder="Enter competitor plan..."
                        defaultValue={item.plan || ""}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <InputLabel
                        className="text-base font-bold text-black"
                        id={uid + "competitor-value-" + index}
                      >
                        Value:
                      </InputLabel>
                      <InputField
                        inputProps={{
                          "aria-labelledby": uid + "competitor-value-" + index,
                        }}
                        placeholder="Enter competitor value..."
                        defaultValue={item.value || ""}
                      />
                    </div>
                    <div className="my-5 flex justify-between">
                      <p className="font-bold">Discount to Fair Value:</p>
                      <p>{item.discountFairValue}%</p>
                    </div>
                    <div className="flex justify-between">
                      <p className="font-bold">Fair Value Differential:</p>
                      <p>{item.fairValueDiff}%</p>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
          </CardContent>
        </Collapse>

        <div className="mt-2 mb-1 text-center">
          <IconButton
            onClick={handleExpandClick}
            aria-label="Show more deal details"
            aria-expanded={expanded ? "true" : undefined}
            className={`transition-transform duration-200 ${
              expanded ? "rotate-180" : "rotate-0"
            }`}
          >
            <ExpandMoreRoundedIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}
