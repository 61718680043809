// React Imports
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Redux Store Imports
import { useSelector, useDispatch } from "react-redux";
import {
  getHandsetDevicePerChannelData,
  getAccessoryDevicePerChannelData,
  updateReviewState,
} from "../../../slices/cycleConfigCanvas";
import {
  getDevicePerChannelHandsets,
  getDevicePerChannelAccessories,
} from "../../../slices/cycleConfigCanvas";
import CycleConfigService from "../../../services/cycleConfig.service";
import AuthService from "../../../services/auth.service";

// MUI Imports
import {
  Button,
  FormControlLabel,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Component Imports
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";
import TabPanel from "../../../components/tab-panel/tab-panel-component";

// Icon Imports
import TickOutlineCircle from "../../../assets/icons/tick-circle-outline-icon.svg";
import XCircleIcon from "../../../assets/icons/x-circle-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";

// Sorting Options
const sortingOptions = [
  {
    heading: "OEM",
    options: [
      {
        value: "oem_asc",
        label: "A - Z",
      },
      {
        value: "oem_desc",
        label: "Z - A",
      },
    ],
  },
];

export default function DevicePerChannelReviewTabContent({
  setTabValue,
  handleSnackOpen,
  setReviewState,
  reviewStatus,
}) {
  // Rejection reason state
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { cycle } = useParams();

  // On Approval function to trigger tab change and success snackbar popup
  const onApprove = () => {
    dispatch(
      updateReviewState({
        data: {
          config_status: "A",
          reviewed: "Y",
          reason_rejected: null,
          config_set: "DEVICE_PER_CHANNEL",
          cycle: cycle,
        },
        object: "cycleReviewsData",
        navigation: navigate,
      })
    );

    handleSnackOpen("success", "Deal Cycle Parameters has been approved.");
    handleClickChangeTab();
  };

  // On Reject save function to trigger tab change and success snackbar popup
  const onRejectSave = () => {
    dispatch(
      updateReviewState({
        data: {
          config_status: "R",
          reviewed: "Y",
          reason_rejected: message,
          config_set: "DEVICE_PER_CHANNEL",
          cycle: cycle,
        },
        object: "cycleReviewsData",
        navigation: navigate,
      })
    );

    handleSnackOpen("success", "Deal Cycle Parameters has been rejected.");
    handleClickChangeTab();
  };

  // Change Tab on Parent Component
  const handleClickChangeTab = () => {
    setTabValue(5);
  };

  // Rejected State
  const [rejected, setRejected] = useState(false);

  // Handle reject button
  const handleRejectChange = () => {
    setRejected(!rejected);
  };

  // Set DATA sources
  const handsetData = useSelector(getHandsetDevicePerChannelData);
  const accessoryData = useSelector(getAccessoryDevicePerChannelData);

  // Handsets States
  const [handsets_searchText, setHandsets_searchText] = useState("");
  const [handsets_filterBy, setHandsets_filterBy] = useState([]);
  const [handsets_sortOrder, setHandsets_sortOrder] = useState("");
  const [filteredHandsetData, setFilteredHandsetData] = useState(handsetData);

  // Accessories States
  const [accessory_searchText, setAccessory_searchText] = useState("");
  const [accessory_filterBy, setAccessory_filterBy] = useState([]);
  const [accessory_sortOrder, setAccessory_sortOrder] = useState("");
  const [filteredAccessoryData, setFilteredAccessoryData] =
    useState(accessoryData);

  const dispatch = useDispatch();

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(-1);
  const [rowsPerPageA, setRowsPerPageA] = useState(-1);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);
  const [rowsPerPageOptionsA, setRowsPerPageOptionsA] = useState([]);

  // Handle change table page
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  // Handle change table rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/logout");
    } else if (status === 403) {
      // Unauthorized
      handleSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleSnackOpen("notFound", errors[0].message);
    } else {
      // Unexpected server error
      handleSnackOpen("error", errors[0].message);
    }
  };

  // Filters
  const [filterByOptionsD, setFilterByOptionsD] = useState([]);
  const [filterByOptionsA, setFilterByOptionsA] = useState([]);

  // Get Filters
  useEffect(() => {
    CycleConfigService.getDevicePerChannelFilters(cycle)
      .then((response) => {
        const { data } = response;

        setFilterByOptionsD(data[0]);
        setFilterByOptionsA(data[1]);
      })
      .catch((error) => {
        handleServerError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDevicePerChannelAccessories({ cycle }));
    dispatch(getDevicePerChannelHandsets({ cycle }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search and Filter Handsets Data
  useEffect(() => {
    let updatedHandsetData = handsetData;

    // Filter Handsets Data by search text
    if (handsets_searchText) {
      updatedHandsetData = updatedHandsetData.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const deviceValues = Object.values(item)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");

        // Check if the search text is present in the joined row values
        return (
          deviceValues
            .toLowerCase()
            .indexOf(handsets_searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter Handsets data by selected filters
    if (handsets_filterBy.length > 0) {
      updatedHandsetData = updatedHandsetData.filter((device) => {
        // Extract oem and network_tech properties
        const { oem, network_tech } = device;

        // Combine oem and network_tech properties into an array
        const deviceValues = [oem, network_tech];

        // Iterate over handsets_filterBy options and check if any row values contain the option
        for (let option of handsets_filterBy) {
          // Filter Device with OEM or Network Tech
          if (deviceValues[0].includes(option) || deviceValues[1] === option) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredHandsetData(updatedHandsetData);

    // Set rows per page
    const rowsLen = updatedHandsetData.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptions([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPage(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPage(rows);
    }
  }, [handsets_searchText, handsets_filterBy, handsetData]);

  // Handle search filter via search input field (Handsets)
  const handleHandsetsSearch = (event) => {
    const { value } = event.target;
    setHandsets_searchText(value);
    setPage(0);
  };

  // Handle filter by option change (Handsets)
  const handleHandsetsFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setHandsets_filterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setHandsets_filterBy(filteredOptions);
  };

  // Handle sorting order change (Handsets)
  const handleHandsetsSort = (event) => {
    setHandsets_sortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "oem_asc":
        sortedData = [...filteredHandsetData].sort((a, b) =>
          a.oem.localeCompare(b.oem)
        );
        break;
      case "oem_desc":
        sortedData = [...filteredHandsetData].sort((a, b) =>
          b.oem.localeCompare(a.oem)
        );
        break;
      default:
        sortedData = filteredHandsetData;
        break;
    }

    setFilteredHandsetData(sortedData);
  };

  // Search and Filter Accessory Data
  useEffect(() => {
    let updatedAccessoryData = accessoryData;

    // Filter Accessories Data by search text
    if (accessory_searchText) {
      updatedAccessoryData = accessoryData.filter((item) => {
        // Join all values of the data object and its specs object into a string
        const values = Object.values(item)
          .map((value) =>
            typeof value === "object" && value !== null
              ? Object.values(value).join("")
              : value
          )
          .join("");

        // Check if the search text is present in the joined row values
        return (
          values.toLowerCase().indexOf(accessory_searchText.toLowerCase()) !==
          -1
        );
      });
    }

    // Filter Accessories data by selected filters
    if (accessory_filterBy.length > 0) {
      updatedAccessoryData = updatedAccessoryData.filter((device) => {
        // Extract oem and network_tech properties
        const { oem, network_tech } = device;

        // Combine oem and network_tech properties into an array
        const deviceValues = [oem, network_tech];

        // Iterate over accessory_filterBy options and check if any row values contain the option
        for (let option of accessory_filterBy) {
          if (deviceValues[0].includes(option) || deviceValues[1] === option) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredAccessoryData(updatedAccessoryData);

    // Set rows per page
    const rowsLen = updatedAccessoryData.length;
    const rows = Math.ceil(rowsLen / 10) * 10;
    const pg = [];
    for (let i = 0; i <= rowsLen; i++) {
      if (i > 0 && i <= 5) {
        pg.push(i * 10);
      }
    }

    setRowsPerPageOptionsA([...pg, { label: "All", value: rows }]);

    if (rowsLen > 0 && rows <= 50) {
      setRowsPerPageA(rows);
    } else if (rowsLen > 0 && rows > 50) {
      setRowsPerPageA(rows);
    }
  }, [accessory_searchText, accessory_filterBy, accessoryData]);

  // Handle search filter via search input field (Accessories)
  const handleAccessorySearch = (event) => {
    const { value } = event.target;
    setAccessory_searchText(value);
    setPage(0);
  };

  // Handle filter by option change (Accessories)
  const handleAccessoryFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setAccessory_filterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setAccessory_filterBy(filteredOptions);
  };

  // Handle sorting order change (Accessories)
  const handleAccessorySort = (event) => {
    setAccessory_sortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "oem_asc":
        sortedData = [...filteredAccessoryData].sort((a, b) =>
          a.oem.localeCompare(b.oem)
        );
        break;
      case "oem_desc":
        sortedData = [...filteredAccessoryData].sort((a, b) =>
          b.oem.localeCompare(a.oem)
        );
        break;
      default:
        sortedData = filteredAccessoryData;
        break;
    }

    setFilteredAccessoryData(sortedData);
  };

  // // Group the handset data by OEM
  const groupedHandsetDataByOEM = {};
  filteredHandsetData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach((item) => {
      if (!groupedHandsetDataByOEM[item.oem]) {
        groupedHandsetDataByOEM[item.oem] = [];
      }
      groupedHandsetDataByOEM[item.oem].push(item);
    });

  // Group the accessory data by OEM
  const groupedAccessoryDataByOEM = {};
  filteredAccessoryData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .forEach((item) => {
      if (!groupedAccessoryDataByOEM[item.oem]) {
        groupedAccessoryDataByOEM[item.oem] = [];
      }
      groupedAccessoryDataByOEM[item.oem].push(item);
    });

  // Category selection states and function
  const [category, setCategory] = useState(0);
  const handleCategoryChange = (_, category) => {
    setCategory(category);
  };

  return (
    <>
      <div className="text-center">
        <ToggleButtonGroup
          value={category}
          exclusive
          onChange={handleCategoryChange}
          aria-label="Category selection"
        >
          <ToggleButton value={0}>Handsets</ToggleButton>
          <ToggleButton value={1}>Accessories</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <TabPanel value={category} index={0}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={handsets_searchText}
                onChange={handleHandsetsSearch}
                placeholder="Search by device name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: handsets_sortOrder || "",
                  onChange: handleHandsetsSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((group) => [
                  <ListSubheader
                    key={group.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {group.heading}
                  </ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      className="px-6"
                    >
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={handsets_filterBy}
                onChange={handleHandsetsFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptionsD.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={handsets_filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        <Table>
          {filteredHandsetData.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={6}
                  className="border-0 py-4 text-center text-base text-black"
                >
                  No devices found...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            Object.keys(groupedHandsetDataByOEM).map((oem, index) => (
              <React.Fragment key={oem}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={`border-grey-100 pl-0 ${
                        index > 0 ? "pt-12" : "pt-0"
                      }`}
                      colSpan={groupedHandsetDataByOEM[oem].map((item) => {
                        return item.channels.length + 1;
                      })}
                    >
                      <h2 className="text-3xl font-bold">{oem}</h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedHandsetDataByOEM[oem].map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="pl-0">
                        <span className="text-base font-bold">
                          {item.device}
                        </span>
                      </TableCell>
                      {item.channels.map((channel) => (
                        <TableCell key={channel.label} className="text-base">
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                name="active"
                                checked={channel.active === "Y" ? true : false}
                              />
                            }
                            label={channel.label}
                            labelPlacement="start"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </React.Fragment>
            ))
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={filteredHandsetData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Items per page"
                SelectProps={{
                  inputProps: {
                    "aria-label": "Items per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TabPanel>

      <TabPanel value={category} index={1}>
        <div className="mt-8 mb-12">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={accessory_searchText}
                onChange={handleAccessorySearch}
                placeholder="Search by device name..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: accessory_sortOrder || "",
                  onChange: handleAccessorySort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((group) => [
                  <ListSubheader
                    key={group.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {group.heading}
                  </ListSubheader>,
                  ...group.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <ListItemText primary={option.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={accessory_filterBy}
                onChange={handleAccessoryFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptionsA.map((filter) => [
                  <ListSubheader
                    key={filter.value}
                    className="mx-6 my-3 border-b border-solid border-grey-50 px-0 pb-2 text-base font-bold text-black"
                  >
                    {filter.heading}
                  </ListSubheader>,
                  ...filter.options.map((item) => (
                    <MenuItem value={item.label}>
                      <CustomCheckbox
                        checked={accessory_filterBy.indexOf(item.label) > -1}
                      />
                      <ListItemText primary={item.label} />
                    </MenuItem>
                  )),
                ])}
              </InputField>
            </div>
          </div>
        </div>

        <Table>
          {filteredAccessoryData.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={6}
                  className="border-0 py-4 text-center text-base text-black"
                >
                  No devices found...
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            Object.keys(groupedAccessoryDataByOEM).map((oem, index) => (
              <React.Fragment key={oem}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={`border-grey-100 pl-0 ${
                        index > 0 ? "pt-12" : "pt-0"
                      }`}
                      colSpan={groupedAccessoryDataByOEM[oem].map((item) => {
                        return item.channels.length + 1;
                      })}
                    >
                      <h2 className="text-3xl font-bold">{oem}</h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedAccessoryDataByOEM[oem].map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="pl-0">
                        <span className="text-base font-bold">
                          {item.device}
                        </span>
                      </TableCell>
                      {item.channels.map((channel) => (
                        <TableCell key={channel.label} className="text-base">
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                name="active"
                                checked={channel.active === "Y" ? true : false}
                              />
                            }
                            label={channel.label}
                            labelPlacement="start"
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </React.Fragment>
            ))
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptionsA}
                count={filteredAccessoryData.length}
                rowsPerPage={rowsPerPageA}
                page={page}
                labelRowsPerPage="Items per page"
                SelectProps={{
                  inputProps: {
                    "aria-label": "Items per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TabPanel>

      {!setReviewState && !reviewStatus && (
        <div className="mb-12">
          <div className="flex justify-center gap-2 md:justify-end">
            <Button
              color="error"
              variant="outlined"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={XCircleIcon}
                  width={24}
                  height={24}
                  ariaHidden
                />
              }
              onClick={handleRejectChange}
              disabled={rejected}
            >
              Reject
            </Button>
            <Button
              color="success"
              variant="contained"
              disableFocusRipple
              startIcon={
                <InlineSVG
                  src={TickOutlineCircle}
                  width={24}
                  height={24}
                  ariaHidden
                />
              }
              onClick={onApprove}
            >
              Approve
            </Button>
          </div>

          {rejected && (
            <div className="flex flex-col gap-6">
              <InputField
                inputLabel="Reason"
                placeholder="Enter your comments here..."
                multiline
                minRows={2}
                onChange={(e) => setMessage(e.target.value)}
              />

              <Button
                variant="text"
                disableFocusRipple
                className="self-end text-red-100"
                onClick={onRejectSave}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
