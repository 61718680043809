import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  systemNotification: null,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setSystemNotification: (state, action) => {
      state.systemNotification = action.payload;
    },
    clearMessage: (state) => {
      state.message = "";
    },
    clearSystemNotification: (state) => {
      state.systemNotification = null;
    },
  },
});

export const {
  setMessage,
  setSystemNotification,
  clearMessage,
  clearSystemNotification,
} = messageSlice.actions;
export default messageSlice.reducer;
