// React and Mui Imports
import { useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

// Component Imports
import InputField from "../../../components/input/input-component";
import TitleBreadcrumb from "../../../components/title-breadcrumb/title-breadcrumb-component";
import BackButton from "../../../components/back-button/back-button-component";
import { BaseGenericGeneratedDealSummary } from "../base-generic-oem-selection/base-generic-oem-selection-page";
import BulkDealCardGeneric from "../../../components/bulk-deal-cards/bulk-deal-cards-component";
import TablePaginationActions from "../../../components/table-pagination-actions/table-pagination-actions-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

import AuthService from "../../../services/auth.service";
import DealService from "../../../services/deal.service";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import { useDispatch } from "react-redux";

import { getBaseGenericDealsList } from "../../../slices/dealCanvas";

const sortingOptions = [
  {
    value: "asc",
    label: "A - Z",
  },
  {
    value: "desc",
    label: "Z - A",
  },
];

const filterByOptions = [
  {
    value: "flexi",
    label: "Red Flexi",
  },
  {
    value: "1gb",
    label: "Red 1GB",
  },
];

export default function BaseGenericTariffList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Display the Device name according to the selected device in the URL
  const { cycle, oem, device } = useParams();

  // Table Properties for Items per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Deals Table Search, Sort and Filter options
  const [genericDeviceTariffData, setGenericDeviceTariffData] = useState([]);
  const [filteredTariffs, setFilteredTariffs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    DealService.getBaseGenericDeviceTariffList(cycle, oem, device)
      .then((response) => {
        setGenericDeviceTariffData(response.data);
        setFilteredTariffs(response.data);
      })
      .catch((error) => {
        const status = error.response.status;
        if (status === 401) {
          AuthService.logout();
          navigate("/logout");
        } else if (status === 403) {
          navigate("/forbidden");
        } else {
          // Handle Error
          navigate("/error");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let filteredData = genericDeviceTariffData;

    // Filter tariffs by search text
    if (searchText) {
      filteredData = genericDeviceTariffData.filter((tariff) => {
        return (
          Object.values(tariff)
            .join("")
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter plans by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((plan) => {
        // Iterate over filterBy options and check if any plan values contain the option
        for (let option of filterBy) {
          if (
            Object.values(plan)
              .join("")
              .toLowerCase()
              .includes(option.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredTariffs(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, filterBy]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  const handleSort = (event) => {
    setSortOrder(event.target.value);
    let sortedData = [];

    switch (event.target.value) {
      case "asc":
        sortedData = filteredTariffs.sort((a, b) =>
          a.tariff.localeCompare(b.tariff)
        );
        break;
      case "desc":
        sortedData = filteredTariffs.sort((a, b) =>
          b.tariff.localeCompare(a.tariff)
        );
        break;
      default:
        sortedData = filteredTariffs;
        break;
    }

    setFilteredTariffs(sortedData);
  };

  const handleGoToDeals = (tariff) => {
    dispatch(getBaseGenericDealsList({ cycle, oem, device, tariff }))
      .unwrap()
      .then(() => {
        navigate(tariff);
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="col-start-2 col-end-12 row-start-2 mt-8 mb-4 sm:mb-6 sm:mt-12 md:mb-8 md:mt-16">
        <BackButton></BackButton>
      </div>
      <section
        aria-labelledby="#selectedDeviceTitle"
        className="col-start-2 col-end-12 row-start-3"
      >
        <TitleBreadcrumb title={device} titleID="selectedDeviceTitle" />

        <div className="mt-8 mb-6">
          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-10 lg:col-span-6">
              <InputField
                inputLabel="Search"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Sort by"
                id="sort-by"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                  value: sortOrder || "",
                  onChange: handleSort,
                }}
              >
                <MenuItem value="" disabled>
                  - Select -
                </MenuItem>
                {sortingOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="col-span-5 lg:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={filterBy}
                onChange={handleFilterByChange}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterByOptions.map((filter) => (
                  <MenuItem value={filter.label} key={filter.value}>
                    <CustomCheckbox
                      checked={filterBy.indexOf(filter.label) > -1}
                    />
                    <ListItemText primary={filter.label} />
                  </MenuItem>
                ))}
              </InputField>
            </div>
          </div>
        </div>

        <div className="mt-6 mb-4">
          <BaseGenericGeneratedDealSummary
            standalone={genericDeviceTariffData.length}
            accessory={0}
            secondary={0}
            flagged={
              genericDeviceTariffData.filter((item) => item.flagged === "Y")
                .length
            }
          />
        </div>

        <Table>
          <TableBody>
            {filteredTariffs.length === 0 ? (
              <TableRow>
                <TableCell className="text-center text-base text-black">
                  No plans found...
                </TableCell>
              </TableRow>
            ) : (
              filteredTariffs.map((deal) => (
                <TableRow key={deal.id}>
                  <TableCell className="border-0 px-0 py-2">
                    <Button
                      component={Link}
                      onClick={() => {
                        handleGoToDeals(deal.tariff);
                      }}
                      aria-label={`Tariff: ${deal.tariff}`}
                      disableFocusRipple
                      className="w-full p-0 hover:no-underline"
                    >
                      <BulkDealCardGeneric
                        id={deal.id}
                        title={deal.tariff}
                        standalone={deal.standaloneDeals}
                        accessory={deal.accessoryDeals}
                        secondary={deal.secondaryDeviceDeals}
                        flagged={deal.flaggedDeals}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  20,
                  30,
                  40,
                  50,
                  { label: "All", value: -1 },
                ]}
                count={filteredTariffs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Items per page"
                SelectProps={{
                  inputProps: {
                    "aria-label": "Items per page",
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </section>
    </>
  );
}
