// React Imports
import { IconButton, Menu } from "@mui/material";
import React, { useState } from "react";

// Icon Imports
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";

// Unique ID Import
import { v4 as uuidv4 } from "uuid";

export default function MoreOptions(props) {
  const { menuOptions } = props;

  const menuOptionsArray = React.Children.toArray(menuOptions.props.children);

  const uid = uuidv4();

  // More options for each deal card
  const [anchorEl, setAnchorEl] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);

  // Handle options open event
  const handleOptionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Handle options close event
  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={`${uid}-more-menu-trigger`}
        className="text-red-100"
        aria-label="More deal options"
        aria-controls={moreOptionsOpen ? `${uid}-more-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={moreOptionsOpen ? "true" : undefined}
        onClick={handleOptionsOpen}
      >
        <MoreHorizRoundedIcon />
      </IconButton>
      <Menu
        id={`${uid}-more-menu`}
        anchorEl={anchorEl}
        open={moreOptionsOpen}
        keepMounted
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleOptionsClose}
        MenuListProps={{
          "aria-labelledby": `${uid}-more-menu-trigger`,
        }}
      >
        {menuOptionsArray.map((menuItem) => (
          <div key={menuItem.key}>{menuItem}</div>
        ))}
      </Menu>
    </>
  );
}
