import axios from "axios";

const API_URL = "/api/uploads/";

const uploadFile = (formData) => {
  return axios.post(API_URL, formData).then((response) => {
    return response.data;
  });
};

const getAllDocuments = () => {
  return axios.get(API_URL);
};

const getDocumentsByCycleId = (cycle_id) => {
  return axios.get(API_URL + cycle_id);
};

const getTradeList = (cycle) => {
  return axios.get(API_URL + cycle +"/trade-list");
};

const deleteDocument = (id) => {
  return axios.delete(API_URL + id);
};


const UploadService = {
  uploadFile,
  getAllDocuments,
  getDocumentsByCycleId,
  deleteDocument,
  getTradeList
};

export default UploadService;
